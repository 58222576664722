import { create } from 'zustand'

const stepList = [
    "reference",
    "expeditor",
    "receiver",
    "pickupDelivery",
    "packages",
    "expeditorBack",
    "receiverBack",
    "pickupDeliveryBack",
    "packagesBack",
]
const InitialUserData = {
    civility: "M",
    company: "",
    fullname: "",
    lastName: "",
    firstName: "",
    address1: "",
    address2: "",
    country: "fr",
    postalCode: "",
    city: "",
    phonecode: "",
    phone1: "+33",
    phone2: "+33",
    email: "",
    "save-contact": 0,
    "update-contact": 0,
}

const proformaInitial = {
    composition: "",
    description: "",
    hs_code: "",
    origin: "",
    prix_unitaire: "",
    qte: "",
    manifacturer: ""

}

const InitialSupData = {
    user_id: "",
    bill_center_id: {},
    username: "",
    nomContact: "",
    customer_ref: "",
    tracking_mail: "",
    types: "",
    delais: "",
    transport_type: "",
    code_receveur: "",
    code_donneur: "",
    selected_user: null,
}

const InitialPackagesData = {
    length: 0,
    width: 0,
    height: 0,
    weight: 1,
    weight_volume: "",
    description: "",
    incoterm: "exw",
    value_in_douane: "",
    devises: "eur",
    documents: [],
    ref: "",
}

const InitialPickupDeliveryData = {
    date: "",
    time: "",
    name: "",
    instruction: ""
}

const InitialAllValid = {
    reference: false,
    expeditor: false,
    receiver: true,
    expeditorBack: false,
    receiverBack: false,
    pickupDelivery: false,
    packages: false,
    pickupDeliveryBack: false,
    packagesBack: false,
    all: true
}

const InitialCoursePackage = {
    package_description: "",
    additional_insurance_amount: "",
    additional_insurance: "",
    documents: [{}],
    created_at: "",
    valeur_douane: "",
    porteur: "",
}

const proformaHeaderInitial = {
    nature: '',
    num_proforma: '',
    date_export: '',

}

export const ExpeditionStore = create((set) => ({
    validStepperList: [],
    activeStepper: 3,
    maxStepper: 5,
    withBack: false,
    validData: {
        ...InitialAllValid
    },
    user: {
        expeditor: { ...InitialUserData },
        expeditorBack: { ...InitialUserData },
        receiver: { ...InitialUserData },
        receiverBack: { ...InitialUserData },
    },
    infoSupData: {
        ...InitialSupData
    },
    coursePackageData: {
        ...InitialCoursePackage
    },
    packagesData: [
        InitialPackagesData
    ],
    deliveryPickupData: {
        pickup: {
            ...InitialPickupDeliveryData
        },
        delivery: {
            ...InitialPickupDeliveryData
        },
        pickupBack: {
            ...InitialPickupDeliveryData
        },
        deliveryBack: {
            ...InitialPickupDeliveryData
        },
    },
    proforma: {
        proformaList: [],
        proformaHeader: proformaHeaderInitial
    },
    updateProformaList: (index, key, value) => set((state) => {
        let newProformaList = [...state.proforma.proformaList];
        newProformaList[index][key] = value;
        return {
            proforma: {
                ...state.proforma,
                proformaList: newProformaList
            }
        }
    }),
    updateProformaHeader: (key, value) => set((state) => {
        return {
            proforma: {
                ...state.proforma,
                proformaHeader: {
                    ...state.proforma.proformaHeader,
                    [key]: value
                }
            }
        }
    }),
    resetProforma: () => set((state) => ({
        proforma: {
            proformaList: [],
            proformaHeader: proformaHeaderInitial
        }
    })),
    setProformaHeader: (value) => set((state) => ({
        proforma: {
            ...state.proforma,
            proformaHeader: value
        }
    })),
    setProformaList: (value) => set((state) => ({
        proforma: {
            ...state.proforma,
            proformaList: value
        }
    })),
    addProforma: () => set((state) => {
        let newProformaList = [...state.proforma.proformaList];
        newProformaList.push(proformaInitial);
        return {
            proforma: {
                ...state.proforma,
                proformaList: newProformaList
            }
        }
    }),
    removeProforma: (index) => set((state) => {
        let newProformaList = [...state.proforma.proformaList];
        newProformaList.splice(index, 1);
        return {
            proforma: {
                ...state.proforma,
                proformaList: newProformaList
            }
        }
    }),
    setWithBack: (value) => set((state) => {
        state.withBack = value
        return {
            withBack: value
        }
    }),

    updateValidData: (dataKey, value) => set((state) => {
        return {
            validData: {
                ...state.validData,
                [dataKey]: value
            }
        }
    }),
    resetValidData: () => set((state) => {
        return {
            validData: {
                ...InitialAllValid
            }
        }
    }),

    setUserData: (value, userType, shippingProcess) => set((state) => {
        const typeUser = userType === "expeditor" || userType === "expeditorBack"
            ? (shippingProcess === "normal" ? "expeditor" : "expeditorBack")
            : (shippingProcess === "normal" ? "receiver" : "receiverBack");

        return {
            user: {
                ...state.user,
                [typeUser]: {
                    ...state.user[typeUser],
                    ...value
                }
            }
        };
    }),
    setAllUserData: (data) => set((state) => {
        return {
            user: {
                expeditor: data.expeditor,
                expeditorBack: data.expeditorBack,
                receiver: data.receiver,
                receiverBack: data.receiverBack,
            }
        };
    }),
    updateUserData: (dataKey, value, userType, shippingProcess) => set((state) => {
        const typeUser = userType === "expeditor" || userType === "expeditorBack"
            ? (shippingProcess === "normal" ? "expeditor" : "expeditorBack")
            : (shippingProcess === "normal" ? "receiver" : "receiverBack");

        const data = state['user'][typeUser];

        // Fonction utilitaire pour extraire le prénom et le nom
        const parseName = (fullname, name) => {
            const combinedName = fullname || name || "";
            const [firstName, lastName] = combinedName.split(" ");
            return {
                firstName: firstName || data?.firstName,
                lastName: lastName && lastName !== "undefined" ? lastName : data?.lastName,
            };
        };

        let newValue = {};

        // Mise à jour en fonction de `dataKey`
        if (dataKey === "name") {
            const { firstName, lastName } = parseName(data?.fullname, data?.name);
            newValue = {
                ...data,
                fullname: `${value} ${lastName || ""}`,
                name: value,
                firstName,
                lastName,
            };
        } else if (dataKey === "fullname") {
            const { firstName, lastName } = parseName(value, data?.name);
            newValue = {
                ...data,
                fullname: value,
                firstName,
                lastName,
            };
        } else {

            newValue = {
                ...data,
                [dataKey]: value,
            };
        }

        return {
            user: {
                ...state.user,
                [typeUser]: newValue,
            }

        };


    }),
    resetUserData: (userType, shippingProcess) => set((state) => {
        const typeUser = userType === "expeditor" || userType === "expeditorBack"
            ? (shippingProcess === "normal" ? "expeditor" : "expeditorBack")
            : (shippingProcess === "normal" ? "receiver" : "receiverBack");

        return {
            user: {
                [typeUser]: {
                    ...InitialUserData
                }
            }
        };

    }),
    resetAllUser: () => set((state) => {
        return {
            user: {
                expeditor: { ...InitialUserData },
                expeditorBack: { ...InitialUserData },
                receiver: { ...InitialUserData },
                receiverBack: { ...InitialUserData },
            }
        }
    }),

    setSupData: (data) => set((state) => ({
        infoSupData: {
            ...state.infoSupData,
            ...data
        }
    })),
    upadateSupInfo: (dataKey, Value) => set((state) => ({
        infoSupData: {
            ...state.infoSupData,
            [dataKey]: Value
        }
    })),
    resetSupData: () => set((state) => ({
        infoSupData: {
            ...InitialSupData
        }
    })),

    setPackagesData: (value) => set((state) => ({
        packagesData: [
            ...state.packagesData,
            { ...value }
        ]
    })),
    updatePackagesData: (dataKey, value, index) => set((state) => {
        let newPackagesData = [...state.packagesData];
        newPackagesData[index][dataKey] = value;
        return {
            packagesData: newPackagesData
        }
    }),
    addPackagesData: () => set((state) => ({
        packagesData: [
            ...state.packagesData,
            InitialPackagesData
        ]
    })),
    deletePackagesData: (index) => set((state) => {
        if (state.packagesData.length > 1) {
            let newPackagesData = [...state.packagesData];
            newPackagesData.splice(index, 1);
            return {
                packagesData: newPackagesData
            }
        }
    }),
    duplicatePackagesData: (index, number) => set((state) => {
        let newPackagesData = [...state?.packagesData];
        let multiplyColis = { ...newPackagesData[index] };
        for (let i = 0; i < number; i++) {
            newPackagesData.splice(index, 0, multiplyColis);
        }
        return {
            packagesData: newPackagesData
        }
    }),
    resetPackagesData: () => set((state) => ({
        packagesData: [{
            ...InitialPackagesData
        }]
    })),

    setCoursePackageData: (value) => set((state) => ({
        coursePackageData: value
    })),
    updateCoursePackageData: (dataKey, value, processingData) => set((state) => {

        return {
            coursePackageData: {
                ...state.coursePackageData,
                [dataKey]: value
            }
        }
    }),
    resetCoursePackageData: () => set((state) => ({
        coursePackageData: [{
            ...InitialCoursePackage
        }]
    })),

    setPickupDeliveryData: (value, actionState, shippingProcess) => set((state) => {

        const action = actionState === "pickup"
            ? (shippingProcess === "normal" ? "pickup" : "pickupBack")
            : (shippingProcess === "normal" ? "delivery" : "deliveryBack");
        return {
            deliveryPickupData: {
                ...state.deliveryPickupData,
                [action]: {
                    ...state.deliveryPickupData[action],
                    ...value
                }
            }
        };
    }),
    updatePickupDeliveryData: (dataKey, value, actionState, shippingProcess) => set((state) => {
        const action = actionState === "pickup" || actionState === "pickupBack"
            ? (shippingProcess === "normal" ? "pickup" : "pickupBack")
            : (shippingProcess === "normal" ? "delivery" : "deliveryBack");


        let newPickupDeliveryData = { ...state.deliveryPickupData };
        newPickupDeliveryData[action][dataKey] = value;
        return {
            deliveryPickupData: newPickupDeliveryData
        }
    }),
    resetPickupDeliveryData: (actionState, shippingProcess) => set((state) => {
        const action = actionState === "pickup"
            ? (shippingProcess === "normal" ? "pickup" : "pickupBack")
            : (shippingProcess === "normal" ? "delivery" : "deliveryBack");
        let newPickupDeliveryData = { ...state.deliveryPickupData };
        newPickupDeliveryData[action] = {
            ...InitialPickupDeliveryData
        }
        return {
            deliveryPickupData: newPickupDeliveryData
        }
    }),

    setMaxStepper: (value) => set((state) => ({
        maxStepper: value
    })),

    setActiveStepper: (value) => set((state) => ({
        activeStepper: value
    })),

    nextStepper: () => set((state) => {
        if (state.activeStepper < state.maxStepper) {

            const step = stepList[state.activeStepper - 1];

            console.log(state)

            return {
                validData: {
                    ...state.validData,
                    [step]: true,
                    all: state.activeStepper + 1 === state.maxStepper
                },
                validStepperList: [...state.validStepperList, state.activeStepper + 1],
                activeStepper: state.activeStepper + 1
            }
        }
    }),

    previousStepper: () => set((state) => {
        if (state.activeStepper > 0) {
            return {
                activeStepper: state.activeStepper - 1
            }
        }
    }),

    resetStepper: () => set((state) => ({
        activeStepper: 1,
        maxStepper: 5
    })),
    setValidStep: (data) => set((state) => ({
        validStepperList: [
            ...state.validStepperList,
            ...data
        ]

    })),


    resetAllShippingData: () => set((state) => ({
        validData: {
            ...InitialAllValid
        },
        user: {
            expeditor: { ...InitialUserData },
            expeditorBack: { ...InitialUserData },
            receiver: { ...InitialUserData },
            receiverBack: { ...InitialUserData },

        },
        infoSupData: {
            ...InitialSupData
        },
        packagesData: [{ ...InitialPackagesData }],
        coursePackageData: {
            ...InitialCoursePackage
        },
        deliveryPickupData: {
            pickup: {
                ...InitialPickupDeliveryData
            },
            delivery: {
                ...InitialPickupDeliveryData
            },
            pickupBack: {
                ...InitialPickupDeliveryData
            },
            deliveryBack: {
                ...InitialPickupDeliveryData
            },
        },
        withBack: false,
        activeStepper: 1,
        maxStepper: 5,
        validStepperList: [1],
        proforma: {
            proformaList: [],
            proformaHeader: proformaHeaderInitial
        },
    })),
}));
