import React, { useCallback, useEffect, useState } from 'react'
import useDisclosure from '../../hooks/useDisclosure';
import useAdminLists from '../../hooks/useAdminLists';
import { formatDefaultDate } from '../../utils/moment';
import { TableLoader } from '../Utils/Loader';
import { getCountryNameOrCode } from '../../utils/const/countriesConstant';
import { FormModal } from './ContactAdminFomComponent';
import { useApi } from "../../hooks/useRequestLoader"
import contactService from '../../services/contact/contactService';
import { getUniqueItemsByKey } from '../../utils';
import ReactPaginate from "react-paginate";
import PaginationComponent from '../paginationComponent/PaginationComponent';
import useTableFilter from '../../hooks/useTableFilter';
import { TbArrowsUpDown } from 'react-icons/tb';
import { PlusIcon } from '@heroicons/react/24/outline';
import { TableComponent } from '../TableauComponent';
import { TableDataStore } from '../../states/tableData';


export default function ContactsView() {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [totalItems, setTotalItems] = useState(0);
    const [perPageItems, setperPageItems] = useState(25);

    const {
        isOpen: isDeleteConfirmOpen,
        onClose: onDeleteConfirmClose,
        onOpen: onDeleteConfirmOpen
    } = useDisclosure();

    const [metadata, setMetadata] = useState({
        letter: "A",
        page: 1
    })

    const { search } = metadata

    /* const { adminData, isLoading, onSubmit, search, setSearch } = useAdminLists({
        type, params: {
            letter: metadata.letter
        }
    }); */

    const [selectedItem, setSelectedItem] = useState(null)

    const onItemSelected = (item, action) => {
        setSelectedItem(item)
        if (action === "edit") {
            onOpen()
        } else {
            onDeleteConfirmOpen()
        }
    }

    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        const fetchItems = async () => {
            try {
                setIsLoading(true)
                const params = {
                    type: "all",
                    per_page: parseInt(perPageItems),
                    page: metadata.page,
                    filter: {
                        letter: metadata.letter.toLowerCase(),
                    }
                }
                if (metadata.search) {
                    params.filter.other = metadata.search
                }
                const { data: resData } = await contactService.fetchAdminFilteredContacts(params)
                if (resData.success) {
                    setItems(resData.data.data)
                    setIsLoading(false);
                    //increase page to load next items
                    const total = resData.data.total;
                    setTotalItems(total)

                }
            } catch (error) {
                console.error(error)
                setIsLoading(false)
            }
        };

        fetchItems()
    }, [metadata, perPageItems])

    const handlePageClick = (page) => {
        console.log({ page })
        setMetadata(m => ({
            ...m,
            page
        }))

        //setCurrentPage(event.selected);

    };


    const LETTERS = Array.from({ length: 26 }, (_, index) => String.fromCharCode('A'.charCodeAt(0) + index));

    const onSubmit = useCallback(
        () => {

        },
        [],
    )

    console.log({ totalItems, perPageItems, metadata })

    return (
        <div className={""}>
            <div className="flex items-center justify-between">
                <div>
                    <h4 className="mb-1 uppercase page-title">Liste des contacts</h4>
                    <div className="w-md">
                        <input
                            className="w-full px-4 py-2 mb-3 bg-white border border-gray-500 rounded-lg outline-none form-control"
                            type="search"
                            placeholder="Recherche"
                            value={metadata.search}
                            onChange={(e) => setMetadata(m => ({
                                ...m,
                                search: e.target.value,
                                page: 1
                            }))}
                        />
                    </div>
                </div>
                <div
                    className="float-right my-auto text-white bg-black btn btn-rounded"
                    onClick={onOpen}
                >
                    <PlusIcon className={"h-4 w-4 m-3 text-xl font-bold "} />
                </div>
            </div>
            <div className="w-full my-3 ">
                <PaginationComponent
                    page={metadata.page}
                    handlePageClick={handlePageClick}
                    totalItems={totalItems}
                    perPageItems={perPageItems}
                    setnumberPerPage={setperPageItems}
                />
            </div>

            <div className="flex justify-between w-full gap-2 py-1 mb-3 overflow-auto bg-white rounded-md full">
                {LETTERS.map((l, i) => {
                    const active = l === metadata.letter
                    return (
                        <div key={i}
                            className={`${active ? "bg-admin text-white" : "text-black hover:bg-admin hover:text-white"} h-8 w-8 flex items-center justify-center text-sm rounded-full cursor-pointer ${active}`}
                            onClick={() => {
                                setMetadata(m => ({ ...m, letter: l, page: 1 }))
                            }}
                        >
                            {l}
                        </div>
                    )
                })}
            </div>

            <Lists
                data={items}
                onSelect={onItemSelected}
                isLoading={isLoading}
            />

            <div className="my-3">
                <PaginationComponent
                    page={metadata.page}
                    element='pagination'
                    handlePageClick={handlePageClick}
                    totalItems={totalItems}
                    perPageItems={perPageItems}
                    setnumberPerPage={setperPageItems}
                />
            </div>
            {/* <div>
                <nav>
                    <div
                        className="mb-0 pagination justify-content-end"
                        listClassName="justify-content-end mb-0"
                    >
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="Suivant >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            previousLabel="< Précédent"
                            renderOnZeroPageCount={null}
                            breakClassName={"break-me"}
                            pageCount={Math.ceil(totalItems / 25)}
                            marginPagesDisplayed={2}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                        />
                    </div>
                </nav>
            </div> */}

            <FormModal
                isOpen={isOpen}
                onClose={() => {
                    onClose()
                    setSelectedItem(null)
                }}
                onSubmit={onSubmit}
                selectedData={selectedItem} />

        </div>
    )
}


const Lists = ({ data, onSelect, isLoading, onToggleActive }) => {
    const { results, onTableHeaderClick } = useTableFilter({ items: data });

    const HEADERS = [
        { label: 'ID', filterKey: 'id' },
        { label: 'Civilité', filterKey: 'civility' },
        { label: 'Nom & Prénoms', filterKey: 'fullname' },
        { label: 'Entreprise/Société', filterKey: 'company' },
        { label: 'Pays', filterKey: 'country' },
        { label: 'Adresse', filterKey: 'address1' },
        { label: 'Ville', filterKey: 'city' },
        { label: 'Téléphone', filterKey: 'telephone1' },
        { label: 'Type', filterKey: 'type' },
        { label: 'DATE DE CRÉATION', filterKey: 'created_at' },
        
    ];

     const dataList = TableDataStore((state) => state.dataList);
    const setDataList = TableDataStore((state) => state.setDataList);
    const updateData = TableDataStore((state) => state.updateData);
    const deleteData = TableDataStore((state) => state.deleteData);

    return (
        <TableComponent withAction={false} headerList={HEADERS} dataLength={results.length} isLoading={isLoading}>
            {results.map((item, i) => (
                <tr key={i} className="py-3 divide-y divide-gray-300 ">
                    <td
                        className={'cursor-pointer'}
                        onClick={() => {
                            onSelect(item, "edit")
                        }}
                    >{item.id}</td>
                    <td
                        className={'cursor-pointer'}
                        onClick={() => {
                            onSelect(item, "edit")
                        }}
                    >{item.civility}</td>
                    <td
                        className={'cursor-pointer'}
                        onClick={() => {
                            onSelect(item, "edit")
                        }}
                    >{item.fullname || item?.firstName + " " + item?.lastName}</td>
                    <td
                        className={'cursor-pointer'}
                        onClick={() => {
                            onSelect(item, "edit")
                        }}
                    >{item.company}</td>
                    <td
                        className={'cursor-pointer'}
                        onClick={() => {
                            onSelect(item, "edit")
                        }}
                    >
                        <div className="flex flex-wrap items-center">
                            <img
                                src={`https://flagcdn.com/16x12/${getCountryNameOrCode(getCountryNameOrCode(item.country), true)}.png`}
                                srcSet={`https://flagcdn.com/32x24/${getCountryNameOrCode(getCountryNameOrCode(item.country), true)}.png 2x,
                                    https://flagcdn.com/48x36/${getCountryNameOrCode(getCountryNameOrCode(item.country), true)}.png 3x`}
                                width="16"
                                height="12"
                                alt={getCountryNameOrCode(
                                    getCountryNameOrCode(item.country),
                                    true
                                )} />
                            <div className='ml-1'>
                                {getCountryNameOrCode(item.country)}
                            </div>
                        </div>
                    </td>
                    <td
                        className={'cursor-pointer'}
                        onClick={() => {
                            onSelect(item, "edit")
                        }}
                    >{item.address1}</td>
                    <td
                        className={'cursor-pointer'}
                        onClick={() => {
                            onSelect(item, "edit")
                        }}
                    >{item.city}</td>
                    <td
                        className={'cursor-pointer'}
                        onClick={() => {
                            onSelect(item, "edit")
                        }}
                    >{item.telephone1}</td>
                    <td
                        className={'cursor-pointer'}
                        onClick={() => {
                            onSelect(item, "edit")
                        }}
                    >{item.type}</td>
                    <td
                        className={'cursor-pointer'}
                        onClick={() => {
                            onSelect(item, "edit")
                        }}
                    >{formatDefaultDate(item.created_at)}</td>

                    {/* <td>
                                                <div className="flex items-center">
                                                    <div
                                                        className="text-green-600 cursor-pointer text-[14px] mr-5"
                                                        onClick={() => {
                                                            onSelect(item, "edit")
                                                        }} title="Editer"
                                                    >
                                                        <i className="fas fa-edit"></i>
                                                    </div>

                                                </div>
                                            </td> */}
                </tr>
            ))
            }
        </TableComponent>


    )
}