import React, { useEffect, useState } from "react";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { toFixed2 } from "../../utils";
import IncotermSelector from "../Utils/Selectors/IncotermSelector";
import clsx from "clsx";
import { ButtonComponent } from "../ButtonComponent";
import InputComponent from "../InputComponent/InputComponent";
import { ErrorStore } from "../../store/inputError";
import { ExpeditionStore } from "../../store/expedition";
import { Princing } from "./pricing";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);


export const ShippingPackage = ({
  index,
  deletColis,
  multiply
}) => {
  const [files, setFiles] = useState([]);
  const [nombre, setNombre] = useState(0);



  const packagesData = ExpeditionStore((state) => state.packagesData)[index];
  const updatePackagesData = ExpeditionStore((state) => state.updatePackagesData);
  const deletePackagesData = ExpeditionStore((state) => state.deletePackagesData);
  const duplicatePackagesData = ExpeditionStore((state) => state.duplicatePackagesData);
  const errorMessage = ErrorStore((state) => state.errorField);
  const setMessageError = ErrorStore((state) => state.setMessageError);

  const UpdateColisData = (index, Attribut, valeur) => {
    updatePackagesData(Attribut, valeur, index)
  }

  // index Attribut valeur
  useEffect(() => {

    updatePackagesData("weight_volume", (packagesData.height * packagesData.width * packagesData.length) / 5000, index)

    UpdateColisData(index, "fichiers", files)
  }, [packagesData.height, packagesData.width, packagesData.length, files]);

  const isError = 2;

  return (
    <div className="row" key={`row_${index}`}>
      {errorMessage.field}
      <div className="">
        <h4 className="text-center card-title">Colis {index + 1}</h4>
        <hr className="my-2" />

        <div className={"md:grid grid-cols-12 items-baseline gap-1 space-y-2 md:space-y-0 "}>
          <div className={"col-span-4"}>
            <div className={`flex gap-5 `}>
              <div className={``}>

                {/* <span>Dimenssions</span> */}


                <div className="items-center w-full mb-3 md:flex ">
                  <div>
                    <label className={`pl-2`}>L </label>
                    <InputComponent
                      type="number"
                      value={packagesData.length}
                      onChange={(valeur) =>
                        UpdateColisData(index, "length", valeur)
                      }
                      errorMessage={errorMessage.message}
                      placeholder={"L*".toUpperCase()}
                      label=""
                      withLabel={false}
                      isError={
                        errorMessage.field === "length" && isError
                      }
                      min={0}
                    />

                  </div>

                  <span className="hidden input-group-text md:block">X</span>
                  <div>
                    <label className={`pl-2`}>l </label>
                    <InputComponent
                      type="number"
                      value={packagesData.width}
                      onChange={(valeur) =>
                        UpdateColisData(index, "width", valeur)
                      }
                      errorMessage={errorMessage.message}
                      placeholder={"L*".toUpperCase()}
                      label=""
                      withLabel={false}
                      isError={
                        errorMessage.field === "width" && isError
                      }
                      min={0}
                    />
                  </div>


                  <span className="hidden input-group-text md:block">X</span>
                  <div>
                    <label className={`pl-2 `}>h</label>
                    <InputComponent
                      type="number"
                      value={packagesData.height}
                      onChange={(valeur) =>
                        UpdateColisData(index, "height", valeur)
                      }
                      errorMessage={errorMessage.message}
                      placeholder={"L*".toUpperCase()}
                      label=""
                      withLabel={false}
                      isError={
                        errorMessage.field === "height" && isError
                      }
                      min={0}
                    />
                  </div>

                  <span className="input-group-text">cm</span>
                </div>
                {(index === errorMessage.field && (errorMessage?.field2 === 'height' || errorMessage?.field2 === 'width' || errorMessage?.field2 === 'length')) &&
                  <p className="mt-2 text-sm text-red-600" id={"full-error"}>
                    {errorMessage.message}
                  </p>
                }
              </div>
            </div>
          </div>
          <div className={`col-span-2`}>
            <div className={``}>
              <label className={`mb-4 mt-1 uppercase`}>
                POIDS VOLUMéTRIQUE
              </label>

              <InputComponent
                type="number"
                errorMessage={errorMessage.message}
                placeholder={"POIDS VOLUMéTRIQUE".toUpperCase()}
                label=""
                withLabel={false}
                isError={
                  errorMessage.field === "weight_volume" && isError
                }
                disabled
                value={parseFloat(toFixed2((packagesData.height *
                  packagesData.width *
                  packagesData.length) /
                  5000))

                }
                min={0}
                onChange={(e) =>
                  UpdateColisData(
                    index,
                    "weight_volume",
                    parseFloat(toFixed2(
                      (packagesData.height *
                        packagesData.width *
                        packagesData.length) /
                      5000
                    )
                    ))

                }
              />


            </div>
            {(index === errorMessage.field && errorMessage?.field2 === 'weight_volume') &&
              <p className="mt-2 text-sm text-red-600" id={"full-error"}>
                {errorMessage.message}
              </p>
            }
          </div>
          <div className={`col-span-2  `}>
            <label className={`mb-4 mt-1 uppercase`}>POIDS REEL(Kg)</label>

            <input
              type="number"
              className="w-full bg-white form-control"
              value={packagesData.weight}
              min={0}
              onChange={(e) =>
                UpdateColisData(index, "weight", e.target.value)
              }
            />
          </div>

          <div className=" md:col-span-2">
            <label className={`mb-4 mt-1 uppercase `}>VALEUR COMMERCIALE </label>

            <Princing index={index} />

          </div>
          <div className=" md:col-span-2">
            <IncotermSelector value={packagesData["incoterm"]} onChange={(v) =>
              UpdateColisData(index, "incoterm", v)
            }
              showLabel={true}
            />

          </div>
        </div>

        <div className={`md:grid grid-cols-2 gap-1`}>
          <div className={``}>
            <div className="row">
              <div className="form-group ">
                <label className={`form-label`}>DESCRIPTION*</label>
                <textarea
                  rows="5"
                  cols="5"
                  // className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  className={clsx({ "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500": errorMessage?.field2 === 'description' && index === errorMessage.field, "ring-gray-300 focus:ring-gray-500 bg-white border border-gray-500": !errorMessage?.field2 === 'description' && index !== errorMessage.field }, "px-2 focus:border-gray-300 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-gray-300  focus:ring-2 text-base sm:leading-6 h-auto border-gray-300 focus:ring-gray-300")}
                  value={packagesData["package_description"]}
                  style={{ resize: "none", }}
                  onChange={(e) => {
                    UpdateColisData(
                      index,
                      "package_description",
                      e.target.value
                    );
                    UpdateColisData(index, "description", e.target.value);
                  }}
                ></textarea>

                {errorMessage?.field2 === 'description' && index === errorMessage.field &&
                  <p className="mt-2 text-sm text-red-600" id={"full-error"}>
                    {errorMessage.message}
                  </p>
                }
              </div>
            </div>
          </div>

          <div className={`space-y-2`}>
            <div className="mt-4 border border-black border-dashed ">
              {/* Pour les fichiers */}

              <FilePond
                files={files}
                allowReorder={true}
                allowMultiple={true}
                onupdatefiles={setFiles}
                labelIdle='Faites glisser et déposez vos fichiers ou <span className="filepond--label-action">parcourir</span>'
              />
            </div>

            <div className=" md:col-span-4">
              <div className="flex gap-2 ">
                <div className="col-4 ">
                  <div className="form-group">
                    <input
                      type="number"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                      name=""
                      id=""
                      placeholder="X Fois"
                      min={2}
                      className={
                        "w-full  bg-white border border-gray-500 rounded-lg outline-none form-control"
                      }
                    />
                  </div>
                </div>

                <div className="">
                  <ButtonComponent title={"Dupliquer"} onClick={() => duplicatePackagesData(index, nombre)} />
                </div>
                {index > 0 &&
                  <div>
                    <ButtonComponent title={"Supprimer"} onClick={() => deletePackagesData(index)} />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

