// create Fuel react service

import axios from "axios";
import TokenService from "../config/tokenService.js";

class ExploitationService {

    constructor() {
        TokenService.setHeader();
    }
    getDrivers(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/ops/all/driver`, data);
    }

    getDriverShippings(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/user/shipping/en-cours/Attribuer`, data)
    }

    getShippings(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/user/shipping/en-cours`, data)
    }
}

export default new ExploitationService();
