import React from "react";
import Select from "react-select";

const options = [
    { value: "M", label: "Monsieur" },
    { value: "Mme", label: "Madame" },
  ]
const CivilitySelector = ({ showLabel = true, value, onChange }) => {

  const values = options.find((t) => t.value === value) ?? options[0] ;

  return (
    <div>
      {showLabel && (
        <label
          htmlFor="types"
          className="block mt-1 mb-4 text-sm font-medium text-gray-900 uppercase form-label"
        >
          CHOIX DE CIVILITé
        </label>
      )}
      <Select
        options={options}
        value={values}
        onChange={(v) => onChange(v.value)}
        className={"text-base w-100"}
        placeholder={' CHOIX DE CIVILITE'}
      />
    </div>
  );
};

export default CivilitySelector;
