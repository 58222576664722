import React, { useCallback, useEffect, useState } from "react";
import centreFacturationService from "../../../services/centreFacturation/centreFacturationService";
import Select from "react-select";
import { urlSplit } from "../../../utils/Functions/otherFunctions";
import useBillingCenters from "../../../states/billingCenter"
import opsService from "../../../services/ops/opsService";

function BillCenterSelector({
  type,
  selectedValue,
  onSelect,
  isDisabled,
  multiple,
  user = false,
  adminRouting = false,
  className,
  client = false,
  showLabel = false,
  byOps = false
}) {

  const { items: billCenter, setItems: setBillCenter } = useBillingCenters()
  const [page, setPage] = useState(1);
  const [defaultData, setDefaultData] = useState()
  const [isLoading, setisLoading] = useState(true)

  useEffect(() => {
    setPage(1);
    setBillCenter([]);
  }, [user, client]);

  const { exnetSpace } = urlSplit();

  const handleSetDefaultBilling = useCallback(() => {
    let newData = billCenter.length === 1 ? billCenter[0] : Array.isArray(selectedValue) ? billCenter.filter((c) => selectedValue.includes(c?.value)) : billCenter.find((c) => c?.value === selectedValue);

    //console.log('newData', newData, selectedValue)
    setDefaultData(newData)

  }, [billCenter, selectedValue])

  useEffect(() => {
    let option = {
      page: page,
      per_page: 30,
      type,
    };

    if (user) {
      option.customer_id = user;
    }

    // ops/billings-centers
    setisLoading(true)
    const fetchCentreFacturations = async () => {
      try {
        const response = byOps ? await opsService.allBillingCenterByOps(option) : client ?
          await centreFacturationService.fetchUserBillingCenter({ type: exnetSpace === "dash" ? "all" : exnetSpace }) : adminRouting ?
            await centreFacturationService.getBillingCenterByCustommerIdForAdmin(option) : (
              user
                ? await centreFacturationService.fetchCentreFacturationByUser(option)
                : await centreFacturationService.fetchCentreFacturations(option)
            );

        const { data } = response;

        if (data.success) {
          let tableauData = user ? data.data : data.data.data;

          let newValue = tableauData.map((c) => ({
            label: c.billing_center_name,
            value: c.id,
          }));

          const lists = page === 1 ? newValue : [...billCenter, ...newValue]

          setBillCenter(lists);

          if (data.data.last_page) {
            if (data.data.last_page > page) {
              setPage(page + 1);
            }
          }

          if (data.last_page) {
            if (data.last_page > page) {
              setPage(page + 1);
            }
          }
          setisLoading(false)
        }
      } catch (error) {
        console.log("Error", error);
        // Handle error here
      }
    };

    fetchCentreFacturations();


  }, [page, user, type]);


  useEffect(() => {
    if (defaultData?.label) {
      onSelect(multiple ? [{ ...defaultData }] : { ...defaultData })
    }
  }, [defaultData, multiple])

  useEffect(() => {
    if (billCenter.length > 0) {
      handleSetDefaultBilling()
    }
  }, [billCenter, handleSetDefaultBilling])

  //console.log({ selectedValue, defaultData })

  return (
    <div className={''}>
      {showLabel && (
        <label
        htmlFor="drivers"
          className="titre-detail"
        >
          Centre de facturation
        </label>
      )}
      <Select

        value={defaultData}
        placeholder={"Choix du centre*".toUpperCase()}
        className={`text-base  `}
        options={billCenter}
        onChange={(v) => {
          setDefaultData(v)
          onSelect(v);
        }}
        isDisabled={isDisabled}
        noOptionsMessage={() => "Pas de centre de facturation disponible"}
        isMulti={multiple}
        isLoading={isLoading}
        LoadingMessage={() => "Chargement..."}
      />

    </div>
  );
}

export default BillCenterSelector;
