import React from 'react';
import { getCountryNameOrCode } from '../../utils/const/countriesConstant';
import clsx from 'clsx';
import  PhoneInput  from 'react-phone-input-2';

function PhoneInputComponent({ isChangeCountry, value, country, onChange, errorInformation }) {
    return (
        <>
            {isChangeCountry &&
                <PhoneInput
                    country={
                        getCountryNameOrCode(
                            getCountryNameOrCode(country),
                            true
                        ) ?? "fr"
                    }
                    defaultValue={value}
                    value={value}
                    onChange={(value, country, e, formattedValue) => onChange(value, country, e, formattedValue)
                    }
                    inputClass={clsx({
                        "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500 form-controle-error":
                            errorInformation.field === "phone1",
                        "ring-gray-300 focus:ring-gray-500 bg-white border border-gray-500 form-controle":
                            errorInformation.field !== "phone1",
                    })}
                    countryCodeEditable={false}
                    disableDropdown={false}
                />
            }

            {!isChangeCountry &&

                <PhoneInput

                    defaultValue={value}
                    value={value}
                    onChange={(value, country, e, formattedValue) => onChange(value, country, e, formattedValue)}
                    inputClass={clsx({
                        "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500 form-controle-error":
                            errorInformation.field === "phone1",
                        "ring-gray-300 focus:ring-gray-500 bg-white border border-gray-500 form-controle":
                            errorInformation.field !== "phone1",
                    })}
                    countryCodeEditable={false}
                    disableDropdown={false}
                />
            }
        </>
    );
}

export default PhoneInputComponent;