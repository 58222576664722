import React, { useCallback, useEffect, useState } from "react";
import ModalUI from "../../Utils/Modal/Modal";
import { useBasicFormValues } from "../../../hooks/useFormValues";
import vehiculeService from "../../../services/vehicule/vehiculeService";
import UsersSelector from "../../Utils/Selectors/UsersSelector";
import { validateCarData } from "../../../utils/validators";
import Loader from "../../Utils/Loader";
import { pick } from "lodash";
import { toast } from "react-toastify";
import { SwitchComponent } from "../../Utils/Index";
import OptionsSelector from "../../Utils/Selectors/Index";

export default function CarFormModal({
  isOpen,
  onClose,
  onSubmit,
  selectedData,
}) {

  const CAR_TYPES = [
    { label: "2 roues", value: "2" },
    { label: "Voiture", value: "4" },
    { label: "Camion", value: "8" }
  ]

  const initialValue = {
    plaque: "",
    kmdepart: 0,
    kmretour: 0,
    chauffeur_id: "",
    telephone_societe: "",
    bip_telepage: "",
    carte_carburant: "",
    carte_bancaire: "",
    pochette_recu: 0,
    type_vehicule: ""
  }

  const {
    data: vehiculeData,
    setFormValue,
    setData,
  } = useBasicFormValues(initialValue);

  useEffect(() => {
    if (selectedData) {
      //console.log({ selectedData })
      setFormValue("chauffeur_id", selectedData.chauffeur_id);
      setData((d) => ({
        ...d,
        ...selectedData,
      }));
    }
  }, [selectedData]);

  //console.log({ vehiculeData })

  const [validationError, setValidationError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        setValidationError(null);
        const { error: _err } = validateCarData(vehiculeData);

        if (_err) {
          setValidationError(_err);
          return;
        }
        setIsSubmitting(true);
        const submitData = pick(vehiculeData, [
          "id",
          "kmretour",
          "kmdepart",
          "plaque",
          //"chauffeur_id",
          "telephone_societe",
          "bip_telepage",
          "carte_carburant",
          "carte_bancaire",
          "pochette_recu",
          "type_vehicule"
        ]);
        const request = selectedData
          ? vehiculeService.updateVehicule
          : vehiculeService.createVehicule;
        const { data } = await request(submitData);
        if (data.success) {
          onClose();
          onSubmit?.(selectedData ? vehiculeData : data.data);
        }
      } catch (error) {
        console.error(error);
        toast.error('Une erreur lors de la soumission de votre demande', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } finally {
        setIsSubmitting(false);
      }
    },
    [vehiculeData, selectedData]
  );

  return (
    <ModalUI
      isOpen={isOpen}
      onClose={onClose}
      title={selectedData ? "Modifier le véhicule" : "Créer un véhicule"}
    >
      {validationError && (
        <div className="bg-red-100 mt-4 text-red-600 px-4 py-2 rounded-md text-[13px]">
          {validationError}
        </div>
      )}
      <form onSubmit={handleSubmit} className="p-3">
        <div className="grid gap-6 mb-3 md:grid-cols-2">
          {/* <div className="text-left">
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Choix du chauffeur
            </label>
            <UsersSelector
              showLabel={false}
              profil={"chauffeur"}
              selectedValue={vehiculeData.chauffeur_id}
              onSelect={(v) => {
                setFormValue("chauffeur_id", v.id);
              }}
            />
          </div> */}

          <div className="text-left">
            <label
              htmlFor="plaque"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Plaque
            </label>
            <input
              type="text"
              id="plaque"
              placeholder="Numéro plaque"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-100 block w-full p-2"
              required
              value={vehiculeData.plaque}
              onChange={(e) => setFormValue("plaque", e.target.value)}
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="kmdepart"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              KM de départ
            </label>
            <input
              type="number"
              id="kmdepart"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-100 block w-full p-2"
              value={vehiculeData.kmdepart}
              onChange={(e) => setFormValue("kmdepart", e.target.value)}
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="kmretour"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              KM de retour
            </label>
            <input
              type="number"
              id="kmretour"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-100 block w-full p-2"
              required
              value={vehiculeData.kmretour}
              onChange={(e) => setFormValue("kmretour", e.target.value)}
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="telephone_societe"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Société téléphone
            </label>
            <input
              type="text"
              id="telephone_societe"
              placeholder=""
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-100 block w-full p-2"
              required
              value={vehiculeData.telephone_societe}
              onChange={(e) => setFormValue("telephone_societe", e.target.value)}
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="bip_telepage"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Bip telepéage
            </label>
            <input
              type="text"
              id="bip_telepage"
              placeholder=""
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-100 block w-full p-2"
              required
              value={vehiculeData.bip_telepage}
              onChange={(e) => setFormValue("bip_telepage", e.target.value)}
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="carte_carburant"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Carte carburant
            </label>
            <input
              type="text"
              id="Carte carburant"
              placeholder=""
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-100 block w-full p-2"
              required
              value={vehiculeData.carte_carburant}
              onChange={(e) => setFormValue("carte_carburant", e.target.value)}
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="carte_bancaire"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Carte bancaire
            </label>
            <input
              type="text"
              id="carte_bancaire"
              placeholder=""
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-100 block w-full p-2"
              required
              value={vehiculeData.carte_bancaire}
              onChange={(e) => setFormValue("carte_bancaire", e.target.value)}
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="type_vehicule"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Type de véhicule
            </label>
            <OptionsSelector
              options={CAR_TYPES}
              value={vehiculeData.type_vehicule}
              onChange={(v) => {
                setFormValue("type_vehicule", v.value)
              }}
            />
          </div>

          <div className="text-left">
            <div className="flex items-center justify-between mt-2 mb-3">
              <label
                htmlFor="pochette_recu"
                className="text-sm font-medium text-gray-900 cursor-pointer"
              >
                Pochette reçue
              </label>
              <SwitchComponent
                id="pochette_recu"
                label="pochette_recu"
                checked={vehiculeData.pochette_recu === 1}
                onChange={(checked) => {
                  setFormValue("pochette_recu", checked ? 1 : 0);
                }}
                checkedColor="#098721"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <button
            type="button"
            onClick={() => onClose?.()}
            className="bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-5"
          >
            Annuler
          </button>
          <button
            type="submit"
            className={`cursor-pointer text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm px-14 py-2.5 text-center`}
            onClick={handleSubmit}
            disabled={isSubmitting}
          //title={`${}`}
          >
            <span className="mr-3">
              {!selectedData ? "Enregistrer" : "Modifier"}
            </span>
            {isSubmitting && <Loader />}
          </button>
        </div>
      </form>
    </ModalUI>
  );
}
