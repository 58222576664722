import React, { useEffect } from "react";
import { getCurrentDate } from "../../utils/Functions/otherFunctions";
import { useParams, useNavigate } from "react-router-dom";
import Head from "../HeadComponent/Head";

function ConditionGeneralComponent() {
    const navigate = useNavigate();
    const { EspaceExnet } = useParams();
    // if (["paris", "medical", "service"].indexOf(EspaceExnet) < 0) {
    //     window.location.href = "http://localhost:5173";
    // }

    return (
        <>
            <Head espace={EspaceExnet}>
                <title>{`Exnet | Condition générale de vente`}</title>
            </Head>
            <div
                className="my-3 card mx-auto p-4"
                role="document"
                style={{ width: "90%" }}
            >
                <div className={"card-body"}>
                    {" "}
                    <div className="">
                        {" "}
                        <h4
                            className=" text-center"
                            style={{
                                fontSize: "18.0pt",
                                fontFamily: "Roboto",
                                color: "black",
                            }}
                        >
                            CGV {EspaceExnet === "paris" && <>EXNET PARIS</>}
                            {EspaceExnet === "medical" && <>EXNET MEDICALS</>}
                            {EspaceExnet === "service" && <>EXNET SERVICES</>}
                        </h4>
                    </div>
                    <div className="my-2">
                        <section id="wrapper">
                            <div
                                className="container"
                                style={{ maxWidth: "70rem" }}
                            >
                                <div id="content">
                                    <div className="WordSection1">
                                        <p
                                            className="MsoNormal"
                                            align="right"
                                            style={{
                                                textAlign: "right",
                                                lineHeight: "10.0pt",
                                            }}
                                        >
                                            <b>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                        marginTop: "105px",
                                                    }}
                                                >
                                                    {getCurrentDate()}
                                                </span>
                                            </b>
                                        </p>
                                        <p className="MsoNormal">
                                            <b>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "18.0pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    ARTICLE 1 - DEFINITIONS
                                                </span>
                                            </b>
                                        </p>
                                        <p className="MsoNormal">
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Pour l’interprétation des
                                                présentes Conditions Générales
                                                de Vente, les définitions
                                                suivantes s'appliquent :
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginLeft: "35.0pt",
                                                textIndent: "-16.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                CGV : Conditions Générales de
                                                Vente{" "}
                                                {EspaceExnet === "paris" && (
                                                    <>EXNET PARIS</>
                                                )}
                                                {EspaceExnet === "medical" && (
                                                    <>EXNET MEDICALS</>
                                                )}
                                                {EspaceExnet === "service" && (
                                                    <>EXNET SERVICES</>
                                                )}
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginBottom: 3,
                                                marginLeft: ".5in",
                                                textIndent: "-17.95pt",
                                                lineHeight: "102%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Vendeur
                                                {EspaceExnet === "paris" && (
                                                    <>EXNET PARIS</>
                                                )}
                                                {EspaceExnet === "medical" && (
                                                    <>EXNET MEDICALS</>
                                                )}
                                                {EspaceExnet === "service" && (
                                                    <>EXNET SERVICES</>
                                                )}
                                                , son service commercial, toute
                                                personne de la société dûment
                                                habilitée à valider les
                                                prestations ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginBottom: 3,
                                                marginLeft: "35.0pt",
                                                textIndent: "-16.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Bordereau de Transport :
                                                document du Vendeur complété qui
                                                accompagne chaque Envoi ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginBottom: 3,
                                                marginLeft: ".5in",
                                                textAlign: "justify",
                                                textIndent: "-17.95pt",
                                                lineHeight: "101%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "101%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "101%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Client : l'expéditeur, le
                                                consignataire, le destinataire
                                                du colis, l'importateur, le
                                                détenteur du Bordereau de
                                                Transport, le réceptionnaire et
                                                les propriétaires du chargement,
                                                toute autre partie ayant un
                                                intérêt à cette expédition,
                                                l’utilisateur de myexnet.com ou
                                                toute personne au profit de
                                                laquelle le Vendeur fournit des
                                                Prestations ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginBottom: 3,
                                                marginLeft: "35.0pt",
                                                textIndent: "-16.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Transport : ensemble des
                                                opérations et prestations prises
                                                en charge par le Vendeur en
                                                relation avec l'expédition ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginBottom: 3,
                                                marginLeft: ".5in",
                                                textIndent: "-17.95pt",
                                                lineHeight: "102%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Autres Prestations : toute
                                                prestation exécutée et/ou
                                                organisée par le Vendeur et
                                                notamment le stockage, le tri,
                                                l’emballage, la maintenance, la
                                                manutention, sans que cette
                                                liste ne soit limitative ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginBottom: 3,
                                                marginLeft: ".5in",
                                                textAlign: "justify",
                                                textIndent: "-17.95pt",
                                                lineHeight: "101%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "101%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "101%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Prestation d’échange standard :
                                                prestation consistant en la
                                                livraison et la reprise de
                                                marchandise chez le Destinataire
                                                avec d’éventuelles prestations
                                                complémentaires, et notamment
                                                l’installation, le branchement
                                                ou l’aide à la prise en main et
                                                à l’utilisation du matériel
                                                livré, réalisées selon les
                                                instructions du Client ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginBottom: 3,
                                                marginLeft: "35.0pt",
                                                textIndent: "-16.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Délais : durée prévisionnelle
                                                d’exécution de la prestation
                                                exprimée en jours ouvrés ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginBottom: 3,
                                                marginLeft: "35.0pt",
                                                textIndent: "-16.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Colis : toute enveloppe,
                                                document ou paquet constituant
                                                une charge unitaire lors de la
                                                remise ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginBottom: 3,
                                                marginLeft: "35.0pt",
                                                textIndent: "-16.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Document : tout support
                                                contenant des données ou
                                                informations de toutes natures ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginBottom: 3,
                                                marginLeft: ".5in",
                                                textIndent: "-17.95pt",
                                                lineHeight: "102%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Envoi : Colis ou ensemble de
                                                Colis remis ensemble par un même
                                                Expéditeur au Vendeur pour une
                                                livraison ensemble chez un même
                                                destinataire, d'un lieu de
                                                chargement unique à un lieu de
                                                déchargement unique ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginBottom: 3,
                                                marginLeft: ".5in",
                                                textIndent: "-17.95pt",
                                                lineHeight: "104%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "104%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "104%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Matériels : équipements,
                                                contenants, emballages,
                                                fournitures, accessoires, objet
                                                de la vente ou toutes
                                                prestations de services
                                                afférentes à la vente ou la
                                                location.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "12.5pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <b>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "18.0pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    ARTICLE 2 – CHAMP
                                                    D’APPLICATION
                                                </span>
                                            </b>
                                        </p>
                                        <p className="MsoNormal">
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Les présentes CGV sont
                                                systématiquement adressées ou
                                                remises à chaque Client pour lui
                                                permettre de passer la commande.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".05pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Elles s’appliquent à toutes
                                                offres ou ventes de produit et
                                                de service par le Vendeur à ses
                                                Clients.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "11.55pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "105%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "105%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Les CGV sont réputées
                                                expressément agréées et
                                                acceptées par le Client, qui
                                                déclare en avoir eu une parfaite
                                                connaissance, étant précisé que
                                                les renseignements figurant dans
                                                les catalogues et autres
                                                documents techniques ou
                                                publicitaires ont seulement une
                                                valeur indicative.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "10.25pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "105%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "105%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                En toutes circonstances, les
                                                présentes CGV prévalent sur les
                                                conditions d’achat du Client. Le
                                                fait pour le Vendeur de ne pas
                                                faire application à un moment
                                                donné d’une quelconque
                                                disposition, ne peut
                                                s’interpréter comme valant
                                                renonciation à se prévaloir
                                                ultérieurement de ladite
                                                disposition.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "12.65pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <b>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "18.0pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    ARTICLE 3 - DROITS DE
                                                    PROPRIÉTÉ INTELLECTUELLE –
                                                    CONFIDENTIALITÉ
                                                </span>
                                            </b>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "108%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "108%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                3. 1. L’ensemble des droits de
                                                propriété industrielle et
                                                intellectuelle afférents aux
                                                produits sont conservés par le
                                                Vendeur. Ainsi, la vente de nos
                                                produits ne confère au Client
                                                aucune licence ni autorisation
                                                quelconque relative au droit de
                                                propriété industrielle ou
                                                intellectuelle se rapportant au
                                                produit vendu. Ainsi, le Client
                                                s’engage à ne jamais modifier,
                                                altérer, retirer la plaque du
                                                constructeur ou effacer les
                                                logos de la marque du Vendeur
                                                apposés sur les matériels ou les
                                                emballages ou contenants.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "10.25pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "102%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Par ailleurs, le Client
                                                reconnaît que les documentations
                                                mis à sa disposition par le
                                                Vendeur restent la propriété
                                                exclusive du Vendeur et ne
                                                peuvent en conséquence être
                                                utilisées, dupliquées, ou
                                                communiquées à des tiers sans
                                                autorisation écrite et préalable
                                                du Vendeur. Dans ce cadre, le
                                                Client assume seul les risques
                                                financiers et autres liés à
                                                quelque action en contrefaçon
                                                d’un tiers à son encontre ou à
                                                l’encontre de la société du
                                                Vendeur, liées ou découlant de
                                                l’utilisation ou la vente, par
                                                ce Client, de la marchandise
                                                vendue par le Vendeur.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "10.9pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "112%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "112%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                3. 2. Le Client reconnaît que
                                                certaines informations à
                                                caractère technique qui pourront
                                                lui être communiquées dans le
                                                cadre de la vente ou de la
                                                location des matériels ou
                                                emballages ou contenants peuvent
                                                avoir un caractère confidentiel.
                                                Dans un tel cas, le Client
                                                s’engage à prendre toutes
                                                mesures appropriées afin de
                                                protéger cette confidentialité
                                                et notamment de faire respecter
                                                <a name="page2" />
                                                cette confidentialité par ses
                                                salariés pour lesquels il se
                                                porte fort. La présente
                                                obligation de confidentialité
                                                restera en vigueur pendant un
                                                Délais de cinq (5) ans à compter
                                                de la livraison des Matériels.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "9.85pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "103%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "103%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                3.3 Le Client autorise le
                                                Vendeur à réaliser des prises de
                                                photos et vidéos des matériels
                                                livrés avant et après la
                                                réalisation de la prestation à
                                                des fins promotionnelles. Le
                                                Vendeur pourra reproduire et
                                                modifier ces photos en fonction
                                                des besoins et les exploiter sur
                                                tous les supports possibles
                                                (magazine, TV, web, mobile,
                                                etc.) existants et à venir.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "10.0pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <b>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "18.0pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    ARTICLE 4 - EXCLUSIONS
                                                </span>
                                            </b>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "99%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "99%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Le Vendeur ne sera pas tenu pour
                                                responsable si tout ou partie
                                                des Matériels est perdu,
                                                endommagé, retardé, livré à une
                                                mauvaise adresse ou qu’il n’est
                                                pas livré du fait :
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".05pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginLeft: ".5in",
                                                textAlign: "justify",
                                                textIndent: "-17.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                De circonstances indépendantes
                                                au Vendeur tels que le vice
                                                propre de la marchandise et/ou
                                                de son emballage, un événement
                                                relevant de la force majeure ou
                                                toute autre circonstance tels
                                                que tempêtes, inondations,
                                                incendies, épidémies,
                                                brouillard, givre, accident,
                                                grèves, péril aérien, trouble
                                                locaux, désordres du trafic
                                                aérien ou routier, ou
                                                ferroviaire, au plan national ou
                                                local, refus du destinataire ou
                                                destinataire absent, difficultés
                                                de transport ou
                                                d’approvisionnements essentiels
                                                ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".15pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginLeft: ".5in",
                                                textIndent: "-17.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Du non-respect par le Client de
                                                ses obligations ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginLeft: ".5in",
                                                textIndent: "-17.95pt",
                                                lineHeight: "104%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "104%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "104%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                De toute personne publique ou
                                                privée investie d’un pouvoir
                                                légal ou réglementaire,
                                                susceptible de retarder ou
                                                empêcher la prestation.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "14.5pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <b>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "18.0pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    ARTICLE 5 - RESTRICTIONS AU
                                                    TRANSPORT
                                                </span>
                                            </b>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    5.1 Matières Dangereuses
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "102%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                5.1.1 Le Vendeur refuse de
                                                prendre en charge toute matière
                                                dangereuse, au sens notamment
                                                des règlements ICAO I.T., IATA
                                                DGR, IMDG – Code ADR ou toute
                                                autre réglementation nationale
                                                ou internationale concernant le
                                                transport de Matières
                                                Dangereuses.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "10.9pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "101%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "101%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                5.1.2 Sur demande du Client, et
                                                à titre exceptionnel, le Vendeur
                                                peut toutefois accepter de
                                                prendre en charge des matières
                                                dangereuses. Cette acceptation
                                                devra être signifiée par écrit
                                                préalablement à la prise en
                                                charge des Marchandises. En
                                                toute hypothèse, les matières
                                                dangereuses ne seront acceptées
                                                que si les réglementations
                                                relatives à leur transport, et
                                                notamment celles visées à
                                                l’article 5.1.1, ainsi que les
                                                normes en vigueur concernant
                                                l'étiquetage, le marquage,
                                                l'emballage, et les instructions
                                                complémentaires éventuelles du
                                                Vendeur, sont respectées par le
                                                Client. Tous renseignements
                                                détaillés sur ce point sont
                                                disponibles auprès du Vendeur.
                                                Compte-tenu du caractère
                                                exceptionnel de cette
                                                prestation, le transport des
                                                matières dangereuses ouvre droit
                                                à un supplément de prix au
                                                profit du Vendeur.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "13.1pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    5.2 Règles de sécurité du
                                                    transport aérien
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "100%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "100%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                5.2.1 Le Client s’engage à
                                                vérifier que les Colis ne
                                                comportent aucune marchandise
                                                non autorisée au sens des
                                                réglementations énoncées au
                                                5.1.1. Pour les Transports
                                                Internationaux, le Client
                                                s’engage à donner une
                                                description complète et fidèle
                                                du contenu des Colis sur le
                                                Bordereau de Transport. Tout
                                                Colis est susceptible de subir
                                                un contrôle de sécurité sur
                                                écran, pouvant inclure
                                                l'utilisation de rayons X.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "11.05pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "105%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "105%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                5.2.2 Le Client déclare avoir
                                                préparé les Colis dans un local
                                                sûr, que ceci a été fait par
                                                lui-même ou du personnel salarié
                                                en qui il a confiance, et que
                                                les Colis ont été protégés de
                                                toute intervention non autorisée
                                                pendant les opérations de
                                                préparation, de stockage et de
                                                transport jusqu'à leur remise
                                                matérielle au Vendeur.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "12.45pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    5.3 Nature des marchandises
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "1.95pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    5.3.1 Déclaration et
                                                    information.
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "102%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Il appartient au Client de
                                                déclarer la nature des
                                                marchandises et d’informer le
                                                Vendeur concernant les
                                                contraintes légales et
                                                réglementaires de nature à en
                                                interdire ou en restreindre le
                                                transport. Le Client garantit le
                                                Vendeur contre tout préjudice
                                                consécutif au non-respect de
                                                cette obligation essentielle.
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    5.3.2 Marchandises
                                                    strictement prohibées.
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "104%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "104%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Le Client s’engage à ne remettre
                                                au Vendeur aucune marchandise
                                                dont le transport est interdit
                                                par la loi et/ou figurant dans
                                                la liste suivante : matériel de
                                                guerre ou assimilé, armes,
                                                stupéfiants. Etant précisé que
                                                cette liste n’est pas
                                                exhaustive.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "104%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "104%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    5.3.3 Marchandises pouvant
                                                    être prises en charge sous
                                                    conditions.
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "104%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "104%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Sous réserve du respect de
                                                certaines conditions définies
                                                par le Vendeur, le transport des
                                                catégories de marchandises
                                                suivantes peut être
                                                exceptionnellement réalisé :
                                                matières dangereuses, sous
                                                réserve des dispositions de
                                                l’article 5.1 ; tabac
                                                manufacturé&nbsp;; fourrure ;
                                                tissus ou prélèvements humains
                                                ou animaux ; produits non
                                                alimentaires auto réfrigérés par
                                                emballage spécial ; produits
                                                pharmaceutiques ; vins et
                                                spiritueux ; plantes ou fleurs
                                                fraîches ; marchandises à
                                                caractère pornographique ;
                                                téléphonie mobile et accessoires
                                                ; ordinateurs portables.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "10.25pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                5.3.4 Nonobstant les
                                                dispositions ci-dessus, le
                                                Vendeur est capable d’étudier
                                                toute demande de transport
                                                spécifique.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "104%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "104%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <b>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "18.0pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    ARTICLE 6. RESPONSABILITE
                                                    CONTRACTUELLE
                                                </span>
                                            </b>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "110%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "110%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Sous réserve des exclusions
                                                visées à l'article 10 ci-après,
                                                la responsabilité du Vendeur en
                                                cas de perte, dommage ou retard
                                                souffert par tout ou partie de
                                                l’Envoi du Client est limitée
                                                comme suit :
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "104%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "104%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    6.1 Transport aérien.
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Si tout ou partie de l’Envoi est
                                                expédié par avion et comporte
                                                une destination finale ou une
                                                escale dans un pays autre que le
                                                pays de départ, c'est la
                                                Convention de Varsovie de 1929
                                                ou la Convention de Montréal du
                                                28 mai 1999, révisée
                                                <span
                                                    style={{
                                                        background: "white",
                                                    }}
                                                >
                                                    28 décembre 2019
                                                </span>
                                                , qui s'applique. Ces
                                                conventions gouvernent et
                                                limitent la responsabilité du
                                                Vendeur en cas de perte, dommage
                                                ou retard subi par l’Envoi à 22
                                                DTS par Kg de marchandise.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "104%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "104%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <a name="page3" />
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    6.2 Transport routier
                                                    international.
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "100%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "100%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Si le transport est réalisé
                                                exclusivement par la route,
                                                dans, vers ou depuis un pays
                                                ayant signé la Convention de
                                                Genève du 19 mai 1956 (dite «
                                                CMR »), la responsabilité du
                                                Vendeur pour la perte ou les
                                                dommages subis par l’Envoi ou la
                                                partie endommagée de l’Envoi, se
                                                limite à 8,33 DTS par Kg de
                                                marchandise sinistrée. En cas de
                                                retard ayant entraîné un
                                                préjudice, il appartiendra en
                                                tout état de cause au Client
                                                d'établir la responsabilité du
                                                Vendeur, voit Art.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "13.15pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    6.3 Transport routier
                                                    national français.
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Conformément aux dispositions de
                                                la loi d’orientation des
                                                transports intérieurs du 30
                                                décembre 1982 (dite « LOTI »),
                                                puis au
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Décret no 2017-461 du 31 mars
                                                2017 relatif à l’annexe II à la
                                                partie 3 réglementaire du code
                                                des transports concernant le
                                                contrat type applicable aux
                                                transports publics routiers de
                                                marchandises pour lesquels il
                                                n’existe pas de contrat type,
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                en cas de transport routier
                                                réalisé exclusivement en France
                                                métropolitaine, la
                                                responsabilité du Vendeur pour
                                                perte ou avarie est limitée à :
                                            </span>
                                        </p>
                                        <p
                                            className="MsoListParagraphCxSpFirst"
                                            style={{ textIndent: "0in" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                33 Euros par Kg de marchandise
                                                sinistrée avec un maximum de
                                                1000 Euros par Colis sinistré
                                                pour les Envois de moins de 3
                                                tonnes ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoListParagraphCxSpLast"
                                            style={{ textIndent: "0in" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                20 Euros par Kg de marchandise
                                                sinistrée avec un maximum de
                                                3200 Euros par tonne de l'Envoi
                                                brut pour les Envois de 3 tonnes
                                                et plus.
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Pour ce qui est des envois sous
                                                température dirigée, cela est
                                                régit par le contrat-type
                                                «&nbsp;température
                                                dirigée&nbsp;» qui prévoit les
                                                limites suivantes&nbsp;:
                                            </span>
                                        </p>
                                        <p
                                            className="MsoListParagraphCxSpFirst"
                                            style={{ textIndent: "0in" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                23 Euros par Kg de marchandise
                                                sinistrée avec un maximum de 750
                                                Euros par Colis sinistré pour
                                                les Envois de moins de 3 tonnes
                                                ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoListParagraphCxSpLast"
                                            style={{ textIndent: "0in" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                14 Euros par Kg de marchandise
                                                sinistrée avec un maximum de
                                                4.000 Euros par tonne de l'Envoi
                                                brut pour les Envois de 3 tonnes
                                                et plus.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "11.8pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    6.4 Transport maritime
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                La convention de Bruxelles du 18
                                                Mai 1966 s’applique pour tout
                                                transport maritime.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".05pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                La responsabilité du Vendeur est
                                                limitée à 2 DTS par Kg de
                                                marchandise
                                                <span
                                                    style={{
                                                        background: "white",
                                                    }}
                                                >
                                                    brut de la
                                                </span>
                                                <b>marchandise</b>
                                                <span
                                                    style={{
                                                        background: "white",
                                                    }}
                                                >
                                                    perdue ou avariée
                                                </span>
                                                ou 666,67 DTS par Colis
                                                sinistré,
                                                <span
                                                    style={{
                                                        background: "#F0F5F7",
                                                    }}
                                                >
                                                    la plus forte des deux
                                                    limites s'appliquant
                                                </span>
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "11.8pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    6.5 Transport Multimodal
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "101%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "101%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                En cas de transport combinant
                                                plusieurs modes de transport
                                                (multimodal) et/ou en cas de
                                                responsabilité du fait personnel
                                                du commissionnaire, la
                                                limitation de responsabilité
                                                applicable est celle du mode de
                                                transport au cours duquel le
                                                dommage s'est produit.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "11.8pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "110%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "110%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                A défaut de pouvoir déterminer
                                                ledit mode de transport, la
                                                responsabilité du Vendeur est
                                                limitée à 17 DTS par Kg de
                                                marchandise sinistrée.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "12.5pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "11.8pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    6.6 Prestation d’échange
                                                    standard
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "104%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "104%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                En cas de Prestation d’échange
                                                standard, la responsabilité du
                                                Vendeur est limitée à 23 Euros
                                                par Kg de marchandise sinistrée
                                                avec un maximum de 750 Euros par
                                                Colis sinistré ou par Prestation
                                                organisée ou exécutée.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "12.5pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    6.7 Autres Prestations.
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                La responsabilité du Vendeur au
                                                titre des Autres Prestations est
                                                limitée à 10 000 Euros par fait
                                                générateur et par an.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "13.5pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    6.8 Indemnisation des
                                                    retards.
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "110%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "110%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                En toute hypothèse, la
                                                responsabilité du Vendeur au
                                                titre des retards est limitée
                                                aux plafonds définis par les
                                                articles
                                                <b>6.1 à 6.7</b>
                                                des présentes CGV.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "11.7pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    6.9 Transfert de propriété
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "104%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "104%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Dans tous les cas où le Vendeur
                                                procède à une indemnisation de
                                                la valeur matérielle de la
                                                marchandise sinistrée, il
                                                s’effectue un transfert de
                                                propriété de cette marchandise
                                                au profit du Vendeur.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "10.6pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "110%",
                                            }}
                                        >
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        lineHeight: "110%",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    6.10 Toute Prestation non
                                                    expressément et
                                                    préalablement acceptée
                                                </span>
                                            </u>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "110%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                par le Vendeur est réputée
                                                exécutée pour le compte et sous
                                                la responsabilité exclusive du
                                                bénéficiaire de cette
                                                Prestation.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "11.75pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <b>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "18.0pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    ARTICLE 7 - EMBALLAGE
                                                </span>
                                            </b>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "102%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Les emballages livrés ou mis à
                                                disposition par le Vendeur
                                                doivent être manipulés sans
                                                brutalité ni choc et ne doivent
                                                pas être exposés de façon
                                                prolongée au grand froid ou au
                                                soleil, ni demeurer à proximité
                                                d’une source quelconque de
                                                chaleur. La charge des risques
                                                est automatiquement transférée
                                                au Client dès sa prise de
                                                possession des marchandises.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "12.8pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    7.1 Conditions spécifiques à
                                                    la mise à disposition
                                                    d’emballages appartenant au
                                                    Vendeur
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "103%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "103%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Les emballages appartenant au
                                                Vendeur et/ou revêtus de l’une
                                                de ses marques ne peuvent être
                                                rechargés que dans ses entrepôts
                                                ou dans des établissements
                                                officiellement agréés par lui,
                                                ou par des personnes désignées
                                                par lui, et dans ce dernier cas
                                                exclusivement avec les produits
                                                qu’il leur fournit. La reprise
                                                des emballages vides est
                                                effectuée à l’occasion des
                                                livraisons.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "103%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "103%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "105%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "105%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Pour certaines prestations, le
                                                Vendeur met à disposition des
                                                emballages réutilisables. Le
                                                Client accepte et reconnait que
                                                ces emballages doivent être
                                                retournés immédiatement au
                                                Vendeur après la livraison chez
                                                le destinataire final des
                                                produits acheminés.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "12.45pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    7.2 Les emballages
                                                    réutilisables fournis par le
                                                    Vendeur restent la propriété
                                                    exclusive du Vendeur
                                                </span>
                                            </u>
                                        </p>
                                        <p className="MsoNormal">
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Tout emballage réutilisable
                                                fourni par le Vendeur qui :
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".5pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginLeft: ".5in",
                                                textIndent: "-17.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                -
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Serait détérioré ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginLeft: ".5in",
                                                textIndent: "-17.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                -
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Présenterait des pièces
                                                manquantes ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginLeft: ".5in",
                                                textIndent: "-17.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                -
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Ne serait pas restitué dans les
                                                Délaiss convenus par le Vendeur ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".05pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "102%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                sera facturé sans préavis de
                                                plein droit au tarif en vigueur
                                                dont le Client déclare avoir
                                                pris connaissance. En aucun cas
                                                il n’est accepté ni d’échange ni
                                                de compensation de Matériel dont
                                                les inscriptions et numéros ne
                                                correspondraient pas à ceux mis
                                                à disposition par le Vendeur.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "10.65pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "105%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "105%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Le Client, gardien des
                                                emballages et de leur contenu,
                                                est responsable de tout accident
                                                et dommage corporel ou matériel
                                                pouvant résulter de la
                                                non-observation des règles
                                                visées aux présentes CGV, et aux
                                                consignes de sécurité, et, à ce
                                                titre, garantira et indemnisera
                                                le Vendeur contre tout recours
                                                (y compris de tiers) à cet
                                                égard.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "10.65pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    7.3 Emballage fourni par le
                                                    Client
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "102%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Le Client reconnait expressément
                                                que la responsabilité du Vendeur
                                                ne saurait être mis en cause
                                                pour quelque raison que ce soit.
                                                En toute hypothèse, la
                                                responsabilité du Vendeur au
                                                titre des retards est limitée
                                                aux plafonds définis par les
                                                articles 6.1 à 6.7 des présentes
                                                CGV
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "10.65pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <b>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "18.0pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    ARTICLE 8 COMMANDE
                                                </span>
                                            </b>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    8.1 Bon de commande
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "99%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "99%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Le Client ou son représentant
                                                souhaitant faire apparaitre ses
                                                références de bon de commande
                                                sur la facture, s’engage à
                                                indiquer les références de bon
                                                de commande lors :
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".05pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginLeft: ".5in",
                                                textIndent: "-17.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                -
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                De la saisie de la demande de
                                                prestation dans la plateforme
                                                fournie par le Vendeur ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginLeft: ".5in",
                                                textIndent: "-17.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                -
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                De l’envoi du formulaire de
                                                demande d’enlèvement.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "10.65pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "105%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "105%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                En tout état de cause, le Client
                                                ou ses représentants s’engage à
                                                fournir ses références de bon de
                                                commande avant le début de la
                                                prestation, ou, au plus tard,
                                                avant la fin de la prestation.
                                                Faute de quoi, des frais de
                                                modifications de factures seront
                                                appliquées selon l’article 11.4
                                                Frais de modification de
                                                facture.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "105%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "105%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <a name="page5" />
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                8.2. Les commandes sont fermes
                                                et définitives dès qu’elles sont
                                                confirmées par écrit au sein du
                                                document « bon de
                                                commande&nbsp;»ou « Demande
                                                d’enlèvement » et qu’elles sont
                                                dûment datées et signées par le
                                                Client. Toute spécification
                                                particulière ou adaptation doit
                                                figurer expressément dans la
                                                commande.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "10.35pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "110%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "110%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                8.3. Le Vendeur s’engage à
                                                fournir les produits et services
                                                pour l’usage ou la finalité
                                                convenu tel que décrit dans
                                                l’offre commerciale ou le devis
                                                approuvé par le Client. Le
                                                Client déclare connaître le
                                                niveau technique de réalisation
                                                des prestations.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "9.85pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "102%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                8.4. Le Vendeur se réserve le
                                                droit de modifier les
                                                caractéristiques des prestations
                                                afin d’y apporter des
                                                adaptations mineures, dans la
                                                mesure où ces modifications
                                                n’altèrent pas les
                                                caractéristiques principales de
                                                la prestation. La prestation
                                                pourra le cas échéant être
                                                adaptée notamment pour tenir
                                                compte des adaptations
                                                nécessaires au Client dans la
                                                réalisation de la prestation. Le
                                                Client ne peut demander la
                                                résolution de la vente ou
                                                rechercher la responsabilité du
                                                Vendeur en cas de modifications
                                                des caractéristiques initiales
                                                résultant d’une modification
                                                d’une réglementation propre à
                                                l’industrie du secteur du
                                                Client.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "10.9pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "105%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "105%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                8.5. Toute modification de
                                                l’offre commerciale demandée par
                                                le Client après la réception par
                                                le Vendeur de la commande signée
                                                ne liera le Vendeur qu’avec son
                                                accord écrit express et pourra
                                                entraîner une révision du prix
                                                initialement convenu ainsi
                                                qu’une modification des Délaiss
                                                de réalisation de la prestation
                                                et, le cas échéant, des autres
                                                conditions particulières de la
                                                vente.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "10.5pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "103%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "103%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                8.6. Le Client a la charge de
                                                l’obtention des documents ou
                                                autorisations nécessaires à la
                                                réalisation de la prestation. A
                                                défaut, la charge de l’obtention
                                                desdits documents au Vendeur
                                                nécessitera un accord écrit de
                                                ce dernier et, l’intégralité des
                                                frais correspondants seront à la
                                                charge du Client.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "10.75pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "110%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "110%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                8.7 Modification de la commande.
                                                Toute modification à
                                                l’initiative du Client,
                                                intervenue après le début de la
                                                prestation, entraine un
                                                changement tarifaire en fonction
                                                du changement.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "9.85pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "107%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "107%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                La modification du centre de
                                                facturation après le début de la
                                                prestation entraine une
                                                facturation forfaitaire de
                                                suivant le tarif en vigueur.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "10.0pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <b>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "18.0pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    ARTICLE 9 - REALISATION DE
                                                    LA PRESTATION
                                                </span>
                                            </b>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "100%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "100%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                La marchandise est reçue, agréée
                                                et prise en charge par le Client
                                                dès sa livraison, c’est -à-dire
                                                dès réception à l’adresse
                                                convenue, si elle est
                                                transportée par le Vendeur ou un
                                                mandataire désigné par lui, ou
                                                dès sa mise à disposition sur le
                                                quai ou l’aire de chargement du
                                                dépôt du Vendeur ou de tout
                                                autre de ses représentants si
                                                elle est enlevée par le Client
                                                ou l’un de ses mandataires.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "11.05pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "103%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "103%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                En cas de refus des marchandises
                                                par le Destinataire, comme en
                                                cas de défaillance de ce dernier
                                                pour quelque cause que ce soit,
                                                tous les frais initiaux et
                                                supplémentaires dus et engagés
                                                pour le compte de la marchandise
                                                resteront à la charge du Client.
                                                En cas de mauvaise adresse
                                                communiquée par le Client, le
                                                Transporteur et le Vendeur ne
                                                pourront être tenus responsables
                                                de l’absence de livraison ou du
                                                retard occasionné.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "10.5pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "110%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "110%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Le Vendeur remet au Client des
                                                consignes de sécurité, et tient
                                                à sa disposition, selon la
                                                réglementation en vigueur, une
                                                fiche de données de sécurité,
                                                concernant le ou les produits
                                                objets de la livraison ou du
                                                contrat.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "11.05pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    9.1. Glace carbonique
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "115%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "115%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                La glace carbonique fournie
                                                n’est jamais reprise, ni en
                                                totalité, ni en partie. En cas
                                                de non-conformité de celle-ci,
                                                le Vendeur s’engage à remplacer
                                                le produit par un autre produit
                                                répondant aux spécificités
                                                convenues (hors sublimation de
                                                la carbonique).
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "11.05pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "110%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "110%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Sauf conditions spécifiques, les
                                                Délaiss de livraison ne sont
                                                donnés qu’à titre indicatif,
                                                sans engager la responsabilité
                                                du Vendeur en cas de retard pour
                                                quelque cause que ce soit.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "9.65pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "105%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "105%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Pour les colis transportés avec
                                                de la glace carbonique
                                                uniquement, en cas de livraison
                                                dans un Délais supérieur à trois
                                                (3) jours ouvrés au-delà du
                                                Délais convenu et accepté par le
                                                Vendeur, un remboursement égal à
                                                100% du prix de la prestation de
                                                transport (emballages, droits,
                                                taxes et divers exclus) est
                                                effectué par le Vendeur.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "11.05pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    9.2. Prestations de services
                                                    logistiques sous température
                                                    dirigée
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "102%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Le Client s’engage à fournir au
                                                Vendeur tous les renseignements
                                                utiles sur le produit transporté
                                                pour permettre la mise en œuvre
                                                de la prestation. A défaut, ou
                                                en cas d’informations imprécises
                                                ou incomplètes, le Vendeur se
                                                réserve le droit de refuser la
                                                prestation.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "102%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "102%",
                                            }}
                                        >
                                            <a name="page6" />
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                La mise en place du produit dans
                                                l’Emballage fourni par le
                                                Vendeur est exécutée par le
                                                Client sous sa seule
                                                responsabilité, sauf conditions
                                                particulières.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".05pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "104%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "104%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Les produits sous température
                                                dirigée avec un emballage fourni
                                                par le Client ne peuvent-être
                                                traités spécifiquement tel que
                                                défini par l’articles 7.3 des
                                                présentes CGV
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "12.25pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    9.3. Produit transporté
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "100%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "100%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Le Vendeur n’est en aucun cas
                                                responsable du produit
                                                transporté, le Client étant seul
                                                responsable de ce dernier et des
                                                éventuels dommages causés par le
                                                produit. Seuls les produits
                                                mentionnés par le Client sur son
                                                bon de commande, et acceptés par
                                                le Vendeur sont autorisés à être
                                                introduits dans les Matériels
                                                livrés par le Vendeur. Il est
                                                formellement interdit d’employer
                                                ces Matériels à un autre usage
                                                que celui précisé par le Client
                                                avant expédition, et notamment
                                                d’y introduire ou de laisser
                                                pénétrer tout autre produit.
                                                Sauf conditions spécifiques
                                                mentionnées dans l’offre ou le
                                                devis, le Vendeur ne procède à
                                                aucune indemnisation pour les
                                                dommages subis par les produits
                                                liés à une variation de
                                                température du colis.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "11.15pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "102%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Pour engager la responsabilité
                                                du Vendeur, en cas de dommages
                                                subis par les Matériels (pertes,
                                                avaries ou retard), le
                                                Destinataire ou le
                                                Réceptionnaire procède à des
                                                réserves immédiates,
                                                significatives et écrites sur le
                                                document de transport et
                                                confirme ces réserves par lettre
                                                recommandée dans les trois (3)
                                                jours ouvrables qui suivent,
                                                faute de quoi aucun recours ne
                                                peut être exercé à l’encontre du
                                                Vendeur.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "10.9pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "105%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "105%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                En cas de perte ou avarie, si la
                                                responsabilité du Vendeur est
                                                engagée, pour quelque cause et à
                                                quelque titre que ce soit, elle
                                                est limitée à la réparation du
                                                seul dommage matériel direct
                                                résultant de la perte ou de
                                                l’avarie, à l’exclusion de tout
                                                autre dommages et intérêts ou
                                                dommages immatériels, sans
                                                pouvoir excéder les limitations
                                                suivantes fixées en article 6.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "10.25pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "112%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "112%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Lorsque la valeur des
                                                marchandises, objet du contrat,
                                                excède les limites de
                                                responsabilité fixées en article
                                                6, le Client a la faculté de
                                                souscrire une valeur déclarée
                                                qui, fixée par lui et acceptée
                                                par le Vendeur, élève les
                                                limitations d’indemnisation pour
                                                pertes ou avaries, au montant de
                                                ladite valeur déclarée et
                                                entraîne le paiement d’un
                                                supplément de prix, tel défini
                                                en article 13.5
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "9.7pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                L’indemnisation est établie
                                                toutes taxes comprises en cas de
                                                perte et hors taxes en cas de
                                                vol ou d’avarie avec dépôt de
                                                plainte.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "10.0pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <b>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "18.0pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    ARTICLE 10 - RESPONSABILITE
                                                    CONTRACTUELLE / GARANTIES
                                                    ASSURANCES
                                                </span>
                                            </b>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "101%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "101%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                La garantie accordée par le
                                                Vendeur est limitée au seul
                                                remplacement à l’identique ou
                                                comparable des Matériels ou des
                                                composants de ces Matériels
                                                présentant un vice caché ou un
                                                défaut de fabrication avéré.
                                                Sont exclus de la garantie tout
                                                autre dédommagement du type
                                                prise en charge, déplacement,
                                                indemnité à raison de préjudices
                                                matériels ou immatériels, perte
                                                d’information, perte
                                                d’exploitation ou autre
                                                préjudice de quelque nature que
                                                ce soit. Chaque partie souscrira
                                                les assurances nécessaires et
                                                suffisantes pour couvrir sa
                                                responsabilité civile dans le
                                                cadre de l’exécution des
                                                obligations qui lui incombent.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "10.7pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "102%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Le Client doit prendre toutes
                                                dispositions pour assurer
                                                l’évacuation, l’élimination et
                                                le traitement ou la mise à la
                                                décharge de tout produit,
                                                matière, ou substance, soit
                                                confié au Vendeur pour
                                                prestation de transfert, soit
                                                résultant de l’exécution par le
                                                Vendeur de la fourniture ou des
                                                prestations, et qui seraient
                                                considérées comme des matières
                                                dangereuses en vertu de
                                                disposition légale,
                                                réglementaire, nationale,
                                                régionale, municipale,
                                                communautaire, vers un site
                                                d’évacuation, élimination,
                                                traitement ou décharge de
                                                matières dangereuses, dûment
                                                agréé à cet effet par les
                                                autorités concernées.
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                En tout état de cause, la
                                                responsabilité du Vendeur ne
                                                pourra être recherchée lorsqu’il
                                                y a :
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".5pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginLeft: ".5in",
                                                textIndent: "-17.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Faute, négligence, omission ou
                                                défaillance du Client ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginLeft: ".5in",
                                                textIndent: "-17.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                La réalisation de tout évènement
                                                indépendant de la volonté du
                                                Vendeur tel que défini à
                                                l’article 9 ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginLeft: ".5in",
                                                textIndent: "-17.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Le fait d’un tiers sur lequel le
                                                Vendeur n’a aucun pouvoir de
                                                contrôle et de surveillance.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "102%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Dans la mesure où le Client
                                                démontrerait avoir subi un
                                                préjudice du fait du Vendeur, la
                                                réparation de tous dommages, de
                                                quelque nature que ce soit, au
                                                titre de la responsabilité du
                                                Vendeur, ne pourra en aucun cas
                                                excéder le montant hors taxes
                                                des sommes perçues par le
                                                Vendeur au titre du contrat. En
                                                conséquence, le Client renonce à
                                                tout recours contre le Vendeur
                                                au-delà de ce montant et
                                                obtiendra de ses assureurs une
                                                renonciation à recours contre le
                                                Vendeur.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "10.9pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "105%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "105%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Les dommages de toutes natures
                                                causés aux personnels des
                                                parties restent à la charge de
                                                leurs employeurs. Ces
                                                dispositions ne portent pas
                                                atteinte aux droits ou actions
                                                dont pourraient également se
                                                prévaloir les victimes des
                                                accidents ou leurs ayants droits
                                                ou la Sécurité Sociale.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "10.0pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <b>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "18.0pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    ARTICLE 11 – TARIFS ET
                                                    CONDITIONS DE PAIEMENT
                                                </span>
                                            </b>
                                        </p>
                                        <p className="MsoNormal">
                                            <a name="page7" />
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    11.1. Tarifs des Prestations
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "100%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "100%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Les tarifs des Prestations sont
                                                disponibles auprès du Vendeur.
                                                Les tarifs publiés peuvent être
                                                modifiés à tout moment sous
                                                réserve du respect d’un préavis
                                                de trente (30) jours. Il est
                                                expressément stipulé que les
                                                dispositions de la législation
                                                française en matière
                                                d’ajustement du prix du
                                                transport s’appliquent pour
                                                toute opération de transport
                                                réalisée intégralement en France
                                                ainsi qu’à toute opération de
                                                transport au départ ou à
                                                destination de la France.
                                                Toutefois, à circonstances
                                                exceptionnelles, comme
                                                l’exception pandémique par
                                                exemple, de nouvelles charges
                                                et/ou des variations du prix au
                                                kilo peuvent s’ajouter au prix
                                                du transport, le Vendeur se
                                                verra dans l’obligation de les
                                                répercuter sur la facture de la
                                                prestation.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "11.05pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "102%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Pour les Transports
                                                internationaux, le poids retenu
                                                comme base de tarification est
                                                le plus élevé entre le poids
                                                réel et le poids volumétrique.
                                                Le poids volumétrique retenu
                                                étant de :
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginLeft: ".5in",
                                                textIndent: "-17.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                250 Kg/m3 pour les transports à
                                                destination de l’Europe ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "1.0pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginLeft: ".5in",
                                                textIndent: "-17.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                200 Kg/m3 pour les transports à
                                                destination du reste du monde.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "110%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "110%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "110%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "110%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Le terme « Volume » est utilisé
                                                si c'est le poids volumétrique
                                                qui est retenu. Le Vendeur se
                                                réserve le droit de contrôler
                                                les dimensions et le poids
                                                mentionnés sur les Bordereaux,
                                                et d'appliquer la mesure
                                                constatée.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "11.8pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    11.2. Redevance de sureté
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "100%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "100%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Dans le cadre de la prévention
                                                des actes terroristes et
                                                notamment de la réglementation
                                                du 29 décembre 1997 et des
                                                instructions de la Direction
                                                Générale de l’Aviation Civile,
                                                le Vendeur ayant la qualité
                                                d’«&nbsp;agent habilité&nbsp;»,
                                                appliquera pour chaque
                                                expédition une Redevance Sûreté
                                                Expédition, facturée en sus, et
                                                dont le montant et les modalités
                                                de calcul figurent dans les
                                                tarifs en vigueur disponibles
                                                sur demande.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "12.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    11.3 En cas de paiement :
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "12.25pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    11.3.1. Par l'expéditeur
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "102%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Celui-ci paie tous les frais de
                                                transport, le supplément de prix
                                                correspondant à la couverture
                                                d’assurance optionnelle (cf.
                                                article 12), les frais de
                                                dédouanement et tous les autres
                                                frais ; la TVA et les droits à
                                                l'importation, les droits de
                                                douane et tous les frais
                                                connexes seront facturés au
                                                Destinataire ou à l'importateur.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "10.65pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    11.3.2. Par le destinataire
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "104%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "104%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Celui-ci paie tous les frais de
                                                transport, le supplément de prix
                                                correspondant à la couverture
                                                d’assurance optionnelle (cf.
                                                article 12), les frais de
                                                dédouanement et autres frais, la
                                                TVA et les droits à
                                                l'importation ainsi que tous les
                                                frais connexes.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "10.6pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "105%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "105%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Sauf instruction contraire, les
                                                Prestations seront toujours
                                                facturées à l'Expéditeur.
                                                L'Expéditeur, le Destinataire et
                                                toutes parties au contrat sont
                                                conjointement et solidairement
                                                tenus responsables comme
                                                débiteurs principaux pour toute
                                                somme due, y compris en cas
                                                d'instruction de facturation du
                                                destinataire.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "12.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    11.4 Frais de modification
                                                    de facture
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "104%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "104%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Une tarification forfaitaire de
                                                40 € H.T. (quarante euros) est
                                                due pour toute modification de
                                                facture demandée par le Client
                                                après l’émission de celle-ci.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "12.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    11.6. Retard de paiement
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "104%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "104%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Le paiement est exigible à la
                                                réception de la facture et à son
                                                lieu d’émission. Tout retard
                                                dans le paiement entraine de
                                                plein droit, après mise en
                                                demeure, le versement de
                                                pénalités suivant les articles
                                                12.3 et 12.4 des présentes CGV.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "12.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    11.7 Paiement des factures
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Les factures émises en exécution
                                                des prestations sont payables au
                                                siège du Vendeur comme suit :
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".05pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginLeft: ".5in",
                                                textIndent: "-17.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                -
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Toutes taxes d'importation, TVA
                                                sur les marchandises, droits de
                                                douane et autres débours
                                                acquittés aux administrations
                                                seront payables à réception de
                                                marchandise ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".05pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginLeft: ".5in",
                                                textIndent: "-17.95pt",
                                                lineHeight: "102%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                -
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Les prestations et tous les
                                                autres frais sont payables à 15
                                                (quinze) jours maximum date de
                                                facturation, net d'escompte
                                                (selon la loi n°2006-10 du 5
                                                janvier 2006).
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "10.85pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                En cas de paiement anticipé, il
                                                n’est accordé aucun escompte.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "14.2pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    11.8 Facturation des
                                                    Transports Internationaux
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "110%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "110%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Pour les Transports
                                                internationaux, il est possible
                                                de demander la facturation du
                                                destinataire (personne physique
                                                exclue). Les instructions du
                                                Client pour une facturation du
                                                Destinataire, qui doivent
                                                figurer sur le Bordereau,
                                                entraîneront l'application des
                                                tarifs en vigueur dans le pays
                                                du Destinataire. Certains pays,
                                                de même que les solutions de
                                                transport de Fret, ne permettent
                                                pas
                                                <a name="page8" />
                                                l'application de ce système. Il
                                                appartient au Client de vérifier
                                                et de se renseigner auprès de
                                                l’agence locale du Vendeur dans
                                                les pays concernés. Pour les
                                                imports en Destinataire facturé
                                                en provenance du Vietnam, le
                                                prix appliqué est celui du pays
                                                Exportateur majoré des frais de
                                                dossiers.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "12.45pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    11.9 Paiement à l’échéance
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "101%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "101%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                A défaut de paiement à
                                                l’échéance, ou en cas de report
                                                d’échéance, les sommes restantes
                                                dues porteront immédiatement
                                                intérêts, de plein droit, à
                                                trois (3) fois le taux d’intérêt
                                                légal en vigueur, sans mise en
                                                demeure préalable et sans nuire
                                                à l’exigibilité. Et ce, sans
                                                préjudice des dommages intérêts
                                                et autres frais que le Vendeur
                                                se réserve le droit de réclamer.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "10.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "110%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "110%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Les pénalités de retard sont
                                                capitalisées au même titre que
                                                le principal. Tout paiement
                                                partiel sera d’abord imputé sur
                                                les intérêts dus, puis sur le
                                                capital de la facture la plus
                                                ancienne.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "9.85pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "102%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                En cas de prélèvement revenu
                                                impayé, il sera exigé le
                                                paiement de la somme
                                                correspondante par virement sous
                                                huit (8) jours ainsi qu’une
                                                preuve de ce virement bancaire.
                                                Le non-paiement dans les Délaiss
                                                convenus d'une seule échéance
                                                emportera sans formalité la
                                                déchéance du terme, le solde
                                                devenant immédiatement exigible
                                                même en cas d'acceptation
                                                d'effets. En outre, les
                                                conditions de paiement
                                                exceptionnellement consenties
                                                seront immédiatement annulées et
                                                remplacées par les Délaiss de
                                                paiement prévues à l’article
                                                11.5.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "10.9pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "110%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "110%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                En cas de cumul de créances
                                                privilégiées et chirographaires,
                                                les paiements du Client
                                                s'imputeront en premier lieu sur
                                                la partie non privilégiée des
                                                créances. Toutes sommes dues
                                                courront du jour de l'échéance
                                                jusqu'au paiement effectif.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "9.65pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Toute échéance non respectée
                                                entraîne de plein droit une
                                                facturation de 40 € (quarante
                                                euros) au titre des frais de
                                                relance.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "14.2pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    11.10 Droit de gage
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "100%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "100%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Le Vendeur conserve dans tous
                                                les cas un droit de gage
                                                emportant droit de rétention et
                                                de préférence général et
                                                permanent sur les Envois ainsi
                                                que sur tout document s’y
                                                rapportant, qui garantit
                                                l'intégralité des montants dus
                                                et exigibles en vertu de ce
                                                contrat ainsi que tous les
                                                frais, dépens, honoraires et
                                                autres dépenses encourues pour
                                                leur recouvrement. Il pourra
                                                s'exercer sur toute marchandise
                                                entre ses mains, que celle-ci
                                                soit ou non l'objet de la
                                                créance pour le recouvrement de
                                                laquelle le privilège est
                                                exercé. Le Vendeur exercera ce
                                                privilège de manière
                                                raisonnable, ce qui inclura la
                                                vente des marchandises mais n'y
                                                sera pas limité. Ce privilège
                                                existe sans préjudice de
                                                l'exercice des autres droits
                                                dont le Vendeur dispose.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "13.05pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    11.11 Procédure collective
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "102%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                En cas d’ouverture d’une
                                                procédure collective à
                                                l’encontre du Client, la
                                                poursuite des prestations est
                                                conditionnée au versement d’un
                                                dépôt de garantie à hauteur de
                                                l’encours de facturation moyen.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "10.0pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginRight: "211.0pt",
                                                lineHeight: "111%",
                                            }}
                                        >
                                            <b>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "18.0pt",
                                                        lineHeight: "111%",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    ARTICLE 12 – DEFAUT, RETARD,
                                                    RESILIATION
                                                </span>
                                            </b>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginRight: "211.0pt",
                                                lineHeight: "111%",
                                            }}
                                        >
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        lineHeight: "111%",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    12.1 Manquement
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".05pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "104%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "104%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                En cas de manquement grave de
                                                l’une des parties à ses
                                                obligations, l’autre partie peut
                                                mettre fin au contrat qu’il soit
                                                à durée déterminée ou
                                                indéterminée, sans préavis, par
                                                l’envoi d’une lettre recommandé
                                                avec avis de réception.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "14.2pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    12.2 Défaut de paiement
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                De convention expresse, et sauf
                                                report sollicité à temps et
                                                accordé par le Vendeur de
                                                manière particulière, le défaut
                                                de paiement l’échéance entraîne
                                                de plein droit et après mise en
                                                demeure préalable restée sans
                                                effet :
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginLeft: ".5in",
                                                textIndent: "-17.95pt",
                                                lineHeight: "97%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "97%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "97%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                L’exigibilité immédiate de
                                                toutes sommes restant dues, quel
                                                que soit le mode de règlement
                                                prévu ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginLeft: ".5in",
                                                textAlign: "justify",
                                                textIndent: "-17.95pt",
                                                lineHeight: "105%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "105%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "105%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                La facturation d’intérêts de
                                                retard calculés sur la base du
                                                taux de la Banque Centrale
                                                Européenne + 15 %, l’intérêt
                                                étant dû par le seul fait de
                                                l’échéance du terme contractuel
                                                ; le taux est calculé prorata
                                                temporis par période d’un (1)
                                                mois ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginLeft: ".5in",
                                                textIndent: "-17.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                La suspension de toutes les
                                                prestations en cours ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginLeft: ".5in",
                                                textAlign: "justify",
                                                textIndent: "-17.95pt",
                                                lineHeight: "102%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                •
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Le versement par le Client d’une
                                                indemnité forfaitaire selon les
                                                frais de recouvrement prévues à
                                                l’article 13.4. Le Vendeur se
                                                réserve le droit de demander une
                                                indemnisation complémentaire si
                                                les frais de recouvrement
                                                effectivement engagés dépassent
                                                ce montant.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginLeft: ".5in",
                                                textAlign: "justify",
                                                lineHeight: "102%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    12.3 Pénalités de retard de
                                                    paiement
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                En cas de retard de paiement, le
                                                taux de pénalités de retard est
                                                fixé à 15 (quinze) % par an.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "13.5pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    12.4 Frais de recouvrement
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "102%" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Une indemnité forfaitaire de 40
                                                € H.T. (quarante euros) est due
                                                au créancier pour frais de
                                                recouvrement, à l'occasion de
                                                tout retard de paiement.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "102%" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    lineHeight: "102%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <a name="page9" />
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Ce montant forfaitaire s'ajoute
                                                aux pénalités de retard, mais
                                                n'est pas inclus dans la base de
                                                calcul des pénalités.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "14.4pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <b>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "18.0pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    ARTICLE 13 – RECLAMATIONS
                                                </span>
                                            </b>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "104%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "104%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Pour être recevable, toute
                                                réclamation tendant à engager la
                                                responsabilité du Vendeur doit
                                                impérativement respecter la
                                                procédure suivante :
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "12.5pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    13.1 Réserves
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "104%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "104%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Dans tous les cas, le
                                                Destinataire ou le
                                                Réceptionnaire est tenu de
                                                procéder à des réserves
                                                immédiates, significatives et
                                                écrites sur le document de
                                                transport au moment de la
                                                livraison du Colis.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "12.5pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    13.1.2 Transports
                                                    internationaux
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "101%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "101%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Pour les Transports
                                                internationaux, à peine de
                                                forclusion, le Client doit
                                                adresser la réclamation au
                                                Vendeur par écrit dans les 21
                                                (vingt et un) jours suivant la
                                                livraison du Colis ou dans les
                                                21 (vingt et un) jours suivant
                                                la date à laquelle le Colis
                                                aurait dû être livré. Puis dans
                                                les 21 (vingt et un) jours
                                                suivants, le Client doit faire
                                                parvenir au Vendeur tous les
                                                documents concernant le Colis et
                                                la perte ou le dommage subi.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "12.75pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    13.1.3 Transports nationaux
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".95pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "100%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "100%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Pour les Transports nationaux, à
                                                peine de forclusion, le
                                                destinataire ou le
                                                réceptionnaire doit confirmer
                                                les réserves faites à la
                                                livraison par lettre recommandée
                                                avec accusé de réception dans
                                                les 3 (trois) jours ouvrables
                                                qui suivent. Dans le cas d’une
                                                livraison avant 8 (huit) heures
                                                dans un coffre et hors de la
                                                présence du Destinataire,
                                                celui-ci doit faire parvenir les
                                                réserves au Vendeur par
                                                télécopie avant midi, le jour
                                                même de la livraison litigieuse.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "12.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    13.2 Produits sous
                                                    température dirigée ou
                                                    contrôlée
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "101%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "101%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Les produits sous température
                                                dirigée ou contrôlée ne peuvent
                                                faire l’objet d’une réclamation
                                                pour détérioration uniquement
                                                dans le cas où le Client a
                                                commandé l’option d’une sonde de
                                                température (la sonde ou tout
                                                autre dispositif permettant de
                                                contrôler la température, et
                                                l’emballage sont obligatoirement
                                                fournis par le Vendeur) afin de
                                                garantir la traçabilité pendant
                                                toute la durée de l’acheminement
                                                à partir du point d’enlèvement.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "10.8pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "110%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "110%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Dans le cas où l’activation de
                                                la sonde est à la charge de
                                                l’Expéditeur et ce dernier
                                                aurait omis d’activer la sonde
                                                de température préalablement
                                                commandée, aucune réclamation ne
                                                sera acceptée pour
                                                détérioration.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "11.8pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    13.3 Limitation de
                                                    responsabilité
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "104%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "104%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                En tout état de cause, le Client
                                                ne peut réclamer une valeur
                                                supérieure au montant déclaré
                                                lors de sa demande d’assurance
                                                <i>ad-valorem</i>. A défaut,
                                                toute réclamation se limite sur
                                                la base définie à l’article 6.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "12.5pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    13.4 Avarie
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "104%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "104%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                En cas d’avarie, le Destinataire
                                                doit tenir la marchandise
                                                sinistrée (contenu et emballage)
                                                à la disposition du Vendeur pour
                                                une reprise éventuelle de
                                                celle-ci aux fins d’expertise.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "12.75pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    13.5 Assurances Ad Valorem -
                                                    Indemnisations
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "101%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "101%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                En cas de demande d’assurance
                                                <i>Ad Valorem</i>, le Client
                                                s’engage à fournir toutes les
                                                factures ou tout autre document
                                                justifiant son préjudice à
                                                hauteur de la valeur déclarée
                                                lors de la demande d’assurance
                                                complémentaire. Le Client
                                                reconnait que sa demande
                                                d’indemnisation peut être
                                                retardée ou refusée en cas de
                                                non-obtention des documents
                                                attendus.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "10.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "110%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "110%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Les demandes d’assurance Ad
                                                Valorem ne sont possible que
                                                pour les prestations Premium
                                                notamment du fait des risques
                                                évalués.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "11.8pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    13.6 Facture non acquittée
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "104%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "104%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Le Vendeur n'a aucune obligation
                                                d'agir au sujet d'une
                                                réclamation quelconque tant que
                                                la facture de transport n'a pas
                                                été réglée.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "12.5pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    13.7 Délais
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                A peine de forclusion, toute
                                                action en réparation à
                                                l’encontre du Vendeur doit être
                                                intentée par le Client dans un
                                                Délais d’un (1) an à compter :
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".05pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginLeft: ".5in",
                                                textIndent: "-17.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                -
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                De la date de livraison
                                                effective ou de la date à
                                                laquelle le colis aurait dû être
                                                livré dans le cadre de
                                                prestations telles que visées
                                                aux articles 6.1 à 6.5 ci-dessus
                                                ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".05pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginLeft: ".5in",
                                                textIndent: "-17.95pt",
                                                lineHeight: "104%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "104%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                -
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "104%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                De la date à laquelle la
                                                prestation a été ou aurait dû
                                                être réalisée dans le cadre de
                                                prestations d’échange standard
                                                telles que visées à l’article
                                                6.6 ci-dessus ;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginLeft: "35.0pt",
                                                textIndent: "-16.95pt",
                                            }}
                                        >
                                            <a name="page10" />
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                -
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                De la date du fait générateur du
                                                dommage survenu dans le cadre de
                                                l’exécution d’Autres Prestations
                                                telles que visées
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".7pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                marginLeft: "43.0pt",
                                                textIndent: "-6.95pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                à
                                                <span
                                                    style={{
                                                        font: '7.0pt "Times New Roman"',
                                                    }}
                                                />
                                            </span>
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                l’article 6.7 ci-dessus.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "13.5pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    13.8 Colis laissés pour
                                                    compte ou non identifiés
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "101%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "101%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Les Colis laissés pour compte ou
                                                non identifiés sont conservés
                                                par le Vendeur pendant 4
                                                (quatre) mois, Délais au-delà
                                                duquel, en l’absence de
                                                réclamation, le Vendeur peut en
                                                disposer. Les Colis présentant
                                                un danger pour la sécurité des
                                                biens et des personnes peuvent
                                                être détruits sans attendre
                                                l’expiration de ce Délais.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "12.85pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    13.9 Risques identifiés
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Tous risques identifiés et
                                                communiqués par le Vendeur, ne
                                                peuvent faire l’objet d’une
                                                demande de réclamation.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "11.45pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <b>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "18.0pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    ARTICLE 14 – AUTRES
                                                    DISPOSITIONS
                                                </span>
                                            </b>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    14.1 Clause nulle ou non
                                                    opposable
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "104%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "104%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Si l’une des clauses des CGV
                                                était considérée comme nulle ou
                                                non opposable, la validité et
                                                l’opposabilité des autres
                                                clauses ne serait pas affectée.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "12.5pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    14.2 Devis
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Les devis du Vendeur sont
                                                valables un (1) mois sauf
                                                dérogation confirmée par écrit.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "13.5pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <u>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "13.5pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    14.3 Offres
                                                </span>
                                            </u>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: ".75pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Les offres du Vendeur sont
                                                valables trois (3) mois sauf
                                                dérogation confirmée par écrit.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "11.45pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <b>
                                                <span
                                                    lang="FR"
                                                    style={{
                                                        fontSize: "18.0pt",
                                                        fontFamily: "Roboto",
                                                        color: "black",
                                                    }}
                                                >
                                                    ARTICLE 15 - JURIDICTION
                                                    COMPÉTENTE
                                                </span>
                                            </b>
                                        </p>
                                        <p className="MsoNormal">
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                15.1 De convention expresse
                                                entre les parties, les documents
                                                contractuels y compris les
                                                annexes sont soumis au droit
                                                français.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                lineHeight: "11.55pt",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{
                                                textAlign: "justify",
                                                lineHeight: "110%",
                                            }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    lineHeight: "110%",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                15.2 Toute contestation relative
                                                à la validité, l’interprétation,
                                                l’exécution et/ou la résiliation
                                                des documents contractuels sera
                                                de convention expresse de la
                                                compétence exclusive du tribunal
                                                de commerce du siège du Vendeur.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "9.85pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "9.85pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Fait à
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "9.85pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Date&nbsp;:
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "9.85pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Le Client (Nom, qualité)
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "9.85pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                        </p>
                                        <p className="MsoNormal">
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "13.5pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                déclare avoir pris connaissance
                                                des présentes Conditions
                                                Générales de Vente EXNETMEDICALS
                                                et y adhère sans réserve.
                                            </span>
                                        </p>
                                        <p
                                            className="MsoNormal"
                                            style={{ lineHeight: "9.85pt" }}
                                        >
                                            <span
                                                lang="FR"
                                                style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily: "Roboto",
                                                    color: "black",
                                                }}
                                            >
                                                Cachet et signature précédé de
                                                la mention «&nbsp;lu et
                                                approuvé&nbsp;»
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ConditionGeneralComponent;
