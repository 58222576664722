// create FacturationLine react service

import axios from "axios";
import TokenService from "../config/tokenService.js";

class TransportTypesService {

    constructor() {
        TokenService.setHeader();
    }

    getLists(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/type-transport/list`, data);
    }

    create(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/type-transport/create`, data);
    }

    update(FacturationLine) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/type-transport/update`, FacturationLine);
    }

    delete(FacturationLine) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/type-transport/delete`, FacturationLine);
    }

    getOpsLists(data) {
        return axios.get(`${process.env.REACT_APP_API_URL}/ops/type-transport/list`, data);
    }

}

const TransportType = new TransportTypesService()

export default TransportType;
