export const ExpeditionEnCourHeadParis = ['REF','ENLEV','EXP','Adresse','DEST','Adresse','LIVR','TRACK','DISPATCH','ACTION'];

export const ExpeditionEnLivreHeadParis = ['REF','ENLEV','EXP','Adresse','DEST','Adresse','LIVR','TRACK','DISPATCH','ACTION'];

export const ExpeditionAllHeadParis = ['REF','ENLEV','EXP','Adresse','DEST','Adresse',' LIVR','TRACK','DISPATCH','ACTION'];

export const ListeContacts = ['N°', 'CIVILITÉ', 'ENTREPRISE', 'NOM & PRÉNOM', 'PAYS', 'VILLE', 'Adresse', 'E-MAIL', 'OPTION', 'ACTION'];

export const ExpeditionArchiveHeadParis =['REF','ENLEV','EXP','Adresse','DEST','Adresse','LIVR','TRACK','DISPATCH','ACTION'];

export const ExpeditionFactureHeadParis = ['REF','ENLEV','EXP','Adresse','DEST','Adresse','LIVR','TRACK','DISPATCH','ACTION'];

export const TableauDetailHeadParis = ['DATE & HEURE','DESCRIPTION','POD','Chauffeur','Agent','ACTION'];

