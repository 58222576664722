import React, { useEffect, useState } from "react";
import { VerifyStep_3 } from "../../utils/Functions/verifyDropdown";
import { toast } from "react-toastify";
import { compareDate, compareTwoDate, isWorkingDay, urlSplit } from "../../utils/Functions/otherFunctions";
import TimeComponente from "../TimeComponente";
import { ErrorStore } from "../../store/inputError";
import { ExpeditionStore } from "../../store/expedition";
import { useAppContext } from "../../context";
import TypedeRoueSelector from "../Utils/Selectors/TypedeRoueSelector";
import Select from 'react-select';
import { ButtonComponent } from "../ButtonComponent";
import clsx from "clsx";

export const ShippingPickUpDelivery = ({
    shippingProcess,
    ExpeditionType,
    disabled = false
}) => {
    const [delais, setDelais] = useState(null);
    const [delaisBack, setDelaisBack] = useState(null);

    const { isAuth, setIsAuth } = useAppContext();

    const deliveryPickupData = ExpeditionStore((state) => state.deliveryPickupData);
    const setPickupDeliveryData = ExpeditionStore((state) => state.setPickupDeliveryData);
    const updatePickupDeliveryData = ExpeditionStore((state) => state.updatePickupDeliveryData);
    const resetPickupDeliveryData = ExpeditionStore((state) => state.resetPickupDeliveryData);
    const infoSupData = ExpeditionStore((state) => state?.infoSupData);
    const upadateSupInfo = ExpeditionStore((state) => state.upadateSupInfo);

    const nextStepper = ExpeditionStore((state) => state.nextStepper);
    const errorInformation = ErrorStore((state) => state.errorField);
    const setMessageError = ErrorStore((state) => state.setMessageError);
    const resetErrorField = ErrorStore((state) => state.resetErrorField);
    const updateCoursePackageData = ExpeditionStore((state) => state.updateCoursePackageData);


    const handleNext = () => {
        const { valid, error, errorData } = VerifyStep_3(deliveryPickupData);
        if (!error) {
            setMessageError({ ...errorData })
            toast.error(errorData.message, {
                position: "top-center",
                autoClose: 500,
            });

        } else {
            nextStepper()
        }
    };

    useEffect(() => {
        const tempsDepart = deliveryPickupData["pickup"]["time"].split(":");
        if (delais) {
            const { value, label, time } = delais;
            let delaisValue = time === "rien" ? 0 : parseInt(time)

            let duree =
                parseInt(tempsDepart[0]) * 60 +
                parseInt(tempsDepart[1]) +
                parseInt(delaisValue);

            let hour = (duree - (duree % 60)) / 60;
            let minut = duree % 60;

            let newHour = hour >= 10 ? hour : '0' + hour;
            let newMinute = minut >= 10 ? minut : '0' + minut;

            updatePickupDeliveryData("time", newHour + ":" + newMinute, "delivery", shippingProcess);
            updateCoursePackageData('delais', value)
        } else if (delaisBack) {
            const { value, label, time } = delaisBack;
            let delaisValue = time === "rien" ? 0 : parseInt(time)

            let duree =
                parseInt(tempsDepart[0]) * 60 +
                parseInt(tempsDepart[1]) +
                parseInt(delaisValue);

            let hour = (duree - (duree % 60)) / 60;
            let minut = duree % 60;

            let newHour = hour >= 10 ? hour : '0' + hour;
            let newMinute = minut >= 10 ? minut : '0' + minut;

            updatePickupDeliveryData("time", newHour + ":" + newMinute, "delivery", shippingProcess);
            updateCoursePackageData('delaisBack', value)
        }


    }, [deliveryPickupData["pickup"]["time"], delais, delaisBack]);

    return (
        <div className="col-span-2 gap-3 space-y-3 md:grid md:space-y-0 md:grid-cols-2 ">

            {ExpeditionType !== "import" && ExpeditionType !== "export" && (
                <>

                    <div className={""}>
                        {" "}
                        <TypedeRoueSelector
                            roue={shippingProcess === 'normal' ? infoSupData?.type_de_roue : infoSupData?.type_de_roueBack}
                            onChange={(v) => {
                                if (shippingProcess === 'normal') {
                                    upadateSupInfo("type_de_roue", v);
                                } else {
                                    upadateSupInfo("type_de_roueBack", v);
                                }

                            }}
                            showLabel={true}
                        //isDisabled={((parseInt(user["2_roues"]) === 1 && parseInt(user["vt"]) === 0) || (parseInt(user["2_roues"]) === 0 && parseInt(user["vt"]) === 1) ) ? true : false}
                        />

                        {((errorInformation.field === "type_de_roue" && shippingProcess === 'normal') || (errorInformation.field === "type_de_roueBack" && shippingProcess !== 'normal')) &&
                            <p className="mt-2 text-sm text-red-600" >
                                {errorInformation?.message}
                            </p>
                        }

                    </div>
                    {/* clsx({"col-span-2":isAuth?.userRole === "ops"}) */}
                    <div className={""}>
                        <div className="form-group">
                            <label htmlFor="field6">{"Délais de livraison".toUpperCase()}</label>
                            <Select
                                className="text-base"
                                options={
                                    parseInt(shippingProcess === 'normal' ? infoSupData?.type_de_roue?.value : infoSupData?.type_de_roueBack?.value) === 4
                                        ? delaisData4
                                        : delaisData
                                }
                                value={shippingProcess === 'normal' ? delais : delaisBack}
                                onChange={shippingProcess === 'normal' ? setDelais : setDelaisBack}
                                placeholder={"CHOIX DU DELAI DE LIVRAISON *".toUpperCase()}
                            />
                            {((errorInformation.field === "delais" && shippingProcess === 'normal') || (errorInformation.field === "delaisBack" && shippingProcess !== 'normal')) &&
                                <p className="mt-2 text-sm text-red-600" >
                                    {errorInformation?.message}
                                </p>
                            }
                            <small className="ml-3">
                                Délais pour Paris intra-muros
                            </small>
                        </div>
                    </div>
                </>
            )}

            <div>
                <DetailPickupDelivery type={shippingProcess === 'normal' ? 'pickup' : 'pickupBack'} shippingProcess={shippingProcess} />
            </div>

            <div>
                <DetailPickupDelivery type={shippingProcess === 'normal' ? 'delivery' : 'deliveryBack'} shippingProcess={shippingProcess} />
            </div>

            {shippingProcess === "normal" &&
                <>
                    <div>
                        <ButtonComponent title={"Précédent"} onClick={() => console.log("précédent")} />
                    </div>
                    <div>
                        <ButtonComponent title={"Suivant"} onClick={handleNext} />
                    </div>
                </>
            }


        </div>

    );
}

const DetailPickupDelivery = ({ type, shippingProcess }) => {

    const deliveryPickupData = ExpeditionStore((state) => state.deliveryPickupData);
    const updatePickupDeliveryData = ExpeditionStore((state) => state.updatePickupDeliveryData);

    const data = deliveryPickupData[type];



    const title = (type === "pickup" || type === "pickupBack") ? "Enlèvement".toUpperCase() : "Livraison".toUpperCase();
    const seconsTitle = shippingProcess === "normal" ? "" : "RETOUR".toUpperCase();



    const updatedata = (dataKey, value) => {
        if (dataKey === 'date') {

            if (type === "pickup") {
                if (compareDate(value)) {
                    updatePickupDeliveryData("date", value, type, shippingProcess);
                } else {
                    toast.error('La date sélectionnée est antérieure à la date actuelle.', {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }
            else {
                if (compareTwoDate(value, deliveryPickupData['pickup']["date"])) {
                    updatePickupDeliveryData("date", value, type, shippingProcess);
                } else {
                    toast.error('La date sélectionnée est antérieure à la date actuelle.', {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }

        }
        else if (dataKey === 'time') {
            let date = new Date(deliveryPickupData[type]["date"]);
            if (compareDate(date)) {
                updatePickupDeliveryData("time", value, type, shippingProcess);
            }
            else {
                toast.error("La date d'enlèvement doit être supérieure à la date actuelle.", {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }


        }
        updatePickupDeliveryData(dataKey, value, type, shippingProcess)
    }

    useEffect(() => {
        if (type === "pickup") {
            const today = new Date().toISOString().split('T')[0]; // Format YYYY-MM-DD
            updatedata("date", today); // Met à jour avec la date formatée
        } else {
            const today = new Date();
            const tomorrow = new Date(today);
            tomorrow.setDate(today.getDate() + 1);
            const tomorrowFormatted = tomorrow.toISOString().split('T')[0]; // Format YYYY-MM-DD

            updatedata("date", tomorrowFormatted);

        }
    }, [type]);

    return (
        <div className="card-box">
            <h4 className="text-center uppercase card-title">
                {title} {seconsTitle}
            </h4>

            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <input
                            // disabled={disabled} 
                            className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                            type="date"
                            placeholder={""}
                            value={data?.date}
                            pattern="\d{2}-\d{2}-\d{4}"
                            onChange={(e) => updatedata("date", e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <TimeComponente onChange={(v) => updatedata("time", v)} value={data?.time} placeholder={""} />

                </div>
            </div>

            <div className="">
                <textarea
                    rows="5"
                    cols="5"
                    className={"px-2 focus:border-gray-300 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-gray-300  focus:ring-2 text-base sm:leading-6 h-auto border-gray-300 focus:ring-gray-300 "}
                    placeholder={`Instructions ${type === "pickup" ? "d'" + title : "de " + title}`}
                    value={data?.instruction}
                    onChange={(e) =>
                        updatePickupDeliveryData("instruction", e.target.value, type, shippingProcess)
                    }
                ></textarea>
            </div>
        </div>
    )
}

const delaisData = [
    { value: "normal", label: " Normal (3 heures )", time: 180 },
    { value: "spx_1", label: " SPX (45 mins )", time: 45 },
    { value: "spx_2", label: " Sur rendez-vous", time: "rien" },
];

const delaisData4 = [
    { value: "spx_1", label: " SPX (1 heure 30 minutes)", time: 90 },
    { value: "normal", label: " Normal (3 heures )", time: 180 },
    { value: "spx_2", label: " Sur rendez-vous", time: "rien" },
];