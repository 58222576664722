export const trierTableau = (tableau, critere, ordre) => {


    return tableau.sort((a, b) => {
        let tableauCritee = critere?.split('.')
        let valueA;
        let valueB;
        if (tableauCritee?.length > 1) {
            if (a[tableauCritee[0]][tableauCritee[1]] && b[tableauCritee[0]][tableauCritee[1]]) {
                valueA = a[tableauCritee[0]][tableauCritee[1]];
                valueB = b[tableauCritee[0]][tableauCritee[1]];
            } else {
                valueA = a[tableauCritee[0]];
                valueB = b[tableauCritee[0]];
            }

        }
        else {
            valueA = a[tableauCritee[0]];
            valueB = b[tableauCritee[0]];
        }

        if (ordre === 'asc') {
            if (valueA < valueB) {
                return -1;
            }
            if (valueA > valueB) {
                return 1;
            }
            return 0;
        } else if (ordre === 'desc') {
            if (valueA > valueB) {
                return -1;
            }
            if (valueA < valueB) {
                return 1;
            }
            return 0;
        }
    });
}

