import React, { useState } from "react";
import Select from "react-select";
import { ShippingDetails } from "../../store/shippingDetails";

const services = [
    { value: "pole", label: "Pôle" },
    { value: "presse", label: "Presse VIP" },
    { value: "transport", label: "Transport" },
    { value: "haute", label: "Haute Couture" },
    { value: "autre", label: "Autre (à préciser)" },
];

const operationTypes = [
    { value: "import", label: "Import" },
    { value: "export", label: "Export" },
    { value: "introduction", label: "Introduction" },
    { value: "tiers", label: "Tiers" },
    { value: "dedie", label: "Dédié" },
];

const transportModes = [
    { value: "air", label: "Air" },
    { value: "road", label: "Road" },
    { value: "sea", label: "Sea" },
];

const vehiculeTypes = [
    { value: "voiture", label: "4 Roues" },
    { value: "moto", label: "2 Roues" },
];

const numeroTypes = [
    { value: "EX-A", label: "Numéro EX-A" },
    { value: "IM-A", label: "Numéro Numéro IM-A" },
]

function ClassificationComponent({ data }) {

    const updateDetailInfoSupplement = ShippingDetails((state) => state.updateDetailInfoSupplement);

    const [service, setService] = useState(null);
    const [operation, setOperation] = useState(null);
    const [transport, setTransport] = useState(null);
    const [vehicule, setVehicule] = useState(null);
    const [douaneType, setDouaneType] = useState(null);
    const [numeroType, setnumeroType] = useState("");

    const service_cdc = services.find((c) => c?.value === data.service_cdc);
    const type_operation = operationTypes.find((c) => c?.value === data.type_operation);
    const mode_de_transport = transportModes.find((c) => c?.value === data.mode_de_transport);
    const type_vehicule = vehiculeTypes.find((c) => c?.value === data.type_vehicule);
    const type_de_douane = transportModes.find((c) => c?.value === data.type_de_douane);
    const type_de_numero = numeroTypes.find((c) => c?.value === data.type_de_numero);

    return (
        <div className="p-2">
            <div className={"md:grid grid-cols-3 lg:grid-cols-4 gap-2"}>
                <div className="my-0 col-md-2">
                    <div className="form-group">
                        {/* <label htmlFor="service_cdc" className="mb-1 form-label">Service CDC</label> */}
                        <Select
                            className="w-full bg-transparent border border-gray-500 rounded-lg outline-none basic-single selection"
                            classNamePrefix="select"
                            defaultValue={service}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={services}
                            placeholder={"Service CDC".toUpperCase()}
                            value={service_cdc}
                            onChange={(v) => {
                                updateDetailInfoSupplement("service_cdc", v.value)
                            }}
                        />
                    </div>
                </div>
                <div className="my-0 col-md-2">
                    <div className="form-group">
                        {/* <label htmlFor="type_operation" className="mb-1 form-label">Type d'opération</label> */}
                        <Select
                            classNames="w-full bg-transparent border border-gray-500 rounded-lg outline-none basic-single selection"
                            classNamePrefix="select"
                            defaultValue={operation}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={operationTypes}
                            placeholder={"Type d'opération".toUpperCase()}
                            value={type_operation}
                            onChange={(v) => {
                                updateDetailInfoSupplement("type_operation", v.value)
                            }}
                        />
                    </div>
                </div>
                <div className="my-0 col-md-2">
                    <div className="form-group">
                        {/*  <label htmlFor="mode_de_transport" className="mb-1 form-label">Mode de transport</label> */}
                        <Select
                            className="w-full bg-transparent border border-gray-500 rounded-lg outline-none selection"
                            classNamePrefix="select"
                            defaultValue={transport}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={transportModes}
                            placeholder={"Mode de transport".toUpperCase()}
                            value={mode_de_transport}
                            onChange={(v) => {
                                updateDetailInfoSupplement("mode_de_transport", v.value)
                            }} />
                    </div>
                </div>
                <div className="my-0 col-md-2">
                    <div className="form-group">
                        {/*  <label htmlFor="type_vehicule" className="mb-1 form-label">Type de véhicule</label> */}
                        <Select
                            className="w-full bg-transparent border border-gray-500 rounded-lg outline-none basic-single selection"
                            classNamePrefix="select"
                            defaultValue={vehicule}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={vehiculeTypes}
                            placeholder={"Type de véhicule".toUpperCase()}
                            value={type_vehicule}
                            onChange={(v) => {
                                updateDetailInfoSupplement("type_vehicule", v.value)
                            }} />
                    </div>
                </div>

                <div className="my-0 col-md-2">
                    <div className="form-group">
                        {/* <label htmlFor="type_de_douane" className="mb-1 form-label">Type de douane</label> */}
                        <Select
                            className="w-full bg-transparent border border-gray-500 rounded-lg outline-none basic-single selection"
                            classNamePrefix="select"
                            defaultValue={transport}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={transportModes}
                            placeholder={"Type de douane".toUpperCase()}
                            value={type_de_douane}
                            onChange={(v) => {
                                updateDetailInfoSupplement("type_de_douane", v.value)
                            }} />
                    </div>
                </div>
                <div className="my-0 col-md-2">
                    <div className="form-group">
                        {/* <label htmlFor="numero_facture" className="mb-1 form-label">Numéro Facture / Carnet ATA</label> */}
                        <input
                            type="text"
                            className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                            id="numero_facture"
                            placeholder={"Numéro Facture / Carnet ATA".toUpperCase()}
                            value={data.numero_facture}
                            onChange={(e) => {
                                updateDetailInfoSupplement("numero_facture", e.target.value)
                            }}
                        />
                    </div>
                </div>

                <div className="my-0 col-md-2">
                    <div className="form-group">
                        {/* <label htmlFor="aeroport_depart" className="mb-1 form-label">Aéroport Départ</label> */}
                        <input
                            type="text"
                            className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                            id="aeroport_depart"
                            placeholder={"Aéroport Départ".toUpperCase()}
                            value={data.aeroport_depart}
                            onChange={(e) => {
                                updateDetailInfoSupplement("aeroport_depart", e.target.value)
                            }}
                        />
                    </div>
                </div>
                <div className="my-0 col-md-2">
                    <div className="form-group">
                        {/* <label htmlFor="aeroport_arrive" className="mb-1 form-label">Aéroport Arrivée</label> */}
                        <input
                            type="text"
                            className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                            id="aeroport_arrive"
                            placeholder={"Aéroport Arrivée".toUpperCase()}
                            value={data.aeroport_arrive}
                            onChange={(e) => {
                                updateDetailInfoSupplement("aeroport_arrive", e.target.value)
                            }}
                        />
                    </div>
                </div>

                <div className="my-0 col-md-2">
                    <div className="form-group">
                        {/* <label htmlFor="type_de_numero" className="mb-1 form-label">Type de Numéro</label> */}
                        <Select
                            className="w-full bg-transparent border border-gray-500 rounded-lg outline-none basic-single selection"
                            classNamePrefix="select"
                            defaultValue={numeroType}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={numeroTypes}
                            placeholder={"Type de Numéro".toUpperCase()}
                            value={type_de_numero}
                            onChange={(e) => {
                                updateDetailInfoSupplement("type_de_numero", e.value)
                            }} />
                    </div>
                </div>
                <div className="my-0 col-md-2">
                    <div className="form-group">
                        {/* <label htmlFor="numero_bon_vert" className="mb-1 form-label">N° bon vert</label> */}
                        <input
                            type="text"
                            className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                            id="numero_bon_vert"
                            placeholder={"N° bon vert".toUpperCase()}
                            value={data.numero_bon_vert}
                            onChange={(e) => {
                                updateDetailInfoSupplement("numero_bon_vert", e.target.value)
                            }}
                        />
                    </div>
                </div>
                <div className="my-0 col-md-2">
                    <div className="form-group">
                        {/* <label htmlFor="numero_commande" className="mb-1 form-label">N° commande</label> */}
                        <input
                            type="text"
                            className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                            id="numero_commande"
                            placeHolder={"N° commande".toUpperCase()}
                            value={data.numero_commande}
                            onChange={(e) => {
                                updateDetailInfoSupplement("numero_commande", e.target.value)
                            }}
                        />
                    </div>
                </div>
                <div className="my-0 col-md-2">
                    <div className="form-group">
                        {/* <label htmlFor="service" className="mb-1 form-label">Service</label> */}
                        <input
                            type="text"
                            className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                            id="service"
                            placeholder={"Service".toUpperCase()}
                            value={data.service}
                            onChange={(e) => {
                                updateDetailInfoSupplement("service", e.target.value)
                            }}
                        />
                    </div>
                </div>
                <div className="my-0 col-md-2">
                    <div className="form-group">
                    
                        <input
                            type="text"
                            className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                            id="droits_douane_taxe"
                            placeholder={"Droits de Douane et Taxes".toUpperCase()}
                            value={data.droits_douane_taxe}
                            onChange={(e) => {
                                updateDetailInfoSupplement("droits_douane_taxe", e.target.value)
                            }}
                        />
                    </div>
                </div>
                <div className="my-0 col-md-2">
                    <div className="form-group">
                        
                        <input
                            type="text"
                            className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                            id="co2_emis"
                            placeholder={"CO2 émis".toUpperCase()}
                            value={data.co2_emis}
                            onChange={(e) => {
                                updateDetailInfoSupplement("co2_emis", e.target.value)
                            }}
                        />
                    </div>
                </div>
                <div className="my-0 col-md-2">
                    <div className="form-group">
                        
                        <input
                            type="text"
                            className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                            id="mawb"
                            placeholder={"MAWB ou Numéro LTA".toUpperCase()}
                            value={data.mawb}
                            onChange={(e) => {
                                updateDetailInfoSupplement("mawb", e.target.value)
                            }}
                        />
                    </div>
                </div>
                <div className="my-0 col-md-2">
                    <div className="form-group">
                        
                        <input
                            type="text"
                            className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                            id="irc"
                            placeholder={"IRC".toUpperCase()}
                            value={data.irc}
                            onChange={(e) => {
                                updateDetailInfoSupplement("irc", e.target.value)
                            }}
                        />
                    </div>
                </div>
                <div className="my-0 col-md-2">
                    <div className="form-group">
                       
                        <input
                            type="text"
                            className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                            id="numero_facture"
                            placeholder={"Numéro de facture".toUpperCase()}
                            value={data.numero_facture}
                            onChange={(e) => {
                                updateDetailInfoSupplement("numero_facture", e.target.value)
                            }}
                        />
                    </div>
                </div>
                <div className="my-0 col-md-2">
                    <div className="form-group">
                        
                        <input
                            type="text"
                            className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                            id="numero_compte"
                            placeholder={"Numéro de compte".toUpperCase()}
                            value={data.numero_compte}
                            onChange={(e) => {
                                updateDetailInfoSupplement("numero_compte", e.target.value)
                            }}
                        />
                    </div>
                </div>
                <div className="hidden my-0 col-md-12">
                    <div className="form-group">
                        
                        <input
                            type="text"
                            className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                            id="volume"
                            placeholder={"Volume".toUpperCase()}
                            value={data.volume}
                            onChange={(e) => {
                                updateDetailInfoSupplement("volume", e.target.value)
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClassificationComponent;
