import { useState, useEffect } from 'react';
import factureService from '../services/facture/factureService';

const useAvoirLoader = (factureRef, interval = 5000) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await factureService.getShippingsWithAvoir({
                    facture_ref: factureRef
                });
                if (data.success) {
                    setData(data.data)
                }
            } catch (error) {
                setError(error);
            }
        };

        fetchData();

        const intervalId = setInterval(fetchData, interval);

        return () => clearInterval(intervalId);
    }, [factureRef, interval]);

    return { data, error };
};

export default useAvoirLoader;
