
import { create } from 'zustand'
import { trierTableau } from '../utils/filterFunction';

export const BillingCenterListSotre = create((set) => ({
    dataListConfig: {
        page: 1,
        totalItems: 0,
        perPageItems: 25,
        isSearch: false,
        searchValue: ""

    },
    dataList: [],
    data: {},
    setDataContent: (newData) => set((state) => ({
        data: { ...newData }
    })),
    modifyData: (newData) => set((state) => ({
        data: { ...state.data, ...newData }
    })),
    updateData: (id, newData) => set((state) => {
        const index = state.dataList.findIndex((item) => item.id === id);


        if (index === -1) {
            // Si aucune adresse avec cet ID n'est trouvée, on renvoie l'état inchangé
            return state;
        }

        // Cloner l'objet trouvé pour ne pas muter l'état directement
        const updatedData = { ...state.dataList[index], ...newData };

        // Créer une nouvelle liste d'adresses avec l'adresse mise à jour
        const updatedDataList = [...state.dataList];
        updatedDataList[index] = updatedData;

        // Retourner le nouvel état avec la liste d'adresses mise à jour
        return { dataList: [...updatedDataList] };

    }),
    deleteData: (id) => set((state) => {
        const index = state.dataList.findIndex((item) => item.id === id);


        if (index === -1) {
            // Si aucune adresse avec cet ID n'est trouvée, on renvoie l'état inchangé
            return state;
        }

        // Cloner l'objet trouvé pour ne pas muter l'état directement
        const updatedData = state.dataList.filter((item) => item?.id !== id);

        // Retourner le nouvel état avec la liste d'adresses mise à jour
        return { dataList: [...updatedData] };

    }),
    addData: (newData) => set((state) => ({
        dataList: [...state.dataList, { ...newData }]
    })),
    setDataList: (newData) => set((state) => ({
        dataList: [...newData]
    })),
    filterListe: (filterBy, filterOrder) => set((state) => {
        let newListe = trierTableau(state.dataList, filterBy, filterOrder)

        return { dataList: [...newListe] };
    }),
    setDataListConfig: (newData) => set((state) => ({
        dataListConfig: { ...newData }
    })),
    modifyDataListConfig: (newData) => set((state) => ({
        dataListConfig: { ...state.dataListConfig, ...newData }
    })),
    resetData: () => set((state) => {
        return { dataList: [] };
    })
}))