

export default [
    {
        index: 0,
        custom: false,
        key: "ref",
        prefix: true,
        type: "field",
    },
    {
        index: 1,
        custom: true,
        expression: {
            value: "{pickup_date} @@ {pickup_time}",
            type: "date",
            format: {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
            },
        },
        key: "",
        type: "field",
    },
    {
        index: 2,
        custom: true,
        expression: {
            value: "{expeditor.fullname}",
            type: "string",
        },
        key: "",
        type: "field",
    },
    {
        index: 3,
        custom: true,
        expression: {
            value: "{expeditor.postalCode} {expeditor.city} \n {expeditor.country}",
            type: "string",
        },
        key: "",
        type: "field",
    },
    {
        index: 4,
        custom: true,
        expression: {
            value: "{receiver.fullname}",
            type: "string",
        },
        key: "",
        type: "field",
    },
    {
        index: 5,
        custom: true,
        expression: {
            value: "{receiver.postalCode} {receiver.city} \n {receiver.country}",
            type: "string",
        },
        key: "",
        type: "field",
    },
    {
        index: 6,
        custom: true,
        expression: {
            value: "{delivery_date} \n {delivery_time}",
            type: "date",
            format: {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
            },
        },
        key: "",
        type: "field",
    },
    {
        index: 7,
        custom: true,
        expression: {
            value: "{suivis_details}",
            type: "string",
        },
        key: "",
        type: "field",
        empty: {
            html: 'Aucun Tracking'
        },
    },
    {
        index: 8,
        custom: true,
        expression: {
            value: "{chauffeur_nom}",
            type: "string",
        },
        key: "",
        type: "field",
        empty: {
            html: 'Aucun Dispatch'
        }
    },
    {
        index: 9,
        custom: true,
        option: [
            {
                text: "Editer",
                icon: "fa fa-eye",
                action: "click",
                parameter: "ref",
            },
            {
                text: "Assigner chauffeur",
                icon: "fa fa-truck",
                action: "click",
                parameter: "ref",
            },
            {
                text: "Envoyer mail",
                icon: "fa fa-envelope",
                action: "click",
                parameter: "ref",
            },
            {
                text: "Borderaux",
                icon: "fa fa-download ",
                action: "click",
                parameter: "ref",
            },
            {
                text: "Dispatch",
                icon: "fa fa-truck",
                action: "click",
                parameter: "ref",
                link:''
            },
        ],
        key: "",
        type: "option",
    },
]
