export default [
    {
        index: 0,
        custom: false,
        key: "id",
        type: "field",
    },
    {
        index: 1,
        custom: false,
        key: "value",
        type: "field",
    },
    {
        index: 2,
        custom: true,
        expression: {
            value: "{for_variation}",
            type: "radio",
        },
        key: "",
        type: "field",
    },
    {
        index: 3,
        custom: true,
        expression: {
            value: "{created_at}",
            type: "date",
            format: {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
            },
        },
        key: "",
        type: "field",
    },
    {
        index: 4,
        custom: true,
        expression: {
            value: "{is_actif}",
            type: "radio",
        },
        key: "",
        type: "field",
    },
    {
        index: 8,
        custom: true,
        option: [
            {
                text: "Editer",
                icon: "fa fa-eye",
                action: "click",
                parameter: "ref",
            },
        ],
        key: "",
        type: "option",
    },
]
