import React from 'react'
import Select from 'react-select';

const OptionsSelector = ({ multiple, options, label, value, onChange }) => {

    const val = options.find(option => option.value === value)
    //console.log({ val })
    return (
        <div className='w-full'>
            {label && <label htmlFor="date_echeance" className="block text-sm font-medium text-gray-900">{label}</label>}
            <Select
                options={options}
                value={val}
                onChange={onChange}
                isMulti={multiple}
                className='relative text-base w-100 z-90'
            />
        </div>
    )
}

export default OptionsSelector