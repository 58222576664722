import React, { useEffect, useState } from 'react'
import { TableLoader } from '../Utils/Loader'
import exploitation from '../../services/exploitation';
import { BiChevronLeft } from 'react-icons/bi';
import { Lists as ExpeditionLists } from "./TableRow";
import { useParams } from "react-router-dom";

export default function DriverShippings({ user, onBack }) {
    const [items, setItems] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [metadata, setMetadata] = useState({
        page: 1,
    });

    const { page, search } = metadata;

    const { milieu } = useParams()


    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true)
                const { data: resData } = await exploitation.getDriverShippings({
                    type: milieu,
                    per_page: 50,
                    page,
                    category: "all", // import ou export ou all #default all#
                    type_expedition: "all", // expedition ou course
                    user_id: user.id,
                }) //.getDrivers({ interface: type, page, per_page: 100 });
                //console.log({ resData })
                if (resData.success) {
                    const _items = resData.data.data
                    setItems(i => page === 1 ? _items : [...i, ..._items]);
                    if (page < resData.data.last_page) {
                        setMetadata(m => ({
                            ...m,
                            page: m.page + 1
                        }))
                    }
                }
            } catch (error) {
                console.error(error)
            } finally {
                setIsLoading(false)

            }
        })()
    }, [page, user]);


    return (
        <div>
            <div className="flex items-center mb-2 cursor-pointer font-bold" onClick={onBack}>
                <BiChevronLeft className='text-4xl w-auto' />
                <div className="text-2xl">Liste des expéditions de <span className="uppercase">{user.name}</span></div>
            </div>
            <div className="relative overflow-x-auto">
                <div className="card-box bg-white">
                    <ExpeditionLists
                        data={items}
                        setItems={setItems}
                        isLoading={isLoading && items.length === 0}
                    />
                </div>
            </div>
        </div>
    )
}
