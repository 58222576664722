

import React from 'react';

function LoaderCard() {
    return (
        <div className="w-full bg-white rounded-lg shadow-md animate-pulse">
            <div >
                <div className="space-y-1 animate-pulse">
                    <div className="w-full h-20 bg-gray-200 rounded-lg" />
                </div>
            </div>
        </div>


    );
}

export default LoaderCard;