
import React, { useState } from "react";
import {
    ArrowDownIcon,
    ArrowUpIcon,
} from "@heroicons/react/24/outline";
import LoaderCard from "./loader/LoaderCard";
import clsx from "clsx";
import { useAppContext } from "../context";
import LoaderTableau from "./tableauComponent/LoaderTableau";

export const NouvelTableau = ({
    headerList,
    isLoading,
    dataLength = 0,
    withAction = true,
    checkboxVisible,
    allowCheckBoxToggler,
    allSelected,
    toggleAllSelection,
    TableDataStore,
    children,
}) => {

    const filterListe = TableDataStore((state) => state.filterListe);
    const modifyDataListConfig = TableDataStore(
        (state) => state.modifyDataListConfig
    );
    const { isAuth, setIsAuth } = useAppContext();
    const { totalItems, perPageItems, page, isSearch, searchValue } =
        TableDataStore((state) => state.dataListConfig);

    const [filterOrder, setfilterOrder] = useState("desc");

    const handleFilter = (filterBy) => {
        let newOrder = filterOrder === "asc" ? "desc" : "asc";
        setfilterOrder(newOrder);
        filterListe(filterBy, newOrder);
    };

    const setnumberPerPage = (a) => {
        modifyDataListConfig({ perPageItems: a });
    };

    const updateDataListConfig = (a) => {
        modifyDataListConfig({ ...a });
    };

    let size = withAction ? 1 : 0
    return (
        <>
            <div className="py-2">
                <div className="relative w-full bg-white rounded-xl">

                    <div className="mb-5 overflow-auto rounded-md ">
                        <div className="inline-block min-w-full align-middle">
                            <table className="w-full divide-y divide-gray-300">
                                <thead className={clsx({
                                    "bg-paris": isAuth?.spaceName === "paris",
                                    "bg-medical": isAuth?.spaceName === "medical",
                                    "bg-service": isAuth?.spaceName === "service",
                                    "bg-admin": isAuth?.spaceName === "admin",
                                }, " py-3  rounded-t-md text-center")}>
                                    <tr className={" text-white  px-2"}>
                                        {checkboxVisible && allowCheckBoxToggler && (
                                            <th className="mr-2">
                                                <input
                                                    type="checkbox"
                                                    checked={allSelected}
                                                    onChange={toggleAllSelection}
                                                />
                                            </th>
                                        )}

                                        {headerList.map((item, index) => (
                                            <th
                                                onClick={() => handleFilter(item?.filterKey)}
                                                key={index + '_' + item?.label}
                                                scope="col"
                                                className={clsx("py-1 text-base text-center font-normalsm:pl-0 hover:cursor-pointer min-w-fit px-2 ")}
                                            >
                                                <div className={clsx("flex items-center gap-5 justify-center")}>
                                                    {" "}
                                                    {item?.label}
                                                    {filterOrder === "asc" ? (
                                                        <ArrowUpIcon className={clsx(
                                                            {
                                                                "text-paris": isAuth?.spaceName === "paris",
                                                                "text-medical": isAuth?.spaceName === "medical",
                                                                "text-service": isAuth?.spaceName === "service",
                                                                "text-admin": isAuth?.spaceName === "admin",
                                                            },
                                                            "w-4 h-4 text-white"
                                                        )} />
                                                    ) : (
                                                        <ArrowDownIcon className={clsx(
                                                            {
                                                                "text-paris": isAuth?.spaceName === "paris",
                                                                "text-medical": isAuth?.spaceName === "medical",
                                                                "text-service": isAuth?.spaceName === "service",
                                                                "text-admin": isAuth?.spaceName === "admin",
                                                            },
                                                            "w-4 h-4 text-white"
                                                        )} />
                                                    )}
                                                </div>
                                            </th>
                                        ))}

                                        {withAction && (
                                            // <th
                                            //     scope="col"
                                            //     className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                                            // >
                                            //     <span className="sr-only">Edit</span>
                                            <th

                                                scope="col"
                                                className={clsx("py-1 text-base text-center font-normalsm:pl-0 hover:cursor-pointer min-w-fit px-2 ")}
                                            >
                                                Action
                                            </th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody className="text-center divide-y divide-gray-200">
                                    {isLoading ? (
                                        <>

                                            <tr key={"index"}>
                                                <td colSpan={headerList.length + size}>
                                                    <LoaderTableau />
                                                </td>
                                            </tr>


                                        </>
                                    ) : (
                                        <>
                                            {dataLength > 0 ? (
                                                <>{children}</>
                                            ) : (
                                                <tr>
                                                    <td colSpan={headerList.length + size}>
                                                        <div classname={"py-4 rounded-lg bg-white"}>
                                                            <p
                                                                className={
                                                                    "my-4 text-xl font-medium text-center "
                                                                }
                                                            >
                                                                Pas de données actuellement !{" "}
                                                            </p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
};
