import React, { useEffect, useState } from "react";
import { VerifyStep_3 } from "../../utils/Functions/verifyDropdown";
import { toast } from "react-toastify";
import { compareDate, compareTwoDate, isWorkingDay, urlSplit } from "../../utils/Functions/otherFunctions";
import { useLocation } from 'react-router-dom';
import TimeComponente from "../TimeComponente";
import TimeComponente2 from "../TimeComponente2";

function NewExpeditionEnlevementLivraisonDetailComponent({
    kind,
    consignes,
    setConsignes,
    delais,
    disabled = false
}) {

    const [errorInformation, setErrorInformation] = useState({ field: '', message: '' })
    const {
        pickup_date,
        delivery_date,
        pickup_instruction,
        delivery_instruction,
        pickup_time,
        delivery_time,
    } = consignes;

    const { pathname } = useLocation()


    const updateConsignesEnl = (element, valeur) => {
        let newConsignes = { ...consignes };

        if (element === "date") {
            if (compareDate(valeur)) {
                newConsignes["pickup_date"] = valeur;
                setConsignes(newConsignes);
            } else {
                toast.error('La date sélectionnée est antérieure à la date actuelle.', {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }

        } else if (element === "heure") {
            var dateActuelle = new Date();
            var heureActuelle = dateActuelle.getHours() >= 10 ? dateActuelle.getHours() : '0' + dateActuelle.getHours();
            var minutesActuelles = dateActuelle.getMinutes() >= 10 ? dateActuelle.getMinutes() : '0' + dateActuelle.getMinutes();
            let NewHour = heureActuelle + ':' + minutesActuelles
            let pickup_date = new Date(newConsignes["pickup_date"]);

            if (compareDate(pickup_date)) {
                newConsignes["pickup_time"] = valeur;
                setConsignes(newConsignes);
            }
            else {
                toast.error("La date d'enlèvement doit être supérieure à la date actuelle.", {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } else if (element === "commentaire") {
            newConsignes["pickup_instruction"] = valeur;
            setConsignes(newConsignes);
            console.log(valeur, consignes)
        }

    };


    const updateConsignesLiv = (element, valeur) => {
        let newConsignes = { ...consignes };
        if (element === "date") {

            if (compareTwoDate(valeur, newConsignes["pickup_date"])) {
                newConsignes["delivery_date"] = valeur;
                setConsignes(newConsignes);
            } else {
                toast.error("La date de livraison doit être supérieur ou égal à la date d'enlevement.", {
                    position: "top-center",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                })
            }
        } else if (element === "heure") {
            let delivery_date = new Date(newConsignes["delivery_date"]);
            if (compareDate(delivery_date)) {
                newConsignes["delivery_time"] = valeur;
                setConsignes(newConsignes);
            } else {
                toast.error('La date de livraison doit être supérieure à la date d\'enlèvement ', {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } else if (element === "commentaire") {
            newConsignes["delivery_instruction"] = valeur;
            setConsignes(newConsignes);
        }
    }

    useEffect(() => {
        const tempsDepart = consignes["pickup_time"].split(":");
        if (delais) {
            const { value, label, time } = delais;
            let delaisValue = time === "rien" ? 0 : parseInt(time)
            // value === "normal" ? 180 : value === "spx_1" ? 90 : 0;

            let duree =
                parseInt(tempsDepart[0]) * 60 +
                parseInt(tempsDepart[1]) +
                parseInt(delaisValue);

            let hour = (duree - (duree % 60)) / 60;
            let minut = duree % 60;

            let newHour = hour >= 10 ? hour : '0' + hour;
            let newMinute = minut >= 10 ? minut : '0' + minut;

            let newConsignes = { ...consignes };
            newConsignes["delivery_time"] = newHour + ":" + newMinute;
            setConsignes(newConsignes);
        }
    }, [consignes["pickup_time"],delais]);

    useEffect(() => {

        // setInterval(() => {
        //     const now = new Date();
        //     const currentHour = now.getHours();
        //     const currentMinute = now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes();
        //     const currentSecond = now.getSeconds();
        //     updateConsignesEnl("heure", currentHour + ':' + currentMinute)
        //     updateConsignesLiv("heure", currentHour + ':' + currentMinute)

        // }, 60000);

    }, [kind])



    return (
        <>
            {kind === "enlevement" &&
                <div className="card-box">
                    <h4 className="text-center uppercase card-title">
                        {kind === "enlevement" ? "Enlèvement".toUpperCase() : "Livraison".toUpperCase()}
                    </h4>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">

                                <input
                                    // disabled={disabled}
                                    className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                                    type="date"
                                    placeholder={"DATE ENLèVEMENT".toUpperCase()}
                                    value={pickup_date}
                                    pattern="\d{2}-\d{2}-\d{4}"
                                    onChange={(e) => updateConsignesEnl("date", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <TimeComponente2 key={'enlevement'} onChange={(v) => updateConsignesEnl("heure", v)} value={pickup_time} placeholder={"Heure d'enlèvement".toUpperCase()} />
                        </div>
                    </div>

                    <div className="form-group">
                        <textarea
                            rows="5"
                            cols="5"
                            className="w-full bg-white border border-gray-500 rounded-lg outline-none textarea form-control"
                            placeholder={"Instructions d'enlèvement".toUpperCase()}
                            value={pickup_instruction}
                            onChange={(e) =>
                                updateConsignesEnl("commentaire", e.target.value)
                            }
                        ></textarea>
                    </div>
                </div>
            }


            {kind === "livraison" &&
                <div className="card-box">
                    <h4 className="text-center uppercase card-title">
                        {"Livraison".toUpperCase()}
                    </h4>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <input
                                    // disabled={disabled} 
                                    className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                                    type="date"
                                    placeholder={"DATE LIVRAISON".toUpperCase()}
                                    value={delivery_date}
                                    pattern="\d{2}-\d{2}-\d{4}"
                                    onChange={(e) => updateConsignesLiv("date", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <TimeComponente key={'livraison'} onChange={(v) => updateConsignesLiv("heure", v)} value={delivery_time} placeholder={"Heure de livraison".toUpperCase()} />

                        </div>
                    </div>

                    <div className="form-group">
                        <textarea
                            rows="5"
                            cols="5"
                            className="w-full bg-white border border-gray-500 rounded-lg outline-none textarea form-control"
                            placeholder={"Instructions de livraison".toUpperCase()}
                            value={delivery_instruction}
                            onChange={(e) =>
                                updateConsignesLiv("commentaire", e.target.value)
                            }
                        ></textarea>
                    </div>
                </div>
            }


        </>

    );
}

export default NewExpeditionEnlevementLivraisonDetailComponent;
