import React, { useState } from 'react';
import ListBaniere from './ListBaniere';
import { InfoForm } from '../../form/InfoForm';

function IndexBanniere() {
    const [Data, setData] = useState({
        action:"new",
        id:""
    })
    return (
        <div>
            <section clasName={"my-6"}>
                <InfoForm action={Data?.action} id={Data?.id} />
            </section>
            <ListBaniere setAction={setData} />
        </div>
    );

}

export default IndexBanniere;