import React, { useState, useEffect } from "react";
import contactService from "../../services/contact/contactService";
import TableauComponent from "../tableauComponent/tableau.component";

const tabsHead = [
    "*",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
];

const data = [
    "Donnée 1",
    "Donnée 2",
    "Donnée 3",
    "Donnée 4",
    "Donnée 5",
    "Donnée 6",
    "Donnée 7",
    "Donnée 8",
    "Donnée 9",
    "Donnée 10",
    "Donnée 11",
    "Donnée 12",
    "Donnée 13",
    "Donnée 14",
    "Donnée 15",
    "Donnée 16",
    "Donnée 17",
    "Donnée 18",
    "Donnée 19",
    "Donnée 20",
    "Donnée 21",
    "Donnée 22",
    "Donnée 23",
    "Donnée 24",
    "Donnée 25",
    "Donnée 26",
    "Donnée 27",
];

const TabsContactComponent = ({
    colonnes,
    dataConfig,
    title,
    dataType,
    type,
}) => {
    const [expeditionList, setExpeditionList] = useState([]);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [nbrTotalPage, setNbrTotalPage] = useState(null);
    const [filterLetter, setfilterLetter] = useState("*");

    const changeTabs = (data) => {
        setfilterLetter(data);
        let filter = {
            type: type,
            filter: {
                letter: data,
            },
        };
        switch (type) {
            case "all":
                setPage(1);

                contactService.fetchAdminFilteredContacts(filter).then(
                    (data) => {
                        setData(data.data.data.data);
                        setNbrTotalPage(data.data.data.last_page);
                        setPage(2);
                    },
                    (error) => {
                        console.log(error);
                    }
                );
                break;
        }
    };

    useEffect(() => {
        let filter = {
            type: type,
            page: page,
            filter: {
                letter: filterLetter,
            },
        };
        contactService.fetchAdminFilteredContacts(filter).then(
            (data) => {
                setData(data.data.data.data);
                setNbrTotalPage(data.data.data.last_page);
                setPage(page + 1);
            },
            (error) => {
                console.log(error);
            }
        );
    }, []);

    useEffect(() => {
        if (page < nbrTotalPage && page > 1) {
            let filter = {
                type: type,
                page: page,
                filter: {
                    letter: filterLetter,
                },
            };
            contactService.fetchAdminFilteredContacts(filter).then(
                (res) => {
                    setData([...data, ...res.data.data.data]);
                    setPage(page + 1);
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }, [page]);
    return (
        <div className="row">
            <div className="card-box">
                <h4 className="card-title"></h4>
                <div className="row">
                    <div className="col-md-2">
                        <div className="form-group">
                            <input
                                type="text"
                                onChange={(e) => changeTabs(e.target.value)}
                                name=""
                                id=""
                                placeholder="Recherche"
                                min={2}
                                className={
                                    "w-full  bg-transparent border border-gray-500 rounded-lg outline-none form-control"
                                }
                            />
                        </div>
                    </div>
                </div>
                <ul className="nav nav-tabs nav-tabs-solid nav-tabs-rounded">
                    {tabsHead.map((item, index) => (
                        <li
                            className="nav-item"
                            key={index}
                            onClick={() => changeTabs(item)}
                        >
                            <a
                                className={`nav-link ${
                                    index === 0 ? "active" : ""
                                }`}
                                href={`#solid-rounded-tab${index}`}
                                data-bs-toggle="tab"
                            >
                                {item}
                            </a>
                        </li>
                    ))}
                </ul>
                <div className="tab-content">
                    <TableauComponent
                        colonnes={colonnes}
                        valeurs={data}
                        title={title}
                        withFiltre={false}
                        parametreFiltre={[]}
                        expeditionKind={"contact"}
                        espace={"admin"}
                        role="admin"
                        secondTitle=""
                        top={true}
                        children=""
                        dataConfig={dataConfig}
                        hiddenCreateButton={true}
                        dataType={dataType}
                        
                    />
                </div>
            </div>
        </div>
    );
};

export default TabsContactComponent;
