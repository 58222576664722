import React, { useCallback, useEffect } from "react";
import TransportTypeSelector, {
  IncotermSelector, TransportTypeSelectorDeux,
} from "../Utils/Selectors/TransportTypeSelector";
import { getDefaultDate, getTimeFromDate } from "../../utils";
import { TbPlus } from "react-icons/tb";
import Select from "react-select";
import TexteareaInput from "../Utils/UI/TexteareaInput";
import CommentaireComponent from "./CommentaireComponent";
import TypedeRoueSelector from "../Utils/Selectors/TypedeRoueSelector";
import moment from "moment";
import DelaySelector from "../Utils/Selectors/DelaySelector";

const listeTypeRoue = [
  {
    label: "DEUX ROUES",
    value: 2,
  },
  {
    label: "QUATRE ROUES",
    value: 4,
  },
]

export default function EditCourse({
  expedition,
  onExpeditionDataChange,
  expeditionDetails,
  onAddTrackingModalOpen,
  onInfosSupplementaireChange,
  onDispatchModalOpen,
  setExpeditionData
}) {
  const { course, shipping } = expedition;

  const expeditionCourse = course || {};

  //console.log({ shipping, course })

  const onCourseDataChange = (key, value) => {
    onExpeditionDataChange("course", {
      ...expeditionCourse,
      [key]: value,
    });
  };

  const onShippingDataChange = (key, value) => {
    onExpeditionDataChange("shipping", {
      ...shipping,
      [key]: value,
    });
  };

  useEffect(() => {
    //console.log(shipping?.info_supplementaire)
    if (shipping) {
      const d = getDefaultDate();
      const h = getTimeFromDate();

      if (!shipping.pickup_date) {
        onShippingDataChange("pickup_date", d);
      } else {
        const formattedPickupDate = shipping.pickup_date
        //console.log({ formattedPickupDate })
        //formattedPickupDate.setHours(12);
        const _pickup_date = moment(formattedPickupDate).format("YYYY-MM-DD");
        //console.log({ _pickup_date })
        onShippingDataChange("pickup_date", _pickup_date);
      }

      if (!shipping.delivery_date) {
        onShippingDataChange("delivery_date", d);
      } else {
        const formattedDeliveryDate = shipping.delivery_date;
        //formattedDeliveryDate.setHours(12)
        onShippingDataChange("delivery_date", moment(formattedDeliveryDate).format("YYYY-MM-DD"));
      }

      if (!shipping.pickup_time) {
        onShippingDataChange("pickup_time", h);
      }

      if (!shipping.delivery_time) {
        onShippingDataChange("delivery_time", h);
      }

      // if (expeditionDetails?.type_de_roue) {
      //   onShippingDataChange('type_de_roue', expeditionDetails?.type_de_roue)
      // }
    }
  }, [shipping]);




  return (
    <>
      <fieldset>
        <legend>Informations sur le colis</legend>
        <div className="grid-cols-2 gap-2 space-y-2 lg:grid-cols-4 md:grid md:space-y-0">
          <div className="col-md-3">
            <div className="mb-3 form-group">
              <label htmlFor="field5" className="form-label">
                Référence interne client
              </label>
              <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                name="field5"
                value={shipping.customer_ref}
                onChange={(e) =>
                  onShippingDataChange("customer_ref", e.target.value)
                }
              />
            </div>
          </div>
          <div className="col-md-3">
            <TransportTypeSelectorDeux
              value={shipping.transport_type}
              onSelect={(v) => {
                onShippingDataChange("transport_type", v.value);
              }}
            />
          </div>
          <div className="col-md-3">
            <div className="mb-3 form-group">
              <label htmlFor="field5" className="form-label">
                Valeur commerciale / douane *
              </label>

              <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                name="field5"
                value={expeditionCourse.valeur_douane}
                onChange={(e) =>
                  onCourseDataChange("valeur_douane", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="mb-3 form-group">
              <label htmlFor="field5" className="form-label">
                Description*
              </label>
              <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                name="field5"
                value={expeditionCourse?.package_description}
                onChange={(e) =>
                  onCourseDataChange("package_description", e.target.value)
                }
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group ">
              <label htmlFor="date" className="form-label">
                Date d'enlèvement *
              </label>
              <input
                type="date"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none datetimepicker form-control"
                id="date"
                value={shipping.pickup_date}
                onChange={(e) =>
                  onShippingDataChange("pickup_date", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="time" className="form-label">
                Heure d'enlèvement *
              </label>
              <input
                type="time"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="time"
                value={shipping.pickup_time}
                onChange={(e) =>
                  onShippingDataChange("pickup_time", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group ">
              <label htmlFor="date" className="form-label">
                Date de livraison *
              </label>
              <input
                type="date"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none datetimepicker form-control"
                id="date"
                value={shipping.delivery_date}
                onChange={(e) =>
                  onShippingDataChange("delivery_date", e.target.value)
                }
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="time" className="form-label">
                Heure de livraison *
              </label>
              <input
                type="time"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="time"
                value={shipping.delivery_time}
                onChange={(e) =>
                  onShippingDataChange("delivery_time", e.target.value)
                }
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group ">
              <label htmlFor="text" className="form-label">
                Instruction d'enlèvement
              </label>
              <TexteareaInput
                rows={1}
                // className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="text"
                value={shipping.pickup_instruction}
                onChange={(e) =>
                  onShippingDataChange("pickup_instruction", e.target.value)
                }
              />
            </div>
          </div>
          <div className="col-md-3">
            {" "}
            <div className="mb-3 form-group">
              <label htmlFor="field6" className="uppercase">Instruction de livraison</label>
              <TexteareaInput
                rows={1}
                // className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                name="field6"
                value={shipping.delivery_instruction}
                onChange={(e) =>
                  onShippingDataChange("delivery_instruction", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="mb-3 form-group">
              <label htmlFor="field6" className="uppercase">Information importante </label>

              <div className="relative rounded-md shadow-sm">
                <input
                  type="text"
                  name="tack"
                  id="tack"
                  className="block w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  placeholder=""
                  aria-describedby="tracking"
                  value={(Array.isArray(expeditionDetails?.information_importante)) ? expeditionDetails?.information_importante[expeditionDetails?.information_importante.length - 1]?.information : ""
                  }
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 ">
                  <span
                    className="text-gray-500 sm:text-sm pointer"
                    id="price-currency"
                  >
                    <TbPlus
                      onClick={onDispatchModalOpen}
                      className="pointer"
                      type="button"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="myInput1" className="uppercase">Mail tracking</label>
              <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="myInput1"
                value={shipping.tracking_mail}
                onChange={(e) =>
                  onShippingDataChange("tracking_mail", e.target.value)
                }
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3 form-group">
              <label htmlFor="field6" className="uppercase">Dernier Track</label>

              <div className="relative rounded-md shadow-sm">
                <input
                  type="text"
                  name="tack"
                  id="tack"
                  className="block w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  placeholder=""
                  aria-describedby="tracking"
                  value={
                    expeditionDetails?.suivis?.length > 0
                      ? expeditionDetails.suivis[
                      expeditionDetails.suivis.length - 1
                      ]["details"]
                      : ""
                  }
                  disabled
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 ">
                  <span
                    className="text-gray-500 sm:text-sm pointer"
                    id="price-currency"
                  >
                    <TbPlus
                      onClick={onAddTrackingModalOpen}
                      className="pointer"
                      type="button"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={"col-md-3"}>
            {" "}

            <TypedeRoueSelector roue={shipping?.type_de_roue ? parseInt(shipping?.type_de_roue) : parseInt(expeditionDetails?.type_de_roue)}
              onChange={(v) => {
                onShippingDataChange("type_de_roue", v?.value);
              }}

            />

          </div>

          <div className={"col-md-3"}>
            <div className="form-group">
              <label htmlFor="field6" className="uppercase">Délais de livraison </label>
              <DelaySelector
                type_de_roue={shipping?.type_de_roue ? parseInt(shipping?.type_de_roue) : parseInt(expeditionDetails?.type_de_roue)}
                value={expeditionCourse?.porteur}
                onShippingDataChange={(v) => onCourseDataChange("porteur", v)} />
              <small className="ml-3">
                Délais pour Paris intra-muros
              </small>
            </div>
          </div>

          <div className="col-md-3">
            <CommentaireComponent withLabel={true} value={expeditionDetails} />
            {/* <div className="form-group">
              <label className="form-label">Suppléments</label>
              <TexteareaInput
                rows={1}
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="text"
                value={expeditionDetails?.info_supplementaire?.supplements}
                onChange={(e) => {
                  onShippingDataChange("supplements", e.target.value);
                }}
              />
            </div> */}
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="form-label">Articles Exotiques</label>
              <TexteareaInput
                rows={1}
                // className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="text"
                value={
                  expeditionDetails?.info_supplementaire?.articles_exotiques
                }
                onChange={(e) => {
                  onInfosSupplementaireChange(
                    "articles_exotiques",
                    e.target.value
                  );
                }}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="form-label">Frais Dédouanement</label>
              <TexteareaInput
                rows={1}
                // className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="text"
                value={
                  expeditionDetails?.info_supplementaire?.frais_dedouanement
                }
                onChange={(e) => {
                  onInfosSupplementaireChange(
                    "frais_dedouanement",
                    e.target.value
                  );
                }}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="volume" className="mb-1 form-label">
                Volume m<sup>3</sup>
              </label>
              <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="volume"
                value={expedition?.detail_info_supplementaire?.volumes}
                onChange={(e) => {
                  onInfosSupplementaireChange("volumes", e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </fieldset>
    </>
  );
}
