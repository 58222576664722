import { useCallback, useEffect, useState } from "react";
import { AdminTableDataController } from "../controller/admin/adminTableDataController";
import { toast } from "react-toastify";
import clientService from "../services/client/clientService";
import centreFacturationService from "../services/centreFacturation/centreFacturationService";
import contactService from "../services/contact/contactService";
import { userService } from "../services/user/UserService";
import vehiculeService from "../services/vehicule/vehiculeService";
import employeesService from "../services/employees";
import TransportTypesService from "../services/transportTypes/index";
import fuelService from "../services/fuel/fuelService";
import adminService from "../services/admin/adminService";
import FacturationCodeService from "../services/facturationCode/service";
import { omit } from "lodash"
import { useBasicFormValues } from "./useFormValues";
import { TableDataStore } from "../states/tableData";

export default function useAdminLists({ type, params, loop }) {
  const [adminData, setAdminData] = useState({
    data: [],
  });

  const dataList = TableDataStore((state) => state.dataList);
  const setDataList = TableDataStore((state) => state.setDataList);
  const updateData = TableDataStore((state) => state.updateData);
  const deleteData = TableDataStore((state) => state.deleteData);

  const [isLoading, setIsLoading] = useState(true);
  const { data: metadata, setData: setMetadata, setFormValue: setMetadataKey } = useBasicFormValues({
    page: 1,
    perPage: 25,
    search: "",
    filter: {},
  });

  const _params = params;

  const { page, search } = metadata;

  const onSubmit = useCallback(
    (updatedItem) => {
      // Clone the current list to avoid mutating the original state
      let items = [...adminData.data];

      // Find the index of the item with the same unique identifier
      const existingIndex = items.findIndex(
        (item) => item.id === updatedItem.id
      );

      const updated = existingIndex !== -1;

      if (updated) {
        // If the item already exists, update it by replacing the old item with the updated item
        //items[existingIndex] = updatedItem;
        setAdminData((d) => ({
          ...d,
          data: d.data.map((item) =>
            item.id === updatedItem.id
              ? {
                ...item,
                ...updatedItem,
              }
              : item
          ),
        }));
        updateData(updatedItem.id, updatedItem)
      } else {
        // If the item doesn't exist in the list, add it
        //items.unshift(updatedItem);
        setAdminData((d) => ({
          ...d,
          data: [updatedItem, ...d.data],
        }));
        setDataList([ updatedItem,...dataList,])
      }

      // Update the state with the new list
      /*  setAdminData(d => ({
             ...d,
             data: items
         })) */

      toast.success(
        `${updated ? "Détails mis à jour" : "Données ajoutées"} avec succès!`,
        {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    },
    [adminData]
  );

  const onDelete = useCallback((item) => {
    //console.log({ item })
    const items = [...adminData.data];
    const index = items.findIndex((i) => i.id === item?.id);
    //console.log({ index });
    if (index > -1) {
      items.splice(index, 1);
      setAdminData((d) => ({
        ...d,
        data: items,
      }));
    }
    deleteData(item.id)
  }, [adminData]);

  const getRequest = useCallback(() => {
    const _metadata = omit(metadata, ['page', 'perPage', 'search', 'filter'])
    const params = {
      page: metadata.page,
      ..._metadata,
      filter: metadata.filter,
      ..._params
    };
    if (metadata.search.length > 0) {
      params.filter = {
        ...params.filter,
        other: metadata.search,
      };
    }

    switch (type) {
      case "clients":
        return clientService.fetchClients({
          type: "all",
          per_page: metadata.perPage,
          ...params,
        });

      case "centre_facturation":
        return centreFacturationService.fetchCentreFacturations({
          type: "all",
          per_page: metadata.perPage,
          ...params,
        });
      case "contacts":
        return contactService.fetchAdminFilteredContacts({
          type: "all",
          per_page: metadata.perPage,
          ...params,
        });
      case "utilisateurs":
        const resp = userService.adminGetUserList({
          profil: "user",
          type: "all",
          per_page: metadata.perPage,
          ...params,
        });
        return resp;

      case "ops":
        return userService.adminGetUserList({
          profil: "ops",
          type: "all",
          per_page: metadata.perPage,
          ...params,
        });

      case "admin":
        return userService.adminGetUserList({
          profil: "admin",
          type: "all",
          per_page: metadata.perPage,
          ...params,
        });

      case "chauffeur":
        return userService.adminGetUserList({
          profil: "chauffeur",
          type: "all",
          per_page: metadata.perPage,
          ...params,
        });


      case "vehicules":
        return vehiculeService.fetchListVehicules({
          type: "all",
          per_page: metadata.perPage,
          ...params,
        });

      case "histories":
        return adminService.getAllHistories({
          type: "all",
          per_page: metadata.perPage,
          ...params,
        });

      case "salaries":
      case "soustraitants":
        return employeesService.getList({
          type,
          per_page: metadata.perPage,
          ...params,
        });

      case "fuels":
        return fuelService.fetchFuels({
          type: "all",
          per_page: metadata.perPage,
          ...params,
        });

      case "templates":
        return adminService.getTracking({
          type: "all",
          per_page: metadata.perPage,
          ...params,
        });

      case "facturation-codes":
        return FacturationCodeService.lists({
          type: "all",
          per_page: metadata.perPage,
          ...params,
        });


      case "transport-types":
        return TransportTypesService.getLists({
          type: "all",
          per_page: metadata.perPage,
          ...params,
        })

      default:
        break;
    }
  }, [type, _params, metadata]);

  useEffect(() => {
    //let pathSegments = window.location.pathname.split("/");

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { data } = await getRequest();
        if (data) {
          const isPaginated = data.data?.last_page;
          if (isPaginated) {
            setAdminData((d) => ({
              ...d,
              data:
                data.data.data,
              total: data.data.total,
              lastPage: data.data.last_page,
            }));
            
            setDataList(data.data.data)

            if (loop && parseInt(page) < parseInt(data.data.last_page)) {
              setMetadata((d) => ({
                ...d,
                page: page + 1,
              }));
            }
          } else {
            if (Array.isArray(data.data)) {
              setAdminData((d) => ({
                ...d,
                data: data.data,
              }));
              setDataList(data.data)
            }
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [page, getRequest, loop]);

  const onSearch = (value) => {
    setMetadata((d) => ({
      ...d,
      search: value,
      page: 1,
    }));
  };

  const setPage = pageNumber => {
    setMetadata(m => ({ ...m, page: pageNumber }));
  }

  const setPerPage = (value) => {
    setMetadata(m => ({ ...m, perPage: value }));
  }

  return {
    adminData,
    metadata,
    isLoading,
    onSubmit,
    onDelete,
    search,
    setPage,
    setPerPage,
    setSearch: onSearch,
    setAdminData,
    setMetadata,
    setMetadataKey
  };
}
