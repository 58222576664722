
import { EnvelopeIcon, ExclamationCircleIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const InputNew = ({ type = "text", value, onChange, InputClassName = "rounded-md", errorMessage = '', placeholder = '', label = '', withLabel = true, title = '', children = (<></>), isError = false, ...other }) => {

    const [inputType, setInputType] = useState("text");
    const { role, milieu } = useParams();

    useEffect(() => {
        setInputType(type)
    }, [])


    return (
        <section>
            <div>
                {withLabel &&
                    <label htmlFor={label} className="text-lg font-normal leading-6">
                        {label}
                    </label>
                }

                {type !== 'textarea' &&
                    <>

                        {type === 'nombre' ?
                            (<div className="relative rounded-md ">
                                <input
                                    value={value}
                                    {...other}
                                    name={label}

                                    className={clsx({ "text-red-500 ring-red-500 placeholder:text-red-500 focus:ring-red-500 bg-white": isError, "ring-gray-300 focus:ring-gray-50 bg-white focus:ring-1 focus:ring-inset": !isError }, `px-2 block w-full border-0 py-1.5 pr-10  ring-1 ring-inset  focus:ring-2 focus:ring-inset text-base sm:leading-6 focus:outline-none ${InputClassName}`)}
                                    placeholder={label[0]?.toUpperCase() + label.slice(1)}
                                    defaultValue={value}
                                    aria-invalid="true"
                                    aria-describedby={label.replace(/ /g, '') + "-error"}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        let numericValue = inputValue.replace(/[^0-9]/g, '');
                                        onChange(numericValue);

                                    }
                                    }
                                />

                                {isError &&
                                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                        <ExclamationCircleIcon className="w-5 h-5 text-red-500" aria-hidden="true" />
                                    </div>
                                }

                            </div>) : (type === 'file' ?
                                (<div className="relative rounded-md ">
                                    <input
                                        value={value}
                                        type={type}
                                        {...other}
                                        name={label}

                                        className={clsx({ "text-red-500 ring-red-500 placeholder:text-red-500 focus:ring-red-500 bg-white": isError, "ring-gray-300 focus:ring-gray-50 bg-white focus:ring-1 focus:ring-inset": !isError },
                                            `px-2 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-inset  focus:ring-2 focus:ring-inset text-base sm:leading-6 focus:outline-none $`

                                        )}

                                        placeholder={label[0]?.toUpperCase() + label.slice(1)}
                                        defaultValue={value}
                                        aria-invalid="true"
                                        aria-describedby={label.replace(/ /g, '') + "-error"}
                                        onChange={(e) => {

                                            onChange(e.target.files?.[0])
                                        }}
                                    />

                                    {isError &&
                                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                            <ExclamationCircleIcon className="w-5 h-5 text-red-500" aria-hidden="true" />
                                        </div>
                                    }

                                </div>)
                                :
                                (type === "double" ? (
                                    <div className="relative rounded-md ">

                                        <input
                                            value={value}
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                let numericValue = inputValue.replace(/[^0-9]/g, '');
                                                onChange(numericValue);

                                            }}
                                            type="text"
                                            name={placeholder[0].toUpperCase() + placeholder.slice(1)}

                                            className={clsx({ "text-red-500 ring-red-500 placeholder:text-red-500 focus:ring-red-500 bg-white": isError, "ring-gray-300 focus:ring-gray-50 bg-white focus:ring-1 focus:ring-inset": !isError }, `px-2 block w-full border-0 py-1.5 pr-10  ring-1 ring-inset  focus:ring-2 focus:ring-inset text-base sm:leading-6 focus:outline-none ${InputClassName}`)}
                                            placeholder={label[0]?.toUpperCase() + label.slice(1)}
                                            aria-describedby="price-currency"
                                        />
                                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                {title}
                                            </span>
                                        </div>
                                    </div>
                                ) : (type === 'payment' ? (

                                    <div className="relative rounded-md ">
                                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                            {children}
                                        </div>
                                        <input
                                            value={value}
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                let numericValue = inputValue.replace(/[^0-9]/g, '');
                                                onChange(numericValue);

                                            }}
                                            type="text"
                                            name={placeholder[0].toUpperCase() + placeholder.slice(1)}

                                            className={clsx({ "text-red-500 ring-red-500 placeholder:text-red-500 focus:ring-red-500 bg-white": isError, "ring-gray-300 focus:ring-gray-50 bg-white focus:ring-1 focus:ring-inset": !isError }, `pl-14 block w-full border-0 py-1.5 pr-10  ring-1 ring-inset  focus:ring-2 focus:ring-inset text-base sm:leading-6 focus:outline-none ${InputClassName}`)}
                                            placeholder={label[0]?.toUpperCase() + label.slice(1)}
                                            aria-describedby="price-currency"
                                        />
                                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                {title}
                                            </span>
                                        </div>
                                    </div>

                                ) :
                                    (type === "password" ?
                                        // <div className='w-full'>
                                        <div className="flex rounded-md ">
                                            <div className="relative flex items-stretch flex-grow focus-within:z-10">

                                                <input
                                                    value={value}
                                                    type={inputType}
                                                    className="block w-full rounded-lg border-0 py-1.5 pl-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 focus:outline-none"
                                                    placeholder=""
                                                    onChange={(e) => onChange(e.target.value)}
                                                />
                                            </div>
                                            <span
                                                className="relative cursor-pointer -ml-10 z-10  my-1 right-2 inline-flex items-center gap-x-1.5 rounded-lg px-3 py-2 text-sm font-semibold text-white ring-y-1 ring-inset focus:outline-none"
                                            >
                                                {inputType === "password" &&
                                                    <EyeIcon className={clsx({
                                                        'text-paris': milieu === "paris", 'text-service': milieu === "service",
                                                        'text-medical': milieu === "medical",
                                                        'text-admin': milieu === "admin"
                                                    },
                                                        "-ml-0.5 h-4 w-")}
                                                        aria-hidden="true"
                                                        onClick={() => setInputType("text")} />}
                                                {inputType === "text" &&
                                                    <EyeSlashIcon
                                                        className={clsx({
                                                            'text-paris': milieu === "paris", 'text-service': milieu === "service",
                                                            'text-medical': milieu === "medical",
                                                            'text-admin': milieu === "admin"
                                                        },
                                                            "-ml-0.5 h-4 w-4")} aria-hidden="true" onClick={() => setInputType("password")} />
                                                }

                                            </span>
                                        </div>
                                        // </div>

                                        : (type === "email" ?
                                            <div className='w-full'>
                                                <div className="flex rounded-md ">
                                                    <div className="relative flex items-stretch flex-grow focus-within:z-10">

                                                        <input
                                                            value={value}
                                                            type={inputType}
                                                            className="block w-full rounded-lg border-0 py-1.5 pl-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 focus:outline-none"
                                                            placeholder=""
                                                            onChange={(e) => onChange(e.target.value)}
                                                        />
                                                    </div>
                                                    <span
                                                        className="relative cursor-pointer -ml-10 z-10  my-1 right-2 inline-flex items-center gap-x-1.5 rounded-lg px-3 py-2 text-sm font-semibold text-white ring-y-1 ring-inset focus:outline-none"
                                                    >

                                                        <EnvelopeIcon className={clsx({
                                                            'text-paris': milieu === "paris", 'text-service': milieu === "service",
                                                            'text-medical': milieu === "medical",
                                                            'text-admin': milieu === "admin"
                                                        },
                                                            "-ml-0.5 h-4 w-4")}
                                                            aria-hidden="true"
                                                        />

                                                    </span>
                                                </div>
                                            </div>
                                            : <div className="relative rounded-md ">
                                                <input
                                                    value={value}
                                                    type={type}
                                                    {...other}
                                                    name={label}
                                                    className={clsx({ "text-red-500 bg-gray-500 ring-red-500 placeholder:text-red-500 focus:ring-red-500 bg-white": isError, "ring-gray-300 focus:ring-gray-50 bg-white focus:ring-1 focus:ring-inset": !isError }, "px-2 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-inset  focus:ring-2 focus:ring-inset text-base sm:leading-6 focus:outline-none")}
                                                    placeholder={label[0]?.toUpperCase() + label.slice(1)}
                                                    defaultValue={value}
                                                    aria-invalid="true"
                                                    aria-describedby={label.replace(/ /g, '') + "-error"}
                                                    onChange={(e) => onChange(e.target.value)}
                                                />

                                                {isError &&
                                                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                                        <ExclamationCircleIcon className="w-5 h-5 text-red-500" aria-hidden="true" />
                                                    </div>
                                                }

                                            </div>)

                                    )
                                )
                                ))
                        }
                    </>

                }

                {type === 'textarea' &&

                    <div className="relative rounded-md">
                        <textarea
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            rows={6}
                            name="comment"
                            id="comment"
                            placeholder={label[0]?.toUpperCase() + label.slice(1)}
                            className={clsx({ "text-red-500 ring-red-500 placeholder:text-red-500 focus:ring-red-500 bg-white": isError, "ring-gray-300 focus:ring-gray-50 bg-white focus:ring-1 focus:ring-inset": !isError }, "px-2 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-inset  focus:ring-2 focus:ring-inset text-base sm:leading-6")}
                        ></textarea>
                    </div>


                }
                {isError &&

                    <p className="text-sm text-red-600 ">
                        {errorMessage}
                    </p>
                }

            </div>
        </section >
    );
}