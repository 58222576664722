import React, { useEffect, useState } from "react";
import ContactList from "./ContactList";
import contactService from "../../../services/contact/contactService";
import ContactFilter from "./ContactFilter";
import useDisclosure from "../../../hooks/useDisclosure";
import ContactForm from "./ContactForm";
import { toast } from "react-toastify";
import PaginationComponent from "../../paginationComponent/PaginationComponent";
import { ArrowUpTrayIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useAppContext } from "../../../context";
import clsx from "clsx";
import ModalUI from "../../Utils/Modal/Modal";
import { ExcelReader } from "../../ExcelFileReader";
import { ContactDataStore } from "../../../states/tableData";

function ContactIndex() {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { isAuth, setIsAuth } = useAppContext();

    const  dataList= ContactDataStore((state)=>state.dataList)
    const setDataList = ContactDataStore((state) => state.setDataList);
    const deleteData = ContactDataStore((state) => state.deleteData);


    const [page, setPage] = useState(1);
    const [contactData, setContactData] = useState([]);
    const [filterLetter, setFilterLetter] = useState("*");
    const [isLoading, setIsLoading] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalFormContent, setModalFormContent] = useState(null);
    const [isNew, setIsNew] = useState(true);
    const [perPageItems, setPerPageItems] = useState(25);
    const [totalItems, setTotalItems] = useState(0);
    const [isLetter, setisLetter] = useState(true);


    // Handle fetching contacts with current filters
    const fetchContacts = async () => {
        setIsLoading(true);
        const options = {
            user_id: isAuth?.id,
            by: "user",
            contact_type: "all",
            type: "all",
            page: page, // Adjusting for API which might expect 1-based indexing
            per_page: perPageItems,
            ...(filterLetter !== "*" && {
                filter: isLetter ? { letter: filterLetter } : { other: filterLetter },
            }),
        };

        try {
            const { data } = await contactService.fechClientConatctList(options);
            // dispatch(setContacts(data.data.data));
            setDataList([...data.data.data])
            setTotalItems(data.data.total);
            setIsLoading(false);
        } catch (error) {
            toast.error("An error occurred while fetching contacts.", { position: "top-center" });
            setIsLoading(false);
        }
    };

    // Effect to fetch contacts on filter, page, or perPageItems change
    useEffect(() => {
        fetchContacts();
    }, [page, filterLetter, perPageItems]);

    // Simplified modal handling for adding or editing contacts
    const handleModal = (isNew, contact) => {
        setIsNew(isNew);
        setModalFormContent(contact); // Pass empty object for new contact
        setModalTitle(isNew ? "Créer un contact" : "Modifier un contact");
        onOpen();
    };

    // Pagination handler
    const handlePageClick = ({ selected }) => {
        //console.log({ selected })
        setPage(selected);
    };


    const handleDeleteAction = (id) => {
        const toastId = toast.loading("Suppression en cours...", {
            position: "top-right",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        try {
            contactService.clientDeleteContact({ id: id }).then((data) => {
                let { success, data: donnee } = data.data;

                if (success) {
                    toast.update(toastId, {
                        render: "Le contact a été supprimé avec succès!",
                        type: "success",
                        isLoading: false,
                        autoClose: 500,
                    });
                    // dispatch(removeContact(id));
                    deleteData(id)

                    // setData(
                    //     newData.filter(({ id }) => String(id) !== String(id))
                    // );
                } else {
                    toast.update(toastId, {
                        render: "Le contact non supprimé!",
                        type: "error",
                        isLoading: false,
                        autoClose: 500,
                    });
                }
            });
        } catch (error) {
            toast.update(toastId, {
                render: "Le contact non supprimé!",
                type: "error",
                isLoading: false,
                autoClose: 500,
            });
        }
    };

    const [userAction, setUserAction] = useState('other')

    const modalAction = (action) => {
        handleModal(true)
        setUserAction(action)
    }

    // Render component
    return (
        <div className={"px-2"}>
            <ModalUI isOpen={isOpen} onClose={onClose} title={modalTitle}>
                { }
                {userAction === "other" && <ContactForm
                    modalFormContent={modalFormContent}
                    editable={Boolean(modalFormContent)}
                    setIsNew={setIsNew}
                    isNew={!Boolean(modalFormContent)}
                    onClose={() => {
                        //setIsNew(true);
                        onClose();
                    }}
                    setContactData={setContactData}
                    contactList={contactData}
                    setmodalFormContent={setModalFormContent}

                />}

                {userAction === "upload" &&
                    <ExcelReader setOpen={() => {onClose();}} />
                }


            </ModalUI>

            <div>
                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <input
                                type="text"
                                onChange={(e) => setFilterLetter(e.target.value, false)}
                                name=""
                                id=""
                                placeholder="Recherche"
                                min={2}
                                className={
                                    "w-full md:w-1/3  bg-transparent border border-gray-500 rounded-lg outline-none form-control"
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={"flex justify-between items-end gap-5 my-3 "}>
                <div className="flex-1 overflow-auto bg-white rounded-md">
                    <ContactFilter changeTabs={(letter) => setFilterLetter(letter)} filterLetter={filterLetter} />
                </div>

                <button className={clsx({
                    "bg-paris": isAuth?.spaceName === "paris",
                    "bg-medical": isAuth?.spaceName === "medical",
                    "bg-service": isAuth?.spaceName === "service",
                    "bg-admin": isAuth?.spaceName === "admin",
                }, " flex items-center rounded-md mb-1 mx-1 px-1 gap-2 ")} onClick={() => modalAction('upload')}>
                    <ArrowUpTrayIcon className={"h-4 w-4 my-3 text-xl font-bold "} /> Import contacts
                </button>
                <button className={clsx({
                    "bg-paris": isAuth?.spaceName === "paris",
                    "bg-medical": isAuth?.spaceName === "medical",
                    "bg-service": isAuth?.spaceName === "service",
                    "bg-admin": isAuth?.spaceName === "admin",
                }, " flex items-center rounded-md mb-1 mx-1 px-1 gap-2 ")} onClick={() => modalAction('other')}>
                    <PlusIcon className={"h-4 w-4 my-3 text-xl font-bold "} /> Ajouter
                </button>


            </div>

            <ContactList
                isOpen={onOpen}
                contacts={dataList}
                isLoading={isLoading}
                onEdit={(contact) => handleModal(true, contact)}
                handleDeleteAction={handleDeleteAction} // Implement or pass this function similarly to previous logic
                setmodalFormContent={setModalFormContent}
                setmodalTitle={setModalTitle}
                setIsNew={setIsNew}
            />

            <PaginationComponent
                //currentPage={page || 1}
                page={page || 1}
                handlePageClick={handlePageClick}
                totalItems={totalItems}
                perPageItems={perPageItems}
                setnumberPerPage={setPerPageItems}
            />
        </div>
    );
}

export default ContactIndex;
