import React from 'react'
import DashLayout from '../dashboard/DashLayout'
import { Outlet } from 'react-router-dom';
import { ClientParisWrapper } from '../../context';
import { parisClientMenu } from '../../core/menu/client/_paris.menu';
import { HomeIcon } from '@heroicons/react/24/outline';
import { medicalClientMenu } from '../../core/menu/client/_medical.menu';
import { serviceClientMenu } from '../../core/menu/client/_service.menu';


export const ClientParisDashLayout = ({ userRole, spaceName }) => {
    return (
        <section className={'0'}>
            {/*  interfac = "client" type= "medical" */}
            <ClientParisWrapper userRole={userRole} spaceName={spaceName}>
                <DashLayout space={spaceName} navigation={spaceName === "paris" ? parisClientMenu : (spaceName === "medical" ? medicalClientMenu : serviceClientMenu)}>
                    <Outlet />
                </DashLayout>
            </ClientParisWrapper >

        </section >
    )
}
