import React, { useEffect, useState } from 'react'
import { RxAvatar } from "react-icons/rx";
import HistoriqueService from '../../services/historique/HistoriqueService';
import { TableLoader } from '../Utils/Loader';
import { RiBox3Fill } from 'react-icons/ri';
import { BiChevronDown, BiChevronLeft } from 'react-icons/bi';
import { formatDefaultDate } from '../../utils/moment';

export default function Details({ data, onBack }) {
    const [searchValue, setSearchValue] = useState("");
    const [items, setItems] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [metadata, setMetadata] = useState({
        page: 1,
        search: "",
    });

    const { page, search } = metadata;

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true)
                const { data: resData } = await HistoriqueService.listHistoriqueByUser({ user_id: data.id, page, per_page: 25 }) //.getDrivers({ interface: type, page, per_page: 100 });
                console.log({ resData })
                if (resData.success) {
                    const _items = resData.data.data
                    setItems(i => page === 1 ? _items : [...i, ..._items]);
                    if (page < resData.data.last_page) {
                        setMetadata(m => ({
                            ...m,
                            page: m.page + 1
                        }))
                    }
                }
            } catch (error) {
                console.error(error)
            } finally {
                setIsLoading(false)

            }
        })()
    }, [page, data])

    return (
        <div>
            <div className="flex items-center mb-2 cursor-pointer" onClick={onBack}>
                <BiChevronLeft className='text-3xl w-auto' />
                <div className="text-[14px]">Retour à la liste</div>
            </div>
            <div className="bg-white p-4">
                <div className="flex items-center">
                    <RxAvatar className="text-[170px] w-auto" />
                    <div className='ml-10 flex-1'>
                        <div className="grid lg:grid-cols-6 md:grid-cols-4 grid-cols-2 gap-16 pl-3">
                            <div className=''>
                                <h2 className="font-bold text-lg">Nom et prénom(s)</h2>
                                <div className="text-lg">{data.name}</div>
                            </div>
                            <div className=''>
                                <h2 className="font-bold text-lg">Téléphone</h2>
                                <div className="text-lg">{data.telephone1}</div>
                            </div>
                            <div className=''>
                                <h2 className="font-bold text-lg">Email</h2>
                                <div className="text-lg">{data.email}</div>
                            </div>
                            <div className=''>
                                <h2 className="font-bold text-lg">Pays</h2>
                                <div className="text-lg">{data.country || "-"}</div>
                            </div>
                            <div className=''>
                                <h2 className="font-bold text-lg">Ville</h2>
                                <div className="text-lg">{data.city || "-"}</div>
                            </div>
                            <div className=''>
                                <h2 className="font-bold text-lg">Adresse</h2>
                                <div className="text-lg">{data.address1 || "-"}</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="mt-20">
                <Lists data={items} isLoading={isLoading} />
            </div>
        </div>
    )
}


const Lists = ({ data, onSelect, isLoading }) => {
    return (
        <div className="relative overflow-x-auto">
            <div className="card-box bg-white">
                <div className="card-block">
                    {/*                     <h4 className="card-title">Basic Table</h4>*/}
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <thead>
                                <tr className='uppercase'>
                                    <th className="text-left">Date</th>
                                    <th className="text-left">Prise de poste</th>
                                    <th className="text-left">Pause</th>
                                    <th className="text-left">Reprise de poste</th>
                                    <th className="text-left">Fin de journée</th>
                                    <th className="text-left">Expéditions attribuées</th>

                                </tr>
                            </thead>
                            <tbody>
                                {data.length > 0 && (
                                    data.map((item, i) => {
                                        return (
                                            <tr key={i} className="py-4">
                                                <td>{formatDefaultDate(item.created_at, "DD-MM-YYYY")}</td>
                                                <td>{item.heurepriseposte ? formatDefaultDate(item.heurepriseposte, "DD-MM-YYYY hh:mm") : "-"}</td>
                                                <td>{item.heurepause ? formatDefaultDate(item.heurepause, "DD-MM-YYYY hh:mm") : "-"}</td>
                                                <td>{item.heureRetourpause ? formatDefaultDate(item.heureRetourpause, "DD-MM-YYYY hh:mm") : "-"}</td>
                                                <td>{item.heurefin ? formatDefaultDate(item.heurefin, "DD-MM-YYYY hh:mm") : "-"}</td>
                                                <td>
                                                    <div className="cursor-pointer">
                                                        <RiBox3Fill className='text-lg' />
                                                    </div>
                                                </td>

                                            </tr>
                                        )
                                    })
                                )}
                            </tbody>
                        </table>
                        {isLoading ? <TableLoader /> : data.length === 0 ? (
                            <h6 className="text-center text-lg my-5">Aucune donnée</h6>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}