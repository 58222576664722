import React, { useState } from "react";
import { exnetSpaceColor } from "../../../utils/Functions/otherFunctions";

export default function Filter({
    type,
    setisSearch,
    filterData,
    setfilterData,
}) {

    const changeValue = (valueKey, value) => {
        let newValue = {};
        for (const property in filterData) {
            if (filterData[property] !== "") {
                newValue[property] = filterData[property];
            }
        }

        newValue[valueKey] = value;

        setfilterData(newValue);
    };

    const handleSearch = () => {
        setisSearch(true);
    };

    const handleReset = () => {
        setisSearch(false);
        setfilterData({});

    };

    return (
        <div className="p-3 bg-gray-100 rounded-md">
            <div className="items-center justify-between md:flex-wrap md:flex">
                <div className="w-full md:w-[230px] mb-6 md:mb-0">
                    <label
                        className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                        htmlFor="ref"
                    >
                        Référence
                    </label>
                    <input
                        className="block text-sm w-full h-[40px] px-4 py-2 mb-3 leading-tight text-gray-700  border ring-gray-300 focus:ring-gray-500 bg-white  border-gray-500 rounded-lg appearance-none focus:outline-none focus:bg-white"
                        id="ref"
                        type="text"
                        placeholder=""
                        value={filterData.ref ? filterData.ref : ""}
                        onChange={(e) => {
                            if (
                                parseInt(e.target.value) ||
                                e.target.value === ""
                            )
                                changeValue("ref", e.target.value);
                        }}
                    />
                </div>

                <div className="w-full md:w-[230px] mb-6 md:mb-0">
                    <label
                        className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                        htmlFor="suivi"
                    >
                        Suivi
                    </label>
                    <input
                        className="block text-sm w-full h-[40px] px-4 py-2 mb-3 leading-tight text-gray-700  border ring-gray-300 focus:ring-gray-500 bg-white  border-gray-500 rounded-lg appearance-none focus:outline-none focus:bg-white"
                        id="suivi"
                        type="text"
                        placeholder=""
                        value={filterData.suivi ? filterData.suivi : ""}
                        onChange={(e) => {
                            changeValue("suivi", e.target.value);
                        }}
                    />
                </div>
                <div className="w-full md:w-[230px] mb-6 md:mb-0">
                    <label
                        className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                        htmlFor="contact"
                    >
                        Contact
                    </label>
                    <input
                        className="block w-full px-4 py-2 mb-3 leading-tight text-gray-700 bg-white border border-gray-300 rounded-lg appearance-none focus:outline-none focus:bg-white"
                        id="contact"
                        type="text"
                        placeholder=""
                        value={filterData.contact ? filterData.contact : ""}
                        onChange={(e) => {
                            changeValue("contact", e.target.value);
                        }}
                    />
                </div>

                <div className="w-full md:w-[230px] mb-6 md:mb-0">
                    <label
                        className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                        htmlFor="billing_center"
                    >
                        Centre de facturation
                    </label>
                    <input
                        className="block w-full px-4 py-2 mb-3 leading-tight text-gray-700 bg-white border border-gray-300 rounded-lg appearance-none focus:outline-none focus:bg-white"
                        id="billing_center"
                        type="text"
                        placeholder=""
                        value={
                            filterData.billing_center
                                ? filterData.billing_center
                                : ""
                        }
                        onChange={(e) => {
                            changeValue("billing_center", e.target.value);
                        }}
                    />
                </div>

                

                <div className="w-full md:w-[230px] mb-6 md:mb-0 flex">
                    <button
                        onClick={handleSearch}
                        type="button"
                        className="mx-2 text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-3 py-2.5 text-center"
                        // disabled={isSubmitting}
                        style={{ color: '#fff', backgroundColor: `${exnetSpaceColor(type)}` }}
                    >
                        Rechercher
                    </button>

                    <button
                        onClick={handleReset}
                        type="button"
                        className="mx-2 text-white  focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-3 py-2.5 text-center"
                        // disabled={isSubmitting}
                        style={{ color: '#fff', backgroundColor: `${exnetSpaceColor(type)}` }}
                    >
                        Annuler
                    </button>
                </div>
            </div>
        </div>
    );
}
