import React from 'react'

export default function Filter({ value, onChange }) {
    return (
        <div className="p-3 mt-3 bg-gray-100 rounded-lg">
            <div className="justify-between grid-cols-4 gap-1 space-y-2 md:grid md:gap-6 md:space-y-0">
                <div className="w-full mb-6 md:mb-0">
                    <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase" htmlFor="description">
                        Description
                    </label>
                    <input
                        className=" border bg-white  border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full pl-10 p-2.5"
                        id="description"
                        type="text"
                        placeholder=""
                        value={value.description}
                        onChange={e => {
                            onChange("description", e.target.value)
                        }}
                    />
                </div>
                <div className="w-full mb-6 md:mb-0">
                    <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase" htmlFor="mawb">
                        MAWB
                    </label>
                    <input
                        className=" border bg-white  border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full pl-10 p-2.5"
                        id="mawb"
                        type="text"
                        placeholder=""
                        value={value.mawb}
                        onChange={e => {
                            onChange("mawb", e.target.value)
                        }}
                    />
                </div>
                <div className="w-full mb-6 md:mb-0">
                    <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase" htmlFor="ex1">
                        EX1
                    </label>
                    <input
                        className=" border bg-white  border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full pl-10 p-2.5"
                        id="ex1"
                        type="text"
                        placeholder=""
                        value={value.ex1}
                        onChange={e => {
                            onChange("ex1", e.target.value)
                        }}
                    />
                </div>
                <div className="w-full mb-6 md:mb-0">
                    <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase" htmlFor="viavol">
                        VIAVOL
                    </label>
                    <input
                        className=" border bg-white  border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full pl-10 p-2.5"
                        id="viavol"
                        type="text"
                        placeholder=""
                        value={value.viavol}
                        onChange={e => {
                            onChange("viavol", e.target.value)
                        }}
                    />
                </div>
                <div className="w-full mb-6 md:mb-0">
                    <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase" htmlFor="datemanifest">
                        Date Manifest
                    </label>
                    <input
                        className=" border bg-white  border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full pl-10 p-2.5"
                        id="datemanifest"
                        type="date"
                        placeholder=""
                        value={value.datemanifest}
                        onChange={e => {
                            onChange("datemanifest", e.target.value)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
