import { create } from "zustand";
import { uniqueId } from "lodash";
import ExpeditionService from "../services/expedition/ExpeditionService";

const InitialUserData = {
  civility: "M",
  company: "",
  fullname: "",
  lastName: "",
  firstName: "",
  address1: "",
  address2: "",
  country: "fr",
  postalCode: "",
  city: "",
  phonecode: "",
  phone1: "+33",
  phone2: "+33",
  email: "",
};

const InitialPackagesData = {
  length: 0,
  width: 0,
  height: 0,
  weight: 1,
  weight_volume: "",
  description: "",
  incoterm: "exw",
  value_in_douane: "",
  devises: "eur",
  documents: [],
  ref: "",
};
const InitialCoursePackage = {
  package_description: "",
  additional_insurance_amount: "",
  additional_insurance: "",
  documents: [{}],
  created_at: "",
  valeur_douane: "",
  porteur: "",
};
const initialEmailValue = {
  email: "",
  type: "",
};

const initialFactureLineState = {
  price: "",
  quantity: 1,
  comment: "",
  line_type: { value: "Facture", label: "Facture" }
}

const initialTrackingValue = {
  details: "",
  heur_suivi: "",
  date_suivi: "",
  agents: "",
  chaufeur: "",
  visible: 1,
  problem: 0,
  mail: 0,
  sms: 0,
  shipping_id: "",
  isparis: 0,
  ismedical: 0,
  isservice: 0,
  isdriver: 0,
};
export const ShippingDetails = create((set) => ({
  details: {
    expeditor: { ...InitialUserData },
    receiver: { ...InitialUserData },
    packages: [InitialPackagesData],
    coursePackages: [InitialCoursePackage],
    comments: [],
    detail_info_supplementaire: {},
    information_importante: [],
    suivis: [],
    courses: [],
    allDocs: [],
    manifest: 0,
    emailList: [],
    facturations: [],
  },
  trackingData: initialTrackingValue,
  updateTrackingData: (keys, value) => set((state) => ({
    trackingData: {
      ...state.trackingData,
      [keys]: value
    }
  })),
  resetTrackingData: () => set((state) => ({
    trackingData: {
      initialTrackingValue
    },
  })),
  setTrackingData: (value) => set((state) => ({
    trackingData: {
      ...state.trackingData,
      ...value
    }
  })),
  Email: initialEmailValue,
  factureLine: initialFactureLineState,
  updateFactureLine: (keys, value) => set((state) => ({
    factureLine: {
      ...state.factureLine,
      [keys]: value
    }
  })),
  resetFactureLine: () => set((state) => ({
    factureLine: initialFactureLineState,
  })),
  addFactureLine: (value) => set((state) => ({
    details: {
      ...state.details,
      facturations: [...state.details.facturations, value]
    }
  })),
  removeFactureLine: (id) => set((state) => ({
    details: {
      ...state.details,
      facturations: state.details.facturations.filter(f => f.id !== id)
    }
  })),

  comment: {
    content: "",
    shipping_id: ""
  },
  setComment: (value) =>
    set((state) => ({
      comment: {
        ...value,
      },
    })),
  updateCommentSingle: (keys, value) =>
    set((state) => ({
      comment: {
        ...state.comment,
        [keys]: value,
      },
    })),
  resetEmail: () =>
    set((state) => ({
      Email: {
        ...initialEmailValue,
      },
    })),
  UpdateEmail: (keys, value) =>
    set((state) => ({
      Email: {
        ...state.Email,
        [keys]: value,
      },
    })),
  updateManifest: () =>
    set((state) => ({
      details: {
        ...state.details,
        manifest: 1,
      },
    })),
  deleteOneDoc: (index) =>
    set((state) => ({
      details: {
        ...state.details,
        allDocs: state.details.allDocs.filter((_, i) => i !== index),
      },
    })),
  addOneDoc: (value) =>
    set((state) => ({
      details: {
        ...state.details,
        allDocs: [...state.details.allDocs, value],
      },
    })),
  addManyDocs: (value) =>
    set((state) => ({
      details: {
        ...state.details,
        allDocs: [...state.details.allDocs, ...value],
      },
    })),
  setAllDocs: (value) =>
    set((state) => ({
      details: {
        ...state.details,
        allDocs: value,
      },
    })),
  deleteOneEmail: (index) =>
    set((state) => ({
      details: {
        ...state.details,
        emailList: state.details.emailList.filter((_, i) => i !== index),
      },
    })),
  addOneEmail: (value) =>
    set((state) => ({
      details: {
        ...state.details,
        emailList: [...state.details.emailList, value],
      },
    })),
  addManyEmail: (value) =>
    set((state) => ({
      details: {
        ...state.details,
        emailList: [...state.details.emailList, ...value],
      },
    })),
  updateOneEmailByIndex: (index, value) =>
    set((state) => ({
      details: {
        ...state.details,
        emailList: state.details.emailList.map((item, i) => i === index ? value
          : item
        ),
      },
    })),
  addComment: (value) =>
    set((state) => ({
      details: {
        ...state.details,
        comments: [value, ...state.details.comments],
      },
    })),
  updateComment: (value, keys, index) =>
    set((state) => ({
      details: {
        ...state.details,
        comments: state.details.comments.map((item, i) =>
          i === index ? { ...item, [keys]: value } : item
        ),
      },
    })),
  updateOneCommentByIndex: (index, value) =>
    set((state) => ({
      details: {
        ...state.details,
        comments: state.details.comments.map((item, i) =>
          i === index ? { ...item, ...value } : item
        ),
      },
    })),
  deleteComment: (id) =>
    set((state) => ({
      details: {
        ...state.details,
        comments: state.details.comments.filter((c) => c.id !== id),
      },
    })),
  setDetails: (expedition) =>
    set((state) => {
      let docs = [];
      let _emails = [];

      if (expedition) {
        if (expedition.expeditor?.email) {
          _emails = [
            ...expedition.expeditor.email.split(",")?.map((e) => ({
              id: uniqueId("email--"),
              email: e,
              type: "expeditor",
              label: "Expéditeur",
              phone: expedition.expeditor.phone1,
            })),
          ];
        }

        if (expedition.receiver?.email) {
          _emails = [
            ..._emails,
            ...expedition.receiver.email.split(",")?.map((e) => ({
              id: uniqueId("email--"),
              email: e,
              type: "receiver",
              label: "Destinataire",
              phone: expedition.receiver.phone1,
            })),
          ];
        }
        if (expedition.doc_accompagnement) {
          docs = [
            ...docs,
            ...expedition.doc_accompagnement?.map((f, i) => ({
              name: `Doc d'accompagnement ${i + 1}`,
              type: "PDF",
              created_at: expedition.created_at,
              document: f,
            })),
          ];
        }

        if (expedition.doc_borderaux) {
          docs = [
            ...docs,
            {
              name: `Bordereaux`,
              type: "PDF",
              created_at: expedition.created_at,
              document: expedition.doc_borderaux,
            },
          ];
        }

        if (expedition.documents) {
          docs = [
            ...docs,
            ...expedition?.documents?.map((d) => ({ ...d, name: d.nom })),
          ];
        }
      }

      return {
        details: {
          ...expedition,
          //   packages:expedition?.packages?.length>0?expedition.packages[0]:null,
          shipping: {
            billing_center_id: expedition?.billing_center?.id,
            code_donneur: expedition?.code_donneur ?? "",
            code_receveur: expedition?.code_receveur ?? "",
            comment: expedition?.commentaire ?? "",
            customer_ref: expedition?.customer_ref ?? "",
            delivery_date: expedition?.delivery_date,
            delivery_instruction: expedition?.delivery_instruction ?? "",
            delivery_time: expedition?.delivery_time,
            devises: expedition?.devises ?? "EUR",
            pickup_date: expedition?.pickup_date,
            pickup_instruction: expedition?.pickup_instruction ?? "",
            pickup_time: expedition?.pickup_time,
            tracking_mail: expedition?.tracking_mail ?? "",
            transport_type: expedition?.transport_type ?? "",
            type_de_roue: expedition?.type_de_roue ?? "",
            type_expedition: expedition?.type_expedition ?? "",
          },
          allDocs: docs,
          emailList: _emails,
        },
      };
    }),
  updateShipping: (keys, value) =>
    set((state) => ({
      details: {
        ...state.details,
        shipping: {
          ...state.details.shipping,
          [keys]: value, // Met à jour la clé dynamique dans `shipping`
        },
      },
    })),

  updateDetails: (value, keys) =>
    set((state) => ({
      details: {
        ...state.details,
        [keys]: value,
      },
    })),
  updatePackages: (keys, value) =>
    set((state) => ({
      details: {
        ...state.details,
        packages:
          state.details.packages.length === 0
            ? [{ [keys]: value }] // Ajoute un nouvel élément si le tableau est vide
            : state.details.packages.map((item, i) =>
              i === 0 ? { ...item, [keys]: value } : item
            ),
      },
    })),

  updateCoursePackages: (keys, value) =>
    set((state) => ({
      details: {
        ...state.details,
        courses:
          state.details.courses.length === 0
            ? [{ [keys]: value }] // Ajoute un nouvel élément si le tableau est vide
            : state.details.courses.map((item, i) =>
              i === 0 ? { ...item, [keys]: value } : item
            ),
      },
    })),

  updateDetailInfoSupplement: (keys, value) =>
    set((state) => ({
      details: {
        ...state.details,
        detail_info_supplementaire: {
          ...state.details.detail_info_supplementaire,
          [keys]: value,
        },
      },
    })),
  addInformationImportant: (value) =>
    set((state) => ({
      details: {
        ...state.details,
        information_importante: [
          ...state.details.information_importante,
          value,
        ],
      },
    })),
  addSuivis: (value) =>
    set((state) => ({
      details: {
        ...state.details,
        suivis: [ value,...state.details.suivis],
      },
    })),

  resetUserData: () =>
    set((state) => ({
      factureLine: initialFactureLineState,
      Email: initialEmailValue,
      details: {
        expeditor: { ...InitialUserData },
        receiver: { ...InitialUserData },
        packages: [InitialPackagesData],
        coursePackages: [InitialCoursePackage],
        comments: [],
        detail_info_supplementaire: {},
        information_importante: [],
        suivis: [],
        courses: [],
        allDocs: [],
        manifest: 0,
        emailList: [],
        facturations: [],
      }
    })),
  fetchDetails: async (expeditionId) => {
    try {
      ExpeditionService.getExpeditionDetails({ ref: expeditionId }).then(
        (data) => {
          const { data: expedition } = data.data;
          let docs = [];
          let _emails = [];


          if (expedition.expeditor?.email) {
            _emails = [
              ...expedition.expeditor.email.split(",")?.map((e) => ({
                id: uniqueId("email--"),
                email: e,
                type: "expeditor",
                label: "Expéditeur",
                phone: expedition.expeditor.phone1,
              })),
            ];
          }

          if (expedition.receiver?.email) {
            _emails = [
              ..._emails,
              ...expedition.receiver.email.split(",")?.map((e) => ({
                id: uniqueId("email--"),
                email: e,
                type: "receiver",
                label: "Destinataire",
                phone: expedition.receiver.phone1,
              })),
            ];
          }
          if (expedition.doc_accompagnement) {
            docs = [
              ...docs,
              ...expedition.doc_accompagnement?.map((f, i) => ({
                name: `Doc d'accompagnement ${i + 1}`,
                type: "PDF",
                created_at: expedition.created_at,
                document: f,
              })),
            ];
          }

          if (expedition.doc_borderaux) {
            docs = [
              ...docs,
              {
                name: `Bordereaux`,
                type: "PDF",
                created_at: expedition.created_at,
                document: expedition.doc_borderaux,
              },
            ];
          }

          if (expedition.documents) {
            docs = [
              ...docs,
              ...expedition?.documents?.map((d) => ({ ...d, name: d.nom })),
            ];
          }


          set((state) => ({
            details: {
              ...expedition,
              //   packages:expedition?.packages?.length>0?expedition.packages[0]:null,
              shipping: {
                billing_center_id: expedition?.billing_center?.id,
                code_donneur: expedition?.code_donneur ?? "",
                code_receveur: expedition?.code_receveur ?? "",
                comment: expedition?.commentaire ?? "",
                customer_ref: expedition?.customer_ref ?? "",
                delivery_date: expedition?.delivery_date,
                delivery_instruction: expedition?.delivery_instruction ?? "",
                delivery_time: expedition?.delivery_time,
                devises: expedition?.devises ?? "EUR",
                pickup_date: expedition?.pickup_date,
                pickup_instruction: expedition?.pickup_instruction ?? "",
                pickup_time: expedition?.pickup_time,
                tracking_mail: expedition?.tracking_mail ?? "",
                transport_type: expedition?.transport_type ?? "",
                type_de_roue: expedition?.type_de_roue ?? "",
                type_expedition: expedition?.type_expedition ?? "",
              },
              allDocs: docs,
              emailList: _emails,
            },
          }));
        },
        (error) => {
          console.log(error);
        }
      )
    } catch (error) {
      console.error("Erreur lors de la récupération des détails :", error);
    }
  },
}));
