import React, { useEffect, useRef, useState } from "react";
import { getFileUrl, isValidUrl } from "../../utils";

export default function FormUploadSection({
  logo,
  header,
  footer,
  onDataChange,
}) {
  return (
    <div className="flex flex-wrap items-center justify-between border-t border-b py-3">
      <FileSelector
        title="Cliquer pour choisir le logo"
        value={logo}
        onChange={(files) => {
          onDataChange?.("logo", files[0]);
        }}
      >
        <div className="text-lg">LOGO</div>
      </FileSelector>

      <FileSelector
        title="Cliquer pour choisir le header"
        value={header}
        onChange={(files) => {
          onDataChange?.("header", files[0]);
        }}
      >
        <div className="text-lg">Header</div>
      </FileSelector>

      <FileSelector
        title="Cliquer pour choisir le footer"
        value={footer}
        onChange={(files) => {
          onDataChange?.("footer", files[0]);
        }}
      >
        <div className="text-lg">Footer</div>
      </FileSelector>
    </div>
  );
}

export const FileSelector = ({ value, onChange, children }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (typeof value === "string" && value?.startsWith("uploads")) {
      setSelectedFile(getFileUrl(value))
    } /* else if (selectedFile?.startsWith("uploads")) {
      setSelectedFile(selectedFile)
    } */
    /* if (isValidUrl(value)) {
      // If value is a valid URL, set it as the selectedFile
      setSelectedFile(value);
    } */
  }, [value]);

  const handleFileInputChange = (e) => {
    const selectedFiles = e.target.files;
    if (selectedFiles.length > 0) {
      const val = URL.createObjectURL(selectedFiles[0])
      // Set the selected file for preview
      setSelectedFile(val);
      // Pass the selected files to the onChange callback
      onChange?.(selectedFiles);
    } else {
      setSelectedFile(null);
    }
  };

  //console.log({ selectedFile })

  const imageUrl = selectedFile

  return (
    <div
      className={`flex ${!value ? "items-center justify-center" : ""
        } h-28 w-28 rounded-md overflow-hidden cursor-pointer border-[1px] border-grborder-[1px] border-gray-500 border-dashed bg-gray-50`}
      onClick={() => {
        fileInputRef.current?.click();
      }}
    >
      <input
        ref={fileInputRef}
        type="file"
        hidden
        //value={value || ""}
        onChange={handleFileInputChange}
      />
      {selectedFile ? (
        <img
          src={imageUrl}
          alt="Selected File Preview"
          className="w-full h-full object-cover"
        />
      ) : (
        children
      )}
    </div>
  );
};
