import React, { useCallback, useEffect, useState } from 'react';
import employeesService from '../../../services/employees';
import Select from 'react-select';
import adminService from '../../../services/admin/adminService';

function SalarieSalarieComponent({ selectedValue, onChange, showLabel = true, ...props }) {

    const [salarieList, setSalarieList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [pageNumber, setPageNumber] = useState(1)
    const [selectedSalary, setselectedSalary] = useState({})


    useEffect(() => {
        //let pathSegments = window.location.pathname.split("/");
        const fetchData = async () => {
            try {
                const { data } = await adminService.allEmployees({
                    per_page: 1000000,
                    page: pageNumber
                });

                if (data) {
                    const isPaginated = data.data?.last_page;
                    const _items = data.data.data
                    setIsLoading(false)
                    if (Array.isArray(_items)) {
                        _items.forEach(element => {
                            let newData = {
                                label: element.nom + ' ' + element.prenom,
                                identifiant: element.id,
                                value: element,
                            }

                            setSalarieList((d) => {
                                //console.log(d)
                                // Vérifier si newData existe déjà dans le tableau
                                const newDataExists = d.some(item => item.name === newData.label);
                                // Si newData n'existe pas, ajouter newData au tableau
                                if (!newDataExists) {
                                    return [
                                        ...d,
                                        newData,
                                    ];
                                }

                                // Si newData existe déjà, retourner le tableau inchangé
                                return d;
                            });
                        });
                    }
                    // last_page
                    if (isPaginated && pageNumber < parseInt(isPaginated)) {
                        setPageNumber(pageNumber + 1)
                    }
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [pageNumber]);

    const value = salarieList.find((c) => c?.value?.id === selectedValue);

    return (
        <div>
            {showLabel &&
                <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                >
                    Sélectionner un salarié {selectedValue}
                </label>
            }

            <Select
              LoadingMessage={() => "Chargement..."}
                options={salarieList}
                value={value}
                onChange={(v) => {
                    setselectedSalary(v)
                    onChange(v)
                }}
                isLoading={isLoading}
                className='text-base'
                {...props}
            />

        </div>
    );
}

export default SalarieSalarieComponent;