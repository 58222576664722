// create CentreFacturation react service

import axios from "axios";
import TokenService from "../config/tokenService.js";

class CentreFacturationService {
  constructor() {
    TokenService.setHeader();
  }

  fetchUserBillingCenter(data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/user/billings-center`, data)
  }


  fetchCodeAdminFacturations(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/facturation-lines`,
      data
    );
  }

  fetchCodeClientFacturations(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/user/facturation-lines`,
      data
    );
  }

  fetchCentreFacturations(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/billings-centers`,
      data
    );
  }

  fetchCentreOpsFacturations(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/billings-centers`,
      data
    );
  }

  fetchCentreFacturationByUser(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/ops/billings-center/customer`,
      data
    );
  }

  fetchCentreFacturationById(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/billings-center`,
      data
    );
  }

  desactiverCentreFacturationById(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/billings-center/desactiver`,
      data
    );
  }

  activerCentreFacturationById(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/billings-center/activer`,
      data
    );
  }

  addCentreFacturation(CentreFacturation) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/billing-center/create`,
      CentreFacturation
    );
  }

  editCentreFacturation(CentreFacturation) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/billing-center/edit`,
      CentreFacturation
    );
  }

  deleteFacturationLine(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL
      }/ops/shipping/facturation-ligne/delete`,
      data
    );
  }

  AddFacturationLine(data) {
    return axios.post(
      `${process.env.REACT_APP_API_URL
      }/ops/shipping/facturation-ligne/add`,
      data
    );
  }

  getBillingCenterByCustommerIdForAdmin(data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/admin/billings-center/customer/type`, data)
  }
}

export default new CentreFacturationService();
