import React, { useEffect, useState } from "react";
import Select from "react-select";
import CountryService from "../../../services/country";
import useCountries from "../../../states/countries";
import clsx from "clsx";
import { userService } from "../../../services/user/UserService";

export default function CountryQoteSelector({
    qoteValue,
    selectedValue,
    onSelect,
    showLabel = true,
    isDisabled,
    setPhoneCode = false,
    isError,
    errorMessage
}) {
    const { countries: ListCountries, setCountries: setListCountries } = useCountries();

    const [countries, setCountries] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if(qoteValue && qoteValue!=="" && qoteValue!== undefined){
             const fetchCountries = async () => {
            setIsLoading(true);
            let requestData = {
                expedition_mode:qoteValue?.value
            }
            const { data } = await userService.countriesByQote(requestData);
            if (data.success) {
                setListCountries([...data.data])
                setCountries(
                    data.data.map((c) => ({ label: c.Value_country, value: c.id }))
                );
                setIsLoading(false);
            }
        };
        fetchCountries();
        }
       
    }, [qoteValue]);


    return (
        <div>
            {showLabel && (
                <label
                    htmlFor="countries"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                >
                    Pays*
                </label>
            )}
            <Select
                placeholder={'choix du pays'.toUpperCase()}
                className={clsx({ "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500": isError, "ring-gray-300 focus:ring-gray-500 bg-white border border-gray-500": !isError }, 'text-base ')}
                options={countries}
                value={selectedValue}
                onChange={(v) => onSelect(v) }
                isDisabled={isDisabled}
                isLoading={isLoading}
                LoadingMessage={() => "Chargement..."}
            />
            {isError &&
                <p className="mt-2 text-sm text-red-600" id="email-error">
                    {errorMessage}
                </p>
            }

            {/* <select id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5      ">
                <option selected>Choose a country</option>
                {countries.map((c, i) => (
                    <option key={i} value={c.value}>{c.label}</option>
                ))}
            </select> */}
        </div>
    );
}
