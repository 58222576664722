import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { BiCheckCircle } from 'react-icons/bi';
import clsx from 'clsx';

function InputComponent({ type = "text", value, onChange, errorMessage, placeholder = '', label = '', withLabel = false, isError = false, ...other }) {
    return (
        <div>
            {withLabel &&
                <label htmlFor={label} className="block text-sm font-medium leading-6 text-gray-900">
                    {label}
                </label>
            }

            <div className="relative rounded-md ">
                <input
                    {...other}
                    type={type}
                    name={label}
                    id={label}
                    // " lowercase ":type==="email" ,
                    className={clsx({"text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500": isError, "ring-gray-300 focus:ring-gray-500 bg-white border border-gray-500": !isError }, "px-2 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-inset  focus:ring-2 focus:ring-inset text-base sm:leading-6")}
                    // className={clsx({ "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500": isError, "ring-gray-300 focus:ring-gray-500 bg-white border border-gray-500": !isError }, "border form-control px-2 block w-full rounded-md  py-1.5 pr-10  ring-1 ring-inset  focus:ring-2 focus:ring-inset text-base sm:leading-6")}
                    placeholder={placeholder?.toUpperCase()}
                    value={value}
                    aria-invalid="true"
                    aria-describedby={label.replace(/ /g, '') + "-error"}
                    onChange={(e) =>{
                        let value = type==="email"?e.target.value?.toLowerCase():e.target.value;
                        onChange(value)
                    } }
                />

                {/* <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    {isError &&
                        <ExclamationCircleIcon className="w-5 h-5 text-red-500" aria-hidden="true" />
                    }
                </div> */}
            </div>
            {isError &&
                <p className="mt-2 text-sm text-red-600" id={label.replace(/ /g, '') + "-error"}>
                    {errorMessage}
                </p>
            }

        </div>
    );
}

export default InputComponent;





