export const DashLoader = () => {

    return (
        <div className="w-full mx-auto overflow-hidden bg-white shadow-md animate-pulse">
            <div className="p-8">
                <div className="flex h-screen gap-3 animate-pulse ">
                    <div className="w-1/4 bg-gray-200 rounded-lg " />
                    <div className="w-3/4 space-y-4 overflow-y-hidden">
                        <div className="w-full h-10 bg-gray-200 rounded-lg" />
                        <div className="w-full h-full bg-gray-200 rounded-lg " />
                    </div>
                </div>
            </div>
        </div>

    )
}