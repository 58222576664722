import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearExpeditions } from '../../../features/slices/expeditionSlice';
import { ExpeditionsView } from './Index';
import { useLocation } from 'react-router-dom';

export const OpsInProgressExpedition = ({
    category,
    type_expedition,
    route,
    title,
    subtitle,
}) => {

    const { pathname } = useLocation()

    const dispatch = useDispatch()

  

    return <ExpeditionsView category={category}
        type_expedition={type_expedition}
        route={route}
        title={title}
        subtitle={subtitle}
        key={pathname}
    />
}

export const OpsPendingExpedition = ({
    category,
    type_expedition,
    route,
    title,
    subtitle,
}) => {


    const { pathname } = useLocation()


    return <ExpeditionsView category={category}
        type_expedition={type_expedition}
        route={route}
        title={title}
        subtitle={subtitle}
        key={pathname}
    />
}

export const OpsDeliveredExpedition = ({
    category,
    type_expedition,
    route,
    title,
    subtitle,
}) => {

    const { pathname } = useLocation()

   

    return <ExpeditionsView category={category}
        type_expedition={type_expedition}
        route={route}
        title={title}
        subtitle={subtitle}
        key={pathname}
    />
}

export const OpsFacturedExpedition = ({
    category,
    type_expedition,
    route,
    title,
    subtitle,
}) => {

    const { pathname } = useLocation()


    return <ExpeditionsView category={category}
        type_expedition={type_expedition}
        route={route}
        title={title}
        subtitle={subtitle}
        key={pathname}
    />
}

export const OpsAllExpedition = ({
    category,
    type_expedition,
    route,
    title,
    subtitle,
}) => {


    const { pathname } = useLocation()

    return <ExpeditionsView category={category}
        type_expedition={type_expedition}
        route={route}
        title={title}
        subtitle={subtitle}
        key={pathname}
    />
}

export const OpsCancelledExpedition = ({
    category,
    type_expedition,
    route,
    title,
    subtitle,
}) => {

    const { pathname } = useLocation()


    return <ExpeditionsView category={category}
        type_expedition={type_expedition}
        route={route}
        title={title}
        subtitle={subtitle}
        key={pathname}
    />
}

export const OpsArchivedExpedition = ({
    category,
    type_expedition,
    route,
    title,
    subtitle,
}) => {

    const { pathname } = useLocation()

    return <ExpeditionsView category={category}
        type_expedition={"all"}
        route={route}
        title={title}
        subtitle={subtitle}
        key={pathname}
    />
}

export const OpsForArchivedExpedition = ({
    category,
    type_expedition,
    route,
    title,
    subtitle,
}) => {
    const { pathname } = useLocation()


    return <ExpeditionsView category={category}
        type_expedition={type_expedition}
        route={route}
        title={title}
        subtitle={subtitle}
        key={pathname}
    />
}