import React, { useEffect, useState } from "react";
import BillCenterSelector from "../Utils/Selectors/BillCenterSelector";
import { VerifyStep_1 } from "../../utils/Functions/verifyDropdown";
import InputComponent from "../InputComponent/InputComponent";
import { useAppContext } from "../../context";
import { ButtonComponent } from "../ButtonComponent";
import { ExpeditionStore } from "../../store/expedition";


export const ClientShippingRefference = ({}) => {


  const infoSupData = ExpeditionStore((state) => state.infoSupData);
  const upadateSupInfo = ExpeditionStore((state) => state.upadateSupInfo);

  const [errorInformation, setErrorInformation] = useState({ field: '', message: '' })
  const { isAuth, setIsAuth } = useAppContext();
  const [selectedCentre, setSelectedCentre] = useState(infoSupData?.bill_center_id);
  const nextStepper = ExpeditionStore((state) => state.nextStepper);





  const { valid, error, errorData } = VerifyStep_1(infoSupData);

  const handleNext = () => {
    if (error) {
      setErrorInformation({ ...errorData })

    } else {
      nextStepper()
    }
  };

  useEffect(() => {
    upadateSupInfo("user_id", isAuth?.id);
  }, [])
  


  return (
    <div className="content">
      <div className="mx-1">
        <div className="">
          <div className="grid-cols-2 gap-2 space-y-2 md:space-y-0 md:grid">
            <div className="">
              {" "}
              <div className="form-group">
                <BillCenterSelector
                  client={true}
                  user={isAuth?.customer_id}
                  type={isAuth?.spaceName}
                  defaultInputValue={infoSupData?.bill_center_id}
                  selectedValue={selectedCentre}
                  onSelect={(v) => {
                    upadateSupInfo("bill_center_id", v);
                    setSelectedCentre(v);
                  }}
                  isDisabled={false}
                />
              </div>
            </div>
            <div className="">
              {" "}
              <div className="form-group">
                <InputComponent
                  value={infoSupData?.customer_ref}
                  onChange={(valeur) => upadateSupInfo("customer_ref", valeur)}
                  errorMessage={errorInformation.message}
                  placeholder={"Référence du client".toUpperCase()}
                  label='' withLabel={false}
                  isError={errorInformation.field === 'customer_ref' ? true : false}
                />
              </div>
            </div>
            <div className="">
              {" "}
              <div className="form-group">
                <InputComponent
                  value={infoSupData?.username}
                  onChange={(valeur) => upadateSupInfo("username", valeur)}
                  errorMessage={errorInformation.message}
                  placeholder={"Nom d'utilisateur*".toUpperCase()}
                  label='' withLabel={false}
                  isError={errorInformation.field === 'username' ? true : false}
                />
              </div>
            </div>
            {(isAuth?.spaceName === 'medical') && <div className="">
              {" "}
              <div className="form-group">

                <InputComponent
                  value={infoSupData?.code_donneur}
                  onChange={(valeur) => upadateSupInfo("code_donneur", valeur)}
                  errorMessage={errorInformation.message}
                  placeholder={"CODE DONNEUR".toUpperCase()}
                  label='' withLabel={false}
                  isError={errorInformation.field === 'code_donneur' ? true : false}
                />
              </div>
            </div>}

            <div className="">
              {" "}
              <div className="form-group">

                <InputComponent
                  type="email"
                  value={infoSupData?.tracking_mail}
                  onChange={(valeur) => upadateSupInfo("tracking_mail", valeur)}
                  errorMessage={errorInformation.message}
                  placeholder={"MAIL DE SUIVI"}
                  label='' withLabel={false}
                  isError={errorInformation.field === 'tracking_mail' ? true : false}
                />
              </div>
            </div>
            {(isAuth?.spaceName === 'medical') && <div className="">
              {" "}
              <div className="form-group">

                <InputComponent
                  value={infoSupData?.code_receveur}
                  onChange={(valeur) => upadateSupInfo("code_receveur", valeur)}
                  errorMessage={errorInformation.message}
                  placeholder={"CODE Receveur".toUpperCase()}
                  label='' withLabel={false}
                  isError={errorInformation.field === 'code_receveur' ? true : false}
                />
              </div>
            </div>}


            <div >
              <ButtonComponent title={"Suivant"} onClick={handleNext} />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
