import clsx from 'clsx';
import React from 'react';
import { ExpeditionStore } from '../../store/expedition';
import { ErrorStore } from '../../store/inputError';

export const Princing = ({ index }) => {

    const packagesData = ExpeditionStore((state) => state.packagesData)[index];
    const updatePackagesData = ExpeditionStore((state) => state.updatePackagesData);
    const errorInformation = ErrorStore((state) => state.errorField);


    let error = ((errorInformation?.field2 === 'devises' || errorInformation?.field2 === 'value_in_douane') && index === errorInformation.field) || errorInformation?.field === 'devises' || errorInformation?.field === 'valeur_douane' ? true : false
    return (
        <>

            <div className={
                clsx({
                    'shadow-red-500': error, 'shadow-sm': !error,
                }, "relative  rounded-md   ")} >
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <span className="text-gray-500 sm:text-sm"> {index === "none" ? packagesData["devises"] === 'usd' ? "$" : (packagesData["devises"] === 'gbp' ? "£" : "€") : packagesData["devises"] === 'usd' ? "$" : (packagesData["devises"] === 'gbp' ? "£" : "€")} </span>
                </div>
                <input
                    type="text"
                    name="price"
                    id="price"
                    className={clsx({
                        "ring-red-500 placeholder:text-red-400 focus:ring-2 focus:ring-inset focus:ring-red-500": error,
                        "ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-500": !error
                    },
                        "block w-full h-[40px] rounded-sm border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset  sm:text-base sm:leading-6")}
                    placeholder="0.00"
                    value={packagesData["value_in_douane"]}
                    min={2}
                    onChange={(e) => {
                        updatePackagesData("value_in_douane", e.target.value, index)
                    }
                    }
                />
                <div className="absolute inset-y-0 right-0 flex items-center">
                    <label htmlFor="currency" className="sr-only">
                        Currency
                    </label>
                    <select
                        id="currency"
                        name="currency"
                        className={clsx({ 'text-red-600 ring-red-600': error, 'text-gray-500 focus:ring-2 focus:ring-inset focus:ring-gray-500': !error }, "h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7  sm:text-base")}
                        value={packagesData["devises"] ?? "eur"
                        }
                        onChange={(e) => {
                            updatePackagesData("devises", e.target.value, index)

                        }

                        }
                    >

                        <option value={'eur'} >EUR</option>
                        <option value={'gbp'}>GBP</option>
                        <option value={'usd'}>USD</option>

                    </select>
                </div>

            </div>

            {(errorInformation?.field2 === 'devises' || errorInformation?.field2 === 'value_in_douane') && index === errorInformation.field &&
                <p className="mt-2 text-sm text-red-600" id={"full-error"}>
                    {errorInformation.message}
                </p>
            }

            {(errorInformation?.field === 'devises' || errorInformation?.field === 'valeur_douane') &&
                <p className="mt-2 text-sm text-red-600" id={"full-error"}>
                    {errorInformation.message}
                </p>
            }




        </>

    );
}