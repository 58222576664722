import clsx from 'clsx';
import React from 'react';

function PrincingInput({ value, UpdateColisData, colis, index, errorMessage, returnValue = false }) {

    let error = ((errorMessage?.field2 === 'devises' || errorMessage?.field2 === 'value_in_douane') && index === errorMessage.field) || errorMessage?.field === 'devises' || errorMessage?.field === 'valeur_douane' ? true : false
    return (
        <>

            <div className={
                clsx({
                    'shadow-red-500': error, 'shadow-sm': !error,
                }, "relative  rounded-md   ")} >
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm"> {index === "none" ? colis["devises"] === 'usd' ? "$" : (colis["devises"] === 'gbp' ? "£" : "€") : colis[index]["devises"] === 'usd' ? "$" : (colis[index]["devises"] === 'gbp' ? "£" : "€")} </span>
                </div>
                <input
                    type="text"
                    name="price"
                    id="price"
                    className={clsx({"ring-red-500 placeholder:text-red-400 focus:ring-2 focus:ring-inset focus:ring-red-500":error,
                    "ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-500":!error},
                    "block w-full h-[40px] rounded-sm border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset  sm:text-base sm:leading-6")} 
                    placeholder="0.00"
                    value={returnValue ? value :
                        index === "none" ? colis["value_in_douane"] :
                            colis[index]["value_in_douane"]
                                ? colis[index]["value_in_douane"]
                                : ""
                    }
                    min={2}
                    onChange={(e) => {
                        if (returnValue) {

                            UpdateColisData(e.target.value)

                        }
                        else {
                            if (index === "none") {
                                UpdateColisData("value_in_douane", e.target.value)
                            } else {
                                UpdateColisData(index, "value_in_douane", e.target.value)
                            }
                        }
                    }
                    }
                />
                <div className="absolute inset-y-0 right-0 flex items-center">
                    <label htmlFor="currency" className="sr-only">
                        Currency
                    </label>
                    <select
                        id="currency"
                        name="currency"
                        className= { clsx({'text-red-600 ring-red-600':error,'text-gray-500 focus:ring-2 focus:ring-inset focus:ring-gray-500':!error},"h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7  sm:text-base") }
                        value={index === "none" ? colis["devises"] :
                            colis[index]["devises"]
                                ? colis[index]["devises"]
                                : "eur"
                        }
                        onChange={(e) => {
                            if (index === "none") {
                                UpdateColisData("devises", e.target.value)
                            } else {
                                UpdateColisData(index, "devises", e.target.value)
                            }

                        }

                        }
                    >

                        <option value={'eur'} >EUR</option>
                        <option value={'gbp'}>GBP</option>
                        <option value={'usd'}>USD</option>

                    </select>
                </div>

            </div>

            {(errorMessage?.field2 === 'devises' || errorMessage?.field2 === 'value_in_douane') && index === errorMessage.field &&
                <p className="mt-2 text-sm text-red-600" id={"full-error"}>
                    {errorMessage.message}
                </p>
            }

            {(errorMessage?.field === 'devises' || errorMessage?.field === 'valeur_douane') &&
                <p className="mt-2 text-sm text-red-600" id={"full-error"}>
                    {errorMessage.message}
                </p>
            }




        </>

    );
}

export default PrincingInput;