import React, { useCallback } from "react";

const useFormValues = (initialValue = {}) => {
  const [data, setData] = React.useState({
    name: "",
    exnet_paris: 0,
    exnet_medical: 0,
    exnet_service: 0,
    is_sup: 0,
    restreint: false,
    email: "",
    email_verified_at: "",
    password: "",
    remember_token: "",
    is_actif: 0,
    country: "fr",
    postal_code: "",
    city: "",
    poste: "",
    comments: "",
    address1: "",
    address2: "",
    telephone1: "",
    telephone2: "",
    email1: "",
    email2: "",
    is_ops_admin: 0,
    num_code: "",
    num_code1: "",
    num_code2: "",
    calculatrice: 0,
    physic_company: "",
    type: "",
    roles: [],
    types: [],
    menus: [],
    sub_menus: [],
    customer_id: "",
    expedition: 0,
    user_type: "",
    ...initialValue,
  });

  const setFormValue = (key, value) => {
    //console.log(`${key}---${value}`);
    setData((d) => ({
      ...d,
      [key]: value,
    }));
  };

  return {
    data,
    setData,
    setFormValue,
  };
};

export const useBasicFormValues = (initialValue = {}) => {
  const [data, setData] = React.useState(initialValue);

  const setFormValue = (key, value) => {
    //console.log(`${key}---${value}`);
    setData((d) => ({
      ...d,
      [key]: value,
    }));
  };

  /* Change form data with input's name and value */
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const resetData = useCallback(() => {
    setData({ ...initialValue });
  });

  return {
    data,
    setData,
    setFormValue,
    handleChange,
    resetData,
  };
};

export default useFormValues;
