export const Paragraphe = ({ label, value, mail = false }) => {

    return <div className="my-2">
        <span className="text-sm font-semibold">{label} : </span>

        {mail ? <>
            {value.split(',').map((item, index) => (
                <>
                    {index === 0 && <span className="text-sm ml-1">{item}</span>}

                    {index > 0 && <span className="text-sm ml-1 block">{item}</span>}
                </>

            ))}
        </> :
            <>
                <span className="text-sm ml-1">{value}</span>
            </>
        }

    </div>
}
