export const getValue = (value1, value2) =>
  value1 !== undefined && value1 !== ""
    ? value1
    : value2 !== undefined && value2 !== ""
      ? value2
      : "";


// Fonction utilitaire pour extraire le prénom et le nom
export const parseName = (fullname, name,data) => {
  const combinedName = fullname || name || "";
  const [firstName, lastName] = combinedName.split(" ");
  return {
    firstName: firstName || data?.firstName,
    lastName: lastName && lastName !== "undefined" ? lastName : data?.lastName,
  };
};

export const formatDateForInput=(dateString)=> {
  // Crée un objet Date à partir de la chaîne de date fournie
  const date = new Date(dateString);

  // Obtenir les composants de la date
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Ajouter 1 au mois
  const day = String(date.getDate()).padStart(2, "0");

  // Retourner la date formatée en YYYY-MM-DD
  return `${year}-${month}-${day}`;
}