import { create } from "zustand";


export const ExpeditionStore = create((set) => ({
    expeditionData: {},
    receiverData: {},
    senderData: {},
    setReceiverData: (newData) => set((store) => ({
        receiverData: { ...newData }
    })),
    updateReceiverData: (newData) => set((store) => ({
        receiverData: { ...store.receiverData, ...newData }
    })),
    setSenderData: (newData) => set((store) => ({
        senderData: { ...newData }
    })),
    updateSenderData: (newData) => set((store) => ({
        senderData: { ...store.senderData, ...newData }
    })),
    setExpeditionData: (newData) => set((store) => ({
        expeditionData: { ...newData }
    }))
}))