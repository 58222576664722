import React, { Fragment, useCallback, useEffect, useState } from "react";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TbArrowsUpDown, TbFileDownload } from "react-icons/tb";
import xlsx from "export-json-as-xlsx"
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { LinkData, exnetSpaceColor, urlSplit } from "../../../../utils/Functions/otherFunctions";
import { ConfirmModal } from "../../../Utils/DeleteConfirm";
import factureService from "../../../../services/facture/factureService";
import useDisclosure from "../../../../hooks/useDisclosure";
import LoaderTableau from "../../../tableauComponent/LoaderTableau";
import { trierTableau } from "../../../../utils/Functions/tableFilter";
import { getFactureRef, toFixed2 } from "../../../../utils"
import { TableComponent } from "../../../TableauComponent";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";


const entete_facture2 = [
    {
        filterKey: "ref",
        label: "Facture"
    },
    {
        filterKey: "billingCenterName",
        label: "Société"
    },
    {
        filterKey: "billingCenterAdresse",
        label: "Adresse"
    },
    {
        filterKey: "billingCenterPostalCode",
        label: "Code postal"
    },
    {
        filterKey: "billingCenterCreatedAt",
        label: "Date création"
    },
    {
        filterKey: "echeance",
        label: "Date d'échéance"
    },
    {
        filterKey: "statut",
        label: "Statut"
    },
    {
        filterKey: "fuelHT",
        label: "Montant ht"
    },
    {
        filterKey: "montantHT",
        label: "Fuel ht"
    },
    {
        filterKey: "totalHT",
        label: "Montant total ht"
    },
    {
        filterKey: "tva",
        label: "Tva"
    },
    {
        filterKey: "totalTTC",
        label: "Montant total ttc"
    }
]


const entete_facture = [
    {
        label: "Facture",
        cle: 'ref'
    },
    {
        label: "Société",
        cle: "billing_center.billing_center_name"
    },
    {
        label: "Adresse",
        cle: 'billing_center.adresse'
    },
    {
        label: "Code postal",
        cle: 'billing_center.postal_code'
    },
    {
        label: "Date création",
        cle: 'billing_center.created_at'
    },
    {
        label: "Date d'échéance",
        cle: 'echeance'
    },
    {
        label: "Statut",
        cle: 'statut'
    },
    {
        label: "Montant ht",
        cle: 'fuel'
    },
    {
        label: "Fuel ht",
        cle: 'montant'
    },
    {
        label: "Montant total ht",
        cle: 'total'
    },
    {
        label: "Tva",
        cle: 'tva'
    },
    {
        label: "Montant total ttc",
        cle: 'fuel'
    }
];

const extraction_facture = [
    {
        label: "Date création",
        cle: 'createdAt'
    },
    {
        label: "Date facture",
        cle: 'factureCreatedAt'
    },
    {
        label: "N° facture ID",
        cle: 'ref'
    },
    {
        label: "Client",
        cle: 'client'
    },
    /* {
        label: "Adresse",
        cle: 'address'
    }, */
    {
        label: "Centre de facturation",
        cle: "billingCenter"
    },
    {
        label: "Bon de commande",
        cle: "bonCommande"
    },
    /* {
        label: "Code postal",
        cle: 'postalCode'
    }, */
    {
        label: "Date d'échéance",
        cle: 'echeance'
    },
    /* {
        label: "Statut",
        cle: 'statut'
    }, */
    {
        label: "TOTAL HT",
        cle: 'totalHT'
    },
    {
        label: "TVA",
        cle: 'tva'
    },
    {
        label: "TOTAL TTC",
        cle: 'totalTTC'
    },
    /* {
        label: "Fuel ht",
        cle: 'montant'
    },
    {
        label: "Montant total ht",
        cle: 'total'
    }, */

];


const excelHeader = [
    {
        label: "ref",
        cle: 'ref'
    },
    {
        label: "demandeur",
        cle: ''
    },
    {
        label: "Exp",
        cle: ''
    },
    {
        label: "expCity",
        cle: ''
    },
    {
        label: "Dest",
        cle: ''
    },
    {
        label: "DestCity",
        cle: ''
    },
    {
        label: "createdAt",
        cle: ''
    },
    {
        label: "pickup",
        cle: ''
    },
    {
        label: "delivery",
        cle: ''
    },
    {
        label: "refInt",
        cle: ''
    },
    {
        label: "libelle",
        cle: ''
    },
    {
        label: "description",
        cle: ''
    },
    {
        label: "commentaire",
        cle: ''
    },
    {
        label: "PrixU",
        cle: ''
    },
    {
        label: "qte",
        cle: ''
    },
    {
        label: "fuel",
        cle: ''
    },
    {
        label: "Amount",
        cle: ''
    },
    {
        label: "tva",
        cle: ''
    },
    {
        label: "total",
        cle: ''
    }
]


export default function GlobalList({
    items,
    setItems,
    isLoading,
}) {

    const [entete, setEntete] = useState([]);
    const { pathname } = useLocation();
    const [selectedItem, setSelectedItem] = useState(null);
    const [filterOrder, setfilterOrder] = useState('desc');
    const [filterBy, setfilterBy] = useState('ref');
    const { exnetSpace } = urlSplit()

    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        setEntete(entete_facture);
    }, []);

    const modifiedText = (text) => {
        return text
            ? text.split(" ").map((word, index) => {
                return index !== 0 && index % 3 === 0
                    ? [<br key={index} />, word]
                    : " " + word;
            })
            : "-";
    };


    const filteredArray = items.reduce((uniqueArray, currentItem) => {
        // Check if an object with the same value exists in the uniqueArray
        const existingItem = uniqueArray.find(item => item.id === currentItem.id);

        // If not found, add the current item to the uniqueArray
        if (!existingItem) {
            uniqueArray.push(currentItem);
        }

        return uniqueArray;
    }, []);

    // const sortedArray = filteredArray.sort((a, b) => {
    //     return parseInt(b.id) - parseInt(a.id);
    // });

    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();
    const offset = currentPage * 25;
    const currentPageData = filteredArray.slice(offset, offset + 25);

    const handlePageClick = (event) => {
        setCurrentPage(event.selected);
    };
    const lien = window.location.pathname.split("/");
    let { espace } = LinkData(lien);

    const downloadPDF = (item) => {
        const pdfUrl = `${process.env.REACT_APP_BACKEND_HOST}/${item.doc_facture}`;
        setSelectedItem(item);

        //const url = URL.createObjectURL(pdfBlob);
        const fileName = pdfUrl.split("factures/")[1]
        const a = document.createElement("a");
        a.href = pdfUrl;
        a.target = "_blank"
        a.download = fileName + ".pdf";
        document.body.appendChild(a);
        a.click();

        // Clean up
        //window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        setSelectedItem(null)
    }

    const onRedition = (item) => {
        setSelectedItem(item);
        onOpen()
    }

    //console.log({ selectedItem })

    const onReedited = useCallback(() => {
        const data = items.map(item => selectedItem?.id === item.id ? ({
            ...item,
            active_for_reedited: 1
        }) : item);

        setItems(d => ({
            ...d,
            data
        }));

        setSelectedItem(null)
    }, [selectedItem, items])

    const DownloadExcelCustom = async () => {
        let workbook = new ExcelJS.Workbook();
        let worksheet = workbook.addWorksheet('Liste Globale', {
            headerFooter: { firstHeader: "Hello Exceljs", firstFooter: "Hello World" }
        });

        // Fusion des cellules pour les 5 premières lignes et 15 premières colonnes
        let bill_center = items[0].billing_center;

        // Fusion des cellules pour les 5 premières lignes et 15 premières colonnes
        const A2 = worksheet.getCell('A2');
        A2.value = "Adresse de Facturation";
        A2.font = { bold: true };
        const A3 = worksheet.getCell('A3');
        A3.value = bill_center?.billing_center_name;
        A3.font = { bold: true };
        const A4 = worksheet.getCell('A4');
        A4.value = bill_center?.address;
        A4.font = { bold: true };
        const A5 = worksheet.getCell('A5');
        A5.value = `${bill_center?.postal_code}-${bill_center?.city}-${bill_center?.country}`;
        A5.font = { bold: true };
        const A6 = worksheet.getCell('A6');
        A6.value = `TVA Intracom :  ${bill_center?.intra_vatnumber}`;
        A6.font = { bold: true };

        // Définition des colonnes avec leurs en-têtes
        let newValueTable = [];
        extraction_facture.forEach((item, index) => {
            newValueTable.push(item.label);
        });

        // Ajout des colonnes avec des en-têtes à partir de la ligne 10
        let row10 = worksheet.getRow(10);
        row10.values = newValueTable;

        // Appliquer une largeur minimale de 40 à chaque colonne
        newValueTable.forEach((label, index) => {
            const columnKey = index + 1; // La première colonne a l'index 1
            const column = worksheet.getColumn(columnKey);
            column.width = Math.max(40, label.length); // Utilisez la longueur du libellé comme largeur minimale
        });

        // Appliquer le style de bordure aux en-têtes de colonne
        row10.font = { bold: true };
        row10.eachCell(cell => {
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        });

        // Ajouter des données à partir de la ligne 11
        let rowNumber = 11; // Commencer à partir de la ligne 11


        let fuel = 0.0;
        let total = 0.0;
        let tva = 0.0;

        let nbr = 0
        items.forEach(item => {
            let newArray = item.shipping_ids;
            newArray.forEach(element => {
                let rowData = [];

                rowData.push(moment(item?.created_at).format("DD-MM-YYYY HH:mm"));

                rowData.push(moment(element?.created_at).format("DD-MM-YYYY HH:mm"));

                rowData.push(`${item.type === "paris" ? "EX" : item.type === "medical" ? "EM" : "ES"}-${element.ref}`);

                rowData.push(element.expeditor?.fullname);

                rowData.push(item.billing_center?.adresse)

                // rowData.push(item.billing_center.billing_center_name);

                rowData.push(item.bon_de_commende);

                rowData.push(item.echeance ? moment(item.echeance).format("DD-MM-YYYY HH:mm") : "-");

                // rowData.push(item?.billing_center?.billing_center_name);

                rowData.push(item.montant);// + item.fuel

                rowData.push(item.tva);

                rowData.push(parseFloat(parseFloat(item?.tva) + parseFloat(item?.montant)));//parseFloat(item?.fuel)


                // Ajouter la ligne de données à la feuille de calcul
                worksheet.addRow(rowData, rowNumber);
                rowNumber++; // Incrémenter le numéro de ligne

                tva += parseFloat(item.tva)
                // 
                fuel += parseFloat(item.montant + item.fuel)
                total += parseFloat(parseFloat(item?.tva) + parseFloat(item?.montant) + parseFloat(item?.fuel));//

                nbr += 1;
            });
        });


        // let allDataTable = ["", "", "", "", "", "", "Total", fuel, tva, total];

        let totalFuel = { formula: `ROUND(SUM(H11:H${nbr + 10}), 2)` };
        let totalTva = { formula: `ROUND(SUM(I11:I${nbr + 10}), 2)` };
        let totalTtc = { formula: `ROUND(SUM(J11:J${nbr + 10}) + SUM(H11:H${nbr + 10}) * 0.2, 2)` };
        let allDataTable = ["", "", "", "", "", "", "Total", totalFuel, totalTva, totalTtc]

        worksheet.addRow(allDataTable, rowNumber + 1);
        let nextRow = worksheet.getRow(rowNumber + 1);
        nextRow.font = { bold: true };
        nextRow.eachCell(cell => {
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        })

        // Appliquer les styles de bordure uniquement aux données
        worksheet.eachRow(function (row, rowNumber) {
            if (rowNumber > 10) {
                row.eachCell(function (cell, colNumber) {
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                });
            }
        });

        let sizeArray = items.length;
        let firstElement = items[0]

        // let fileName = `Liste_globale_${bill_center?.billing_center_name}_${moment(firstElement?.created_at).format("DD-MM-YYYY")}_${moment(lastElement?.created_at).format("DD-MM-YYYY")}.xlsx`

        let month = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

        let fileName = `${bill_center?.billing_center_name} ${month[moment(firstElement?.created_at).format("MM") - 1]} ${moment(firstElement?.created_at).format("YYYY")}.xlsx`
        // Écrire dans un tampon et sauvegarder le fichier
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), fileName);
    };

    const returnComment = (value) => {
        if (value) {
            let newValue = value?.comments;
            if (newValue?.length > 0) {
                let { content } = newValue[newValue?.length - 1]
                return (content)
            } else {
                return ''
            }

        }
    }

    const updateFilterListe2 = (filterKey) => {
        //console.log({ filterKey })
        if (filterKey) {
            setfilterBy(filterKey)
            let newOrderFilter = filterOrder === 'desc' ? 'asc' : 'desc'
            setfilterOrder(newOrderFilter)

        }
    }

    const newTable = trierTableau(filteredArray, filterBy, filterOrder)

    return (
        <>

            <div>
                {items.length > 0 &&
                    <div>

                        <button className={'btn'} onClick={DownloadExcelCustom}>Télécharger</button>
                    </div>
                }

                <TableComponent withAction={false} headerList={entete_facture2} dataLength={newTable.length} isLoading={isLoading}>
                    {newTable.map((item, index) => {
                        const ref = getFactureRef(item)
                        return (
                            <>
                                {item.shipping_ids.map((element, shipingIndex) => (
                                    <tr>
                                        <td
                                            style={{ color: exnetSpaceColor(item.type) }}
                                        >
                                            {ref}
                                        </td>
                                        <td>
                                            {item?.billing_center?.billing_center_name}
                                        </td>
                                        <td>
                                            {item?.billing_center?.address}
                                        </td>
                                        <td>
                                            {item?.billing_center?.postal_code}
                                        </td>
                                        <td>
                                            {moment(item?.created_at).format("YYYY-MM-DD HH:mm")}
                                        </td>
                                        <td>
                                            {item ? moment(item.echeance).format("YYYY-MM-DD HH:mm") : "-"}
                                        </td>
                                        <td>
                                            {item?.statut}
                                        </td>
                                        <td>
                                            {toFixed2(item?.montant - item?.fuel)}
                                        </td>
                                        <td>
                                            {toFixed2(item?.fuel)}
                                        </td>
                                        <td>
                                            {toFixed2(item?.montant)}
                                        </td>
                                        <td>
                                            {toFixed2(item?.tva)}
                                        </td>
                                        <td>
                                            {toFixed2(item.total)}
                                        </td>
                                        <td>

                                            {(Boolean(item.active_for_reedited) || Boolean(item.doc_facture)) && (

                                                <div>
                                                    <Menu as="div" className="relative flex items-start justify-center w-auto text-center ">
                                                        <Menu.Button className="-m-1.5 flex items-start p-1.5 md:mr-0 justify-center">
                                                            <span className="sr-only">Open user menu</span>
                                                            <EllipsisVerticalIcon className="w-8 h-8 text-6xl font-bold text-right " />

                                                        </Menu.Button>
                                                        <Transition
                                                            as={Fragment}
                                                            enter="transition ease-out duration-100"
                                                            enterFrom="transform opacity-0 scale-95"
                                                            enterTo="transform opacity-100 scale-100"
                                                            leave="transition ease-in duration-75"
                                                            leaveFrom="transform opacity-100 scale-100"
                                                            leaveTo="transform opacity-0 scale-95"
                                                            className={"divide-y-2 space-y-1"}
                                                        >
                                                            <Menu.Items className="absolute right-0 z-10 mt-2.5 w-44 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">

                                                                {item.doc_facture &&
                                                                    <Menu.Item key={"item.name"}>
                                                                        <div
                                                                            onClick={() => {
                                                                                downloadPDF(item)
                                                                            }}
                                                                            className="mb-1 cursor-pointer "
                                                                        >
                                                                            <i className={`fa fa-download text-lg mr-3`}></i>
                                                                            Télécharger
                                                                        </div>
                                                                    </Menu.Item>
                                                                }

                                                                {!Boolean(item.active_for_reedited) && (
                                                                    <Menu.Item key={"item.name"}>
                                                                        <div
                                                                            className="px-3 mb-1 text-left cursor-pointer"
                                                                            onClick={() => {
                                                                                onRedition(item)
                                                                            }}
                                                                        >
                                                                            <i className={`fa fa-edit text-lg mr-3`}></i>
                                                                            Réediter
                                                                        </div>
                                                                    </Menu.Item>
                                                                )}




                                                            </Menu.Items>
                                                        </Transition>
                                                    </Menu>
                                                </div>
                                            )}
                                        </td>

                                    </tr>
                                ))}

                            </>

                        )
                    })}
                </TableComponent>

                {
                    selectedItem && <ConfirmModal
                        title={`Réédition de facture`}
                        description={"Etes-vous sûr de vouloir rééditer cette facture? Cela entraînera la suppression de la facture actuelle"}
                        isOpen={isOpen}
                        onClose={onClose}
                        confirmRequest={() => factureService.reeditFacture({ id: selectedItem.id })}
                        onConfirm={onReedited}
                        successText={"Facture rééditée avec succès"}
                        submitText={"Oui, rééditer"}
                        submittingText={"Réédition..."}
                    />
                }
            </div >
        </>

    );
}

