import React, { useCallback, useEffect, useState } from 'react'
import ExnetTypeSelector from '../../components/Utils/Selectors/ExnetTypeSelector';
import { TableLoader } from '../../components/Utils/Loader';
import exploitation from '../../services/exploitation';
import { BiDetail } from "react-icons/bi";
import { RiBox3Fill } from "react-icons/ri";
import DetailsDriver from '../../components/Exploitation/Details';
import DriverShippings from '../../components/Exploitation/Shippings';
import { exnetSpaceColor } from '../../utils/Functions/otherFunctions';
import { useNavigate } from "react-router-dom";
import useTableFilter from '../../hooks/useTableFilter';
import { TbArrowsUpDown } from 'react-icons/tb';


export default function ExploitationDrivers() {
    const [type, setType] = useState("paris");
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [detailView, setDetailView] = useState("");

    const [isLoading, setIsLoading] = useState(true);
    const [metadata, setMetadata] = useState({
        page: 1,
        search: "",
    });

    const { page, search } = metadata;

    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true)
                const { data } = await exploitation.getDrivers({ interface: type, page, per_page: 100 });
                //console.log({ data })
                if (data.success) {
                    const _items = data.data
                    setItems(i => page === 1 ? _items : [...i, ..._items])
                }
            } catch (error) {
                console.error(error)
            } finally {
                setIsLoading(false)

            }
        })()
    }, [type, page])

    const onInterfaceChange = useCallback(
        (v) => {
            navigate(`/exploitation/${v.value}/drivers`)
            setType(v.value)
        },
        [navigate],
    )

    return (
        <div className="p-6">
            {selectedItem ?
                (
                    detailView === "DETAILS" ?
                        <DetailsDriver
                            data={selectedItem}
                            onBack={() => {
                                setSelectedItem(null)
                            }}
                        />
                        : detailView === "SHIPPINGS" ?
                            <DriverShippings
                                user={selectedItem}
                                onBack={() => {
                                    setSelectedItem(null)
                                }}
                            /> : null
                )
                : (
                    <div>
                        <div className="flex items-center mb-10">
                            <h4 className="uppercase page-title mb-0">Liste des chauffeurs</h4>
                            <div className="min-w-[350px] mx-10">
                                <input
                                    className="w-full bg-white border border-gray-500 rounded-lg px-4 py-2 outline-none form-control"
                                    type="search"
                                    placeholder="Recherche"
                                    value={search}
                                    onChange={(e) => {
                                        setMetadata(m => ({
                                            ...m,
                                            search: e.target.value
                                        }))
                                    }}
                                />
                            </div>
                            <div className="min-w-[240px]">
                                <ExnetTypeSelector
                                    showLabel={false}
                                    placeholder={"Filtre par type"}
                                    value={type}
                                    onChange={onInterfaceChange}
                                    multiple={false}
                                />
                            </div>
                        </div>
                        <Lists data={items} isLoading={isLoading}
                            onSelect={setSelectedItem}
                            onDetailSelect={(item) => {
                                setSelectedItem(item)
                                setDetailView("DETAILS")
                            }}
                            onShippingSelect={(item) => {
                                setSelectedItem(item)
                                setDetailView("SHIPPINGS")
                            }}
                            type={type}
                        />
                    </div>
                )}
        </div>
    )
}


const Lists = ({ data, onDetailSelect, onShippingSelect, isLoading, type }) => {

    const { results, onTableHeaderClick } = useTableFilter({ items: data });

    const HEADERS = [
        { label: '#', key: 'id' },
        { label: 'Nom du chauffeur', key: 'name' },
        { label: 'Type', key: 'type' },
        { label: 'Téléphone', key: 'telephone1' },
        { label: 'Email', key: 'email' },
        { label: 'Détails', key: null },
        { label: 'Expéditions attribuées', key: null },
    ];

    return (
        <div className="relative overflow-x-auto">
            <div className="bg-white">
                <div className="card-block">
                    {/*                     <h4 className="card-title">Basic Table</h4>*/}
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <thead style={{ backgroundColor: exnetSpaceColor(type) }} className={``}>
                                <tr className='uppercase px-5'>
                                    {HEADERS.map((h, i) => (
                                        <th key={i} className="text-left cursor-pointer" onClick={() => {
                                            onTableHeaderClick(h.key)
                                        }}>
                                            <div className="flex items-center">
                                                <span className='mr-2'>
                                                    {h.label}
                                                </span>
                                                {h.key && <span>
                                                    <TbArrowsUpDown />
                                                </span>}
                                            </div>

                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {results.length > 0 && (
                                    results.map((item, i) => {
                                        return (
                                            <tr key={i} className="px-5">
                                                <td className="cursor-pointer" onClick={() => {
                                                    onDetailSelect(item)
                                                }}>{item.id}</td>
                                                <td className="cursor-pointer" onClick={() => {
                                                    onDetailSelect(item)
                                                }}>{item.name}</td>
                                                <td className="cursor-pointer" onClick={() => {
                                                    onDetailSelect(item)
                                                }}>
                                                    {item.type?.split(",").filter(t => t !== "isdriver").map((t, i) => (
                                                        <div
                                                            key={i}
                                                            className="mb-1 text-[12px] rounded-md text-center"
                                                            style={{
                                                                backgroundColor: exnetSpaceColor(t)
                                                            }}
                                                        >
                                                            {t?.endsWith("s") ? t : `${t}s`}
                                                        </div>
                                                    ))}
                                                </td>
                                                <td className="cursor-pointer" onClick={() => {
                                                    onDetailSelect(item)
                                                }}>{item.telephone1}</td>
                                                <td className="cursor-pointer" onClick={() => {
                                                    onDetailSelect(item)
                                                }}>{item.email}</td>
                                                <td>
                                                    <div className="cursor-pointer" onClick={() => {
                                                        onDetailSelect(item)
                                                    }}>
                                                        <BiDetail
                                                            style={{
                                                                color: exnetSpaceColor(type)
                                                            }}
                                                            className={`text-lg`} />
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className="cursor-pointer" onClick={() => {
                                                        onShippingSelect(item)
                                                    }}>
                                                        <RiBox3Fill
                                                            style={{
                                                                color: exnetSpaceColor(type)
                                                            }}
                                                            className='text-lg' />
                                                    </div>
                                                </td>

                                            </tr>
                                        )
                                    }
                                    )
                                )}
                            </tbody>
                        </table>
                        {isLoading ? <TableLoader /> : results.length === 0 ? (
                            <h6 className="text-center text-lg my-5">Aucune donnée</h6>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}