import React, { Fragment, useCallback, useEffect, useState } from "react";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { TbArrowsUpDown, TbFileDownload } from "react-icons/tb";
import xlsx from "export-json-as-xlsx"
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { LinkData, exnetSpaceColor, urlSplit } from "../../../../utils/Functions/otherFunctions";
import { ConfirmModal } from "../../../Utils/DeleteConfirm";
import factureService from "../../../../services/facture/factureService";
import useDisclosure from "../../../../hooks/useDisclosure";
import LoaderTableau from "../../../tableauComponent/LoaderTableau";
import { trierTableau } from "../../../../utils/Functions/tableFilter";
import { getFactureRef, toFixed2 } from "../../../../utils";
import AvoirShippings from "../Avoir/Shippings";
import useFacturations from "../../../../states/facturations"
import { TableComponent } from "../../../TableauComponent";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { FactureListStore } from "../../../../store/FactureListStore";
import { NouvelTableau } from "../../../NouvelTableau";


const entete_facture2 = [
    {
        filterKey: "ref",
        label: "Facture"
    },
    {
        filterKey: "billing_center.billing_center_name",
        label: "Société"
    },
    {
        filterKey: "billing_center.address",
        label: "Adresse"
    },
    {
        filterKey: "billing_center.postal_code",
        label: "Code postal"
    },
    {
        filterKey: "created_at",
        label: "Date création"
    },
    {
        filterKey: "echeance",
        label: "Date d'échéance"
    },
    {
        filterKey: "statut",
        label: "Statut"
    },
    {
        filterKey: "montantHT",
        label: "Montant ht"
    },
    {
        filterKey: "fuelHT",
        label: "Fuel ht"
    },
    {
        filterKey: "totalHT",
        label: "Montant total ht"
    },
    {
        filterKey: "tva",
        label: "Tva"
    },
    {
        filterKey: "totalTTC",
        label: "Montant total ttc"
    },

]



const entete_facture = [
    {
        label: "Facture",
        cle: 'ref'
    },
    {
        label: "Société",
        cle: 'billing_center.billing_center_name'
    },
    {
        label: "Adresse",
        cle: 'billing_center.address'
    },
    {
        label: "Code postal",
        cle: 'billing_center.postal_code'
    },
    {
        label: "Date création",
        cle: 'billing_center?.created_at'
    },
    {
        label: "Date d'échéance",
        cle: 'echeance'
    },
    {
        label: "Statut",
        cle: 'statut'
    },
    {
        label: "Montant ht",
        cle: 'montant'
    },
    {
        label: "Fuel ht",
        cle: 'fuel'
    },
    {
        label: "Montant total ht",
        cle: 'total'
    }, {
        label: "Tva",
        cle: 'tva'
    },
    {
        label: "Montant total ttc",
        cle: 'fuel'
    },
    {
        label: "Actions",
        cle: ''
    }





];


const excelHeader = [
    "ref",
    "demandeur",
    "Exp",
    "expCity",
    "Dest",
    "DestCity",
    "createdAt",
    "pickup",
    "delivery",
    "refInt",
    "libelle",
    "description",
    "commentaire",
    "PrixU",
    "qte",
    "fuel",
    "Amount",
    "tva",
    "total"
]

export default function SimpleList({
    items,
    setItems,
    isLoading,
}) {

    const [entete, setEntete] = useState([]);
    const { pathname } = useLocation();
    const [selectedItem, setSelectedItem] = useState(null);

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [filterOrder, setfilterOrder] = useState('desc');
    const [filterBy, setfilterBy] = useState('ref');

    const [selectedFacture, setSelectedFacture] = useState(null);
    const { isOpen: isAvoirOpen, onOpen: onAvoirOpen, onClose: onAvoirClose } = useDisclosure()
    const { setAvoirFacture } = useFacturations()

    useEffect(() => {
        setEntete(entete_facture);
    }, []);

    const filteredArray = items.reduce((uniqueArray, currentItem) => {
        // Check if an object with the same value exists in the uniqueArray
        const existingItem = uniqueArray.find(item => item.id === currentItem.id);

        // If not found, add the current item to the uniqueArray
        if (!existingItem) {
            uniqueArray.push(currentItem);
        }

        return uniqueArray;
    }, []);

    // const sortedArray = filteredArray.sort((a, b) => {
    //     return parseInt(b.id) - parseInt(a.id);
    // });

    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();
    const offset = currentPage * 25;
    const currentPageData = filteredArray.slice(offset, offset + 25);
    const { exnetSpace } = urlSplit()

    const handlePageClick = (event) => {
        setCurrentPage(event.selected);
    };
    const lien = window.location.pathname.split("/");
    let { espace } = LinkData(lien);

    const downloadPDF = (item) => {
        const pdfUrl = `${process.env.REACT_APP_BACKEND_HOST}/${item.doc_facture}`;
        setSelectedItem(item);

        const fName = item?.doc_facture?.split("F-")[1]
        const filename = `F-${fName}`
        //console.log({ item, fName, filename });


        //const url = URL.createObjectURL(pdfBlob);
        //const fileName = pdfUrl.split("factures/")[1]
        const a = document.createElement("a");
        a.href = pdfUrl;
        a.target = "_blank"
        a.download = filename + ".pdf";
        document.body.appendChild(a);
        a.click();

        // Clean up
        //window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        setSelectedItem(null)
    }

    const onRedition = (item) => {
        setSelectedItem(item);
        onOpen()
    }

    //console.log({ selectedItem })

    const onReedited = useCallback(() => {
        const data = items.map(item => selectedItem?.id === item.id ? ({
            ...item,
            active_for_reedited: 1
        }) : item);

        setItems(d => ({
            ...d,
            data
        }));

        setSelectedItem(null)
    }, [selectedItem, items])


    const avoirSelected = useCallback((facture) => {
        setSelectedFacture(facture)
        setAvoirFacture(facture)
        onAvoirOpen()
    }, [onAvoirOpen, setAvoirFacture])


    const DownloadExcelCustom = async () => {
        let workbook = new ExcelJS.Workbook();
        let worksheet = workbook.addWorksheet('Ma Feuille');

        // // Définir les en-têtes
        // worksheet.columns = [
        //     { header: 'Colonne 1', key: 'col1', width: 30 },
        //     { header: 'Colonne 2', key: 'col2', width: 30 },
        //     // Autres colonnes...
        // ];

        let newValueTable = []
        entete_facture.forEach((item, index) => {
            newValueTable.push({ header: item, key: excelHeader[index], width: 30 })
        })

        worksheet.columns = newValueTable;

        worksheet.getRow(1).font = { bold: true };


        // Ajouter des données
        let newData = [];

        items.forEach(item => {
            let newArray = item.shipping_ids
            newArray?.forEach(element => {
                let newElement = {
                    ref: `${item.type === "paris" ? "EX" : item.type === "medical" ? "EM" : "ES"}-${element.ref}`,
                    demandeur: item?.billing_center?.billing_center_name,
                    Exp: element?.expeditor?.fullname,
                    expCity: element?.expeditor?.city,
                    Dest: element?.receiver?.fullname,
                    DestCity: element?.receiver?.city,
                    createdAt: moment(element?.created_at).format("DD-MM-YYYY HH:mm"),
                    pickup: moment(element?.pickup_date).format("DD-MM-YYYY"),
                    delivery: moment(element?.delivery_date).format("DD-MM-YYYY"),
                    refInt: element?.customer_ref,
                    libelle: item?.bon_de_commende,
                    description: '',
                    commentaire: returnComment(element),
                    PrixU: item?.montant,
                    qte: item.shipping_ids?.length,
                    fuel: item?.fuel,
                    Amount: item?.total,
                    tva: item?.tva,
                    total: parseFloat(parseFloat(item?.fuel) + parseFloat(item?.tva) + parseFloat(item?.montant)).toFixed(3)
                };

                newData.push(newElement)
            })
        });

        alert('dnsfdfksd')

        const data = [...newData]

        data.forEach(row => {
            worksheet.addRow(row);
        });

        // Appliquer les styles de bordure à toutes les lignes, y compris les données
        worksheet.eachRow(function (row, rowNumber) {
            row.eachCell(function (cell, colNumber) {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });
        });

        // Écrire dans un tampon et sauvegarder le fichier
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), 'monTableauPersonnalise.xlsx');
    };


    const DownloadXls = () => {

        let newData = [];

        items.forEach(item => {
            let newArray = item.shipping_ids
            newArray?.forEach(element => {
                let newElement = {
                    ref: `${item.type === "paris" ? "EX" : item.type === "medical" ? "EM" : "ES"}-${element.ref}`,
                    demandeur: item?.billing_center?.billing_center_name,
                    Exp: element?.expeditor?.fullname,
                    expCity: element?.expeditor?.city,
                    Dest: element?.receiver?.fullname,
                    DestCity: element?.receiver?.city,
                    createdAt: moment(element?.created_at).format("DD-MM-YYYY HH:mm"),
                    pickup: moment(element?.pickup_date).format("DD-MM-YYYY"),
                    delivery: moment(element?.delivery_date).format("DD-MM-YYYY"),
                    refInt: element?.customer_ref,
                    libelle: item?.bon_de_commende,
                    description: '',
                    commentaire: returnComment(element),
                    PrixU: item?.montant,
                    qte: item.shipping_ids?.length,
                    fuel: item?.fuel,
                    Amount: item?.total,
                    tva: item?.tva,
                    total: parseFloat(parseFloat(item?.fuel) + parseFloat(item?.tva) + parseFloat(item?.montant)).toFixed(3)
                };

                newData.push(newElement)
            })


        });

        let data = [
            {
                sheet: "Adults",
                columns: [
                    { label: 'AWB', value: 'ref' },
                    { label: 'Demandeur', value: 'demandeur' },
                    { label: 'Expediteur', value: 'Exp' },
                    { label: 'Ville d\'expédition', value: 'expCity' },
                    { label: 'Destinataire', value: 'Dest' },
                    { label: 'Ville de destination', value: 'DestCity' },
                    { label: 'Date de demande', value: 'createdAt', format: "DD-MM-YYYY", type: "d" },
                    { label: 'Date enlèvement', value: 'pickup', format: "DD-MM-YYYY", type: "d" },
                    { label: 'Date livraison', value: 'delivery', format: "DD-MM-YYYY", type: "d" },
                    { label: 'Ref interne', value: 'refInt' },
                    { label: 'Libellé', value: 'libelle' },
                    { label: 'Description', value: 'description' },
                    { label: 'Commentaire', value: 'commentaire' },
                    { label: 'Prix unitaire', value: 'PrixU' },
                    { label: 'Quantité', value: 'qte' },
                    { label: 'Gazoil', value: 'fuel' },
                    { label: 'Montant', value: 'Amount' },
                    { label: 'TVA', value: 'tva' },
                    { label: 'Total', value: 'total' },

                ],
                content: newData,
            },

        ]

        let settings = {
            fileName: "MySpreadsheet", // Name of the resulting spreadsheet
            extraLength: 3, // A bigger number means that columns will be wider
            writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
            writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options

            RTL: false, // Display the columns from right-to-left (the default value is false)
        }


        xlsx(data, settings) // Will download the excel file
    }


    const returnComment = (value) => {
        if (value) {
            let newValue = value?.comments;
            if (newValue?.length > 0) {
                let { content } = newValue[newValue?.length - 1]
                return (content)
            } else {
                return ''
            }

        }
    }

    const updateFilterListe2 = (filterKey) => {
        //console.log({ filterKey })
        if (filterKey) {
            setfilterBy(filterKey)
            let newOrderFilter = filterOrder === 'desc' ? 'asc' : 'desc'
            setfilterOrder(newOrderFilter)

        }
    }

    const newTable = trierTableau(filteredArray, filterBy, filterOrder)
    const dataList = FactureListStore((state) => state.dataList);
    const setDataList = FactureListStore((state) => state.setDataList);



    return (

        <>

            <NouvelTableau TableDataStore={FactureListStore} withAction={true} headerList={entete_facture2} dataLength={dataList.length} isLoading={isLoading}>
                {dataList.map((item, index) => {
                    const ref = getFactureRef(item);
                    const isAvoir = item.statut === "avoir"
                    return (
                        <tr key={index} className={`${item.active_for_reedited ? "bg-red-200" : ""} text-center`}>
                            <td style={{ color: exnetSpaceColor(item.type) }} >
                                {ref}
                            </td>
                            <td>
                                {item?.billing_center?.billing_center_name}
                            </td>
                            <td>
                                {item?.billing_center?.address}
                            </td>
                            <td>
                                {item?.billing_center?.postal_code}
                            </td>
                            <td>
                                {moment(item?.created_at).format("YYYY-MM-DD")}
                            </td>
                            <td>
                                {item?.echeance}
                            </td>
                            <td>
                                {item?.statut}
                            </td>
                            <td>
                                {isAvoir ? "-" : ""}  {toFixed2(item?.montant - item?.fuel)}
                            </td>
                            <td>
                                {isAvoir ? "-" : ""} {toFixed2(item?.fuel)}
                            </td>
                            <td>
                                {isAvoir ? "-" : ""}  {toFixed2(item?.montant)}
                            </td>
                            <td>
                                {isAvoir ? "-" : ""} {toFixed2(item?.tva)}
                            </td>
                            <td>
                                {isAvoir ? "-" : ""} {toFixed2(item.total)}
                            </td>
                            <td>
                                {(Boolean(item.active_for_reedited) || Boolean(item.doc_facture)) && (

                                    <div>
                                        <Menu as="div" className="relative flex items-start justify-center w-auto text-center ">
                                            <Menu.Button className="-m-1.5 flex items-start p-1.5 md:mr-0 justify-center">
                                                <span className="sr-only">Open user menu</span>
                                                <EllipsisVerticalIcon className="w-8 h-8 text-6xl font-bold text-right " />

                                            </Menu.Button>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                                className={"divide-y-2 space-y-1"}
                                            >
                                                <Menu.Items className="absolute right-0 z-10 mt-2.5 w-44 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                                    {item.doc_facture &&
                                                        <Menu.Item key={"item.name"} onClick={() => {
                                                            downloadPDF(item)
                                                        }}>
                                                            <div

                                                                className="mb-1 cursor-pointer "
                                                            >
                                                                <i className={`fa fa-download text-lg mr-3`}></i>
                                                                Télécharger
                                                            </div>
                                                        </Menu.Item>
                                                    }

                                                    {!Boolean(item.active_for_reedited) && (!isAvoir) && (
                                                        <Menu.Item key={"item.name"} onClick={() => {
                                                            onRedition(item)
                                                        }}>
                                                            <div
                                                                className="px-3 mb-1 text-left cursor-pointer"

                                                            >
                                                                <i className={`fa fa-edit text-lg mr-3`}></i>
                                                                Réediter
                                                            </div>
                                                        </Menu.Item>
                                                    )}

                                                    {!isAvoir && (
                                                        <Menu.Item key={"item.name"} onClick={() => {
                                                            avoirSelected(item)
                                                        }}>
                                                            <div
                                                                className="px-3 text-left cursor-pointer"

                                                            >
                                                                <i className={`fa fa-minus-circle text-lg mr-3`}></i>
                                                                Avoir
                                                            </div>
                                                        </Menu.Item>

                                                    )

                                                    }


                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>



                                    // <div className="dropdown dropdown-action">
                                    // <a
                                    //     href="#"
                                    //     className="action-icon dropdown-toggle"
                                    //     data-bs-toggle="dropdown"
                                    //     aria-expanded="false"
                                    // >
                                    //     <i className="fas fa-ellipsis-v"></i>
                                    // </a>
                                    // <div className="px-3 dropdown-menu dropdown-menu-right">
                                    //     {item.doc_facture &&
                                    //         <div
                                    //             onClick={() => {
                                    //                 downloadPDF(item)
                                    //             }}
                                    //             className="mb-1 cursor-pointer"
                                    //         >
                                    //             <i className={`fa fa-download text-lg mr-3`}></i>
                                    //             Télécharger
                                    //         </div>}

                                    //     {!Boolean(item.active_for_reedited) && (!isAvoir) && (
                                    //         <div
                                    //             className="mb-1 cursor-pointer"
                                    //             onClick={() => {
                                    //                 onRedition(item)
                                    //             }}
                                    //         >
                                    //             <i className={`fa fa-edit text-lg mr-3`}></i>
                                    //             Réediter
                                    //         </div>
                                    //     )}
                                    //     {!isAvoir && <div
                                    //         className="cursor-pointer"
                                    //         onClick={() => {
                                    //             avoirSelected(item)
                                    //         }}
                                    //     >
                                    //         <i className={`fa fa-minus-circle text-lg mr-3`}></i>
                                    //         Avoir
                                    //     </div>}

                                    // </div>
                                    // </div>
                                )}
                            </td>

                        </tr>

                    )
                })}
            </NouvelTableau>

            {selectedItem && <ConfirmModal
                title={`Réédition de facture`}
                description={"Etes-vous sûr de vouloir rééditer cette facture? Cela entraînera la suppression de la facture actuelle"}
                isOpen={isOpen}
                onClose={onClose}
                confirmRequest={() => factureService.reeditFacture({ id: selectedItem.id })}
                onConfirm={onReedited}
                successText={"Facture rééditée avec succès"}
                submitText={"Oui, rééditer"}
                submittingText={"Réédition..."}
            />}
            {selectedFacture && <AvoirShippings facture={selectedFacture} isOpen={isAvoirOpen} onClose={() => {
                onAvoirClose()
                //setSelectedFacture(null)
            }} />}
        </>
    );
}

