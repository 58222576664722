import React from "react";
import { exnetSpaceColor, urlSplit } from "../../utils/Functions/otherFunctions";
import { TbUser } from "react-icons/tb";

const ProfilePage = React.memo((props) => {
  let data = JSON.parse(localStorage.getItem("persist:root"));
  const { exnetSpace } = urlSplit();

  return (
    <div className="container p-4 mx-auto">
      {/* Page Header */}
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-3xl font-semibold">Mon compte</h1>
      </div>

      {/* Profile Card */}
      <div className="p-6 bg-white rounded-lg shadow">
        <div className="flex items-center">
          {/* Profile Image */}
          <div className="flex items-center justify-center w-32 h-32 bg-gray-200 rounded-full">
            <TbUser size={100} className="text-gray-500" />
          </div>

          {/* Profile Information */}
          <div className="ml-6">
            <h3 className="text-2xl font-semibold">{data.name.replace(/['"]+/g, '')}</h3>
            <div className="mt-2">
              {data.is_actif.replace(/['"]+/g, '') === "1" ? (
                <span className="inline-block px-3 py-1 text-sm text-white bg-green-500 rounded-md">
                  Actif
                </span>
              ) : (
                <span className="inline-block px-3 py-1 text-sm text-white bg-red-500 rounded-md">
                  Inactif
                </span>
              )}
            </div>
          </div>
        </div>

        {/* Contact Details */}
        <div className="grid grid-cols-1 gap-6 mt-6 md:grid-cols-2">
          <div>
            <h4 className="mb-2 text-lg font-medium">Informations personnelles</h4>
            <ul className="text-gray-700">
              <li className="mb-2">
                <strong>Téléphone:</strong> {data.telephone1.replace(/['"]+/g, '')}
              </li>
              <li className="mb-2">
                <strong>Email:</strong> {data.email.replace(/['"]+/g, '')}
              </li>
              <li className="mb-2">
                <strong>Adresse:</strong> {data.address1.replace(/['"]+/g, '') === "null" ? 'Aucune adresse' : data.address1.replace(/['"]+/g, '')}
              </li>
              <li className="mb-2">
                <strong>Code postal:</strong> {data.postal_code.replace(/['"]+/g, '') === "null" ? 'Aucun code postal' : data.postal_code.replace(/['"]+/g, '')}
              </li>
              <li>
                <strong>Créé le:</strong> {new Date(data.created_at.replace(/['"]+/g, '')).toLocaleDateString()}
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Tabs */}
      <div className="mt-6">
        <div className="border-b border-gray-200">
          <ul className="flex space-x-4">
            <li>
              <a
                href="#about-cont"
                className="px-4 py-2 text-blue-600 border-b-2 border-blue-600"
                data-bs-toggle="tab"
              >
                About
              </a>
            </li>
            <li>
              <a
                href="#bottom-tab2"
                className="px-4 py-2 text-gray-500 hover:text-blue-600"
                data-bs-toggle="tab"
              >
                Centre de facturation
              </a>
            </li>
            <li>
              <a
                href="#bottom-tab3"
                className="px-4 py-2 text-gray-500 hover:text-blue-600"
                data-bs-toggle="tab"
              >
                Contacts
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
});

export default ProfilePage;
