import { create } from 'zustand'
import { devtools } from "zustand/middleware";

const useTchats = (set) => ({
    tchats: {
        items: [],
        loading: true
    },
    setTchats: (payload) => set(() => ({ tchats: payload })),
    activeTchat: null,
    setActiveTchat: (payload) => set(() => ({ activeTchat: payload })),
    messages: {
        items: [],
        loading: false
    },
    setMessages: (payload) => set(() => ({ messages: payload })),

    //async function
    getTchatsLists: async () => {
        try {
            //const {data} = await getRequest()
            const data = {};
            set(() => ({ tchats: data }));
        } catch (error) {
            console.error(error)
        }
    },

    getMessages: async () => {
        try {
            //const {data} = await getRequest()
            const data = {};
            set(() => ({ tchats: data }));
        } catch (error) {
            console.error(error)
        }
    }
})


export default create(devtools(useTchats))