import React, { useCallback, useState } from "react";
import ModalComponent from "../../Utils/Modal/Index";
import opsService from "../../../services/ops/opsService";
import { toast } from "react-toastify";
import { exnetSpaceColor } from "../../../utils/Functions/otherFunctions";
import { formatDefaultDate } from "../../../utils/moment";
import useAuth from "../../../hooks/useAuth";
import Loader from "../../Utils/Loader";
import { useAppContext } from "../../../context";
import clsx from "clsx";
import { TableComponent } from "../../TableauComponent";

export default function InformationModal({
  type,
  isOpen,
  onClose,
  selectedData,
  onSubmit,
}) {
  const { user } = useAuth();

  const initialData = {
    note: "",
  };

  const [informationData, setInformationData] = useState(initialData);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isAuth, setIsAuth } = useAppContext();

  const setFormValue = (key, value) => {
    //alert(`${key}---${value}`)
    setInformationData((d) => ({
      ...d,
      [key]: value,
    }));
  };

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e?.preventDefault();
        setIsSubmitting(true);
        const submitData = {
          information: informationData.note,
          shipping_id: selectedData.id,
        };

        const { data } = await opsService.addInformations(submitData);
        if (data.success) {
          setInformationData(initialData);
          onSubmit?.({ ...data.data, user });
          toast.success(`Informations ajoutées avec succès!`, {
            position: "bottom-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          onClose();
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsSubmitting(false);
      }
    },
    [user, informationData, selectedData, onSubmit, onClose, initialData]
  );

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      title={`Informations importantes`}
    >
      <div>
        <form className="w-full px-3 py-4">
          <div className="mb-6">
            <div className="mx-auto ml-0">
              <label
                className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                htmlFor="note"
              >
                Note
              </label>
              <textarea
                rows={5}
                // cols={5}
                className={
                  "px-2 focus:border-gray-300 block  rounded-md border-0 py-1.5 pr-10  ring-1 w-full ring-gray-300  focus:ring-2 text-base sm:leading-6 h-auto border-gray-300 focus:ring-gray-300 "
                }
                id="note"
                placeholder="Note"
                value={informationData.note}
                onChange={(e) => {
                  setFormValue("note", e.target.value);
                }}
              ></textarea>
            </div>
          </div>

          <div className="flex justify-center">
            <button
              onClick={handleSubmit}
              type="submit"
              className={clsx(
                {
                  "bg-paris": isAuth?.spaceName === "paris",
                  "bg-medical": isAuth?.spaceName === "medical",
                  "bg-service": isAuth?.spaceName === "service",
                  "bg-admin": isAuth?.spaceName === "admin",
                },
                "text-white flex items-center focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
              )}
              disabled={isSubmitting}
            >
              <span className="mr-2">Enregistrer</span>
              {isSubmitting && <Loader />}
            </button>
          </div>
        </form>

        {selectedData?.information_importante?.length > 0 && (
          <TableComponent
            withAction={false}
            headerList={headerList}
            dataLength={selectedData?.information_importante?.length}
            isLoading={false}
          >
            {selectedData?.information_importante.map((e, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{e.information}</td>
                <td>{e.user?.name}</td>
                <td>{formatDefaultDate(e.created_at, "DD-MM-YYYY hh:mm")}</td>
              </tr>
            ))}
          </TableComponent>
        )}
      </div>
    </ModalComponent>
  );
}

const headerList = [
  { label: "#", filterKey: "" },
  { label: "Commentaire", filterKey: "information" },
  { label: "Ajouté par", filterKey: "user.name" },
  { label: "Ajouté le", filterKey: "created_at" },
];
