import React from "react";
import "./accordionStyle.css";
import clsx from "clsx";

class Accordion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    toggleAccordion = (identifiant) => {
        if (this.props.activeAccordionList.includes(identifiant)) {
            this.props.toggleAccordion(identifiant);
        }
    };

    render() {
        // const { isOpen } = this.state;
        const { title, children, isOpen, index,isError } = this.props;

        return (
            <div className={clsx( "accordion ")}>
                <div
                    className={clsx({"ring-1 ring-red-500" : isError}," accordion-header")} 
                    onClick={() => this.toggleAccordion(index)}
                >
                    <h1 className={clsx({"text-red-500":isError})}>{title}</h1>
                    <span
                        className={
                            isOpen
                                ? "fas fa-chevron-up fs-5"
                                : "fas fa-chevron-down fs-5"
                        }
                    ></span>
                </div>
                {isOpen && <div className="accordion-content">{children}</div>}
            </div>
        );
    }
}

export default Accordion;
