import React from "react";
import OverLayComponent from "../components/overlayComponent/OverLayComponent";

export const LoadingComponent = ({ overlay,withShadow }) => {
    return (
        <>

            {withShadow ?
                (
                    <OverLayComponent display={overlay}>
                    </OverLayComponent>
                ):
                (
                    <span></span>
                )
            }
        </>
    );
};

// export const chargement = (setOverlay) => {
//     const [first, setfirst] = useState()
//     setOverlay(true);
//     setfirst(
//         toast.loading("Traitement du formaulaire en cours", {
//             position: "top-right",
//             theme: "light",
//         })
//     );
// };
