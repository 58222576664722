import React, { useCallback, useMemo } from "react";
import { useState, useEffect } from "react";
import clientService from "../../../services/client/clientService";
import Select from "react-select";
import opsService from "../../../services/ops/opsService";
import { urlSplit } from "../../../utils/Functions/otherFunctions";
import usePreviousState from "../../../hooks/usePreviousState";

function ClientSelector({
    selectedValue,
    onSelect,
    showLabel = false,
    isDisabled,
    //autoSelect = false,
    type = "all",
    filterOnAll// pour tout récupérer et filtrer côté front
}) {
    const { role: userRole } = urlSplit()
    const [clients, setClient] = useState([]);
    const [page, setPage] = useState(1);

    const previousType = usePreviousState(type);

    const [isLoading, setIsLoading] = useState(true);

    const fetchClient = useCallback(async () => {
        let option = {
            type: filterOnAll ? "all" : type,
            per_page: 30,
            page: page,
        };
        const { data } = userRole === 'ops' ? await opsService.allOpsClients(option) : await clientService.fetchClients(option);

        //console.log('results ', page)
        if (data?.success) {
            const results = data.data.data.map((c) => ({
                ...c,
                label: `${c.fullname} (${c.type})`,
                value: c.id,
            }))

            setClient(c => page === 1 ? results : [...c, ...results]);
            /* if (page === 1) {
                onSelect(results[0])
            } */
            if (page < data.data.last_page) {
                setPage(page + 1);
            }
            setIsLoading(false);
        }
    }, [page, type, userRole, filterOnAll]);

    const hasChanged = previousType !== type;

    //console.log({ hasChanged, type, previousType });

    useEffect(() => {
        if (hasChanged) {
            setPage(1)
        }
    }, [hasChanged])



    useEffect(() => {
        fetchClient();
    }, [fetchClient]);

    const value = useMemo(() => {
        return clients.find((c) => c?.value === selectedValue)
    }, [selectedValue, clients]);

    const results = useMemo(() => {
        if (type === "all") {
            return clients
        } else {
            const types = type.split(",");
            return clients.filter(client => types.includes(client.type));
        }
    }, [type, clients]);

    return (
        <div>
            {showLabel && (
                <label
                    htmlFor="drivers"
                    className="block mb-2 text-base font-semibold text-gray-900 "
                >
                    Nom d'utilisateur
                </label>
            )}
            <Select
                className={"text-base "}
                placeholder={"Choix du client*".toUpperCase()}
                options={results}
                value={value}
                onChange={(v) => {
                    onSelect(v);
                }}
                isDisabled={isDisabled}
                isLoading={isLoading || (selectedValue && !value)}
                LoadingMessage={() => "Chargement..."}

            />
        </div>
    );
}

export default ClientSelector;
