import React, { useEffect, useState } from 'react';
import List from './List';
import Form from './Form';
import ModalComponent from "../../Utils/Modal/Index";
import useDisclosure from '../../../hooks/useDisclosure';
import { exnetSpaceColor, urlSplit } from '../../../utils/Functions/otherFunctions';
import useAdminLists from '../../../hooks/useAdminLists';
import PaginationComponent from '../../paginationComponent/PaginationComponent';
import { PlusIcon } from '@heroicons/react/24/outline';

export const IndexTemplate = (props) => {
    const { adminData, metadata, setPage, setPerPage, isLoading, onSubmit } = useAdminLists({ type: 'templates' });

    const { isOpen, onClose, onOpen } = useDisclosure();
    const [title, setTitle] = useState('Nouveau tracking template')
    const [templateList, setTemplateList] = useState([])
    const [search, setsearch] = useState("")
    //const [isLoading, setisLoading] = useState(true)
    const { exnetSpace } = urlSplit()


    // const filteredLists = adminData.data.filter(item => {
    //     const matchingFirstName = item.name.includes(search)
    //     return matchingFirstName
    // })

    /*     useEffect(() => {
            if (isLoading && !chargement) {
                setisLoading(false)
                setTemplateList([...adminData.data])
            }
    
        }, [chargement]) */

    /*     console.log(adminData.data) */

    return (
        <div>
            <div className={' flex justify-between'}>
                <div className="">
                    <h4 className="page-title">
                        <p className={"my-2"}>
                            Liste des templates de tracking
                        </p>

                    </h4>
                </div>
                <div className="">
                    <div
                        className="float-right my-auto text-white bg-black btn btn-rounded"
                        onClick={onOpen}
                        style={{ backgroundColor: exnetSpaceColor(exnetSpace) }}
                    >
                        <PlusIcon className={"h-4 w-4 m-3 text-xl font-bold text-white "} />
                    </div>
                </div>
            </div>

            <div className="py-3">
                {adminData.total ?
                    <PaginationComponent
                        page={metadata.page}
                        handlePageClick={setPage}
                        totalItems={adminData.total}
                        perPageItems={metadata.perPage}
                        setnumberPerPage={setPerPage}
                    /> : null}
            </div>



            <ModalComponent isOpen={isOpen} onClose={onClose} title={title}>
                <Form newData={true} setTemplateList={setTemplateList} onClose={onClose} onSubmit={onSubmit} />
            </ModalComponent>


            <List isLoading={isLoading} items={adminData.data} />
            <div className="py-3">
                {adminData.total ?
                    <PaginationComponent
                        page={metadata.page}
                        element='pagination'
                        handlePageClick={setPage}
                        totalItems={adminData.total}
                        perPageItems={metadata.perPage}
                        setnumberPerPage={setPerPage}
                    /> : null}
            </div>
        </div>
    );
}
