import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allExpedition: [],
  expeditions: {},
  isExpeditionInProgress: false,
  expeditionStatus: null,
};

const expeditionSlice = createSlice({
  name: "expedition",
  initialState,
  reducers: {
    startExpedition: (state) => {
      state.isExpeditionInProgress = true;
      state.expeditionStatus = "En cours";
    },
    completeExpedition: (state) => {
      state.isExpeditionInProgress = false;
      state.expeditionStatus = "Terminée";
    },
    failExpedition: (state) => {
      state.isExpeditionInProgress = false;
      state.expeditionStatus = "Échouée";
    },
    setExpedition: (state, action) => {
      state.expeditions = { ...action.payload };
    },
    addExpedition: (state, action) => {
      state.allExpedition.push(...action.payload);
    },
    initExpedition:(state, action)=>{
      state.allExpedition=[...action.payload];
    },
    clearExpeditions: (state) => {
      state.allExpedition = [];
    },
    clearExpedition: (state) => {
      state.expeditions = {};
    },
    addFacturation: (state, action) => {
      state.expeditions.facturations.push(action.payload);
    },
    removeFacturation: (state, action) => {
      const facturationIdToRemove = action.payload;

      // Recherche de l'index de l'objet à supprimer
      const indexToRemove = state.expeditions.facturations.findIndex(
        (facturation) => facturation.id === facturationIdToRemove
      );

      if (indexToRemove !== -1) {
        // Suppression de l'objet du tableau
        state.expeditions.facturations.splice(indexToRemove, 1);
      }
    },
  },
});

export const {
  initExpedition,
  startExpedition,
  completeExpedition,
  failExpedition,
  setExpedition,
  addExpedition,
  clearExpeditions,
  addFacturation,
  removeFacturation,
  clearExpedition
} = expeditionSlice.actions;

export const Expedition = expeditionSlice.reducer;
