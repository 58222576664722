import React from 'react';
import LoaderTableau from '../../tableauComponent/LoaderTableau';
import { getCountryNameOrCode } from "../../../utils/const/countriesConstant";
import moment from 'moment';

function IndexAdmin({ items, colonnes, isLoading }) {

    return (
        <div>
            <div className={'text-xl my-10 font-calibri'}>
                Liste des 10 dernières expéditions
            </div>
            <div className="relative p-6 mx-auto overflow-x-auto bg-white rounded-lg shadow">
                <table className="min-w-full border-collapse">
                    <thead>
                        <tr>
                            {colonnes.slice(0, colonnes?.length - 2).map((item, index) => (
                                <th className="px-6 py-1 text-sm leading-4 tracking-wider text-left border-b-2 border-gray-200" key={item}>
                                    {item}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading ? (
                            <>
                                {items.length === 0 ? (
                                    <tr key="">
                                        <td colSpan={colonnes.length}>Il n'y a pas de template pour le moment</td>
                                    </tr>
                                ) : (<>
                                    {items.map((item, index) => (
                                        <tr key={item?.ref}>
                                            <td className="px-6 py-2 text-sm border-b border-gray-200">
                                                #{item?.ref}
                                            </td>
                                            <td className="px-6 py-2 text-sm border-b border-gray-200">
                                                {moment(item.pickup_date).format("DD-MM-YYYY")} <br />  {item.pickup_time}
                                            </td>
                                            <td
                                                className="px-6 py-2 text-sm border-b border-gray-200"
                                                style={{
                                                    maxWidth: "200px",
                                                }}
                                            >
                                                {" "}
                                                <span className={" font-bold"}>
                                                    {(item.expeditor.fullname)}
                                                </span>
                                                <br />
                                                <span>{(item.expeditor.company)}</span>
                                                <br />
                                                {/* <i className="font-bold ">
                                                    {" "}
                                                    {moment(item.pickup_date).format("DD-MM-YYYY")}
                                                </i>
                                                <br />
                                                <i className="font-bold "> {item.pickup_time}</i>
                                                <br /> */}
                                            </td>
                                            <td className="px-6 py-2 text-sm border-b border-gray-200">
                                                <span> {(item.expeditor.address1)}</span> <br />
                                                <span>{item.expeditor.city}</span>
                                                <br />
                                                <span>{item.expeditor.postalCode}</span>
                                                <div className={'flex items-center gap-3 flex-wrap'}>
                                                    <span>
                                                        <img
                                                            src={`https://flagcdn.com/16x12/${getCountryNameOrCode(
                                                                getCountryNameOrCode(item?.expeditor?.country),
                                                                true
                                                            )}.png`}
                                                            srcSet={`https://flagcdn.com/32x24/${getCountryNameOrCode(
                                                                getCountryNameOrCode(item?.expeditor?.country),
                                                                true
                                                            )}.png 2x,
              https://flagcdn.com/48x36/${getCountryNameOrCode(
                                                                getCountryNameOrCode(item?.expeditor?.country),
                                                                true
                                                            )}.png 3x`}
                                                            width="16"
                                                            height="12"
                                                            alt={getCountryNameOrCode(item?.expeditor?.country)}
                                                        />
                                                    </span>
                                                    <span> {getCountryNameOrCode(item?.expeditor?.country)}</span>
                                                </div>
                                            </td>

                                            <td
                                                className="px-6 py-2 text-sm border-b border-gray-200"
                                                style={{
                                                    maxWidth: "200px",
                                                }}
                                            >
                                                {" "}
                                                <span className={" font-bold"}>
                                                    {(item.receiver.fullname)}
                                                </span>
                                                <br />
                                                <span>{(item.receiver.company)}</span>
                                                <br />
                                                {/* <i className="font-bold ">
                                                    {" "}
                                                    {moment(item.pickup_date).format("DD-MM-YYYY")}
                                                </i>
                                                <br />
                                                <i className="font-bold "> {item.delivery_time}</i>
                                                <br /> */}
                                            </td>
                                            <td className="px-6 py-2 text-sm border-b border-gray-200">
                                                <span> {(item.receiver.address1)}</span> <br />
                                                <span>{item.receiver.city}</span>
                                                <br />
                                                <span>{item.receiver.postalCode}</span>
                                                <div className={'flex items-center gap-3 flex-wrap'}>
                                                    <span>
                                                        <img
                                                            src={`https://flagcdn.com/16x12/${getCountryNameOrCode(
                                                                getCountryNameOrCode(item?.receiver?.country),
                                                                true
                                                            )}.png`}
                                                            srcSet={`https://flagcdn.com/32x24/${getCountryNameOrCode(
                                                                getCountryNameOrCode(item?.receiver?.country),
                                                                true
                                                            )}.png 2x,
                                                                  https://flagcdn.com/48x36/${getCountryNameOrCode(
                                                                getCountryNameOrCode(item?.receiver?.country),
                                                                true
                                                            )}.png 3x`}
                                                            width="16"
                                                            height="12"
                                                            alt={getCountryNameOrCode(item?.receiver?.country)}
                                                        />
                                                    </span>
                                                    <span> {getCountryNameOrCode(item?.receiver?.country)}</span>
                                                </div>
                                            </td>
                                            <td className="px-6 py-2 text-sm border-b border-gray-200">
                                                {moment(item.pickup_date).format("DD-MM-YYYY")} <br />  {item.delivery_time}
                                            </td>
                                            <td className="px-6 py-2 text-sm border-b border-gray-200">
                                                {" "}
                                                <div className="text-center">

                                                    <span>{item?.suivis?.length > 0 ? item.suivis[item?.suivis?.length - 1]['details'] : '-'}</span>
                                                </div>
                                            </td>


                                        </tr>
                                    ))
                                    }

                                </>)}
                            </>

                        ) : (
                            <tr key="">
                                <td colSpan={colonnes.length}>
                                    <LoaderTableau />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default IndexAdmin;