import clsx from 'clsx';
import React from 'react';
import { useAppContext } from '../context';

export const ButtonComponent = ({ onClick, title = "Enregistrer", buttonClass = "" }) => {

    const { isAuth, setIsAuth } = useAppContext();

    return (
        <>
            <button onClick={onClick} className={clsx({
                "bg-paris": isAuth?.spaceName === "paris",
                "bg-medical": isAuth?.spaceName === "medical",
                "bg-service": isAuth?.spaceName === "service",
                "bg-admin": isAuth?.spaceName === "admin",
            }, `text-sm rounded-md px-4 py-2 text-white ${buttonClass}`)}> {title}</button>
        </>
    );
}