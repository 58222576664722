import React, { useCallback, useEffect, useState } from "react";
import useDisclosure from "../../hooks/useDisclosure";
import ModalComponent from "../Utils/Modal/Index";
import { useBasicFormValues } from "../../hooks/useFormValues";
import CountriesSelector from "../Utils/Selectors/CountriesSelector";
import { validateExpeditorData } from "../../utils/validators";
import opsService from "../../services/ops/opsService";
import { toast } from "react-toastify";
import { CivilitySelector } from "../Utils/Selectors/TransportTypeSelector";
import CarnetAddress, { ContactFormInput } from "../contact/CarnetAddress";
import Loader from "../Utils/Loader";
import { exnetSpaceColor, urlSplit } from "../../utils/Functions/otherFunctions";
import { getCountryNameOrCode } from "../../utils/const/countriesConstant";
import { Stciker } from "../shippingDetails/Stciker";


function ExpediteurOrExpediteurComponent({ UserType, data: userData, shippingId, userType }) {

    const [data, setData] = useState({})
    const { role, exnetSpace } = urlSplit();
    useEffect(() => {
        if (userData) {
            setData({ ...userData })
        }
    }, [userData])

    const { isOpen, onOpen, onClose } = useDisclosure();

    const onSubmit = useCallback((data) => {
        if (data) {
            setData(d => ({
                ...d,
                ...data
            }))
        }
    }, [])



    return (
        <div className="h-full p-4 bg-white rounded-md">
            <div
                className="flex justify-between card-title"

            >
                <span>{UserType?.toUpperCase()}</span>
                <div className={"flex items-center justify-end gap-3"}>
                    <Stciker info={userData} userType={UserType?.toUpperCase()} />
                    <span
                        className="cursor-pointer fa fa-edit"
                        style={{ marginLeft: "0px", color: `${exnetSpaceColor(exnetSpace)}` }}
                        onClick={onOpen}
                    ></span>
                </div>
            </div>
            <hr />
            <div className={"mt-3"}>
                <span
                    style={{ marginLeft: "0px", color: `${exnetSpaceColor(exnetSpace)}` }}
                    className="far fa-hospital fs-6 me-2 icone-detail"
                ></span>
                <span className="uppercase titre-detail">Nom et Prénom(s): </span>
                <span className="data-detail">
                    {" "}
                    {data ? data.fullname : ""}
                </span>
            </div>
            <div>
                <span
                    style={{ marginLeft: "0px", color: `${exnetSpaceColor(exnetSpace)}` }}
                    className="far fa-address-card fs-6 me-2 icone-detail"
                ></span>
                <span className="uppercase titre-detail">Compagnie : </span>
                <span className="data-detail"> {data ? data.company : ""}</span>
            </div>
            <div>
                <span
                    style={{ marginLeft: "0px", color: `${exnetSpaceColor(exnetSpace)}` }}
                    className="fa fa-at fs-6 me-2 icone-detail"
                ></span>
                <span className="uppercase titre-detail">Email :</span>
                <span className="data-detail"> {data ? data.email : ""}</span>
            </div>
            <div>
                {" "}
                <span
                    style={{ marginLeft: "0px", color: `${exnetSpaceColor(exnetSpace)}` }}
                    className="fas fa-phone fs-6 me-2 icone-detail"
                ></span>
                <span className="uppercase titre-detail">Téléphone : </span>
                <span className="data-detail">
                    {" "}
                    {data ? data.phone1 : ""}
                </span>
            </div>
            <div>
                {" "}
                <span
                    style={{ marginLeft: "0px", color: `${exnetSpaceColor(exnetSpace)}` }}
                    className="fas fa-map-marker-alt fs-6 me-2 icone-detail"
                ></span>
                <span className="uppercase titre-detail">Adresse : </span>
                <span className="data-detail">
                    {" "}
                    {data ? data.address1 : ""}
                </span>
            </div>
            <div className="my-1" >
                <span
                    style={{ marginLeft: "0px", color: `${exnetSpaceColor(exnetSpace)}` }}
                    className="fa fa-book fa-fw fs-6 me-2 icone-detail"
                ></span>
                <span className="uppercase titre-detail">Ville : </span>
                <span className="data-detail"> {data ? data.city : ""}</span>
            </div>
            <div>
                <span
                    style={{ marginLeft: "0px", color: `${exnetSpaceColor(exnetSpace)}` }}
                    className="fa fa-cog fs-6 me-2 icone-detail"
                ></span>
                <span className="uppercase titre-detail">Pays : </span>
                <span className="data-detail">{getCountryNameOrCode(data.country)} </span>
            </div>
            {Object.keys(data).length > 1 &&
                <FormModal
                    data={userData}
                    UserType={UserType}
                    isOpen={isOpen}
                    onClose={onClose}
                    onSubmit={onSubmit}
                    shippingId={shippingId}
                    userType={userType}
                />}
        </div>
    );
}


const FormModal = ({ isOpen, UserType, onClose, data, onSubmit, shippingId, userType }) => {
    const { data: userData, handleChange, setData, setFormValue, } = useBasicFormValues(data)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [selectedTab, setSelectedTab] = useState("")
    const [validationError, setValidationError] = useState(null)

    const { isOpen: isContactOpen, onClose: onContactClose, onOpen: onContactOpen } = useDisclosure()

    const isNew = selectedTab === 1;

    //console.log({ data })

    useEffect(() => {
        if (!userData?.civility) {
            setFormValue("civility", "M")
        }
    }, [userData])

    const { valid, error: _err } = validateExpeditorData(userData)

    useEffect(() => {
        if (shippingId) {
            setFormValue("shipping_id", shippingId)
        }
    }, [shippingId])

    const handleSubmit = useCallback(async (e) => {
        try {
            e?.preventDefault()
            setValidationError(null)
            if (_err) {
                setValidationError(_err)
                return
            }
            setIsSubmitting(true)
            const submitData = {
                ...userData,
                type: userType
            }
            delete submitData.created_at
            delete submitData.updated_at

            if (!isNew) {
                delete submitData.type_contact
            }

            const request = isNew ? opsService.addContact : opsService.updateInfos

            const { data } = await request(submitData)
            if (data.success) {
                onClose();
                const type = submitData.type
                onSubmit?.(data.data[0][type])
                toast.success(
                    isNew ? "Contact ajouté avec succès" : `Informations mises à jour`,
                    {
                        position: "bottom-center",
                        autoClose: 4000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            }
        } catch (error) {
            console.error(error)
            onClose();
            toast.error(
                "Une erreur est survenue",
                {
                    position: "bottom-center",
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );
        } finally {
            setIsSubmitting(false)
        }
    }, [userData, isNew, _err]
    )




    const resetData = useCallback(() => {
        if (userData) {
            setSelectedTab(1)
            Object.keys(userData).forEach(d => {
                setFormValue(d, "")
            })
        }

    }, [userData])

    const civilities = [
        { label: "Nouvelle fiche", value: "Nouvelle fiche", onClick: resetData },
        {
            label: "Carnet d'adresse", value: "Carnet d'adresse", onClick: () => {
                onContactOpen()
                setSelectedTab(2)
            }
        }
    ]

    const onContactSelect = useCallback((contact) => {
        setData(d => {
            //console.log({ "D": d, contact })
            const _d = {
                ...d,
                ...contact,
                type: d.type || UserType === "Expéditeur" ? "expeditor" : "destinator",
                civility: d.civility || "M",
            }

            //console.log({ _d })
            return _d
        })
    }, [UserType])

    const onFicheCancel = useCallback(() => {
        setSelectedTab(0)
        setData(data)
    }, [data])

    //console.log({ isContactOpen })

    //console.log({ t: "HH", userData, valid, error })

    return (
        <>
            <ModalComponent
                isOpen={isOpen}
                onClose={onClose}
                title={`Modifier`}
            >
                {validationError && (
                    <div className="bg-red-100 mt-4 text-red-600 px-4 py-2 rounded-md text-[13px]">{validationError}</div>
                )}
                <form onSubmit={handleSubmit} className="p-4">

                    <div className="grid-cols-2 gap-2 space-y-2 md:grid md:space-y-0">


                        {civilities?.map((c, i) => (
                            <div className={`px-5 py-2 h-[40px] rounded-lg text-sm border cursor-pointer text-center flex items-center justify-center ${selectedTab === i + 1 ? "bg-gray-100" : "bg-transparent"}`}
                                onClick={() => {
                                    c.onClick?.()
                                }}
                            >
                                {c.label}
                            </div>
                        ))}
                        <div className="">
                            <CivilitySelector
                                value={userData.civility}
                                onSelect={v => {
                                    setFormValue("civility", v.value)
                                }}

                            />
                        </div>
                        <ContactFormInput
                            label="Nom de l'entreprise"
                            value={userData.company}
                            onChange={value => {
                                setFormValue("company", value)
                            }}
                            onSelect={onContactSelect}
                            attribute={"company"}
                        />
                        <div>
                            <ContactFormInput
                                label="Nom et Prénom(s)"
                                value={userData.fullname}
                                onChange={value => {
                                    setFormValue("fullname", value)
                                }}
                                onSelect={onContactSelect}
                                attribute={"fullname"}
                            />
                        </div>

                        <div className="">
                            <ContactFormInput
                                label="Adresse"
                                value={userData.address1}
                                onChange={value => {
                                    setFormValue("address1", value)
                                }}
                                onSelect={onContactSelect}
                                attribute={"address1"}
                            />
                        </div>

                        <div className="">
                            <ContactFormInput
                                label="Complément d'adresse"
                                value={userData.address2 ?? ""}
                                onChange={value => {
                                    setFormValue("address2", value)
                                }}
                                onSelect={onContactSelect}
                                attribute={"address2"}
                            />
                        </div>

                        <div>
                            <CountriesSelector
                                selectedValue={userData.country}
                                onSelect={v => setFormValue("country", v.value)}
                            />
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 uppercase ">Code Postal</label>
                            <input
                                type="text"
                                name="postalCode"
                                value={userData.postalCode}
                                onChange={handleChange}
                                className="block text-sm w-full h-[40px] px-4 py-2 mb-3 leading-tight text-gray-700 bg-white border border-gray-300  appearance-none focus:outline-none focus:bg-white"
                                required
                            />
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 uppercase">Ville</label>
                            <input
                                type="text"
                                name="city"
                                value={userData.city}
                                onChange={handleChange}
                                className="block text-sm w-full h-[40px] px-4 py-2 mb-3 leading-tight text-gray-700 bg-white border border-gray-300  appearance-none focus:outline-none focus:bg-white"
                                required
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-4 mt-4">
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 uppercase">Téléphone</label>
                            <div className="flex">
                                <input
                                    type="text"
                                    name="phone1"
                                    value={userData.phone1}
                                    onChange={handleChange}
                                    className="block text-sm w-full h-[40px] px-4 py-2 mb-3 leading-tight text-gray-700 bg-white border border-gray-300  appearance-none focus:outline-none focus:bg-white" required
                                />
                            </div>
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 uppercase">Téléphone 2</label>
                            <div className="flex">
                                <input
                                    type="text"
                                    name="phone2"
                                    value={userData.phone2}
                                    onChange={handleChange}
                                    className="block text-sm w-full h-[40px] px-4 py-2 mb-3 leading-tight text-gray-700 bg-white border border-gray-300  appearance-none focus:outline-none focus:bg-white" />

                            </div>
                        </div>
                    </div>

                    <div className="mt-4">
                        <label className="block mb-2 text-sm font-medium text-gray-900 uppercase">Email</label>
                        <input
                            type="email"
                            name="email"
                            value={userData.email}
                            onChange={handleChange}
                            className="block text-sm w-full h-[40px] px-4 py-2 mb-3 leading-tight text-gray-700 bg-white border border-gray-300  appearance-none focus:outline-none focus:bg-white"
                            required
                        />
                    </div>

                    <div className="flex justify-center text-right ">
                        <button
                            type="submit"
                            className={` ${valid ? "cursor-pointer" : "cursor-not-allowed"} text-white bg-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm px-14 py-2.5 text-center mt-4`}
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                        //title={`${}`}
                        >
                            <span className="mr-3">
                                {isNew ? "Enregistrer" : "Modifier"}
                            </span>
                            {isSubmitting && (
                                <Loader />
                            )}

                        </button>
                        {isNew && <button
                            className={`text-black bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm px-14 py-2.5 text-center mt-4 ml-4`}
                            onClick={onFicheCancel}
                        //title={`${}`}
                        >
                            Annuler
                        </button>}
                    </div>
                </form>
            </ModalComponent>
            <CarnetAddress
                isOpen={isContactOpen}
                onClose={() => {
                    onContactClose()
                    setSelectedTab(0)
                }} onSelect={onContactSelect}
            />
        </>
    )
}

export default ExpediteurOrExpediteurComponent;
