import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import parisFavicon from './../../assets/img/mobile/EP.ico'
import medicalFavicon from './../../assets/img/mobile/EM.ico'
import serviceFavicon from './../../assets/img/mobile/ES.ico'
import admin from './../../assets/img/mobile/ES.ico'
import { useAppContext } from '../../context';




function Head({ espace="paris", children }) {
    const [favicon, setFavicon] = useState(parisFavicon)
    const { isAuth, setIsAuth } = useAppContext();

    useEffect(() => {
        if (espace === 'medical') {
            setFavicon(medicalFavicon)
        }
        else if (espace === 'service') {
            setFavicon(serviceFavicon)
        }
        else if (espace === 'paris') {
            setFavicon(parisFavicon)
        }
        else {
            setFavicon(admin)
        }
    }, [])
    return (
        <Helmet>
            {/* {espace === 'paris' && <link
                rel="stylesheet"
                type="text/css"
                href="/assets/css/styles/parisPaginationStyle.css"
            />}

            {espace === 'medical' && <link
                rel="stylesheet"
                type="text/css"
                href="/assets/css/styles/medicalPaginationStyle.css"
            />}

            {espace === 'service' && <link
                rel="stylesheet"
                type="text/css"
                href="/assets/css/styles/servicePaginationStyle.css"
            />} */}
            <link rel="icon" href={favicon} type="image/x-icon" />
            {children}
        </Helmet>
    );
}

export default Head;