import React from 'react'
import DetailsColisComponent from '../../components/detailsExpedition/DetailsColisComponent'
import { useParams, useLocation } from "react-router-dom";
import ClientExpeditionDetailComponent from '../../components/detailsExpedition/client/clientExpeditionDetailComponent';

export default function ShippingDetails() {

    const { identifiant, milieu } = useParams();
    const { pathname } = useLocation()

    return (
        <div className="row" style={{ width: "99%", margin: "auto" }}>
            <div className="col-md-12">
                {/* <ClientExpeditionDetailComponent
                /> */}
                <DetailsColisComponent
                    expeditionId={identifiant}
                    expeditionSpace={milieu}
                    key={pathname}
                />
            </div>

        </div>
    )
}
