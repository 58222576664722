import React, { useState } from "react";
import ManifestService from "../../services/manifest/service";

const DeletionConfirm = ({
    isOpen,
    title,
    onClose,
    onSubmit,
    selectedData,
}) => {
    const [isDeleting, setIsDeleting] = useState(false);

    const handleOutsideClick = (event) => {
        //console.log(event)
        if (event.target.id === "confirm-popup-modal") {
            onClose();
        }
    };

    const handleDelete = async () => {

        if (selectedData?.id) {
            try {
                setIsDeleting(true);
                const { data } = await ManifestService.delete(selectedData.id);
                if (data.success) {
                    onSubmit?.(selectedData);
                }
            } catch (error) {
                console.warn("Error deleting item:", error);
            } finally {
                setIsDeleting(false);
            }
        }
    };

    return (
        isOpen && (
            <div
                id="confirm-popup-modal"
                onClick={handleOutsideClick}
                className="fixed inset-0 overflow-y-auto"
                style={{ zIndex: 9999 }}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div
                        className="fixed inset-0 transition-opacity"
                        aria-hidden="true"
                    >
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <div
                        className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full`}
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline"
                    >
                        <div className="bg-white p-4 pt-5 sm:p-6 sm:pb-4">
                            <button
                                onClick={onClose}
                                className="absolute top-3 right-4 focus:outline-none"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    className="h-6 w-6 text-gray-600"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    ></path>
                                </svg>
                            </button>

                            <h3
                                className="text-lg text-center leading-6 font-medium text-gray-900"
                                id="modal-headline"
                            >
                                {title}
                            </h3>
                            <div className="p-6 text-center">
                                <svg
                                    className="mx-auto mb-4 text-gray-400 w-12 h-12 "
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                    />
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 ">
                                    Are you sure you want to delete this
                                    product?
                                </h3>
                                <button
                                    onClick={handleDelete}
                                    type="button"
                                    disabled={isDeleting}
                                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                                >
                                    {isDeleting
                                        ? "Suppression..."
                                        : "Oui, j'en suis sûr"}
                                </button>
                                <button
                                    onClick={onClose}
                                    type="button"
                                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10     "
                                >
                                    No, cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default DeletionConfirm;
