import React, { useCallback, useMemo, useState } from 'react'
import { trierTableau } from '../utils/Functions/tableFilter';

export default function useTableFilter({ items }) {

    const [filterBy, setFilterBy] = useState("ref");
    const [filterOrder, setfilterOrder] = useState("desc");

    const toggleOrderBy = useCallback(() => {
        setfilterOrder(ord => ord === "desc" ? "asc" : "desc")
    }, [])


    const onTableHeaderClick = useCallback((key) => {
        if (key) {
            if (filterBy === key) {
                setfilterOrder(ord => ord === "desc" ? "asc" : "desc")
            } else {
                setFilterBy(key);
            }
        }

    }, [filterBy])

    const results = useMemo(() => trierTableau(items, filterBy, filterOrder), [filterBy, items, filterOrder])

    return {
        results,
        toggleOrderBy,
        filterBy,
        filterOrder,
        setFilterBy,
        onTableHeaderClick
    }
}
