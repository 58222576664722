// create vehiculeService react service

import axios from "axios";
import TokenService from "../config/tokenService.js";

class vehiculeService {

    constructor() {
        TokenService.setHeader();
    }
    fetchListVehicules(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/vehicule/liste`, data);
    }

    fetchListVehiculesById(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/vehicule/informations`, data);
    }

    createVehicule(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/vehicule/create`, data);
    }

    updateVehicule(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/vehicule/update`, data);
    }

    deleteVehicule(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/vehicule/delete`, data);
    }

    attributeVehicule(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/attribution/vehicule`, data);
    }

}

export default new vehiculeService();
