import React, { useCallback, useEffect, useState } from 'react'
import ModalUI from '../../Utils/Modal/Modal'
import { useBasicFormValues } from '../../../hooks/useFormValues';
import EmployeeService from '../../../services/employees/index';
import { validateEmployeeData } from '../../../utils/validators';
import Loader from '../../Utils/Loader';
import { pick } from 'lodash';
import { toast } from 'react-toastify';
import PhoneInput from "react-phone-input-2";
import CountriesSelector from '../../Utils/Selectors/CountriesSelector';
import { getCountryNameOrCode } from '../../../utils/const/countriesConstant';

export default function EmployeeFormModal({ isOpen, onClose, onSubmit, selectedData, type = "salaries" }) {
    const initialData = {
        nom: "",
        prenom: "",
        email: "",
        telephone: "",
        adresse: "",
        type,
        country: "fr"
    }
    const { data: employeeData, setFormValue, setData } = useBasicFormValues(initialData);

    //console.log({ type, selectedData })
    useEffect(() => {
        if (selectedData) {
            setData(d => ({
                ...d,
                ...selectedData
            }))
        } else {
            setData(initialData)
        }
    }, [selectedData, isOpen])

    const [validationError, setValidationError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = useCallback(
        async (e) => {
            try {
                e.preventDefault();
                setValidationError(null);
                const submitData = pick(employeeData, ["id", "nom", "prenom", "email", "telephone", "type", "postal_code", "city", "adress", "country"])

                const { error: _err } = validateEmployeeData(submitData);
                if (_err) {
                    setValidationError(_err)
                    return;
                }
                setIsSubmitting(true);

                //console.log({ submitData })
                const request = selectedData ? EmployeeService.update : EmployeeService.create
                const { data } = await request(submitData);
                if (data.success) {
                    onClose();
                    onSubmit?.(selectedData ? employeeData : data.data)
                    setData(initialData)
                }
            } catch (error) {
                console.error(error);
                toast.error('Une erreur lors de la soumission de votre demande', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } finally {
                setIsSubmitting(false);
            }
        },
        [employeeData, selectedData]
    );

    const keyword = type === "salaries" ? "salarié" : "fournisseur"

    return (
        <ModalUI
            isOpen={isOpen}
            onClose={onClose}
            title={selectedData ? `Modifier le ${keyword}` : `Créer un ${keyword}`}
        >
            {validationError && (
                <div className="bg-red-100 mt-4 text-red-600 px-4 py-2 rounded-md text-[13px]">{validationError}</div>
            )}
            <form onSubmit={handleSubmit} className="p-3">
                <div className="grid gap-6 mb-3 md:grid-cols-2">
                    <div className="text-left">
                        <label
                            htmlFor="nom"
                            className="block mb-2 text-sm font-medium text-gray-900"
                        >
                            Nom*
                        </label>
                        <input
                            type="text"
                            id="nom"
                            placeholder="Nom de l'employé"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-100 block w-full p-2"
                            required
                            value={employeeData.nom}
                            onChange={(e) => setFormValue("nom", e.target.value)}
                        />
                    </div>

                    <div className="text-left">
                        <label
                            htmlFor="prenom"
                            className="block mb-2 text-sm font-medium text-gray-900"
                        >
                            Prénoms*
                        </label>
                        <input
                            type="text"
                            id="prenom"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-100 block w-full p-2"
                            value={employeeData.prenom}
                            onChange={(e) => setFormValue("prenom", e.target.value)}
                        />
                    </div>

                    <div className="text-left">
                        <label
                            htmlFor="adress"
                            className="block mb-2 text-sm font-medium text-gray-900"
                        >
                            Adresse*
                        </label>

                        <input
                            id="adress"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-100 block w-full p-2"
                            required
                            value={employeeData.adress}
                            onChange={(e) => setFormValue("adress", e.target.value)}
                        />

                        {/* <input
                            type="text"
                            id="telephone"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-100 block w-full p-2"
                            required
                            value={employeeData.telephone}
                            onChange={(e) => setFormValue("telephone", e.target.value)}
                        /> */}
                    </div>

                    <div className="text-left">
                        <label
                            htmlFor="city"
                            className="block mb-2 text-sm font-medium text-gray-900"
                        >
                            Ville*
                        </label>
                        <input
                            type="text"
                            id="city"
                            className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-100 block w-full p-2"
                            required
                            value={employeeData.city}
                            onChange={(e) => setFormValue("city", e.target.value)}
                        />
                    </div>

                    <div className="text-left">
                        <label
                            htmlFor="postal_code"
                            className="block mb-2 text-sm font-medium text-gray-900"
                        >
                            Code Postal*
                        </label>
                        <input
                            type="text"
                            id="postal_code"
                            className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-100 block w-full p-2"
                            required
                            value={employeeData.postal_code}
                            onChange={(e) => setFormValue("postal_code", e.target.value)}
                        />
                    </div>

                    <div className="text-left">
                        <CountriesSelector
                            selectedValue={employeeData.country}
                            onSelect={(v) => {
                                setFormValue("country", v.value);
                            }}
                        // isDisabled={!editable}
                        />
                    </div>

                    <div className="text-left">
                        <label
                            htmlFor="telephone"
                            className="block mb-2 text-sm font-medium text-gray-900"
                        >
                            Téléphone*
                        </label>

                        <PhoneInput
                            country={
                                getCountryNameOrCode(
                                    getCountryNameOrCode(employeeData?.country),
                                    true
                                ) ?? "fr"
                            }
                            defaultValue={employeeData.telephone}
                            value={employeeData.telephone}
                            onChange={(value, country, e, formattedValue) => {
                                setFormValue("telephone", formattedValue)
                            }}
                            inputClass={'form-controle'}
                            countryCodeEditable={true}
                            disableDropdown={false}
                        />


                        {/* <input
                            type="text"
                            id="telephone"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-100 block w-full p-2"
                            required
                            value={employeeData.telephone}
                            onChange={(e) => setFormValue("telephone", e.target.value)}
                        /> */}
                    </div>

                    <div className="text-left">
                        <label
                            htmlFor="email"
                            className="block mb-2 text-sm font-medium text-gray-900"
                        >
                            Email*
                        </label>
                        <input
                            type="email"
                            id="email"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-100 block w-full p-2"
                            required
                            value={employeeData.email}
                            onChange={(e) => setFormValue("email", e.target.value)}
                        />
                    </div>

                    <div className="text-left" />
                </div>

                <div className="flex justify-end mt-4">
                    <button
                        type="button"
                        onClick={() => onClose?.()}
                        className="bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-5"
                    >
                        Annuler
                    </button>
                    <button
                        type="submit"
                        className={`cursor-pointer text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm px-14 py-2.5 text-center`}
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                    //title={`${}`}
                    >
                        <span className="mr-3">
                            {!selectedData ? "Enregistrer" : "Modifier"}
                        </span>
                        {isSubmitting && (
                            <Loader />
                        )}

                    </button>
                </div>
            </form>
        </ModalUI>
    )
}
