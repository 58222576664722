import React, { useEffect, useState } from "react";
import DiscussionList from "./layout/discussionList";
import ChatBoxComponent from "./layout/chatBoxComponent";
import { useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import ChatService from "../../services/chat/ChatService";
import { urlSplit } from "../../utils/Functions/otherFunctions";

function Tchatcomponent(props) {
    const { identifiant } = useParams();
    const [activeChat, setActiveChat] = useState({ chat_messages: [] })
    const [page, setPage] = useState(1)
    const [allChat, setAllChat] = useState([])
    const { isAuthenticated, user } = useAuth();

    const fecthShippingMessage = (shipping_id) => {
        ChatService.fetchMessages(shipping_id, 1).then(
            (res) => {
                let newActive = res.data.data.data
                newActive = newActive[0]
                setActiveChat({ ...newActive })
            },
            (error) => {
                console.log(error)
            }
        )
    }

    const { role } = urlSplit();


    const fecthallChat = () => {
        if (role === 'ops') {
            ChatService.fetchOpsChat(page).then(
                (data) => {
                    const { data: newChat, total: totalPage, current_page: currentPage } = data.data.data
                    setAllChat(d => [...allChat, ...newChat])
                },
                (error) => {
                    console.log(error)
                }
            )
        } else if (role === 'client') {
            ChatService.fecthClientChat(page).then(
                (data) => {
                    const { data: newChat, total: totalPage, current_page: currentPage } = data.data.data;
                    
                },
                (error) => {
                    console.log(error)
                }
            )
        }

    }

    useEffect(() => {

        if (identifiant) {
            fecthShippingMessage(identifiant)
        }
        else {
            setActiveChat({ chat_messages: [] })
        }
    }, [identifiant])

    useEffect(() => {
        fecthallChat()
    }, [])



    return (
        <div className="row">


            <div className="col-xl-4 d-flex">
                <DiscussionList setActiveChat={setActiveChat} activeChat={activeChat} allChat={allChat} />
            </div>
            <div className="col-xl-8 ">
                <ChatBoxComponent activeChat={activeChat} />
            </div>
        </div>
    );
}

export default Tchatcomponent;
