import React, { useCallback } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import opsService from '../../../services/ops/opsService';
import { urlSplit } from '../../../utils/Functions/otherFunctions';
import { useAppContext } from '../../../context';

export default function Restaure({ shipping_id }) {

    const { pathname } = useLocation();
    const { isAuth, setIsAuth } = useAppContext();

    const navigate = useNavigate()

    const expeditionType = pathname.split("/").pop()
    const { exnetSpace } = urlSplit();

    

    const handleRestaure = async () => {
        try {

            Swal.fire({
                title: "Êtes-vous sûr de vouloir restaurer ?",
                text: "Cette action est irréversible !",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: exnetSpace === 'paris'?"#ba9964":(exnetSpace === 'service'?"#098721":(exnetSpace === 'medical'?"#3A8EBA":"#222")),
                confirmButtonText: "Oui, restaurer",
                cancelButtonText: "Annuler",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await onRestaure()
                } else {
                    Swal.fire(
                        "Annuler",
                        "Restauration annulée avec succès",
                        "error",
                        500
                    );
                }
            });

        } catch (error) {
            console.log(error)
        }
    }

    const onRestaure = useCallback(async () => {
        if (shipping_id) {
            const { data } = await opsService.restoreShipping({ shipping_id });
            if (data.success) {
                navigate(`/ops/${isAuth?.spaceName}/dash/en-cours`)
            }
        }
    }, [shipping_id])

    return (
        <div>
            {expeditionType === "annulee" && <a
                className="dropdown-item "
                href="#"
                onClick={handleRestaure}
            >
                <i
                    className={`fa fa-user mx-3`}
                ></i>
                Restaurer
            </a>}
        </div>
    )
}
