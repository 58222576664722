import React, { useEffect, useState } from 'react';
import List from './List';
import Filter from './Filter';
import adminService from '../../services/admin/adminService';
import { toast } from 'react-toastify';
import PaginationComponent from '../paginationComponent/PaginationComponent';
import { exnetSpaceColor, urlSplit } from '../../utils/Functions/otherFunctions';
import { useAppContext } from '../../context';
import { FunnelIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

function Index(props) {
    const [filterData, setfilterData] = useState({});
    const [historiesList, setHistoriesList] = useState([])
    const [isSearch, setisSearch] = useState(false)
    const [isLoading, setisLoading] = useState(false)
    const [Data, setData] = useState({ data: [] })
    const [page, setpage] = useState(1)
    const [totalItems, setTotalItems] = useState(0);
    const [perPageItems, setperPageItems] = useState(25);
    const [currentPage, setCurrentPage] = useState(0);
    const [showFilter, setShowFilter] = useState(true);
    const { isAuth, setIsAuth } = useAppContext();
    const { role } = urlSplit()

    const fetchData = async () => {
        setisLoading(true)
        if (filterData?.user_id?.value?.id || filterData?.user_id) {
            let value = {
                user_id: filterData?.user_id?.value?.id ?? filterData?.user_id,
                per_page: perPageItems,
                page: page
            }

            if (filterData?.ref) {
                value.ref = parseInt(filterData.ref)
            }

            if (filterData?.startDate && filterData?.endDate) {
                value.date = {
                    start: filterData.startDate,
                    end: filterData.endDate
                }
            }

            if (role === 'admin') {
                adminService.getAllHistories(value).then(
                    (res) => {
                        let result = res.data.data.data;
                        setTotalItems(res.data.data.total)
                        setHistoriesList([...result])
                    },
                    (error) => {
                        console.log(error)
                    }
                )
            } else {
                adminService.getAllHistoriesByOps(value).then(
                    (res) => {
                        let result = res.data.data.data;
                        setTotalItems(res.data.data.total)
                        setHistoriesList([...result])
                    },
                    (error) => {
                        console.log(error)
                    }
                )
            }

            setisLoading(false)
        }
        else {
            toast.error('Vous devez choisir un utilisateur', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })

            setisLoading(false)
        }

    }

    const handleSearch = () => {
        fetchData()
    }

    const revokeSearch = () => {
        setfilterData({});
        setisLoading(true);
        setisSearch(false)
        setpage(1);
        setData({ data: [] }); // Réinitialisez les données
    }

    const handlePageClick = (event) => {
        setpage(parseInt(event))
        setCurrentPage(event);
        fetchData();
    };

    useEffect(() => {
        if (filterData?.user_id?.value?.id || filterData?.user_id) {
            fetchData();
        }

    }, [filterData, page,perPageItems])


    return (
        <div>
            <div className='flex justify-end '>
                <div className="rounded-full cursor-pointer w-fit" style={{
                    color: "#fff",
                    backgroundColor: `${exnetSpaceColor(isAuth?.spaceName)}`,
                }}>
                    <FunnelIcon onClick={() => setShowFilter(!showFilter)} className={"h-4 w-4 m-3 text-xl font-bold  "} />
                </div>
            </div>

            <section
                className={clsx(
                    "transition-all duration-700 ease-in-out",
                    { "max-h-[800px] opacity-100  p-3 ": showFilter, "max-h-0 opacity-0": !showFilter }
                )}> <Filter filterData={filterData} setfilterData={setfilterData} revokeSearch={revokeSearch} handleSearch={handleSearch} />
            </section>


            <PaginationComponent
                handlePageClick={handlePageClick}
                totalItems={totalItems}
                perPageItems={perPageItems}
                setnumberPerPage={setperPageItems}
            />

            <List items={historiesList} isLoading={isLoading} />
            <PaginationComponent
                element='pagination'
                handlePageClick={handlePageClick}
                totalItems={totalItems}
                perPageItems={perPageItems}
                setnumberPerPage={setperPageItems}
            />
        </div>
    );
}

export default Index;