import React from "react";
import "./style.css";
import Head from "../HeadComponent/Head";

function PolitiqueConfidentiakiteComponent(props) {
    return (
        <div style={{paddingBlock:'5vh'}}>
            <Head espace={'paris'}>
                <title>{`Exnet | Politique de confidentialité`}</title>
            </Head>
            <section id="wrapper">
                <div className="container" style={{ maxWidth: "60rem" }}>
                    <div id="content">
                        <h1>Politique de confidentialité</h1>
                        <p className={'paragraphe'}>
                            <span
                                style={{
                                    fontSize: "13.5pt",
                                    lineHeight: "101%",
                                    fontFamily: "Roboto",
                                    color: "black",
                                }}
                            >
                                EXNET PARIS entend fournir aux utilisateurs un
                                environnement en ligne sûr et sécurisé. Le
                                Centre de protection clients fournit des
                                informations sur l'approche adoptée par EXNET
                                PARIS pour favoriser votre protection et sur les
                                mesures que vous pouvez vous-même adopter pour
                                vous protéger.
                            </span>
                        </p>
                        <br />
                        <h2>POLITIQUE DE CONFIDENTIALITÉ</h2>
                        <br />
                        <p className={'paragraphe'}>
                            <span
                                style={{
                                    fontSize: "13.5pt",
                                    lineHeight: "101%",
                                    fontFamily: "Roboto",
                                    color: "black",
                                }}
                            >
                                Votre confiance est au cœur de nos
                                préoccupations. C'est pourquoi nous protégeons
                                vos informations de manière responsable, tout en
                                continuant à vous fournir le service
                                d’excellence que vous attendez d’EXNET PARIS et
                                de ses groupes d'exploitation, filiales et
                                succursales. Chez EXNET PARIS, nous nous
                                engageons à protéger votre vie privée et la
                                sécurité des informations permettant
                                d’identifier une personne physique (ci-après
                                dénommées « données personnelles »). EXNET PARIS
                                a créé cette politique de confidentialité
                                (ci-après dénommée "Politique de
                                Confidentialité") afin d'expliquer comment EXNET
                                PARIS collecte et utilise les données à
                                caractère personnel.
                            </span>
                        </p>
                        <br />
                        <p className={'paragraphe'}>Dernière mise à jour : Juin 2021.</p>
                        <br />
                        <p className={'paragraphe'}>
                            <span
                                style={{
                                    fontSize: "13.5pt",
                                    lineHeight: "101%",
                                    fontFamily: "Roboto",
                                    color: "black",
                                }}
                            >
                                Lorsque cet avis de confidentialité mentionne "
                                EXNET PARIS ", "nous" ou "notre", EXNET PARIS
                                fait référence aux sociétés EXNET PARIS qui
                                décident des finalités et des moyens du
                                traitement de vos données personnelles dans le
                                cadre de cette politique de confidentialité.
                            </span>
                        </p>
                        <br />
                        <p className={'paragraphe'}>
                            <span
                                style={{
                                    fontSize: "13.5pt",
                                    lineHeight: "101%",
                                    fontFamily: "Roboto",
                                    color: "black",
                                }}
                            >
                                Lorsque vous utilisez nos services en Europe,
                                vos données personnelles sont contrôlées par
                                EXNET PARIS, dont le siège social est situé à
                                Paris, en France. Lorsque vous utilisez nos
                                services dans le monde entier, vos données
                                personnelles sont contrôlées par EXNET PARIS,
                                dont le siège social est situé à Paris, en
                                France.
                            </span>
                        </p>
                        <br />
                        <h2>Informations de contact:</h2>
                        <br />
                        <address>
                            <h2>EXNET PARIS</h2>
                            <span> 20 Allée des Érables 93420 Villepinte </span><br/>
                            <span>France</span><br/>
                            <span>
                                {" "}
                                <a href="mailto:dpo@exnetparis.com">
                                    {" "}
                                    E:dpo@exnetparis.com
                                </a>{" "}
                            </span>
                        </address>

                        <br />
                        <h2>
                            1. Cette politique de confidentialité vous
                            concerne-t-elle ?
                        </h2>
                        <p className={'paragraphe'}>
                            <span
                                style={{
                                    fontSize: "13.5pt",
                                    lineHeight: "101%",
                                    fontFamily: "Roboto",
                                    color: "black",
                                }}
                            >
                                Cette politique de confidentialité s'applique à
                                vous si vous êtes un client d’ EXNET PARIS, un
                                destinataire d'un colis livré par EXNET PARIS,
                                un fournisseur d’ EXNET PARIS, ou si vous
                                contactez EXNET PARIS, par exemple, via nos
                                sites Web tels que <a href="www.exnetparis.com">www.exnetparis.com</a>  et
                                 <a href="www.myexnet.com"> www.myexnet.com</a>
                                , y compris les (sous-) pages et
                                applications mobiles (ci-après dénommées «sites
                                Web»), utilisant les réseaux sociaux ou si vous
                                recevez des e-mails envoyés par EXNET PARIS.
                                <br />
                                <br />
                                Nos sites web s’adressent à un public général et
                                ne sont pas destinés aux enfants. Nous ne
                                collectons pas, sciemment ou intentionnellement,
                                des données personnelles auprès d’enfant âgés de
                                moins de 16 ans. Si vous avez moins de 16 ans,
                                veuillez ne pas nous soumettre de données
                                personnelles et demander l’assistance de l’un de
                                vos parents ou d’un tuteur.
                                <br />
                                <br />
                                Si vous pensez qu’un enfant de moins de 16 ans a
                                pu nous divulguer des données personnelles,
                                veuillez nous contacter à l’adresse
                                <a href="mailto:dpo@exnetparis.com"> dpo@exnetparis.com </a>
                                 afin que nous puissions les
                                supprimer.
                            </span>
                        </p>
                        <h2>
                            <br />
                            2. Quelles sont les données personnelles collectées
                            par EXNET PARIS ?
                        </h2>
                        <br />
                        <p className={'paragraphe'}>
                            <span
                                style={{
                                    fontSize: "13.5pt",
                                    lineHeight: "101%",
                                    fontFamily: "Roboto",
                                    color: "black",
                                }}
                            >
                                Dans le cadre de ses activités commerciales et
                                de la fourniture des services, EXNET PARIS est
                                amené à traiter des données à caractère
                                personnel. Sans vos données personnelles, nous
                                ne serons pas en mesure de vous fournir les
                                services demandés. En règle générale, les
                                données personnelles que vous fournissez
                                directement ou indirectement à EXNET PARIS
                                lorsque vous utilisez nos services et visitez
                                nos sites Web sont les suivantes :
                            </span>
                        </p>
                        <ul
                            style={{
                                listStyle: "circle",
                                margin: 10,
                                paddingLeft: 40,
                                fontSize: "13.5pt",
                                lineHeight: "101%",
                                fontFamily: "Roboto",
                                color: "black",
                            }}
                        >
                            <li>
                                <b>Informations de contact</b>.
                                <p className={'paragraphe'}>Comme votre nom,
                                adresse, adresse e-mail et numéro de téléphone ;</p>
                                
                            </li>
                            <li>
                                <b>Informations financières</b>. 
                                <p className={'paragraphe'}> Comme votre
                                numéro de compte bancaire, l’état du paiement et
                                vos factures;</p>
                            </li>
                            <li>
                                <b>Informations d'identification</b>.
                                <p className={'paragraphe'}>Comme le
                                numéro de votre permis de conduire. Si vous
                                n’avez pas de permis de conduire, vous
                                utiliserez une autre méthode d’identification
                                qui nous assurera que nous pouvons vous
                                identifier, mais qui porte le moins atteinte à
                                la vie privée que possible ;</p>  
                            </li>
                            <li>
                                <b>Informations sur le compte.</b>. 
                                <p className={'paragraphe'}>Comme les
                                détails de connexion, y compris votre adresse
                                e-mail, et d'autres informations fournies via
                                votre compte ;</p> 
                            </li>
                            <li>
                                <b>
                                    Informations relatives à des expéditions et
                                    aux services.
                                </b>{" "}<br/> 
                                <p className={'paragraphe'}> Comme le numéro de suivi de l'envoi, les
                                informations d'acheminement de l'envoi, les
                                données de localisation, le statut de l'envoi,
                                le lieu de livraison, le type d'emballage, le
                                nombre de pièces, le poids, la photo du colis et
                                les informations douanières;</p>
                               
                            </li>
                            <li>
                                <b>
                                    Informations sur l'utilisateur et les
                                    préférences.
                                </b>{" "}
                                <p className={'paragraphe'}> Cela inclut, le cas échéant, les montants des
                                envois, les réclamations, l'historique des
                                achats et les activités commerciales associées,
                                les communications, les informations relatives
                                aux sondages et les préférences en matière
                                d'achat.</p>
                               
                            </li>
                            <li>
                                <b className="my-3">Informations générées automatiquement.</b>{" "}
                                <p className={'paragraphe'}>
                                    Cela comprend l'adresse IP, l'identifiant unique
                                de l'appareil ou de l'utilisateur, le type de
                                système et de navigateur, les horodatages,
                                l'adresse du site Web référant, le contenu et
                                les pages auxquelles vous avez accédé sur nos
                                sites Web ou applications mobiles, les actions
                                sur les dates, les heures et les lieux, les
                                sites Web visités (si vous vous connectez à nos
                                services sans fil en magasin), cliquez sur
                                l'information sur le flux et sur l'emplacement
                                du périphérique (si vous activez la
                                fonctionnalité dans l'application mobile). 
                                </p>
                               
                            </li>
                        </ul>
                        <br />
                        <h2>3. EXNET PARIS utilise-t-il des cookies ?</h2>
                        <br />
                        <p className={'paragraphe'}>
                            <span
                                lang="FR"
                                style={{
                                    fontSize: "13.5pt",
                                    lineHeight: "101%",
                                    fontFamily: "Roboto",
                                    color: "black",
                                }}
                            >
                                Oui, EXNET PARIS utilise des cookies et des
                                technologies similaires sur ses sites Web. Grâce
                                à ces cookies, EXNET PARIS obtient
                                automatiquement les données personnelles
                                énumérées ci-dessus lorsque vous visitez nos
                                sites Web.
                            </span>
                        </p>
                        <br />
                        <h2>
                            4. Pourquoi EXNET PARIS traite-t-il les données
                            personnelles ?
                        </h2>
                        <br />
                        <p className={'paragraphe'}>
                            Les données personnelles doivent être collectées,
                            utilisées, stockées ou autrement traitées si
                            nécessaire dans le cadre d'une gestion responsable,
                            efficace et efficiente des affaires d’EXNET PARIS.
                            EXNET PARIS traite les données personnelles en
                            fonction des motifs légaux applicables. Le fondement
                            juridique est souvent intrinsèquement lié à
                            l'objectif de l'entreprise. Cela signifie, par
                            exemple, que l'exécution d'un contrat peut être à la
                            fois un motif juridique et un objectif commercial
                            pour EXNET PARIS. Par conséquent, nous clarifierons
                            d'abord le ou les motifs juridiques sur lesquels
                            EXNET PARIS traite vos données à caractère
                            personnel, puis les objectifs commerciaux pour
                            lesquels nous utilisons vos données à caractère
                            personnel pour :
                        </p>
                        <br />
                        <p className={'paragraphe'}>
                            <i>Motifs légaux</i>
                            <br />
                            En général, EXNET PARIS traite vos données
                            personnelles sur la base de l’un des motifs
                            juridiques suivants :
                        </p>
                        <br />
                        <ul
                            style={{
                                listStyle: "circle",
                                margin: 10,
                                paddingLeft: 40,
                            }}
                        >
                            <li>
                                Le traitement est nécessaire pour exécuter un
                                accord entre vous et EXNET PARIS,
                            </li>
                            <li>
                                Le traitement est nécessaire pour nous permettre
                                de respecter nos obligations légales,
                            </li>
                            <li>
                                Le traitement est nécessaire pour protéger vos
                                intérêts vitaux ou d’autres personnes,
                            </li>
                            <li>
                                Le traitement est nécessaire pour les intérêts
                                légitimes de EXNET PARIS, sauf si vos intérêts
                                ou vos droits et libertés fondamentaux priment
                                sur ces intérêts, où
                            </li>
                            <li>
                                Le cas échéant, nous vous demanderons votre
                                consentement.
                            </li>
                        </ul>
                        <br />
                        <p className={'paragraphe'}>
                            <i>Fins commerciales</i>
                            <br />
                            EXNET PARIS ne doit collecter, utiliser ou autrement
                            traiter des données à caractère personnel que si le
                            traitement relève d'un ou de plusieurs des objectifs
                            commerciaux légitimes énumérés ci-dessous :
                        </p>
                        <ul
                            style={{
                                listStyle: "circle",
                                margin: 10,
                                paddingLeft: 40,
                            }}
                        >
                            <li>
                                <b>
                                    Développement de produits, recherche et
                                    amélioration des produits et / ou services
                                    EXNET PARIS. EXNET PARIS
                                </b>
                                {"{"}" "{"}"}
                                traite les données à caractère personnel
                                nécessaires au développement et à l’amélioration
                                des produits et / ou services EXNET PARIS, à la
                                recherche et au développement (par exemple,
                                analyser les informations relatives à l’envoi et
                                les services pour améliorer nos services).
                            </li>
                            <li>
                                <b>Respect des accords.</b> Cela comprend les
                                services d’expédition, le suivi des services
                                EXNET PARIS, la communication avec des individus
                                et d’autres parties concernant les services, la
                                réponse aux demandes d’informations
                                (supplémentaires), la résolution des litiges et
                                la préparation d’accords (par exemple, associer
                                le numéro de suivi de l’expédition à votre
                                compte pour vous permettre de suivre votre
                                envoi).
                            </li>
                            <li>
                                <b>
                                    Gestion des relations et de l’aspect
                                    marketing pour les activités commerciales
                                </b>
                                . De manière générale, EXNET PARIS traite les
                                données à caractère personnel nécessaires au
                                développement et à l'amélioration de produits et
                                / ou services EXNET PARIS, à la gestion des
                                comptes, aux services clients et à la
                                réalisation d'activités marketing (ciblées) afin
                                d'établir une relation avec un client et / ou de
                                ainsi que pour étendre une relation avec un
                                client, un partenaire commercial ou un
                                fournisseur et pour effectuer des analyses à des
                                fins de statistiques et de données scientifiques
                                relatives aux données à caractère personnel (par
                                exemple, diffuser de la publicité, des
                                communications et du contenu provenant de nos
                                sites ainsi que de tiers spécifiques à vos
                                centres d'intérêt ).
                            </li>
                            <li>
                                <b>
                                    Exécution du processus opérationnel, gestion
                                    interne et rapports de gestion.{"{"}" "{"}"}
                                </b>
                                {"{"}" "{"}"}
                                Cela inclut les activités en lien avec la
                                gestion des actifs de la société, l'organisation
                                d'audits internes et d’enquêtes, les finances et
                                la comptabilité, la mise en place de contrôles
                                commerciaux, la fourniture d’installations de
                                traitement centrales plus efficaces, la gestion
                                des fusions, les acquisitions et les ventes
                                ainsi que le traitement des données personnelles
                                pour les rapports de gestion et analyses
                            </li>
                            <li>
                                <b>Sûreté et sécurité.</b> Les données à
                                caractère personnel doivent être incluses dans
                                le traitement pour des activités telles que
                                celles concernant la sécurité et la santé, la
                                protection de EXNET PARIS et les actifs du
                                client, du fournisseur ou du partenaire
                                commercial et l'authentification du statut du
                                client, du fournisseur ou du partenaire
                                commercial et des droits d'accès (par exemple,
                                services sécurisés pour les transactions en
                                ligne et hors ligne).
                            </li>
                            <li>
                                <b>
                                    Protéger les intérêts vitaux des individus.
                                </b>
                                {"{"}" "{"}"}
                                Cela comprend le traitement des données lorsque
                                cela est nécessaire pour protéger vos intérêts
                                vitaux ou d'autres personnes (par exemple, pour
                                des raisons médicales urgentes).
                            </li>
                            <li>
                                <b>Respect des obligations légales.</b> Cela
                                comprend le traitement des données à caractère
                                personnel nécessaire au respect des lois,
                                réglementations et directives sectorielles
                                auxquelles EXNET PARIS est soumis (par exemple,
                                les noms correspondants des clients,
                                fournisseurs et partenaires commerciaux avec les
                                listes des partenaires commerciaux non
                                autorisés).
                            </li>
                        </ul>
                        <br />
                        <br />
                        <h2>
                            <br />
                            5. Qui a accès à vos données personnelles ?
                        </h2>
                        <br />
                        <p className={'paragraphe'}>
                            EXNET PARIS peut partager vos données personnelles
                            avec des tierces parties dans les situations
                            suivantes :
                        </p>
                        <ul
                            style={{
                                listStyle: "circle",
                                margin: 10,
                                paddingLeft: 40,
                            }}
                        >
                            <li>
                                Avec ses sociétés affiliées, groupes
                                opérationnels, filiales et succursales, ou avec
                                des tiers si cela est nécessaire aux fins
                                énumérées ci-dessus. Le cas échéant, EXNET PARIS
                                demandera à des tiers de mener ses activités
                                conformément aux politiques et directives
                                d’EXNET PARIS en matière de protection des
                                données.
                            </li>
                            <li>
                                Avec des entités de traitement de données,
                                c’est-à-dire que les parties traitent des
                                données à caractère personnel pour notre compte.
                                Dans de tels cas, ces tiers utilisent vos
                                données personnelles uniquement aux fins
                                décrites ci-dessus et uniquement conformément à
                                nos instructions. EXNET PARIS utilisera
                                uniquement appel à des entités de traitement
                                offrant des garanties suffisantes pour mettre en
                                œuvre les mesures techniques et
                                organisationnelles appropriées et pour assurer
                                la protection des droits des personnes
                                concernées.
                            </li>
                            <li>
                                Avec ses employés si et dans la mesure
                                nécessaire à l'accomplissement de leurs tâches.
                                Dans un tel cas, l'accès ne sera accordé que si
                                et dans la mesure nécessaire aux fins décrites
                                ci-dessus et uniquement si l'employé est lié par
                                un accord de confidentialité.
                            </li>
                            <li>
                                Si et lorsque cela est requis par la loi, une
                                décision de justice ou une autre procédure
                                légale, par exemple, avec des organismes chargés
                                de l'application de la loi ou d'autres
                                organismes gouvernementaux, afin d'établir ou
                                d'exercer nos droits légaux ou dans le cadre
                                d'une transaction commerciale, telle qu'un
                                dessaisissement, fusion, consolidation ou vente
                                d'actifs, ou dans le cas improbable d'une
                                faillite.
                            </li>
                        </ul>
                        <br />
                        <h2>
                            6. Quelle est la durée de conservation de vos
                            données personnelles ?
                        </h2>
                        <br />
                        <p className={'paragraphe'}>
                            Nous ne conserverons pas vos données personnelles
                            plus que nécessaire aux fins pour lesquelles nous
                            traitons vos données personnelles. Après la période
                            de détention, vos données personnelles seront
                            supprimées ou anonymisées, sauf si nous devons
                            conserver certaines de vos données personnelles à
                            d'autres fins. Nous ne le ferons que si nous
                            disposons d'un fondement juridique pour conserver
                            vos données personnelles. Nous veillerons également
                            à ce que les données personnelles ne soient
                            accessibles qu'à cette autre fin.
                        </p>
                        <br />
                        <p className={'paragraphe'}>
                            Par exemple, nous avons besoin de vos données
                            personnelles pour effectuer le processus de
                            dédouanement. Les lois douanières nous imposent de
                            conserver / stocker - certaines de ces données
                            personnelles. En règle générale, cette période varie
                            de 3 à 7 ans, en fonction du pays et de la
                            législation douanière applicables. Dans ces cas,
                            nous ne stockons que les données personnelles
                            nécessaires pour respecter nos obligations légales.
                        </p>
                        <br />
                        <p className={'paragraphe'}>
                            Veuillez nous contacter en utilisant les coordonnées
                            mentionnées ci-dessus si vous avez des questions sur
                            des durées de conservation spécifiques.
                        </p>
                        <br />
                        <h2>
                            7. Quelles mesures sont prises par EXNET PARIS pour
                            protéger vos données personnelles ?
                        </h2>
                        <br />
                        <p className={'paragraphe'}>
                            EXNET PARIS a pris les mesures techniques et
                            organisationnelles appropriées pour protéger vos
                            données à caractère personnel contre tout traitement
                            accidentel ou illégal, notamment en veillant à ce
                            que :
                        </p>
                        <br />
                        <ul
                            style={{
                                listStyle: "circle",
                                margin: 10,
                                paddingLeft: 40,
                            }}
                        >
                            <li>
                                Vos données personnelles sont protégées contre
                                les accès non autorisés.
                            </li>
                            <li>
                                La confidentialité de vos données personnelles
                                est assurée ;
                            </li>
                            <li>
                                L'intégrité et la disponibilité de vos données
                                personnelles seront maintenues ;
                            </li>
                            <li>
                                Le personnel est formé aux exigences de sécurité
                                de l’information ; et
                            </li>
                            <li>
                                Les violations de données réelles ou présumées
                                sont signalées conformément à la loi applicable.
                            </li>
                        </ul>
                        <br />
                        <h2>
                            8. Où est-ce que vos données sont-elles stockées et
                            transférées par EXNET PARIS ?
                        </h2>
                        <br />
                        <p className={'paragraphe'}>
                            En raison de la nature de nos activités et des
                            services que nous fournissons à nos clients, EXNET
                            PARIS peut être amenée à transférer vos données à
                            caractère personnel vers des pays extérieurs au pays
                            dans lequel vous résidez. Quoi qu’il arrive EXNET
                            PARIS veillera à ce que ce transfert soit soumis aux
                            garanties appropriées. Pour l'Espace économique
                            européen, de tels transferts à des tiers (en dehors
                            de l'Espace économique européen) seront régis par un
                            contrat basé sur les clauses contractuelles types
                            pour les transferts de données approuvés par la
                            Commission européenne ou d'autres garanties
                            appropriées. Pour plus d'informations détaillées sur
                            ces garanties, veuillez contacter  <a href="mailto:dpo@exnetparis.com"> dpo@exnetparis.com </a>
                        </p>
                        <br />
                        <h2>
                            9. Quels droits relatifs à vos données pouvez-vous
                            exercer ?
                        </h2>
                        <br />
                        <p className={'paragraphe'}>
                            En vertu de la loi applicable à l'utilisation de vos
                            données personnelles, vous disposez d’un certain
                            nombre de droits que vous pouvez exercer en relation
                            avec vos données personnelles. Veuillez noter que
                            dans certains cas, nous ne sommes pas tenus - de -
                            pleinement - satisfaire votre demande, car ces
                            droits peuvent être conditionnels ou parce que nous
                            devons traiter vos droits en conformité avec nos
                            droits et obligations de traiter vos données
                            personnelles et nous devons protéger les droits et
                            libertés des autres utilisateurs. Un certain nombre
                            de droits que vous avez concernant vos données
                            personnelles, tels qu’ils sont applicables dans
                            l’Espace économique européen, sont expliqués
                            ci-dessous :
                        </p>
                        <br />
                        <p className={'paragraphe'}>
                            <i> Droit d'accès</i>
                            <br />
                            Vous avez droit à une copie des données personnelles
                            que nous détenons à votre sujet et à des
                            informations sur leur utilisation. Vos données
                            personnelles vous seront généralement fournies sous
                            forme numérique. Nous pouvons vous demander de
                            prouver votre identité avant de fournir les
                            informations demandées.
                        </p>
                        <br />
                        <p className={'paragraphe'}>
                            <i>Droit de rectification</i>
                            <br />
                            Nous prenons des mesures raisonnables pour nous
                            assurer que les informations que nous détenons sur
                            vous sont exactes et complètes. Cependant, si vous
                            pensez que ce n'est pas le cas, vous avez le droit
                            de demander que les données personnelles incomplètes
                            ou inexactes que nous traitons à votre sujet soient
                            modifiées.
                        </p>
                        <br />
                        <p className={'paragraphe'}>
                            <i>Droit de restreindre le traitement</i>
                            <br />
                            Vous avez le droit de nous demander d'effacer vos
                            données personnelles, par exemple lorsque les
                            données personnelles que nous avons collectées ne
                            sont plus nécessaires à la réalisation de l'objectif
                            initial, lorsque les données personnelles sont
                            devenues obsolètes ou lorsque vous retirez votre
                            consentement. Cependant, cette devra être mis en
                            balance avec d'autres facteurs. Par exemple, nous
                            pourrions ne pas être en mesure de répondre à votre
                            demande en raison de certaines obligations légales
                            ou réglementaires
                        </p>
                        <br />
                        <p className={'paragraphe'}>
                            <i>Droit à la portabilité des données</i>
                            <br />
                            Dans certaines circonstances, vous pouvez avoir le
                            droit de demander que nous transférions les données
                            personnelles que vous nous avez fournies à un tiers
                            de votre choix. Ce droit ne peut être exercé que
                            lorsque vous nous avez fourni les données
                            personnelles et que nous les traitons par des moyens
                            automatisés sur la base de votre consentement ou
                            afin de nous acquitter de nos obligations découlant
                            d'un contrat avec vous.
                        </p>
                        <br />
                        <p className={'paragraphe'}>
                            <i>Droit d'opposition</i>
                            <br />
                            Vous avez le droit de vous opposer à un traitement
                            fondé sur nos intérêts légitimes. En cas de
                            traitement de données personnelles à des fins de
                            marketing, vous avez le droit de vous opposer à tout
                            moment. Lorsque vous nous demandez la cessation
                            d'utilisation de vos données personnelles à des fins
                            de marketing, EXNET PARIS cessera immédiatement
                            d'utiliser vos données personnelles. À d'autres fins
                            fondées sur nos intérêts légitimes, nous ne
                            traiterons plus les données à caractère personnel
                            sur cette base lorsque vous déposerez une opposition
                            fondée sur vos motifs liés à votre situation
                            particulière, à moins que nous n'ayons un motif
                            impérieux légitime pour le traitement. Notez
                            cependant que nous pourrions être dans l’incapacité
                            de fournir certains services ou avantages si nous
                            sommes incapables de traiter les données
                            personnelles nécessaires à cette fin.
                        </p>
                        <br />
                        <p className={'paragraphe'}>
                            <i>
                                Droits relatifs à la prise de décision
                                automatisée
                            </i>
                            <br />
                            Vous avez le droit de ne pas être soumis à une prise
                            de décision automatisée, y compris le profilage, ce
                            qui produit un effet juridique pour vous ou a un
                            effet significatif similaire. Si vous avez fait
                            l'objet d'une décision automatisée et que vous
                            n'êtes pas d'accord avec le résultat, vous pouvez
                            nous contacter à l'aide des informations ci-dessous
                            et nous demander de revoir la décision.
                        </p>
                        <br />
                        <p className={'paragraphe'}>
                            <i>Droit de retrait du consentement</i>
                            <br />
                            Nous pouvons vous demander votre consentement pour
                            traiter vos données personnelles dans des cas
                            spécifiques. Dans ce cas, vous avez le droit de
                            retirer votre consentement à tout moment. EXNET
                            PARIS arrêtera le traitement ultérieur dans les
                            meilleurs Délaiss après le retrait de votre
                            consentement. Toutefois, cela n’affecte pas la
                            licéité du traitement avant le retrait du
                            consentement.
                        </p>
                        <br />
                        <h2>
                            10. Quelle est la marche à suivre si vous avez des
                            questions supplémentaires ou des réclamations ?
                        </h2>
                        <br />
                        <p className={'paragraphe'}>
                            Les questions ou réclamations concernant le
                            traitement de vos données personnelles peuvent être
                            adressées à EXNET PARIS en utilisant les coordonnées
                            indiquées en haut de cette politique de
                            confidentialité.
                        </p>
                        <br />
                        <p className={'paragraphe'}>
                            Vous avez également le droit de déposer une plainte
                            auprès de l'autorité compétente (locale) de la
                            protection des données dans la juridiction où vous
                            travaillez, où vous habitez ou où se déroule une
                            infraction présumée. En règle générale, l'autorité
                            de surveillance principale d’EXNET PARIS dans
                            l'Espace économique européen est la Commission
                            Nationale de l'Informatique et des Libertés (CNIL),
                            à moins que l'infraction présumée ne soit de nature
                            purement locale. Une liste des autorités européennes
                            de protection des données est disponible{"{"}" "
                            {"}"}
                            <a
                                style={{ color: "blue" }}
                                href="https://edpb.europa.eu/about-edpb/about-edpb/members_en"
                            >
                                ici
                            </a>
                            .
                        </p>
                        <br />
                        <h2>
                            11. Cette politique de confidentialité fera-t-elle
                            l’objet de mises à jour ?
                        </h2>
                        <br />
                        <p className={'paragraphe'}>
                            EXNET PARIS peut mettre à jour cette politique de
                            confidentialité de temps à autre. En cas de
                            modification importante, EXNET PARIS s'efforcera de
                            vous informer activement à propos de ces
                            modifications. EXNET PARIS publiera à tout moment
                            sur les sites Web une politique de confidentialité
                            actualisé indiquant les modifications les plus
                            récentes.
                        </p>
                        <br />
                        <br />
                    </div>
                </div>
            </section>
        </div>
    );
}

export default PolitiqueConfidentiakiteComponent;
