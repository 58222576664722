import React, { useEffect, useRef, useState } from 'react';
import './style.css'
// import logoParis from "./../../assets/img/v1/logo.png";
// import logoMedical from "./../../assets/img/v1/medical.png";
// import logoService from "./../../assets/img/v1/service_full3.png";
import { exnetSpaceColor, urlSplit } from '../../utils/Functions/otherFunctions';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import logoParis from "./../../assets/img/exnext-logo/paris.png";
import logoAdmin from "./../../assets/img/exnext-logo/logoAdmin.png";
import logoMedical from "./../../assets/img/exnext-logo/medical.png";
import logoService from "./../../assets/img/exnext-logo/service.png";
import { useAppContext } from '../../context';

function ManifestTemplateComponent({ isOpenTemplateModal, onCloseTemplateModal, data, expedition }) {
    const { role, exnetSpace } = urlSplit();
    const [packageData, setpackageData] = useState({});
    const { isAuth, setIsAuth } = useAppContext();
    const pdfRef = useRef()
    const handleOutsideClick = (event) => {
        //console.log(event)
        if (event.target.id === "confirm-popup-modal") {
            onCloseTemplateModal();
        }
    };


    const download = () => {
        const input = pdfRef.current;
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4', true);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
            const imgX = (pdfHeight - imgWidth * ratio) / 12;
            const imgY = (pdfHeight - imgWidth * ratio) / 12;
            pdf.addImage(imgData, 'PNG', 3, 3, pdfWidth, pdfHeight);
            // (pdfHeight - imgHeight * ratio) / 2;
            // pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);

            pdf.addPage();
            // pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
            pdf.addImage(imgData, 'PNG', 3, 3, pdfWidth, pdfHeight);

            //add automatically page after overflow

            pdf.save('manifest.pdf');
        })
    }

    useEffect(() => {
        setpackageData(expedition?.packages.length > 0 ? expedition?.packages[0] : {});
    }, [expedition])



    return (
        isOpenTemplateModal && (
            <div
                id="confirm-popup-modal"
                onClick={handleOutsideClick}
                className="fixed inset-0 overflow-y-auto"
                style={{ zIndex: 9999 }}
            >
                <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                    <div
                        className="fixed inset-0 transition-opacity"
                        aria-hidden="true"
                    >
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <div
                        className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full`}
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline"
                    >
                        <div className="p-4 pt-5 bg-white sm:p-6 sm:pb-4">
                            <button
                                onClick={onCloseTemplateModal}
                                className="absolute top-3 right-4 focus:outline-none"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    className="w-6 h-6 text-gray-600"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    ></path>
                                </svg>
                            </button>

                            <h3
                                className="text-lg font-medium leading-6 text-center text-gray-900"
                                id="modal-headline"
                            >
                                {'Template de Manifest'}
                            </h3>
                            <div className={'text-end w-full'}>
                                {/* {data.length > 0 &&
                                    <button
                                        onClick={download}
                                        type="button"

                                        className="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                                        style={{ color: '#fff', backgroundColor: `${exnetSpaceColor(exnetSpace)}` }}
                                    >
                                        Télécharger
                                    </button>} */}

                                <button
                                    onClick={onCloseTemplateModal}
                                    type="button"
                                    className="text-gray-500 focus:ring-4 focus:outline-none  rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
                                    style={{ color: '#fff', backgroundColor: `${exnetSpaceColor(exnetSpace)}` }}
                                >
                                    Annuler
                                </button>
                            </div>
                            <div className="w-full text-center " ref={pdfRef}>
                                <div>
                                    <table border="1" className={'tableau'}>
                                        <tbody>
                                            <tr >
                                                <td className={'colonnes'}> <span>
                                                    {" "}
                                                    <img
                                                        src={`${exnetSpace === 'service' ? logoService : (exnetSpace === 'paris' ? logoParis : logoMedical)}`}
                                                        style={{ maxWidth: "178px" }}
                                                        alt="Logo"
                                                    />

                                                </span></td>
                                                <td className={'colonnes'}><h2>EXPORT MANIFEST #123456</h2></td>
                                                <td className={'colonnes'}>
                                                    <div >
                                                        <p className="s20">
                                                            EXNET PARIS
                                                        </p>
                                                        <p className="s20">
                                                            20 ALLEE DES ERABLES
                                                        </p>
                                                        <p className="s20">
                                                            93420 VILLEPINTE
                                                        </p>
                                                        <p className="s20">
                                                            FRANCE
                                                        </p>
                                                        <p className="s20">
                                                            +331-8228-4030
                                                        </p>

                                                    </div>
                                                </td>

                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div style={{ paddingBottom: '15px' }}>

                                    <table border="1" className={'tableau'}>
                                        <thead>
                                            <tr>
                                                <th className={'lignes'}>AWB</th>
                                                <th className={'lignes'}>Cross Weight</th>
                                                <th className={'lignes'}>Vol Weight</th>
                                                <th className={'lignes'}>Shipper</th>
                                                <th className={'lignes'}>Consignee</th>
                                                <th className={'lignes'}>Content</th>
                                                <th className={'lignes'}>Comments</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.length > 0 ? <>

                                                {data.map((manif, index) => (
                                                    <tr>
                                                        <td className={'colonnes'}>{manif?.mawb}</td>
                                                        <td className={'colonnes'}>{packageData?.weight}</td>
                                                        <td className={'colonnes'}>{packageData?.weight_volume}</td>
                                                        <td className={'colonnes'}>
                                                            <div style={{ paddingTop: '5px' }}>
                                                                <p className='lh1'>{expedition?.expeditor?.fullname}</p>
                                                                <p className='lh1'>{expedition?.expeditor?.address1}</p>
                                                                <p className='lh1'>{expedition?.expeditor?.postalCode}</p>
                                                                <p className='lh1'>{expedition?.expeditor?.city}</p>
                                                                <p className='lh1'>{expedition?.expeditor?.country}</p>
                                                            </div>
                                                        </td>
                                                        <td className={'colonnes'}>
                                                            <div style={{ paddingTop: '5px' }}>
                                                                <p className='lh1'>{expedition?.receiver?.fullname}</p>
                                                                <p className='lh1'>{expedition?.receiver?.address1}</p>
                                                                <p className='lh1'>{expedition?.receiver?.postalCode}</p>
                                                                <p className='lh1'>{expedition?.receiver?.city}</p>
                                                                <p className='lh1'>{expedition?.receiver?.country}</p>
                                                            </div>
                                                        </td>
                                                        <td className={'colonnes'}>{manif?.viavol}</td>
                                                        <td className={'colonnes'}>{manif?.description}</td>
                                                    </tr>
                                                ))}</> : <tr>
                                                <td colSpan={7}>Rien</td>

                                            </tr>}


                                        </tbody>

                                    </table>
                                    <span>Le manifest reprend toutes les expéditions qui auront été intégrées à un manifest similaire. Le manifest doit pouvoir recevoir autantd'expéditions que nécessaire</span>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    );
}

export default ManifestTemplateComponent;