import React, { useCallback, useState } from 'react';
import Select from "react-select";
import opsService from '../../../services/ops/opsService';
import { toast } from 'react-toastify';
import { validateEmailData } from '../../../utils/validators';
import Swal from 'sweetalert2';
import { TableComponent } from '../../TableauComponent';
import { ShippingDetails } from '../../../store/shippingDetails';
import { useAppContext } from '../../../context';
import { TrashIcon } from '@heroicons/react/24/outline';


export const EmailTabsComponent = () => {

    const { id, ref } = ShippingDetails((state) => state.details);
    const emailList = ShippingDetails((state) => state.details.emailList);
    const deleteOneEmail = ShippingDetails((state) => state.deleteOneEmail);
    const addOneEmail = ShippingDetails((state) => state.addOneEmail);
    const UpdateEmail = ShippingDetails((state) => state.UpdateEmail);
    const Email = ShippingDetails((state) => state.Email);
    const updateOneEmailByIndex = ShippingDetails((state) => state.updateOneEmailByIndex);
    const resetEmail = ShippingDetails((state) => state.resetEmail);
    const fetchDetails = ShippingDetails((state) => state.fetchDetails);

    const { isAuth, setIsAuth } = useAppContext();
    const [selectedItem, setSelectedItem] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [search, setSearch] = useState("");
    const [selectedEmails, setSelectedEmails] = useState([]);

    const typesOptions = [{ label: "Expéditeur", value: "expeditor" }, { label: "Destinataire", value: "receiver" }]

    const { valid, error: _err } = validateEmailData({ ...Email, shipping_id: id })
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = useCallback(async () => {
        console.log({ ...Email, shipping_id: id })
        try {
            console.log()
            if (_err) {
                toast.error(
                    `${_err}`,
                    {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            } else {
                if (selectedItem) {
                    //submitData.comment_id = selectedItem.id
                }
                setIsSubmitting(true);


                const request = selectedItem ? opsService.updateEmail : opsService.addEmail
                const { data } = await request({ ...Email, shipping_id: id });
                if (data.success) {
                    fetchDetails(ref)
                    const newData = {
                        ...Email, shipping_id: id
                    }
                    onSubmit(selectedItem ?
                        {
                            ...selectedItem,
                            ...newData
                        } : newData)

                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsSubmitting(false)
        }
    }, [selectedItem, _err]);



    const onSubmit = (result) => {
        console.log({ result })
        if (result) {
            let items = [...emailList];
            const resultIndex = items.findIndex(i => i.id === result.id);
            const isUpdate = resultIndex > -1
            if (isUpdate) {
                updateOneEmailByIndex(resultIndex, result)
            } else {
                const element = items.filter(i => i.type === result.type)[0]
                addOneEmail({ ...element, ...result })
            }

            setSelectedItem(null)
            resetEmail()

            toast.success(`${isUpdate ? "Email mis à jour" : "Email ajouté"} avec succès!`, {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }


    const results = emailList?.filter(t => {
        const regExp = new RegExp(search, "gi");
        return t.email.match(regExp)
    }
    )

    const onDeleteClick = useCallback(async (e) => {
        setSelectedEmails([e]);
        setTimeout(() => {
            if (id && selectedEmails.length > 0) {
                try {

                    Swal.fire({
                        title: "Êtes-vous sûr de vouloir supprimer ?",
                        text: "Cette action est irréversible !",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#d33",
                        cancelButtonColor: isAuth?.spaceName === 'paris' ? "#ba9964" : (isAuth?.spaceName === 'service' ? "#098721" : (isAuth?.spaceName === 'medical' ? "#3A8EBA" : "#222")),
                        confirmButtonText: "Oui, supprimer",
                        cancelButtonText: "Annuler",
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            await onDelete()
                            // ExpeditionService.getExpeditionDetails({ ref: expeditionDetails.ref }).then(
                            //     (data) => {
                            //         const { data: expedition } = data.data;
                            //         dispatch(setExpedition(expedition));
                            //     },
                            //     (error) => {
                            //         console.log(error);
                            //     }
                            // );
                        } else {
                            Swal.fire(
                                "Annuler",
                                "Suppression annulée avec succès",
                                "error",
                                500
                            );
                        }
                    });

                } catch (error) {
                    console.log(error)
                }
            }
        }, 100);
    }, [selectedEmails, id])

    const onDelete = useCallback(async () => {
        try {
            const submitData = {
                shipping_id: id,
                email: selectedEmails[0].email,
                type: selectedEmails[0].type,
            }
            const { data } = await opsService.deleteEmail(submitData);
            if (data.success) {
                const resultIndex = emailList.findIndex(i => i.email === selectedEmails[0]["email"]);
                deleteOneEmail(resultIndex)
                toast.success(
                    `Suppression effectuée avec succès`,
                    {
                        position: "bottom-center",
                        autoClose: 4000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            }
        } catch (error) {

        }
    }, [selectedEmails, id])

    // console.log({ emailList })

    return (
        <div>

            <div className="p-4 bg-gray-50">
                <div className="flex items-center">
                    <div className="mr-5 w-2/3 max-w-[800px]">
                        <label
                            htmlFor="options"
                            className="block mb-2 text-sm font-medium text-gray-900 "
                        >
                            Email(s)*
                        </label>
                        <input
                            className="w-full  border bg-white  border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-300 focus:border-gray-300 block  pl-10 p-2.5"
                            type="type"
                            placeholder="email1@gmail, email2@gmail.com...."
                            value={Email.email}
                            onChange={(e) => UpdateEmail("email", e.target.value)}
                        />
                    </div>
                    <div className="w-full max-w-[200px]">
                        <CustomSelector
                            label={"Type*"}
                            value={Email.type}
                            onChange={v => {

                                UpdateEmail("type", v.value)
                            }}
                            options={typesOptions}
                        />
                    </div>
                </div>
                <button
                    className={` ${valid ? "cursor-pointer" : "cursor-not-allowed"} text-white bg-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm px-5 py-2.5 text-center mt-4`}
                    onClick={handleSubmit}
                    disabled={isSubmitting}                            >
                    {isSubmitting ? "Enregistrement..." : selectedItem ? "Modifier" : "Enregistrer"}
                </button>
            </div>

            <div className="p-4 mt-2 bg-gray-50">
                <input
                    className="max-w-lg  border bg-white  border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full pl-10 p-2.5"
                    type="type"
                    placeholder="Recherche"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}

                />
            </div>

            <TableComponent withAction={true} headerList={headers} dataLength={results?.length} isLoading={isLoading}>
                {results?.map((e, i) => (
                    <tr key={i}>
                        <td>{i + 0}</td>
                        <td>{e.email}</td>
                        <td>{e.phone}</td>
                        <td>{e.label}</td>
                        {/* <td>{formatDefaultDate()}</td> */}
                        <td>
                            <div
                                className="text-red-500 cursor-pointer text-[14px]"
                                onClick={() => {
                                    //onDeleteConfirmOpen()
                                    onDeleteClick(e)
                                }}
                                title="Supprimer"
                            >
                                {/* <i className="fas fa-trash"></i> */}
                                <TrashIcon className={"h-5 w-5 mx-auto"} />
                            </div>
                        </td>
                    </tr>
                ))
                }
            </TableComponent>


        </div>
    );
}

const headers = [
    { label: "#", filterKey: "" },
    { label: "Email", filterKey: "email" },
    { label: "Téléphone", filterKey: "phone" },
    { label: "Type", filterKey: "label" },
]

export const CustomSelector = ({ label, options, value, onChange }) => {
    //const [options, setOptions] = useState(options);
    const _value = options.find((c) => c?.value == value);
    return (
        <div>
            {label && (
                <label
                    htmlFor="options"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                >
                    {label}
                </label>
            )}
            <Select
                options={options}
                value={_value}
                onChange={(v) => {
                    onChange(v);
                }}
                className="w-100"
            />
        </div>
    );
};