import React from "react";

const OverLayComponent = ({ children, display }) => {
    const overlayStyle = {
        display: display===true ? "block" : "none",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.7)", // Modifier l'opacité (0.5) selon vos besoins
        zIndex: 9999,
        cursor:'no-drop'
    };

    // const contentStyle = {
    //     position: "absolute",
    //     top: "50%",
    //     left: "50%",
    //     transform: "translate(-50%, -50%)",
    //     background: "#fff",
    //     padding: "20px",
    //     borderRadius: "4px",
    //     boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    // };

    return (
        <div style={overlayStyle}>
            <div>{children}</div>
        </div>
    );
};

export default OverLayComponent;
