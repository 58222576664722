import React, { useCallback, useRef, useState } from 'react';
import opsService from '../../../services/ops/opsService';
import moment from "moment"
import { getUniqueItemsByKey } from '../../../utils';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { urlSplit } from '../../../utils/Functions/otherFunctions';
import { TableComponent } from '../../TableauComponent';
import { ShippingDetails } from '../../../store/shippingDetails';

export const FichiersTabsComponent=({ formVisible = true }) =>{
    const expedition =  ShippingDetails((state)=>state.details);
    const allDocs = ShippingDetails((state)=>state.details.allDocs) || [];
    const addManyDocs = ShippingDetails((state)=>state.addManyDocs);
    const deleteOneDoc = ShippingDetails((state)=>state.deleteOneDoc);

    const fileRef = useRef();
    const { exnetSpace } = urlSplit();

    const [files, setFiles] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const onFileChange = (e) => {
        const selectedFiles = e.target.files;
        setFiles(Array.from(selectedFiles))
    }

    const handleSubmit = useCallback(async () => {
        if (expedition) {
            try {
                setIsUploading(true);
                const formData = new FormData();
                formData.append("shipping_id", expedition.id);
                files.forEach(file => {
                    formData.append("files[]", file);
                })

                const { data } = await opsService.addFiles(formData);

                if (data.success) {

                    if (data.data) {
                        const lastAdded = [...data.data].slice(-files.length);

                        const filesListe = [...lastAdded?.map(d => ({ ...d, name: d.nom }))];

                        addManyDocs(filesListe);

                        const end = lastAdded.length > 1 ? "s" : ""
                        toast.success(`Fichier${end} ajouté${end} avec succès`, {
                            position: "bottom-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                    setFiles([])
                }

            } catch (e) {
                console.error(e)
            } finally {
                setIsUploading(false)
            }
        }
    }, [expedition, files])

    const valid = files.length > 0;

    const onDelete = useCallback(async (file, index) => {
        console.log({ file, index })
        try {
            Swal.fire({
                title: "Êtes-vous sûr de vouloir supprimer ce fichier ?",
                text: "Cette action est irréversible !",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: exnetSpace === 'paris' ? "#ba9964" : (exnetSpace === 'service' ? "#098721" : (exnetSpace === 'medical' ? "#3A8EBA" : "#222")),
                confirmButtonText: "Oui, supprimer",
                cancelButtonText: "Annuler",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    //delete result
                    const type = file.document.includes("accompagnement") ? "accompagnement" : /* file.document.includes("bordereau") ? "bordereau" : */ "supplementaire";
                    const file_name = file.document.split("/").pop()
                    const payload = {
                        shipping_id: expedition.id,
                        file_name,
                        type
                    }
                    const { data } = await opsService.deleteFile(payload);
                    if (data.success) {
                        // setFilesLists(_files => _files.filter((_, i) => i !== index))
                        deleteOneDoc(index)
                    }
                } else {
                    /* Swal.fire(
                        "Annuler",
                        "Suppression annulée avec succès",
                        "error",
                        500
                    ); */
                }
            })
        } catch (error) {
            console.error(error)
        }
    }, [expedition])


    const lists = getUniqueItemsByKey(allDocs,"document")  //getUniqueItemsByKey(filesLists, "document") 
    return (
        <div className="row">
            {formVisible && <div className="row">
                <div className="mb-2">
                    <label className="block mb-2 text-sm font-semibold text-gray-900 " htmlFor="default_size">Ajout de fichiers</label>
                    <div className="flex items-center">
                        <input
                            className="w-full mb-5 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none "
                            id="default_size"
                            type="file"
                            hidden
                            ref={fileRef}
                            onChange={onFileChange}
                            multiple
                        />
                        <div className="flex items-center px-3 py-2.5 rounded-md border cursor-pointer border-gray-200 bg-white w-full max-w-xl"
                            onClick={() => {
                                fileRef.current?.click()
                            }}>
                            <i className="text-lg fa fa-solid fa-file"></i>
                            <div className="ml-4 text-sm text-gray-700">
                                {valid ? `${files?.map(f => f.name).join(",")}` : " Ajouter un document..."}
                            </div>
                        </div>
                        <button
                            className={` ${valid ? "cursor-pointer" : "cursor-not-allowed"} ml-4 text-white bg-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm px-5 py-2.5 text-center`}
                            onClick={handleSubmit}
                            disabled={!valid || isUploading}                            >
                            {isUploading ? "Validation..." : "Valider"}
                        </button>
                    </div>

                </div>

            </div>}

            <div>
                <TableComponent withAction={true} headerList={headers} dataLength={lists?.length} isLoading={isLoading}>

                    {lists?.map((file, i) => (
                        <tr key={i} className="text-lg divide-y divide-gray-300 ">
                            <td>{i + 1}</td>
                            <td>{file.name} </td>
                            <td>{moment(file.created_at).format("DD-MM-YYYY")}</td>
                            <td className="uppercase">{file.type}</td>
                            <td>
                                <div className="flex items-center justify-center ">
                                    <div className="mr-4 cursor-pointer">
                                        <a target='_blank' href={`${process.env.REACT_APP_BACKEND_HOST}/${file.document}`} download className="text-gray-700">
                                            <span title="Télécharger">
                                                <i className="fas fa-solid fa-download"></i>
                                            </span>
                                        </a>
                                    </div>
                                    {file.name?.toLowerCase().includes("bordereau") ? null : <div
                                        className={`text-red-500 cursor-pointer text-[14px]`}
                                        onClick={() => {
                                            //onDeleteConfirmOpen()
                                            onDelete(file, i)
                                        }}
                                        title="Supprimer"
                                    >
                                        <i className="fas fa-trash"></i>
                                    </div>}
                                </div>

                            </td>
                        </tr>
                    ))
                    }
                </TableComponent>
            </div>

            


        </div>
    );
}

const headers = [
    {
        label: "#",
        filterKey: "",
    },
    {
        label: "NOM DU DOCUMENT",
        filterKey: "name",
    },
    {
        label: "AJOUTÉ LE",
        filterKey: "created_at",
    },
    {
        label: "TYPE",
        filterKey: "type",
    },
]