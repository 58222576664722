import React, { useCallback } from 'react'
import { useSelector, useDispatch } from "react-redux"
import { SetUser } from "../features/slices/UserSlice";

export default function useAuth() {
    const user = useSelector(state => state.persistedReducer)
    const dispatch = useDispatch()

    const setUser = useCallback(payload => {
        dispatch(SetUser(payload))
    }, [dispatch])

    return {
        isAuthenticated: !!user,
        user,
        setUser
    }
}
