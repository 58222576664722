import React, { useEffect, useState } from "react";
import Head from "../HeadComponent/Head";
import ClientExpeditionDetailComponent from "./client/clientExpeditionDetailComponent";
import ExpeditionService from "../../services/expedition/ExpeditionService.js";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  refPrefixer,
  replaceLastValueInPath,
  urlSplit,
} from "../../utils/Functions/otherFunctions";
import { toast } from "react-toastify";
import LoaderTableau from "../tableauComponent/LoaderTableau";
import { formatShippingRef } from "../../utils/index.js";
import useAuthorization from "../../hooks/useAuthorization.jsx";
import clsx from "clsx";
import { useAppContext } from "../../context/index.jsx";
import { ShippingDetails } from "../../store/shippingDetails.js";
import { OpsShippingDetails } from "./opsShippingDetails/index.jsx";
import { TableDataStore } from "../../states/tableData.js";

export const ShippingDetail = ({ expeditionId, expeditionSpace }) => {


  const expeditionDetails= ShippingDetails((state) => state.details);
  
  const { identifiant } = useParams();

  const [role, setRole] = useState("");
  const [expeditionData, setExpeditionData] = useState({});
  const { pathname } = useLocation();
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const { exnetSpace } = urlSplit();
  const [isLoading, setIsLoading] = useState(false);

  const setDetails = ShippingDetails((state) => state.setDetails);
  const dataList = TableDataStore((state) => state.dataList)

  const findPreviousAndNextElement = (id) => {
    const index = dataList.findIndex((element) => element.ref === id);

    if (index === -1) {
      return null; // ID non trouvé dans le tableau
    }

    const previousIndex = index > 0 ? index - 1 : null;
    const nextIndex = index < dataList.length - 1 ? index + 1 : null;

    const previousElement =
      previousIndex !== null ? dataList[previousIndex].ref : null;
    const nextElement =
      nextIndex !== null ? dataList[nextIndex].ref : null;

    return { previousElement, nextElement };
  };

  const handleNext = () => {
    let result = findPreviousAndNextElement(parseInt(expeditionId));
    if (result?.nextElement) {
      navigate(replaceLastValueInPath(pathname, result?.nextElement));
    } else {
      navigate(replaceLastValueInPath(pathname, expeditionId));
    }
  };

  const handlePrevious = () => {
    let result = findPreviousAndNextElement(parseInt(expeditionId));
    if (result?.previousElement) {
      navigate(replaceLastValueInPath(pathname, result?.previousElement));
    } else {
      navigate(replaceLastValueInPath(pathname, expeditionId));
    }
  };

  const [suffixeIndex, setSuffixeIndex] = useState("");
  const [title, setTitle] = useState(
    `Détail ${refPrefixer(exnetSpace)}${expeditionId}`
  );
  const expeditionRef = expeditionDetails?.billing_center
    ? formatShippingRef(
        expeditionDetails.ref,
        expeditionDetails.billing_center.type
      )
    : "-";

  const  changeTitle=(titleChange)=> {
    setTitle(`${titleChange} ${refPrefixer(exnetSpace)}`);
  }

  const showFactureError = (e) => {
    e.preventDefault();
    toast.error("Votre expédition est toujours en cours d'expédition", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  useEffect(() => {
    setIsLoading(true);
    let element = document.getElementById("bloquer");

    if (element) {
      if (expeditionDetails.pod?.length > 0 && !expeditionDetails.annulee) {
        element?.removeAttribute("disabled");
      } else {
        element?.setAttribute("disabled", true);
      }
    }

    let determinant =
      expeditionSpace === "paris"
        ? "EX"
        : expeditionSpace === "medical"
        ? "EM"
        : "ES";
    setSuffixeIndex(determinant);
    setRole(isAuth?.userRole);
    document.getElementById("fistLink").click();
    changeTitle("Detail");

    setTimeout(() => {
      document.querySelector("#progrss-wizard ul")?.removeAttribute("style");
    }, 500);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (pathname && pathname.indexOf(expeditionId)) {
      if (expeditionId === identifiant) {
        ExpeditionService.getExpeditionDetails({ ref: expeditionId }).then(
          (data) => {
            const { data: expedition } = data.data;
            setDetails(expedition);
            setExpeditionData({ ...data.data.data });
            setIsLoading(false);
          },
          (error) => {
            setIsLoading(false);
            console.log(error);
          }
        );
      }
    }
  }, [expeditionId]);

  const { isShippingTabAllowed } = useAuthorization();
  const [activeTabs, setActiveTabs] = useState("tabsDetail");
  const { isAuth, setIsAuth } = useAppContext();

  const tabsClick = (tabsValue, title) => {
    setActiveTabs(tabsValue);
    changeTitle(title);
  };

  return (
    <>
      <Head>
        <title>EXNET | {title} </title>
      </Head>
      {role === "client" ? (
        <ClientExpeditionDetailComponent />
      ) : (
        <>
          <div className="">
            <div id="progrss-wizard" className="twitter-bs-wizard">
              <div
                className={
                  "bg-white rounded-md gap-4 overflow-auto w-full px-3 py-1"
                }
              >
                <ul className="flex justify-between w-full my-2">
                  <li
                    className={clsx(
                      {
                        "bg-paris": isAuth?.spaceName === "paris",
                        "bg-medical": isAuth?.spaceName === "medical",
                        "bg-service": isAuth?.spaceName === "service",
                      },
                      "px-3 py-2 mx-8 text-white rounded-full"
                    )}
                    onClick={handlePrevious}
                  >
                    <a href={"#"} className="nav-link">
                      <div
                        className="step-icon"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="précédent"
                      >
                        <i className="text-white fas fa-arrow-left" />
                      </div>
                    </a>
                  </li>
                  <li
                    className={clsx(
                      {
                        "bg-paris": isAuth?.spaceName === "paris",
                        "bg-medical": isAuth?.spaceName === "medical",
                        "bg-service": isAuth?.spaceName === "service",
                      },
                      "px-3 py-2 mx-8 text-white rounded-full"
                    )}
                    onClick={() => tabsClick("tabsDetail", "Details")}
                  >
                    <a
                      id={"fistLink"}
                      href="#tabsDetail"
                      className="nav-link active"
                      data-bs-toggle="tab"
                    >
                      <div
                        className="step-icon"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Details Expédition"
                      >
                        <i
                          className={clsx(
                            { "text-white": activeTabs === "tabsDetail" },
                            "far fa-newspaper text-xl"
                          )}
                        />
                      </div>
                    </a>
                  </li>
                  {window.location.pathname.split("/")[4] !== "chauffeur" ? (
                    <li
                      className={clsx(
                        {
                          "bg-paris": isAuth?.spaceName === "paris",
                          "bg-medical": isAuth?.spaceName === "medical",
                          "bg-service": isAuth?.spaceName === "service",
                        },
                        "px-3 py-2 mx-8 text-white rounded-full"
                      )}
                      onClick={() => tabsClick("tabsFichiers", "Fichiers")}
                    >
                      <a
                        href="#tabsFichiers"
                        className="nav-link"
                        data-bs-toggle="tab"
                      >
                        <div
                          className="step-icon"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Fichiers"
                        >
                          <i
                            className={clsx(
                              { "text-white": activeTabs === "tabsFichiers" },
                              "fas fa-file text-xl"
                            )}
                          />
                        </div>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {window.location.pathname.split("/")[4] !== "chauffeur" ? (
                    <li
                      className={clsx(
                        {
                          "bg-paris": isAuth?.spaceName === "paris",
                          "bg-medical": isAuth?.spaceName === "medical",
                          "bg-service": isAuth?.spaceName === "service",
                        },
                        "px-3 py-2 mx-8 text-white rounded-full"
                      )}
                      onClick={() => tabsClick("tabsManifest", "Manifest")}
                    >
                      <a
                        href="#tabsManifest"
                        className="nav-link"
                        data-bs-toggle="tab"
                      >
                        <div
                          className="step-icon"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Manifest"
                        >
                          <i
                            className={clsx(
                              { "text-white": activeTabs === "tabsManifest" },
                              "fas fa-solid fa-vector-square text-xl"
                            )}
                          ></i>
                        </div>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {window.location.pathname.split("/")[4] !== "chauffeur" ? (
                    <li
                      className={clsx(
                        {
                          "bg-paris": isAuth?.spaceName === "paris",
                          "bg-medical": isAuth?.spaceName === "medical",
                          "bg-service": isAuth?.spaceName === "service",
                        },
                        "px-3 py-2 mx-8 text-white rounded-full"
                      )}
                      onClick={() => tabsClick("tabsEmail", "Mails")}
                    >
                      <a
                        href="#tabsEmail"
                        className="nav-link"
                        data-bs-toggle="tab"
                      >
                        <div
                          className="step-icon"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Mails"
                        >
                          <i
                            className={clsx(
                              { "text-white": activeTabs === "tabsEmail" },
                              "fas fa-envelope text-xl"
                            )}
                          />
                        </div>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  <li
                    className={clsx(
                      {
                        "bg-paris": isAuth?.spaceName === "paris",
                        "bg-medical": isAuth?.spaceName === "medical",
                        "bg-service": isAuth?.spaceName === "service",
                      },
                      "px-3 py-2 mx-8 text-white rounded-full"
                    )}
                    onClick={() => tabsClick("tabsTracking", "Trackings")}
                  >
                    <a
                      href="#tabsTracking"
                      className="nav-link"
                      data-bs-toggle="tab"
                    >
                      <div
                        className="step-icon"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Trackings"
                      >
                        <i
                          className={clsx(
                            { "text-white": activeTabs === "tabsTracking" },
                            "far fa-map "
                          )}
                        />
                      </div>
                    </a>
                  </li>
                  <li
                    className={clsx(
                      {
                        "bg-paris": isAuth?.spaceName === "paris",
                        "bg-medical": isAuth?.spaceName === "medical",
                        "bg-service": isAuth?.spaceName === "service",
                      },
                      "px-3 py-2 mx-8 text-white rounded-full"
                    )}
                    onClick={() => tabsClick("tabsPod", "Pod")}
                  >
                    <a
                      href="#tabsPod"
                      className="nav-link"
                      data-bs-toggle="tab"
                    >
                      <div
                        className="step-icon"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Pod"
                      >
                        <i
                          className={clsx(
                            { "text-white": activeTabs === "tabsPod" },
                            "fas fa-map-pin text-xl"
                          )}
                        />
                      </div>
                    </a>
                  </li>
                  {window.location.pathname.split("/")[4] !== "chauffeur" ? (
                    <li
                      className={clsx(
                        {
                          "bg-paris": isAuth?.spaceName === "paris",
                          "bg-medical": isAuth?.spaceName === "medical",
                          "bg-service": isAuth?.spaceName === "service",
                        },
                        "px-3 py-2 mx-8 text-white rounded-full"
                      )}
                      onClick={() =>
                        tabsClick("tabsCommentaire", "Commentaires")
                      }
                    >
                      <a
                        href="#tabsCommentaire"
                        className="nav-link"
                        data-bs-toggle="tab"
                      >
                        <div
                          className="step-icon"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Commentaires"
                        >
                          <i
                            className={clsx(
                              {
                                "text-white": activeTabs === "tabsCommentaire",
                              },
                              "fas fa-comment text-xl"
                            )}
                          />
                        </div>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {window.location.pathname.split("/")[4] !== "chauffeur" ? (
                    <li
                      className={clsx(
                        {
                          "bg-paris": isAuth?.spaceName === "paris",
                          "bg-medical": isAuth?.spaceName === "medical",
                          "bg-service": isAuth?.spaceName === "service",
                        },
                        "px-3 py-2 mx-8 text-white rounded-full"
                      )}
                      onClick={(e) => {
                        if (
                          expeditionDetails?.pod?.length > 0 &&
                          expeditionDetails.annulee === 0
                        ) {
                          tabsClick("tabsFacturation", "Facturations");
                        } else {
                          showFactureError(e);
                        }
                      }}
                    >
                      <a
                        disabled={
                          expeditionDetails?.pod?.length > 0 &&
                          expeditionDetails?.annulee === 0
                            ? false
                            : true
                        }
                        href={`${
                          expeditionDetails?.pod?.length > 0 &&
                          expeditionDetails?.annulee === 0
                            ? "#tabsFacturation"
                            : ""
                        }`}
                        className="nav-link"
                        data-bs-toggle="tab"
                      >
                        <div
                          className="step-icon"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Facturations"
                        >
                          <i
                            className={clsx(
                              {
                                "text-white": activeTabs === "tabsFacturation",
                              },
                              "fas fa-euro-sign text-xl"
                            )}
                          />
                        </div>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  <li
                    className={clsx(
                      {
                        "bg-paris": isAuth?.spaceName === "paris",
                        "bg-medical": isAuth?.spaceName === "medical",
                        "bg-service": isAuth?.spaceName === "service",
                      },
                      "px-3 py-2 mx-8 text-white rounded-full"
                    )}
                    onClick={handleNext}
                  >
                    <a href={"#"} className="nav-link">
                      <div
                        className="step-icon"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Suivant"
                      >
                        <i className="text-xl fas fa-arrow-right" />
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

              <div
                id="bar"
                className="mt-4 progress"
                style={{ height: "30px" }}
              >
                <span
                  style={{
                    fontSize: "20px",
                    textAlign: "center",
                    display: "block",
                    width: "100%",
                  }}
                >
                  
                  {/* Détails {expeditionRef} */}
                  {title}{expeditionDetails?.ref}
                </span>
                <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"></div>
              </div>
              {isLoading ? (
                <>
                  <div>
                    <LoaderTableau />
                  </div>
                </>
              ) : (
                <>
                  {expeditionData.id ? (
                    <OpsShippingDetails activeTabs={activeTabs} />
                  ) : (
                    <LoaderTableau />
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
