
import servicesImport from '../../../assets/img/svg/Services-import.svg'
import servicesExport from '../../../assets/img/svg/Services-export.svg'
import servicescourse from '../../../assets/img/svg/Services.svg'
import dashLogo from '../../../assets/img/Icones/Dashboard/services.svg'
import contactLogo from '../../../assets/img/Icones/carnet/services.svg'
import archiveLogo from '../../../assets/img/Icones/archive/services.svg'
import boiteLogo from '../../../assets/img/Icones/boite/services.svg'
import freightLogo from '../../../assets/img/Icones/freight/services.svg'

export const serviceClientMenu = [
    {
        isTitle: false,
        tague: "dashboard",
        isMultiple: true,
        libelle: "Dashboard",
        // lien: "/client/service/dash",
        icon: <img alt={'fd'} src={dashLogo} className={` w-8 hover:text-black`} />,
        liens: [
            {
                libelle: "Expéditions en cours",
                lien: "/client/service/dash",
                tague: 'dashboard-progress'
            },
            {
                libelle: "expéditions livrées",
                lien: "/client/service/dash/livre",
                tague: 'dashboard-delivered'
            },
        ],
    },
    {
        libelle: "Mes expéditions",
        isTitle: true
    },
    {
        isTitle: false,
        tague: "course",
        isMultiple: true,
        libelle: "Courses",
        icon: <img alt={'fd'} src={servicescourse} className={` w-8 hover:text-black`} />,
        liens: [
            {
                libelle: "Nouvelle course",
                lien: "/client/service/dash/courses-a-courses/new",
                tague: 'course-new'
            },
            {
                libelle: "Course en cours",
                lien: "/client/service/dash/courses-a-courses/en-cours",
                tague: 'course-progress'
            },
            {
                libelle: "Course livrées",
                lien: "/client/service/dash/courses-a-courses/livre",
                tague: 'course-delivered'
            },

        ],
    },


    {
        isTitle: false,
        tague: "export",
        isMultiple: true,
        libelle: "Exports",
        icon: <img alt={'fd'} src={servicesExport} className={` w-8 hover:text-black`} />,
        liens: [
            {
                libelle: "Nouvel export",
                lien: "/client/service/dash/export/new",
                tague: "export-new"
            },
            {
                libelle: "Exports en cours",
                lien: "/client/service/dash/export/en-cours",
                tague: "export-progress"
            },
            {
                libelle: "Exports livrées",
                lien: "/client/service/dash/export/livre",
                tague: "export-delivered"
            }
        ],
    },
    {
        isTitle: false,
        tague: "import",
        isMultiple: true,
        libelle: "Imports",
        icon: <img alt={'fd'} src={servicesImport} className={` w-8 hover:text-black`} />,
        liens: [
            {
                libelle: "Nouvel import",
                lien: "/client/service/dash/import/new",
                tague: "import-new",
            },
            {
                libelle: "Imports en cours",
                lien: "/client/service/dash/import/en-cours",
                tague: "import-progress",
            },
            {
                libelle: "Imports livrées",
                lien: "/client/service/dash/import/livre",
                tague: "import-delivered",
            },

        ],
    },
    {
        libelle: "Carnet d'adresses",
        lien: "/client/service/dash/carnet",
        icon: <img alt={'fd'} src={contactLogo} className={` w-8 hover:text-black`} />,
        tague: 'contact'
    },
    {
        libelle: "Expéditions archivées",
        lien: "/client/service/dash/export/archive",
        icon: <img alt={'fd'} src={archiveLogo} className={` w-8 hover:text-black`} />,
        tague: 'archivées-archived'
    },
    {
        libelle: "Mes Statistiques",
        isTitle: true
    },
    {
        libelle: "Courses",
        lien: "/client/service/dash/statiques/expeditions",
        icon: <img alt={'fd'} src={boiteLogo} className={` w-8 hover:text-black`} />,
        tague: 'statistic-expedition'
    },
    {
        libelle: "Freight",
        lien: "/client/service/dash/statiques/freight",
        icon: <img alt={'fd'} src={freightLogo} className={` w-8 hover:text-black`} />,
        tague: 'statistic-freight'
    }
];
