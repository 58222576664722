import React, { useEffect, useState } from "react";
import Lists from "./Lists";
import AttributionFormModal from "./Form";
import { toast } from "react-toastify";
import useDisclosure from "../../../hooks/useDisclosure";
import { Link, useLocation, useParams } from "react-router-dom";
import DeletionConfirm from "./DeletionConfirm";
import { getUniqueItemsByKey } from "../../../utils";
import Filter from "./Filter";
import ExpeditionService from "../../../services/expedition/ExpeditionService";
import DispatchFormModal from "./DispatchFormModal";
import { AddTrackingModal } from "../../tracking/Form";
import { useDispatch, useSelector } from "react-redux";
import { addExpedition, clearExpeditions } from "../../../features/slices/expeditionSlice";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useAppContext } from "../../../context";


export default function ExpeditionsView({ category, type_expedition, route }) {

    const expeditionStore = useSelector(state => state.expedition.allExpedition)
    const dispatch = useDispatch();

    const [isSearch, setisSearch] = useState(false);
    const [filterData, setfilterData] = useState({});
    const { pathname } = useLocation();
    const splitPathname = pathname.split("/");
    const [updateTrackingData, setupdateTrackingData] = useState({})

    const { isOpen: isAttributionOpen, onClose: onAttributionClose, onOpen: onAttributionOpen } = useDisclosure();
    const {
        isOpen: isDeleteConfirmOpen,
        onClose: onDeleteConfirmClose,
        onOpen: onDeleteConfirmOpen,
    } = useDisclosure();

    const {
        isOpen: isDispatchModalOpen,
        onClose: onDispatchModalClose,
        onOpen: onDispatchModalOpen,
    } = useDisclosure();

    const {
        isOpen: isAddTrackingModalOpen,
        onClose: onAddTrackingModalClose,
        onOpen: onAddTrackingModalOpen,
    } = useDisclosure();

    const { isAuth, setIsAuth } = useAppContext();

    const [data, setData] = useState({
        data: [],
    });

    const [isLoading, setIsLoading] = useState(true);
    const [attributionProfile, setAttributionProfile] = useState();
    const [filter, setFilter] = useState({
        ref: "",
        suivi: "",
        contact: "",
        billing_center: "",
        delivery_date: "",
        pickup_date: "",
        code_donneur: "",
        code_receveur: "",
    });

    const [page, setPage] = useState(1);
    const [selectedItem, setSelectedItem] = useState();


    const [editMode, setEditMode] = useState(false);

    const fetchData = async () => {
        try {
            const params = {
                page,
                type:isAuth?.spaceName,
                category,
                type_expedition,
                per_page: 30,
            };

            const { data: resData } = await ExpeditionService.getAllOpsExpeditionsEnCoursFrance(
                params
            );

            if (resData.success) {
                let reponse = resData.data.data
                if (reponse.length === 0) dispatch(clearExpeditions())
                dispatch(addExpedition([...reponse]))

                const lastPage = resData.data.last_page;
                if (page < lastPage) {
                    setPage(page + 1);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };





    const onSubmit = (result) => {
        let items = [...data.data];
        const resultIndex = items.findIndex((i) => i.id === result?.id);
        const isUpdate = resultIndex > -1;
        if (isUpdate) {
            items[resultIndex] = result;
        } else {
            items = [result, ...items];
        }

        setData((d) => ({
            ...d,
            data: items,
        }));

        toast.success(
            `${isUpdate ? "Détails mis à jour" : "Données ajoutées"
            } avec succès!`,
            {
                position: "bottom-center",
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        );
    };

    const onItemSelected = (item, isEditable) => {
        if (isEditable) {
            setEditMode(true);
        }
        setSelectedItem(item);
        onAttributionOpen();
    };

    const handleClose = () => {
        setSelectedItem(null);
        onAttributionClose();
        onDispatchModalClose()
        setEditMode(false);
    };

    const handleDeleteModalClose = () => {
        onDeleteConfirmClose();
    };

    const onDelete = (item) => {
        setSelectedItem(item);
        onDeleteConfirmOpen();
    };

    const onDeleted = (item) => {
        const items = [...data.data];
        const index = items.findIndex((i) => i.id === item?.id);
        if (index > -1) {
            items.splice(index, 1);
            setData((d) => ({
                ...d,
                data: items,
            }));
        }
        onDeleteConfirmClose();
        setSelectedItem(undefined);
    };

    const results = data.data.filter((d) => {
        const {
            ref,
            suivi,
            contact,
            billing_center,
            delivery_date,
            pickup_date,
            code_donneur,
            code_receveur,
        } = filter;
        // alert(d.ref)

        const refMatch = d?.ref
            ?.toString()
            .toLowerCase()
            .includes(ref.toString().toLowerCase());

        const codeDonneurMatch =
            code_donneur.length > 0
                ? d?.code_donneur
                    ?.toLowerCase()
                    .includes(code_donneur?.toLowerCase() || null)
                : true;

        const codeReceveursMatch =
            code_receveur.length > 0
                ? d?.code_receveur
                    ?.toLowerCase()
                    .includes(code_receveur?.toLowerCase() || null)
                : true;

        const suiviMatch = d?.suivi
            ?.toLowerCase()
            .includes(suivi.toLowerCase());

        const contactMatch = d?.contact
            ?.toLowerCase()
            .includes(contact.toLowerCase());

        const billing_centerMatch = d?.billing_center
            ?.toLowerCase()
            .includes(billing_center?.toLowerCase());

        const deliverDateMatch = d?.delivery_date
            ?.toLowerCase()
            .includes(delivery_date.toLowerCase());

        const pickupDateMatch = d?.pickup_date
            ?.toLowerCase()
            .includes(pickup_date.toLowerCase());



        return codeDonneurMatch && codeReceveursMatch && refMatch;
    });

    const fetchData2 = async () => {
        try {
            // setIsLoading(true);
            const params = {
                page,
                type:isAuth?.spaceName,
                category,
                type_expedition,
                per_page: 30,
                filter: filterData,
            };

            const { data: resData } = await ExpeditionService.getAllExpedition(
                route,
                params
            );
            if (resData.success) {
                let reponse = resData.data.data
                if (reponse.length === 0) dispatch(clearExpeditions())
                dispatch(addExpedition([...reponse]))

                const lastPage = resData.data.last_page;
                if (page < lastPage) {
                    setPage(page + 1);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            // setIsLoading(false);
        }
    };

    useEffect(() => {
        setPage(1);
        setData({
            data: [],
        });
    }, [isSearch]);

    const onAttributionSelected = (item, profile) => {
        setSelectedItem(item);
        setAttributionProfile(profile)
        onAttributionOpen();
    }

    const onDispatchModalSelected = (item) => {
        setSelectedItem(item);
        onDispatchModalOpen();
    }

    const onAddTrackingSelected = (item, setItem) => {
        // setSelectedItem(item);
        let value = {
            value: item,
            fonc: setItem
        }
        setupdateTrackingData({ ...value })
        onAddTrackingModalOpen()
    }

    useEffect(() => {
        if (page === 1) {
            let value = {
                data: [],
            }
            setData({ ...value });
            setIsLoading(true);
        }

        if (isSearch) {
            fetchData2();
        } else {
            fetchData();
        }
    }, [page, isSearch]);


    useEffect(() => {
        dispatch(clearExpeditions())
        setPage(1);
        let value = {
            data: [],
        }
        setData({ ...value });
        fetchData();
    }, [pathname]);


    useEffect(() => {
        let value = { ...data };
        value.data = [...expeditionStore]
        setData({ ...value })
    }, [expeditionStore])

    return (
        <div>
            <div className="flex items-center justify-between">
                <h4 className="uppercase page-title">Dashboard Ops</h4>

                <Link
                    to={`/${splitPathname[1]}/${splitPathname[2]}/dash/${splitPathname[4]
                        ? splitPathname[4]
                        : "en-cours".toLowerCase().replace(/ /g, "-")
                        }/new`}
                    className="float-right btn btn-rounded"
                >
                  <PlusIcon  className={"h-4 w-4 m-3 text-xl font-bold "}/>
                </Link>
            </div>
            <Filter
                setisSearch={setisSearch}
                setfilterData={setfilterData}
                filterData={filterData}
                type={isAuth?.spaceName}
            />
            <Lists
                items={results}
                onSelect={onItemSelected}
                onAttributionSelect={onAttributionSelected}
                onDelete={onDelete}
                onDispatchModalSelect={onDispatchModalSelected}
                onAddTrackingClick={onAddTrackingSelected}
                type={isAuth?.spaceName}
                pathname={splitPathname}
                isLoading={isLoading}
            />
            <AttributionFormModal
                onSubmit={onSubmit}
                selectedData={selectedItem}
                isOpen={isAttributionOpen}
                onClose={handleClose}
                editable={editMode}
                type={isAuth?.spaceName}
                selectedProfile={attributionProfile}
            />

            <DispatchFormModal
                selectedData={selectedItem}
                isOpen={isDispatchModalOpen}
                onClose={handleClose}
                type={isAuth?.spaceName}
            />

            <DeletionConfirm
                onSubmit={onDeleted}
                selectedData={selectedItem}
                isOpen={isDeleteConfirmOpen}
                onClose={handleDeleteModalClose}
                title={"Etes-vous sûr de vouloir supprimer ce manifest?"}
                type={isAuth?.spaceName}
            />
            <AddTrackingModal
                updatedSelectedData={updateTrackingData?.func}
                expedition={updateTrackingData?.value}
                isOpen={isAddTrackingModalOpen}
                onClose={onAddTrackingModalClose}
                onSubmit={onSubmit}
                type={isAuth?.spaceName}
            />
        </div>
    );
}
