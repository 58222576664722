import React, { useCallback, useEffect, useState } from 'react';
import employeesService from '../../../services/employees';
import Select from 'react-select';
import adminService from '../../../services/admin/adminService';


const temperatureList = [
    { value: '-80', label: '-80°C (glace carbonique)' },
    { value: '-20', label: '-20°C' },
    { value: '2/8', label: '+2°C / +8°C' },
    { value: "15/25", label: '+15°C / +25°C' },
    { value: 'AMBi', label: 'Ambiant non contrôlé' },
    { value: "-196", label: '-196°C' },
]

export const TemperatureSelector=({ selectedValue, onChange, showLabel = true, ...props })=> {

    // const [temperatureList, setTemperatureList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [selectedSalary, setselectedSalary] = useState({})


    // useEffect(() => {
      
    //     const fetchData = async () => {
    //         try {
    //             const { data } = await adminService.allEmployees({
    //                 per_page: 1000000,
    //                 page: pageNumber
    //             });

    //             if (data) {
    //                 const isPaginated = data.data?.last_page;
    //                 const _items = data.data.data
    //                 setIsLoading(false)
    //                 if (Array.isArray(_items)) {
    //                     _items.forEach(element => {
    //                         let newData = {
    //                             label: element.nom + ' ' + element.prenom,
    //                             identifiant: element.id,
    //                             value: element,
    //                         }

    //                         settemperatureList((d) => {
    //                             //console.log(d)
    //                             // Vérifier si newData existe déjà dans le tableau
    //                             const newDataExists = d.some(item => item.name === newData.label);
    //                             // Si newData n'existe pas, ajouter newData au tableau
    //                             if (!newDataExists) {
    //                                 return [
    //                                     ...d,
    //                                     newData,
    //                                 ];
    //                             }

    //                             // Si newData existe déjà, retourner le tableau inchangé
    //                             return d;
    //                         });
    //                     });
    //                 }
    //                 // last_page
    //                 if (isPaginated && pageNumber < parseInt(isPaginated)) {
    //                     setPageNumber(pageNumber + 1)
    //                 }
    //             }
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     };

    //     fetchData();
    // }, [pageNumber]);

    const value = temperatureList.find((c) => c?.value === selectedValue);

    return (
        <div>
            {showLabel &&
                <label
                    htmlFor="name"
                    className="block mb-1 text-lg font-normal leading-6 "
                >
                    Sélectionner la température de votre expédition

                </label>
            }

            <Select
                LoadingMessage={() => "Chargement..."}
                options={temperatureList}
                value={value}
                onChange={(v) => {
                    setselectedSalary(v)
                    onChange(v.value)
                }}
                isLoading={isLoading}
                className='text-base'
                {...props}
            />

        </div>
    );
}
