import React, { useCallback, useEffect, useState } from 'react';
import { useBasicFormValues } from '../../../hooks/useFormValues';
import opsService from '../../../services/ops/opsService';
import { toast } from 'react-toastify';
import useDisclosure from '../../../hooks/useDisclosure';
import { formatDefaultDate } from '../../../utils/moment';
import { useDispatch, useSelector } from 'react-redux';
import ExpeditionService from '../../../services/expedition/ExpeditionService';
import { setExpedition } from '../../../features/slices/expeditionSlice';
import LoaderTableau from '../../tableauComponent/LoaderTableau';
import { TableComponent } from '../../TableauComponent';

function CommentaireTabsComponent({ expedition }) {
    const expeditionDetails = useSelector(state => state.expedition.expeditions);
    const dispatch = useDispatch();
    const initialValue = {
        content: "",
        shipping_id: ""
    }
    const { data: commentData, setData, setFormValue } = useBasicFormValues(initialValue);
    const [selectedItem, setSelectedItem] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [comments, setComments] = useState([]);

    const { isOpen: isDeleteConfirmOpen, onClose: onDeleteConfirmClose, onOpen: onDeleteConfirmOpen } = useDisclosure()

    useEffect(() => {
        if (expedition) {
            setComments(expedition.comments)
        }
    }, [expedition])


    const handleSubmit = useCallback(async () => {
        try {
            const submitData = {
                content: commentData.content,
                shipping_id: expedition.id,
            }
            if (selectedItem) {
                submitData.id = selectedItem.id
            }
            setIsSubmitting(true);


            const request = selectedItem ? opsService.updateComment : opsService.addComment
            const { data } = await request(submitData);
            if (data.success) {

                onSubmit(selectedItem ?
                    {
                        ...selectedItem, content: submitData.content,
                    } : data.data)
                ExpeditionService.getExpeditionDetails({ ref: expeditionDetails.ref }).then(
                    (data) => {
                        const { data: expedition } = data.data;
                        dispatch(setExpedition(expedition));
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsSubmitting(false)
        }
    }, [commentData]);

    const onSelect = (item) => {
        setSelectedItem(item)
        if (item) {
            setFormValue("content", item.content)
        } else {
            setFormValue("content", "")
        }
    }

    const onSubmit = (result) => {

        if (result) {
            let items = [...comments];
            const resultIndex = items.findIndex(i => i.id === result.id);

            const isUpdate = resultIndex > -1
            if (isUpdate) {
                items[resultIndex] = result;
            } else {
                items = [result, ...items]
            }

            setComments(items)
            setSelectedItem(null)
            setFormValue("content", "")

            toast.success(`${isUpdate ? "Commentaire mis à jour" : "Commentaire ajouté"} avec succès!`, {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }

    const onDeleteClick = (c) => {
        setSelectedItem(c)
        onDeleteConfirmOpen()
        //onDelete(c)
    }

    const onDelete = useCallback(async () => {
        try {
            if (selectedItem) {
                const { data } = await opsService.deleteComment(selectedItem.id)
                if (data.success) {
                    setComments(c => (
                        [...c].filter(_c => _c.id !== selectedItem.id)
                    ))

                    toast.success(`Commentaire supprimé avec succès`, {
                        position: "bottom-center",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        } catch (error) {
            console.error(error)
        }
    }, [selectedItem])

    return (
        <>

            <div className="row">
                <div className="col-md-3">
                    <div className="">
                        <div className="py-2 text-center card-title">
                            <span className="">
                                Ajout de commentaires
                            </span>
                        </div>
                        <div className="p-2">
                            <div className="card-text ">
                                <div className="mb-3">
                                    <label htmlFor="content" className="form-label"></label>
                                    <textarea
                                        className={"px-2 focus:border-gray-300 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-gray-300  focus:ring-2 text-base sm:leading-6 h-auto border-gray-300 focus:ring-gray-300 "}
                                        placeholder='Votre commentaire'
                                        name="content"
                                        id="content"
                                        rows={5}
                                        resize={"none"}
                                        value={commentData.content}
                                        onChange={(e) => {
                                            setFormValue("content", e.target.value)
                                        }}
                                    ></textarea>
                                </div>
                            </div>
                            <div className={"md:w-1/2 mx-auto"}>
                                <button
                                    className="text-white bg-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full py-2.5 text-center"
                                    onClick={handleSubmit}
                                    disabled={isSubmitting}                            >
                                    {isSubmitting ? "Enregistrement..." : selectedItem ? "Modifier" : "Enregistrer"}
                                </button>
                            </div>

                        </div>
                    </div>

                </div>

                <TableComponent withAction={true} headerList={tableHead} dataLength={comments.length} isLoading={false}>
                    {comments?.map((c, i) => (
                        <tr className={`text-center ${selectedItem?.id === c.id ? "bg-slate-200" : ""}`} key={i}>
                            <td scope="row">{c.id}</td>
                            <td>{c.content}</td>
                            <td>{formatDefaultDate(c.created_at)}</td>
                            <td>
                                <div className="flex items-center justify-center">
                                    <div
                                        className="text-green-600 cursor-pointer text-[14px] mr-5"
                                        onClick={() => {
                                            onSelect(selectedItem?.id === c.id ? null : c)
                                        }} title="Editer"
                                    >
                                        <i className="fas fa-edit"></i>
                                    </div>

                                    {/* <div
                                                                className="text-red-500 cursor-pointer text-[14px]"
                                                                onClick={() => {
                                                                    onDeleteClick(c)
                                                                }}
                                                                title="Supprimer"
                                                            >
                                                                <i className="fas fa-trash"></i>
                                                            </div> */}
                                </div>
                            </td>
                        </tr>
                    ))}
                </TableComponent>



                <DeletionConfirm
                    isOpen={isDeleteConfirmOpen}
                    onClose={() => {
                        onDeleteConfirmClose()
                        setSelectedItem(null)
                    }}
                    onSubmit={onDelete}
                    title="Suppression de commentaire"
                />
            </div>
        </>

    );
}

export default CommentaireTabsComponent;

const tableHead = [
    { "label": "#", "filterKey": "" },
    { "label": "Commentaire", "filterKey": "commentaire" },
    { "label": "Date de commentaire", "filterKey": "date_de_commentaire" }
]


const DeletionConfirm = ({ isOpen, title, onClose, onSubmit }) => {
    const [isDeleting, setIsDeleting] = useState(false);

    const handleOutsideClick = (event) => {

        if (event.target.id === "confirm-popup-modal") {
            onClose();
        }
    };

    const handleDelete = async () => {
        try {
            setIsDeleting(true)
            await onSubmit?.();
            onClose()
        } catch (error) {
            console.error('Error deleting item:', error);
        } finally {
            setIsDeleting(false)

        }
    }

    return isOpen && (
        <div id="confirm-popup-modal" onClick={handleOutsideClick} className="fixed inset-0 overflow-y-auto" style={{ zIndex: 9999 }}>
            <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full`}
                    role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div className="p-4 pt-5 bg-white sm:p-6 sm:pb-4">
                        <button onClick={onClose} className="absolute top-3 right-4 focus:outline-none">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6 text-gray-600">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </button>

                        <h3 className="text-lg font-medium leading-6 text-center text-gray-900" id="modal-headline">
                            {title}
                        </h3>
                        <div className="p-6 text-center">
                            <svg className="w-12 h-12 mx-auto mb-4 text-gray-400 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            <h3 className="mb-5 text-lg font-normal text-gray-500 ">Etes-vous sûr de vouloir supprimer cette donnée?</h3>
                            <button onClick={handleDelete} type="button"
                                disabled={isDeleting}
                                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                                {isDeleting ? "Suppression..." : "Oui, j'en suis sûr"}
                            </button>
                            <button onClick={onClose} type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10     ">
                                Non, annuler
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export const CommentaireList = ({ comments }) => {

    return (

        <TableComponent withAction={true} headerList={tableHead} dataLength={comments?.length} isLoading={false}>

            {comments?.map((c, i) => (
                <tr className={`text-center`} key={i}>
                    <td scope="row">{c.id}</td>
                    <td>{c.content}</td>
                    <td>{formatDefaultDate(c.created_at)}</td>

                </tr>
            ))}
        </TableComponent>


    )
}

const tableHeads = [
    { "label": "#", "filterKey": "" },
    { "label": "Commentaire", "filterKey": "commentaire" },
    { "label": "Date de commentaire", "filterKey": "date_de_commentaire" }
]
