import React, { useState, useEffect } from 'react';
import { exnetSpaceColor, urlSplit } from '../../utils/Functions/otherFunctions';
import { getCountryNameOrCode } from '../../utils/const/countriesConstant';

function DetailExpeditorReceiverComponent({ UserType, data: userData, shippingId }) {
    const [data, setData] = useState({})
    const { role, exnetSpace } = urlSplit();
    useEffect(() => {
        if (userData) {
            setData({ ...userData })
        }
    }, [userData])
    return (

        <div className="h-full p-4 bg-white divide-y-2 divide-gray-500 rounded-md ">
            <div
                className="justify-between pb-2 text-lg font-bold"

            >
                <span>{UserType}</span>

            </div>

            <div className='mt-3'>


                <div className='text-base uppercase'>
                    <span
                        style={{ marginLeft: "0px", color: `${exnetSpaceColor(exnetSpace)}` }}
                        className="far fa-hospital fs-6 me-2 icone-detail"
                    ></span>
                    <span className="titre-detail">Nom contact: </span>
                    <span className="data-detail">
                        {" "}
                        {data ? data.fullname : ""}
                    </span>
                </div>
                <div>
                    {" "}
                    <span
                        style={{ marginLeft: "0px", color: `${exnetSpaceColor(exnetSpace)}` }}
                        className="fas fa-map-marker-alt fs-6 me-2 icone-detail"
                    ></span>
                    <span className="titre-detail">Adresse postale : </span>
                    <span className="data-detail">
                        {" "}
                        {data ? data.address1 : ""}
                    </span>
                </div>
                <div>
                    <span
                        style={{ marginLeft: "0px", color: `${exnetSpaceColor(exnetSpace)}` }}
                        className="far fa-address-card fs-6 me-2 icone-detail"
                    ></span>
                    <span className="titre-detail">Code postal : </span>
                    <span className="data-detail"> {data ? data.postalCode : ""}</span>
                </div>

                <div className="my-1" >
                    <span
                        style={{ marginLeft: "0px", color: `${exnetSpaceColor(exnetSpace)}` }}
                        className="fa fa-book fa-fw fs-6 me-2 icone-detail"
                    ></span>
                    <span className="titre-detail">Ville : </span>
                    <span className="data-detail"> {data ? data.city : ""}</span>
                </div>
                <div>
                    <span
                        style={{ marginLeft: "0px", color: `${exnetSpaceColor(exnetSpace)}` }}
                        className="fa fa-cog fs-6 me-2 icone-detail"
                    ></span>
                    <span className="titre-detail">Pays : </span>
                    <span className="data-detail">{getCountryNameOrCode(data.country)}</span>
                </div>
            </div>
        </div>
    );
}

export default DetailExpeditorReceiverComponent;