import AuthPage from "../Pages/auth.page";
import ExpeditionDetailsPage from "../Pages/dashboard/ExpeditionDetails.page";
import ExploitationCourses from "../Pages/exploitation/courses";
import ExploitationDrivers from "../Pages/exploitation/drivers";
import ShippingDetails from "../Pages/exploitation/shippingDetails";
import Dash from "../dash";

const exploitationRouter = [

    {
        path: "drivers",
        element: <ExploitationDrivers />,
    },

    {
        path: "courses",
        element: <ExploitationCourses />,
    },
    {
        path: "courses/details/:identifiant",
        element: <ShippingDetails />,
    }

]

export default exploitationRouter