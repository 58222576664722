import React, { useEffect, useState } from "react";
import Select from "react-select";
import CountryService from "../../../services/country";
import useCountries from "../../../states/countries";
import clsx from "clsx";

export default function CountriesSelector({
    selectedValue,
    onSelect,
    showLabel = true,
    isDisabled,
    setPhoneCode = false,
    isError,
    errorMessage
}) {
    const { countries: ListCountries, setCountries: setListCountries } = useCountries();

    const [countries, setCountries] = useState([
        { value: "US", label: "United States" },
        { value: "CA", label: "Canada" },
        { value: "fr", label: "France" },
        { value: "DE", label: "Germany" },
    ]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchCountries = async () => {
            setIsLoading(true);
            const { data } = await CountryService.get();
            if (data.success) {
                setListCountries([...data.data])
                setCountries(
                    data.data.map((c) => ({ label: c.name, value: c.code }))
                );
                setIsLoading(false);
            }
        };
        fetchCountries();
    }, []);


    const value = countries.find((c) => ((c?.label?.toLowerCase() === selectedValue?.toLowerCase()) || c?.value?.toLowerCase() === selectedValue?.toLowerCase()));

    return (
        <div>
            {showLabel && (
                <label
                    htmlFor="countries"
                    className="block mb-2 text-sm font-medium text-gray-900 uppercase"
                >
                    Pays*
                </label>
            )}
            <Select
                isLoading={isLoading}
                LoadingMessage={() => "Chargement..."}
                placeholder={'choix du pays'.toUpperCase()}
                className={clsx({ "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500": isError, "ring-gray-300 focus:ring-gray-500 bg-white border border-gray-500": !isError }, 'text-base ')}
                options={countries}
                value={value ?? 'fr'}
                onChange={(v) => {
                    if (v.label) {
                        onSelect(v);
                        if (setPhoneCode) {
                            setPhoneCode()
                        }
                    }

                }}
                isDisabled={isDisabled}
            />
            {isError &&
                <p className="mt-2 text-sm text-red-600" id="email-error">
                    {errorMessage}
                </p>
            }

            {/* <select id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5      ">
                <option selected>Choose a country</option>
                {countries.map((c, i) => (
                    <option key={i} value={c.value}>{c.label}</option>
                ))}
            </select> */}
        </div>
    );
}
