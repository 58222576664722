import React, { useEffect, useMemo, useState } from "react";
import LoaderTableau from "../../tableauComponent/LoaderTableau";
import { trierTableau } from "../../../utils/Functions/tableFilter";
import TableRowComponent, {
  ClientTableRow,
} from "../../rowComponent/TableRowComponent";
import { useAppContext } from "../../../context";
import { TableComponent } from "../../TableauComponent";
import { TableDataStore } from "../../../states/tableData";

export default function ExpeditionLists({
  type_expedition,
  items,
  setItems,
  type,
  isLoading,
}) {
  const { isAuth, setIsAuth } = useAppContext();

  return (
    <>

      <TableComponent withAction={true} headerList={isAuth?.spaceName === "medical" ? medicalHeaderList : otherHeaderList} dataLength={items.length} isLoading={isLoading}>
        {items.map((item, index) => {
          return <ClientTableRow key={item?.ref} item={item} />;
        })}
      </TableComponent>

    </>

  );
}



const otherHeaderList = [
  {
    label: "REF",
    filterKey: "ref",
  },
  {
    label: "EXP",
    filterKey: "expeditor.fullname",
  },
  {
    label: "adresse",
    filterKey: "expeditor.address1",
  },
  {
    label: "DEST",
    filterKey: "receiver.fullname",
  },
  {
    label: "adresse",
    filterKey: "receiver.address1",
  },
  {
    label: "TRACKING",
    filterKey: "suivis_details"
  }
]


const medicalHeaderList = [
  {
    label: "REF",
    filterKey: "ref",
  },
  {
    label: "EXP",
    filterKey: "expeditor.fullname",
  },
  {
    label: "REF interne",
    filterKey: "customer_ref",
  },
  {
    label: "Code don",
    filterKey: "code_donneur",
  },
  {
    label: "adresse",
    filterKey: "expeditor.address1",
  },
  {
    label: "DEST",
    filterKey: "receiver.fullname"
  },
  {
    label: "Code rec",
    filterKey: "code_receveur",
  },
  {
    label: "adresse",
    filterKey: "receiver.address1",
  },
  {
    label: "TRACKING",
    filterKey: "suivis_details"
  },
]