import React, { useCallback, useEffect, useState } from 'react'
import ManifestView from '../../Manifest/Index'
import { useBasicFormValues } from '../../../hooks/useFormValues';
import opsService from '../../../services/ops/opsService';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { getDefaultDate } from '../../../utils';
import useDisclosure from '../../../hooks/useDisclosure';
import DetailExpeditorReceiverComponent from '../../detailExpeditorReceiver/DetailExpeditorReceiverComponent';
import UsersSelector from '../../Utils/Selectors/UsersSelector';
import { useAppContext } from '../../../context';

export default function Manifest({ expedition }) {
    //const [selectedItem, setSelectedItem] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isAuth, setIsAuth } = useAppContext();

    const { data, setFormValue } = useBasicFormValues({
        description: "",
        date_entree: "",
        date: "",
        manifest_id: ""
    })

    useEffect(() => {
        //onOpen()
    }, [])

    useEffect(() => {
        const fetchManifest = async () => {
            if (expedition.id) {
                try {
                    const { data: resData } = await opsService.getManifest({
                        shipping_id: expedition.id
                    })
                    if (resData.success) {
                        //console.log(resData.data)
                        if (resData.message !== "Aucun manifest") {
                            setFormValue("description", resData.data[0]?.description)
                            setFormValue("manifest_id", resData.data[0]?.id)
                        }
                        //setFormValue("ma")
                    }
                } catch (error) {
                    console.error(error)
                }
            }
        }
        fetchManifest()
    }, [expedition])

    useEffect(() => {
        if (expedition?.id) {
            setFormValue("shipping_id", expedition.id)

            const d = new Date()
            d.setMonth(d.getMonth() + 6);

            const startDate = getDefaultDate()
            const endDate = getDefaultDate(d)

            setFormValue("date_entree", startDate)
            setFormValue("date", endDate)

        }
    }, [expedition])


    const onManifestSelect = (item) => {
        console.log({ item })
        //setSelectedItem(item)
        if (item) {
            setFormValue("description", item.description)
            setFormValue("manifest_id", item.id)
        }
    }

    const handleSubmit = useCallback(async (e) => {
        try {
            e?.preventDefault()
            setIsSubmitting(true);
            const submitData = {
                ...data,
                interface: isAuth?.spaceName,
            }
            const { data: resData } = await opsService.addManifest(submitData);
            if (resData.success) {
                toast.success(`Manifest ajouté avec succès!`, {
                    position: "bottom-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error(error)
        } finally {
            setIsSubmitting(false)
        }
    }, [data])

    const valid = data.description.length > 0

    return (
        <div>

            <div className={'space-y-2 md:space-y-0 md:grid grid-cols-2 gap-4'}>
                <div >
                    <DetailExpeditorReceiverComponent UserType={'Expéditeur'} data={expedition?.expeditor} shippingId={expedition?.id} />
                </div>

                <div >
                    <DetailExpeditorReceiverComponent UserType={'Destinataire'} data={expedition?.receiver} shippingId={expedition?.id} />
                </div>
            </div>
            <div className="flex my-4">
                <div className="w-full p-4 bg-white">
                    <form action="">
                        <div className="grid grid-cols-1 gap-4 mb-3">
                            

                            <div className="mb-3 form-group">
                                <label
                                    htmlFor="field5"
                                    className="form-label"
                                >
                                    Nom du manifest
                                </label>
                                <input
                                    type="text"
                                    className=" border bg-white  border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full pl-10 p-2.5"
                                    name="field5"
                                    value={data.description}
                                    onChange={e => setFormValue("description", e.target.value)}
                                    disabled
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-2 gap-4 mb-3">
                            <div className="form-group">
                                <label
                                    htmlFor="field5"
                                    className="form-label"
                                >
                                    Date d'entrée
                                </label>
                                <input
                                    type="date"
                                    className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full pl-10 p-2.5      "
                                    placeholder="Select date"
                                    value={data.date_entree}
                                    onChange={(e) => {
                                        setFormValue("date_entree", e.target.value)
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label
                                    htmlFor="field5"
                                    className="form-label"
                                >
                                    Date
                                </label>
                                <input
                                    type="date"
                                    className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full pl-10 p-2.5      "
                                    placeholder="Select date"
                                    value={data.date}
                                    onChange={(e) => {
                                        setFormValue("date", e.target.value)
                                    }}
                                />
                            </div>
                        </div>

                        <button
                            className={` ${valid ? "cursor-pointer" : "cursor-not-allowed"} text-white bg-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm px-5 py-2.5 text-center`}
                            onClick={handleSubmit}
                            disabled={!valid || isSubmitting}                            >
                            {isSubmitting ? "Enregistrement..." : "Enregistrer"}
                        </button>

                    </form>
                </div>
            </div>
            <ManifestView onItemSelect={onManifestSelect} showTitle={false} expedition={expedition} />
        </div>
    )
}
