import React, { useCallback, useEffect, useState } from "react";
import useDisclosure from "../../hooks/useDisclosure";
import ModalComponent from "../Utils/Modal/Index";
import { useBasicFormValues } from "../../hooks/useFormValues";
import { getDefaultDate, getStartAndEndDateOfCurrentMonth } from "../../utils";
import OptionsSelector from "../Utils/Selectors/Index";
import facturationLineService from "../../services/facturationLine/facturationLineService";
import Preview from "./NewPreview";
import { useParams } from "react-router-dom";
import { exnetSpaceColor } from "../../utils/Functions/otherFunctions";
import { validateFactureData } from "../../utils/validators";
import useFacturations from "../../states/facturations";
import FactureRefSelectors from "../Utils/Selectors/FactureRefSelectors"
import HtmlPreview from "./NewPreview";
import { useAppContext } from "../../context";

const TYPES_OPTIONS = [
  { label: "Facture", value: "facture" },
  { label: "Avoir", value: "avoir" },
  { label: "Rééditer une facture", value: "reediter" },
];

const PAYMENT_METHODS_OPTIONS = [
  { label: "Cartes bancaires", value: "Cartes bancaires" },
  { label: "Chèques", value: "Chèques" },
  { label: "Virement", value: "Virement" },
  { label: "Prélèvements", value: "Prélèvements" },
];

export default function FacturationModal({ shippingIds, selectedFacture, isOpen, onClose }) {

  const [isLoading, setIsLoading] = useState(false);
  const [htmlContent, setHtmlContent] = useState("")
  const [options, setOptions] = useState(TYPES_OPTIONS)
  const [factureType, setFactureType] = useState("facture")
  const {
    isOpen: isPreviewOpen,
    onOpen: onPreviewOpen,
    onClose: onPreviewClose,
  } = useDisclosure();

  const { facturationLines, setFacturationLines } = useFacturations();

  const { isAuth, setIsAuth } = useAppContext();

  const [validationError, setValidationError] = useState("");

  const initialData = {
    type: "facture",
    bon_commande: "",
    facture_reedite: "",
    date_creation: "",
    date_echeance: "",
    paymentMethod: "Virement",
    shippingIds: [],
    message: ""
  }

  const { data: previewFormData, setFormValue } = useBasicFormValues(initialData);

  const [submitData, setSubmitData] = useState(initialData);

  useEffect(() => {
    if (selectedFacture) {
      setFormValue("type", "avoir");
      setOptions(o => o.filter((_option) => _option.value !== "facture" && _option.value !== "reediter"))
      setSubmitData(_data => ({
        ..._data,
        type: "avoir",
      }))
      setFactureType("avoir")

    } else {
      setOptions(o => o.filter((_option) => _option.value !== "avoir"))
      setFactureType("facture")
    }
  }, [selectedFacture]);

  //console.log({ previewFormData })

  useEffect(() => {
    const { endDate, todayDate } = getStartAndEndDateOfCurrentMonth();
    setFormValue("date_creation", todayDate);
    setFormValue("date_echeance", previewFormData.type === "avoir" ? todayDate : endDate);

  }, [previewFormData?.type]);

  useEffect(() => {
    if (!isPreviewOpen) {
      setFacturationLines([]);
    }
  }, [isPreviewOpen]);

  const onPreviewSubmit = useCallback(
    (data) => {
      setHtmlContent(data)
      onPreviewOpen();
    },
    [onPreviewOpen]
  );

  const handleSubmit = useCallback(async () => {
    setValidationError(null);
    const { error: _err } = validateFactureData(previewFormData);

    if (_err) {
      setValidationError(_err);
      return;
    }
    setIsLoading(true);
    const _submitData = {
      ...previewFormData,
      shipping_ids: shippingIds,
      message: previewFormData.message,
      bon_commande: previewFormData.bon_commande,
      start_date: previewFormData.date_creation,
      end_date: previewFormData.date_echeance,
      modeReglement: previewFormData.paymentMethod,
      ref_facture_source_avoir: selectedFacture?.ref,
      type: factureType
    }

    const { data } = await facturationLineService.getPreview(_submitData);

    setSubmitData(_submitData)

    //console.log({ data })
    if (data) {
      onClose();

      onPreviewSubmit(data);
    }
    setIsLoading(false);
  }, [shippingIds, onPreviewSubmit, previewFormData, selectedFacture, factureType, onClose]);

  //console.log({ submitData, factureType })

  return (
    <div>

      <ModalComponent isOpen={isOpen} onClose={onClose}>
        <div>
          {validationError && (
            <div className="bg-red-100 mt-4 text-red-600 px-4 py-2 rounded-md text-[13px]">
              {validationError}
            </div>
          )}
          <div className="text-center font-semibold text-[20px] mb-3">
            Enregistrement de facture
          </div>
          <div className="grid gap-6 mb-6 md:grid-cols-2">
            <div className="text-left">
              <label
                htmlFor="date_creation"
                className="block mb-2 text-sm font-semibold text-gray-900 "
              >
                Date de création*
              </label>
              <input
                type="date"
                id="date_creation"
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-6 p-2.5"
                placeholder="Date de création"
                value={previewFormData.date_creation}
                onChange={(e) => {
                  setFormValue("date_creation", e.target.value);
                }}
              />
            </div>
            <div className="text-left">
              <label
                htmlFor="date_echeance"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                Date d'écheance*
              </label>
              <input
                type="date"
                id="date_echeance"
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-6 p-2.5"
                placeholder="Date d'écheance"
                value={previewFormData.date_echeance}
                onChange={(e) => {
                  setFormValue("date_echeance", e.target.value);
                }}
              />
            </div>
            <div className="text-left">
              <OptionsSelector
                label={"Statut"}
                value={previewFormData.type}
                onChange={(v) => {
                  setFormValue("type", v.value);
                  setFactureType(v.value)
                }}
                options={options}
              />
            </div>
            <div className="text-left relative z-90">
              <OptionsSelector
                label={"Mode de règlement"}
                value={previewFormData.paymentMethod}
                onChange={(v) => {
                  setFormValue("paymentMethod", v.value);
                }}
                options={PAYMENT_METHODS_OPTIONS}
              />
            </div>
            <div className="text-left">
              <label
                htmlFor="bon_commande"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                Bon de commande
              </label>
              <input
                id="bon_commande"
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-6 p-2.5"
                placeholder=""
                value={previewFormData.bon_commande}
                onChange={(e) => {
                  setFormValue("bon_commande", e.target.value);
                }}
              />
            </div>

            {previewFormData.type === "reediter" && (
              <div className="text-left">
                <label
                  htmlFor="facture_reedite"
                  className="block mb-2 text-sm font-semibold text-gray-900"
                >
                  Facture rééditer
                </label>
                <FactureRefSelectors
                  selectedValue={previewFormData.facture_reedite}
                  onChange={(v) => {
                    //console.log({ v })
                    setFormValue("ref", v.ref);
                    setFormValue("facture_reedite", v.value);
                  }}
                  type={isAuth?.spaceName}
                />
              </div>
            )}

            <div className="text-left">
              <label
                htmlFor="message"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                Message
              </label>
              <textarea
                id="message"
                value={previewFormData.message}
                onChange={e => {
                  setFormValue("message", e.target.value);
                }}
                maxLength={275}
                style={{
                  minHeight: 90,
                  fontSize: 11,
                  borderRadius: 6,
                  padding: "2px 8px",
                  border: "1px solid #ccc",
                }}
              >
              </textarea>
            </div>
          </div>

          <div className="flex justify-end my-7">
            <button
              onClick={handleSubmit}
              type="submit"
              className={`text-white ${isLoading ? "bg-gray-700" : "bg-black"
                } hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center`}
              style={{ color: "#fff", backgroundColor: `${exnetSpaceColor(isAuth?.spaceName)}` }}
            >
              {isLoading ? "Validation" : "Valider"}
            </button>
          </div>
        </div>
      </ModalComponent>
      {facturationLines.length > 0 && (
        <Preview
          isOpen={isPreviewOpen}
          onClose={() => {
            //setFacturationLines([])
            onPreviewClose();
            //onOpen();
          }}
          data={{
            facturationLines,
            metadata: previewFormData,
          }}
        />
      )}
      <HtmlPreview
        data={submitData}
        isOpen={isPreviewOpen}
        onClose={onPreviewClose}
        htmlContent={htmlContent}
      />
    </div>
  );
}
