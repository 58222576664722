import React, { useCallback, useEffect, useState } from 'react'
import { PreviewModalComponent } from '../Utils/Modal/Index'
import "./style.css"
import factureService from '../../services/facture/factureService';
import { useNavigate } from "react-router-dom";
import { exnetSpaceColor } from '../../utils/Functions/otherFunctions';
import Loader from '../Utils/Loader';
import { toast } from "react-toastify";
import { formatFactureRef } from '../../utils';
import useFacturations from "../../states/facturations"
import { useAppContext } from '../../context';

export default function HtmlPreview({ htmlContent, isOpen, onClose, data }) {
    const [isLoading, setIsLoading] = useState(false)
    const { isAuth, setIsAuth } = useAppContext();
    const [factureRef, setFactureRef] = useState("X-XXXXXX-XXXX");
    const navigate = useNavigate()
    const { setSelectedRows, setFacturationLines } = useFacturations()



    const isReedited = data?.type === "reediter"

    useEffect(() => {
        const getFactureRef = async () => {
            try {
                if (!isReedited) {
                    const { data: resData } = await factureService.getLatestFactureRef()
                    //console.log({ resData, data })
                    if (resData.success) {
                        const ref = formatFactureRef(resData.data.ref, isAuth?.spaceName, data.type);
                        //console.log({ ref }, resData.data.ref, type, data.type)
                        setFactureRef(ref)
                    }
                } else {
                    const ref = data.facture_reedite
                    setFactureRef(ref)
                }

            } catch (error) {
                console.error(error);
                toast.error(`Une erreur est survenue, veuillez reessayer plus tard!`, {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        };

        // Call getFactureRef initially
        if (data) {
            getFactureRef();
        }

        // Set up interval to call getFactureRef every 5 seconds
        const intervalId = setInterval(() => {
            getFactureRef();
        }, 5000); // 5000 milliseconds = 5 seconds

        // Clear the interval when the component is unmounted
        return () => {
            clearInterval(intervalId);
        };
    }, [data, isReedited])

    const onSubmit = useCallback(async () => {
        try {
            setIsLoading(true)
            const ref = factureRef.split("-")?.[2]
            const submitData = {
                ...data,
                ref: Number(ref),
                full_ref: factureRef,
            }
            if (isReedited) {
                submitData.facture_reedited_ref = ref
            }
            console.log({ submitData })
            const { data: resData } = await factureService.saveFacture(submitData);
            if (resData.success) {
                onClose()
                setSelectedRows([])
                setFacturationLines([])
                if (data.type === "avoir") {
                    //reload page
                    window.location.reload()
                } else {
                    navigate(`/ops/${isAuth?.spaceName}/dash/factures/listes`)
                }
            }
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }, [data, navigate, isReedited, onClose, factureRef, setFacturationLines, setSelectedRows]);

    return (
        <PreviewModalComponent
            isOpen={isOpen}
            onClose={onClose}
            closeIconVisible={false}
        >
            <div id="preview-containe" className='pt-[210px] pb-[210px]'>
                <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
            </div>
            <div className="flex justify-end">
                <button
                    onClick={() => {
                        setIsLoading(true);
                        setTimeout(() => {
                            onSubmit()
                        }, 100);
                    }}
                    type="button"
                    className="mb-2 text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                    disabled={isLoading}
                    style={{
                        color: "#fff",
                        backgroundColor: `${exnetSpaceColor(isAuth?.spaceName)}`,
                    }}
                >
                    <span className="mr-1">{isLoading ? "Validation en cours..." : "Valider"}</span>
                    {isLoading && <Loader />}
                </button>
            </div>
        </PreviewModalComponent>
    )
}
