import React, { useCallback, useEffect, useState } from 'react'
import ModalUI from '../../Utils/Modal/Modal'
import { useBasicFormValues } from '../../../hooks/useFormValues';
import { validateFuelData } from '../../../utils/validators';
import Loader from '../../Utils/Loader';
import { pick } from 'lodash';
import fuelService from '../../../services/fuel/fuelService';
import { toast } from 'react-toastify';
import { SwitchComponent } from '../../Utils/Index';

export default function FuelModal({ isOpen, onClose, onSubmit, selectedData, count }) {
    const initialData = {
        value: "",
        is_actif: 1,
        for_variation: 0,
        index_fuel: count
    }

    const { data: fuelData, setFormValue, setData } = useBasicFormValues(initialData);

    //console.log({ fuelData })

    useEffect(() => {
        if (selectedData) {
            setData(d => ({
                ...d,
                ...selectedData
            }))
        } else {
            setData(initialData)
        }
    }, [selectedData, isOpen])

    const [validationError, setValidationError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);


    const handleSubmit = useCallback(
        async (e) => {
            try {
                e.preventDefault();
                setValidationError(null);
                console.log({ fuelData })
                const submitData = pick(fuelData, ["id", "value", "is_actif", "for_variation"])

                const { error: _err } = validateFuelData(submitData);
                if (_err) {
                    setValidationError(_err)
                    return;
                }
                setIsSubmitting(true);

                console.log({ submitData })
                const request = selectedData ? fuelService.editFuel : fuelService.addFuel
                const { data } = await request(submitData);
                if (data.success) {
                    onClose();
                    onSubmit?.(selectedData ? fuelData : data.data)
                    setData(initialData)
                }
            } catch (error) {
                console.error(error);
                toast.error('Une erreur lors de la soumission de votre demande', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } finally {
                setIsSubmitting(false);
            }
        },
        [fuelData, selectedData]
    );

    const keyword = "fuel"

    return (
        <ModalUI
            isOpen={isOpen}
            onClose={onClose}
            title={selectedData ? `Modifier le ${keyword}` : `Créer un ${keyword}`}
        >
            {validationError && (
                <div className="bg-red-100 mt-4 text-red-600 px-4 py-2 rounded-md text-[13px]">{validationError}</div>
            )}
            <form onSubmit={handleSubmit} className="p-3">
                <div className="grid gap-6 mb-3 md:grid-cols-1">
                    <div className="text-left">
                        <label
                            htmlFor="value"
                            className="block mb-2 text-sm font-medium text-gray-900"
                        >
                            Valeur*
                        </label>
                        <input
                            type="number"
                            id="value"
                            placeholder="Valeur du fuel"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-100 block w-full p-2"
                            required
                            value={fuelData.value}
                            onChange={(e) => setFormValue("value", e.target.value)}
                        />
                    </div>

                    <div className="flex items-center mt-2">
                        <div className="text-sm font-medium text-gray-900 mr-2">Pour variation</div>
                        <SwitchComponent
                            label="for_variation"
                            checked={Boolean(fuelData.for_variation === 1)}
                            onChange={(value) => {
                                console.log({ value })
                                setFormValue("for_variation", value ? 1 : 0);
                            }}
                        />
                    </div>

                </div>
                <div className="flex justify-end">
                    <button
                        type="submit"
                        className={`cursor-pointer text-white bg-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm px-14 py-2.5 text-center mt-4`}
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                    //title={`${}`}
                    >
                        <span className="mr-3">
                            {!selectedData ? "Enregistrer" : "Modifier"}
                        </span>
                        {isSubmitting && (
                            <Loader />
                        )}

                    </button>
                </div>
            </form>
        </ModalUI>
    )
}
