// create CentreFacturation react service

import axios from "axios";
import TokenService from "../config/tokenService.js";

class CountryService {

    constructor() {
        TokenService.setHeader();
    }
    get() {
        return axios.get(`${process.env.REACT_APP_API_URL}/user/countries`);
    }
}

export default new CountryService();
