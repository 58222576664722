import React, { useCallback, useEffect, useState } from "react";
import useDisclosure from "../../hooks/useDisclosure";
import useFormValues, { useBasicFormValues } from "../../hooks/useFormValues";
import CountriesSelector from "../Utils/Selectors/CountriesSelector";
import { userService } from "../../services/user/UserService";
import ExnetTypeSelector from "../Utils/Selectors/ExnetTypeSelector";
import UsersSelector from "../Utils/Selectors/UsersSelector";
import ModalUI from "../Utils/Modal/Modal";
import FormUploadSection from "../Utils/FormUploadSection";
import { SwitchComponent } from "../Utils/Index";
import centreFacturationService from "../../services/centreFacturation/centreFacturationService";
import { getFileUrl, jsonToFormData } from "../../utils";
import ClientSelector from "../Utils/Selectors/ClientSelector";
import Loader from "../Utils/Loader";
import { toast } from "react-toastify";
import { comparerObjets } from "../../utils/Functions/otherFunctions";
import PhoneInput from "react-phone-input-2";
import { getCountryNameOrCode } from "../../utils/const/countriesConstant";

const CenterForm = ({
  modalFormContent,
  newData,
  editable,
  onSubmit,
  onClose
}) => {
  const { data: centerData, setFormValue, setData } = useBasicFormValues(
    { type: "", country: "fr" }
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [makeUpdate, setmakeUpdate] = useState(false);

  useEffect(() => {
    if (modalFormContent) {
      // console.log('la data ', modalFormContent)
      setData(d => ({ ...d, ...modalFormContent }))
    }
  }, [modalFormContent])

  const submit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        const updated = centerData.id

        setIsSubmitting(true);
        let newData = comparerObjets(centerData, updated)
        // let submitData = jsonToFormData({
        //   ...newData,
        //   actif: updated ? centerData.actif : 1
        // });
        let submitData = jsonToFormData({
          ...centerData,
          actif: updated ? centerData.actif : 1
        });

        if (updated) {
          submitData.id = updated
        }
        // console.log({ submitData })
        const request = updated ? centreFacturationService.editCentreFacturation : centreFacturationService.addCentreFacturation;

        /*   if (!updated) {
            submitData.actif = 1
          } */

        const { data } = await request(submitData);
        //console.log({ data })
        if (data.success) {
          onSubmit?.(updated ? data.data : { ...data.data, actif: 1 })
        } else {
          toast.error('Une erreur lors de la soumission de votre demande', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        // console.error(error);
        toast.error('Une erreur lors de la soumission de votre demande', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } finally {
        setIsSubmitting(false);
      }
    },
    [centerData, onSubmit]
  );


  const onChangeSelect = (data) => {
    console.log(data);

    setFormValue("customer_id", data?.value);
    let customer_id = data?.value;
    let address = data?.address;
    let address2 = data?.address2;
    let postal_code = data?.postal_code;
    let city = data?.city;
    let telephone = data?.telephone;
    let telephone1 = data?.telephone1;
    let telephone2 = data?.telephone2;
    let country = data?.country;
    let email_facturation = data?.email_facturation;
    let responsable_comptable = data?.responsable_comptable;
    let intra_vatnumber = data?.intra_vatnumber;
    let email1 = data?.email1;
    let email2 = data?.email2;
    let email3 = data?.email3;
    let numero_siren = data?.numero_siren;
    let eori = data?.eori;

    setData({ customer_id, address, address2, city, postal_code, telephone1, telephone2, country, responsable_comptable, email_facturation, intra_vatnumber, email1, email2, email3, eori, numero_siren });

  }

  return (
    <div className="p-3">
      {!newData && (
        <div className={"flex justify-end"}>
          <button
            type="submit"
            className="text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
            onClick={() => setmakeUpdate(!makeUpdate)}
          >
            <span className={"fa fa-edit"}></span>
          </button>
        </div>
      )}

      <form onSubmit={submit}>
        <div className="grid gap-6 mb-3 md:grid-cols-2">
          <div className="text-left">
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Choix du client
            </label>
            <ClientSelector
              showLabel={false}
              selectedValue={centerData.customer_id}
              onSelect={(v) => {
                onChangeSelect(v)

              }}
              type={centerData?.type?.split(",")?.[0] || "all"}
              filterOnAll
            //type="all"
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="address"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Nom du centre de facturation *
            </label>
            <input
              type="text"
              id="billing_center_name"
              placeholder=""
              className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
              required
              value={centerData.billing_center_name}
              onChange={(e) =>
                setFormValue("billing_center_name", e.target.value)
              }
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="address"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Adresse de facturation *
            </label>
            <input
              type="text"
              id="address"
              placeholder="Adresse"
              className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
              required
              value={centerData.address}
              onChange={(e) => setFormValue("address", e.target.value)}
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="address2"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Complément d'adresse
            </label>
            <input
              type="text"
              id="address2"
              className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
              value={centerData.address2 ?? ""}
              onChange={(e) => setFormValue("address2", e.target.value)}
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="city"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Ville*
            </label>
            <input
              type="text"
              id="city"
              className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
              required
              value={centerData.city}
              onChange={(e) => setFormValue("city", e.target.value)}
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="postal_code"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Code Postal*
            </label>
            <input
              type="text"
              id="postal_code"
              className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
              required
              value={centerData.postal_code}
              onChange={(e) => setFormValue("postal_code", e.target.value)}
            />
          </div>

          <div className="text-left">
            <CountriesSelector
              selectedValue={centerData.country}
              onSelect={(v) => {
                setFormValue("country", v.value);
              }}
            // isDisabled={!editable}
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="telephone"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Téléphone*
            </label>

            <PhoneInput
              country={getCountryNameOrCode(getCountryNameOrCode(centerData?.country), true) ?? 'fr'}
              defaultValue={centerData.telephone1}
              value={centerData.telephone1}
              onChange={(value, country, e, formattedValue) => { setFormValue("telephone1", formattedValue) }}
              inputClass={'form-controle'}
              countryCodeEditable={true}
              disableDropdown={true}
            />
          </div>
          <div className="text-left">
            <label
              htmlFor="email_facturation"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Email de facturation*
            </label>
            <input
              type="email"
              id="email_facturation"
              className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
              value={centerData?.email_facturation}
              onChange={(e) => setFormValue("email_facturation", e.target.value)}
            />
          </div>

          <div className="text-left">
            {/*  {centerData.type} */}
            <ExnetTypeSelector
              multiple={false}
              isRequired
              value={centerData?.type}
              onChange={(v) => {
                setFormValue("type", v?.value);
              }}
            />
          </div>
        </div>

        <div className="w-full h-[1px] bg-gray-300 my-2" />

        <div className="grid gap-6 mb-3 md:grid-cols-3">
          <div className="text-left">
            <label
              htmlFor="dirigeant"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              <span className="font-semibold">Contact</span> (Nom/Prénoms)
            </label>
            <input
              id="dirigeant"
              className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
              value={centerData.dirigeant}
              onChange={(e) => setFormValue("dirigeant", e.target.value)}
            />
          </div>
          <div className="grid col-span-2 gap-6 md:grid-cols-2">
            <div className="text-left">
              <label
                htmlFor="dirigeant_tel"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Téléphone
              </label>

              <PhoneInput
                country={getCountryNameOrCode(getCountryNameOrCode(centerData?.country), true) ?? 'fr'}
                defaultValue={centerData.dirigeant_tel}
                value={centerData.dirigeant_tel}
                onChange={(value, country, e, formattedValue) => { setFormValue("dirigeant_tel", formattedValue) }}
                inputClass={'form-controle'}
                countryCodeEditable={true}
                disableDropdown={false}
              />
            </div>

            <div className="text-left">
              <label
                htmlFor="dirigeant_email"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Email
              </label>
              <input
                type="text"
                id="dirigeant_email"
                className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
                value={centerData.dirigeant_email}
                onChange={(e) =>
                  setFormValue("dirigeant_email", e.target.value)
                }
              />
            </div>
          </div>
        </div>

        <div className="w-full h-[1px] bg-gray-300 my-2" />

        <div className="grid gap-6 mb-3 md:grid-cols-3">
          <div className="text-left">
            <label
              htmlFor="responsable_comptable"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              <span className="font-semibold">Resp. Compta.</span> (Nom/Prénoms)
            </label>
            <input
              type="text"
              id="responsable_comptable"
              className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
              value={centerData.responsable_comptable}
              onChange={(e) =>
                setFormValue("responsable_comptable", e.target.value)
              }
            />
          </div>

          <div className="grid col-span-2 gap-6 md:grid-cols-2">
            <div className="text-left">
              <label
                htmlFor="responsable_comptable_tel"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Téléphone
              </label>

              <PhoneInput
                country={getCountryNameOrCode(getCountryNameOrCode(centerData?.country), true) ?? 'fr'}
                defaultValue={centerData.responsable_comptable_tel}
                value={centerData.responsable_comptable_tel}
                onChange={(value, country, e, formattedValue) => { setFormValue("responsable_comptable_tel", formattedValue) }}
                inputClass={'form-controle'}
                countryCodeEditable={true}
                disableDropdown={false}
              />
            </div>

            <div className="text-left">
              <label
                htmlFor="responsable_comptable_email"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Email
              </label>
              <input
                type="text"
                id="responsable_comptable_email"
                className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
                value={centerData.responsable_comptable_email}
                onChange={(e) => setFormValue("responsable_comptable_email", e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="w-full h-[1px] bg-gray-300 my-2" />

        {/* <div>
                    <div className="w-full mb-3">
                        <label
                            htmlFor="responsable_comptable"
                            className="block mb-2 text-sm font-medium text-gray-900"
                        >
                            Resp. DAF. (Nom/Prénoms)
                        </label>
                        <input
                            type="email"
                            id="dafresponsible_name"
                            className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
                            value={centerData.dafresponsible_name}
                            onChange={(e) =>
                                setFormValue("dafresponsible_name", e.target.value)
                            }
                        />
                    </div>

                    <div className="grid gap-6 mb-3 md:grid-cols-2">
                        <div className="text-left">
                            <label
                                htmlFor="dafresponsible_phone"
                                className="block mb-2 text-sm font-medium text-gray-900"
                            >
                                Téléphone
                            </label>
                            <input
                                type="text"
                                id="dafresponsible_phone"
                                className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
                                value={centerData.dafresponsible_phone}
                                onChange={(e) =>
                                    setFormValue(
                                        "dafresponsible_phone",
                                        e.target.value
                                    )
                                }
                            />
                        </div>

                        <div className="text-left">
                            <label
                                htmlFor="dafresponsible_email"
                                className="block mb-2 text-sm font-medium text-gray-900"
                            >
                                Email
                            </label>
                            <input
                                type="text"
                                id="dafresponsible_email"
                                className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
                                value={centerData.dafresponsible_email}
                                onChange={(e) =>
                                    setFormValue(
                                        "dafresponsible_email",
                                        e.target.value
                                    )
                                }
                            />
                        </div>
                    </div>
                </div> */}

        <div className="grid gap-6 mb-3 md:grid-cols-3">
          <div className="text-left">
            <label
              htmlFor="intra_vatnumber"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              N° TVA Intracommunautaire
            </label>
            <input
              type="text"
              id="intra_vatnumber"
              className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
              value={centerData.intra_vatnumber}
              onChange={(e) => setFormValue("intra_vatnumber", e.target.value)}
            />
          </div>
          <div className="text-left">
            <label
              htmlFor="siren"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              N° SIREN
            </label>
            <input
              type="text"
              id="numero_siren"
              className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
              value={centerData.numero_siren}
              onChange={(e) => setFormValue("numero_siren", e.target.value)}
            />
          </div>
          <div className="text-left">
            <label
              htmlFor="numero_eori"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              N° EORI
            </label>
            <input
              type="text"
              id="numero_eori"
              className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
              value={centerData.numero_eori}
              onChange={(e) => setFormValue("numero_eori", e.target.value)}
            />
          </div>
        </div>

        <div className="grid gap-6 mb-3 md:grid-cols-3">
          <div className="text-left">
            <label
              htmlFor="email1"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Email
            </label>
            <input
              type="text"
              id="email1"
              className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
              value={centerData.email1}
              onChange={(e) => setFormValue("email1", e.target.value)}
            />
          </div>
          <div className="text-left">
            <label
              htmlFor="email2"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Email 2
            </label>
            <input
              type="text"
              id="email2"
              className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
              value={centerData.email2}
              onChange={(e) => setFormValue("email2", e.target.value)}
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="email3"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Email 3
            </label>
            <input
              type="text"
              id="email3"
              className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
              value={centerData.email3}
              onChange={(e) => setFormValue("email3", e.target.value)}
            />
          </div>
        </div>

        <div>
          <div className="mb-3">
            <FormUploadSection
              logo={centerData.logo}
              header={centerData.header}
              footer={centerData.footer}
              onDataChange={setFormValue}
            />
          </div>
          <div className="flex items-center justify-between mb-3">
            <label
              htmlFor="valeur_customer"
              className="text-sm font-medium text-gray-900 cursor-pointer"
            >
              Afficher valeur commerciale sur bordereau
            </label>
            <SwitchComponent
              id="valeur_customer"
              label="valeur_customer"
              checked={+centerData.valeur_customer === 1}
              onChange={(checked) => {
                setFormValue("valeur_customer", checked ? 1 : 0);
              }}
              checkedColor="#098721"
            />
          </div>

          <div className="flex items-center justify-between mb-3">
            <label
              htmlFor="customer_name"
              className="text-sm font-medium text-gray-900 cursor-pointer"
            >
              Afficher nom client sur bordereau
            </label>
            <SwitchComponent
              id="customer_name"
              label="customer_name"
              checked={+centerData.customer_name === 1}
              onChange={(checked) => {
                setFormValue("customer_name", checked ? 1 : 0);
              }}
              checkedColor="#098721"
            />
          </div>

          <div className="flex items-center justify-between mb-3">
            <label
              htmlFor="active_mail_instruction"
              className="text-sm font-medium text-gray-900 cursor-pointer"
            >
              Afficher mail d'instruction
            </label>
            <SwitchComponent
              id="active_mail_instruction"
              label="active_mail_instruction"
              checked={+centerData.active_mail_instruction === 1}
              onChange={(checked) => {
                setFormValue("active_mail_instruction", checked ? 1 : 0);
              }}
              checkedColor="#098721"
            />
          </div>

          <div className="flex items-center justify-between mb-3">
            <label
              htmlFor="mailing"
              className="text-sm font-medium text-gray-900 cursor-pointer"
            >
              Lettre d'info / mailing
            </label>
            <SwitchComponent
              id="mailing"
              label="mailing"
              checked={+centerData.mailing === 1}
              onChange={(checked) => {
                setFormValue("mailing", checked ? 1 : 0);
              }}
              checkedColor="#098721"
            />
          </div>



          <div className="flex items-center justify-between mb-3">
            <label
              htmlFor="deux_bordereaux"
              className="text-sm font-medium text-gray-900 cursor-pointer"
            >
              Deux borderaux
            </label>
            <SwitchComponent
              id="deux_bordereaux"
              label="deux_bordereaux"
              checked={+centerData.deux_bordereaux === 1}
              onChange={(checked) => {
                setFormValue("deux_bordereaux", checked ? 1 : 0);
              }}
              checkedColor="#098721"
            />
          </div>
        </div>

        <div className="flex justify-end mt-10">
          <div
            onClick={() =>
              onClose?.()
            }
            className="bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-3"
          >
            Annuler
          </div>
          {(editable || makeUpdate) && (
            <button
              type="submit"
              className={`${isSubmitting ? "cursor-not-allowed" : "cursor-pointer"} text-white bg-[#098721] hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center`}
              disabled={isSubmitting}
            >
              <span className="mr-1">Modifier</span>
              {isSubmitting && <Loader />}
            </button>
          )}

          {newData && (
            <button
              type="submit"
              className={`${!isSubmitting ? "cursor-not-allowed" : "cursor-pointer"} text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center`}
              disabled={isSubmitting}
            >
              <span className="mr-1">Enregistrer</span>
              {isSubmitting && <Loader />}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default function CreateCenterForm({
  withoutModal,
  modalFormContent,
  editable,
  dataType,
  isOpen,
  onClose,
  onSubmit
}) {

  // console.log({ modalFormContent })

  return (
    <>
      {withoutModal ? (
        <CenterForm
          modalFormContent={modalFormContent}
          editable={editable}
          dataType={dataType}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      ) : (
        <>

          <ModalUI
            isOpen={isOpen}
            onClose={onClose}
            title={modalFormContent ? "Modifier le centre de facturation" : "Ajouter un centre de facturation"}
          >
            <CenterForm
              modalFormContent={null}
              newData={true}
              dataType={dataType}
              onClose={onClose}
              onSubmit={onSubmit}
            />
          </ModalUI>
        </>
      )}
    </>
  );
}

export const BillingCenterModal = ({ isOpen, selectedData, onClose, onSubmit }) => {

  return (
    <ModalUI
      isOpen={isOpen}
      onClose={onClose}
      title={selectedData ? "Modifier le centre de facturation" : "Créer un centre de facturation"}
    >
      <CenterForm
        modalFormContent={selectedData}
        newData={true}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    </ModalUI>
  )
}