import React, { useState, useEffect } from "react";
import axios from "axios";
import clsx from "clsx";

function CitiesSelector({
    country_code,
    postalCode,
    onSelect,
    defaultValue,
    isDisabled = false,
    isError,
    errorMessage,
    ...props
}) {
    const [inputValue, setInputValue] = useState(defaultValue);
    const [suggestions, setSuggestions] = useState([]);
    const [isFocus, setIsFocus] = useState(false);

    useEffect(() => {
        setInputValue(defaultValue)
    }, [defaultValue])

    useEffect(() => {
        // Fetch and set default suggestions when the component mounts
        async function fetchDefaultSuggestions() {
            try {
                let response = (inputValue && inputValue !== "") ?
                    await axios.get(`${process.env.REACT_APP_API_URL}/user/map-cities/code-postal?country_code=${country_code}&postal_code=${postalCode}&search_term=${inputValue}`)
                    :
                    await axios.get(`${process.env.REACT_APP_API_URL}/user/map-cities/code-postal?country_code=${country_code}&postal_code=${postalCode}`);

                console.log(response)

                if (Array.isArray(response.data.data)) {
                    setSuggestions(
                        response.data.data.map((place) => ({
                            value: place.place_name,
                            label: place.place_name,
                        }))
                    );
                }
            } catch (error) {
                console.error("Error fetching default suggestions:", error);
            }
        }

        fetchDefaultSuggestions();
    }, [country_code, postalCode]); // Empty dependency array ensures this effect runs once after mounting

    const handleChange = async (event) => {
        const value = event.target.value;
        setInputValue(value);

        try {

            let response = (value && value !== "") ?
                await axios.get(`${process.env.REACT_APP_API_URL}/user/map-cities/code-postal?country_code=${country_code}&postal_code=${postalCode}&search_term=${value}`)
                :
                await axios.get(`${process.env.REACT_APP_API_URL}/user/map-cities/code-postal?country_code=${country_code}&postal_code=${postalCode}`);
            console.log(response)
            // Assuming your response.data is an array of strings
            if (Array.isArray(response.data.data)) {
                setSuggestions(
                    response.data.data.map((place) => ({
                        value: place.place_name,
                        label: place.place_name,
                    }))
                );
            } else {
                console.error("Invalid data format:", response.data);
                setSuggestions([]); // Clear suggestions if data format is invalid
            }
        } catch (error) {
            console.error("Error fetching suggestions:", error);
            setSuggestions([]); // Clear suggestions in case of an error
        }
    };

    return (
        <div className="relative">
            <input
                // className={`${isDisabled ? "bg-gray-50" : "bg-white"
                //     // } border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-4 p-2.5      `}
                //     } w-full  bg-white border border-gray-500 rounded-lg outline-none form-control`}
                className={clsx({ "bg-gray-50": isDisabled, "bg-white": !isDisabled, "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500": isError, "ring-gray-300 focus:ring-gray-500  border border-gray-500": !isError }, "px-2 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-inset  focus:ring-2 focus:ring-inset text-base sm:leading-6")}
                placeholder="VILLE *"
                value={inputValue}
                onChange={(e) => {
                    onSelect(e.target.value);
                    handleChange(e)
                }}
                onFocus={() => {
                    setIsFocus(true);
                }}
                onBlur={() => {
                    setTimeout(() => {
                        setIsFocus(false);
                    }, 300);
                }}
                disabled={isDisabled}
            />

            {isError &&
                <p className="mt-2 text-sm text-red-600" id="email-error">
                    {errorMessage}
                </p>
            }


            {isFocus && suggestions.length > 0 && (
                <div className="absolute left-0 right-0 bg-white mt-2 border max-h-60 rounded-sm overflow-scroll z-10">
                    {suggestions.map((opt, i) => (
                        <div
                            onClick={() => {
                                setInputValue(opt.label);
                                onSelect(opt.label);
                                // onChange(opt.value);
                            }}
                            className="px-4 py-2 cursor-pointer text-sm"
                            key={i}
                        >
                            {opt.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default CitiesSelector;
