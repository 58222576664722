import React, { useState } from "react";
import Select from "react-select";
import { roleSplit } from "../../../utils/Functions/otherFunctions";

const ALL_ROLES = [
  { value: "sedentaire", label: "Sédentaire" },
  { value: "ops", label: "Ops" },
  { value: "traveller", label: "Voyageur" },
  { value: "exploitation", label: "Exploitation" },
  { value: "chauffeur", label: "Chauffeur" },
  { value: "user", label: "Utilisateur" },
];

const RolesSelector = ({
  showLabel = true,
  value,
  onChange: onRoleChange,
  required,
}) => {
  const [roles, setRoles] = useState(ALL_ROLES);

  // const { role } = roleSplit(value);

  const values = roles.filter((type) => value?.includes(type.value));

  return (
    <div>
      {showLabel && (
        <label
          htmlFor="countries"
          className="block mb-2 text-sm font-medium text-gray-900 "
        >
          Roles {required && "*"}
        </label>
      )}
      <Select
        options={roles}
        value={values}
        onChange={(v) => {
          const selectedValues = v.map((v) => v.value);
          onRoleChange(selectedValues);
        }}
        isMulti
        className="text-base w-100"
      />
    </div>
  );
};

export default RolesSelector;
