import { create } from 'zustand'
import { devtools } from "zustand/middleware";

const useFacturations = (set) => ({
    facturationLines: [],
    setFacturationLines: (payload) => set(() => ({ facturationLines: payload })),
    selectedRows: [],
    setSelectedRows: (payload) => set(() => ({ selectedRows: payload })),
    avoirFacture: null,
    setAvoirFacture: (payload) => set(() => ({ avoirFacture: payload })),
    shippingsInAvoir: [],
    setShippingsInAvoir: (payload) => set(() => ({ shippingsInAvoir: payload })),

})


export default create(devtools(useFacturations))