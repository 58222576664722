import React, { createContext, useEffect, useState } from 'react';
import Echo from "laravel-echo";
import io from "socket.io-client";

const Context = createContext();

export default function EchoContext({ children }) {
    const [echo, setEcho] = useState();
    const [socket, setSocket] = useState();

    const SOCKET_URL = `${process.env.REACT_APP_BACKEND_HOST}`

    /*  useEffect(() => {
         let _echo = new Echo({
             broadcaster: "socket.io",
             transports: ["websocket", "polling", "flashsocket"],
             host: `${process.env.REACT_APP_BACKEND_HOST}:6001`,
             client: io,
 
         });
         setEcho(_echo);
 
     }, []) */

    useEffect(() => {
        const connectAsync = () => {
            try {
                //const token = await getSecureData("token");
                //if (token) {
                const _socket = io(SOCKET_URL, {
                    /* auth: {
                      authUserId: user._id,
                      token,
                    }, */
                });
                setSocket(_socket);
                //}
            } catch (error) {
                console.error(error);
            }
        };
        //connectAsync();

    }, [SOCKET_URL])

    return (
        <Context.Provider value={echo}>
            {/* socket && */ children}
        </Context.Provider>
    );
}

export const useEcho = () => {
    const echo = React.useContext(Context);
    if (echo === undefined) {
        throw new Error("useEcho must be used within a EchoProvider");
    }
    return echo;
}
