import { Switch } from "@headlessui/react";

export const SwitchComponent = ({
  id = "switch",
  label = "",
  checked,
  onChange,
  checkedColor = "#098721",
  uncheckedColor = "#ddd",
  ...props
}) => {
  return (
    <Switch
      id={id}
      checked={checked}
      onChange={onChange}
      style={{
        backgroundColor: checked ? checkedColor : uncheckedColor
      }}
      className={`relative inline-flex h-6 w-11 items-center rounded-full`}
      {...props}
    >
      <span className="sr-only">{label}</span>
      <span
        className={`${checked ? "translate-x-6" : "translate-x-1"
          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
      />
    </Switch>
  );
};
