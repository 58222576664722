import React from "react";

export default function Filter({
    type,
    filterData,
    setfilterData,
    revokeSearch,
    handleSearch
}) {

    const changeValue = (valueKey, value) => {
        let newValue = {};
        for (const property in filterData) {
            if (filterData[property] !== "") {
                newValue[property] = filterData[property];
            }
        }

        newValue[valueKey] = value;

        setfilterData(newValue);
    };


    const handlerEnterClick = (event) => {
        // Vérifier si la touche "Enter" a été pressée (code 13)
        if (event.key === 'Enter') {
            handleSearch()
        }
    };



    return (
        <div className="py-3 ">
            <div className="grid-cols-4 gap-4 space-y-3 md:grid md:space-y-0">
                <div className="w-full md:w-[230px] mb-6 md:mb-0">
                    <label
                        className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                        htmlFor="ref"
                    >
                        {/* Référence */}
                       Recherche
                    </label>
                    <input
                        className="block text-sm h-[40px] w-full px-4 py-2 mb-3 leading-tight text-gray-700 bg-white border border-gray-300  appearance-none focus:outline-none focus:bg-white"
                        id="ref"
                        type="text"
                        placeholder=""
                        value={filterData?.ref ? filterData?.ref : ""}
                        onChange={(e) => {
                            if (
                                parseInt(e.target.value) ||
                                e.target.value === ""
                            ) {
                                if (e.target.value === "") {
                                    revokeSearch()
                                    changeValue("ref", e.target.value);
                                
                                   
                                } else {
                                    changeValue("ref", e.target.value);
                                }

                            }

                        }}
                        onKeyDown={handlerEnterClick}
                    />
                </div>

                {/* <div className="w-full md:w-[230px] mb-6 md:mb-0">
                    <label
                        className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                        htmlFor="suivi"
                    >
                        Suivi
                    </label>
                    <input
                        className="block text-sm h-[40px] w-full px-4 py-2 mb-3 leading-tight text-gray-700 bg-white border border-gray-300  appearance-none focus:outline-none focus:bg-white"
                        id="suivi"
                        type="text"
                        placeholder=""
                        value={filterData?.suivi ? filterData?.suivi : ""}
                        onChange={(e) => {
                            changeValue("suivi", e.target.value);
                        }}
                    />
                </div>
                <div className="w-full md:w-[230px] mb-6 md:mb-0">
                    <label
                        className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                        htmlFor="contact"
                    >
                        Contact
                    </label>
                    <input
                        className="block text-sm h-[40px] w-full px-4 py-2 mb-3 leading-tight text-gray-700 bg-white border border-gray-300  appearance-none focus:outline-none focus:bg-white"
                        id="contact"
                        type="text"
                        placeholder=""
                        value={filterData?.contact ? filterData?.contact : ""}
                        onChange={(e) => {
                            changeValue("contact", e.target.value);
                        }}
                    />
                </div>

                <div className="w-full md:w-[230px] mb-6 md:mb-0 pb-3">
                    <label
                        className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                        htmlFor="billing_center"
                    >
                        Centre de facturation
                    </label>
                    <BillCenterSelector
                        client={true}
                        user={user?.customer_id}
                        type={type}
                        defaultInputValue={filterData?.billing_center}
                        selectedValue={filterData?.billing_center}
                        onSelect={(v) => {
                            changeValue("billing_center", v.value)
                        }}
                        isDisabled={false}

                    />

                </div> */}

                {/* <div className="w-full md:w-[230px] mb-6 md:mb-0 flex">
                    <button
                        onClick={handleSearch}
                        type="button"
                        className="mx-2 text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold -lg text-sm w-full sm:w-auto px-3 py-2.5 text-center"
                        // disabled={isSubmitting}
                        style={{ color: '#fff', backgroundColor: `${exnetSpaceColor(type)}` }}
                    >
                        Rechercher
                    </button>

                    <button
                        onClick={revokeSearch}
                        type="button"
                        className="mx-2 text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold -lg text-sm w-full sm:w-auto px-3 py-2.5 text-center"
                        // disabled={isSubmitting}
                        style={{ color: '#fff', backgroundColor: `${exnetSpaceColor(type)}` }}
                    >
                        Annuler
                    </button>
                </div> */}
            </div>
        </div>
    );
}
