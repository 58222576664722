import React, { useEffect, useState } from 'react'
import Lists from './Lists'
import ManifestService from '../../services/manifest/service';
import ManifestFormModal from './Form';
import { toast } from 'react-toastify';
import useDisclosure from '../../hooks/useDisclosure';
import { useMatch, useParams } from 'react-router-dom';
import DeletionConfirm from './DeletionConfirm';
import { getUniqueItemsByKey } from '../../utils';
import Filter from './Filter';
import ManifestTemplateComponent from '../detailExpeditorReceiver/ManifestTemplateComponent';
import PaginationComponent from '../paginationComponent/PaginationComponent';
import { PlusIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useAppContext } from '../../context';

export default function ManifestView({ onItemSelect, expedition, showTitle = true }) {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [totalItems, setTotalItems] = useState(0);
    const [perPageItems, setperPageItems] = useState(25);
    const [currentPage, setCurrentPage] = useState(0);
    const { isAuth, setIsAuth } = useAppContext();

    const { isOpen: isOpenTemplateModal, onClose: onCloseTemplateModal, onOpen: onOpenTemplateModal } = useDisclosure();
    const {
        isOpen: isDeleteConfirmOpen,
        onClose: onDeleteConfirmClose,
        onOpen: onDeleteConfirmOpen
    } = useDisclosure();

    const [data, setData] = useState({
        data: []
    })

    const [isLoading, setIsLoading] = useState(true);
    const [filter, setFilter] = useState({
        viavol: "",
        mawb: "",
        description: "",
        ex1: "",
        datemanifest: "",
    });

    const [page, setPage] = useState(1);
    const [selectedItem, setSelectedItem] = useState();

    const [editMode, setEditMode] = useState(false);


    useEffect(() => {
        setIsLoading(true)
        const fetchData = async () => {
            try {
                setIsLoading(true)
                const params = {
                    page,
                    type:isAuth?.spaceName,
                    per_page: parseInt(perPageItems),
                }

                const { data: resData } = await ManifestService.get(params);
                //console.log({ d: resData.data.data })
                if (resData.success) {
                    setData({
                        data: getUniqueItemsByKey([...resData.data.data], "id")
                    });
                    setTotalItems(parseInt(resData.data.total))
                }
                setIsLoading(false)
            } catch (error) {
                console.log(error)
                setIsLoading(false)
            } finally {
                setIsLoading(false)
            }
        }
        fetchData()
    }, [perPageItems, page])


    const onSubmit = (result) => {
        let items = [...data.data];
        const resultIndex = items.findIndex(i => i.id === result?.id);
        const isUpdate = resultIndex > -1
        if (isUpdate) {
            items[resultIndex] = result;
        } else {
            items = [result, ...items]
        }

        setData(d => ({
            ...d,
            data: items
        }))

        toast.success(`${isUpdate ? "Détails mis à jour" : "Données ajoutées"} avec succès!`, {
            position: "bottom-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    }

    const onItemSelected = (item, isEditable) => {
        if (onItemSelect) {
            onItemSelect(item)
        } else {
            if (isEditable) { setEditMode(true) }
            setSelectedItem(item)
            onOpen()
        }
    }

    const handleClose = () => {
        setSelectedItem(null)
        onClose()
        setEditMode(false)
    }

    const handleDeleteModalClose = () => {
        onDeleteConfirmClose()
    }

    const onDelete = (item) => {
        setSelectedItem(item);
        onDeleteConfirmOpen()
    }

    const onDeleted = (item) => {
        const items = [...data.data];
        const index = items.findIndex(i => i.id === item?.id);
        if (index > -1) {
            items.splice(index, 1);
            setData(d => ({
                ...d,
                data: items
            }))
        }
        onDeleteConfirmClose()
        setSelectedItem(undefined)
    }

    const onFilterChange = (key, value) => {
        //alert(`${key}---${value}`)
        setFilter(d => ({
            ...d,
            [key]: value
        }))
    }

    const handlePageClick = (event) => {

        setPage(parseInt(event))

        setCurrentPage(event);

    };

    const results = data.data.filter(d => {
        const { viavol, mawb, description, ex1, datemanifest } = filter;
        const viavolMatch = d?.viavol?.toLowerCase().includes(viavol.toLowerCase());
        const mawbMatch = d?.mawb?.toLowerCase().includes(mawb.toLowerCase());
        const descriptionMatch = d?.description?.toLowerCase().includes(description.toLowerCase());
        const ex1Match = d?.ex1?.toLowerCase().includes(ex1.toLowerCase());
        const dateMatch = d?.datemanifest?.toLowerCase().includes(datemanifest.toLowerCase());

        return viavolMatch && mawbMatch && descriptionMatch && ex1Match && dateMatch
    })

    return (
        <div className={"px-2 md:px-0"}>
            <div className="flex items-baseline justify-between">
                {showTitle ? <h4 className="uppercase page-title">Liste des Manifests</h4> : <div />}
                <div className="flex items-center">
                    <span
                        className={clsx({
                            "bg-paris": isAuth?.spaceName === "paris",
                            "bg-medical": isAuth?.spaceName === "medical",
                            "bg-service": isAuth?.spaceName === "service",
                            "bg-admin": isAuth?.spaceName === "admin",
                        }, "float-right  p-2 rounded-md")}
                        onClick={onOpen}
                    >
                        <PlusIcon className={"h-4 w-4 text-xl font-bold "} />
                    </span>

                    <button className={clsx({
                        "bg-paris": isAuth?.spaceName === "paris",
                        "bg-medical": isAuth?.spaceName === "medical",
                        "bg-service": isAuth?.spaceName === "service",
                        "bg-admin": isAuth?.spaceName === "admin",
                    }, "float-right ml-5 p-2 rounded-md ")}
                        onClick={onOpenTemplateModal}>Prévisualiser</button>
                </div>


            </div>
            <Filter value={filter} onChange={onFilterChange} />

            <div>
                <PaginationComponent
                    handlePageClick={handlePageClick}
                    totalItems={totalItems}
                    perPageItems={perPageItems}
                    setnumberPerPage={setperPageItems}
                    page={page}
                />
            </div>


            <Lists items={results} onSelect={onItemSelected} onRowSelect={onItemSelect} onDelete={onDelete} isLoading={isLoading} />

            <div>
                <PaginationComponent
                    element='pagination'
                    handlePageClick={handlePageClick}
                    totalItems={totalItems}
                    perPageItems={perPageItems}
                    setnumberPerPage={setperPageItems}
                    page={page}
                />
            </div>


            <ManifestFormModal
                onSubmit={onSubmit}
                selectedData={selectedItem}
                isOpen={isOpen}
                onClose={handleClose}
                title={selectedItem ? `Détails du Manifest ${selectedItem?.id}` : "Nouveau Manifest"}
                editable={editMode}
                type={isAuth?.spaceName}
            />
            <DeletionConfirm
                onSubmit={onDeleted}
                selectedData={selectedItem}
                isOpen={isDeleteConfirmOpen}
                onClose={handleDeleteModalClose}
                title={"Etes-vous sûr de vouloir supprimer ce manifest?"}
            />

            <ManifestTemplateComponent
                isOpenTemplateModal={isOpenTemplateModal}
                onCloseTemplateModal={onCloseTemplateModal}
                data={results}
                expedition={expedition}
            />
        </div>
    )
}
