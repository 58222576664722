"use client"
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "../services/user/UserService";
import { DashLoader } from "../components/loader/DashLoader";

const AppContext = createContext({});

export const ClientParisWrapper = ({ userRole, spaceName, children }) => {

    const [isAuth, setIsAuth] = useState({
        token: null,
        lastName: null,
        firstName: null,
        role: null,
        permissions: null,
        phone: null,
        email: null,
        image: null
    })

    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        let fetchClientData = async () => {

            try {
                const userServic = userService;

                userServic.getUserData().then(
                    (res) => {
                        const { status, data } = res;

                        let newRole = userRole === "client" ? "USER" : userRole.toUpperCase()
                        let role = `ROLE_${newRole}_${spaceName.toUpperCase()}`

                        if (status === 200 && (data?.roles?.includes(role) || data?.roles?.includes("ROLE_ADMIN"))) {
                            setIsLoading(false)
                            setIsAuth({ ...data,userRole: userRole, spaceName: spaceName })
                        }
                        else if (status === 302) {
                            userServic.refreshToken().then(
                                (response) => {
                                    const { status: refreshStatus, data: refreshData } = response;
                                    if (refreshStatus === 200 && refreshData.data?.roles?.includes(role)) {
                                       
                                        setIsAuth({ ...refreshData.data, userRole: userRole, spaceName: spaceName })
                                        setIsLoading(false)
                                    }
                                },
                                (error) => {
                                    navigate(`/${userRole}/${spaceName}/auth`)
                                    setIsLoading(false)
                                }
                            )
                        } else {
                            navigate(`/${userRole}/${spaceName}/auth`)
                            setIsLoading(false)
                        }
                    },
                    (error) => {
                        navigate(`/${userRole}/${spaceName}/auth`)
                        setIsLoading(false)
                    }
                )
            }
            catch (error) {
                // navigate(`/${userRole}/${spaceName}/auth`)
            }
        }
        fetchClientData()
    }, [])



    return (
        <AppContext.Provider value={{ isAuth, setIsAuth }}>
            {isLoading ? (
                <DashLoader />
            ) : (<>
                {children}
            </>
            )
            }

        </AppContext.Provider>
    )
}

export const AdminWrapper = ({ userRole, spaceName, children }) => {

    const [isAuth, setIsAuth] = useState({
        token: null,
        lastName: null,
        firstName: null,
        role: null,
        permissions: null,
        phone: null,
        email: null,
        image: null
    })

    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        let fetchClientData = async () => {

            try {
                const userServic = userService;

                userServic.getUserData().then(
                    (res) => {
                        const { status, data } = res;

                        let role = `ROLE_${userRole.toUpperCase()}`

                        if (status === 200 && (data?.roles?.includes(role) || data?.roles?.includes("ROLE_ADMIN")) ) {
                            setIsLoading(false)
                            setIsAuth({ ...data,userRole: userRole, spaceName: spaceName })
                        }
                        else if (status === 302) {
                            userServic.refreshToken().then(
                                (response) => {
                                    const { status: refreshStatus, data: refreshData } = response;
                                    if (refreshStatus === 200 && refreshData.data?.roles?.includes(role)) {
                                       
                                        setIsAuth({ ...refreshData.data, userRole: userRole, spaceName: spaceName })
                                        setIsLoading(false)
                                    }
                                },
                                (error) => {
                                    navigate(`/`)
                                    setIsLoading(false)
                                }
                            )
                        } else {
                            navigate(`/`)
                            setIsLoading(false)
                        }
                    },
                    (error) => {
                        navigate(`/`)
                        setIsLoading(false)
                    }
                )
            }
            catch (error) {
                navigate(`/`)
            }
        }
        fetchClientData()
    }, [])



    return (
        <AppContext.Provider value={{ isAuth, setIsAuth }}>
            {isLoading ? (
                <DashLoader />
            ) : (<>
                {children}
            </>
            )
            }

        </AppContext.Provider>
    )
}

export const useAppContext = () => {

    return useContext(AppContext)
}


