import React from 'react'
import { formatDefaultDate } from '../../utils';
import LoaderTableau from '../tableauComponent/LoaderTableau';
import { TableComponent } from '../TableauComponent';

export default function Lists({ items, onSelect, onRowSelect, onDelete, isLoading }) {

    return (
        <>

            <TableComponent withAction={true} headerList={tableHead} dataLength={items.length} isLoading={isLoading}>
                {items.map((item) => (
                    <tr key={item.id} className="py-2 cursor-pointer">
                        <td onClick={() => {
                            onSelect(item)
                        }}>{item.id}</td>
                        <td
                            onClick={() => {
                                onSelect?.(item)
                                onRowSelect?.(item)
                            }}
                        >{item.description}</td>
                        <td
                            onClick={() => {
                                onSelect?.(item)
                                onRowSelect?.(item)
                            }}
                        >{item.mawb}</td>
                        <td onClick={() => {
                            onSelect?.(item)
                            onRowSelect?.(item)
                        }}>{item.ex1}</td>
                        <td onClick={() => {
                            onSelect?.(item)
                            onRowSelect?.(item)
                        }}>{item.viavol}</td>
                        <td>{
                            formatDefaultDate(item.datemanifest)
                        }</td>
                        <td>{formatDefaultDate(item.created_at)}</td>
                        <td>
                            <div className="flex items-center">
                                <div
                                    className="text-green-600 cursor-pointer text-[14px] mr-5"
                                    onClick={() => {
                                        onSelect(item, true)
                                    }} title="Editer"
                                >
                                    <i className="fas fa-edit"></i>
                                </div>

                                <div
                                    className="text-red-500 cursor-pointer text-[14px]"
                                    onClick={() => {
                                        onDelete(item)
                                    }}
                                    title="Supprimer"
                                >
                                    <i className="fas fa-trash"></i>
                                </div>
                            </div>
                        </td>
                    </tr>
                ))}
            </TableComponent>
        </>

    )
}


const tableHead = [
    {
        filterKey: "",
        label: "#"
    },
    {
        filterKey: "description",
        label: "Description"
    },
    {
        filterKey: "mawb",
        label: "MAWB"
    },
    {
        filterKey: "ex1",
        label: "EX1"
    },
    {
        filterKey: "viavol",
        label: "VIAVOL"
    },
    {
        filterKey: "dateManifest",
        label: "DATE MANIFEST"
    },
    {
        filterKey: "ajouteLe",
        label: "AJOUTE LE"
    }
]
