import React from "react";
import { enteteContactData } from "../../../utils/ArrayHeaderData";
import LoaderTableau from "../../tableauComponent/LoaderTableau";
import { getCountryNameOrCode } from "../../../utils/const/countriesConstant";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';
import { urlSplit } from "../../../utils/Functions/otherFunctions";
import { ArrowDownIcon } from "@heroicons/react/20/solid";
import { ArrowUpIcon } from "@heroicons/react/24/outline";
import { TableComponent } from "../../TableauComponent";
import { ContactDataStore } from "../../../states/tableData";
import { NouvelTableau } from "../../NouvelTableau";

function ContactList({
    value,
    isLoading,
    isOpen,
    setmodalTitle,
    setmodalFormContent,
    handleDeleteAction,
    setIsNew
}) {

    const { exnetSpace } = urlSplit();
    const  dataList= ContactDataStore((state)=>state.dataList)


    const handleDelete = (identifiant) => {
        Swal.fire({
            title: "Êtes-vous sûr de vouloir supprimer ?",
            text: "Cette action est irréversible !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: exnetSpace === 'paris' ? "#ba9964" : (exnetSpace === 'service' ? "#098721" : (exnetSpace === 'medical' ? "#3A8EBA" : "#222")),
            confirmButtonText: "Oui, supprimer",
            cancelButtonText: "Annuler",
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteAction(identifiant);
            } else {
                Swal.fire(
                    "Annuler",
                    "Suppression annulée avec succès",
                    "error",
                    500
                );
            }
        });
    };

    const handleEdite = (identifiant) => {
        const data = dataList.find((element) => {
            return element.id === identifiant;
        });
        //console.log({ data })
        setmodalFormContent(data);
        setmodalTitle("Modifier un contact");
        isOpen(data);
    };

  


    return (
        <>

            <NouvelTableau TableDataStore={ContactDataStore} withAction={true} headerList={enteteContactData} dataLength={dataList.length} isLoading={isLoading}>
                {dataList.map((item, index) => (
                    <tr key={index}>
                        <td className="pointer " onClick={() =>
                            handleEdite(
                                item.id
                            )
                        }>{item.id}</td>
                        <td className="pointer " onClick={() =>
                            handleEdite(
                                item.id
                            )
                        }>{item.fullname || item?.firstName + " " + item?.lastName}</td>
                        <td className="pointer " onClick={() =>
                            handleEdite(
                                item.id
                            )
                        }>{item.email1}</td>
                        <td className="pointer " onClick={() =>
                            handleEdite(
                                item.id
                            )
                        }>
                            {item.telephone1}
                        </td>
                        <td className="pointer " onClick={() =>
                            handleEdite(
                                item.id
                            )
                        }>{item.address ?? item.address1}</td>
                        <td className="pointer " onClick={() =>
                            handleEdite(
                                item.id
                            )
                        }>
                            {item.postal_code}
                        </td>
                        <td className="pointer " onClick={() =>
                            handleEdite(
                                item.id
                            )
                        }>{item.city}</td>
                        <td className="pointer " onClick={() =>
                            handleEdite(
                                item.id
                            )
                        }>
                            <div
                                className={'flex items-center gap-3 flex-wrap'}
                            >
                                <span>
                                    <img
                                        src={`https://flagcdn.com/16x12/${getCountryNameOrCode(
                                            getCountryNameOrCode(item.country),
                                            true
                                        )}.png`}
                                        srcSet={`https://flagcdn.com/32x24/${getCountryNameOrCode(
                                            getCountryNameOrCode(item.country),
                                            true
                                        )}.png 2x,
           https://flagcdn.com/48x36/${getCountryNameOrCode(
                                            getCountryNameOrCode(item.country),
                                            true
                                        )}.png 3x`}
                                        width="16"
                                        height="12"
                                        alt={getCountryNameOrCode(
                                            getCountryNameOrCode(item.country),
                                            true
                                        )}
                                    />
                                </span>
                                <span> {getCountryNameOrCode(item.country)}</span>
                            </div>

                        </td>


                        <td>
                            <div className="flex items-center">
                                <div
                                    className="text-green-600 cursor-pointer text-[14px] mr-5"
                                    title="Editer"
                                    onClick={() => {
                                        setIsNew(false)
                                        handleEdite(
                                            item.id
                                        )
                                    }

                                    }
                                >
                                    <i className="fas fa-edit"></i>
                                </div>

                                <div
                                    className="text-red-500 cursor-pointer text-[14px]"
                                    onClick={() =>
                                        handleDelete(
                                            item.id
                                        )
                                    }
                                    title="Supprimer"
                                >
                                    <i className="fas fa-trash"></i>
                                </div>
                            </div>
                        </td>
                    </tr>
                ))}
            </NouvelTableau>


            {/* <div className="mb-5 overflow-auto ">
                <div className="inline-block min-w-full py-2 align-middle ">
                    <table className="w-full divide-y divide-gray-300">
                        <thead className={"bg-[#ECECEC]"}>
                            <tr className={" text-black "}>
                                <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3  text-base font-normalsm:pl-0 text-center hover:cursor-pointer "
                                >#</th>
                                {enteteContactData.map((item, index) => (
                                    <th

                                        key={item}
                                        scope="col"
                                        className="py-3.5 pl-4 pr-3  text-base font-normalsm:pl-0 text-center hover:cursor-pointer "
                                    >
                                        <div className={"flex items-center gap-1"}>
                                            {" "}
                                            {item.toUpperCase()}

                                        </div>
                                    </th>
                                ))}
                                <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3  text-base font-normalsm:pl-0 text-center hover:cursor-pointer "
                                ></th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {isLoading ? (
                                <>
                                    <tr>
                                        <td colSpan={enteteContactData.length}>
                                            <LoaderTableau />
                                        </td>
                                    </tr>
                                </>
                            ) : (
                                <>
                                    {contacts.length > 0 ? (
                                        <>
                                            {contacts.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="pointer " onClick={() =>
                                                        handleEdite(
                                                            item.id
                                                        )
                                                    }>{item.id}</td>
                                                    <td className="pointer " onClick={() =>
                                                        handleEdite(
                                                            item.id
                                                        )
                                                    }>{item.fullname || item?.firstName + " " + item?.lastName}</td>
                                                    <td className="pointer " onClick={() =>
                                                        handleEdite(
                                                            item.id
                                                        )
                                                    }>{item.email1}</td>
                                                    <td className="pointer " onClick={() =>
                                                        handleEdite(
                                                            item.id
                                                        )
                                                    }>
                                                        {item.telephone1}
                                                    </td>
                                                    <td className="pointer " onClick={() =>
                                                        handleEdite(
                                                            item.id
                                                        )
                                                    }>{item.address ?? item.address1}</td>
                                                    <td className="pointer " onClick={() =>
                                                        handleEdite(
                                                            item.id
                                                        )
                                                    }>
                                                        {item.postal_code}
                                                    </td>
                                                    <td className="pointer " onClick={() =>
                                                        handleEdite(
                                                            item.id
                                                        )
                                                    }>{item.city}</td>
                                                    <td className="pointer " onClick={() =>
                                                        handleEdite(
                                                            item.id
                                                        )
                                                    }>
                                                        <div
                                                            className={'flex items-center gap-3 flex-wrap'}
                                                        >
                                                            <span>
                                                                <img
                                                                    src={`https://flagcdn.com/16x12/${getCountryNameOrCode(
                                                                        getCountryNameOrCode(item.country),
                                                                        true
                                                                    )}.png`}
                                                                    srcSet={`https://flagcdn.com/32x24/${getCountryNameOrCode(
                                                                        getCountryNameOrCode(item.country),
                                                                        true
                                                                    )}.png 2x,
           https://flagcdn.com/48x36/${getCountryNameOrCode(
                                                                        getCountryNameOrCode(item.country),
                                                                        true
                                                                    )}.png 3x`}
                                                                    width="16"
                                                                    height="12"
                                                                    alt={getCountryNameOrCode(
                                                                        getCountryNameOrCode(item.country),
                                                                        true
                                                                    )}
                                                                />
                                                            </span>
                                                            <span> {getCountryNameOrCode(item.country)}</span>
                                                        </div>

                                                    </td>


                                                    <td>
                                                        <div className="flex items-center">
                                                            <div
                                                                className="text-green-600 cursor-pointer text-[14px] mr-5"
                                                                title="Editer"
                                                                onClick={() => {
                                                                    setIsNew(false)
                                                                    handleEdite(
                                                                        item.id
                                                                    )
                                                                }

                                                                }
                                                            >
                                                                <i className="fas fa-edit"></i>
                                                            </div>

                                                            <div
                                                                className="text-red-500 cursor-pointer text-[14px]"
                                                                onClick={() =>
                                                                    handleDelete(
                                                                        item.id
                                                                    )
                                                                }
                                                                title="Supprimer"
                                                            >
                                                                <i className="fas fa-trash"></i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    ) : (
                                        <tr>
                                            <td colSpan={enteteContactData.length}>
                                                <div classname={"py-4 rounded-lg bg-white "}>
                                                    <p
                                                        className={
                                                            "my-4 text-xl font-medium text-center text-black "
                                                        }
                                                    >
                                                        Vous n'aviez pas de contact pour le moment
                                                    </p>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </>
                            )}
                        </tbody>
                    </table>
                </div>
            </div> */}
        </>
    );
}

export default ContactList;
