import React, { useCallback, useEffect, useState } from "react";
import ModalUI from "../../Utils/Modal/Modal";
import { useBasicFormValues } from "../../../hooks/useFormValues";
import TransportTypesService from "../../../services/transportTypes/index";
import { validateTransportTypesData } from "../../../utils/validators";
import Loader from "../../Utils/Loader";
import { pick } from "lodash";
import { toast } from "react-toastify";

export default function CarFormModal({
    isOpen,
    onClose,
    onSubmit,
    selectedData,
}) {

    const initialValue = {
        label: "",
    }

    const {
        data: formData,
        setFormValue,
        setData,
    } = useBasicFormValues(initialValue);

    useEffect(() => {
        if (isOpen && selectedData) {
            //console.log({ selectedData })
            setData((d) => ({
                ...d,
                ...selectedData,
            }));
        } else {
            setData(initialValue);
        }
    }, [selectedData, isOpen]);

    //console.log({ formData })

    const [validationError, setValidationError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = useCallback(
        async (e) => {
            try {
                e.preventDefault();
                setValidationError(null);
                const { error: _err } = validateTransportTypesData(formData);

                if (_err) {
                    setValidationError(_err);
                    return;
                }
                setIsSubmitting(true);
                const submitData = pick(formData, [
                    "id",
                    "label",
                ]);
                const request = selectedData
                    ? TransportTypesService.update
                    : TransportTypesService.create;
                const { data } = await request(submitData);
                if (data.success) {
                    onClose();
                    onSubmit?.(selectedData ? formData : data.data);
                }
            } catch (error) {
                console.error(error);
                toast.error('Une erreur lors de la soumission de votre demande', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } finally {
                setIsSubmitting(false);
            }
        },
        [formData, onClose, onSubmit, selectedData]
    );

    return (
        <ModalUI
            isOpen={isOpen}
            onClose={onClose}
            title={selectedData ? "Modifier le type de transport" : "Créer un type de transport"}
        >
            {validationError && (
                <div className="bg-red-100 mt-4 text-red-600 px-4 py-2 rounded-md text-[13px]">
                    {validationError}
                </div>
            )}
            <form onSubmit={handleSubmit} className="p-3">
                <div className="grid gap-6 mb-3 md:grid-cols-2">
                    <div className="text-left">
                        <label
                            htmlFor="label"
                            className="block mb-2 text-sm font-medium text-gray-900"
                        >
                            Libellé
                        </label>
                        <input
                            type="text"
                            id="label"
                            placeholder=""
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-100 block w-full p-2"
                            required
                            value={formData.label}
                            onChange={(e) => setFormValue("label", e.target.value)}
                        />
                    </div>


                </div>
                <div className="flex justify-end mt-4">
                    <button
                        type="button"
                        onClick={() => onClose?.()}
                        className="bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-5"
                    >
                        Annuler
                    </button>
                    <button
                        type="submit"
                        className={`cursor-pointer text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm px-14 py-2.5 text-center`}
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                    //title={`${}`}
                    >
                        <span className="mr-3">
                            {!selectedData ? "Enregistrer" : "Modifier"}
                        </span>
                        {isSubmitting && <Loader />}
                    </button>
                </div>
            </form>
        </ModalUI>
    );
}
