import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { SwitchComponent } from '../../Utils/Index';
import ModalComponent from '../../Utils/Modal/Index';
import Form from './Form';
import useDisclosure from '../../../hooks/useDisclosure';

export const TableRow = ({ item }) => {
    const [template, setTemplate] = useState({})
    const { isOpen, onClose, onOpen } = useDisclosure();

    const onToggleActive = (item, type, value) => {
        //alert(item.id);

        let data = { type: type, value: value }
        let newValue = template;
        newValue[type] = !newValue[type] ? 1 : 0;
        setTemplate({ ...newValue });


    }

    useEffect(() => {
        setTemplate(item)
    }, [item])



    return (

        <>
            <ModalComponent isOpen={isOpen} onClose={onClose} title={'Détails de template tracking'}>
                <Form selectedValue={item} />
            </ModalComponent>

            <tr key={template?.id}>
                <td className="px-6 py-2 text-sm border-b border-gray-200 cursor-pointer" onClick={onOpen}>
                    {template?.id}
                </td>
                <td className="px-6 py-2 text-sm border-b border-gray-200 cursor-pointer" onClick={onOpen}>
                    {template?.details}
                </td>
                <td className="px-6 py-2 text-sm border-b border-gray-200">
                    <SwitchComponent
                        checked={Boolean(template?.isparis)}
                        disabled
                        onChange={(value) => {
                            //onToggleActive(template, 'isparis', value);
                        }}
                    />
                </td>
                <td className="px-6 py-2 text-sm border-b border-gray-200">
                    <SwitchComponent
                        checked={Boolean(template?.ismedical)}
                        onChange={(value) => {
                            // onToggleActive(template, 'ismedical', value);
                        }}
                    />
                </td>
                <td className="px-6 py-2 text-sm border-b border-gray-200">
                    <SwitchComponent
                        checked={Boolean(template.isservice)}
                        onChange={(value) => {
                            // onToggleActive(template, 'isservice', value);
                        }}
                    />
                </td>
                <td className="px-6 py-2 text-sm border-b border-gray-200">
                    <SwitchComponent
                        checked={Boolean(template.isdriver)}
                        onChange={(value) => {
                            // onToggleActive(template, 'isdriver', value);
                        }}
                    />
                </td>
                <td className="px-6 py-2 text-sm border-b border-gray-200 cursor-pointer" onClick={onOpen}>
                    {moment(template.created_at).format("DD-MM-YYYY")}{" "} <br />
                    {moment(template.created_at).format("HH:mm")}{" "}
                </td>
                {/* <td className="px-6 py-2 text-sm border-b border-gray-200">
                    <span
                        className="pointer"
                        onClick={onOpen}
                    >
                        <i className={`fa fa-edit mx-3 text-success`}></i>

                    </span>
                </td> */}
            </tr>
        </>

    );
}

