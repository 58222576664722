import { TbArrowsUpDown, TbDownload, TbEdit, TbMotorbike, TbTruckDelivery } from "react-icons/tb";
import { exnetSpaceColor, expeditionTypeByCountries, refPrefixer, returnExpeditionTypeLogo, splitText, urlSplit } from "../../utils/Functions/otherFunctions";
import moment from "moment";
import { getCountryNameOrCode } from "../../utils/const/countriesConstant";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useBasicFormValues } from "../../hooks/useFormValues";
import { useCallback, useEffect, useState } from "react";
import useDisclosure from "../../hooks/useDisclosure";
import LoaderTableau from "../tableauComponent/LoaderTableau";
import { AttributionComponent } from "../Tableaux/Expeditions/Lists";
import { AddTrackingModal } from "../tracking/Form";
import OptionsSelector from "../Utils/Selectors/Index";
import opsService from "../../services/ops/opsService";
import adminService from "../../services/admin/adminService";

const Adresse = ({ item }) => {
    return (
        <>
            <span> {splitText(item.expeditor.address1)}</span> <br />
            <span>{item.expeditor.city}</span>
            <br />
            <span>{item.expeditor.postalCode}</span>
            <div className={'flex items-center gap-3 flex-wrap'}>
                <span>
                    <img
                        src={`https://flagcdn.com/16x12/${getCountryNameOrCode(
                            getCountryNameOrCode(item?.expeditor?.country),
                            true
                        )}.png`}
                        srcSet={`https://flagcdn.com/32x24/${getCountryNameOrCode(
                            getCountryNameOrCode(item?.expeditor?.country),
                            true
                        )}.png 2x,
              https://flagcdn.com/48x36/${getCountryNameOrCode(
                            getCountryNameOrCode(item?.expeditor?.country),
                            true
                        )}.png 3x`}
                        width="16"
                        height="12"
                        alt={getCountryNameOrCode(item?.expeditor?.country)}
                    />
                </span>
                <span> {getCountryNameOrCode(item?.expeditor?.country)}</span>
            </div>
        </>
    )
}

const TableRowComponent = ({
    item: value,
    sortedArray,
    onAddTrackingClick,
    drivers
}) => {
    const navigate = useNavigate();
    const { exnetSpace } = urlSplit();
    //const [item, setItem] = useState({ ...value });
    const { data: item } = useBasicFormValues({ ...value })

    const redirectionUrl = `/exploitation/${exnetSpace}/courses/details/${value.ref}`

    return (
        <>
            <tr key={item?.ref} className="cursor-pointer text-uppercase tableRow">
                <td onClick={() => {
                    localStorage.setItem("expeditions", JSON.stringify(sortedArray));
                    navigate(redirectionUrl);
                }}>
                    <div style={{
                        color: exnetSpaceColor(exnetSpace)
                    }}>
                        {refPrefixer(item.type)}
                        {item.ref} <br />
                    </div>

                    <div
                        style={{
                            display: "flex",
                            gap: 1,
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {((item.type_expedition === "course" || item.type_expedition === "courses")) ?
                            <>
                                {(item.type_de_roue === "quatre_roues" || item.type_de_roue === "4") ?
                                    <>
                                        <TbTruckDelivery color={exnetSpaceColor(exnetSpace)} size={25} />
                                    </>
                                    :
                                    <>
                                        <TbMotorbike color={exnetSpaceColor(exnetSpace)} size={25} />
                                    </>
                                }

                            </>
                            :
                            <>
                                {expeditionTypeByCountries(
                                    item?.expeditor?.country,
                                    item?.receiver?.country
                                ) === "export" ? (
                                    <img src={returnExpeditionTypeLogo('export')} className={'mt-2 w-10'} alt="" />
                                ) : expeditionTypeByCountries(
                                    item?.expeditor?.country,
                                    item?.receiver?.country
                                ) === "import" ? (
                                    <img src={returnExpeditionTypeLogo('import')} className={'mt-2 w-10'} alt="" />
                                ) : (
                                    <img src={returnExpeditionTypeLogo('export')} className={'mt-2 w-10'} alt="" />
                                )}

                            </>
                        }
                    </div>
                </td>

                {/* REF INTERNE */}
                <td
                    className={"text-center"}
                    style={{
                        maxWidth: "50px",
                    }}
                    onClick={() => {
                        localStorage.setItem("expeditions", JSON.stringify(sortedArray));
                        navigate(redirectionUrl);
                    }}
                >
                    {item.customer_ref || "-"}
                </td>

                {/* EXPEDITEUR */}

                <td
                    style={{
                        maxWidth: "200px",
                    }}
                    onClick={() => {
                        localStorage.setItem("expeditions", JSON.stringify(sortedArray));
                        navigate(redirectionUrl);
                    }}
                >
                    {" "}
                    <span className={" font-bold"}>
                        {splitText(item.expeditor.fullname)}
                    </span>
                    <br />
                    <span>{splitText(item.expeditor.company)}</span>
                    <br />
                    <i className=" font-bold ">
                        {" "}
                        {moment(item.pickup_date).format("DD-MM-YYYY")}
                    </i>
                    <br />
                    <i className=" font-bold "> {item.pickup_time}</i>
                    <br />
                </td>

                {/* EXP ADRESS */}
                <td
                    onClick={() => {
                        localStorage.setItem("expeditions", JSON.stringify(sortedArray));
                        navigate(redirectionUrl);
                    }}
                >
                    <Adresse item={item} />
                </td>

                {/* DESTINATAIRE */}
                <td
                    style={{
                        backgroundColor: item.color ? item.color : "",
                        maxWidth: "200px",
                    }}
                    onClick={() => {
                        localStorage.setItem("expeditions", JSON.stringify(sortedArray));
                        navigate(redirectionUrl);
                    }}
                >
                    {" "}
                    <span className={"font-bold"}>
                        {splitText(item.receiver.fullname)}
                    </span>
                    <br />
                    <span>{item.receiver.company}</span> <br />
                    <i className="font-bold ">
                        {moment(item.delivery_date).format("DD-MM-YYYY")}{" "}
                    </i>
                    <br />
                    <i className="font-bold "> {item.delivery_time}</i>
                </td>

                {/* DESTINATAIRE ADRESS */}

                <td><Adresse item={item} /></td>

                {/* TRACKING */}
                <td
                    style={{
                        maxWidth: "70px",
                    }}>
                    {" "}
                    <div className="flex flex-col item-center">
                        <span className="mr-2">
                            <TbEdit
                                onClick={() => onAddTrackingClick(item)}
                                color={exnetSpaceColor(exnetSpace)}
                                className="text-xl"
                            />{" "}
                        </span>
                        <span>{item?.suivis?.length > 0 ? item.suivis[item?.suivis?.length - 1]['details'] : ''}</span>
                    </div>
                </td>

                {/* POD */}
                <td>
                    <TbDownload className="text-xl" color={exnetSpaceColor(item.type)} />
                </td>

                {/* ATTRIBUTIONS */}
                <td>
                    <AttributionComponent
                        shipping_id={item.id}
                        type={exnetSpace}
                        drivers={drivers}
                        value={item?.driver_id}
                        isDisabled={false}
                    />
                </td>

                {/* ACTIONS */}
                <td>
                    <ActionSelector value={item.remischauffeur === 1 ? "livraison" : item.recupererchauffeur === 1 ? "ramassage" : ""} />
                </td>
            </tr>
        </>
    );
}


const HEADERS = ["REF",
    "ref inter",
    "EXP",
    "adresse",
    "DEST",
    "adresse",
    "TRACKING",
    "POD",
    "ATTRIBUTION",
    "ACTION",]

export const Lists = ({ data, setItems, isLoading, type }) => {
    const { exnetSpace } = urlSplit()

    const initialState = {
        filterOrder: "asc",
        filterKey: "ref"
    }

    const [metadata, setMetadata] = useState(initialState)
    const [selectedItem, setSelectedItem] = useState();
    const [drivers, setDrivers] = useState([]);
    //const [driverPage, setDriverPage] = useState(1);

    const {
        isOpen: isAddTrackingModalOpen,
        onClose: onAddTrackingModalClose,
        onOpen: onAddTrackingModalOpen,
    } = useDisclosure();

    useEffect(() => {
        (async () => {
            try {
                const { data } = await adminService.getAllDrivers(exnetSpace)
                console.log({ data })
                if (data.success) {
                    setDrivers(
                        data.data.map((c) => {
                            return ({ label: c?.name, value: c.id })
                        })
                    );
                }
            } catch (error) {
                console.error(error)
            }
        })()
    }, [exnetSpace])

    const filterKey = (index) => {
        if (exnetSpace !== 'medical') {
            if (index === 0) {
                return 'ref';
            } else if (index === 1) {
                return 'pickup_time';
            } else if (index === 2) {
                return 'expeditor.fullname';
            }
            else if (index === 3) {
                return 'expeditor.address1';
            }
            else if (index === 4) {
                return 'expeditor.country';
            }
            else if (index === 5) {
                return 'receiver.fullname';
            } else if (index === 6) {
                return 'receiver.address1';
            }
            else if (index === 7) {
                return 'receiver.country';
            } else if (index === 8) {
                return 'suivis_details';
            }
        } else {

            if (index === 0) {
                return 'ref';
            }
            else if (index === 1) {
                return 'pickup_time';
            }
            else if (index === 2) {
                return 'expeditor.fullname';
            }
            else if (index === 3) {
                return 'code_donneur';
            }
            else if (index === 4) {
                return 'expeditor.address1';
            }
            else if (index === 5) {
                return 'expeditor.country';
            }
            else if (index === 6) {
                return 'receiver.fullname';
            }
            else if (index === 7) {
                return 'code_receveur';
            }
            else if (index === 8) {
                return 'receiver.address1';
            }
            else if (index === 9) {
                return 'receiver.country';
            }
            else if (index === 10) {
                return 'suivis_details';
            }
        }

    }

    const tableDataFilter = (index) => {
        setMetadata(m => ({
            ...m,
            filterOrder: m.filterOrder === 'desc' ? 'asc' : 'desc',
            filterKey: filterKey(index)
        }))
    }

    const sortedArray = data;

    const onAddTrackingSelected = (item) => {
        setSelectedItem(item);
        onAddTrackingModalOpen();
    };

    const onTrackingSubmited = useCallback(
        (tracking) => {
            //console.log({ selectedItem, tracking })
            setItems(_items => {
                const index = _items.findIndex(el => el.id === selectedItem?.id);
                console.log({ index, selectedItem })
                const newItems = _items.map((it) => it.id === selectedItem?.id ? ({
                    ...it,
                    suivis: [...it.suivis, tracking]
                }) : it)

                return newItems
            })
            onAddTrackingModalClose();
        },
        [selectedItem, setItems],
    )

    return (
        <div>
            <AddTrackingModal
                expedition={selectedItem}
                isOpen={isAddTrackingModalOpen}
                onClose={onAddTrackingModalClose}
                onSubmit={onTrackingSubmited}
                type={type}
                showComment={true}
            />
            <div className="card-box">
                <div className="card-block">
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <thead>
                                <tr>
                                    {HEADERS.map((item, index) => (
                                        <th key={index} onClick={() => tableDataFilter(index)} className="cursor-pointer">
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <span>
                                                    {item.toUpperCase()}
                                                </span>
                                                {(index !== HEADERS.length - 1) &&
                                                    <span>
                                                        <TbArrowsUpDown color={`${exnetSpaceColor(type)}`} />
                                                    </span>

                                                }
                                            </div>
                                        </th>
                                    ))}

                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    <tr key="">
                                        <td colSpan={HEADERS.length}>
                                            <LoaderTableau />
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        {sortedArray.length === 0 ? (
                                            <tr key="">
                                                <td className="px-6 py-2 text-lg text-center whitespace-no-wrap" colSpan={HEADERS.length}>

                                                    Pas d'expéditions pour le moment

                                                </td>
                                            </tr>
                                        ) : (
                                            <>
                                                {sortedArray.map((item, index) => {
                                                    return (
                                                        <TableRowComponent
                                                            item={item}
                                                            key={index}
                                                            onAddTrackingClick={onAddTrackingSelected}
                                                            drivers={drivers}
                                                        /* 
                              onAttributionSelect={onAttributionSelect}
                              checkboxVisible={checkboxVisible}
                              onSelect={onSelect}
                              onDispatchModalSelect={onDispatchModalSelect}
                              onAddTrackingClick={onAddTrackingClick}
                              isChecked={selectedShippingIds.includes(item.id)}
                              handleCheckboxChange={handleCheckboxChange}
                              sortedArray={sortedArray} */
                                                        />
                                                    );
                                                })}
                                            </>
                                        )}
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    )
}

const ActionSelector = ({ value, onChange }) => {
    const options = [
        { label: "Ramassage", value: "ramassage" },
        { label: "Livraison", value: "livraison" }
    ]
    return (
        <OptionsSelector
            options={options}
            value={value}
            onChange={onChange}
        />
    )
}