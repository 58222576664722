import React from 'react'
import DashLayout from '../dashboard/DashLayout'
import { Outlet } from 'react-router-dom';
import { AdminWrapper } from '../../context';
import { menuAdmin } from '../../core/menu/admin/_admin.menu';


export const AdminDashLayout = ({ userRole, spaceName }) => {
    return (
        <section className={'0'}>
            {/*  interfac = "client" type= "medical" */}
            <AdminWrapper userRole={userRole} spaceName={"admin"}>
                <DashLayout space={"admin"} navigation={menuAdmin}>
                    <Outlet />
                </DashLayout>
            </AdminWrapper >

        </section >
    )
}
