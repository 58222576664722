import React from "react";

export default function TexteareaInput({ label, value, onChange, ...props }) {
  return (
    <div>
      <div className="form-group">
        {label && (
          <label htmlFor="volume" className="mb-1 form-label">
            {label}
          </label>
        )}
        <textarea
          className={"px-2 focus:border-gray-300 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-gray-300  focus:ring-2 text-base sm:leading-6 h-auto border-gray-300 focus:ring-gray-300 overflow-auto "}
          //className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
          value={value}
          onChange={onChange}
          {...props}
        />
      </div>
    </div>
  );
}



