import React, { useEffect, useState } from "react";
import "./first.page.css";
import logoParis from "./../../assets/img/exnext-logo/paris.png";
import logoMedical from "./../../assets/img/exnext-logo/medical.png";
import logoService from "./../../assets/img/exnext-logo/service.png";
import { useNavigate, useParams } from "react-router-dom";

import backgroundAdmin from "../../assets/img/v1/18776165_6032877.jpg";
import BgNatureImg from "../../assets/img/nature.jpg";
import BgNatureImg3 from "../../assets/img/nature3.jpg";
import BgNatureImg4 from "../../assets/img/nature4.jpg";
import BgNatureImg5 from "../../assets/img/nature5.jpg";
import Head from "../../components/HeadComponent/Head";
import { roleTable, typeTable } from "../../utils/SpaceData";
import CookieBanner from "../../layout/Notification/CookieBanner";
import {isSelectedYears } from "../../utils/Functions/otherFunctions";
import DecemnerImg_1 from '../../assets/img/december/december_1.jpg'
import DecemnerImg_3 from '../../assets/img/december/december_3.jpg'
import DecemnerImg_4 from '../../assets/img/december/december_4.jpg'
import DecemnerImg_5 from '../../assets/img/december/december_5.jpg'
import DecemnerImg_6 from '../../assets/img/december/december_6.jpg'
import DecemnerImg_7 from '../../assets/img/december/december_7.jpg'
import DecemnerImg_8 from '../../assets/img/december/december_8.jpg'
import DecemnerImg_9 from '../../assets/img/december/december_9.jpg'
import DecemnerImg_10 from '../../assets/img/december/december_10.jpg'
import DecemnerImg_11 from '../../assets/img/december/december_11.jpg'
import NewYearImg_1 from '../../assets/img/newyears/newyers_1.jpeg'
import NewYearImg_2 from '../../assets/img/newyears/newyers_2.jpeg'
import NewYearImg_3 from '../../assets/img/newyears/newyers_3.jpeg'
import NewYearImg_4 from '../../assets/img/newyears/newyers_4.jpeg'
import NewYearImg_5 from '../../assets/img/newyears/newyers_5.jpeg'
import NewYearImg_6 from '../../assets/img/newyears/newyers_6.jpeg'
import NewYearImg_7 from '../../assets/img/newyears/newyers_7.jpeg'
import NewYearImg_8 from '../../assets/img/newyears/newyers_8.jpeg'
import { ButtonComponent } from "../../components/ButtonComponent";

const tableImg = [BgNatureImg, BgNatureImg3, BgNatureImg4, BgNatureImg5, backgroundAdmin];
const decemberImg = [DecemnerImg_1, DecemnerImg_3, DecemnerImg_4, DecemnerImg_5, DecemnerImg_6, DecemnerImg_7, DecemnerImg_8, DecemnerImg_9, DecemnerImg_10, DecemnerImg_11,];
const newYearsImage = [NewYearImg_1, NewYearImg_2, NewYearImg_3, NewYearImg_4, NewYearImg_5, NewYearImg_6, NewYearImg_7, NewYearImg_8];

// generate a random number between 0 and 3
const randomImage = () => {
    console.log(isSelectedYears(0), isSelectedYears(11))
    if (isSelectedYears(0)) {
        return newYearsImage[Math.floor(Math.random() * 7)];
    } else if (isSelectedYears(11)) {
        return decemberImg[Math.floor(Math.random() * 10)];
    }
    else {
        return tableImg[Math.floor(Math.random() * 3)];
    }
}



function FirstAuthPage() {
    const navigate = useNavigate();
    const { role, milieu } = useParams();
    const [backgroundState, setBackgroundState] = useState("");

    useEffect(() => {
        let lien = window.location.pathname.split("/");
        if (lien.includes("ops")) {
            setBackgroundState(randomImage());
        } else if (lien.includes("admin")) {
            setBackgroundState(randomImage());
        } else {
            setBackgroundState(randomImage());
        }

        if (!roleTable.includes(role) || !typeTable.includes(milieu)) {
            navigate("/not-found");
        }

    }, []);

    return (
        <>
            <Head espace={role === undefined ? "Client" : role}>
                <title>{`Exnet -${role === undefined ? "Client" : role
                    }  Homepage`}</title>
            </Head>

            <div
                className="flex items-center justify-center h-full bg-cover"
                style={{
                    backgroundImage: `url(${backgroundState})`,
                }}
            >
                <div className={"w-4/5 grid items-center justify-center  h-auto grid-cols-1 auth-bg md:grid-cols-3 justify-items-center pb-10 rounded-lg opacity-95 "}>

                    <div className="flex flex-col items-center content-center justify-center align-middle">
                        <img
                            className="w-6/12 my-10 img-responsive"
                            src={logoParis}
                            alt="logo"
                        />
                        <ButtonComponent
                            title={"Connexion"}
                            buttonClass="px-5 text-white bg-paris w-3/4  md:w-1/2"
                            onClick={() =>
                                navigate(
                                    role === "admin"
                                        ? "/admin/auth"
                                        : role === "ops" ||
                                            (milieu === "paris" && role === "ops")
                                            ? "/ops/paris/auth"
                                            : "/client/paris/auth"
                                )
                            } />

                    </div>
                    <div className="flex flex-col items-center content-center justify-center align-middle">
                        <img
                            className="w-6/12 my-10 img-responsive"
                            src={logoMedical}
                            alt="logo"
                        />

                        <ButtonComponent
                            title={"Connexion"}
                            buttonClass="px-5 text-white bg-medical  w-3/4  md:w-1/2"
                            onClick={() =>
                                navigate(
                                    role === "admin"
                                        ? "/admin/auth"
                                        : role === "ops" ||
                                            (milieu === "medical" && role === "ops")
                                            ? "/ops/medical/auth"
                                            : "/client/medical/auth"
                                )
                            }
                        />

                    </div>
                    <div className="flex flex-col items-center content-center justify-center align-middle xs:mb-5 md:mb-0 ">
                        <img
                            className="w-6/12 my-10 img-responsive"
                            src={logoService}
                            alt="logo"
                        />

                        <ButtonComponent
                            title={"Connexion"}
                            buttonClass="px-5 text-white bg-service w-3/4  md:w-1/2"
                            onClick={() =>
                                navigate(
                                    role === "admin"
                                        ? "/admin/auth"
                                        : role === "ops" ||
                                            (milieu === "service" && role === "ops")
                                            ? "/ops/service/auth"
                                            : "/client/service/auth"
                                )
                            }
                        />
                    </div>
                </div>

            </div>

            <CookieBanner />
        </>
    );
}

export default FirstAuthPage;
