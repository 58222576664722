import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ExpeditionService from '../../services/expedition/ExpeditionService'
import useQuery from '../../hooks/useQuery'
import Loader from '../../components/Utils/Loader'
import { getBase64Image } from '../../utils/base64'
import { formatDate, formatDefaultDate, formatShippingRef2 } from '../../utils'
import { format } from 'date-fns'

export default function TrackingSummary() {
    const { milieu } = useParams()
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    const { getParams } = useQuery()

    const shippingId = getParams("shipping_id")

    useEffect(() => {
        (async () => {
            try {
                const api = ExpeditionService
                const { data: resData } = await api.getShippingTrackingSummary({ shipping_id: shippingId });
                if (resData.success) {
                    setData(resData.data)
                }
            } catch (error) {

            } finally {
                setIsLoading(false)
            }
        })()
    }, [shippingId])

    console.log({ data })

    if (isLoading) {
        return <div className='h-screen w-full justify-center items-center'>
            <Loader />
        </div>
    }

    return (
        <div className='max-w-5xl mx-auto p-5 bg-gray-100'>
            {/*   <h1>TrackingSummary {milieu}</h1> */}
            {data &&
                <div className="d-flex g-0 mt-5">
                    <div style={{ borderRadius: '3px 0px -10px 3px', backgroundColor: '#eee' }}>
                        <i style={{ color: '#f2f2f2' }}>....</i>
                    </div>
                    <div className="ms-3">
                        <div className="row mb-3">
                            <div className="row mt-3">
                                <div className="row">
                                    <div className="col">
                                        {/* Image based on type */}
                                        <div className="row">
                                            <div className="col">
                                                <img src={getBase64Image(milieu)} alt="logo" className="img img-responsive h-20 md:h-28" />
                                            </div>
                                        </div>
                                        <p className='mt-3 text-base'>
                                            Veuillez trouver ci-après le suivi de votre expédition {formatShippingRef2(data.shipping.ref, milieu)}
                                        </p>
                                    </div>
                                </div>
                                <div className="row my-2 text-lg">
                                    <strong>
                                        <i className="fa fa-envelope mr-2" /> Numéro d'expédition {" "}
                                        {formatShippingRef2(data.shipping.ref, milieu)}
                                    </strong>
                                </div>


                                <div className="row mt-3">
                                    <div className="col">
                                        <div className="card border-0">
                                            <div className="relative overflow-x-auto">
                                                <div className="bg-white card-box">
                                                    <div className="card-block">
                                                        {/*                     <h4 className="card-title">Basic Table</h4>*/}
                                                        <div className="table-responsive">
                                                            <table className="table mb-0">
                                                                <thead>
                                                                    <tr className="text-left cursor-pointer">
                                                                        <th className="align-middle">EXPEDITEUR</th>
                                                                        <th className="align-middle">DESTINATAIRE</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr className="border border-gray-200">
                                                                        <td className="py-3 px-2">
                                                                            <span>{data.shipping.expeditor.company?.toUpperCase()}</span><br />
                                                                            <span>{data.shipping.expeditor.fullname?.toUpperCase()}</span><br />
                                                                            <span>{data.shipping.expeditor.address1?.toUpperCase()}</span><br />
                                                                            <span>{data.shipping.expeditor.address2?.toUpperCase()}</span><br />
                                                                            <span>{data.shipping.expeditor.postalCode?.toUpperCase()}</span><br />
                                                                            <span>{data.shipping.expeditor.city?.toUpperCase()}</span><br />
                                                                            <span>{data.shipping.expeditor.country?.toUpperCase()}</span>
                                                                        </td>
                                                                        <td className="py-3 px-2">
                                                                            <span>{data.shipping.receiver.company?.toUpperCase()}</span><br />
                                                                            <span>{data.shipping.receiver.fullname?.toUpperCase()}</span><br />
                                                                            <span>{data.shipping.receiver.address1?.toUpperCase()}</span><br />
                                                                            <span>{data.shipping.receiver.address2?.toUpperCase()}</span><br />
                                                                            <span>{data.shipping.receiver.postalCode?.toUpperCase()}</span><br />
                                                                            <span>{data.shipping.receiver.city?.toUpperCase()}</span><br />
                                                                            <span>{data.shipping.receiver.country?.toUpperCase()}</span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Tracking Section */}
                                <div className="row mt-3">
                                    <div className="col">
                                        <div className="card border-0">
                                            <div className="card-header me-3">
                                                <strong className="text-capitalize text-bold">SUIVI / TRACKING</strong>
                                            </div>
                                            <div className="relative overflow-x-auto">
                                                <div className="bg-white card-box">
                                                    <div className="card-block">
                                                        {/*                     <h4 className="card-title">Basic Table</h4>*/}
                                                        <div className="table-responsive">
                                                            <table className="table mb-0">
                                                                <thead>
                                                                    <tr className="text-left cursor-pointer">
                                                                        <th className="align-middle">AWB</th>
                                                                        <th className="align-middle">Date</th>
                                                                        <th className="align-middle">Heure</th>
                                                                        <th className="align-middle">Statut</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {data.allSuivi.map((suivi, index) => (
                                                                        <tr key={index} className="border border-gray-200">
                                                                            <td className="py-3 px-2">{data.shipping.ref}</td>
                                                                            <td className="py-3 px-2">{formatDefaultDate(suivi.date_suivi)}</td>
                                                                            <td className="py-3 px-2">{suivi.heur_suivi}</td>
                                                                            <td className="py-3 px-2">{suivi.details.toUpperCase()}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End of Tracking Section */}
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    )
}
