import React, { useEffect, useState } from "react";
import { InputComponent3 } from "../InputComponent/InputComponent3";
import clsx from "clsx";
import {
  exnetSpaceColor,
  urlSplit,
} from "../../utils/Functions/otherFunctions";
import Select from "react-select";
import adminService from "../../services/admin/adminService";
import { InformationUpdateValidator, InformationValidator } from "../../utils/validators";
import { toast } from "react-toastify";

const initState = {
  active_baniere: {
    label: "Afficher",
    value: 1,
  },
  with_image: {
    label: "Sans",
    value: 0,
  },
}
export const InfoForm = ({ action = "new", id = "" }) => {

  const [image, setImage] = useState("")
  const [errorInformation, setErrorInformation] = useState({
    field: "",
    message: "",
  });
  const [contentData, setContentData] = useState({ ...initState });
  const [isLoading, setIsLoading] = useState(true);
  const { exnetSpace, role } = urlSplit();

  const update = (cle, value) => {
    let data = { ...contentData };
    data[cle] = value;
    setContentData({ ...data });
    let errorMessage = {
      field: errorInformation.field === cle ? "" : errorInformation.field,
      message: errorInformation.field === cle ? "" : errorInformation.message,
    };
    setErrorInformation({ ...errorMessage });
  };

  const handleSubmit = async () => {

    const img = (contentData?.image_url && contentData?.with_image === 1) ? contentData?.image_url : (!contentData?.image_url && contentData?.with_image === 1 ? image : "");


    const { valid, errorData } = InformationUpdateValidator({ image_url: img, ...contentData });

    if (valid) {
      const toastsId = toast.loading("Modification en cours ...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      let conditionValue = contentData?.with_image?.value === 1 && contentData?.image_url && contentData?.image_url !== ""
      try {
        let newForm = new FormData();
        if (conditionValue) {

          newForm.append("contenus", contentData?.contenus);
          newForm.append("active_baniere", contentData?.active_baniere?.value);
          newForm.append("libele", contentData?.libele);
          newForm.append("image_url", contentData?.image_url);
          newForm.append("id", contentData?.id);
          newForm.append("with_image", 1);

        }

        adminService
          .UpdateInformation(
            conditionValue
              ? newForm
              : {
                ...contentData,
                active_baniere: contentData?.active_baniere?.value,
                with_image: contentData?.with_image?.value
              }
          )
          .then(
            (response) => {
              console.log(response);
              toast.update(toastsId, {
                render: "Enregistrement éffectué avec succès",
                type: "success",
                isLoading: false,
                autoClose: 1000,
                position: "top-center",
              });
              window.location.reload()
              setContentData({ ...initState });
            },
            (error) => {
              console.log(error);
              toast.update(toastsId, {
                render: "Impossible de faire la modification",
                type: "error",
                isLoading: false,
                autoClose: 1000,
                position: "top-center",
              });
            }
          );
      } catch (error) {
        console.log(error);
        toast.error("Impossible de faire la modification", {
          position: "top-center",
        });
      }
    } else {
      toast.error(errorData?.message, {
        position: "top-center",
      });
      setErrorInformation(errorData);
    }
  };

  const handleSaveSubmit = async () => {
    const { valid, errorData } = InformationValidator(contentData);

    if (valid) {
      const toastsId = toast.loading("Enregistrement  en cours ...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      try {
        let newForm = new FormData();
        if (contentData?.with_image?.value === 1) {
          newForm.append("contenus", contentData?.contenus);
          newForm.append("active_baniere", contentData?.active_baniere?.value);
          newForm.append("libele", contentData?.libele);
          newForm.append("image_url", contentData?.image_url);
          newForm.append("id", contentData?.id);
          newForm.append("with_image", 1);
        }

        let { with_image, image_url, ...newValue } = contentData;

        adminService
          .newInformation(
            contentData?.with_image?.value === 1
              ? newForm
              : {
                ...newValue,
                active_baniere: contentData?.active_baniere?.value,
                with_image: 0
              }
          )
          .then(
            (response) => {
              console.log(response);
              toast.update(toastsId, {
                render: "Enregistrement éffectué avec succès",
                type: "success",
                isLoading: false,
                autoClose: 1000,
                position: "top-center",
              });
              window.location.reload()
              setContentData({ ...initState });
            },
            (error) => {
              console.log(error);
              toast.update(toastsId, {
                render: "Impossible de faire la modification",
                type: "error",
                isLoading: false,
                autoClose: 1000,
                position: "top-center",
              });
            }
          );
      } catch (error) {
        console.log(error);
        toast.error("Impossible de faire la modification", {
          position: "top-center",
        });
      }
    } else {
      toast.error(errorData?.message, {
        position: "top-center",
      });
      setErrorInformation(errorData);
    }
  };

  useEffect(() => {
    const fecthInformaton = async () => {
      try {
        adminService.getInfo(id).then(
          (response) => {
            let { updated_at, created_at, image_url, ...other } =
              response.data.data;

            setImage(image_url)

            let status = stautsList.find(
              (item, index) => item.value === other?.active_baniere
            );

            let showPicture = ShowList.find((item, index) => item.value === other?.with_image);

            setContentData({ ...other, active_baniere: status, with_image: showPicture });
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) { }
    };

    if (action !== "new" && id !== "") {
      fecthInformaton();
    }
  }, [id, action]);

  return (
    <div>
      <section
        className={
          "mx-auto my-3 py-8 px-4 space-y-3 bg-zinc-200 opacity-90 rounded-lg"
        }
      >
        <h4 className={"text-xl text-center uppercase font-semibold"}>
          {action === "new" && id === ""
            ? " Création de bannière"
            : "Modification de bannière"}{" "}
          {id}
        </h4>

        <div className={"md:grid grid-cols-2 gap-3"}>
          <div>
            <label
              htmlFor={"titre"}
              className="block text-lg font-medium text-black"
            >
              Afficher Image
            </label>

            <Select
              className="w-full bg-transparent border border-gray-500 rounded-lg outline-none basic-single selection"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              isMulti={false}
              name="color"
              options={ShowList}
              placeholder={"Etat de bannière".toUpperCase()}
              value={contentData?.with_image}
              onChange={(e) => {
                update("with_image", e);
              }}
            />
          </div>
          {contentData?.with_image?.value === 1 && (
            <div>
              <label
                htmlFor={"titre"}
                className="block text-lg font-medium text-black"
              >
                Fichier
              </label>
              <InputComponent3
                key={"file"}
                type="file"
                label={"Fichier"}
                placeholder={"Fichier"}
                isError={errorInformation.field === "image_url"}
                errorMessage={errorInformation?.message}
                onChange={(e) => update("image_url", e)}
                accept={"image/*"}
              />
            </div>
          )}
        </div>

        <div className={"md:grid grid-cols-2 gap-3"}>
          <div>
            <label
              htmlFor={"titre"}
              className="block text-lg font-medium text-black"
            >
              Sujet
            </label>
            <InputComponent3
              key={"Sujet"}
              value={contentData?.libele}
              label={"Sujet"}
              placeholder={"Sujet"}
              isError={errorInformation.field === "libele"}
              errorMessage={errorInformation?.message}
              onChange={(e) => update("libele", e)}
            />
          </div>
          <div>
            <label
              htmlFor={"titre"}
              className="block text-lg font-medium text-black"
            >
              Etat de bannière
            </label>

            <Select
              className="w-full bg-transparent border border-gray-500 rounded-lg outline-none basic-single selection"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              isMulti={false}
              name="color"
              options={stautsList}
              placeholder={"Etat de bannière".toUpperCase()}
              value={contentData?.active_baniere}
              onChange={(e) => {
                update("active_baniere", e);
              }}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor={"titre"}
            className="block text-lg font-medium text-black"
          >
            Message
          </label>

          <div className="relative rounded-md">
            <textarea
              value={contentData?.contenus}
              onChange={(e) => update("contenus", e.target.value)}
              rows={5}
              cols={5}
              name="contenus"
              id="contenus"
              placeholder={"Message"}
              className={clsx(
                {
                  "text-red-500 border-red-500 ring-red-500 placeholder:text-red-500 focus:ring-red-500":
                    errorInformation.field === "contenus",
                  "ring-gray-300 focus:ring-gray-50 bg-white focus:ring-1 focus:ring-inset":
                    errorInformation.field !== "contenus",
                },
                "px-2 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-inset  focus:ring-2 focus:ring-inset text-base sm:leading-6 form-control h-[100px]"
              )}
            ></textarea>
            {errorInformation.field === "contenus" && (
              <p className="mt-2 text-sm text-red-600" id={"comment-error"}>
                {errorInformation?.message}
              </p>
            )}
          </div>
        </div>

        <div className="flex justify-center ">
          <button
            className="px-4 py-2 mx-auto rounded-lg w-150"
            onClick={
              action === "new" && id === "" ? handleSaveSubmit : handleSubmit
            }
            style={{
              color: "#fff",
              backgroundColor: `${exnetSpaceColor(exnetSpace)}`,
            }}
          >
            {action === "new" && id === "" ? "Enregistrer" : "Modifier"}
          </button>
        </div>
      </section>

      <section></section>
    </div>
  );
};

const stautsList = [
  {
    label: "Afficher",
    value: 1,
  },
  {
    label: "Cacher",
    value: 0,
  },
];

const ShowList = [
  {
    label: "Oui",
    value: 1,
  },
  {
    label: "Non",
    value: 0,
  },
];
