import React from 'react';
import Head from '../../components/HeadComponent/Head';
import { Link, useNavigate } from 'react-router-dom';
import './style.css'
import { Player } from "@lottiefiles/react-lottie-player";

function Page404(props) {
    const navigate = useNavigate()
    return (
        <>
            <Head espace={'paris'}>
                <title>Exnet - Page not Found</title>
            </Head>
            <div className="main-wrapper error-wrapper">
                <div className="error-box">
                    <Player
                        autoplay
                        loop
                        src="https://assets6.lottiefiles.com/packages/lf20_2ibpmsby.json"
                        style={{ width: "90%" }}
                    ></Player>
                    <h3><i className="fas fa-exclamation-triangle" /> Oops! Page Introuvable!</h3>
                    <p>La page demandée n'existe pas</p>

                    <span onClick={() => navigate(-2)} className="btn btn-primary go-home">Revenir à l'accueil</span>
                </div>
            </div>
        </>


    );
}

export default Page404;
