import React from 'react';
import ReactPaginate from "react-paginate";
import clsx from 'clsx';
import { useAppContext } from '../../context';

function PaginationComponent({ handlePageClick, totalItems, perPageItems, setnumberPerPage, page = 1, showPagination = true }) {
    const { isAuth, setIsAuth } = useAppContext();


    return (
        <>
            <div className="relative overflow-x-auto z-5">
                <div>
                    <span>Affichage de {(perPageItems * page) - perPageItems} à {(perPageItems * page)} sur {totalItems} éléments</span>
                </div>
                {showPagination && <div className={'space-y-3 md:space-y-0 md:flex md:justify-between md:items-center my-2'}>
                    <div>
                        <div className="flex items-center justify-between ">
                            <div className="relative w-[100px]">
                                <select
                                    onChange={(e) => setnumberPerPage(e.target.value)}
                                    id="example-select"
                                    className="block w-full px-4 py-2 pr-8 text-sm leading-tight bg-white border border-gray-300 shadow appearance-none hover:border-gray-400 focus:outline-none focus:shadow-outline"
                                >
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={75}>75</option>
                                    <option value={100}>100</option>
                                    <option value={250}>250</option>
                                    <option value={1000000}>Tous</option>
                                </select>
                                <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                    <svg
                                        className="w-4 h-4 fill-current"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"flex-1  "}>

                        <nav>
                            <div
                                className="flex w-full gap-2 mb-0 md:justify-end pagination "
                                listClassName="justify-end mb-0"
                            >
                                <ReactPaginate
                                    initialPage={0}
                                    forcePage={page - 1}
                                    breakLabel="..."
                                    nextLabel=">"
                                    nextClassName="border border-black bg-white rounded-md ml-4"
                                    nextLinkClassName="font-calibri text-lg text-black"
                                    onPageChange={(v) => {

                                        handlePageClick(v.selected + 1)
                                    }}
                                    pageRangeDisplayed={5}
                                    previousLabel="<"
                                    previousClassName="border border-black bg-white rounded-md mr-4"
                                    previousLinkClassName="font-calibri text-lg text-black"
                                    renderOnZeroPageCount={null}
                                    breakClassName={"break-me"}
                                    pageCount={Math.ceil(totalItems / parseInt(perPageItems))}
                                    marginPagesDisplayed={2}
                                    containerClassName={"pagination items-center flex gap-1"}
                                    subContainerClassName={"pages pagination text-white"}
                                    activeClassName={
                                        clsx({ "bg-paris": isAuth?.spaceName === "paris", "bg-medical": isAuth?.spaceName === "medical", "bg-service": isAuth?.spaceName === "service", "bg-admin text-white": isAuth?.userRole === "admin" }, `text-white flex items-center justify-center h-7 w-7 rounded-full`)

                                    }
                                    pageClassName={clsx({ "hover:bg-paris": isAuth?.spaceName === "paris", "hover:bg-medical": isAuth?.spaceName === "medical", "hover:bg-service": isAuth?.spaceName === "service", "hover:bg-admin ": isAuth?.spaceName === "admin" }, "hover:text-white hover:flex hover:items-center hover:justify-center hover:h-7 hover:w-7 hover:rounded-full")}
                                />
                            </div>
                        </nav>
                    </div>


                </div>}

            </div>

        </>

    );
}

export default PaginationComponent;