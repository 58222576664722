import React from 'react'
import DashLayout from '../dashboard/DashLayout'
import { Outlet } from 'react-router-dom';
import { ClientParisWrapper } from '../../context';
import { opsParisMenu } from '../../core/menu/ops/_paris.menu';
import { opsMedicalMenu } from '../../core/menu/ops/_medical.menu';
import { opsServiceMenu } from '../../core/menu/ops/_service.menu';

export const OpsDashLayout = ({ userRole, spaceName }) => {
    return (
        <section className={'0'}>
            {/*  interfac = "client" type= "medical" */}
            <ClientParisWrapper userRole={userRole} spaceName={spaceName}>
                <DashLayout space={spaceName} navigation={spaceName === "paris" ? opsParisMenu : (spaceName === "medical" ? opsMedicalMenu : opsServiceMenu)}>
                    <Outlet />
                </DashLayout>
            </ClientParisWrapper >

        </section >
    )
}
