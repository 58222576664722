import React, { useEffect, useState } from "react";
import TableauComponent from "../tableauComponent/tableau.component";
import { TableAdminEntete } from "../../utils/Functions/TableAdminEntete";
import { AdminTableDataController } from "../../controller/admin/adminTableDataController.js";
import { TableAdminDataConfig } from "../../utils/Functions/TableAdminDataConfig.js";
import TabsContactComponent from "../tabsContactComponent/TabsContactComponent.jsx";

function AdminListComponent({ dataType, title,secondTitle="", children }) {
    const [nbrPage, setNbrPage] = useState(1);
    const [nbrTotalPage, setNbrTotalPage] = useState(null);
    const [dataList, setDataList] = useState([]);
    const [URLPage, setURLPage] = useState("");

    useEffect(() => {
        setURLPage(window.location.pathname);
        setNbrPage(1);
        setNbrTotalPage(null);
        setDataList([]);
    }, [window.location.pathname]);

    useEffect(() => {
        let pathSegments = window.location.pathname.split("/");
        // if (nbrTotalPage === null || nbrPage < nbrTotalPage) {
        AdminTableDataController(dataType, nbrPage, pathSegments, "list")
            .then((result) => {
                if (result.success) {

                    setDataList([...dataList, ...result.data.data.data]);
                    if (parseInt(nbrPage) < parseInt(result.data.data.last_page)) {
                        setNbrPage(nbrPage + 1);
                    }

                    // setNbrTotalPage(result.data.data.last_page);
                } 
            })
            .catch((error) => {
                // Gérez les erreurs ici
                console.error(error);
            });
        // }
    }, [URLPage, nbrPage]);

    return (
        <div className="row">
            {dataType === "contacts" ? (
                <>
                    <TabsContactComponent
                        colonnes={TableAdminEntete(dataType)}
                        dataConfig={TableAdminDataConfig(dataType)}
                        type={"all"}
                        title={title}
                        dataType={dataType}
                    />
                </>
            ) : (
                <TableauComponent
                    colonnes={TableAdminEntete(dataType)}
                    valeurs={dataList}
                    title={title}
                    withFiltre={false}
                    parametreFiltre={[]}
                    expeditionKind={"contact"}
                    espace={"admin"}
                    role="admin"
                    secondTitle={secondTitle}
                    top={true}
                    children=""
                    dataConfig={TableAdminDataConfig(dataType)}
                    dataType={dataType}
                >
                    <>{children}</>
                </TableauComponent>
            )}
        </div>
    );
}

export default AdminListComponent;
