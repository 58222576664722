import { create } from 'zustand'

export const MenuStore = create((set) => ({
    activeMenu: {
        name: 'Tableau de Bord',
        tague: 'home',
    },
    setActiveMenu: (data) => set((state) => ({
        activeMenu: { ...data }
    })),
}))