import React, { useEffect, useState } from "react";
import DetailTabsComponent from "./tabsComponent/DetailTabsComponent";
import FichiersTabsComponent from "./tabsComponent/FichiersTabsComponent";
import EmailTabsComponent from "./tabsComponent/EmailTabsComponent";
import PodTabsComponent from "./tabsComponent/PodTabsComponent";
import CommentaireTabsComponent from "./tabsComponent/CommentaireTabsComponent";
import FactureTabsComponent from "./tabsComponent/FactureTabsComponent";
import Head from "../HeadComponent/Head";
import ClientExpeditionDetailComponent from "./client/clientExpeditionDetailComponent";
import AddTrackingComponent from "../tracking/AddTrackingComponent";
import ExpeditionService from "../../services/expedition/ExpeditionService.js";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { refPrefixer, replaceLastValueInPath, urlSplit } from "../../utils/Functions/otherFunctions";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setExpedition } from "../../features/slices/expeditionSlice";
import Manifest from "./tabsComponent/Manifest";
import LoaderTableau from "../tableauComponent/LoaderTableau";
import { formatShippingRef } from "../../utils/index.js";
import useAuthorization from "../../hooks/useAuthorization.jsx";
import clsx from "clsx";
import { useAppContext } from "../../context/index.jsx";
import { ShippingDetails } from "../../store/shippingDetails.js";

function DetailsColisComponent({ expeditionId, expeditionSpace }) {
  const expeditionStore = useSelector(
    (state) => state.expedition.allExpedition
  );

  const expeditionDetails = useSelector(
    (state) => state.expedition.expeditions
  );

  const { identifiant } = useParams()

  const dispatch = useDispatch();
  const [role, setRole] = useState("");
  const [expeditionData, setExpeditionData] = useState({});
  const [expeditionList, setexpeditionList] = useState([]);
  const { pathname } = useLocation();
  const [search] = useSearchParams()
  const navigate = useNavigate();
  const { exnetSpace } = urlSplit()
  const [isLoading, setIsLoading] = useState(false)
  const setDetails = ShippingDetails((state) => state.setDetails);


  /*   useEffect(() => {
      const isAvoir = search.get("isAvoir");
      console.log({ pathname, search, isAvoir })
      if (isAvoir) {
        //alert("A")
        const a = document.createElement("a");
        a.href = `${pathname}#tabsFacturation`;
  
        document.body.appendChild(a);
        a.click();
  
      }
    }, [pathname, search]) */



  const findPreviousAndNextElement = (id) => {
    const index = expeditionStore.findIndex((element) => element.ref === id);

    if (index === -1) {
      return null; // ID non trouvé dans le tableau
    }

    const previousIndex = index > 0 ? index - 1 : null;
    const nextIndex = index < expeditionStore.length - 1 ? index + 1 : null;

    const previousElement = previousIndex !== null ? expeditionStore[previousIndex].ref : null;
    const nextElement = nextIndex !== null ? expeditionStore[nextIndex].ref : null;

    return { previousElement, nextElement };
  }


  const handleNext = () => {

    let result = findPreviousAndNextElement(parseInt(expeditionId))
    if (result?.nextElement) {
      navigate(replaceLastValueInPath(pathname, result?.nextElement));
    } else {
      navigate(replaceLastValueInPath(pathname, expeditionId));
    }

  };

  const handlePrevious = () => {
    let result = findPreviousAndNextElement(parseInt(expeditionId))
    if (result?.previousElement) {
      navigate(replaceLastValueInPath(pathname, result?.previousElement));
    } else {
      navigate(replaceLastValueInPath(pathname, expeditionId));
    }
  };

  const [suffixeIndex, setSuffixeIndex] = useState("");
  const [title, setTitle] = useState(`Détail ${refPrefixer(exnetSpace)}${expeditionId}`);
  const expeditionRef = expeditionDetails?.billing_center ? formatShippingRef(expeditionDetails.ref, expeditionDetails.billing_center.type) : "-"

  function changeTitle(titleChange) {
    setTitle(`${titleChange} ${refPrefixer(exnetSpace)}${expeditionId}`);
  }


  const showFactureError = (e) => {
    e.preventDefault();
    toast.error("Votre expédition est toujours en cours d'expédition", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };



  useEffect(() => {
    setIsLoading(true)
    let element = document.getElementById("bloquer");

    if (element) {
      if (expeditionDetails.pod?.length > 0 && !expeditionDetails.annulee) {
        element?.removeAttribute("disabled");
      } else {
        element?.setAttribute("disabled", true);
      }
    }

    let determinant =
      expeditionSpace === "paris"
        ? "EX"
        : expeditionSpace === "medical"
          ? "EM"
          : "ES";
    setSuffixeIndex(determinant);
    setRole(window.location.pathname.split("/")[1]);
    document.getElementById('fistLink').click();
    changeTitle('Detail')


    setTimeout(() => {
      document.querySelector("#progrss-wizard ul")?.removeAttribute("style");
    }, 500);


  }, []);

  useEffect(() => {
    setIsLoading(true)
    if (pathname && pathname.indexOf(expeditionId)) {
      if (expeditionId === identifiant) {

        ExpeditionService.getExpeditionDetails({ ref: expeditionId }).then(
          (data) => {
            const { data: expedition } = data.data;
            dispatch(setExpedition(expedition));
            setExpeditionData({ ...data.data.data });
            setDetails(data.data.data)
            setIsLoading(false)
          },
          (error) => {
            setIsLoading(false)
            console.log(error);
          }
        );
      }
    }
  }, [expeditionId])


  const { isShippingTabAllowed } = useAuthorization()
  const [activeTabs, setActiveTabs] = useState("tabsDetail");
  const { isAuth, setIsAuth } = useAppContext();

  const tabsClick = (tabsValue, title) => {
    setActiveTabs(tabsValue);
    changeTitle(title);
  }


  return (
    <>
      <Head>
        <title>EXNET | {title} </title>
      </Head>
      {role === "client" ? (
        <ClientExpeditionDetailComponent />
      ) : (
        <>
          <div className="">
            <div id="progrss-wizard" className="twitter-bs-wizard">
              {/* <div className="w-full my-2">
                <div className="relative flex items-center justify-between w-full gap-4 px-3 py-1 overflow-auto bg-white rounded-md">
                  <div className="absolute inset-0 flex items-center justify-between w-full">
                    <div className="w-full border-t-2 border-gray-300"></div>
                  </div>

                  <div className="relative z-10 flex items-center">
                    <div className="px-3 py-2 text-white rounded-full bg-paris">
                      <a href="#" className="nav-link" onClick={handlePrevious}>
                        <i className="text-white fas fa-arrow-left" />
                      </a>
                    </div>
                  </div>

                  <div className="relative z-10 flex items-center">
                    <div className="px-3 py-2 text-white rounded-full bg-paris">
                      <a
                        id="fistLink"
                        href="#tabsDetail"
                        className="nav-link active"
                        onClick={() => changeTitle("Details")}
                      >
                        <i className="far fa-newspaper" />
                      </a>
                    </div>
                  </div>

                  {window.location.pathname.split("/")[4] !== "chauffeur" && (
                    <>
                      <div className="relative z-10 flex items-center">
                        <div className="px-3 py-2 text-white rounded-full bg-paris">
                          <a href="#tabsFichiers" onClick={() => changeTitle("Fichiers")}>
                            <i className="fas fa-file" />
                          </a>
                        </div>
                      </div>

                      <div className="relative z-10 flex items-center">
                        <div className="px-3 py-2 text-white rounded-full bg-paris">
                          <a href="#tabsManifest" onClick={() => changeTitle("Manifest")}>
                            <i className="fas fa-solid fa-vector-square" />
                          </a>
                        </div>
                      </div>

                      <div className="relative z-10 flex items-center">
                        <div className="px-3 py-2 text-white rounded-full bg-paris">
                          <a href="#tabsEmail" onClick={() => changeTitle("Mails")}>
                            <i className="fas fa-envelope" />
                          </a>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="relative z-10 flex items-center">
                    <div className="px-3 py-2 text-white rounded-full bg-paris">
                      <a href="#tabsTracking" onClick={() => changeTitle("Trackings")}>
                        <i className="far fa-map" />
                      </a>
                    </div>
                  </div>

                  <div className="relative z-10 flex items-center">
                    <div className="px-3 py-2 text-white rounded-full bg-paris">
                      <a href="#tabsPod" onClick={() => changeTitle("Pod")}>
                        <i className="fas fa-map-pin" />
                      </a>
                    </div>
                  </div>

                  {window.location.pathname.split("/")[4] !== "chauffeur" && (
                    <>
                      <div className="relative z-10 flex items-center">
                        <div className="px-3 py-2 text-white rounded-full bg-paris">
                          <a href="#tabsCommentaire" onClick={() => changeTitle("Commentaires")}>
                            <i className="fas fa-comment" />
                          </a>
                        </div>
                      </div>

                      <div className="relative z-10 flex items-center">
                        <div className="px-3 py-2 text-white rounded-full bg-paris">
                          <a
                            href={
                              expeditionDetails?.pod?.length > 0 && expeditionDetails?.annulee === 0
                                ? "#tabsFacturation"
                                : "#"
                            }
                            onClick={(e) => {
                              if (expeditionDetails?.pod?.length > 0 && expeditionDetails.annulee == 0) {
                                changeTitle("Facturations");
                              } else {
                                showFactureError(e);
                              }
                            }}
                          >
                            <i className="fas fa-euro-sign" />
                          </a>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="relative z-10 flex items-center">
                    <div className="px-3 py-2 text-white rounded-full bg-paris">
                      <a href="#" onClick={handleNext}>
                        <i className="fas fa-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}


              <div className={"bg-white rounded-md gap-4 overflow-auto w-full px-3 py-1"}>
                <ul className="flex justify-between w-full my-2">
                  <li className={
                    clsx({
                      "bg-paris": isAuth?.spaceName === "paris",
                      "bg-medical": isAuth?.spaceName === "medical",
                      "bg-service": isAuth?.spaceName === "service",
                    }, "px-3 py-2 mx-8 text-white rounded-full"
                    )}
                    onClick={handlePrevious}
                  >
                    <a
                      href={'#'}
                      className="nav-link"

                    >
                      <div
                        className="step-icon"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="précédent"
                      >
                        <i className="text-white fas fa-arrow-left" />
                      </div>
                    </a>
                  </li>
                  <li className={
                    clsx({
                      "bg-paris": isAuth?.spaceName === "paris",
                      "bg-medical": isAuth?.spaceName === "medical",
                      "bg-service": isAuth?.spaceName === "service",
                    }, "px-3 py-2 mx-8 text-white rounded-full"
                    )}
                    onClick={() => tabsClick("tabsDetail", "Details")
                    }
                  >
                    <a
                      id={"fistLink"}
                      href="#tabsDetail"
                      className="nav-link active"
                      data-bs-toggle="tab"

                    >
                      <div
                        className="step-icon"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Details Expédition"
                      >
                        <i className={clsx({ 'text-white': activeTabs === "tabsDetail" }, "far fa-newspaper text-xl")} />
                      </div>
                    </a>
                  </li>
                  {window.location.pathname.split("/")[4] !== "chauffeur" ? (
                    <li className={
                      clsx({
                        "bg-paris": isAuth?.spaceName === "paris",
                        "bg-medical": isAuth?.spaceName === "medical",
                        "bg-service": isAuth?.spaceName === "service",
                      }, "px-3 py-2 mx-8 text-white rounded-full"
                      )}

                      onClick={() => tabsClick("tabsFichiers", "Fichiers")}
                    >
                      <a
                        href="#tabsFichiers"
                        className="nav-link"
                        data-bs-toggle="tab"

                      >
                        <div
                          className="step-icon"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Fichiers"
                        >
                          <i className={clsx({ 'text-white': activeTabs === "tabsFichiers" }, "fas fa-file text-xl")} />
                        </div>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {window.location.pathname.split("/")[4] !== "chauffeur" ? (
                    <li className={
                      clsx({
                        "bg-paris": isAuth?.spaceName === "paris",
                        "bg-medical": isAuth?.spaceName === "medical",
                        "bg-service": isAuth?.spaceName === "service",
                      }, "px-3 py-2 mx-8 text-white rounded-full"
                      )}
                      onClick={() => tabsClick("tabsManifest", "Manifest")}
                    >
                      <a
                        href="#tabsManifest"
                        className="nav-link"
                        data-bs-toggle="tab"

                      >
                        <div
                          className="step-icon"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Manifest"
                        >
                          <i className={clsx({ 'text-white': activeTabs === "tabsManifest" }, "fas fa-solid fa-vector-square text-xl")}></i>
                        </div>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {window.location.pathname.split("/")[4] !== "chauffeur" ? (
                    <li className={
                      clsx({
                        "bg-paris": isAuth?.spaceName === "paris",
                        "bg-medical": isAuth?.spaceName === "medical",
                        "bg-service": isAuth?.spaceName === "service",
                      }, "px-3 py-2 mx-8 text-white rounded-full"
                      )}
                      onClick={() => tabsClick("tabsEmail", "Mails")}
                    >
                      <a
                        href="#tabsEmail"
                        className="nav-link"
                        data-bs-toggle="tab"

                      >
                        <div
                          className="step-icon"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Mails"
                        >
                          <i className={clsx({ 'text-white': activeTabs === "tabsEmail" }, "fas fa-envelope text-xl")} />
                        </div>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  <li className={
                    clsx({
                      "bg-paris": isAuth?.spaceName === "paris",
                      "bg-medical": isAuth?.spaceName === "medical",
                      "bg-service": isAuth?.spaceName === "service",
                    }, "px-3 py-2 mx-8 text-white rounded-full"
                    )}
                    onClick={() => tabsClick("tabsTracking", "Trackings")}
                  >
                    <a
                      href="#tabsTracking"
                      className="nav-link"
                      data-bs-toggle="tab"

                    >
                      <div
                        className="step-icon"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Trackings"
                      >
                        <i className={clsx({ 'text-white': activeTabs === "tabsTracking" }, "far fa-map ")} />
                      </div>
                    </a>
                  </li>
                  <li className={
                    clsx({
                      "bg-paris": isAuth?.spaceName === "paris",
                      "bg-medical": isAuth?.spaceName === "medical",
                      "bg-service": isAuth?.spaceName === "service",
                    }, "px-3 py-2 mx-8 text-white rounded-full"
                    )}
                    onClick={() => tabsClick("tabsPod", "Pod")}
                  >
                    <a
                      href="#tabsPod"
                      className="nav-link"
                      data-bs-toggle="tab"

                    >
                      <div
                        className="step-icon"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Pod"
                      >
                        <i className={clsx({ 'text-white': activeTabs === "tabsPod" }, "fas fa-map-pin text-xl")} />
                      </div>
                    </a>
                  </li>
                  {window.location.pathname.split("/")[4] !== "chauffeur" ? (
                    <li className={
                      clsx({
                        "bg-paris": isAuth?.spaceName === "paris",
                        "bg-medical": isAuth?.spaceName === "medical",
                        "bg-service": isAuth?.spaceName === "service",
                      }, "px-3 py-2 mx-8 text-white rounded-full"
                      )}
                      onClick={() => tabsClick("tabsCommentaire", "Commentaires")}
                    >
                      <a
                        href="#tabsCommentaire"
                        className="nav-link"
                        data-bs-toggle="tab"

                      >
                        <div
                          className="step-icon"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Commentaires"
                        >
                          <i className={clsx({ 'text-white': activeTabs === "tabsCommentaire" }, "fas fa-comment text-xl")} />
                        </div>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {window.location.pathname.split("/")[4] !== "chauffeur" ? (
                    <li className={
                      clsx({
                        "bg-paris": isAuth?.spaceName === "paris",
                        "bg-medical": isAuth?.spaceName === "medical",
                        "bg-service": isAuth?.spaceName === "service",
                      }, "px-3 py-2 mx-8 text-white rounded-full"
                      )}
                      onClick={(e) => {
                        if (
                          expeditionDetails?.pod?.length > 0 &&
                          expeditionDetails.annulee === 0
                        ) {
                          tabsClick("tabsFacturation", "Facturations");
                        } else {
                          showFactureError(e);
                        }
                      }}
                    >
                      <a
                        disabled={
                          expeditionDetails?.pod?.length > 0 &&
                            expeditionDetails?.annulee === 0
                            ? false
                            : true
                        }
                        href={`${expeditionDetails?.pod?.length > 0 &&
                          expeditionDetails?.annulee === 0
                          ? "#tabsFacturation"
                          : ""
                          }`}
                        className="nav-link"
                        data-bs-toggle="tab"

                      >
                        <div
                          className="step-icon"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Facturations"
                        >
                          <i className={clsx({ 'text-white': activeTabs === "tabsFacturation" }, "fas fa-euro-sign text-xl")} />
                        </div>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  <li className={
                    clsx({
                      "bg-paris": isAuth?.spaceName === "paris",
                      "bg-medical": isAuth?.spaceName === "medical",
                      "bg-service": isAuth?.spaceName === "service",
                    }, "px-3 py-2 mx-8 text-white rounded-full"
                    )}
                    onClick={handleNext}
                  >
                    <a
                      href={"#"}
                      className="nav-link"


                    >
                      <div
                        className="step-icon"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Suivant"
                      >
                        <i className="text-xl fas fa-arrow-right" />
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

              <div id="bar" className="mt-4 progress" style={{ height: "30px" }}>
                <span
                  style={{
                    fontSize: "20px",
                    textAlign: "center",
                    display: "block",
                    width: "100%",
                  }}
                >
                  {/* Détails {expeditionRef} */}
                  {title}
                </span>
                <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"></div>
              </div>
              {isLoading ? <>
                {/* <div className="grid grid-cols-2 gap-2">
                  <div>
                    <LoaderCard />
                  </div>
                  <div>
                    <LoaderCard />
                  </div>
                  <div className="h-full col-span-2">
                    <LoaderCard />
                  </div>

                </div> */}
                <div>
                  <LoaderTableau />
                </div>

              </> :
                <>
                  {(expeditionData.id) ? (
                    <div className="tab-content twitter-bs-wizard-tab-content">
                      {activeTabs === "tabsDetail" &&
                        <div className="tab-pane active" id="tabsDetail">
                          {isShippingTabAllowed.details &&
                            <DetailTabsComponent
                              expedition={expeditionData}
                              setExpeditionData={setExpeditionData}
                            />}
                        </div>
                      }

                      {activeTabs === "tabsFichiers" && <>
                        {isShippingTabAllowed.fichiers && <div className="tab-pane" id="tabsFichiers">
                          <FichiersTabsComponent expedition={expeditionData} />
                        </div>}
                      </>}

                      {activeTabs === "tabsEmail" && <>
                        {isShippingTabAllowed.mails && <div className="tab-pane" id="tabsEmail">
                          <EmailTabsComponent expedition={expeditionData} />
                        </div>}
                      </>}

                      {activeTabs === "tabsManifest" && <>
                        {isShippingTabAllowed.manifest && <div className="tab-pane" id="tabsManifest">
                          <Manifest expedition={expeditionData} />
                        </div>}
                      </>}

                      {activeTabs === "tabsTracking" && <>
                        {isShippingTabAllowed.tracking && <div className="tab-pane" id="tabsTracking">
                          <AddTrackingComponent expedition={expeditionData} setExpeditionData={setExpeditionData} />
                        </div>}
                      </>}

                      {activeTabs === "tabsPod" && <>
                        {isShippingTabAllowed.pod && <div className="tab-pane" id="tabsPod">
                          <PodTabsComponent shipping_id={expeditionData.id} />
                        </div>}
                      </>}

                      {activeTabs === "tabsCommentaire" && <>
                        {isShippingTabAllowed.comments && <div className="tab-pane" id="tabsCommentaire">
                          <CommentaireTabsComponent expedition={expeditionData} />
                        </div>}
                      </>}

                      {activeTabs === "tabsFacturation" && <>
                        {isShippingTabAllowed.facturations && <div className="tab-pane" id="tabsFacturation">
                          <FactureTabsComponent />
                        </div>}
                      </>}

                    </div>
                 
                  ) : (
                    <LoaderTableau />
                  )}</>}

            </div>
          </div>
        </>

      )
      }
    </>
  );
}

export default DetailsColisComponent;
