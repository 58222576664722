import React, { useState } from "react";
import CountriesSelector from "../Utils/Selectors/CountriesSelector";
import InputComponent from "../InputComponent/InputComponent";
import { ButtonComponent } from "../ButtonComponent";
import { ErrorStore } from "../../store/inputError";
import { ExpeditionStore } from "../../store/expedition";

export const ShippingProforma = () => {

  const { proformaList, proformaHeader } = ExpeditionStore((state) => state.proforma);
  const updateProformaList = ExpeditionStore((state) => state.updateProformaList);
  const updateProformaHeader = ExpeditionStore((state) => state.updateProformaHeader);
  const addProforma = ExpeditionStore((state) => state.addProforma);
  const removeProforma = ExpeditionStore((state) => state.removeProforma);
  const errorMessage = ErrorStore((state) => state.errorField);
  const setMessageError = ErrorStore((state) => state.setMessageError);



  return (
    <div className={""}>
      <div className={"card-body"}>
        <div className={"m-4"}>
          <div className="grid-cols-3 gap-2 space-y-2 md:grid md:space-y-0 ">
            <div className="col-md-4">
              {" "}
              <div className="form-group">
                <select
                  placeholder={"Nature de la proforma".toUpperCase()}
                  className="w-full bg-white border border-gray-500 rounded-lg outline-none form-select form-control"
                  value={proformaHeader?.nature}
                  onChange={(e) =>
                    updateProformaHeader("nature", e.target.value)
                  }
                >
                  <option disabled>Nature du proforma</option>
                  <option value="  Send for cleaning" key="">
                    Send for cleaning
                  </option>
                  <option value="Send to be restored" key="">
                    Send to be restored
                  </option>
                  <option value="Send back after repair" key="">
                    Send back after repair
                  </option>
                  <option value="Send back after cleaning" key="">
                    Send back after cleaning
                  </option>
                  <option value="returns / after Sales services" key="">
                    returns / After Sales services
                  </option>
                </select>
              </div>
              {errorMessage.field === 'nature' &&
                <p className="mt-2 text-sm text-red-600" id={"nature-error"}>
                  {errorMessage.message}
                </p>
              }
            </div>
            <div className="col-md-4">
              {" "}
              <div className="form-group">
                {/* <input
                  type="text"
                  className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  placeholder={"Numero de la proforma".toUpperCase()}
                  value={proformaHeader?.num_proforma}
                  onChange={(e) =>
                    updateProformaHeader("num_proforma", e.target.value)
                  }
                /> */}
                <InputComponent value={proformaHeader?.num_proforma} onChange={(valeur) => updateProformaHeader("num_proforma", valeur)} errorMessage={errorMessage.message} placeholder={"Numero de la proforma".toUpperCase()} label='' withLabel={false} isError={errorMessage.field === 'num_proforma' ? true : false} />

              </div>
            </div>
            <div className="col-md-4">
              {" "}
              <div className="form-group">

                <InputComponent type={'date'} value={proformaHeader?.date_export} onChange={(valeur) => updateProformaHeader("date_export", valeur)} errorMessage={errorMessage.message} placeholder={"date".toUpperCase()} label='' withLabel={false} isError={errorMessage.field === 'date_export' ? true : false} />
              </div>
            </div>
          </div>
          {proformaList.map((item, index) => (
            <div className="grid-cols-4 gap-2 space-y-2 md:grid md:space-y-0">
              <div className="">
                {" "}
                <div className="form-group">
                
                  <InputComponent value={item?.composition} onChange={(valeur) => updateProformaList(index, "composition", valeur)} errorMessage={errorMessage.message} placeholder={"composition".toUpperCase()} label='' withLabel={false} isError={errorMessage.field === 'composition' ? true : false} />
                </div>
              </div>
              <div className="">
                {" "}
                <div className="form-group">
                  
                  <InputComponent value={item?.description} onChange={(valeur) => updateProformaList(index, "description", valeur)} errorMessage={errorMessage.message} placeholder={"description".toUpperCase()} label='' withLabel={false} isError={errorMessage.field === 'description' ? true : false} />

                </div>
              </div>
              <div className="">
                {" "}
                <div className="form-group">
                 
                  <InputComponent
                    value={item?.manufacturer}
                    onChange={(valeur) => updateProformaList(index, "manufacturer", valeur)}
                    errorMessage={errorMessage.message} placeholder={"manufacturer".toUpperCase()}
                    label='' withLabel={false}
                    isError={errorMessage.field === 'manufacturer' ? true : false} />

                </div>
              </div>
              <div className="">
                {" "}
                <div className="form-group">
                  <CountriesSelector
                    showLabel={false}
                    selectedValue={item?.origin}
                    onSelect={(e) =>
                      updateProformaList(index, "origin", e.value)
                    }
                  />
                </div>
              </div>
              <div className="">
                {" "}
                <div className="form-group">
                 
                  <InputComponent
                    value={item?.prix_unitaire}
                    onChange={(valeur) => updateProformaList(index, "prix_unitaire", valeur)}
                    errorMessage={errorMessage.message} placeholder={"Prix unitaire".toUpperCase()}
                    label=''
                    withLabel={false}
                    isError={errorMessage.field === 'prix_unitaire' ? true : false} />

                </div>
              </div>
              <div className="">
                {" "}
                <div className="form-group">
                  
                  <InputComponent
                    value={item?.hs_code}
                    onChange={(valeur) => updateProformaList(index, "hs_code", valeur)}
                    errorMessage={errorMessage.message}
                    placeholder={"HORS TAXE".toUpperCase()}
                    label='' withLabel={false}
                    isError={errorMessage.field === 'hs_code' ? true : false}
                  />

                </div>
              </div>
              <div className="">
                {" "}
                <div className="form-group">
                  
                  <InputComponent
                    type="number"
                    value={item?.qte}
                    onChange={(valeur) => updateProformaList(index, "qte", valeur)}
                    errorMessage={errorMessage.message}
                    placeholder={"Quantité".toUpperCase()}
                    label='' withLabel={false}
                    isError={errorMessage.field === 'qte' ? true : false}
                  />
                </div>
              </div>
              <div>
                <div className="form-group">

                  <InputComponent
                    disabled
                    value={item?.manufacturer}
                    onChange={(valeur) => updateProformaList(index, "manufacturer", valeur)}
                    errorMessage={errorMessage.message}
                    placeholder={"prix total".toUpperCase()}
                    label='' withLabel={false}
                    isError={errorMessage.field === 'manufacturer' ? true : false}
                  />
                </div>
              </div>
            </div>
          ))}

          <div className={"flex flex-end justify-end "}>
            <div className="gap-2 md:flex ">
              <div className="items-end space-x-3 row h-100">
                <ButtonComponent title={"Supprimer"} onClick={removeProforma} />
                <ButtonComponent title={"Ajouter"} onClick={addProforma} />
                <ButtonComponent title={"Prévisualiser"} onClick={() => console.log(3)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
