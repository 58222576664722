import React, {
    Fragment,
} from "react";

import { Dialog, Transition } from "@headlessui/react";
import { AiOutlineClose } from "react-icons/ai"


const ModalUI = ({
    isOpen,
    onClose,
    children,
    title
}) => {
    return (
        <>
            {/* <ToastContainer /> */}
            <Transition appear show={isOpen} as={Fragment}>

                <Dialog as="div" className="relative z-90" onClose={onClose} style={{ zIndex: 9999 }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">

                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-[800px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <AiOutlineClose className="w-5 h-5 cursor-pointer absolute right-6 top-6" onClick={onClose} />
                                    {title && <Dialog.Title
                                        as="h3"
                                        className="text-lg mx-3 font-medium leading-6 text-gray-900"
                                    >
                                        {title}
                                    </Dialog.Title>}
                                    {children}
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>

    );
};

export default ModalUI;
