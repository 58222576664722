import axios from "axios";
import TokenService from "../config/tokenService.js";
import { getAllShippings } from "../../utils/index.js";

// {
//     "type": "paris||medical||service||all",
//     "per_page": 10,
//     "page" : 1,
//     "user_id": 5
// }

const allShippingsActive = getAllShippings()

class ExpeditionService {
    constructor() {
        TokenService.setHeader();
    }
    // ********************** CLIENT SERVICES ************************

    // Pour avoir la liste de toute les expédition d'un client
    getAllClientExpeditions(data) {
        data.all_shippings = allShippingsActive ? 1 : 0
        data.state = 'autre'
        return axios.post(
            `${process.env.REACT_APP_API_URL}/user/shipping/client/all`,
            data
        );
    }
    // cell ci

    // Pour avoir la liste de toute les expédition livrées d'un client
    getAllClientExpeditionsEnCours(data) {
        data.all_shippings = allShippingsActive ? 1 : 0
        return axios.post(
            `${process.env.REACT_APP_API_URL
            }/user/shipping/client/en-cours`,
            data
        );
    }

    // Pour avoir la liste de toute les expédition livrées d'un client
    getAllClientExpeditionsLivre(data) {
        data.all_shippings = allShippingsActive ? 1 : 0
        return axios.post(
            `${process.env.REACT_APP_API_URL
            }/user/shipping/client/livrees`,
            data
        );
    }

    // Pour avoir les détails d'expéditions d'un client
    getExpeditionsDetail(data) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/user/shipping/client/detail`,
            data
        );
    }

    // Service pour creer une expédition
    createExpedition(data) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/user/shipping/create`,
            data
        );
    }

    // ********************** OPS SERVICES ************************

    // Pour avoir la liste des expéditions annulées
    getAllOpsExpeditionsAnnule(data) {
        data.all_shippings = allShippingsActive ? 1 : 0
        return axios.post(
            `${process.env.REACT_APP_API_URL}/user/shipping/annulees`,
            data
        );
    }

    // Pour avoir la liste des expéditions livrées
    getAllOpsExpeditionsLivre(data) {
        data.all_shippings = allShippingsActive ? 1 : 0
        return axios.post(
            `${process.env.REACT_APP_API_URL}/user/shipping/livrees`,
            data
        );
    }

    // Pour avoir la liste des expéditions facturees
    getAllOpsExpeditionsFacture(data) {
        data.all_shippings = allShippingsActive ? 1 : 0
        return axios.post(
            `${process.env.REACT_APP_API_URL}/user/shipping/facturees`,
            data
        );
    }

    // Pour avoir la liste des expéditions à archiver
    getAllOpsExpeditionsAArchiver(data) {
        data.all_shippings = allShippingsActive ? 1 : 0
        return axios.post(
            `${process.env.REACT_APP_API_URL}/user/shipping/a-archivees`,
            data
        );
    }

    // Pour avoir la liste des expéditions à archiver
    getAllOpsExpeditionsArchiver(data) {
        data.all_shippings = allShippingsActive ? 1 : 0
        return axios.post(
            `${process.env.REACT_APP_API_URL}/user/shipping/archivees`,
            data
        );
    }
    getAllOpsBordeauxArchiver(data) {
        data.all_shippings = allShippingsActive ? 1 : 0
        return axios.post(
            `${process.env.REACT_APP_API_URL}/user/shipping/bordereaux-archivees`,
            data
        );
    }

    // Pour avoir la liste des expéditions en cours
    getAllOpsExpeditionsEnCours(data) {
        data.all_shippings = allShippingsActive ? 1 : 0
        return axios.post(
            `${process.env.REACT_APP_API_URL}/user/shipping/en-cours`,
            data
        );
    }

    // Pour avoir la liste des expéditions en attente
    getAllOpsExpeditionsEnAttente(data) {
        data.all_shippings = allShippingsActive ? 1 : 0
        return axios.post(
            `${process.env.REACT_APP_API_URL}/user/shipping/en-attente`,
            data
        );
    }

    // Pour avoir les détails d'un expédition
    getExpeditionDetails(data) {
        data.all_shippings = allShippingsActive ? 1 : 0
        return axios.post(
            `${process.env.REACT_APP_API_URL}/user/shipping/detail`,
            data
        );
    }

    //
    getAllExpedition(route, data) {
        data.all_shippings = allShippingsActive ? 1 : 0
        return axios.post(
            `${process.env.REACT_APP_API_URL}/user/shipping/${route}`,
            data
        );
    }
    getAllOpsExpeditionsEnCoursFrance(data) {
        data.all_shippings = allShippingsActive ? 1 : 0
        return axios.post(
            `${process.env.REACT_APP_API_URL}/user/shipping/en-cours-france`,
            data
        );
    }

    affectShipping(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/user/shipping/affecter`, data);
    }


    // Expedition client
    getAllClientExpedition(data, route) {
        data.all_shippings = allShippingsActive ? 1 : 0;
        // data.status = allShippingsActive ? 'autre' : ''
        return axios.post(
            `${process.env.REACT_APP_API_URL}/user/shipping/client/${route}`,
            data
        );
    }


    resendMail(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/user/shipping/resend-mail`, data);
    }

    getShippingTrackingSummary(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/suivi/shipping`, data);
    }
}

// "type": "paris||medical||service||all",
//     "per_page": 10,
//     "page" : 1,
//     "category": "", // import ou export ou all #default all#
//     "type_expedition": "", // expedition ou course

export default new ExpeditionService();
