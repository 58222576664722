import React from 'react'
import { saveAs } from 'file-saver';
import {
    Document,
    Packer,
    Paragraph,
    TextRun,
    BorderStyle
} from 'docx';
import { getCountryNameOrCode } from '../../utils/const/countriesConstant';
import { ButtonComponent } from '../ButtonComponent';
export const Stciker = ({ info, userType }) => {

    const handleGenerateWord = async () => {
        let size=65;
        // Créer un document Word avec du texte en gras et en majuscule
        const doc = new Document({
            sections: [
                {
                    properties: {},
                    children: [
                        new Paragraph({
                           alignment: 'center',
                            border: {
                                top: { style: BorderStyle.DOUBLE, size: 10, color: "000000" },
                                bottom: { style: BorderStyle.DOUBLE, size: 10, color: "000000" },
                                left: { style: BorderStyle.DOUBLE, size: 10, color: "000000" },
                                right: { style: BorderStyle.DOUBLE, size: 10, color: "000000" },
                                rounded:80
                            },
                            children: [
                                new TextRun({
                                    text: info?.fullname,
                                    bold: true,
                                    allCaps: true,  // Met le texte en majuscule
                                    size: size,
                                }),
                                new TextRun({
                                    text: info?.company,
                                    bold: true,
                                    allCaps: true,  // Met le texte en majuscule
                                    break: 1,
                                    size: size,
                                }),
                                new TextRun({
                                    text: `${ info?.address1}` ,
                                    bold: true,
                                    allCaps: true,
                                    break: 1,  // Met le texte en majuscule
                                    size: size,
                                }),
                                new TextRun({
                                    text: `${info?.city}, ${info?.postalCode} ` ,
                                    bold: true,
                                    allCaps: true,  // Met le texte en majuscule
                                    break: 1,
                                    size: size,
                                    
                                }),
                                new TextRun({
                                    text: `${getCountryNameOrCode(info.country)}` ,
                                    bold: true,
                                    allCaps: true,  // Met le texte en majuscule
                                    break: 1,
                                    size: size,
                                    
                                }),
                                new TextRun({
                                    text: info?.phone1,
                                    bold: true,
                                    allCaps: true,  // Met le texte en majuscule
                                    break: 1,
                                    size: size,
                                    
                                }),
                            ],
                        }),
                    ],
                },
            ],
        });

        // Convertir le document en Blob et déclencher le téléchargement
        const blob = await Packer.toBlob(doc);
        saveAs(blob, `${userType}.docx`);
    };

    return (
        <div>
             <ButtonComponent title={"Sticker"} onClick={handleGenerateWord} />
        </div>
    )
}
