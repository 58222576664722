import { DateTime } from "luxon";
import {
  AdminColor,
  MedicalColor,
  ParisColor,
  ServiceColor,
} from "../const/colorsConstant";
import medicalExport from "./../../assets/img/expeditionLogo/medicalExport.png";
import medicalImport from "./../../assets/img/expeditionLogo/medicalImport.png";
import serviceExport from "./../../assets/img/expeditionLogo/serviceExport.png";
import serviceImport from "./../../assets/img/expeditionLogo/serviceImport.png";
import parisImport from "./../../assets/img/expeditionLogo/parisImport.png";
import parisExport from "./../../assets/img/expeditionLogo/parisExport.png";
import moment from "moment";

export const LinkData = (link) => {
  let linkTable = link;
  let role = linkTable[1];
  let espace = linkTable[2];
  let typeExpedition =
    linkTable.length === 4
      ? "all"
      : linkTable.length === 6
        ? linkTable[4]
        : linkTable[linkTable.length - 1];
  let etatExpedition =
    linkTable.length === 4
      ? "all"
      : linkTable.length === 6
        ? linkTable[5]
        : "all";

  return {
    role: role,
    espace: espace,
    typeExpedition: typeExpedition,
    etatExpedition: etatExpedition,
  };
};

export const actuallDate = () => {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Les mois sont indexés de 0 à 11, donc on ajoute 1
  const day = currentDate.getDate();
  // alert(day)
  return `${year}-${month >= 10 ? month : "0" + month}-${day >= 10 ? day : "0" + day
    }`;
};

export const getCurrentTime = (delais = false) => {
  const now = new Date();
  const heure = now.getHours().toString().padStart(2, "0");
  const minute = now.getMinutes().toString().padStart(2, "0");
  if (!delais) {
    return `${heure}:${minute}`;
  } else {
    const { value } = delais;
    let delaisValue = value === "normal" ? 180 : value === "spx_1" ? 90 : 100;

    let newTime =
      parseInt(heure) * 60 + parseInt(minute) + parseInt(delaisValue);
    // convert minute to hours

    var hour = (newTime - (newTime % 60)) / 60;
    var minut = Math.round(newTime % 60);

    return `${hour}:${minut}`;
  }
};

export const refPrefixer = (ExnetSpace) => {
  let prefixe = "";
  if (ExnetSpace) {
    prefixe =
      ExnetSpace === "paris" ? "EX" : ExnetSpace === "medical" ? "EM" : "ES";
  }
  return prefixe;
};

export const roleSplit = (value) => {
  let roleTable = [];
  let espaceTable = [];

  try {
    if (value.length > 0) {
      let roleData = JSON.parse(JSON.stringify(value)).split('"');
      const filteredIndexes = roleData
        .map((value, index) =>
          value === "]" ||
            value === "[" ||
            value === "," ||
            value === " " ||
            value === ""
            ? null
            : index
        )
        .filter((index) => index !== null);

      const filteredValues = filteredIndexes.map((index) => roleData[index]);

      for (let i = 0; i < filteredValues.length; i++) {
        let element = filteredValues[i].split("_");
        if (element.length > 2) {
          roleTable.push(element[1].toLowerCase());
          espaceTable.push(element[2].toLowerCase());
        } else {
          roleTable.push(element[1].toLowerCase());
        }
      }
    }

    return {
      role: roleTable,
      espace: espaceTable,
    };
  } catch (error) {
    return value;
  }
};

export const insertLineBreaks = (text) => {
  const words = text.split(" "); // Divise la chaîne en mots
  const groupedWords = [];

  for (let i = 0; i < words.length; i += 10) {
    const group = words.slice(i, i + 10).join(" "); // Regroupe les mots par groupes de 5
    groupedWords.push(group);
  }

  return groupedWords.join("\n \n"); // Rejoint les groupes avec des retours à la ligne
};

export const replaceLastValueInPath = (path, newValue) => {
  const parts = path.split("/");
  parts[parts.length - 1] = newValue;
  return parts.join("/");
};

export const urlSplit = () => {
  var currentUrl = window.location.pathname;
  var splittedUrl = currentUrl.split("/");
  return {
    role: splittedUrl[1],
    exnetSpace: splittedUrl[2],
    expeditionType:
      splittedUrl[splittedUrl.length - 2] === "import" ||
        splittedUrl[splittedUrl.length - 2] === "export"
        ? true
        : false,
    expedition:
      splittedUrl[splittedUrl.length - 2] === "courses-a-courses"
        ? "COURSE EXPRESS "
        : splittedUrl[splittedUrl.length - 2] === "export"
          ? "EXPEDITION"
          : splittedUrl[splittedUrl.length - 2].toUpperCase(),
    expeditionName: splittedUrl[splittedUrl.length - 3] === "courses-a-courses"
      ? "COURSE EXPRESS "
      : "EXPéDITION",
  };
};

export const getCurrentDate = () => {
  const joursSemaine = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ];
  const mois = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  const date = new Date();
  const jourSemaine = joursSemaine[date.getDay()];
  const jourMois = date.getDate();
  const moisAnnee = mois[date.getMonth()];
  const annee = date.getFullYear();

  const dateString = `${jourSemaine} ${jourMois} ${moisAnnee} ${annee}`;

  return dateString;
};

export const decodePHPArray = (encodedString) => {
  if (encodedString) {
    // Utilisation d'expressions régulières pour extraire la valeur
    const match = encodedString.match(/s:\d+:"(.*?)";/);
    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  }
};

export const convertirHeureClientEnFrance = (heureClient, fuseauClient) => {
  // Créez un objet DateTime à partir de l'heure du client et de son fuseau horaire
  const heureClientFormattee = DateTime.fromFormat(heureClient, "HH:mm", {
    zone: fuseauClient,
  });

  // Définissez le fuseau horaire de la France
  const fuseauFrance = "Europe/Paris";

  const heureFrance = heureClientFormattee.setZone(fuseauFrance);

  // Obtenez l'heure en France
  const heureEnFrance = heureFrance.toFormat("HH:mm");

  return heureEnFrance;
};

export const expeditionTypeByCountries = (a, b) => {
  if (a?.toUpperCase() === "FR" || a?.toUpperCase() === "FRANCE") {
    return "export";
  } else if (a?.toUpperCase() !== "FR" || a?.toUpperCase() !== "FRANCE") {
    return "import";
  }
};

export const splitText = (text) => {
  return text
    ? text.split(" ").map((word, index) => {
      return index !== 0 && index % 3 === 0
        ? [<br key={index} />, word]
        : " " + word;
    })
    : "-";
};

export const exnetSpaceColor = (exnetSpace, black = false) => {

  if (black) {
    return AdminColor
  } else {
    return `${exnetSpace === "service"
      ? ServiceColor
      : exnetSpace === "paris"
        ? ParisColor
        : exnetSpace === "medical" ? MedicalColor
          : AdminColor
      }`;
  }

};

export const formatMessageDate = (inputDate) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  const date = new Date(inputDate);
  const formattedDate = date.toLocaleString("fr-FR", options);
  return formattedDate;
};

export const returnExpeditionTypeLogo = (expeditionType, space = false) => {
  let { exnetSpace: newSpace } = urlSplit();

  let exnetSpace = space || newSpace

  if (exnetSpace === "paris") {
    if (expeditionType === "import") {
      return parisImport;
    } else {
      return parisExport;
    }
  } else if (exnetSpace === "medical") {
    if (expeditionType === "import") {
      return medicalImport;
    } else {
      return medicalExport;
    }
  } else if (exnetSpace === "service") {
    if (expeditionType === "import") {
      return serviceImport;
    } else {
      return serviceExport;
    }
  }
};

export const compareDate = (value) => {
  // Créer la date actuelle
  var dateActuelle = new Date();

  // Créer la date sélectionnée (remplacez avec la date que vous avez)
  var dateSelectionnee = new Date(value); // Remplacez cette ligne par la date sélectionnée

  // Extraire le jour, le mois et l'année de la date actuelle
  var jourActuel = dateActuelle.getDate();
  var moisActuel = dateActuelle.getMonth() + 1; // Les mois commencent à 0, donc ajoutez 1
  var anneeActuelle = dateActuelle.getFullYear();

  // Extraire le jour, le mois et l'année de la date sélectionnée
  var jourSelectionne = dateSelectionnee.getDate();
  var moisSelectionne = dateSelectionnee.getMonth() + 1; // Les mois commencent à 0, donc ajoutez 1
  var anneeSelectionnee = dateSelectionnee.getFullYear();

  // Comparer les composants
  if (
    anneeSelectionnee > anneeActuelle ||
    (anneeSelectionnee === anneeActuelle &&
      (moisSelectionne > moisActuel ||
        (moisSelectionne === moisActuel && jourSelectionne >= jourActuel)))
  ) {
    return true;
  } else {
    return false;
  }
};

export const compareTwoDate = (DateA, DateB) => {
  // Créer la date A (remplacez avec la date que vous avez)
  var dateA = new Date(DateA); // Remplacez cette ligne par la date A

  // Créer la date B (remplacez avec la date que vous avez)
  var dateB = new Date(DateB); // Remplacez cette ligne par la date B

  // Extraire le jour, le mois et l'année de la date A
  var jourA = dateA.getDate();
  var moisA = dateA.getMonth() + 1; // Les mois commencent à 0, donc ajoutez 1
  var anneeA = dateA.getFullYear();

  // Extraire le jour, le mois et l'année de la date B
  var jourB = dateB.getDate();
  var moisB = dateB.getMonth() + 1; // Les mois commencent à 0, donc ajoutez 1
  var anneeB = dateB.getFullYear();

  // Comparer les composants
  if (
    anneeA > anneeB ||
    (anneeA === anneeB &&
      (moisA > moisB || (moisA === moisB && jourA >= jourB)))
  ) {
    return true
  } else {
    return false
  }
};


export const chekText = (text) => {

  if (text?.toUpperCase() === 'UNDEFINED') {
    return ""
  } else {
    return text;
  }
};



export const comparerObjets = (objet2, updated = false) => {

  const objet1 = {
    type: '',
    country: '',
    billing_center_name: '',
    address: '',
    address2: '',
    postal_code: 0,
    city: '',
    telephone1: 0,
    email_facturation: '',
    numero_siren:"",
    // contact_name: '',
    // dirigeant_phone: 0,
    // dirigeant_email: '',
    // compta_responsible: '',
    // compta_phone: '',
    // compta_email: '',
    intra_vatnumber: 0,
    numero_eori: 0,
    email1: '',
    email2: '',
    email3: '',
    valeur_customer: 0,
    customer_name: 0,
    active_mail_instruction: 0,
    mailing: 0,
    actif: 0,
    deux_bordereaux: 0,
    customer_id: 0
  };

  console.log(objet2, updated ? 'vrai' : 'faux')
  if (updated) {
    objet1.id = 0

    // Récupérer les clés communes
    const clesCommunes = Object.keys(objet1).filter(cle => Object.keys(objet2).includes(cle));

    // Créer un objet avec les clés communes et les valeurs correspondantes du deuxième objet
    const resultat = {};
    clesCommunes.forEach(cle => {
      resultat[cle] = objet2[cle];
    });

    return resultat;
  } else {
    return objet2
  }



}



export const isSelectedYears = (month) => {
  const dateActuelle = new Date();
  const moisActuel = dateActuelle.getMonth(); // Les mois vont de 0 à 11

  return moisActuel === month;
}

export const updateDate = (dateString) => {
  const dateObject = new Date(dateString);

  // Ajoute une journée à la date


  if (dateObject.setDate(dateObject.getDate() + 1)) {
    // Vous pouvez également formater la date selon vos besoins
    const formattedDate = dateObject.toLocaleString(); // ou utilisez d'autres options de formatage

    return moment(formattedDate).format("YYYY-MM-DD");
  } else {
    console.log(dateString)
    return dateString
  }

}


export const isWorkingDay = (value) => {
  let date = new Date(value)
  const dayOfWeek = date.getDay();

  // Vérifier si la date est un week-end (samedi ou dimanche)
  if (dayOfWeek === 0 || dayOfWeek === 6) {
    return false;
  }

  // TODO: Ajouter votre logique pour vérifier les jours fériés ici
  // Vous pouvez avoir une liste de jours fériés spécifique à votre région
  // Retournez false si la date est un jour férié

  return true;
}

// Exemple d'utilisation
const myDate = new Date('2023-12-15');
if (isWorkingDay(myDate)) {
  console.log('La date est un jour ouvrable.');
} else {
  console.log('La date est un week-end ou un jour férié.');
}


export const convertirTimestampEnDate = (timestamp) => {
  // Crée un objet Date à partir du timestamp
  let date = new Date(timestamp);

  // Récupère les composants de la date
  let jour = date.getDate();
  let mois = date.getMonth() + 1; // Les mois commencent à 0, donc ajoutez 1
  let annee = date.getFullYear();

  // Formate la date sous forme de chaîne 'jj/mm/aaaa'
  let dateFormatee = `${jour < 10 ? '0' : ''}${jour}/${mois < 10 ? '0' : ''}${mois}/${annee}`;

  return dateFormatee;
}

const addZero = (value) => {
  return parseInt(value) < 10 ? '0' + value : value
}

export const trierTableauParDateEtHeureSuivi = (tableau) => {
  if (tableau && Array.isArray(tableau) && tableau.length > 0) {
    // Assurez-vous que les dates sont dans un format approprié (par exemple, "YYYY-MM-DD")
    const convertirEnDate = (dateString, heureString, data) => {
      if (data) {
        let date = new Date(dateString).toLocaleDateString()
        let heures = new Date(dateString).toLocaleTimeString()
        const [annee, mois, jour] = date?.split('-');
        const [heure, minute] = heures?.split(':');
        return new Date(annee, addZero(mois - 1), addZero(jour), addZero(heure), addZero(minute), 0).getTime();
      } else {
        const [annee, mois, jour] = dateString?.split('-');
        const [heure, minute] = heureString?.split(':');
        return new Date(annee, mois - 1, jour, heure, minute, 0).getTime();
      }
    };

    return tableau.sort((a, b) => {
      const dateA = convertirEnDate(a.date_suivi || a.created_at, a.heur_suivi, a.date_suivi ? true : false);
      const dateB = convertirEnDate(b.date_suivi || b.created_at, b.heur_suivi, b.date_suivi ? true : false);

      // Triez par date_suivi, puis par heure_suivi en cas d'égalité
      if (dateA < dateB) {
        return -1;
      } else if (dateA > dateB) {
        return 1;
      } else {
        // Les dates sont égales, trie par heure_suivi
        const heureA = a.heure_suivi;
        const heureB = b.heure_suivi;
        if (heureA < heureB) {
          return -1;
        } else if (heureA > heureB) {
          return 1;
        } else {
          return 0; // Les dates et heures sont égales
        }
      }
    });
  } else {
    return []
  }

}