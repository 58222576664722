import React, { useEffect, useState } from "react";
import BillCenterSelector from "../Utils/Selectors/BillCenterSelector";
import { VerifyStep_1 } from "../../utils/Functions/verifyDropdown";
import ClientSelector from "../Utils/Selectors/ClientSelector";
import { urlSplit } from "../../utils/Functions/otherFunctions";
import UsernameSelector, { UsernameSelect } from "../Utils/Selectors/UsernameSelector";
import InputComponent from "../InputComponent/InputComponent";
import { useAppContext } from "../../context";
import { ButtonComponent } from "../ButtonComponent";
import { toast } from "react-toastify";

function NexExpeditionReferenceComponent({
  type,
  setInfosSupp,
  infosSupp,
  toggleAccordion,
  ExpeditionType,
}) {
  const {
    user_id,
    bill_center_id,
    username,
    nomContact,
    customer_ref,
    tracking_mail,
    code_receveur,
    code_donneur,
    transport_type,
    selected_user
  } = infosSupp;
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedCentre, setSelectedCentre] = useState(bill_center_id);
  const [selectedDelais, setSelectedDelais] = useState(null);
  const [selectedRoues, setSelectedRoues] = useState(null);
  const { isAuth, setIsAuth } = useAppContext();

  const { role, exnetSpace } = urlSplit();

  const updateData = (element, valeur) => {
    let data = { ...infosSupp };
    data[element] = valeur;
    setInfosSupp({ ...data });
  };
  const [errorInformation, setErrorInformation] = useState({ field: '', message: '' })

  const handleNext = () => {
    const { valid, error: messageError, errorData } = VerifyStep_1(infosSupp);
    console.log({ messageError, errorData })
    if (messageError) {
      toast.error(messageError, {
        position: "top-center",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setErrorInformation({ ...errorData })
    } else {
      toggleAccordion(2);
    }
  };

  useEffect(() => {
    let data = { ...infosSupp };
    updateData("user_id", selectedClient?.value);
    setInfosSupp({ ...data });
    if (type === "courses") {
      data["delais"] = selectedDelais;
      setInfosSupp({ ...data });
    }
  }, [selectedDelais]);





  return (
    <div className="content">
      <div className="mx-1 row">
        <div className="card-box">
          <div className="grid-cols-2 gap-2 space-y-2 md:space-y-0 md:grid ">
            {isAuth?.userRole !== "client" && (
              <div className="">
                <div className="form-group">
                  <ClientSelector
                    type={exnetSpace}
                    selectedValue=
                    {
                      user_id?.value
                        ? {
                          label: "Choix du client",
                          value: null,
                        }
                        : user_id
                    }

                    showLabel={false}
                    profil={"customer"}
                    onSelect={(v) => {
                      updateData("user_id", v.value);
                      setSelectedCentre(v);
                    }}
                  />
                </div>
              </div>
            )}

            <div className="">
              {" "}
              <div className="form-group">
                <InputComponent
                  value={customer_ref}
                  onChange={(valeur) => updateData("customer_ref", valeur)}
                  errorMessage={errorInformation.message}
                  placeholder={"Référence du client".toUpperCase()}
                  label='' withLabel={false}
                  isError={errorInformation.field === 'customer_ref' ? true : false}
                />
              </div>
            </div>
            <div className="">
              {" "}
              <div className="form-group">
                <BillCenterSelector
                  type={exnetSpace}
                  defaultInputValue={
                    bill_center_id?.value
                      ? {
                        label: "Choix du centre de facturation",
                        value: null,
                      }
                      : bill_center_id
                  }
                  selectedValue={selectedCentre}
                  onSelect={(v) => {
                    updateData("bill_center_id", v);
                    setSelectedCentre(v);
                  }}
                  isDisabled={false}
                  user={infosSupp.user_id}
                />
              </div>
            </div>

            {(isAuth?.spaceName === 'medical') && <div className="">
              {" "}
              <div className="form-group">

                <InputComponent
                  value={code_donneur}
                  onChange={(valeur) => updateData("code_donneur", valeur)}
                  errorMessage={errorInformation.message}
                  placeholder={"CODE DONNEUR".toUpperCase()}
                  label='' withLabel={false}
                  isError={errorInformation.field === 'code_donneur' ? true : false}
                />
              </div>
            </div>}

            <div className="">
              {" "}
              <div className="form-group">

                <InputComponent
                  type="email"
                  value={tracking_mail}
                  onChange={(valeur) => updateData("tracking_mail", valeur)}
                  errorMessage={errorInformation.message}
                  placeholder={"MAIL DE SUIVI".toUpperCase()}
                  label='' withLabel={false}
                  isError={errorInformation.field === 'tracking_mail' ? true : false}
                />
              </div>
            </div>

            {(isAuth?.spaceName === 'medical') && <div className="col-md-6">
              {" "}
              <div className="form-group">

                <InputComponent
                  value={code_receveur}
                  onChange={(valeur) => updateData("code_receveur", valeur)}
                  errorMessage={errorInformation.message}
                  placeholder={"CODE Receveur".toUpperCase()}
                  label='' withLabel={false}
                  isError={errorInformation.field === 'code_receveur' ? true : false}
                />
              </div>
            </div>}



            {isAuth?.userRole === 'client' &&
              <div className="">
                <div className="form-group">

                  <InputComponent
                    value={username}
                    onChange={(valeur) => updateData("username", valeur)}
                    errorMessage={errorInformation.message}
                    placeholder={"Nom d'utilisateur*".toUpperCase()}
                    label='' withLabel={false}
                    isError={errorInformation.field === 'username' ? true : false}
                  />
                </div>
              </div>
            }

            {isAuth?.userRole === 'ops' &&
              <div className="">
                {" "}
                <div className="form-group">
                  {isAuth?.spaceName === 'paris' ? (
                    <UsernameSelect
                      onSelect={(v) => {
                        //sconsole.log('salut la famille comment allez vous',v)

                        updateData("username", v.value)
                      }}
                      defaultValue={username}
                      billing_center_id={bill_center_id?.value}
                      customer_id={infosSupp.user_id}
                    />
                  ) : (
                    <UsernameSelector
                      onSelect={(v) => {
                        //sconsole.log('salut la famille comment allez vous',v)

                        updateData("username", v)
                      }}
                      defaultValue={username}
                      billing_center_id={bill_center_id?.value}
                      customer_id={infosSupp.user_id}
                    />
                  )}
                </div>
              </div>
            }

            <div className={"col-span-2 "}>
              <div className="row " style={{ justifyContent: "flex-end" }}>
                <div className="col-3">
                  <div className="form-group w-100">
                    <ButtonComponent title={"Suivant"} onClick={handleNext} />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NexExpeditionReferenceComponent;
