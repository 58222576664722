import React, { useCallback, useEffect, useState } from "react";
import Loader, { TableLoader } from "../../Utils/Loader";
import adminService from "../../../services/admin/adminService";
import moment, { formatDefaultDate } from "../../../utils/moment";
import ClientSelector from "../../Utils/Selectors/ClientSelector";
import { fr } from 'date-fns/locale'
import {
  combineDateAndTime,
  convertFrenchDateToEnglish,
  formatShippingRef,
  formatShippingRef2,
  getNumberOfDays,
  getShippingLinesTotals,
  getStartAndEndDateOfCurrentMonth,
  toFixed2,
} from "../../../utils";
import { exnetSpaceColor } from "../../../utils/Functions/otherFunctions";
import { exportToExcel } from "react-json-to-excel";
import { PiExport } from "react-icons/pi";
import { isAfter } from "date-fns";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import PaginationComponent from "../../paginationComponent/PaginationComponent";
import useAuth from "../../../hooks/useAuth";
import { useBasicFormValues } from "../../../hooks/useFormValues";
import { TableComponent } from "../../TableauComponent";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { useAppContext } from "../../../context";
import clsx from "clsx";
import { ShippingDataStore } from "../../../states/shippingStore";



export const StatsFreight = ({ author = 'admin' }) => {
  return <Stats type={"freight"} author={author} />;
};

export const StatsCourses = ({ author = 'admin' }) => {
  return <Stats type={"courses"} author={author} />;
};

export default function Stats({ type, author }) {
  const [data, setData] = useState({
    data: [],
  });
  const items = data.data;

  const { data: metadata, setData: setMetadata, setFormValue } = useBasicFormValues({
    page: 1,
    search: "",
    customer: "",
    perPage: 25,
    start: "",
    end: ""

  });

  const [isLoading, setIsLoading] = useState(true);
  const [isExporting, setIsExporting] = useState(false);
  const { isAuthenticated, user } = useAuth();
  const { isAuth, setIsAuth } = useAppContext();
  const [showFilter, setShowFilter] = useState(true)
  const dataList = ShippingDataStore((state) => state?.dataList)
  const setDataList = ShippingDataStore((state) => state?.setDataList);
  const resetData = ShippingDataStore((state) => state?.resetData);

  const tableHeaders =
    type === "freight"
      ? [
        {
          filterKey: "created_at",
          label: "Date"
        },
        {
          filterKey: "detail_info_supplementaire.service_cdc",
          label: "Service CDC"
        },
        {
          filterKey: "expeditor.fullname",
          label: "Demandeur CDC"
        },
        {
          filterKey: "detail_info_supplementaire.mode_de_transport",
          label: "Mode Transport"
        },
        {
          filterKey: "customer_ref",
          label: "CDC référence Code section"
        },
        {
          filterKey: "detail_info_supplementaire.type_operation",
          label: "Type Opération"
        },
        {
          filterKey: "detail_info_supplementaire.type_de_douane",
          label: "Type de douane"
        },
        {
          filterKey: "detail_info_supplementaire?.numero_facture",
          label: "N° Facture / Carnet ATA"
        },
        {
          filterKey: "detail_info_supplementaire.articles_exotiques",
          label: "Articles exotiques"
        },
        {
          filterKey: "pickup_date",
          label: "Date départ prévu"
        },
        {
          filterKey: "pickup_date",
          label: "Date départ réelle"
        },
        {
          filterKey: "delivery_date",
          label: "Date livraison prévu"
        },
        {
          filterKey: "delivery_date",
          label: "Date livraison réelle"
        },
        {
          filterKey: "tempsTransit",
          label: "Temps de transit"
        },
        {
          filterKey: "retardOuHeure",
          label: "En retard / A l'heure"
        },
        {
          filterKey: "detail_info_supplementaire.mawb",
          label: "MAWB"
        },
        {
          filterKey: "item.type",
          label: "HAWB"
        },
        {
          filterKey: "expeditor.country",
          label: "Départ Pays"
        },
        {
          filterKey: "detail_info_supplementaire.aeroport_depart",
          label: "Aéroport Départ"
        },
        {
          filterKey: "expeditor.city",
          label: "Ville Départ"
        },
        {
          filterKey: "receiver.country",
          label: "Arrivée Pays"
        },
        {
          filterKey: "detail_info_supplementaire.aeroport_arrive",
          label: "Aéroport Arrivée"
        },
        {
          filterKey: "receiver.city",
          label: "Ville Arrivée"
        },
        {
          filterKey: "receiver.fullname",
          label: "Destinataire"
        },
        {
          filterKey: "poidsBrut",
          label: "Poids brut (kg)"
        },
        {
          filterKey: "poidsTaxable",
          label: "Poids taxable (kg)"
        },
        {
          filterKey: "etail_info_supplementaire.volume",
          label: "Volume m3"
        },
        {
          filterKey: "nbColis",
          label: "Nb Colis"
        },
        {
          filterKey: "incoterm",
          label: "Incoterm"
        },
        {
          filterKey: "coutTransport",
          label: "Coût de transport"
        },
        {
          filterKey: "surchargeCaburant",
          label: "Surchage caburant"
        },
        {
          filterKey: "detail_info_supplementaire.irc",
          label: "IRC"
        },
        {
          filterKey: "detail_info_supplementaire.frais_dedouanement",
          label: "Frais dédouanement"
        },
        {
          filterKey: "detail_info_supplementaire.droits_douane_taxe",
          label: "Droits de douane et taxes"
        },
        {
          filterKey: "detail_info_supplementaire.supplements",
          label: "Suppléments"
        },
        {
          filterKey: "montantTotal",
          label: "Montant total"
        },
        {
          filterKey: "value_in_douane",
          label: "Valeur marchandises"
        },
        {
          filterKey: "detail_info_supplementaire.co2_emis",
          label: "CO2 émis"
        },
        {
          filterKey: "detail_info_supplementaire.numero_facture",
          label: "Numéro de facture"
        },
        {
          filterKey: "detail_info_supplementaire.numero_compte",
          label: "Numéro de compte"
        },
        {
          filterKey: "type",
          label: "Service"
        },
        {
          filterKey: "numeroEXAIMA",
          label: "Numéro EX-A / IM-A (n/a quand ATA)"
        }
      ]

      : [
        {
          filterKey: "customer_ref",
          label: "Factures EXNET"
        },
        {
          filterKey: "ref",
          label: "AWB (Ref:)"
        },
        {
          filterKey: "created_at",
          label: "Date de demande"
        },
        {
          filterKey: "receiver.fullname",
          label: "Demandeur"
        },
        {
          filterKey: "expeditor.fullname",
          label: "Expéditeur"
        },
        {
          filterKey: "payeur",
          label: "Payeur"
        },
        {
          filterKey: "expeditor.address1",
          label: "Lieu d'enlèvement"
        },
        {
          filterKey: "expeditor.city",
          label: "Ville d'expédition"
        },
        {
          filterKey: "expeditor.postalCode",
          label: "CP"
        },
        {
          filterKey: "receiver.address1",
          label: "Lieu de livraison"
        },
        {
          filterKey: "receiver.city",
          label: "Ville de destination"
        },
        {
          filterKey: "expeditor.postalCode",
          label: "CP2"
        },
        {
          filterKey: "prestataire",
          label: "Prestataire"
        },
        {
          filterKey: "description",
          label: "Description"
        },
        {
          filterKey: "detail_info_supplementaire.vehicule",
          label: "Type de vehicule"
        },
        {
          filterKey: "commentaire",
          label: "Commentaire"
        },
        {
          filterKey: "nbCourse",
          label: "Nb de course"
        },
        {
          filterKey: "montantHT",
          label: "Montant HT"
        },
        {
          filterKey: "detail_info_supplementaire.numero_commande",
          label: "N° Commande"
        },
        {
          filterKey: "detail_info_supplementaire.numero_bon_vert",
          label: "N° Bon Vert"
        },
        {
          filterKey: "pickup_date",
          label: "Date d'enlèvement"
        },
        {
          filterKey: "delivery_date",
          label: "Date de livraison"
        },
        {
          filterKey: "creneauLivraisonSouhaite",
          label: "Créneau de livraison souhaité"
        }
      ]
    ;

  const tableBody = dataList?.map((item) => {
    const suiviDate = item?.suivis?.[0]?.date_suivi
      ? formatDefaultDate(item?.suivis[0].date_suivi, "DD/MM/YYYY hh:mm")
      : "-";
    const podDate = item?.pod?.[0]?.date
      ? formatDefaultDate(item?.pod[0].date, "DD/MM/YYYY hh:mm")
      : "-";
    const _package = Array.isArray(item?.packages) ? item?.packages[0] : {} // item?.packages[0];
    const _course = Array.isArray(item?.courses) ? item?.courses[0] : {} //item?.courses[0];
    const pDate = new Date(convertFrenchDateToEnglish(podDate))
    const sDate = new Date(convertFrenchDateToEnglish(suiviDate))
    /*     const diff = pDate - sDate
        const x = new Date(diff).getTime() / 1000 */
    const transitTime = !isNaN(pDate) && !isNaN(sDate) ? getNumberOfDays(sDate, pDate) : "-"
    const timing = !isNaN(pDate) ? isAfter(item.delivery_date, pDate) ? 1 : 0 : "-"

    //console.log({ timing/* podDate, suiviDate, pDate, sDate, x, transitTime  */ })

    const shippingLines = item?.facturations?.map((f) => ({
      ...f,
      shipping: item,
    }));

    const { totalHT, totalFuel, totalWithFuel } = shippingLines ?
      getShippingLinesTotals(shippingLines) : 0;

    return type === "freight"
      ? {
        Date: formatDefaultDate(item.created_at),
        "Service CDC": item.detail_info_supplementaire?.service_cdc || "-",
        "Demandeur CDC": item.expeditor?.fullname,
        "Mode Transport":
          item.detail_info_supplementaire?.mode_de_transport || "-",
        "CDC référence Code section": item.customer_ref || "-",
        "Type Opération":
          item.detail_info_supplementaire?.type_operation || "-",
        "Type de douane":
          item.detail_info_supplementaire?.type_de_douane || "-",
        "N° Facture / Carnet ATA":
          item.detail_info_supplementaire?.numero_facture || "-",
        "Articles exotiques":
          item.detail_info_supplementaire?.articles_exotiques || "-",
        "Date départ prévue": formatDefaultDate(combineDateAndTime(item.pickup_date, item.pickup_time), "DD/MM/YYYY hh:mm") || "-",
        "Date départ réelle": suiviDate,
        "Date livraison prévue": formatDefaultDate(combineDateAndTime(item.delivery_date, item.delivery_time), "DD/MM/YYYY hh:mm") || "-",
        "Date livraison réelle": podDate,
        "Temps de transit": transitTime,
        "En retard / A l'heure": timing,
        MAWB: item.detail_info_supplementaire?.mawb || "-",
        HAWB: formatShippingRef(item.ref, item.type),
        "Départ Pays": item.expeditor.country || "-",
        "Aéroport Départ":
          item.detail_info_supplementaire?.aeroport_depart || "-",
        "Ville Départ": item.expeditor.city || "-",
        "Arrivée Pays": item.receiver.country || "-",
        "Aéroport Arrivée":
          item.detail_info_supplementaire?.aeroport_arrive || "-",
        "Ville Arrivée": item.receiver.city,
        Destinataire: item.receiver.fullname,
        "Poids brut (kg)": _package ? toFixed2(_package.weight) : "-",
        "Poids taxable (kg)": _package
          ? toFixed2(_package.weight_volume)
          : "-",
        "Volume m3": item.detail_info_supplementaire?.volume,
        "Nb Colis": item.packages.length,
        Incoterm: _package?.incoterm || "-",
        "Coût de transport": totalHT,
        "Surchage caburant": totalFuel,
        IRC: item.detail_info_supplementaire?.irc || "-",
        "Frais dédouanement":
          item.detail_info_supplementaire?.frais_dedouanement || "-",
        "Droits de douane et taxes":
          item.detail_info_supplementaire?.droits_douane_taxe || "-",
        Suppléments: item.detail_info_supplementaire?.supplements || "-",
        "Montant total": totalWithFuel,
        "Valeur marchandises": item.value_in_douane || "-",
        "CO2 émis": item.detail_info_supplementaire?.co2_emis || "-",
        "Numéro de facture":
          item.detail_info_supplementaire?.numero_facture || "-",
        "Numéro de compte":
          item.detail_info_supplementaire?.numero_compte || "-",
        Service: item.type?.toUpperCase(),
        "Numéro EX-A / IM-A (n/a quand ATA)": null,
      }
      : {
        "Factures EXNET": item?.facturations?.length > 0 ? item?.facturations[0]['facture_ref'] : '-',
        "AWB (Ref:)": formatShippingRef2(item.ref, item.type) || "-",
        "Date de demande": formatDefaultDate(item.created_at, "DD/MM/YYYY hh:mm"),
        Demandeur: item.receiver?.fullname || "-",
        Expéditeur: item.expeditor?.fullname || "-",
        Payeur: null,
        "Lieu d'enlèvement":
          `${item.expeditor?.address1} / ${item.expeditor?.address2}` || "-",
        "Ville d'expédition": item.expeditor?.city || "-",
        CP: item.expeditor?.postalCode || "-",
        "Lieu de livraison":
          `${item.receiver?.address1} / ${item.receiver?.address2}` || "-",
        "Ville de destination": item?.receiver?.city || "-",
        CP2: item?.expeditor?.postalCode || "-",
        Prestataire: null,
        Description: item?.description || "-",
        "Type de vehicule": item.detail_info_supplementaire?.vehicule || "-",
        Commentaire: item.comment || "-",
        "Nb de course": item.courses?.length,
        "Montant HT": totalWithFuel,
        "N° Commande":
          item.detail_info_supplementaire?.numero_commande || "-",
        "N° Bon Vert":
          item.detail_info_supplementaire?.numero_bon_vert || "-",
        "Date d'enlèvement": formatDefaultDate(combineDateAndTime(item.pickup_date, item.pickup_time), "DD/MM/YYYY hh:mm") || "-",
        "Date de livraison": formatDefaultDate(combineDateAndTime(item.delivery_date, item.delivery_time), "DD/MM/YYYY hh:mm") || "-",
        "Créneau de livraison souhaité": null,
      };
  });

  useEffect(() => {
    (async () => {
      try {
        //console.log({ metadata });
        const params = {
          page: metadata.page,
          per_page: metadata.per_page,
          filter: {
            customer: author === 'client' ? parseInt(user.customer_id) : metadata.customer,
            date: {
              start: "2020-08-10",
              end: "2030-10-31",
            },
          },
        };
        setIsLoading(true);
        const { data } = await adminService.getStatistiques(params, author);
        if (data.success) {
          console.log(data.data.data);
          setDataList([...data.data.data])
          setData((d) => ({
            ...d,
            data: data.data.data,
            total: data.data.total,
            lastPage: data.data.last_page,
          }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [metadata, author]);

  const exportData = useCallback(() => {
    setIsExporting(true);
    const sheets = tableBody;
    //console.log({ sheets });
    const filename = `Stats-${type}-${metadata.customer}`;
    exportToExcel(sheets, filename);
    setIsExporting(false);
  }, [tableBody, type, metadata.customer,]);

  useEffect(() => {
    resetData()
    const { startDate, endDate } = getStartAndEndDateOfCurrentMonth()
    setFormValue("start", startDate)
    setFormValue("end", endDate)
  }, [])

  const exportDataToExcel = async () => {
    setIsExporting(true);
    const filename = `Stats-${type}-${metadata.customer}`;
    const entete_liste_globale2 =
      type === "freight"
        ? [
          { label: "Date", value: 'date' },
          { label: "Service CDC", value: 'service_cdc' },
          { label: "Demandeur CDC", value: 'demandeur_cdc' },
          { label: "Mode Transport", value: 'mode_transport' },
          { label: "CDC référence Code section", value: 'cdc_reference_code_section' },
          { label: "Type Opération", value: 'type_operation' },
          { label: "Type de douane", value: 'type_de_douane' },
          { label: "N° Facture / Carnet ATA", value: 'facture_carnet_ata' },
          { label: "Articles exotiques", value: 'articles_exotiques' },
          { label: "Date départ prévu", value: 'date_depart_prevu' },
          { label: "Date départ réelle", value: 'date_depart_reelle' },
          { label: "Date livraison prévu", value: 'date_livraison_prevu' },
          { label: "Date livraison réelle", value: 'date_livraison_reelle' },
          { label: "Temps de transit", value: 'temps_de_transit' },
          { label: "En retard / A l'heure", value: 'en_retard_a_heure' },
          { label: "MAWB", value: 'mawb' },
          { label: "HAWB", value: 'hawb' },
          { label: "Départ Pays", value: 'depart_pays' },
          { label: "Aéroport Départ", value: 'aeroport_depart' },
          { label: "Ville Départ", value: 'ville_depart' },
          { label: "Arrivée Pays", value: 'arrivee_pays' },
          { label: "Aéroport Arrivée", value: 'aeroport_arrivee' },
          { label: "Ville Arrivée", value: 'ville_arrivee' },
          { label: "Destinataire", value: 'destinataire' },
          { label: "Poids brut (kg)", value: 'poids_brut' },
          { label: "Poids taxable (kg)", value: 'poids_taxable' },
          { label: "Volume m3", value: 'volume_m3' },
          { label: "Nb Colis", value: 'nb_colis' },
          { label: "Incoterm", value: 'incoterm' },
          { label: "Coût de transport", value: 'cout_de_transport' },
          { label: "Surchage caburant", value: 'surchage_caburant' },
          { label: "IRC", value: 'irc' },
          { label: "Frais dédouanement", value: 'frais_dedouanement' },
          { label: "Droits de douane et taxes", value: 'droits_de_douane_et_taxes' },
          { label: "Suppléments", value: 'supplements' },
          { label: "Montant total", value: 'montant_total' },
          { label: "Valeur marchandises", value: 'valeur_marchandises' },
          { label: "CO2 émis", value: 'co2_emis' },
          { label: "Numéro de facture", value: 'numero_de_facture' },
          { label: "Numéro de compte", value: 'numero_de_compte' },
          { label: "Service", value: 'service' },
          { label: "Numéro EX-A / IM-A (n/a quand ATA)", value: 'numero' },
        ]
        : [
          { label: "Factures EXNET", value: 'factures_exnet' },
          { label: "AWB (Ref:)", value: 'awb' },
          { label: "Date de demande", value: 'date_de_demande' },
          { label: "Demandeur", value: 'demandeur' },
          { label: "Expéditeur", value: 'expéditeur' },
          { label: "Payeur", value: 'payeur' },
          { label: "Lieu d'enlèvement", value: 'lieu_enlevement' },
          { label: "Ville d'expédition", value: 'ville_expedition' },
          { label: "CP", value: 'cp' },
          { label: "Lieu de livraison", value: 'lieu_de_livraison' },
          { label: "Ville de destination", value: 'ville_de_destination' },
          { label: "CP2", value: 'cp2' },
          { label: "Prestataire", value: 'prestataire' },
          { label: "Description", value: 'description' },
          { label: "Type de vehicule", value: 'type_de_vehicule' },
          { label: "Commentaire", value: 'commentaire' },
          { label: "Nb de course", value: 'nb_de_course' },
          { label: "Montant HT", value: 'montant_ht' },
          { label: "N° Commande", value: 'commande' },
          { label: "N° Bon Vert", value: 'bon_vert' },
          { label: "Date d'enlèvement", value: 'date_enlevement' },
          { label: "Date de livraison", value: 'date_de_livraison' },
          { label: "Créneau de livraison souhaité", value: 'creneau_de_livraison_souhaite' },
        ];


    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('Ma Feuille');

    // // Définir les en-têtes
    let newValueTable = []
    entete_liste_globale2.forEach(({ label, value }, index) => {
      newValueTable.push({ header: label, key: value, width: 50 })
    })
    worksheet.columns = newValueTable;
    worksheet.getRow(1).font = { bold: true };


    // Ajouter des données
    let newData = [];
    if (type === "freight") {
      dataList?.map((item) => {
        const suiviDate = item.suivis?.[0]?.date_suivi
          ? formatDefaultDate(item.suivis[0].date_suivi, "DD/MM/YYYY hh:mm")
          : "-";
        const podDate = item.pod?.[0]?.date
          ? formatDefaultDate(item.pod[0].date, "DD/MM/YYYY hh:mm")
          : "-";
        const _package = item.packages[0];
        const _course = item.courses[0];
        const pDate = new Date(convertFrenchDateToEnglish(podDate))
        const sDate = new Date(convertFrenchDateToEnglish(suiviDate))
        /*     const diff = pDate - sDate
            const x = new Date(diff).getTime() / 1000 */
        const transitTime = !isNaN(pDate) && !isNaN(sDate) ? getNumberOfDays(sDate, pDate) : "-"
        const timing = !isNaN(pDate) ? isAfter(item.delivery_date, pDate) ? 1 : 0 : "-"

        console.log({ timing/* podDate, suiviDate, pDate, sDate, x, transitTime  */ })

        const shippingLines = item?.facturations?.map((f) => ({
          ...f,
          shipping: item,
        }));

        const { totalHT, totalFuel, totalWithFuel } =
          getShippingLinesTotals(shippingLines);


        let newValu = {
          date: formatDefaultDate(item.created_at),
          service_cdc: item.detail_info_supplementaire?.service_cdc || "-",
          demandeur_cdc: item.expeditor?.fullname,
          mode_transport: item.detail_info_supplementaire?.mode_de_transport || "-",
          cdc_reference_code_section: item.customer_ref || "-",
          type_operation:
            item.detail_info_supplementaire?.type_operation || "-",
          type_de_douane:
            item.detail_info_supplementaire?.type_de_douane || "-",
          facture_carnet_ata:
            item.detail_info_supplementaire?.numero_facture || "-",
          articles_exotiques:
            item.detail_info_supplementaire?.articles_exotiques || "-",
          date_depart_prevu: formatDefaultDate(combineDateAndTime(item.pickup_date, item.pickup_time), "DD/MM/YYYY hh:mm") || "-",
          date_depart_reelle: suiviDate,
          date_livraison_prevu: formatDefaultDate(combineDateAndTime(item.delivery_date, item.delivery_time), "DD/MM/YYYY hh:mm") || "-",
          date_livraison_reelle: podDate,
          temps_de_transit: transitTime,
          en_retard_a_heure: timing,
          mawb: item.detail_info_supplementaire?.mawb || "-",
          hawb: formatShippingRef(item.ref, item.type),
          depart_pays: item.expeditor.country || "-",
          aeroport_depart:
            item.detail_info_supplementaire?.aeroport_depart || "-",
          ville_depart: item.expeditor.city || "-",
          arrivee_pays: item.receiver.country || "-",
          aeroport_arrivee:
            item.detail_info_supplementaire?.aeroport_arrive || "-",
          ville_arrivee: item.receiver.city,
          destinataire: item.receiver.fullname,
          poids_brut: _package ? toFixed2(_package.weight) : "-",
          poids_taxable: _package
            ? toFixed2(_package.weight_volume)
            : "-",
          volume_m3: item.detail_info_supplementaire?.volume,
          nb_colis: item.packages.length,
          incoterm: _package?.incoterm || "-",
          cout_de_transport: totalHT,
          surchage_caburant: totalFuel,
          irc: item.detail_info_supplementaire?.irc || "-",
          frais_dedouanement:
            item.detail_info_supplementaire?.frais_dedouanement || "-",
          droits_de_douane_et_taxes:
            item.detail_info_supplementaire?.droits_douane_taxe || "-",
          supplements: item.detail_info_supplementaire?.supplements || "-",
          montant_total: totalWithFuel,
          valeur_marchandises: item.value_in_douane || "-",
          co2_emis: item.detail_info_supplementaire?.co2_emis || "-",
          numero_de_facture:
            item.detail_info_supplementaire?.numero_facture || "-",
          numero_de_compte:
            item.detail_info_supplementaire?.numero_compte || "-",
          service: item.type?.toUpperCase(),
          numero: '',
        }

        newData.push(newValu)
      });


    }
    else {
      dataList?.map((item) => {
        const suiviDate = item?.suivis?.[0]?.date_suivi
          ? formatDefaultDate(item?.suivis[0].date_suivi, "DD/MM/YYYY hh:mm")
          : "-";
        const podDate = item.pod?.[0]?.date
          ? formatDefaultDate(item.pod[0].date, "DD/MM/YYYY hh:mm")
          : "-";
        const _package = item?.packages[0];
        const _course = item?.courses[0];
        const pDate = new Date(convertFrenchDateToEnglish(podDate))
        const sDate = new Date(convertFrenchDateToEnglish(suiviDate))
        /*     const diff = pDate - sDate
            const x = new Date(diff).getTime() / 1000 */
        const transitTime = !isNaN(pDate) && !isNaN(sDate) ? getNumberOfDays(sDate, pDate) : "-"
        const timing = !isNaN(pDate) ? isAfter(item.delivery_date, pDate) ? 1 : 0 : "-"

        console.log({ timing/* podDate, suiviDate, pDate, sDate, x, transitTime  */ })

        const shippingLines = item?.facturations?.map((f) => ({
          ...f,
          shipping: item,
        }));

        const { totalHT, totalFuel, totalWithFuel } =
          getShippingLinesTotals(shippingLines);


        let newValu = {
          factures_exnet: '',
          awb: formatShippingRef(item.ref, item.type) || "-",
          date_de_demande: formatDefaultDate(item.created_at, "DD/MM/YYYY hh:mm"),
          demandeur: item.receiver?.fullname || "-",
          expéditeur: item.expeditor?.fullname || "-",
          payeur: '',
          lieu_enlevement:
            `${item.expeditor?.address1} / ${item.expeditor?.address2}` || "-",
          ville_expedition: item.expeditor?.city || "-",
          cp: item.expeditor?.postalCode || "-",
          lieu_de_livraison:
            `${item.receiver?.address1} / ${item.receiver?.address2}` || "-",
          ville_de_destination: item.receiver.city || "-",
          cp2: item.expeditor?.postalCode || "-",
          prestataire: '',
          description: item.description || "-",
          type_de_vehicule: item.detail_info_supplementaire?.vehicule || "-",
          commentaire: item.comment || "-",
          nb_de_course: item.courses?.length,
          montant_ht: totalWithFuel,
          commande:
            item.detail_info_supplementaire?.numero_commande || "-",
          bon_vert:
            item.detail_info_supplementaire?.numero_bon_vert || "-",
          date_enlevement: formatDefaultDate(combineDateAndTime(item.pickup_date, item.pickup_time), "DD/MM/YYYY hh:mm") || "-",
          date_de_livraison: formatDefaultDate(combineDateAndTime(item.delivery_date, item.delivery_time), "DD/MM/YYYY hh:mm") || "-",
          creneau_de_livraison_souhaite: '',
        }

        newData.push(newValu)
      });

    }


    newData.forEach(row => {
      worksheet.addRow(row);
    });

    // Appliquer les styles de bordure à toutes les lignes, y compris les données
    worksheet.eachRow(function (row, rowNumber) {
      row.eachCell(function (cell, colNumber) {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });
    });

    // Écrire dans un tampon et sauvegarder le fichier
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `Stats-${type}-${metadata.customer}.xlsx`);
    setIsExporting(false);
  };

  return (
    <div className={"px-2 md:px-0"}>
      <div>
        <h4 className="mb-3 uppercase page-title">Statistiques {type}</h4>
        <div className="flex justify-end">

          <div className="rounded-full cursor-pointer w-fit bg-admin" style={{
            color: "#fff",
          }}>
            <FunnelIcon onClick={() => setShowFilter(!showFilter)} className={"h-4 w-4 m-3 text-xl font-bold  "} />
          </div>
        </div>
        <section
          className={clsx(
            "transition-all duration-700 ease-in-out",
            { "max-h-[800px] opacity-100  p-3 ": showFilter, "max-h-0 opacity-0": !showFilter }
          )}>
          <div className="items-center grid-cols-4 gap-2 mb-3 space-y-2 md:grid md:space-y-0">

            {author === 'admin' &&
              <div className=" w-md">
                <label
                  className="block text-xs font-bold tracking-wide text-gray-700 uppercase"
                  htmlFor="startDate"
                >
                  Client
                </label>
                <ClientSelector
                  autoSelect
                  selectedValue={metadata.customer}
                  onSelect={(v) => {
                    //console.log({ v });
                    setMetadata((m) => ({ ...m, customer: v?.value }));
                  }}
                />
              </div>
            }


            <div className="w-md">
              <label
                className="block text-xs font-bold tracking-wide text-gray-700 uppercase"
                htmlFor="startDate"
              >
                Date de début
              </label>
              <input
                className="block text-sm w-full h-[40px] px-4 py-2  leading-tight text-gray-700  border ring-gray-300 focus:ring-gray-500 bg-white  border-gray-500 rounded-lg appearance-none focus:outline-none focus:bg-white"
                id="startDate"
                type="date"
                placeholder=""
                value={metadata.startDate}
                onChange={(e) => setFormValue("startDate", e.target.value)}
              />
            </div>

            <div className="w-md">
              <label
                className="block text-xs font-bold tracking-wide text-gray-700 uppercase"
                htmlFor="endDate"
              >
                Date de fin
              </label>
              <input
                className="block text-sm w-full h-[40px] px-4 py-2  leading-tight text-gray-700  border ring-gray-300 focus:ring-gray-500 bg-white  border-gray-500 rounded-lg appearance-none focus:outline-none focus:bg-white"
                id="endDate"
                type="date"
                placeholder=""
                value={metadata.endDate}
                onChange={(e) => setFormValue("endDate", e.target.value)}
              />
            </div>
          </div>
        </section>


        {tableBody.length > 0 && (
          <button
            // onClick={exportData}
            onClick={exportDataToExcel}
            type="button"
            className="mb-2 bg-black flex items-center text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm sm:w-auto px-5 py-2.5 text-center"
            disabled={isExporting}
          >
            <PiExport className="text-white text-[17px] mr-2" />
            <span className="mr-1">Exporter</span>
            {isExporting && <Loader />}
          </button>
        )}
      </div>

      <div className="py-3">
        {data.total ?
          <PaginationComponent
            page={metadata.page}
            handlePageClick={_page => {
              setMetadata(m => ({
                ...m,
                page: _page
              }))
            }}
            totalItems={data.total}
            perPageItems={metadata.perPage}
            setnumberPerPage={_perPage => {
              setMetadata(m => ({
                ...m,
                perPage: _perPage
              }))
            }}
          /> : null}
      </div>


      <TableComponent withAction={false} headerList={tableHeaders} dataLength={dataList.length} isLoading={isLoading}>
        {tableBody?.map((item, i) => {
          return (
            <tr key={i} className={"even:bg-slate-300"}>
              {Object.values(item)?.map((value, index) => (
                <td key={index}>{value}</td>
              ))}
            </tr>
          );
        })}
      </TableComponent>

      <div className="py-3">
        {data.total ?
          <PaginationComponent
            page={metadata.page}
            element="pagination"
            handlePageClick={_page => {
              setMetadata(m => ({
                ...m,
                page: _page
              }))
            }}
            totalItems={data.total}
            perPageItems={metadata.perPage}
            setnumberPerPage={_perPage => {
              setMetadata(m => ({
                ...m,
                perPage: _perPage
              }))
            }}
          /> : null}
      </div>

    </div>
  );
}
