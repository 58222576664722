import React, { useCallback, useEffect, useState } from "react";
import ExpeditorOrDestinatorComponent from "../ExpediteurOrExpediteurComponent.jsx";
import ClassificationComponent from "../ClassificationComponent.jsx";
import { useBasicFormValues } from "../../../hooks/useFormValues.jsx";
import EditPackages from "../EditPackages.jsx";
import { formatShippingData } from "../../../utils/index.js";
import { validateShippingDetails } from "../../../utils/validators.js";
import { toast } from "react-toastify";
import opsService from "../../../services/ops/opsService.js";
import EditCourse from "../EditCourses.jsx";
import { setExpedition } from "../../../features/slices/expeditionSlice.js";
import { useDispatch, useSelector } from "react-redux";
import ExpeditionService from "../../../services/expedition/ExpeditionService.js";
import { AddTrackingModal } from "../../tracking/Form.jsx";
import useDisclosure from "../../../hooks/useDisclosure.jsx";
import InformationModal from "../../Tableaux/Expeditions/DispatchFormModal.jsx";
import BillingCenterAndClient from "../BillingCenterAndClient.jsx";
import { useAppContext } from "../../../context/index.jsx";
import { ShippingDetails } from "../../../store/shippingDetails.js";
import { FichiersTabsComponent } from "./FichiersTabsComponent.jsx";

export const DetailTabsComponent = ({ onSubmit }) => {
  const setExpeditionData = ShippingDetails((state) => state.updateDetails);
  const { expeditor, shipping, receiver, ...expedition } = ShippingDetails(
    (state) => state.details
  );
  const updateShipping = ShippingDetails((state) => state.updateShipping);
  const addInformationImportant = ShippingDetails(
    (state) => state.addInformationImportant
  );

  const {
    isOpen: isAddTrackingModalOpen,
    onClose: onAddTrackingModalClose,
    onOpen: onAddTrackingModalOpen,
  } = useDisclosure();

  const [openAccordion, setOpenAccordion] = useState(false);

  const {
    isOpen: isDispatchModalOpen,
    onOpen: onDispatchModalOpen,
    onClose: onDispatchModalClose,
  } = useDisclosure();

  const dispatch = useDispatch();
  const { isAuth, setIsAuth } = useAppContext();
  const expeditionDetails = ShippingDetails((state) => state.details);

  const {
    data: expeditionData,
    setFormValue,
    setData,
  } = useBasicFormValues(formatShippingData(expedition));

  const [errorMessage, setErrorMessage] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isExpedition, setIsExpedition] = useState(true);

  const { valid, error } = validateShippingDetails({
    ...expeditionData,
    isExpedition,
  });

  const expeditionTypeChanged =
    (expedition.type_expedition === "courses" && isExpedition) ||
    (expedition.type_expedition !== "courses" && !isExpedition);

  useEffect(() => {
    if (expedition) {
      setData(formatShippingData(expedition));
    }
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e?.preventDefault();
        const submitData = {
          ...expeditionData,
          shipping: {
            ...expeditionData.shipping,
            ...expedition.shipping,
          },
          isExpedition,
          interface: isAuth?.spaceName,
          info_supplementaire: expeditionData.detail_info_supplementaire,
        };

        delete submitData.detail_info_supplementaire;

        setErrorMessage(null);
        const { error: _err } = validateShippingDetails(submitData);

        if (_err) {
          setErrorMessage(_err);
          return;
        }
        setIsSubmitting(true);
        if (expeditionTypeChanged) {
          if (isExpedition) {
            submitData.course = null;
            submitData.shipping.type_expedition = "expedition";
          } else {
            submitData.package = null;
            submitData.shipping.type_expedition = "courses";
          }

          console.log({ submitData });

          await opsService.updateShippingType(submitData);
        }

        const { data } = await opsService.updateShipping(submitData);
        if (data.success) {
          // onSubmit?.(submitData);
          toast.success(`Détails mis à jour avec succès`, {
            position: "bottom-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          ExpeditionService.getExpeditionDetails({
            ref: expeditionDetails.ref,
          }).then(
            (data) => {
              const { data: expedition } = data.data;
              dispatch(setExpedition(expedition));
            },
            (error) => {
              console.log(error);
            }
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsSubmitting(false);
      }
    },
    [expeditionData, expeditionTypeChanged, isExpedition]
  );

  const onDetailsChange = useCallback(
    (key, value) => {
      setFormValue("detail_info_supplementaire", {
        ...expeditionData.detail_info_supplementaire,
        [key]: value,
      });
    },
    [setFormValue, expeditionData.detail_info_supplementaire]
  );

  const onDispatchSubmit = useCallback(
    (info) => {
      addInformationImportant(info);
    },
    [setExpeditionData]
  );

  return (
    <div>
      <AddTrackingModal
        expedition={expeditionDetails}
        isOpen={isAddTrackingModalOpen}
        onClose={onAddTrackingModalClose}
        onSubmit={onSubmit}
        type={isAuth?.spaceName}
        setExpeditionData={setExpeditionData}
      />

      <InformationModal
        isOpen={isDispatchModalOpen}
        onClose={onDispatchModalClose}
        onSubmit={onDispatchSubmit}
        selectedData={expeditionDetails}
        type={isAuth?.spaceName}
      />

      {errorMessage && (
        <div className="w-full p-3 mb-3 text-sm text-red-700 bg-red-200 border border-red-500 rounded-lg md:w-1/2">
          {errorMessage}
        </div>
      )}
      <div className={"space-y-2 md:space-y-0 md:grid grid-cols-2 gap-4"}>
        <div className={"h-full"}>
          <ExpeditorOrDestinatorComponent
            UserType={"Expéditeur"}
            data={expeditor}
            shippingId={expedition?.id}
            userType="expeditor"
          />
        </div>

        <div className="h-full">
          <ExpeditorOrDestinatorComponent
            UserType={"Destinataire"}
            data={receiver}
            shippingId={expedition?.id}
            userType="receiver"
          />
        </div>
      </div>
      <div>
        <div className="my-3 ">
          <div className="shadow ">
            <div className="bg-white accordion">
              <div
                className="bg-white accordion-header "
                onClick={() => setOpenAccordion(!openAccordion)}
              >
                <h1>{"reporting Dior ".toUpperCase()}</h1>
                <span
                  className={
                    openAccordion
                      ? "fas fa-chevron-up fs-5"
                      : "fas fa-chevron-down fs-5"
                  }
                ></span>
              </div>
              {openAccordion && (
                <div className="bg-[#E4E6EA] accordion-content">
                  <ClassificationComponent
                    data={expeditionData.detail_info_supplementaire}
                  
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-3 mb-1 col-md-12">
          <BillingCenterAndClient
            expedition={expedition}
            byOps={true}
            onChange={(id) => updateShipping("billing_center_id", id)}
          />
        </div>
        <div className="flex justify-center p-2 my-4 bg-gray-50">
          <div className="border rounded-tl-md rounded-bl-md">
            <div
              className={`text-lg ${
                shipping.type_expedition === "expedition"
                  ? "bg-gray-200"
                  : "bg-transparent"
              } text-gray-800 cursor-pointer px-4 py-1`}
              onClick={() => {
                updateShipping("type_expedition", "expedition");
              }}
            >
              Expédition
            </div>
          </div>
          <div className="border rounded-tr-md rounded-br-md">
            <div
              className={`text-lg ${
                shipping.type_expedition === "courses"
                  ? "bg-gray-200"
                  : "bg-transparent"
              } text-gray-800 cursor-pointer px-8 py-1`}
              onClick={() => {
                updateShipping("type_expedition", "courses");
              }}
            >
              Course
            </div>
          </div>
        </div>

        {shipping.type_expedition === "expedition" ? (
          <EditPackages
            expedition={expeditionData}
            onExpeditionDataChange={setFormValue}
            expeditionDetails={expeditionDetails}
            onAddTrackingModalOpen={onAddTrackingModalOpen}
            onInfosSupplementaireChange={onDetailsChange}
            onDispatchModalOpen={onDispatchModalOpen}
          />
        ) : (
          <EditCourse
            expedition={expeditionData}
            onExpeditionDataChange={setFormValue}
            expeditionDetails={expeditionDetails}
            onAddTrackingModalOpen={onAddTrackingModalOpen}
            onInfosSupplementaireChange={onDetailsChange}
            onDispatchModalOpen={onDispatchModalOpen}
            
          />
        )}

        {errorMessage && (
          <div className="bg-red-100 mt-4 text-red-600 px-4 py-2 rounded-md text-[13px]">
            {errorMessage}
          </div>
        )}
        {/* <div className="flex justify-end mt-4 mb-16">
          <button
            type="submit"
            className={` ${valid ? "cursor-pointer" : "cursor-not-allowed"
              } text-white bg-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm px-14 py-2.5 text-center mt-4 max-w-xs`}
            onClick={handleSubmit}
            disabled={isSubmitting}
          
          >
            {isSubmitting ? "Enregistrement..." : "Enregistrer"}
          </button>
        </div> */}

        <div className="w-full">
          <FichiersTabsComponent expedition={expedition} formVisible={false} />
        </div>
      </div>
    </div>
  );
};
