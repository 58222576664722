import React, { useCallback, useEffect, useMemo } from 'react'
import useAuth from './useAuth';
import { ADMIN_MENUS } from '../components/Utils/Selectors/MenuSelector';

export default function useAuthorization() {
    const { user } = useAuth();

    useEffect(() => {
        console.log({ user })
    }, [user])

    const menus = user?.menus //"clients, centers, templates, utilisateurs, histories, facturationCodes, fournisseurs, fuels, salaries, statsCourses, statsFreight" //user?.shippingTabs;
    const shippingTabs = user?.onglet_expedition

    const isShippingTabAllowed = useMemo(() => {
        if (shippingTabs) {
            return {
                details: shippingTabs.includes("details"),
                tracking: shippingTabs.includes("tracking"),
                comments: shippingTabs.includes("comments"),
                pod: shippingTabs.includes("pod"),
                manifest: shippingTabs.includes("manifest"),
                facturations: shippingTabs.includes("facture"),
                fichiers: shippingTabs.includes("fichiers"),
                mails: shippingTabs.includes("mails")
            }
        } else {
            return {
                details: true,
                tracking: true,
                comments: true,
                pod: true,
                manifest: true,
                facturations: true,
                fichiers: true,
                mails: true,
            }
        }
    }, [shippingTabs])

    const isAdminMenuAllowed = useMemo(() => {
        if (menus) {
            const allowedAdminMenuItems = {};
            ADMIN_MENUS.forEach(menu => {
                allowedAdminMenuItems[menu.value] = menus.includes(menu.value);
            });

            return allowedAdminMenuItems;
        } else {
            const allowedAdminMenuItems = {};
            ADMIN_MENUS.forEach(menu => {
                allowedAdminMenuItems[menu.value] = true;
            });

            return allowedAdminMenuItems;
        }
    }, [menus]);

    const isOpsMenuAllowed = useMemo(() => {
        const allowedMenus = {
            imports: true,
            exports: true
        }

        if (user) {
            if (user.import === 0) {
                allowedMenus.imports = false
            }

            if (user.export === 0) {
                allowedMenus.exports = false
            }
            //return allowedMenus
        }

        return allowedMenus
    }, [user]);

    //console.log({ isOpsMenuAllowed })

    return {
        isShippingTabAllowed,
        isAdminMenuAllowed,
        isOpsMenuAllowed
    }
}
