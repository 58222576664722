import React from 'react'
import LoaderTableau from '../../tableauComponent/LoaderTableau'
import {DetailTabsComponent} from './DetailTabsComponent'
import { Manifest } from './Manifest'
import AddTrackingComponent from '../../tracking/AddTrackingComponent'
import { PodTabsComponent } from './PodTabsComponent'
import { CommentaireTabsComponent } from './CommentaireTabsComponent'
import { FactureTabsComponent } from './FactureTabsComponent'

import { ShippingDetails } from '../../../store/shippingDetails'
import useAuthorization from '../../../hooks/useAuthorization'
import { FichiersTabsComponent } from './FichiersTabsComponent'
import { EmailTabsComponent } from './EmailTabsComponent'

export const OpsShippingDetails = ({ activeTabs }) => {

    const expeditionData = ShippingDetails((state)=>state.details);

    const { isShippingTabAllowed } = useAuthorization()

    const setExpeditionData = (ss)=>{
        console.log('ddee')
    }

    return (
        <div>
            {(expeditionData.id) ? (
                <div className="tab-content twitter-bs-wizard-tab-content">
                    {activeTabs === "tabsDetail" &&
                        <div className="tab-pane active" id="tabsDetail">
                            {isShippingTabAllowed.details &&
                                <DetailTabsComponent/>}
                        </div>
                    }

                    {activeTabs === "tabsFichiers" && <>
                        {isShippingTabAllowed.fichiers && <div className="tab-pane" id="tabsFichiers">
                            <FichiersTabsComponent expedition={expeditionData} />
                        </div>}
                    </>}

                    {activeTabs === "tabsEmail" && <>
                        {isShippingTabAllowed.mails && <div className="tab-pane" id="tabsEmail">
                            <EmailTabsComponent expedition={expeditionData} />
                        </div>}
                    </>}

                    {activeTabs === "tabsManifest" && <>
                        {isShippingTabAllowed.manifest && <div className="tab-pane" id="tabsManifest">
                            <Manifest expedition={expeditionData} />
                        </div>}
                    </>}

                    {activeTabs === "tabsTracking" && <>
                        {isShippingTabAllowed.tracking && <div className="tab-pane" id="tabsTracking">
                            <AddTrackingComponent expedition={expeditionData} setExpeditionData={setExpeditionData} />
                        </div>}
                    </>}

                    {activeTabs === "tabsPod" && <>
                        {isShippingTabAllowed.pod && <div className="tab-pane" id="tabsPod">
                            <PodTabsComponent shipping_id={expeditionData.id} />
                        </div>}
                    </>}

                    {activeTabs === "tabsCommentaire" && <>
                        {isShippingTabAllowed.comments && <div className="tab-pane" id="tabsCommentaire">
                            <CommentaireTabsComponent expedition={expeditionData} />
                        </div>}
                    </>}

                    {activeTabs === "tabsFacturation" && <>
                        {isShippingTabAllowed.facturations && <div className="tab-pane" id="tabsFacturation">
                            <FactureTabsComponent />
                        </div>}
                    </>}

                </div>
            ) : (
                <LoaderTableau />
            )}
        </div >
    )
}
