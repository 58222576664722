import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { TbArrowsUpDown, TbFileDownload } from "react-icons/tb";
import xlsx from "export-json-as-xlsx"
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { LinkData, exnetSpaceColor, urlSplit } from "../../../../utils/Functions/otherFunctions";
import { ConfirmModal } from "../../../Utils/DeleteConfirm";
import factureService from "../../../../services/facture/factureService";
import useDisclosure from "../../../../hooks/useDisclosure";
import LoaderTableau from "../../../tableauComponent/LoaderTableau";
import { trierTableau } from "../../../../utils/Functions/tableFilter";
import { toFixed2 } from "../../../../utils";
import { ArrowsUpDownIcon } from "@heroicons/react/24/outline";
import { TableComponent } from "../../../TableauComponent";

const entete_liste_globale23 = [
    {
        filterKey: "awb",
        label: "AWB"
    },
    {
        filterKey: "billingCenterName",
        label: "Demandeur"
    },
    {
        filterKey: "expeditorFullname",
        label: "Expéditeur"
    },
    {
        filterKey: "expeditorCity",
        label: "Ville d'expédition"
    },
    {
        filterKey: "receiverFullname",
        label: "Destinataire"
    },
    {
        filterKey: "receiverCity",
        label: "Ville de destination"
    },
    {
        filterKey: "createdAt",
        label: "Date de demande"
    },
    {
        filterKey: "pickupDate",
        label: "Date d'enlèvement"
    },
    {
        filterKey: "deliveryDate",
        label: "Date de livraison"
    },
    {
        filterKey: "customerRef",
        label: "Ref interne"
    },
    {
        filterKey: "bonDeCommende",
        label: "Libellé"
    },
    {
        filterKey: "description",
        label: "Description"
    },
    {
        filterKey: "commentaire",
        label: "Commentaire"
    },
    {
        filterKey: "prixUnitaire",
        label: "Prix unitaire"
    },
    {
        filterKey: "quantite",
        label: "Quantité"
    },
    {
        filterKey: "fuel",
        label: "Gazoil"
    },
    {
        filterKey: "montant",
        label: "Montant"
    },
    {
        filterKey: "tva",
        label: "TVA"
    },
    {
        filterKey: "total",
        label: "Total"
    }
]


const entete_liste_globale = [
    {
        label: "AWB",
        cle: 'awb'
    },
    {
        label: "Demandeur",
        cle: 'billing_center.billing_center_name'
    },
    {
        label: "Expéditeur",
        cle: 'expeditor.fullname'
    },
    {
        label: "Ville\nd'expédition",
        cle: 'expeditor.city'
    },
    {
        label: "Destinataire",
        cle: 'receiver.fullname'
    },
    {
        label: "Ville de\ndestination",
        cle: 'receiver.city'
    },
    {
        label: "Date de\ndemande",
        cle: 'created_at'
    },
    {
        label: " Date\nd'enlèvement",
        cle: 'pickup_date'
    },
    {
        label: "Date de\nlivraison",
        cle: 'delivery_date'
    },
    {
        label: "Ref \n interne",
        cle: 'customer_ref'
    },
    {
        label: "Libellé",
        cle: 'bon_de_commende'
    },
    {
        label: "Description",
        cle: 'v'
    },
    {
        label: "Commentaire",
        cle: 'v'
    },
    {
        label: "Prix unitaire",
        cle: 'montant'
    },
    {
        label: "Quantité",
        cle: 'shipping_ids'
    },
    {
        label: "Gazoil",
        cle: 'fuel'
    },
    {
        label: "Montant",
        cle: 'total'

    },
    {
        label: "TVA",
        cle: "tva"
    },
    {
        label: "Total",
        cle: 'total'
    }

];


const entete_liste_globale2 = [
    "Centre de Facturation",
    "AWB",
    "Demandeur",
    "Expéditeur",
    "Ville d'expédition",
    "Destinataire",
    "Ville de destination",
    "Date de demande",
    " Date d'enlèvement",
    "Date de livraison",
    "Ref interne",
    "Libellé",
    "Description",
    "Commentaire",
    "Prix unitaire HT",
    "Quantité",
    "Gazoil HT",
    "Montant HT",
    "TVA",
    "Total TTC"


];

const entete_expedition = [
    "REF",
    "EXP",
    "REF interne",
    "Code \ndonneur",
    "Address/Ville\n/code postale",
    "DEST",
    "Code receveur",
    "Address/Ville\n/code postale",
    "TRACK"
];


const excelHeader = [
    "ref",
    "demandeur",
    "Exp",
    "expCity",
    "Dest",
    "DestCity",
    "createdAt",
    "pickup",
    "delivery",
    "refInt",
    "libelle",
    "description",
    "commentaire",
    "PrixU",
    "qte",
    "fuel",
    "Amount",
    "tva",
    "total",
    "bc"
]


export default function ExtractionList({
    items,
    setItems,
    isLoading,
    billingCenter
}) {

    const { exnetSpace } = urlSplit()
    const [entete, setEntete] = useState([]);
    const { pathname } = useLocation();
    const [selectedItem, setSelectedItem] = useState(null);
    const [filterOrder, setfilterOrder] = useState('desc');
    const [filterBy, setfilterBy] = useState('ref');

    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        setEntete(entete_expedition);
    }, []);

    const modifiedText = (text) => {
        return text
            ? text.split(" ").map((word, index) => {
                return index !== 0 && index % 3 === 0
                    ? [<br key={index} />, word]
                    : " " + word;
            })
            : "-";
    };


    const filteredArray = items.reduce((uniqueArray, currentItem) => {
        // Check if an object with the same value exists in the uniqueArray
        const existingItem = uniqueArray.find(item => item.id === currentItem.id);

        // If not found, add the current item to the uniqueArray
        if (!existingItem) {
            uniqueArray.push(currentItem);
        }

        return uniqueArray;
    }, []);

    // const sortedArray = filteredArray.sort((a, b) => {
    //     return parseInt(b.id) - parseInt(a.id);
    // });

    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();
    const offset = currentPage * 25;
    const currentPageData = filteredArray.slice(offset, offset + 25);

    const handlePageClick = (event) => {
        setCurrentPage(event.selected);
    };
    const lien = window.location.pathname.split("/");
    let { espace } = LinkData(lien);

    const downloadPDF = (item) => {
        const pdfUrl = `${process.env.REACT_APP_BACKEND_HOST}/${item.doc_facture}`;
        setSelectedItem(item);

        //const url = URL.createObjectURL(pdfBlob);
        const fileName = pdfUrl.split("factures/")[1]
        const a = document.createElement("a");
        a.href = pdfUrl;
        a.target = "_blank"
        a.download = fileName + ".pdf";
        document.body.appendChild(a);
        a.click();

        // Clean up
        //window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        setSelectedItem(null)
    }

    const onRedition = (item) => {
        setSelectedItem(item);
        onOpen()
    }

    //console.log({ selectedItem })

    const onReedited = useCallback(() => {
        const data = items.map(item => selectedItem?.id === item.id ? ({
            ...item,
            active_for_reedited: 1
        }) : item);

        setItems(d => ({
            ...d,
            data
        }));

        setSelectedItem(null)
    }, [selectedItem, items])


    const DownloadExcelCustom = async () => {
        let workbook = new ExcelJS.Workbook();
        let worksheet = workbook.addWorksheet(billingCenter?.label,
            {
                headerFooter: {
                    firstHeader: "En-tête personnalisé"
                }
            }
        );

        // Add different header & footer for the first page
        worksheet.headerFooter.differentFirst = true;
        worksheet.headerFooter.firstHeader = "Hello Exceljs";
        worksheet.headerFooter.firstFooter = "Hello World"

        // // Définir les en-têtes
        // worksheet.columns = [
        //     { header: 'Colonne 1', key: 'col1', width: 30 },
        //     { header: 'Colonne 2', key: 'col2', width: 30 },
        //     // Autres colonnes...
        // ];

        let newValueTable = []
        entete_liste_globale2.forEach((item, index) => {
            newValueTable.push({ header: item, key: excelHeader[index], width: 30 })
        })

        worksheet.columns = newValueTable;

        worksheet.getRow(1).font = { bold: true };


        // Ajouter des données
        let newData = [];

        items.forEach(item => {
            let newElement = {
                ref: `${item.shipping?.billing_center?.type === "paris" ? "EX" : item.shipping?.billing_center?.type === "medical" ? "EM" : "ES"}-${item?.shipping?.ref ?? ''}`,
                demandeur: item?.shipping.username,
                Exp: item?.shipping?.expeditor?.company,
                expCity: item?.shipping?.expeditor?.city,
                Dest: item?.shipping?.receiver?.company,
                DestCity: item?.shipping?.receiver?.city,
                createdAt: moment(item?.shipping?.created_at).format("DD-MM-YYYY HH:mm") || "-",
                pickup: (moment(item?.shipping?.pickup_date).format("DD-MM-YYYY") || "-") + " " + item?.shipping?.pickup_time,
                delivery: (moment(item?.shipping?.pod[0]?.date).format("DD-MM-YYYY") || "-") + " " + item?.shipping?.pod[0]?.heurs,
                refInt: item?.shipping?.customer_ref || "-",
                libelle: item?.facturation_line.label || " ", //item?.bon_de_commende,
                description: item?.facturation_line?.description || " ",
                commentaire: item?.comment || " ",// returnComment(element),
                PrixU: (item?.prix / 1).toFixed(2).toString().replace(".", ','),
                qte: item?.qte,
                fuel: (item?.fuel / 1).toFixed(2).toString().replace(".", ','),
                Amount: (item?.total / 1).toFixed(2).toString().replace(".", ','),
                tva: (item?.tva / 1).toFixed(2).toString().replace(".", ','),
                total: (item?.total_final / 1).toFixed(2).toString().replace(".", ','),
                bc: billingCenter?.label

            };

            newData.push(newElement)
        })

        const data = [...newData]

        data.forEach(row => {
            worksheet.addRow(row);
        });

        // Appliquer les styles de bordure à toutes les lignes, y compris les données
        worksheet.eachRow(function (row, rowNumber) {
            row.eachCell(function (cell, colNumber) {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });
        });

        // Écrire dans un tampon et sauvegarder le fichier
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), billingCenter?.label?.split(' ')?.join("_") + '.xlsx');
    };



    const DownloadExcelCustom2 = async () => {
        let workbook = new ExcelJS.Workbook();
        let worksheet = workbook.addWorksheet('Liste extraction', {
            headerFooter: { firstHeader: "Hello Exceljs", firstFooter: "Hello World" }
        });

        let bill_center = items[0].shipping.billing_center;

        // Fusion des cellules pour les 5 premières lignes et 15 premières colonnes
        const A2 = worksheet.getCell('B2');
        A2.value = "Adresse de Facturation";
        A2.font = { bold: true };
        const A3 = worksheet.getCell('B3');
        A3.value = bill_center?.billing_center_name;
        A3.font = { bold: true };
        const A4 = worksheet.getCell('B4');
        A4.value = bill_center?.address;
        A4.font = { bold: true };
        const A5 = worksheet.getCell('B5');
        A5.value = `${bill_center?.postal_code}-${bill_center?.city}-${bill_center?.country}`;
        A5.font = { bold: true };
        const A6 = worksheet.getCell('B6');
        A6.value = `TVA Intracom :  ${bill_center?.intra_vatnumber}`;
        A6.font = { bold: true };


        // Définition des colonnes avec leurs en-têtes
        let newValueTable = [];
        entete_liste_globale2.forEach((item, index) => {
            newValueTable.push(item);
        });

        // Ajout des colonnes avec des en-têtes à partir de la ligne 10
        let row10 = worksheet.getRow(10);
        row10.values = newValueTable;

        // Appliquer une largeur minimale de 40 à chaque colonne
        newValueTable.forEach((label, index) => {
            const columnKey = index + 1; // La première colonne a l'index 1
            const column = worksheet.getColumn(columnKey);
            column.width = Math.max(40, label?.length); // Utilisez la longueur du libellé comme largeur minimale
        });

        // Appliquer le style de bordure aux en-têtes de colonne
        row10.font = { bold: true };
        row10.eachCell(cell => {
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        });

        // Ajouter des données à partir de la ligne 11
        let rowNumber = 11; // Commencer à partir de la ligne 11


        let prix = 0.0;
        let qte = 0.0;
        let fuel = 0.0;
        let total = 0.0;
        let tva = 0.0;
        let total_final = 0.0;

        let nbr = 0;
        items.forEach(item => {

            let rowData = [
                billingCenter?.label,
                `${item.shipping?.billing_center?.type === "paris" ? "EX" : item.shipping?.billing_center?.type === "medical" ? "EM" : "ES"}-${item?.shipping?.ref ?? ''}`,
                item?.shipping.username,
                item?.shipping?.expeditor?.company,
                item?.shipping?.expeditor?.city,
                item?.shipping?.receiver?.company,
                item?.shipping?.receiver?.city,
                moment(item?.shipping?.created_at).format("DD-MM-YYYY HH:mm") || "-",
                (moment(item?.shipping?.pickup_date).format("DD-MM-YYYY") || "-") + " " + item?.shipping?.pickup_time,
                (moment(item?.shipping?.pod[0]?.date).format("DD-MM-YYYY") || "-") + " " + item?.shipping?.pod[0]?.heurs,
                item?.shipping?.customer_ref || "-",
                item?.facturation_line.label || " ", //item?.bon_de_commende,
                item?.facturation_line?.description || " ",
                item?.comment || " ",// returnComment(element),
                (item?.prix / 1),
                item?.qte,
                (item?.fuel / 1),
                (item?.total / 1),
                (item?.tva / 1),
                (item?.total_final / 1)
            ];

            prix += parseFloat(item?.prix / 1);
            qte += parseInt(item?.qte);
            fuel += parseFloat(item?.fuel / 1);
            total += parseFloat(item?.total / 1);
            //tva += parseFloat(item?.tva / 1);
            //total_final += parseFloat(item?.total_final / 1);

            // Ajouter la ligne de données à la feuille de calcul
            worksheet.addRow(rowData, rowNumber);
            rowNumber++; // Incrémenter le numéro de ligne
            nbr++
        });

        tva = toFixed2(total * 0.2);

        total_final = toFixed2(parseFloat(total * 1.2))


        let totalprix = { formula: `ROUND(SUM(O11:O${nbr + 10}), 2)` };
        let totalqte = { formula: `ROUND(SUM(P11:P${nbr + 10}), 2)` };
        let totalfuel = { formula: `ROUND(SUM(Q11:Q${nbr + 10}), 2)` };
        let totale = { formula: `ROUND(SUM(R11:R${nbr + 10}), 2)` };
        let totaltva = { formula: `ROUND(SUM(S11:S${nbr + 10}), 2)` };
        let total_total_final = { formula: `ROUND(SUM(R11:R${nbr + 10})+SUM(S11:S${nbr + 10}), 2)` };


        // let allDataTable = ["", "", "", "", "", "", "", "", "", "", "", "", "", "Total", prix, qte, fuel, total, tva, total_final]

        let allDataTable = ["", "", "", "", "", "", "", "", "", "", "", "", "", "Total", totalprix, totalqte, totalfuel, totale, totaltva, total_total_final];

        worksheet.addRow(allDataTable, rowNumber + 1);
        let nextRow = worksheet.getRow(rowNumber + 1);
        nextRow.font = { bold: true };
        nextRow.eachCell(cell => {
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        });

        // Appliquer les styles de bordure uniquement aux données
        worksheet.eachRow(function (row, rowNumber) {
            if (rowNumber > 10) {
                row.eachCell(function (cell, colNumber) {
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                });
            }
        });

        let sizeArray = items.length;
        let lastElement = items[sizeArray - 1]['shipping'];
        let firstElement = items[0]['shipping']

        let month = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

        let fileName = `${bill_center?.billing_center_name} ${month[moment(firstElement?.created_at).format("MM") - 1]} ${moment(firstElement?.created_at).format("YYYY")}.xlsx`


        // Écrire dans un tampon et sauvegarder le fichier
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), fileName);
    };

    const returnComment = (value) => {
        if (value) {
            let newValue = value?.comments;
            if (newValue?.length > 0) {
                let { content } = newValue[newValue?.length - 1]
                return (content)
            } else {
                return ''
            }
        }
    }

    const updateFilterListe2 = (filterKey) => {
        //console.log({ filterKey })
        if (filterKey) {
            setfilterBy(filterKey)
            let newOrderFilter = filterOrder === 'desc' ? 'asc' : 'desc'
            setfilterOrder(newOrderFilter)

        }
    }

    const returnDate = (value) => {
        let newValue = value
        console.log(newValue)

        return newValue && newValue !== undefined ? moment(newValue?.date)?.format("DD-MM-YYYY") + " " + newValue?.heurs : ""

        // return 

    }


    const newTable = trierTableau(filteredArray, filterBy, filterOrder)

    return (
        <div>
            {items.length > 0 && <div>
                <button /* style={{backgroundColor: exnetSpaceColor}} */ className={'btn'} onClick={DownloadExcelCustom2}>Télécharger</button>
            </div>}

            <TableComponent withAction={false} headerList={entete_liste_globale23} dataLength={newTable.length} isLoading={isLoading}>
                {newTable.map((item, index) => (
                    <>

                        <tr
                            key={index + '-' + index}
                            className="cursor-pointer"
                            style={{
                                backgroundColor: item.active_for_reedited ? "#ffccd5" : "transparent"
                            }}
                        >
                            <>
                                <td style={{ color: exnetSpaceColor(item.type) }} >
                                    {item.shipping?.billing_center?.type === "paris" ? "EX" : item.shipping?.billing_center?.type === "medical" ? "EM" : "ES"}-{item?.shipping?.ref ?? ''}
                                </td>
                                <td>
                                    {item?.shipping.username}
                                </td>
                                <td>
                                    {item?.shipping?.expeditor?.company}
                                </td>
                                <td>
                                    {item?.shipping?.expeditor?.city}
                                </td>
                                <td>
                                    {item?.shipping?.receiver?.company}
                                </td>
                                <td>
                                    {item?.shipping?.receiver?.city}
                                </td>
                                <td>
                                    {moment(item?.shipping?.created_at).format("DD-MM-YYYY HH:mm")}
                                </td>
                                <td>
                                    {moment(item?.shipping?.pickup_date).format("DD-MM-YYYY")} {item?.shipping?.pickup_time}
                                </td>
                                <td>
                                    {returnDate(item?.shipping?.pod[item?.shipping?.pod.length - 1])}
                                </td>
                                {/* pickup_date */}
                                <td>
                                    {item?.shipping?.customer_ref}
                                </td>
                                <td>
                                    {/* {item?.bon_de_commende} */}
                                    {item?.facturation_line?.label}
                                </td>
                                <td>
                                    {item?.facturation_line?.description}
                                </td>
                                <td>
                                    {item?.comment}
                                </td>
                                <td>
                                    {item?.prix}
                                </td>
                                <td>
                                    {1}
                                </td>
                                <td>
                                    {item?.fuel}
                                </td>
                                <td>
                                    {item?.total}
                                </td>
                                <td>
                                    {item?.tva}
                                </td>
                                <td>
                                    {item?.total_final}
                                </td>


                            </>

                        </tr >
                        {/* hdsfhsdfdsfsdfdsf */}


                    </>

                ))
                }
            </TableComponent>

            {selectedItem && <ConfirmModal
                title={`Réédition de facture`}
                description={"Etes-vous sûr de vouloir rééditer cette facture? Cela entraînera la suppression de la facture actuelle"}
                isOpen={isOpen}
                onClose={onClose}
                confirmRequest={() => factureService.reeditFacture({ id: selectedItem.id })}
                onConfirm={onReedited}
                successText={"Facture rééditée avec succès"}
                submitText={"Oui, rééditer"}
                submittingText={"Réédition..."}
            />}
        </div >
    );
}

