import React, { useEffect, useState } from "react";
import DetailAgentComponent from "./detailAgentComponent";
import ColisClientDetailComponent from "./colisClientDetailComponent";
import ReferenceClientExpeditionComponent from "./referenceClientExpeditionComponent";
import TableauTrackingExpeditionComponent from "./tableauTrackingExpeditionComponent";
import ExpeditionService from "../../../services/expedition/ExpeditionService";
import { useNavigate, useParams } from "react-router-dom";
import LoaderTableau from "../../tableauComponent/LoaderTableau";
import { refPrefixer, urlSplit } from "../../../utils/Functions/otherFunctions";

function ClientExpeditionDetailComponent(props) {
    const [Expedition, setExpedition] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const { identifiant } = useParams();
    const { exnetSpace } = urlSplit()
    const navigate = useNavigate();
    const { expeditionName } = urlSplit()

    if (!identifiant && typeof identifiant === isNaN) {
        navigate(-1);
    }

    useEffect(() => {
        ExpeditionService.getExpeditionDetails({ ref: identifiant }).then(
            (data) => {
                let { success, data: result } = data.data
                setExpedition({ ...result })
                setIsLoading(false)
            },
            (error) => {
                setIsLoading(false)
                console.log(error)
            }
        )
    }, []);


    return (
        <>
            {!isLoading ? <>

                <span className={"text-xl font-semibold "}>{Expedition.type_expedition === 'courses' ? "COURSE EXPRESS" : "EXPéDITION".toUpperCase()} :  {refPrefixer(exnetSpace)}{identifiant}</span>
                <div className="items-stretch justify-between w-full gap-4 mt-6 space-y-6 md:flex md:space-y-0">
                    <div className="flex-1">
                        <ReferenceClientExpeditionComponent Expedition={Expedition} />
                    </div>
                    <div className="flex-1">
                        <ColisClientDetailComponent packages={Expedition.packages} courses={Expedition.courses} dataExpedition={Expedition} />
                    </div>
                </div>

                <div className="items-stretch justify-between w-full gap-4 my-6 space-y-6 md:flex md:space-y-0">
                    <div className="flex-1">
                        <DetailAgentComponent agent={"expediteur"} details={Expedition.expeditor} instruction={Expedition.pickup_instruction} date={Expedition.pickup_date?.split(' ')[0]} heure={Expedition.pickup_time?.split(':').slice(0, 2).join(':')} />
                    </div>
                    <div className="flex-1">
                        {" "}
                        <DetailAgentComponent agent={"destinataire"} details={Expedition.receiver} instruction={Expedition.delivery_instruction} date={Expedition.delivery_date?.split(' ')[0]} heure={Expedition.delivery_time?.split(':').slice(0, 2).join(':')} />
                    </div>
                </div>

                <div className="my-3">
                    <TableauTrackingExpeditionComponent details={{ suivis: Expedition?.suivis, pod: Expedition?.pod }} createdAt={Expedition?.created_at} />
                </div>
            </> : <>
                <div className="flex items-center justify-between h-screen">
                    <LoaderTableau />
                </div>

            </>}
        </>
    );
}

export default ClientExpeditionDetailComponent;
