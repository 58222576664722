import React, { useCallback, useEffect, useState } from "react";
import { useBasicFormValues } from "../../hooks/useFormValues";
import { useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import opsService from "../../services/ops/opsService";
import { toast } from "react-toastify";
import { validateTrackingData } from "../../utils/validators";
import ModalComponent from "../Utils/Modal/Index";
import { getTimeFromDate } from "../../utils";
import CoursierSelector from "../Utils/Selectors/CoursierSelector";
import AddTrackingComponent from "./AddTrackingComponent";
import { SwitchComponent } from "../Utils/Index";
import { userService } from "../../services/user/UserService";
import { useAppContext } from "../../context";
import { ShippingDetails } from "../../store/shippingDetails";
import clsx from "clsx";

export default function AddTrackingForm({
  data,
  template,
  expedition,
  onSubmit,
  onClose,
  updatedSelectedData,
}) {
  const addSuivis = ShippingDetails((state) => state.addSuivis);
  const { isAuth, setIsAuth } = useAppContext();
  const selectedValue = { ...data };
  const initialValue = {
    details: "",
    heur_suivi: "",
    date_suivi: "",
    agents: "",
    chaufeur: "",
    visible: 1,
    problem: 0,
    mail: 0,
    sms: 0,
    shipping_id: "",
    isparis: isAuth?.spaceName === "paris" ? 1 : 0,
    ismedical: isAuth?.spaceName === "medical" ? 1 : 0,
    isservice: isAuth?.spaceName === "service" ? 1 : 0,
    isdriver: 0,
  };


  // const {
  //   data: trackingData,
  //   updateTrackingData,
  //   setData,
  // } = useBasicFormValues(initialValue);


  const [suivis, setSuivis] = useState([]);
  const [status, setStatus] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const fetchDetails = ShippingDetails((state) => state.fetchDetails);
  const trackingData = ShippingDetails((state) => state.trackingData);
  const updateTrackingData = ShippingDetails((state) => state.updateTrackingData);
  const setTrackingData = ShippingDetails((state) => state.setTrackingData);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const STATUS = {
    SENDING_MAIL: "SENDING_MAIL",
    SENDING_SMS: "SENDING_SMS",
  };

  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      updateTrackingData("agents", isAuth.name);
    }
  }, [user]);

  useEffect(() => {
    if (expedition) {
      //console.log({ expedition })
      updateTrackingData("shipping_id", expedition.id);
    }
  }, [expedition]);

  useEffect(() => {
    if (data) {
      // setData((v) => ({ ...v, ...data }));
      setTrackingData({
        ...data,
        isparis: isAuth?.spaceName === "paris" ? 1 : 0,
        ismedical: isAuth?.spaceName === "medical" ? 1 : 0,
        isservice: isAuth?.spaceName === "service" ? 1 : 0
      });
    }
  }, [data]);

  const onChaufeurSelect = (v) => {
    updateTrackingData("chaufeur", v);
  };

  const sendMailAndSms = useCallback(
    async (_status) => {
      try {
        setStatus(_status);

        const actionType = _status === STATUS.SENDING_MAIL ? "mail" : "sms";

        const { data } = await opsService.sendMailOrSms({
          type: actionType,
          shipping_id: expedition.id,
          interface: isAuth?.spaceName,
        });

        if (data.success) {
          setStatus("");
          toast.success(
            `${actionType === "mail" ? "Mail" : "Sms"} envoyé avec succès`,
            {
              position: "bottom-center",
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
    [STATUS.SENDING_MAIL, expedition?.id]
  );

  const sendingMail = status === STATUS.SENDING_MAIL;
  const sendingSms = status === STATUS.SENDING_SMS;
  const [userInfo, setUserInfo] = useState({ ...expedition?.user });

  const handleSubmit = useCallback(async () => {
    let createdAt = new Date(expedition.created_at).getTime() - 86400000 * 7;
    let newDate = new Date(trackingData.date_suivi).getTime();

    if (createdAt <= newDate) {
      try {
        const { error: _err } = validateTrackingData(trackingData);

        if (_err) {
          toast.error(`${_err}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          const submitData = {
            ...trackingData,
            shipping_id: expedition.id,
            template: template ? 1 : 0,
          };
          setIsSubmitting(true);

          const { data } = await opsService.addTracking(submitData);
          if (data.success) {
            onSubmit?.(data.data);

            setSuivis((s) => [data.data, ...s]);

            if (updatedSelectedData) {
              // Pour mettre à jour en passant par le tableau
              let newValue = { ...selectedValue };
              newValue?.suivis?.push(data.data);
              updatedSelectedData({ ...newValue });
            }

            addSuivis(data.data);
            fetchDetails(expedition.ref);

            const d = new Date();

            const date = d.toISOString().split("T")[0];
            const heure = getTimeFromDate();

            setTrackingData({
              ...initialValue,
              agents: isAuth.name,
              shipping_id: expedition.id,
              date_suivi: date,
              heur_suivi: heure,
              isparis: isAuth?.spaceName === "paris" ? 1 : 0,
              ismedical: isAuth?.spaceName === "medical" ? 1 : 0,
              isservice: isAuth?.spaceName === "service" ? 1 : 0
            });

            toast.success(`Le suivi a été enregistré avec succès`, {
              position: "bottom-center",
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            if (onClose) {
              onClose();
            }
          }
        }
      } catch (error) {
        console.error(error);
        if (error.response?.status === 400) {
          toast.error(error.response.data.error, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("Une erreur est survenue", {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.error("Impossible de mettre cette date de suivis", {
        position: "top-center",
      });
    }
  }, [trackingData, expedition, template, user]);

  useEffect(() => {
    const d = new Date();

    const date = d.toISOString().split("T")[0];
    const heure = getTimeFromDate();

    updateTrackingData("date_suivi", date);
    updateTrackingData("heur_suivi", heure);
  }, []);

  const updateUserInfo = async (value) => {
    try {
      setisLoading(true);
      const toastsId = toast.loading("Modification en cours ...", {
        position: "bottom-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      userService
        .updateUserByOps({
          id: expedition?.user?.id,
          tracking_mail: value,
        })
        .then(
          (response) => {
            console.log(response);
            toast.update(toastsId, {
              render: "Modifcation effecutée",
              type: "success",
              isLoading: false,
              autoClose: 1000,
              position: "bottom-center",
            });
            setisLoading(false);
            setUserInfo({ ...userInfo, tracking_mail: value });
            const payload = { ...user, ...response.data.data };
          },
          (error) => {
            console.log(error);
            toast.update(toastsId, {
              render: "Modification non effecuté",
              type: "error",
              isLoading: false,
              autoClose: 1000,
              position: "bottom-center",
            });

            setisLoading(false);
          }
        );
    } catch (error) {
      console.log(error);
      toast.error("Une erreur lors de la soumission", {
        position: "bottom-center",
        theme: "light",
        autoClose: 1000,
      });
      setisLoading(false);
    }
  };

  const verifyDate = (value) => {
    updateTrackingData("date_suivi", value);
  };

  return (
    <div>
      <div className="grid-cols-2 space-y-2 md:gap-3 md:grid md:space-y-0">
        <div className="col-span-2">
          <textarea
            rows={5}
            cols={5}
            className={
              "px-2 focus:border-gray-300 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-gray-300  focus:ring-2 text-base sm:leading-6 h-auto border-gray-300 focus:ring-gray-300 text-black "
            }
            placeholder={"Description"}
            value={trackingData.details}
            onChange={(e) => updateTrackingData("details", e.target.value)}
          ></textarea>
        </div>
        <div className="col-md-6">
          <div className="relative ">
            <input
              type="date"
              className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full pl-10 p-2.5      "
              placeholder="Select date"
              value={trackingData.date_suivi}
              onChange={(e) => verifyDate(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="time"
              className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full pl-10 p-2.5"
              placeholder={"Heure"}
              value={trackingData.heur_suivi}
              onChange={(e) => updateTrackingData("heur_suivi", e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group ">
            <label
              className="block mb-2 text-xs font-bold tracking-wide text-gray-700"
              htmlFor="agents"
            >
              Agent*
            </label>
            <input
              className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full pl-10 p-2.5      "
              id="agents"
              type="agents"
              placeholder=""
              value={trackingData.agents}
              disabled
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group ">
            <CoursierSelector
              selectedValue={trackingData.chaufeur}
              onChange={onChaufeurSelect}
            />
          </div>
        </div>
        <div className="col-md-7">
          <div className="form-group">
            <div className="checkbox">
              <label className={"selection"}>
                <input
                  type="checkbox"
                  name="checkbox"
                  value={trackingData.visible}
                  checked={trackingData.visible}
                  onChange={() =>
                    updateTrackingData("visible", trackingData.visible === 1 ? 0 : 1)
                  }
                />{" "}
                Visible
              </label>
            </div>

            <div className="checkbox">
              <label className={"selection"}>
                <input
                  type="checkbox"
                  name="checkbox"
                  value={trackingData.problem}
                  checked={trackingData.problem}
                  onChange={() =>
                    updateTrackingData("problem", trackingData.problem === 1 ? 0 : 1)
                  }
                />{" "}
                Case à cocher en cas de problème sur le colis
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="row " style={{ justifyContent: "flex-start" }}>
        <div className="flex flex-wrap items-center">
          <>
            {expedition.manifest === 0 && (
              <>
                <button
                  className={`text-white bg-black ${sendingMail ? "cursor-pointer" : "cursor-not-allowed"
                    } hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center md:mr-6 mt-2`}
                  onClick={() => {
                    sendMailAndSms(STATUS.SENDING_MAIL);
                  }}
                  disabled={sendingMail}
                >
                  Envoyer mail
                </button>

                <button
                  className={`text-white bg-black ${sendingSms ? "cursor-pointer" : "cursor-not-allowed"
                    } hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center md:mr-6 mt-2`}
                  onClick={() => {
                    sendMailAndSms(STATUS.SENDING_SMS);
                  }}
                  disabled={sendingSms}
                >
                  Envoyer sms
                </button>
              </>
            )}

            <button
              className={clsx(
                {
                  "cursor-not-allowed":
                    expedition.manifest === 0 || isSubmitting,
                },
                `text-white bg-black hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mt-2`
              )}
              onClick={handleSubmit}
              disabled={expedition.manifest === 0 || isSubmitting}
            >
              {isSubmitting ? "Enregistement..." : "Enregistrer"}
            </button>
          </>
        </div>

        <div className="flex items-center justify-between my-3">
          <label
            htmlFor="tracking_mail"
            className="ml-0 text-base font-semibold text-gray-900 cursor-pointer "
          >
            Le client recevra des alertes de suivi
          </label>
          <SwitchComponent
            id="tracking_mail"
            label="tracking_mail"
            checked={userInfo?.tracking_mail === 1}
            onChange={(checked) => {
              updateUserInfo(checked ? 1 : 0);
              // updateTrackingData("tracking_mail", checked ? 1 : 0);
            }}
            checkedColor="#098721"
          />
        </div>
      </div>
    </div>
  );
}

export const AddTrackingModal = ({
  isOpen,
  onClose,
  onSubmit,
  expedition,
  updatedSelectedData = false,
  showComment = false,
  setExpeditionData = false,
}) => {
  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      title={"Ajout de tracking"}
    >
      <div className="px-4 py-2">
        <div className="mt-3">
          <AddTrackingComponent
            expedition={expedition}
            showComment={showComment}
            setExpeditionData={setExpeditionData}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </ModalComponent>
  );
};
