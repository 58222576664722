

import axios from "axios";
import TokenService from "../config/tokenService.js";

class ChatService {

    constructor() {
        TokenService.setHeader();
    }

    fetchMessages(shipping_id,page) {
        return axios.get(`${process.env.REACT_APP_API_URL}/user/chat/exist?shipping_id=${shipping_id}&perPage=30&page=${page}`);
    }

    fetchOpsChat(page) {
        return axios.get(`${process.env.REACT_APP_API_URL}/ops/chat/open/list/NotStatus?perPage=10&page=${page}`);
    }

    fecthClientChat(page) {
        return axios.post(`${process.env.REACT_APP_API_URL}/user/chat/open/list/ByUser?perPage=30&page=${page}`);
    }

    createMessage(data){
        return axios.post(`${process.env.REACT_APP_API_URL}/user/chat/message/send`,data);
    }

}

export default new ChatService();
