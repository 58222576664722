import React from "react";
import { contactData, enteteContactData } from "../../utils/ArrayHeaderData";
import TableauComponent from "../tableauComponent/tableau.component";

function ListContactComponent({ expeditionKind, role }) {
    return (
        <>
            <div className="row">
              
                <TableauComponent
                    colonnes={enteteContactData}
                    valeurs={contactData}
                    title={expeditionKind}
                    withFiltre={false}
                    // parametreFiltre={parametreFiltre}
                    expeditionKind={expeditionKind}
                    espace={window.location.pathname.split("/")[2]}
                    role={role}
                />
            </div>
        </>
    );
}

export default ListContactComponent;
