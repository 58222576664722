import React, { useCallback, useState } from 'react'
import useDisclosure from '../../../hooks/useDisclosure';
//import Form from './Form';
import useAdminLists from '../../../hooks/useAdminLists';
import { formatDefaultDate } from '../../../utils/moment';
import { TableLoader } from '../../Utils/Loader';
import CarFormModal from './Form';
import DeleteConfirm from '../../Utils/DeleteConfirm';
import vehiculeService from '../../../services/vehicule/vehiculeService';
import { SwitchComponent } from '../../Utils/Index';
import { TbArrowsUpDown, TbMotorbike, TbTruckDelivery } from 'react-icons/tb';
import { PiCarProfileLight } from "react-icons/pi";
import { exnetSpaceColor } from '../../../utils/Functions/otherFunctions';
import PaginationComponent from '../../paginationComponent/PaginationComponent';
import useTableFilter from '../../../hooks/useTableFilter';
import { PlusIcon } from '@heroicons/react/24/outline';
import { TableComponent } from '../../TableauComponent';

export default function CarsView() {
    const { isOpen, onClose, onOpen } = useDisclosure();

    const {
        isOpen: isDeleteConfirmOpen,
        onClose: onDeleteConfirmClose,
        onOpen: onDeleteConfirmOpen
    } = useDisclosure();

    const type = "vehicules"

    const { adminData, metadata, setPage, setPerPage, isLoading, onSubmit, onDelete } = useAdminLists({ type });
    const [selectedItem, setSelectedItem] = useState(null)

    const onItemSelected = (item, action) => {
        setSelectedItem(item)
        if (action === "edit") {
            onOpen()
        } else {
            onDeleteConfirmOpen()
        }
    }

    return (
        <div>
            <div className="flex items-center justify-between">
                <h4 className="uppercase page-title">Liste des véhicules</h4>
                <div
                    className="float-right text-white bg-black btn btn-rounded"
                    onClick={onOpen}
                >
                    <PlusIcon className={"h-4 w-4 m-3 text-xl font-bold "} />
                </div>
            </div>

            <div className="py-3">
                {adminData.total ?
                    <PaginationComponent
                        page={metadata.page}
                        handlePageClick={setPage}
                        totalItems={adminData.total}
                        perPageItems={metadata.perPage}
                        setnumberPerPage={setPerPage}
                    /> : null}
            </div>

            <Lists data={adminData.data} onSelect={onItemSelected} isLoading={isLoading} />
            <div className="py-3">
                {adminData.total ?
                    <PaginationComponent
                        page={metadata.page}
                        element='pagination'
                        handlePageClick={setPage}
                        totalItems={adminData.total}
                        perPageItems={metadata.perPage}
                        setnumberPerPage={setPerPage}
                    /> : null}
            </div>

            <CarFormModal isOpen={isOpen} onClose={() => {
                onClose()
                setSelectedItem(null)
            }} onSubmit={onSubmit} selectedData={selectedItem} />

            {selectedItem &&
                <DeleteConfirm
                    id={selectedItem.id}
                    isOpen={isDeleteConfirmOpen}
                    onClose={onDeleteConfirmClose}
                    onDelete={() => {
                        onDelete(selectedItem)
                        onDeleteConfirmClose()
                    }}
                    deleteRequest={() => vehiculeService.deleteVehicule({ id: selectedItem.id })}
                />}
        </div>
    )
}


const Lists = ({ data, onSelect, isLoading }) => {
    const { results, onTableHeaderClick } = useTableFilter({ items: data });

    const HEADERS = [
        { label: 'ID', filterKey: 'id' },
        { label: 'Matricule', filterKey: 'plaque' },
        // Uncomment the line below if 'chauffeur_id' should be included in the table
        // { label: 'Chauffeur', filterKey: 'chauffeur_id' },
        { label: 'KM de départ', filterKey: 'kmdepart' },
        { label: 'KM de retour', filterKey: 'kmretour' },
        { label: 'Tel société', filterKey: 'telephone_societe' },
        { label: 'Bip telepéage', filterKey: 'bip_telepage' },
        { label: 'Carte carburant', filterKey: 'carte_carburant' },
        { label: 'Carte bancaire', filterKey: 'carte_bancaire' },
        { label: 'DATE DE CRÉATION', filterKey: 'created_at' },
        { label: 'Pochette reçue', filterKey: 'pochette_recu' },
        { label: 'Action', filterKey: null },
    ];
    return (
        <TableComponent withAction={false} headerList={HEADERS} dataLength={results.length} isLoading={isLoading}>{results.map((item, i) => {
            return (
                <tr key={i} className="py-3 divide-y divide-gray-300">
                    <td className={'cursor-pointer'}
                        onClick={() => {
                            onSelect(item, "edit")
                        }}
                    >{item.id}</td>
                    <td className={'cursor-pointer'}
                        onClick={() => {
                            onSelect(item, "edit")
                        }}
                    >
                        <div className="flex items-center max-w-[140px]">
                            {
                                item.type_vehicule === "2" ?
                                    <TbMotorbike size={22} className="ml-[-10px]" /> :
                                    item.type_vehicule === "4" ?
                                        <PiCarProfileLight size={22} /> :
                                        item.type_vehicule === "8" ?
                                            <TbTruckDelivery size={25} />
                                            : null
                            }
                            {item.plaque}
                        </div>

                    </td>
                    {/* <td className={'cursor-pointer'}
                                                    onClick={() => {
                                                        onSelect(item, "edit")
                                                    }}
                                                >{item.chauffeur_id}</td> */}
                    <td className={'cursor-pointer'}
                        onClick={() => {
                            onSelect(item, "edit")
                        }}
                    >{item.kmdepart}</td>

                    <td className={'cursor-pointer'}
                        onClick={() => {
                            onSelect(item, "edit")
                        }}
                    >{item.kmretour}</td>

                    <td className={'cursor-pointer'}
                        onClick={() => {
                            onSelect(item, "edit")
                        }}
                    >{item.telephone_societe}</td>

                    <td className={'cursor-pointer'}
                        onClick={() => {
                            onSelect(item, "edit")
                        }}
                    >{item.bip_telepage}</td>

                    <td className={'cursor-pointer'}
                        onClick={() => {
                            onSelect(item, "edit")
                        }}
                    >{item.carte_carburant}</td>

                    <td className={'cursor-pointer'}
                        onClick={() => {
                            onSelect(item, "edit")
                        }}
                    >{item.carte_bancaire}</td>


                    <td className={'cursor-pointer'}
                        onClick={() => {
                            onSelect(item, "edit")
                        }}
                    >{formatDefaultDate(item.created_at)}</td>
                    <td>
                        <SwitchComponent checked={Boolean(item.pochette_recu)} />
                    </td>
                    <td>
                        <div className="flex items-center">
                            {/* <div
                                                            className="text-green-600 cursor-pointer text-[14px] mr-5"
                                                            onClick={() => {
                                                                onSelect(item, "edit")
                                                            }} title="Editer"
                                                        >
                                                            <i className="fas fa-edit"></i>
                                                        </div> */}

                            <div
                                className="text-red-500 cursor-pointer text-[14px]"
                                onClick={() => {
                                    onSelect(item, "delete")
                                }}
                                title="Supprimer"
                            >
                                <i className="fas fa-trash"></i>
                            </div>
                        </div>
                    </td>
                </tr>
            )
        })
        }
        </TableComponent>

    )
}