import React from "react";
import { useLocation } from "react-router-dom";

const useQuery = () => {
  const query = new URLSearchParams(useLocation().search);

  const getParams = (key) => {
    const value = query.get(key);
    return value !== null ? value : undefined;
  };

  return {
    query,
    getParams,
  };
};

export default useQuery;
