import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    contacts: [],
};

const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        addContact: (state, action) => {
            state.contacts.push(...action.payload);
        },
        addOneContact: (state, action) => {
            state.contacts.unshift(action.payload);
        },
        updateContact: (state, action) => {
            const { id, updatedData } = action.payload;
            const updatedContacts = state.contacts.map(contact => {
                if (parseInt(contact.id) === parseInt(id)) {
                    return { ...contact, ...updatedData };
                }
                return contact;
            });
            return { ...state, contacts: updatedContacts };
        },
        removeContact: (state, action) => {
            const updatedContacts = state.contacts.filter(contact => parseInt(contact.id) !== parseInt(action.payload));
            return { ...state, contacts: updatedContacts };
        },
        clearContacts: (state) => {
            state.contacts = [];
        },
        setContacts: (state, action) => {
            state.contacts = action.payload;
        },
    },
});

export const {
    addContact,
    updateContact,
    removeContact,
    clearContacts,
    setContacts,
    addOneContact
} = contactSlice.actions;

export const contactReducer = contactSlice.reducer;
