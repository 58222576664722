import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import useAuth from '../../../hooks/useAuth';


const TYPES_ROUES = [
  {
    label: "DEUX ROUES",
    value: 2,
  },
  {
    label: "QUATRE ROUES",
    value: 4,
  },
]

function TypedeRoueSelector({ roue, onChange, likeObject = true, ...other }) {

  const [options, setOptions] = useState([])

  const value = TYPES_ROUES.find(item => item.value === parseInt(roue))
  const { user } = useAuth()

  useEffect(() => {
    const rouesOptions = [];

    if (user) {
      if (!user["vt"] && !user["2_roues"]) {
        //roues non définies donc définir toutes les opérations
        setOptions(TYPES_ROUES)
      } else {
        if (user["2_roues"]) {
          rouesOptions.push(TYPES_ROUES[0])
        }

        if (user["vt"]) {
          rouesOptions.push(TYPES_ROUES[1])
        }

        setOptions(rouesOptions)
      }



    }

  }, [user])

  return (
    <div className="form-group">
      <label htmlFor="field6">{"Type de prestation".toUpperCase()}</label>
      <Select
        {...other}
        className={"text-base"}
        value={value}
        options={options}
        placeholder={''}
        onChange={(v) => {
          if (likeObject) {
            onChange(v);
          } else {
            onChange(v?.value);
          }

        }}
      />
    </div>
  );
}

export default TypedeRoueSelector;