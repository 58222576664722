import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useLocation } from 'react-router-dom'

export const AllDriverSelector = ({
    driversList,
    value,
    onChange,
    isDisabled = false,
    champ = false,
    ...props
}) => {


    const [isLoading, setIsLoading] = useState(false);
    let valueSelected = driversList.find((item) => item?.value === value)
    return (
        <div className="form-group">
            <Select
                className="text-base"
                value={valueSelected}
                options={driversList}
                onChange={onChange}
                isLoading={isLoading}
                isDisabled={isDisabled}
                {...props}
                LoadingMessage={() => "Chargement..."}
            />
        </div>
    );
}
