import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    factures: [],
};

const factureSlice = createSlice({
    name: 'facture',
    initialState,
    reducers: {
        addfacture: (state, action) => {
            state.factures.push(...action.payload);
        },
        addOnefacture: (state, action) => {
            state.factures.push(action.payload);
        },
        updatefacture: (state, action) => {
            const { id, updatedData } = action.payload;
            const updatedfactures = state.factures.map(facture => {
                if (parseInt(facture.id) === parseInt(id)) {
                    return { ...facture, ...updatedData };
                }
                return facture;
            });
            return { ...state, factures: updatedfactures };
        },
        removefacture: (state, action) => {
            const updatedfactures = state.factures.filter(facture => parseInt(facture.id) !== parseInt(action.payload));
            return { ...state, factures: updatedfactures };
        },
        clearfactures: (state) => {
            state.factures = [];
        },
        setfactures: (state, action) => {
            state.factures = action.payload;
        },
    },
});

export const {
    addfacture,
    updatefacture,
    removefacture,
    clearfactures,
    setfactures,
    addOnefacture
} = factureSlice.actions;

export const factureReducer = factureSlice.reducer;
