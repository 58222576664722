import React from "react";
import { Player, } from "@lottiefiles/react-lottie-player";

const LoaderTableau=React.memo((props) =>{
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                flex: 1,
                flexDirection: "column",
            }}
        >
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Player
                    autoplay
                    loop
                    src="https://assets1.lottiefiles.com/private_files/lf30_cgfdhxgx.json"
                    style={{ height: "300px", width: "400px" }}
                ></Player>
            </div>
        </div>
    );
})

export default LoaderTableau;
