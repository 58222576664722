import React from 'react';
import PropTypes from 'prop-types';
import { } from "@heroicons/react/20/solid"
import clsx from 'clsx';

export default function ModalComponent({ isOpen, onClose, size = "4xl", containerClassName, title, children, closeIconVisible = true }) {

    const handleOutsideClick = (event) => {

        if (event.target.className === "absolute inset-0 bg-gray-500 opacity-75") {
            onClose();
        }
    };

    return (
        isOpen && (
            <div id="modal" onClick={handleOutsideClick} className="fixed inset-0 overflow-y-auto" style={{ zIndex: 9999 }}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                    <div className={clsx(`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full`, containerClassName ? containerClassName : 'sm:max-w-4xl')}
                        role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                        <div className="bg-white p-4 sm:p-6 sm:pb-4">
                            {closeIconVisible && <button onClick={onClose} className="absolute top-3 right-4 focus:outline-none">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-gray-600">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                            </button>}

                            <h3 className="text-lg text-center leading-6 font-medium text-gray-900" id="modal-headline">
                                {title}
                            </h3>
                            <div className="mt-2 mb-9">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
}

ModalComponent.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};


export function PreviewModalComponent({ isOpen, onClose, size = "4xl", title, children, closeIconVisible = true }) {

    const handleOutsideClick = (event) => {

        if (event.target.className === "absolute inset-0 bg-gray-500 opacity-75") {
            onClose();
        }
    };

    return (
        isOpen && (
            <div id="modal" onClick={handleOutsideClick} className="fixed inset-0 overflow-y-auto" style={{ zIndex: 9999 }}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                    <div className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[900px] sm:w-full`}
                        role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                        <div className="bg-white p-4 sm:p-6 sm:pb-4">
                            {closeIconVisible && <button onClick={onClose} className="absolute top-3 right-4 focus:outline-none">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-gray-600">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                            </button>}

                            <h3 className="text-lg text-center leading-6 font-medium text-gray-900" id="modal-headline">
                                {title}
                            </h3>
                            <div className="mt-2">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
}

PreviewModalComponent.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};