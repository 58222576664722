import React, { useEffect, useState } from 'react';
import { exnetSpaceColor, urlSplit } from '../../../utils/Functions/otherFunctions';
import ExnetTypeSelector2 from '../../Utils/Selectors/ExnetTypeSelector2';
import { toast } from "react-toastify";
import adminService from '../../../services/admin/adminService';


const table = ['isparis', 'ismedical', 'isservice', 'isdriver'];


function Form({ newData, onSubmit, setTemplateList, onClose, selectedValue = false }) {

    const { exnetSpace } = urlSplit()

    const [TemplateSpace, setTemplateSpace] = useState([])
    const [template, setTemplate] = useState({})
    const [isSubmitting, setisSubmitting] = useState(false)

    const DesabledInput = () => {
        return newData ? false : true;
    };

    const differenceArray = (arr1, arr2) => {
        var diff1 = arr2.filter(x => !arr1.includes(x));
        return diff1;
    }

    const handleUpdate = () => {
        setisSubmitting(true);
        if (template?.details && template?.details !== "") {

            if (TemplateSpace.length > 0) {
                const toastsId = toast.loading("Enregistrement en cours ...", {
                    position: "top-right",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                try {
                    let newData = {

                    }
                    TemplateSpace.forEach(element => {
                        newData[element] = 1;
                    });

                    differenceArray(TemplateSpace, table).forEach(element => {
                        newData[element] = 0;
                    });

                    newData.details = template?.details;

                    console.log(newData)
                    toast.update(toastsId, {
                        render: "Enregistrement effecuté",
                        type: "success",
                        isLoading: false,
                        autoClose: 1000,
                        position: "bottom-center",
                    });

                    setisSubmitting(false);
                } catch (error) {
                    setisSubmitting(false);
                    toast.update(toastsId, {
                        render: "Enregistrement échoué",
                        type: "error",
                        isLoading: false,
                        autoClose: 1000,
                        position: "bottom-center",
                    });
                }
            } else {
                toast.error("Vous devez selectionné un espace", {
                    position: "bottom-center",
                    theme: "light",
                    autoClose: 1000,
                });
            }

        } else {
            toast.error("Le champ détails est vide", {
                position: "bottom-center",
                theme: "light",
                autoClose: 1000,
            });

        }


    }

    const handleSave = () => {

        setisSubmitting(true,);
        if (template?.details && template?.details !== "") {

            if (TemplateSpace.length > 0) {
                const toastsId = toast.loading("Enregistrement en cours ...", {
                    position: "top-right",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                try {
                    let newData = {}
                    TemplateSpace.forEach(element => {
                        newData[element] = 1;
                    });

                    differenceArray(TemplateSpace, table).forEach(element => {
                        newData[element] = 0;
                    });

                    newData.details = template?.details;
                    newData.template = 1;

                    adminService.createTracking(newData).then(
                        (res) => {
                            let { success, data } = res.data

                            setisSubmitting(false);
                            onClose()
                            if (success) {
                                onSubmit(data)
                                toast.update(toastsId, {
                                    render: "Enregistrement effecuté",
                                    type: "success",
                                    isLoading: false,
                                    autoClose: 1000,
                                    position: "bottom-center",
                                });
                            }
                            else {
                                toast.update(toastsId, {
                                    render: "Enregistrement échoué",
                                    type: "error",
                                    isLoading: false,
                                    autoClose: 1000,
                                    position: "bottom-center",
                                });
                            }

                        },
                        (error) => {

                            setisSubmitting(false);

                            toast.update(toastsId, {
                                render: "Enregistrement échoué",
                                type: "error",
                                isLoading: false,
                                autoClose: 1000,
                                position: "bottom-center",
                            });

                        }
                    )
                } catch (error) {
                    setisSubmitting(false);
                    toast.update(toastsId, {
                        render: "Enregistrement échoué",
                        type: "error",
                        isLoading: false,
                        autoClose: 1000,
                        position: "bottom-center",
                    });
                }
            } else {
                setisSubmitting(false);
                toast.error("Vous devez selectionné un espace", {
                    position: "bottom-center",
                    theme: "light",
                    autoClose: 1000,
                });
            }

        } else {
            setisSubmitting(false);
            toast.error("Le champ détails est vide", {
                position: "bottom-center",
                theme: "light",
                autoClose: 1000,
            });

        }

    }

    const setFormValue = (key, value) => {
        setTemplate((d) => ({
            ...d,
            [key]: value,
        }));
    };

    useEffect(() => {
        if (selectedValue) {
            let newValue = []
            table.forEach(element => {
                if (selectedValue[element]) {
                    newValue.push(element)
                }
            });
            setTemplateSpace([...newValue])

            setTemplate({ ...selectedValue })
        }
    }, [selectedValue])


    return (
        <div>

            <div className="grid gap-6 mb-6 md:grid-cols-2">
                <div className="text-left">
                    <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                        Titre
                    </label>
                    <input
                        disabled={DesabledInput()}
                        type="text"
                        id="name"
                        className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
                        required
                        value={template?.details}
                        onChange={(e) =>
                            setFormValue("details", e.target.value)
                        }
                    />
                </div>

                <div className="text-left">
                    <ExnetTypeSelector2
                        value={TemplateSpace}
                        onChange={(v) => setTemplateSpace(v)}
                        disabled={DesabledInput()}
                    />
                </div>
            </div>

            <div className="flex justify-center mt-10">
                {/* exnetSpaceColor */}
                {/* {(!newData) && (
                    <button
                        type="button"
                        onClick={handleUpdate}

                        className="text-white  focus:ring-4 focus:outline-none  font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                        disabled={isSubmitting}

                        style={{ backgroundColor: exnetSpaceColor(exnetSpace) }}
                    >
                        Modifier
                    </button>
                )} */}

                {newData && (
                    <button
                        type="button"
                        onClick={handleSave}
                        className="text-white  focus:ring-4 focus:outline-none  font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                        disabled={isSubmitting}
                        style={{ backgroundColor: exnetSpaceColor(exnetSpace) }}
                    >
                        Enregistrer
                    </button>
                )}
            </div>

        </div>
    );
}

export default Form;




