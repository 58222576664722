import clsx from "clsx";
import React from "react";
import { useAppContext } from "../../../context";

const tabsHead = [
    "*",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
];
function ContactFilter({ changeTabs, filterLetter }) {
    const { isAuth, setIsAuth } = useAppContext();

    return (
        <div className={"flex-1"}>
            
            <div className={"my-2 w-full rounded-md "}>
                <div className="flex items-center justify-between py-1 " id="affiche">
                    {tabsHead.map((item, index) => (
                        <span
                            className={clsx({
                                "bg-paris": isAuth?.spaceName === "paris" && filterLetter === item,
                                "bg-medical": isAuth?.spaceName === "medical" && filterLetter === item,
                                "bg-service": isAuth?.spaceName === "service" && filterLetter === item,
                                "bg-admin": isAuth?.spaceName === "admin" && filterLetter === item,
                            }, "rounded-full py-2 px-3 cursor-pointer")}
                            key={index}
                            onClick={() => changeTabs(item)}
                        >
                            {item}
                        </span>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ContactFilter;
