import React from "react";

function UserStateComponent({ data }) {
    return (
        <div className="bg-white rounded-md shadow-sm h-[100px] p-3">
            <div className="space-y-3">
                <p className={"text-sm font-semibold"}>{data.libelle}</p>
                <h2 className={"text-xl font-semibold"}>{data.nombre}</h2>

            </div>
        </div>
    );
}

export default UserStateComponent;
