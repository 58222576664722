import React, { useEffect, useState } from "react";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import Select from "react-select";
import InputComponent from "../InputComponent/InputComponent";
import clsx from "clsx";
import PrincingInput from "../Utils/UI/PrincingInput";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
const optionsIncontex = [
  { value: "exw", label: "EXW " },
  { value: "dap", label: "DAP  " },
  { value: "ddp", label: "DDP " },
];

function NewExpeditionColisCoursComponent({ setColisCours, colisCours, errorMessage }) {
  const [files, setFiles] = useState([]);
  const [incontex, setIncontex] = useState("");

  console.log(errorMessage)

  const {
    package_description,
    additional_insurance_amount,
    additional_insurance,
    documents,
    created_at,
    valeur_douane,
    porteur,
  } = colisCours;

  const updatecolisCourses = (element, valeur) => {
    let newcolisCours = { ...colisCours };
    newcolisCours[element] = valeur;
    setColisCours(newcolisCours);
  };
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    updatecolisCourses("incoterm", incontex.value);
  }, [incontex]);

  useEffect(() => {
    let newcolisCours = { ...colisCours };
    // let file = files.map((item, index) => item.file);

    newcolisCours["documents"] = files;
    setColisCours(newcolisCours);
  }, [files]);

  return (
    <div className="">
      <div className="card-box">
        <h4 className="text-center card-title">COURSE EXPRESS / DRIVE</h4>
        <hr className="mt-2 mb-6" />
        <form action="#">
          <div className="grid-cols-2 gap-2 space-y-2 md:space-y-0 md:grid">

            <div className="">
              <div className="form-group">
                <textarea
                  rows="6"
                  cols="5"
                  // className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  className={clsx({
                    "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500": errorMessage.field === 'package_description',
                    "ring-gray-300 focus:ring-gray-500 bg-white border border-gray-500": !errorMessage.field === 'package_description'
                  },
                    "px-2 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-inset  focus:ring-2 focus:ring-inset text-base sm:leading-6 h-full"
                  )
                  }
                  placeholder={"Description*".toUpperCase()}
                  value={package_description}
                  onChange={(e) =>
                    updatecolisCourses("package_description", e.target.value)
                  }
                ></textarea>
                {/* Afficher le message d'erreur en bas du champ */}
                {errorMessage.field === 'package_description' &&
                  <p className="mt-2 text-sm text-red-600" id={"full-error"}>
                    {errorMessage.message}
                  </p>
                }
              </div>
            </div>

            <div className="space-y-3">
              <PrincingInput returnValue={true} value={valeur_douane} UpdateColisData={(valeur) => updatecolisCourses("valeur_douane", valeur)} colis={'none'} index={'none'} errorMessage={errorMessage} />

              <div className="border border-black border-dashed">
                <FilePond
                  files={files}
                  allowReorder={true}
                  allowMultiple={true}
                  onupdatefiles={setFiles}
                  labelIdle='Faites glisser et déposez vos fichiers ou <span className="filepond--label-action">Parcourir</span>'
                />

              </div>
            </div>


          </div>

        </form>
      </div >
    </div >
  );
}

export default NewExpeditionColisCoursComponent;
