import React, { useEffect, useState } from 'react'
import { getUniqueItemsByKey } from '../utils';

export default function useRequestLoader({ request, deps }) {
    const [results, setResults] = useState({
        data: []
    });
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);

    const _deps = deps || []

    useEffect(() => {
        const fetchItems = async () => {
            try {
                setIsLoading(true)
                const { data: resData } = await request?.(page);
                if (resData.success) {
                    setResults(r => ({
                        ...r,
                        data: getUniqueItemsByKey([...r.data, ...resData.data.data], "id")
                    }))
                    setIsLoading(false);
                    //increase page to load next items
                    const lastPage = resData.data.last_page;
                    if (page < lastPage) {
                        setPage(page + 1);
                    }

                }
            } catch (error) {
                console.error(error)
                setIsLoading(false)
            }
        };

        fetchItems()
    }, [page, ..._deps])

    return { results, page, isLoading }
}


export const useApi = ({ request, params, onLoadMore }) => {
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchItems = async () => {
            try {
                setIsLoading(true)
                const { data: resData } = await request?.(params);
                if (resData.success) {
                    if (resData.data?.data) {
                        setItems(_items => getUniqueItemsByKey([..._items, ...resData.data.data], "id"))
                    } else {
                        setItems(resData.data)
                    }
                    setIsLoading(false);
                    //increase page to load next items
                    const lastPage = resData.data.last_page;
                    if (params?.page < lastPage) {
                        onLoadMore?.()
                    }
                }
            } catch (error) {
                console.error(error)
                setIsLoading(false)
            }
        };

        fetchItems()
    }, [params, request, onLoadMore])

    return { items, isLoading }
}