import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { exnetSpaceColor, urlSplit } from "../../../../utils/Functions/otherFunctions.js";
import useAuth from "../../../../hooks/useAuth.jsx";
import HeaderComponent from "../../../header/header.component.jsx";
import factureService from "../../../../services/facture/factureService.js";
import ClientSelector from "../../../Utils/Selectors/ClientSelector.jsx";
import GlobalList from "./Liste.jsx";
import PaginationComponent from "../../../paginationComponent/PaginationComponent.jsx";
import { toFixed2 } from "../../../../utils/index.js";
import { useAppContext } from "../../../../context/index.jsx";
import { FunnelIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { FactureListStore } from "../../../../store/FactureListStore.js";



export const IndexListSimple = ({
    title,
    subtitle,
}) => {

    const { exnetSpace } = urlSplit()
    const { pathname } = useLocation();
    const { isAuth, setIsAuth } = useAppContext();
    const { isAuthenticated, user } = useAuth();
    const [titlePage, settitlePage] = useState("");
    const [subtitlePage, setsubtitlePage] = useState("");
    const [page, setPage] = useState(1);
    const [billingCenter, setBillingCenter] = useState(0);
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [showFilter, setShowFilter] = useState(true)
    const [data, setData] = useState({
        data: [],
    });
    const [isLoading, setIsLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [perPageItems, setperPageItems] = useState(25);

    const handlePageClick = (event) => {
        console.log(event.selected, event)
        setPage(parseInt(event))
        setCurrentPage(event);
    };

    const dataList = FactureListStore((state) => state.dataList);
    const setDataList = FactureListStore((state) => state.setDataList);



    useEffect(() => {
        settitlePage(title);
        setsubtitlePage(subtitle);
    });

    const fetchData = async (searchData = null) => {
        try {
            setIsLoading(true)

            let params = {
                page: page,
                per_page: perPageItems,
                id: parseInt(billingCenter),
                type: isAuth?.spaceName
            };

            if (startDate && startDate !== "") {
                params.startDate = startDate
            }

            if (endDate && endDate !== "") {
                params.endDate = endDate
            }


            // appel à au service
            const { data: resData } = await factureService.fetchFactures(params);

            if (resData.success) {
                const lastPage = resData.data.last_page;
                const newData = resData.data.data
                //console.log(newData)
                setData((d) => ({
                    data: [...newData],
                }));
                setDataList(newData)

                setIsLoading(false);
                // Increment the page only if it's less than the last page
                setTotalItems(parseInt(resData.data.total))

            }
            else {
                setIsLoading(false)
                console.log(resData)
            }
        } catch (error) {
            setIsLoading(false)
            console.log(error);
        } finally {
            setIsLoading(false)
            setIsLoading(false);
        }
    };


    // Premier useEffect pour charger les données initiales
    useEffect(() => {
        let table = pathname.split('/');
        let tableValue = table[table.length - 1]
        setPage(1);
        let value = {
            data: [],
        };
        setData({ ...value });
        setDataList([])
        fetchData();
    }, []);



    // Deuxième useEffect pour gérer les mises à jour de page et de recherche
    useEffect(() => {
        fetchData();
    }, [page, perPageItems]);



    useEffect(() => {
        if (billingCenter && billingCenter !== "") {
            setIsLoading(true);
            setPage(1);
            let value = {
                data: [],
            };
            setData({ ...value });
            setDataList([])
            fetchData();
        }

        if (startDate && startDate !== "") {
            fetchData();
        }

        if (endDate && endDate !== "") {
            fetchData();
        }

    }, [billingCenter, startDate, endDate])

    const items = data.data;


    const totalHT = items.reduce((acc, cur) => cur.montant + acc, 0);
    const totalTTC = items.reduce((acc, cur) => cur.total + acc, 0);

    const totalAvoirHT = items.filter(item => item.statut === "avoir").reduce((acc, cur) => cur.montant + acc, 0);
    const totalAvoirTTC = items.filter(item => item.statut === "avoir").reduce((acc, cur) => cur.total + acc, 0)

    return (
        <div>
            <div className="flex items-center justify-between">
                <HeaderComponent title={titlePage} subtitle={subtitlePage} />
                <div className="rounded-full cursor-pointer w-fit" style={{
                    color: "#fff",
                    backgroundColor: `${exnetSpaceColor(isAuth?.spaceName)}`,
                }}>
                    <FunnelIcon onClick={() => setShowFilter(!showFilter)} className={"h-4 w-4 m-3 text-xl font-bold  "} />
                </div>
            </div>
            <section
                className={clsx(
                    "transition-all duration-700 ease-in-out",
                    { "max-h-[800px] opacity-100  p-3 ": showFilter, "max-h-0 opacity-0": !showFilter }
                )}>
                <div className="p-3 bg-gray-100 rounded-md">
                    <div className="items-center grid-cols-4 gap-4 space-y-3 md:grid md:space-y-0">
                        <div className="w-full pt-2 mb-4 w-md md:mb-0">
                            <label
                                className="block text-xs font-bold tracking-wide text-gray-700 uppercase"
                                htmlFor="billing_center"
                            >
                                Centre du client
                            </label>
                            <ClientSelector
                                selectedValue={billingCenter}
                                showLabel={false}
                                profil={"customer"}
                                onSelect={(v) => {

                                    setBillingCenter(v.value)
                                }}
                            />
                            {/* <BillCenterSelector type={'paris'} user={false}
                            onSelect={(v) => {
                                setBillingCenter(v.value)
                            }}
                        /> */}
                        </div>

                        <div className="w-full mb-6 w-md md:mb-0">
                            <label
                                className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                                htmlFor="startDate"
                            >
                                Date de création
                            </label>
                            <input
                                className="block text-sm h-[40px] w-full  px-4 py-2  leading-tight text-gray-700 bg-white border border-gray-300 rounded-lg appearance-none focus:outline-none focus:bg-white"
                                id="startDate"
                                type="date"
                                placeholder=""
                                value={startDate}
                                onChange={(e) => {
                                    setStartDate(e.target.value)

                                }}
                            />
                        </div>

                        <div className="w-full mb-6 w-md md:mb-0">
                            <label
                                className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                                htmlFor="endDate"
                            >
                                Date de fin
                            </label>
                            <input
                                className="block text-sm h-[40px] w-full  px-4 py-2  leading-tight text-gray-700 bg-white border border-gray-300 rounded-lg appearance-none focus:outline-none focus:bg-white"
                                id="endDate"
                                type="date"
                                placeholder=""
                                value={endDate}
                                onChange={(e) => {
                                    setEndDate(e.target.value)
                                }}
                            />
                        </div>


                    </div>
                </div>
            </section>
            <div>
                <PaginationComponent
                    handlePageClick={handlePageClick}
                    totalItems={totalItems}
                    perPageItems={perPageItems}
                    setnumberPerPage={setperPageItems}
                    page={page}
                />
            </div>
            {data.data.length > 0 && <div className="flex items-center">
                <div className="mr-5 text-lg font-bold">
                    <u>Total HT:</u> {toFixed2(totalHT - totalAvoirHT)}
                </div>
                <div className="text-lg font-bold">
                    <u>Total TTC:</u> {toFixed2(totalTTC - totalAvoirTTC)}
                </div>
            </div>}
            <GlobalList items={data.data} setItems={setData} isLoading={isLoading} />
            <div>
                <PaginationComponent
                    handlePageClick={handlePageClick}
                    totalItems={totalItems}
                    perPageItems={perPageItems}
                    setnumberPerPage={setperPageItems}
                    page={page}
                />
            </div>


        </div>
    );
}
