import {
    AdminList,
    CentreFacturation,
    ClientList,
    CodeFacturationList,
    ContactList,
    DernierExpedition,
    Fuel,
    OpsList,
    TrackingTemplateList,
    UtilisateurList,
    Chauffeurs, HistoriqueConnexion, Vehicules
} from "../../core/tableau/admin/AdminTableauHead";

export const TableAdminEntete = (element) => {
    if (element === "centre_facturation") {
        return CentreFacturation;
    } else if (element === "clients") {
        return ClientList;
    } else if (element === "utilisateurs") {
        return UtilisateurList;
    } else if (element === "ops") {
        return OpsList;
    } else if (element === "administrateurs") {
        return AdminList;
    } else if (element === "contacts") {
        return ContactList;
    } else if (element === "statistique_client") {
        return ClientList;
    } else if (element === "statistique_factures") {
        return CodeFacturationList;
    } else if (element === "statistique_expeditions") {
        return DernierExpedition;
    } else if (element === "tracking_template") {
        return TrackingTemplateList;
    } else if (element === "fuel") {
        return Fuel;
    } else if (element === "expeditions") {
        return DernierExpedition;
    } else if (element === "chauffeurs") {
        return Chauffeurs ;
    } else if (element === "facture_fuel") {
        return Fuel;
    } else if (element === "facture_code_facturation") {
        return CodeFacturationList;
    } else if (element === "historique") {
        return HistoriqueConnexion;
    } else if (element === "vehicules") {
        return Vehicules;
    }

};
