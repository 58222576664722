import React, { useState } from "react";
import CountriesSelector from "../Utils/Selectors/CountriesSelector";
import InputComponent from "../InputComponent/InputComponent";
import { ButtonComponent } from "../ButtonComponent";

function ProformaComponent({
  proformaHeader,
  setProformaHeader,
  setProformaList,
  proformaList,
  errorMessage
}) {
  const handleAddProforma = () => {
    setProformaList([...proformaList, {}]);

  };

  const removeProforma = () => {
    let newList = [...proformaList];
    newList.pop();
    setProformaList(newList);
  };

  const updateProformaList = (index, key, value) => {
    let newList = [...proformaList];
    newList[index][key] = value;
    setProformaList(newList);
  };

  const updateProformatHeader = (key, value) => {
    let newHeader = { ...proformaHeader };
    newHeader[key] = value;
    setProformaHeader(newHeader);
  };
  return (
    <div className={"card"}>
      <div className={"card-body"}>
        <div className={"m-4"}>
          <div className="row">
            <div className="col-md-4">
              {" "}
              <div className="form-group">
                <select
                  placeholder={"Nature de la proforma".toUpperCase()}
                  className="w-full bg-white border border-gray-500 rounded-lg outline-none form-select form-control"
                  value={proformaHeader?.nature}
                  onChange={(e) =>
                    updateProformatHeader("nature", e.target.value)
                  }
                >
                  <option disabled>Nature du proforma</option>
                  <option value="  Send for cleaning" key="">
                    Send for cleaning
                  </option>
                  <option value="Send to be restored" key="">
                    Send to be restored
                  </option>
                  <option value="Send back after repair" key="">
                    Send back after repair
                  </option>
                  <option value="Send back after cleaning" key="">
                    Send back after cleaning
                  </option>
                  <option value="returns / after Sales services" key="">
                    returns / After Sales services
                  </option>
                </select>
              </div>
              {errorMessage.field === 'nature' &&
                <p className="mt-2 text-sm text-red-600" id={"nature-error"}>
                  {errorMessage.message}
                </p>
              }
            </div>
            <div className="col-md-4">
              {" "}
              <div className="form-group">
                {/* <input
                  type="text"
                  className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  placeholder={"Numero de la proforma".toUpperCase()}
                  value={proformaHeader?.num_proforma}
                  onChange={(e) =>
                    updateProformatHeader("num_proforma", e.target.value)
                  }
                /> */}
                <InputComponent value={proformaHeader?.num_proforma} onChange={(valeur) => updateProformatHeader("num_proforma", valeur)} errorMessage={errorMessage.message} placeholder={"Numero de la proforma".toUpperCase()} label='' withLabel={false} isError={errorMessage.field === 'num_proforma' ? true : false} />

              </div>
            </div>
            <div className="col-md-4">
              {" "}
              <div className="form-group">

                <InputComponent type={'date'} value={proformaHeader?.date_export} onChange={(valeur) => updateProformatHeader("date_export", valeur)} errorMessage={errorMessage.message} placeholder={"date".toUpperCase()} label='' withLabel={false} isError={errorMessage.field === 'date_export' ? true : false} />
              </div>
            </div>
          </div>
          {proformaList.map((item, index) => (
            <div className="row">
              <div className="col-xs-12 col ">
                {" "}
                <div className="form-group">
                  {/* <input
                    type="text"
                    className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                    placeholder={"COMPOSITION".toUpperCase()}
                    value={item?.composition}
                    onChange={(e) =>
                      updateProformaList(index, "composition", e.target.value)
                    }
                  /> */}
                  <InputComponent value={item?.composition} onChange={(valeur) => updateProformaList(index, "composition", valeur)} errorMessage={errorMessage.message} placeholder={"composition".toUpperCase()} label='' withLabel={false} isError={errorMessage.field === 'composition' ? true : false} />
                </div>
              </div>
              <div className="col-xs-12 col ">
                {" "}
                <div className="form-group">
                  {/* <input
                    type="text"
                    className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                    placeholder={"DESCRIPTION".toUpperCase()}
                    value={item?.description}
                    onChange={(e) =>
                      updateProformaList(index, "description", e.target.value)
                    }
                  /> */}
                  <InputComponent value={item?.description} onChange={(valeur) => updateProformaList(index, "description", valeur)} errorMessage={errorMessage.message} placeholder={"description".toUpperCase()} label='' withLabel={false} isError={errorMessage.field === 'description' ? true : false} />

                </div>
              </div>
              <div className="col-xs-12 col ">
                {" "}
                <div className="form-group">
                  {/* <input
                    type="text"
                    className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                    placeholder={"MANUFACTURER".toUpperCase()}
                    value={item?.manifacturer}
                    onChange={(e) =>
                      updateProformaList(index, "manufacturer", e.target.value)
                    }
                  /> */}
                  <InputComponent 
                  value={item?.manufacturer} 
                  onChange={(valeur) => updateProformaList(index, "manufacturer", valeur)} 
                  errorMessage={errorMessage.message} placeholder={"manufacturer".toUpperCase()} 
                  label='' withLabel={false} 
                  isError={errorMessage.field === 'manufacturer' ? true : false} />

                </div>
              </div>
              <div className="col-xs-12 col ">
                {" "}
                <div className="form-group">
                  <CountriesSelector
                    showLabel={false}
                    selectedValue={item?.origin}
                    onSelect={(e) =>
                      updateProformaList(index, "origin", e.value)
                    }
                  />
                </div>
              </div>
              <div className="col-xs-12 col ">
                {" "}
                <div className="form-group">
                  {/* <input
                    type="number"
                    className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                    placeholder={"PRIX UNITAIRE".toUpperCase()}
                    value={item?.prix_unitaire}
                    onChange={(e) =>
                      updateProformaList(index, "prix_unitaire", e.target.value)
                    }
                  /> */}
                  <InputComponent 
                  value={item?.prix_unitaire} 
                  onChange={(valeur) => updateProformaList(index, "prix_unitaire", valeur)} 
                  errorMessage={errorMessage.message} placeholder={"Prix unitaire".toUpperCase()} 
                  label='' 
                  withLabel={false} 
                  isError={errorMessage.field === 'prix_unitaire' ? true : false} />

                </div>
              </div>
              <div className="col-xs-12 col ">
                {" "}
                <div className="form-group">
                  {/* <input
                    type="number"
                    className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                    placeholder={"HORS TAXE".toUpperCase()}
                    value={item?.hs_code}
                    onChange={(e) =>
                      updateProformaList(index, "hs_code", e.target.value)
                    }
                  /> */}
                  <InputComponent
                    value={item?.hs_code}
                    onChange={(valeur) => updateProformaList(index, "hs_code", valeur)}
                    errorMessage={errorMessage.message}
                    placeholder={"HORS TAXE".toUpperCase()}
                    label='' withLabel={false}
                    isError={errorMessage.field === 'hs_code' ? true : false}
                  />

                </div>
              </div>
              <div className="col-xs-12 col ">
                {" "}
                <div className="form-group">
                  {/* <input
                    type="number"
                    className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                    placeholder={"Quantité".toUpperCase()}
                    value={item?.qte}
                    onChange={(e) =>
                      updateProformaList(index, "qte", e.target.value)
                    }
                  /> */}
                  <InputComponent
                    type="number"
                    value={item?.qte}
                    onChange={(valeur) => updateProformaList(index, "qte", valeur)}
                    errorMessage={errorMessage.message}
                    placeholder={"Quantité".toUpperCase()}
                    label='' withLabel={false}
                    isError={errorMessage.field === 'qte' ? true : false}
                  />
                </div>
              </div>
              <div>
                <div className="form-group">
                 
                  <InputComponent
                    disabled
                    value={item?.manufacturer}
                    onChange={(valeur) => updateProformaList(index, "manufacturer", valeur)}
                    errorMessage={errorMessage.message}
                    placeholder={"prix total".toUpperCase()}
                    label='' withLabel={false}
                    isError={errorMessage.field === 'manufacturer' ? true : false}
                  />
                </div>
              </div>
            </div>
          ))}

          <div className={"row flex-end justify-end "}>
            <div className="col-md-4 ">
              <div className="items-end row h-100">
                <div className="col-4">
                  <div className="mb-3 form-group">
                  <ButtonComponent title={"Supprimer"} onClick={removeProforma} />
                   
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group ">
                  <ButtonComponent title={"Ajouter"} onClick={handleAddProforma} />
                    
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group ">
                    <button className="btn boutton w-110 ">
                      Prévisualiser
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProformaComponent;
