import React, { useState, useEffect } from 'react';

const TimeComponente = ({ value, onChange, placeholder }) => {

    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
            let valeur = new Date().toLocaleTimeString().split(':')
            onChange(valeur[0]+':'+valeur[1])
        }, 60000); // Mettez à jour l'heure toutes les secondes

        // Nettoyez l'intervalle lorsque le composant est démonté
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        setCurrentTime(new Date());
        let valeur = new Date().toLocaleTimeString().split(':')
        onChange(valeur[0]+':'+valeur[1])
    }, [placeholder]);

    return (
        <div className="form-group">
            <input
                type="time"
                id={"heure"}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control "
                placeholder={placeholder.toUpperCase()}
            />
        </div>
    );
};

export default TimeComponente;
