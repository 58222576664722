import dataConfigClient from "../../core/tableau/admin/client/dataConfig.js";
import dataConfigCentreFacturation from "../../core/tableau/admin/centreFacturation/dataConfig.js";
import dataConfigUtilisateurs from "../../core/tableau/admin/users/utilisateurDataConfig.js";
import dataConfigAdmin from "../../core/tableau/admin/users/adminDataConfig.js";
import dataConfigOps from "../../core/tableau/admin/users/opsDataConfig.js";
import dataConfig from "../../core/tableau/ops/dataConfig.js";
import dataConfigContact from "../../core/tableau/admin/contact/dataConfig.js";
import dataConfigSuivi from "../../core/tableau/admin/suivi/dataConfig.js";
import dataConfigChauffeur from "../../core/tableau/admin/chauffeur/dataConfig.js";
import dataConfigFactureFuel from "../../core/tableau/admin/fuel/dataConfig.js";
import dataConfigFactureLigneFacturation from "../../core/tableau/admin/facturationLine/dataConfig.js";
import historiqueDataConfig from "../../core/tableau/admin/historique/historiqueDataConfig.js";
import vehiculesDataConfig from "../../core/tableau/admin/vehicule/vehiculesDataConfig.js";

export const TableAdminDataConfig = (element) => {
    if (element === "centre_facturation") {
        return dataConfigCentreFacturation;
    } else if (element === "clients") {
        return dataConfigClient;
    } else if (element === "utilisateurs") {
        return dataConfigUtilisateurs;
    } else if (element === "ops") {
        return dataConfigOps;
    } else if (element === "administrateurs") {
        return dataConfigAdmin;
    } else if (element === "contacts") {
        return dataConfigContact;
    } else if (element === "statistique_client") {
        return dataConfigClient;
    } else if (element === "statistique_factures") {
        return dataConfigClient;
    } else if (element === "statistique_expeditions") {
        return dataConfigClient;
    } else if (element === "tracking_template") {
        return dataConfigSuivi;
    } else if (element === "fuel") {
        return dataConfigClient;
    } else if (element === "expeditions") {
        return dataConfig;
    } else if (element === "chauffeurs") {
        return dataConfigChauffeur;
    } else if (element === "facture_fuel") {
        return dataConfigFactureFuel;
    } else if (element === "facture_code_facturation") {
        return dataConfigFactureLigneFacturation;
    } else if (element === "historique") {
        return historiqueDataConfig;
    }else if (element === "vehicules") {
        return vehiculesDataConfig;
    }
};
