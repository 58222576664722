import "./App.css";
import HeaderLayout from "./layout/Header/header.layout.jsx";
import SidebarLayout from "./layout/SideBar/sidebar.layout.jsx";
import NotificationListLayout from "./layout/Notification/notificationList.layout.jsx";
import BottomLayout from "./layout/Bottom/bottom.layout.jsx";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import menu from "./core/menu/index.jsx";
import { useCallback, useEffect, useState } from "react";
import Head from "./components/HeadComponent/Head";
import ClientTchatComponent from "./components/tchatComponent/clientTchatComponent";
import clsx from "clsx";
import AuthService from "./services/auth/AuthService.js";
import useAuth from "./hooks/useAuth.jsx";
import useFetchUserData from "./hooks/useFetchUserData.jsx";



function Dash({ role }) {

    const { milieu } = useParams();
    const navigate = useNavigate();
    const [isOpened, setIsOpened] = useState(true);
    const {
        parisClientMenu,
        medicalClientMenu,
        serviceClientMenu,
        parisOpsMenu,
        medicalOpsMenu,
        serviceOpsMenu,
        adminMenu,
        exploitationParisMenu,
        exploitationMedicalMenu,
        exploitationServiceMenu
    } = menu;


    const [menuData, setMenuData] = useState({
        data: [],
        configuration: { showExtra: false, showHelp: false },
    });

    const notifications = {
        data: {
            liens: [],
        },
        link: "",
    };

    const { user } = useAuth();
    useFetchUserData()

    const menus = user?.menus //"pending,active,delivered,factured,facture" //

    const getOpsAllowedMenus = useCallback((menuLists) => {
        let lists = menuLists.data;
        //const menus =  //user?.menus
        if (menus) {
            //console.log(lists)
            const dashboardItems = lists[0].liens;
            //get dashboard
            //console.log(dashboardItems)
            const dashboardLinks = dashboardItems.filter(item => {
                const key = item.key?.split("shipping")?.[1]?.toLowerCase()
                //console.log({ k: item.key, key })
                return menus.includes(key)
            })

            //factures section
            //const factureSectionIndex = menuLists.data.findIndex(item => item.key === "facture")

            //console.log({ dashboardLinks })

            const resultsData = menuLists.data.map((listItem, i) => {
                //first element is dashboard (expéditions)
                if (i === 0) {
                    return {
                        ...listItem,
                        liens: dashboardLinks
                    }
                } if (listItem.key === "facture") {
                    return menus.includes("facture") ? listItem : null
                } else if (listItem.key === "archived") {
                    return menus.includes("archived") ? listItem : null
                } else {
                    return listItem
                }
            }
            ).filter(el => Boolean(el))

            const results = {
                ...menuLists,
                data: resultsData
            }

            return results
        }

        return menuLists
    }, [menus])


    useEffect(() => {
        // Pour la protection du rooting
        if (
            localStorage.getItem("client") === undefined ||
            !localStorage.getItem("client")
        ) {
            // window.location.href = "/";
            console.log(localStorage.getItem("client"))
        }

        let clientLocalData = JSON.parse(
            localStorage.getItem("persist:root")
        )?.roles;

        if (clientLocalData) {
            AuthService.setHeader(localStorage.getItem("client"));
            if (role === "client") {
                console.log('client')
                if (milieu === "paris") {

                    if (clientLocalData.includes("ROLE_USER_PARIS")) {
                        setMenuData({ ...parisClientMenu });
                    } else {
                        window.location.href = "/error/501";
                    }
                } else if (milieu === "medical") {
                    if (clientLocalData.includes("ROLE_USER_MEDICAL")) {
                        setMenuData({ ...medicalClientMenu });
                    } else {
                        window.location.href = "/error/501";
                    }
                } else if (milieu === "service") {
                    if (clientLocalData.includes("ROLE_USER_SERVICE")) {
                        setMenuData({ ...serviceClientMenu });
                    } else {
                        window.location.href = "/error/501";
                    }
                } else {
                    navigate("/error/501");
                }
            } else if (role === "ops") {

                if (milieu === "paris") {
                    if (clientLocalData.includes("ROLE_OPS_PARIS") || clientLocalData.includes("ROLE_ADMIN")) {

                        //setMenuData({ ...parisOpsMenu });
                        setMenuData(getOpsAllowedMenus(parisOpsMenu))
                    } else {
                        window.location.href = "/error/501";
                    }
                } else if (milieu === "medical") {
                    if (clientLocalData.includes("ROLE_OPS_MEDICAL") || clientLocalData.includes("ROLE_ADMIN")) {
                        //setMenuData({ ...medicalOpsMenu });
                        setMenuData(getOpsAllowedMenus(medicalOpsMenu))
                    } else {
                        window.location.href = "/error/501";
                    }
                } else if (milieu === "service") {
                    if (clientLocalData.includes("ROLE_OPS_SERVICE") || clientLocalData.includes("ROLE_ADMIN")) {
                        //setMenuData({ ...serviceOpsMenu });
                        setMenuData(getOpsAllowedMenus(serviceOpsMenu))
                    } else {
                        window.location.href = "/error/501";
                    }
                } else {
                    navigate("/error/501");
                }
            } else if (role === "admin") {
                if (clientLocalData.includes("ROLE_ADMIN")) {
                    setMenuData(adminMenu);
                } else {

                    window.location.href = "/error/501";
                }
            } else if (role === "exploitation") {
                setMenuData(exploitationParisMenu);
            } else {
                localStorage.clear();
                // navigate("/");
                console.log(clientLocalData)
            }
        }

    }, [window.location.href]);


    // Commenter en raison des test
    // A ne pas décommenter

    if (!localStorage.getItem("client")) {
        let lien = window.location.pathname.split("/");
        lien.pop();
        let newLink = lien.join("/") + "/auth";
        navigate(newLink);
        window.location.href = newLink;
    }

    const toggleMenu = () => {
        setIsOpened(!isOpened)
    }

    return (
        <>
            <Head espace={milieu}>
                <title>{`Exnet | ${role}-Dashboard`}</title>
            </Head>

            <div className="main-wrapper">
                <HeaderLayout type={milieu} role={role} toggleMenu={toggleMenu} isOpened={isOpened} />

                <div className="grid grid-cols-12">

                    <div className={clsx({ "col-span-2 h-100 overflow-y-auto ": isOpened, 'col-span-1 overflow-hidden ': !isOpened })} >
                        <SidebarLayout menuData={menuData} isOpened={isOpened} />
                    </div>


                    <div className={clsx({ "col-span-10": isOpened, 'px-10 col-span-11': !isOpened }, " bg-wrapper pt-16 pr-3")}>
                        <Outlet />
                        <NotificationListLayout notifications={notifications} />
                    </div>
                </div>
                <BottomLayout />
            </div>

            {/* {role === "client" && <ClientTchatComponent />} */}
        </>
    );
}

export default Dash;
