import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { refPrefixer } from "../../../utils/Functions/otherFunctions";
import Restaure from "./Restaure";
import LoaderTableau from "../../tableauComponent/LoaderTableau";
import EmptyTableau from "../../tableauComponent/EmptyTableau";
import { useSelector } from "react-redux";

export default function ExpeditionLists({
    items,
    onSelect,
    onAttributionSelect,
    onDispatchModalSelect,
    onAddTrackingClick,
    type,
    pathname,
    isLoading
}) {
    const expeditionStore = useSelector(state => state.expedition.allExpedition)
    const entete = [
        "REF",

        "EXP",
        "REF client",
        "Code \ndonneur",
        "Address/Ville\n/code postale",
        "DEST",
        "Code receveur",
        "Address/Ville\n/code postale",
        "TRACK",
        "ACTION",
    ];

    const navigate = useNavigate();

    const modifiedText = (text) => {
        return text
            ? text.split(" ").map((word, index) => {
                return index !== 0 && index % 3 === 0
                    ? [<br key={index} />, word]
                    : ' ' + word;
            })
            : "-";
    };

    return (
        <div>
            <div className="card-box">
                <div className="card-block">
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <thead>
                                <tr>
                                    {entete.map((item, index) => (
                                        <th
                                            key={index}
                                            style={{ whiteSpace: "pre-line" }}
                                        >
                                            {item}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    <tr key="">
                                        <td colSpan={entete.length} >
                                            <LoaderTableau />
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        {expeditionStore.length === 0 ? (
                                            <tr key="">
                                                <td colSpan={entete.length}>
                                                    <div className="text-center">
                                                        <span className="text-base font-500" >Vous n'aviez pas de données pour le moment</span>
                                                    </div>
                                                </td>
                                            </tr>

                                        ) : (
                                            <>
                                                {expeditionStore.map((item, index) => {
                                                    return (
                                                        <tr
                                                            key={index}
                                                            className="cursor-pointer"
                                                        >
                                                            <td
                                                                onClick={() => {
                                                                    localStorage.setItem(
                                                                        "expeditions",
                                                                        JSON.stringify(items)
                                                                    );
                                                                    navigate(
                                                                        `/${pathname[1]}/${type}/dash/${pathname[4]}/${item.ref}`
                                                                    );
                                                                }}
                                                            >
                                                                {refPrefixer(pathname[2])}
                                                                {item.ref}
                                                            </td>


                                                            <td style={{ maxWidth: '250px' }}>
                                                                {" "}
                                                                <strong>
                                                                    {modifiedText(
                                                                        item.expeditor.company
                                                                    )}
                                                                </strong>
                                                                <br />
                                                                <span>
                                                                    {modifiedText(
                                                                        item.expeditor.fullname
                                                                    )}
                                                                </span>
                                                                <br />
                                                                {item.expeditor.email &&
                                                                    item.expeditor.email
                                                                        .split(",")
                                                                        .map(
                                                                            (
                                                                                element,
                                                                                index
                                                                            ) => (
                                                                                <>
                                                                                    {" "}
                                                                                    <span
                                                                                        key={
                                                                                            element
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            element
                                                                                        }
                                                                                    </span>
                                                                                    <br />
                                                                                </>
                                                                            )
                                                                        )}
                                                                <span>
                                                                    {" "}
                                                                    {moment(
                                                                        item.pickup_date
                                                                    ).format("DD-MM-YYYY")}
                                                                </span>
                                                                <br />
                                                            </td>
                                                            <td className={'text-center'} style={{ maxWidth: '50px' }}>{item.customer_ref ? item.customer_ref : '-'}</td>
                                                            <td style={{ maxWidth: '100px' }}>{item.code_donneur ? item.code_donneur : 'NULL'}</td>
                                                            <td>
                                                                <span>
                                                                    {" "}
                                                                    {modifiedText(
                                                                        item.expeditor.address1
                                                                    )}
                                                                </span>{" "}
                                                                <br />
                                                                <span>
                                                                    {item.expeditor.city}
                                                                </span>
                                                                <br />
                                                                <span>
                                                                    {item.expeditor.postalCode}
                                                                </span>
                                                            </td>

                                                            <td  >
                                                                {" "}
                                                                <h4>{item.receiver.company}</h4>
                                                                <span>
                                                                    {modifiedText(
                                                                        item.receiver.fullname
                                                                    )}
                                                                </span>
                                                                <br />
                                                                {item.receiver.email &&
                                                                    item.receiver.email
                                                                    ? item.receiver.email
                                                                        .split(",")
                                                                        .map(
                                                                            (
                                                                                element,
                                                                                index
                                                                            ) => (
                                                                                <>
                                                                                    {" "}
                                                                                    <span
                                                                                        key={
                                                                                            element
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            element
                                                                                        }
                                                                                    </span>
                                                                                    <br />
                                                                                </>
                                                                            )
                                                                        )
                                                                    : "-"}
                                                                <span>
                                                                    {moment(
                                                                        item.delivery_date
                                                                    ).format("DD-MM-YYYY")}{" "}
                                                                </span>
                                                                <br />
                                                            </td>
                                                            <td style={{ maxWidth: '100px' }}>{item.code_receveur ? item.code_receveur : 'NULL'}</td>
                                                            <td>
                                                                <span>
                                                                    {" "}
                                                                    {modifiedText(
                                                                        item.receiver.address1
                                                                    )}
                                                                </span>{" "}
                                                                <br />
                                                                <span>
                                                                    {item.receiver.city}
                                                                </span>
                                                                <br />
                                                                <span>
                                                                    {item.receiver.postalCode}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {modifiedText(
                                                                    item.suivis_details
                                                                )}
                                                            </td>

                                                            <td>
                                                                <div className="dropdown dropdown-action">
                                                                    <a
                                                                        href="#"
                                                                        className="action-icon dropdown-toggle"
                                                                        data-bs-toggle="dropdown"
                                                                        aria-expanded="false"
                                                                    >
                                                                        <i className="fas fa-ellipsis-v"></i>
                                                                    </a>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <a
                                                                            className="dropdown-item "
                                                                            href="#"
                                                                            onClick={() => {
                                                                                onAttributionSelect(
                                                                                    item,
                                                                                    "chauffeur"
                                                                                );
                                                                            }}
                                                                        >
                                                                            <i
                                                                                className={`fa fa-user mx-3`}
                                                                            ></i>
                                                                            Attribuer à
                                                                            chauffeur
                                                                        </a>

                                                                        <a
                                                                            className="dropdown-item "
                                                                            href="#"
                                                                            onClick={() => {
                                                                                onAttributionSelect(
                                                                                    item,
                                                                                    "sedentaire"
                                                                                );
                                                                            }}
                                                                        >
                                                                            <i
                                                                                className={`fa fa-user mx-3`}
                                                                            ></i>
                                                                            Attribuer à
                                                                            sédentaire
                                                                        </a>

                                                                        <a
                                                                            className="dropdown-item "
                                                                            href="#"
                                                                            onClick={() => {
                                                                                onAttributionSelect(
                                                                                    item,
                                                                                    "voyageur"
                                                                                );
                                                                            }}
                                                                        >
                                                                            <i
                                                                                className={`fa fa-user mx-3`}
                                                                            ></i>
                                                                            Attribuer à voyageur
                                                                        </a>
                                                                        <Restaure shipping_id={item.id} />

                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                }
                                            </>
                                        )}

                                    </>
                                )}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div >
    );
}

