import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { UserReducer } from './slices/UserSlice'
import { Expedition } from './slices/expeditionSlice';
import { contactReducer } from './slices/contactSlice';
import { factureReducer } from './slices/facturaeSlice';


const persistConfig = {
    key: 'root',
    storage,
};
const persistedReducer = persistReducer(persistConfig, UserReducer);

export const store = configureStore({
    reducer: {
        persistedReducer,
        expedition: Expedition,
        contact: contactReducer,
        facture:factureReducer

    }
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)
});

export const store_2 = configureStore({
    reducer: {
        user: UserReducer,
        expedition: Expedition,
        contact: contactReducer,
        facture:factureReducer

    },
})

export const persistor = persistStore(store);
// export const persistor = persistStore(store);