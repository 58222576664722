import React, { useEffect, useState } from "react";

import { TableauDetailHeadParis } from "../../../core/tableau/client/ClientParisTableauHead";
import { TbArrowsUpDown, TbEdit, TbTrash } from 'react-icons/tb'
import { exnetSpaceColor, urlSplit } from "../../../utils/Functions/otherFunctions";
import moment from "moment";
// TableauDetailHeadParis
function TableauTrackingExpeditionComponent({ top = false, details, createdAt }) {
    const [filterBy, setfilterBy] = useState('ref');
    const [filterOrder, setfilterOrder] = useState('desc');
    const { role, exnetSpace } = urlSplit();


    const tableDataFilter = (FilterKey) => {
        setfilterBy(FilterKey)
        setfilterOrder(filterOrder === 'desc' ? 'asc' : 'desc')
    }


    return (
        <>
            <div >
                <div className="my-3 text-base font-semibold text-center ">
                    <span>SUIVI/PREUVE DE LIVRAISON</span>
                </div>
                <div className="overflow-x-auto table-responsive">

                    <table className="table min-w-full divide-y divide-gray-200">
                        <thead>
                            <tr>
                                {TableauDetailHeadParis.slice(0, TableauDetailHeadParis.length - 3).map((item, index) => (
                                    <th className="px-6 py-1 leading-4 tracking-wider uppercase pointer bg-gray-50" key={index} onClick={() => tableDataFilter(index)}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <span>
                                                {item.toUpperCase()}
                                            </span>
                                            {(index !== TableauDetailHeadParis.length - 1) &&
                                                <span>
                                                    <TbArrowsUpDown color={`${exnetSpaceColor(exnetSpace)}`} />
                                                </span>
                                            }
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">

                            {details?.suivis?.length > 0 ?
                                (
                                    <>
                                        <tr>
                                            <td className=" md:flex md:gap-2">
                                                <span className="">   {moment(createdAt).format("DD-MM-YYYY")}</span>
                                                <span className="">   {moment(createdAt).format("HH:mm")}</span>



                                            </td>
                                            <td className="">
                                                Votre expédition a été enregistrée
                                            </td>
                                            <td className="">

                                            </td>

                                        </tr>

                                        {details?.suivis.map((item, index) =>
                                        (
                                            <tr>
                                                <td className="block md:flex md:gap-6 ">
                                                    <span className="">   {moment(item.date_suivi).format("DD-MM-YYYY")}</span> {' '}
                                                    <span className="">   {item.heur_suivi?.split(':').slice(0, 2).join(':')}</span>

                                                </td>
                                                <td className="">
                                                    {item?.details}
                                                </td>

                                                <td className="">
                                                    {details?.pod[index]?.coursier?details?.pod[index]?.coursier:(details?.pod[0]?.coursier?details?.pod[0]?.coursier:'')}
                                                </td>


                                            </tr>
                                        ))
                                        }
                                    </>

                                ) :
                                (
                                    <tr>
                                        <td className=" md:flex md:gap-2">
                                            <span className="">   {moment(createdAt).format("DD-MM-YYYY")}</span>
                                            <span className="">   {moment(createdAt).format("HH:mm")}</span>



                                        </td>
                                        <td className="">
                                            Votre expédition a été enregistrée
                                        </td>
                                        <td className="">

                                        </td>

                                    </tr>
                                )}
                        </tbody>
                    </table>
                </div>
            </div>



        </>
    );
}

export default TableauTrackingExpeditionComponent;
