import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    address1: "",
    address2: "",
    calculatrice: "",
    city: "",
    civility: "",
    comments: "",
    company_id: "",
    country: "",
    created_at: "",
    email: "",
    email2: "",
    exnet_medical: "",
    exnet_paris: "",
    exnet_service: "",
    expedition: "",
    fullname: "",
    id: "",
    is_actif: "",
    is_ops_admin: "",
    num_code: "",
    num_code1: "",
    num_code2: "",
    physic_company: "",
    postal_code: "",
    poste: "",
    roles: "",
    session_code: "",
    status: "",
    telephone1: "",
    telephone2: "",
    token: "",
    token_code: "",
    tracking_choice_id: "",
    updated_at: "",
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        SetUser: (state, action) => {
            state = action.payload;
            return state;
        },
        LogOutUser: (state, action) => {
            state = {
                address1: "",
                address2: "",
                calculatrice: "",
                city: "",
                civility: "",
                comments: "",
                company_id: "",
                country: "",
                created_at: "",
                email: "",
                email2: "",
                exnet_medical: "",
                exnet_paris: "",
                exnet_service: "",
                expedition: "",
                fullname: "",
                id: "",
                is_actif: "",
                is_ops_admin: "",
                num_code: "",
                num_code1: "",
                num_code2: "",
                physic_company: "",
                postal_code: "",
                poste: "",
                roles: "",
                session_code: "",
                status: "",
                telephone1: "",
                telephone2: "",
                token: "",
                token_code: "",
                tracking_choice_id: "",
                updated_at: "",
            };
            return state;
        },
        getUser: (state) => {
            return state;
        },
    },
});

// Action creators are generated for each case reducer function
export const { SetUser, LogOutUser } = userSlice.actions;

export const UserReducer = userSlice.reducer;
