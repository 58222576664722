import React, { useCallback, useState } from 'react'
import useDisclosure from '../../hooks/useDisclosure';
import CreateCenterForm, { BillingCenterModal } from './Form';
import useAdminLists from '../../hooks/useAdminLists';
import { formatDefaultDate } from '../../utils/moment';
import { formatInterface } from '../../utils';
import { TableLoader } from '../Utils/Loader';
import { SwitchComponent } from '../Utils/Index';
import GeneralStateComponent from '../generalStateComponent/GeneralStateComponent';
import { getCountryNameOrCode } from '../../utils/const/countriesConstant';
import centreFacturationService from '../../services/centreFacturation/centreFacturationService';
import { toast } from 'react-toastify';
import PaginationComponent from '../paginationComponent/PaginationComponent';
import useTableFilter from '../../hooks/useTableFilter';
import { TbArrowsUpDown } from 'react-icons/tb';
import { PlusIcon } from '@heroicons/react/24/outline';
import { TableComponent } from '../TableauComponent';
import { BillingCenterListSotre } from '../../store/BillingCenterList';
import { TableDataStore } from '../../states/tableData';

export default function FacturationCentersView() {
    const { isOpen, onClose, onOpen } = useDisclosure();

    const {
        isOpen: isDeleteConfirmOpen,
        onClose: onDeleteConfirmClose,
        onOpen: onDeleteConfirmOpen
    } = useDisclosure();

    const type = "centre_facturation"

    const { adminData, metadata, setPage, setPerPage, isLoading, search, setSearch, onSubmit } = useAdminLists({ type });
    const [selectedItem, setSelectedItem] = useState(null)

    const onItemSelected = (item, action) => {
        setSelectedItem(item)
        if (action === "edit") {
            onOpen()
        } else {
            onDeleteConfirmOpen()
        }
    }


    const toggleActif = useCallback(async (item, value) => {
        try {
            //onSubmit({ ...item, actif: value ? 1 : 0 })
            const request = value ? centreFacturationService.activerCentreFacturationById : centreFacturationService.desactiverCentreFacturationById
            const { data } = await request({ id: item.id });
            if (data.success) {
                onSubmit({ ...item, actif: value ? 1 : 0 })
            } else {
                toast.error(`Une erreur est sruvenue`, {
                    position: "bottom-center",
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error(error)
            toast.error(`Une erreur est sruvenue`, {
                position: "bottom-center",
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }, [onSubmit])


    return (

        <div coassName={"px-2"}>
            {/*  <GeneralStateComponent dataType={type} /> */}
            <div className="flex items-center justify-between">
                <div>
                    <h4 className="uppercase page-title">Liste des centres de facturation</h4>
                    <div className="w-md">
                        <input
                            className="w-full px-4 py-2 mb-3 bg-white border border-gray-500 rounded-lg outline-none form-control"
                            type="search"
                            placeholder="Recherche"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                </div>
                <div
                    className="float-right my-auto text-white bg-black btn btn-rounded py-auto"
                    onClick={onOpen}
                >
                    <PlusIcon className={"h-4 w-4 m-3 text-xl font-bold "} />
                </div>
            </div>

            <div className="py-3">
                {adminData.total ?
                    <PaginationComponent
                        page={metadata.page}
                        handlePageClick={setPage}
                        totalItems={adminData.total}
                        perPageItems={metadata.perPage}
                        setnumberPerPage={setPerPage}
                    /> : null}
            </div>

            <Lists
                data={adminData.data}
                onSelect={onItemSelected}
                isLoading={isLoading}
                onToggleActive={toggleActif}
            />

            <div className="py-3">
                {adminData.total ?
                    <PaginationComponent
                        page={metadata.page}
                        element='pagination'
                        handlePageClick={setPage}
                        totalItems={adminData.total}
                        perPageItems={metadata.perPage}
                        setnumberPerPage={setPerPage}
                    /> : null}
            </div>

            <BillingCenterModal
                isOpen={isOpen}
                onClose={() => {
                    onClose()
                    setSelectedItem(null)
                }}
                onSubmit={(value) => {
                    onSubmit(value)
                    onClose()
                }}
                selectedData={selectedItem}
            />

        </div>
    )
}



const Lists = ({ data, onSelect, isLoading, onToggleActive }) => {

    const { results, onTableHeaderClick } = useTableFilter({ items: data })

    const dataList = TableDataStore((state) => state.dataList);
    const setDataList = TableDataStore((state) => state.setDataList);
    const updateData = TableDataStore((state) => state.updateData);
    const deleteData = TableDataStore((state) => state.deleteData);

    const HEADERS = [
        { label: 'ID', filterKey: 'id' },
        { label: 'Nom du centre', filterKey: 'billing_center_name' },
        { label: 'Type', filterKey: 'type' },
        { label: 'Clients', filterKey: 'customer_id' },
        { label: 'Pays', filterKey: 'country' },
        { label: 'Adresse', filterKey: 'address' },
        { label: 'Ville', filterKey: 'city' },
        { label: 'Téléphone', filterKey: 'telephone' },
        { label: 'Email', filterKey: 'email' },
        { label: 'Siren', filterKey: 'numero_siren' },
        { label: 'DATE DE CRÉATION', filterKey: 'created_at' },
    ];


    return (
        <TableComponent withAction={false} headerList={HEADERS} dataLength={dataList.length} isLoading={isLoading}>
            {
                dataList.map((item, i) => {
                    const formattedType = formatInterface(item.type)
                    return (
                        <tr key={i} className="py-3 divide-y divide-gray-300">
                            <td
                                className={'cursor-pointer'}
                                onClick={() => {
                                    onSelect(item, "edit")
                                }}
                            >{item.id}</td>
                            <td
                                className={'cursor-pointer'}
                                onClick={() => {
                                    onSelect(item, "edit")
                                }}
                            >{item.billing_center_name}</td>
                            <td
                                className={'cursor-pointer'}
                                onClick={() => {
                                    onSelect(item, "edit")
                                }}
                            >
                                <div className="flex items-center">
                                    {/* {Array.isArray(item?.type) ? <>
                                        {item?.type.split(",")?.map(type => (
                                            <div className={`${type} text-center rounded-sm px-1 mr-2`}>
                                                {formatInterface(type)}
                                            </div>
                                        ))}
                                    </> : <>
                                        <div className={`${item?.type} text-center rounded-sm px-1 mr-2`}>
                                            {formatInterface(item?.type)}
                                        </div>
                                    </>
                                    } */}

                                    {item.type && <>
                                        {
                                            item.type.split(",")?.map(type => (
                                                <div className={`${type} text-center rounded-sm px-1 mr-2`}>
                                                    {formatInterface(type)}
                                                </div>
                                            ))
                                        }
                                    </>}

                                </div>
                            </td>
                            <td
                                className={'cursor-pointer'}
                                onClick={() => {
                                    onSelect(item, "edit")
                                }}
                            >{item.customer_id}</td>
                            <td
                                className={'cursor-pointer'}
                                onClick={() => {
                                    onSelect(item, "edit")
                                }}
                            >
                                <div className="flex flex-wrap items-center">
                                    <img
                                        src={`https://flagcdn.com/16x12/${getCountryNameOrCode(getCountryNameOrCode(item.country), true)}.png`}
                                        srcSet={`https://flagcdn.com/32x24/${getCountryNameOrCode(getCountryNameOrCode(item.country), true)}.png 2x,
                                    https://flagcdn.com/48x36/${getCountryNameOrCode(getCountryNameOrCode(item.country), true)}.png 3x`}
                                        width="16"
                                        height="12"
                                        alt={getCountryNameOrCode(
                                            getCountryNameOrCode(item.country),
                                            true
                                        )} />
                                    <div className='ml-1'>
                                        {getCountryNameOrCode(item.country)}
                                    </div>
                                </div>
                            </td>
                            <td
                                className={'cursor-pointer'}
                                onClick={() => {
                                    onSelect(item, "edit")
                                }}
                            >{item.address}</td>
                            <td
                                className={'cursor-pointer'}
                                onClick={() => {
                                    onSelect(item, "edit")
                                }}
                            >{item.city}</td>
                            <td
                                className={'cursor-pointer'}
                                onClick={() => {
                                    onSelect(item, "edit")
                                }}
                            >{item.telephone || item.telephone1}</td>
                            <td
                                className={'cursor-pointer'}
                                onClick={() => {
                                    onSelect(item, "edit")
                                }}
                            >{item.email}</td>
                            <td
                                className={'cursor-pointer'}
                                onClick={() => {
                                    onSelect(item, "edit")
                                }}
                            >{item.numero_siren ?? "-"}</td>
                            {/* <td>-</td> */}
                            <td
                                className={'cursor-pointer'}
                                onClick={() => {
                                    onSelect(item, "edit")
                                }}
                            >{formatDefaultDate(item.created_at)}</td>

                        </tr>
                    )
                })
            }
        </TableComponent>



    )
}