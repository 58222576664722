import React, { useState } from 'react'
import ModalComponent from '../Utils/Modal/Index';
import ManifestService from "../../services/manifest/service";
import { useAppContext } from '../../context';

const ManifestForm = ({ selectedData, editable, newData, onSubmit, type }) => {
    const { isAuth, setIsAuth } = useAppContext();
    
    const initialData = {
        description: "",
        mawb: "",
        ex1: "",
        datemanifest: "",
        viavol: "",
        type:isAuth?.spaceName
    }
    const [manifestData, setManifestData] = useState(selectedData || initialData)

    const [isSubmitting, setIsSubmitting] = useState(false)


    const setFormValue = (key, value) => {
        //alert(`${key}---${value}`)
        setManifestData(d => ({
            ...d,
            [key]: value
        }))
    }

    const handleSubmit = async (e) => {
        try {
            e?.preventDefault()
            setIsSubmitting(true);
            const request = selectedData ? ManifestService.update : ManifestService.create
            const submitData = manifestData;
            delete submitData.created_at;
            delete submitData.updated_at;
            const { data } = await request(submitData);
            if (data.success) {
                setManifestData(initialData);
                onSubmit?.(selectedData ? manifestData : data.data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsSubmitting(false);
        }
    }

    return (
        <form className="w-full px-3 py-4">
            <div className="flex flex-wrap mb-6 -mx-3">
                <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0">
                    <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase" htmlFor="description">
                        Description
                    </label>
                    <input
                        className="block w-full px-4 py-3 mb-3 text-sm leading-tight text-gray-700 bg-white border border-gray-300 rounded-lg appearance-none focus:outline-none focus:bg-white"
                        id="description"
                        type="text"
                        placeholder=""
                        value={manifestData.description}
                        onChange={e => {
                            setFormValue("description", e.target.value)
                        }}
                    />
                </div>
                <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0">
                    <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase" htmlFor="mawb">
                        MAWB
                    </label>
                    <input
                        className="block w-full px-4 py-3 mb-3 text-sm leading-tight text-gray-700 bg-white border border-gray-300 rounded-lg appearance-none focus:outline-none focus:bg-white"
                        id="mawb"
                        type="text"
                        placeholder=""
                        value={manifestData.mawb}
                        onChange={e => {
                            setFormValue("mawb", e.target.value)
                        }}
                    />
                </div>
            </div>

            <div className="flex flex-wrap mb-6 -mx-3">
                <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0">
                    <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase" htmlFor="ex1">
                        {/* Ex1 */}
                        Commentaire
                    </label>
                    <input
                        className="block w-full px-4 py-3 mb-3 text-sm leading-tight text-gray-700 bg-white border border-gray-300 rounded-lg appearance-none focus:outline-none focus:bg-white"
                        id="ex1"
                        type="text"
                        placeholder=""
                        value={manifestData.ex1}
                        onChange={e => {
                            setFormValue("ex1", e.target.value)
                        }}
                    />
                </div>
                <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0">
                    <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase" htmlFor="viavol">
                        Vol
                    </label>
                    <input
                        className="block w-full px-4 py-3 mb-3 text-sm leading-tight text-gray-700 bg-white border border-gray-300 rounded-lg appearance-none focus:outline-none focus:bg-white"
                        id="viavol"
                        type="text"
                        placeholder=""
                        value={manifestData.viavol}
                        onChange={e => {
                            setFormValue("viavol", e.target.value)
                        }}
                    />
                </div>
            </div>

            <div className="flex flex-wrap mb-6 -mx-3">
                <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0">
                    <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase" htmlFor="datemanifest">
                        Manifest Date
                    </label>
                    <input
                        className="block w-full px-4 py-3 mb-3 text-sm leading-tight text-gray-700 bg-white border border-gray-300 rounded-lg appearance-none focus:outline-none focus:bg-white"
                        id="datemanifest"
                        type="date"
                        placeholder=""
                        value={manifestData.datemanifest}
                        onChange={e => {
                            setFormValue("datemanifest", e.target.value)
                        }}
                    />
                </div>
                {/* <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0">
                    <label className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase" htmlFor="datemanifest">
                        Manifest Date
                    </label>
                    <DriversSelector />
                </div> */}
            </div>


            <div className="flex justify-center">
                {editable && (
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                        disabled={isSubmitting}
                    >
                        Modifier
                    </button>
                )}

                {newData && (
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                        disabled={isSubmitting}
                    >
                        Enregistrer
                    </button>
                )}
            </div>
        </form>
    )
}

export default function ManifestFormModal({ isOpen, onClose, withoutModal, selectedData, editable, title, onSubmit, type }) {
    return (
        <div>
            {withoutModal ?
                <ManifestForm
                    selectedData={selectedData}
                    editable={editable}
                /> : (
                    <>{/* 
                        <div onClick={onOpen}>
                            {triggerComponent || (
                                <div
                                    className="float-right btn btn-primary btn-rounded"
                                    onClick={onOpen}
                                >
                                    <i className="fas fa-plus"></i>
                                </div>
                            )}
                        </div> */}
                        <ModalComponent
                            isOpen={isOpen}
                            onClose={onClose}
                            title={title}
                            editable={editable}
                        >
                            <ManifestForm
                                selectedData={selectedData}
                                newData={!selectedData}
                                editable={editable}
                                onSubmit={(data) => {
                                    onClose();
                                    onSubmit?.(data);
                                }}
                                type={type}
                            />
                        </ModalComponent>
                    </>
                )}
        </div>
    )
}

