import React, { useEffect, useState } from 'react';
import { TbArrowsUpDown } from 'react-icons/tb';
import { exnetSpaceColor, urlSplit } from '../../utils/Functions/otherFunctions';
import { trierTableau } from '../../utils/Functions/tableFilter';
import moment from 'moment';
import LoaderTableau from '../tableauComponent/LoaderTableau';
import { TableComponent } from '../TableauComponent';

const headers = [
    {
        label: 'Expedition',
        filterKey: ''
    },
    {
        label: 'Date',
        filterKey: 'created_at'
    },
    {
        label: 'Action',
        filterKey: 'action'
    },
    {
        label: 'Utilisateur',
        filterKey: 'user.name'
    },

]

function List({ items = [], isLoading }) {
    const { exnetSpace } = urlSplit()
    const [filterBy, setfilterBy] = useState('ref');
    const [filterOrder, setfilterOrder] = useState('desc');




    const updateFilterListe2 = (filterKey) => {
        //console.log({ filterKey })
        if (filterKey) {
            setfilterBy(filterKey)
            let newOrderFilter = filterOrder === 'desc' ? 'asc' : 'desc'
            setfilterOrder(newOrderFilter)

        }
    }

    const newTable = trierTableau(items, filterBy, filterOrder)

    return (
        <>
            <TableComponent withAction={false} headerList={headers} dataLength={newTable.length} isLoading={isLoading}>
                {newTable.map((item, index) => (
                    <tr key={index}>
                        <td>
                            {item?.shipping?.ref || '-'}
                        </td>
                        <td>
                            {moment(item.created_at).format("DD-MM-YYYY")}
                        </td>
                        <td>
                            {item.action}
                        </td>
                        <td>
                            {item?.user?.name}
                        </td>
                    </tr>
                ))}
            </TableComponent>

        </>
    );
}

export default List;