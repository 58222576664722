/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import LoaderTableau from "./LoaderTableau";
import EmptyTableau from "./EmptyTableau";
import CreateClientForm from "../Clients/CreateForm";

import UsersForm from "../Users/UsersForm";
import UserSimpleForm from "../Users/UsersSimpleForm";
import ModalComponent from "../Utils/Modal/Index";
import useDisclosure from "../../hooks/useDisclosure";
import { ModalTitle } from "../../utils/Functions/ModalTitle";
import CenterFormModal from "../Centers/Form";
import ContactAdminFomComponent from "../contact/ContactAdminFomComponent";
import VehiculeAdminFormComponent from "../vehicule/VehiculeAdminFormComponent";
import { PlusIcon } from "@heroicons/react/24/outline";

const TableauComponent = React.memo(
  ({
    colonnes,
    valeurs,
    title,
    withFiltre,
    parametreFiltre,
    expeditionKind,
    espace,
    role,
    secondTitle,
    top,
    children,
    dataConfig,
    dataType,
    hiddenCreateButton,
  }) => {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [modalTitle, setmodalTitle] = useState("");
    const [modalFormContent, setmodalFormContent] = useState({});

    const [chargement, setChargement] = useState(true);

    const formatDate = (dateString, format) => {
      if (dateString.split(":").length > 2)
        dateString = dateString.replace(" 00:00:00", "");
      const dateObject = new Date(dateString);

      return dateObject.toLocaleString("fr-FR", format);
    };

    const lignes = (element, dataConfig) => {
      if (dataConfig !== undefined) {
        dataConfig.sort((a, b) => {
          if (a.index > b.index) {
            return 1;
          }
          if (a.index < b.index) {
            return -1;
          }
          return 0;
        });
        const lignes = [];
        const keys = Object.keys(element);
        for (const config of dataConfig) {
          const key = config.key;

          if (config.custom && config.expression && config.expression.value) {
            const expression = config.expression.value;
            let expressionValue;
            if (expression.includes(".")) {
              const regex = /{([^}]+)}/g;
              const matches = expression.match(regex);
              expressionValue = expression;
              if (matches) {
                for (const match of matches) {
                  const keys = match
                    .replace("{", "")
                    .replace("}", "")
                    .split(".");
                  let value = element;
                  for (const key of keys) {
                    value = value[key];
                  }
                  value === null
                    ? (value = "")
                    : (expressionValue = expressionValue.replace(match, value));
                }
              }
              expressionValue = expressionValue.replaceAll("undefined", "");

              expressionValue === "" ||
                expressionValue === null ||
                expressionValue === "null"
                ? config.empty
                  ? (expressionValue = (
                    <span
                      className="custom-badge status-red"
                      style={{
                        minWidth: "115px",
                        display: "block",
                        maxWidth: "fit-content",
                      }}
                    >
                      {config.empty.html}
                    </span>
                  ))
                  : (expressionValue = "")
                : null;
            } else {
              const regex = /{([^}]+)}/g;
              const matches = expression.match(regex);
              expressionValue = expression;
              if (matches) {
                for (const match of matches) {
                  const key = match.replace("{", "").replace("}", "");
                  element[key] === null
                    ? (expressionValue = "")
                    : (expressionValue = expressionValue.replace(
                      match,
                      element[key]
                    ));
                }
              }
              expressionValue = expressionValue.replaceAll("undefined", "");
              // eslint-disable-next-line no-unused-expressions
              expressionValue === "" ||
                expressionValue === null ||
                expressionValue === "null"
                ? config.empty
                  ? (expressionValue = (
                    <span
                      className="custom-badge status-red"
                      style={{
                        minWidth: "115px",
                        display: "block",
                        maxWidth: "fit-content",
                      }}
                    >
                      {config.empty.html}
                    </span>
                  ))
                  : (expressionValue = "")
                : null;
            }

            switch (config.expression.type) {
              case "link":
                expressionValue = (
                  <Link
                    to={`/${role}/${espace}/dash/${expeditionKind
                      .toLowerCase()
                      .replace(/_/g, "-")}/${element[key]}`}
                  >
                    {expressionValue}
                  </Link>
                );
                break;
              case "button":
                expressionValue = (
                  <button
                    className="btn btn-primary"
                    onClick={() => goDetail(element[key], config.index)}
                  >
                    {expressionValue}
                  </button>
                );
                break;
              case "date":
                expressionValue = (
                  <span>
                    {formatDate(expressionValue, config.expression.format)}
                  </span>
                );
                break;
              case "espace":
                expressionValue = (
                  <span
                    className={`custom-badge ${expressionValue}`}
                    style={{
                      minWidth: "115px",
                      display: "block",
                      maxWidth: "fit-content",
                    }}
                  >
                    {expressionValue.toUpperCase()}
                  </span>
                );
                break;
              case "liste_roles_ops":
                // if expressionValue contains ROLE_OPS_PARIS or ROLE_OPS_MEDICAL or ROLE_OPS_SERVICE
                let paris = expressionValue.includes("ROLE_OPS_PARIS") && (
                  <span
                    className={`custom-badge paris`}
                    style={{
                      minWidth: "auto",
                      display: "inline-block",
                      maxWidth: "fit-content",
                      fontSize: "xx-small",
                      margin: "2px",
                    }}
                  >
                    {"paris".toUpperCase()}
                  </span>
                );

                let medical = expressionValue.includes("ROLE_OPS_MEDICAL") && (
                  <span
                    className={`custom-badge medical`}
                    style={{
                      minWidth: "auto",
                      display: "inline-block",
                      maxWidth: "fit-content",
                      fontSize: "xx-small",
                      margin: "2px",
                    }}
                  >
                    {"medical".toUpperCase()}
                  </span>
                );

                let service = expressionValue.includes("ROLE_OPS_SERVICE") && (
                  <span
                    className={`custom-badge service`}
                    style={{
                      minWidth: "auto",
                      display: "inline-block",
                      maxWidth: "fit-content",
                      fontSize: "xx-small",
                      margin: "2px",
                    }}
                  >
                    {"service".toUpperCase()}
                  </span>
                );

                expressionValue = (
                  <span
                    style={{
                      display: "contents",
                      maxWidth: "110px",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {paris}
                    {medical}
                    {service}
                  </span>
                );
                break;
              case "radio":
                let randomInt = Math.floor(Math.random() * 100000);
                expressionValue === 1 ||
                  expressionValue === true ||
                  expressionValue === "1"
                  ? (expressionValue = (
                    <div className="material-switch security-switch">
                      <input
                        id={`radio_${config.index}_${randomInt}`}
                        type="checkbox"
                        readOnly={true}
                        checked
                      />
                      <label
                        style={{
                          pointerEvents: "none",
                        }}
                        htmlFor={`radio_${config.index}_${randomInt}`}
                        className="badge-active"
                      ></label>
                    </div>
                  ))
                  : (expressionValue = (
                    <div className="material-switch security-switch">
                      <input
                        id={`radio_${config.index}_${randomInt}`}
                        type="checkbox"
                        readOnly={true}
                      />
                      <label
                        style={{
                          pointerEvents: "none",
                        }}
                        htmlFor={`radio_${config.index}_${randomInt}`}
                        className="badge-inactive"
                      ></label>
                    </div>
                  ));
                break;
              default:
                break;
            }

            lignes.push(expressionValue);
          } else if (config.custom && config.option) {
            const options = config.option;
            const expression = (
              <td className="text-center">
                <div className="dropdown dropdown-action">
                  <a
                    href="#"
                    className="action-icon dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fas fa-ellipsis-v"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    {options.map((option, index) => {
                      return (
                        <a
                          className="dropdown-item "
                          href={option.field}
                          onClick={() => option.onclick()}
                        >
                          <i className={`${option.icon} mx-3`}></i>
                          {option.text}
                        </a>
                      );
                    })}
                  </div>
                </div>
              </td>
            );
            lignes.push(expression);
          } else {
            let prefix = "";
            if (config.prefix) {
              let type = element["type"];
              switch (type) {
                case "paris":
                  prefix = "EX";
                  break;
                case "medical":
                  prefix = "EM";
                  break;
                case "service":
                  prefix = "ES";
                  break;
                default:
                  prefix = "";
                  break;
              }
            }
            lignes.push(prefix + element[config.key]);
          }
        }
        return lignes;
      }
    };

    const navigate = useNavigate();

    const goDetail = (identifiant, cle) => {
      identifiant = identifiant.replace(/\D/g, "");
      expeditionKind = expeditionKind
        .toLowerCase()
        .replace(/_/g, "-")
        .replace(" ", "-");

      if (cle === 0) {
        if (role === "admin") {
          // find data from valeus where id equal to identifiant
          const data = valeurs.find((element) => {
            return element.id == identifiant;
          });


          // setmodalFormContent({ ...data });
          // setmodalTitle(`${ModalTitle("view", dataType)} N° ${identifiant}`);
          // onOpen(data);
        } else {
          navigate(`/${role}/${espace}/dash/${expeditionKind}/${identifiant}`);
        }
      }

      // alert(identifiant);
    };

    const newExpedition = () => {
      navigate(
        `/${role}/${espace}/dash/${expeditionKind
          .toLowerCase()
          .replace(/_/g, "-")}/new`
      );
    };

    useEffect(() => {
      if (valeurs.length > 0) {
        setChargement(false);
      }
      setTimeout(() => {
        if (valeurs.length === 0) {
          setChargement(false);
        }
      }, 5000);
    }, [valeurs]);

    const dataTypeToLowerCase = dataType ? dataType.toLowerCase() : "";

    const splitAndJoinWithNewLine = (text) => {
      let result = "";

      if (typeof text !== "string") {
        result = text;
      } else {
        const parts = text.split("@@");
        result = parts.map((part, index) => (
          <React.Fragment key={index}>
            {part}
            {index !== parts.length - 1 && <br />}
          </React.Fragment>
        ));
      }

      return result;
    };


    return (
      <>
        <ModalComponent
          isOpen={isOpen}
          onClose={onClose}
          size="2xl"
          title={modalTitle}
        >
          {dataType === "utilisateurs" && (
            <CreateClientForm
              withoutModal={true}
              modalFormContent={modalFormContent}
              editable={false}
              dataType={dataType}
            />
          )}
          {dataType === "clients" && (
            <CreateClientForm
              withoutModal={true}
              modalFormContent={modalFormContent}
              editable={false}
              dataType={dataType}
            />
          )}
          {dataType === "ops" && (
            <UserSimpleForm
              withoutModal={true}
              modalFormContent={modalFormContent}
              editable={false}
            />
          )}
          {dataType === "administrateurs" && (
            <UserSimpleForm
              withoutModal={true}
              modalFormContent={modalFormContent}
              editable={false}
            />
          )}
          {dataType === "chauffeurs" && (
            <UserSimpleForm
              withoutModal={true}
              modalFormContent={modalFormContent}
              editable={false}
            />
          )}
          {dataType === "centre_facturation" && (
            <CenterFormModal
              withoutModal={true}
              modalFormContent={modalFormContent}
              editable={false}
              dataType={dataType}
            />
          )}

          {dataType === "contacts" && (
            <ContactAdminFomComponent
              withoutModal={true}
              modalFormContent={modalFormContent}
              editable={false}
              dataType={dataType}
              Fermer={onClose}
            />
          )}
        </ModalComponent>

        <div className="row">
          <div className="col-sm-4 col-5">
            <h4 className="page-title">
              <p className={"my-2"}>
                {title === "courses"
                  ? "COURSES A COURSES"
                  : title.toUpperCase()}{" "}
              </p>
              <span>
                {/* {secondTitle} */}
                {secondTitle && (
                  <>
                    {secondTitle.charAt(0).toUpperCase() +
                      secondTitle.slice(1).toLowerCase()}
                  </>
                )}
              </span>
            </h4>
          </div>
          <div className="text-center col-sm-8 col-7 m-b-5">
            {role === "admin" ? (
              dataTypeToLowerCase === "clients" ? (
                <CreateClientForm />
              ) : dataTypeToLowerCase === "ops" ? (
                <UserSimpleForm title="Ajouter un Ops" />
              ) : dataTypeToLowerCase === "administrateurs" ? (
                <UserSimpleForm title="Ajouter un Admin" />
              ) : dataTypeToLowerCase === "utilisateurs" ? (
                <UsersForm />
              ) : dataTypeToLowerCase === "centre_facturation" ? (
                <CenterFormModal dataType={dataTypeToLowerCase} />
              ) : dataTypeToLowerCase === "contacts" ? (
                <ContactAdminFomComponent />
              ) : dataTypeToLowerCase === "vehicules" ? (
                <VehiculeAdminFormComponent />
              ) : null
            ) : (
              !hiddenCreateButton && (
                <Link
                  to={`/${role}/${espace}/dash/${expeditionKind
                    .toLowerCase()
                    .replace(/ /g, "-")}/new`}
                  className="float-right btn btn-primary btn-rounded"
                >
                 <PlusIcon  className={"h-4 w-4 m-3 text-xl font-bold "}/>
                </Link>
              )
            )}
          </div>
        </div>

        {top && <div className="row m-b-30">{children}</div>}
        {withFiltre ? (
          <div className="content">
            <div className="buy-form">
              <div className="row filter-row">
                {parametreFiltre.map((item, index) => (
                  <>
                    {item.type === "date" ? (
                      <>
                        {item.niveau === "from" ? (
                          <>
                            <div
                              className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12"
                              key={item + "_" + index}
                            >
                              <div className="form-group form-focus">
                                <label className="focus-label">
                                  {item.libelle}
                                </label>
                                <div className="cal-icon">
                                  <input
                                    className="form-control floating datetimepicker"
                                    type="text"
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12"
                              key={item + "_" + index}
                            >
                              <div className="form-group form-focus">
                                <label className="focus-label">
                                  {item.libelle}
                                </label>
                                <div className="cal-icon">
                                  <input
                                    className="form-control floating datetimepicker"
                                    type="text"
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {item.type === "text" ? (
                          <>
                            <div
                              className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12"
                              key={item + "_" + index}
                            >
                              <div className="form-group form-focus">
                                <label className="focus-label">
                                  {item.libelle}
                                </label>
                                <input
                                  type="text"
                                  className="form-control floating"
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12"
                              key={item + "_" + index}
                            >
                              <div className="form-group bg-hover ">
                                {/* <label className="focus-label">
                                                                {item.libelle}
                                                            </label> */}

                                <Select
                                  className="w-full bg-transparent border border-gray-500 rounded-lg outline-none basic-single selection"
                                  classNamePrefix="select"
                                  defaultValue={null}
                                  isClearable={true}
                                  isSearchable={true}
                                  name="color"
                                  options={item.liste}
                                  placeholder={item.libelle}
                                // onChange={setPays}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                ))}

                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <a href="#" className="btn btn-success btn-block">
                    {" "}
                    Rechercher{" "}
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-striped custom-table datatable">
                      <thead>
                        <tr>
                          {colonnes.map((item, index) =>
                            item === "ACTION" ? (
                              <th key={item + "_" + index}>{item}</th>
                            ) : (
                              <th className=""> {item}</th>
                            )
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {chargement ? (
                          <tr key="">
                            <td colSpan={colonnes.length}>
                              <LoaderTableau />
                            </td>
                          </tr>
                        ) : (
                          <>
                            {valeurs.length === 0 ? (
                              <tr>
                                <td colSpan={colonnes.length}>
                                  <div className="text-center">
                                    <span className="text-base font-500" >Vous n'aviez pas de données pour le moment</span>
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              <>
                                {valeurs.map((item, index) => (
                                  <tr key={`ligne_${index}`}>
                                    {lignes(item, dataConfig).map(
                                      (element, cle) => (
                                        <td
                                          className={
                                            cle === 0
                                              ? "cursor-pointer"
                                              : ""
                                          }
                                          key={`colonne_${cle}`}
                                          onClick={() => goDetail(element, cle)}
                                        >
                                          {cle === 0
                                            ? element
                                            : splitAndJoinWithNewLine(element)}
                                        </td>
                                      )
                                    )}
                                  </tr>
                                ))}
                              </>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="content">
            <div className="row">
              <div className="col-sm-12">
                <div className="card-box">
                  <div className="card-block">
                    <div className="table-responsive">
                      <table className="table datatable table-stripped ">
                        <thead>
                          <tr>
                            {colonnes.map((item, index) => (
                              <th key={item + "_" + index}>{item}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {chargement ? (
                            <tr key="">
                              <td colSpan={colonnes.length}>
                                <LoaderTableau />
                              </td>
                            </tr>
                          ) : (
                            <>
                              {valeurs.length > 0 ? (
                                <>
                                  {valeurs.map((item, index) => (
                                    <tr key={`ligne_${index}`}>
                                      {lignes(item, dataConfig).map(
                                        (element, cle) => (
                                          <td
                                            className={
                                              cle === 0
                                                ? "cursor-pointer"
                                                : ""
                                            }
                                            key={`colonne_${cle}`}
                                            onClick={() =>
                                              goDetail(element, cle)
                                            }
                                          >
                                            {cle === 0
                                              ? element
                                              : splitAndJoinWithNewLine(
                                                element
                                              )}
                                          </td>
                                        )
                                      )}
                                    </tr>
                                  ))}
                                </>
                              ) : (
                                <tr>
                                  <td colSpan={colonnes.length}>
                                    <div className="text-center">
                                      <span className="text-base font-500" >Vous n'aviez pas de données pour le moment</span>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {!top && <div className="row m-t-30">{children}</div>}
      </>
    );
  }
);

export default TableauComponent;
