import React from 'react';
import LoaderTableau from '../../tableauComponent/LoaderTableau';
import { TableRow } from './Row';

import useTableFilter from '../../../hooks/useTableFilter';
import { TbArrowsUpDown } from 'react-icons/tb';
import { TableComponent } from '../../TableauComponent';

function List({ isLoading, items }) {

    const { results, onTableHeaderClick } = useTableFilter({ items });

    const HEADERS = [
        { label: 'N°', filterKey: 'id' },
        { label: 'TEMPLATE', filterKey: 'details' },
        { label: 'PARIS', filterKey: 'isparis' },
        { label: 'MEDICALS', filterKey: 'ismedical' },
        { label: 'SERVICES', filterKey: 'isservice' },
        { label: 'CHAUFFEUR', filterKey: 'isdriver' },
        { label: 'DATE DE CREATION', filterKey: 'created_at' },
        // Add more columns as needed
        // { label: 'ACTION', key: 'action' },
    ];

    return (
        <TableComponent withAction={false} headerList={HEADERS} dataLength={results.length} isLoading={isLoading}>
            {results.map((item, index) => (
                <TableRow key={index} item={item} />
            ))
            }

        </TableComponent>)

}

export default List;