import React, { useEffect, useState } from "react";
import Select from "react-select";
import { IoIosAdd } from "react-icons/io"
import opsService from "../../../services/ops/opsService";

export default function CoursierSelector({
    selectedValue,
    onChange,
    isDisabled = false,
    label = "Coursier*",
    type = "chauffeurs",
    ...props
}) {
    const [coursiers, setCoursiers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isFocus, setIsFocus] = useState(false);

    const options = [...coursiers].filter(c => {
        const regExp = new RegExp(selectedValue, "gi");
        return c.value?.match(regExp)
    }).slice(0, 20)



    useEffect(() => {
        const fetchCoursiers = async () => {
            setIsLoading(true);
            const isChauffeur = type === "chauffeurs";

            const request = isChauffeur ? opsService.getChauffeurs : opsService.getCoursiers
            const { data } = await request()

            if (data.success) {
                setCoursiers(
                    data.data.map((c) => {
                        const value = c[isChauffeur ? "chaufeur" : "coursier"]
                        return ({ label: value, value })
                    })
                );
                setIsLoading(false);
            }
        };

        fetchCoursiers();
    }, [type]);



    return (
        <div className="relative">
            <label
                htmlFor="coursiers"
                className="block mb-1 text-sm font-medium text-gray-900  capitalize"
            >
                {label}
            </label>
            <input
                className={`${isDisabled ? "bg-gray-50" : "bg-white"} border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-4 p-2.5      `}
                id="agents"
                type="agents"
                placeholder=""
                value={selectedValue}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
                onFocus={() => {
                    setIsFocus(true)
                }}
                onBlur={() => {
                    setTimeout(() => {
                        setIsFocus(false)
                    }, 300)
                }}
                disabled={isDisabled}
            />
            {isFocus && options.length > 0 && (
                <div className="absolute left-0 right-0 bg-white mt-2 border max-h-60 rounded-sm overflow-scroll">
                    {options.map((opt, i) => (
                        <div
                            onClick={() => {
                                onChange(opt.value)
                            }} className="px-4 py-2 cursor-pointer text-sm" key={i}>
                            {opt.label}
                        </div>
                    ))}
                </div>
            )}

        </div>
    );
}
