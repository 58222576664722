// create Client react service

import axios from "axios";
import TokenService from "../config/tokenService.js";

class ClientService {

    constructor() {
        TokenService.setHeader();
    }
    fetchClients(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/clients`, data);
    }

    fetchClientById(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/client`, data);
    }

    desactiverClientById(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/client/desactiver`, data);
    }

    activerClientById(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/client/activer`, data);
    }

    addClient(Client) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/client/create`, Client);
    }

    editClient(Client) {
        return axios.post(`${process.env.REACT_APP_API_URL}/admin/client/edit`, Client);
    }

}

export default new ClientService();
