import React from "react";

function HeaderComponent({title,subtitle}) {
    return (
        <p className="">
            <h4 className="page-title uppercase mb-1">{title}</h4>
            <span className="text-gray-400 text-sm">{subtitle}</span>
        </p>
    );
}

export default HeaderComponent;
