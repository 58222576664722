import React, { useCallback, useEffect, useState } from 'react'
import useAdminLists from '../../../hooks/useAdminLists';
import useDisclosure from '../../../hooks/useDisclosure';
import { TbArrowsUpDown, TbMotorbike, TbTruckDelivery } from 'react-icons/tb';
import { PiCarProfileLight } from "react-icons/pi";
import OptionsSelector from '../../Utils/Selectors/Index';
import vehiculeService from '../../../services/vehicule/vehiculeService';
import { toast } from "react-toastify";

/* export default function CarAttribution() {
    const { adminData: carsData, isLoading } = useAdminLists({ type: "vehicules" });

    const { isOpen, onClose } = useDisclosure()

    return (
        <>
        <div>

        </div>
            <ModalUI
                isOpen={isOpen}
                onClose={onClose}
                title={"Attribution de véhicules"}
            >
                <div>
                    {carsData.data.map(car => (
                        <div className="flex items-center">
                            <div className="flex items-center max-w-[140px]">
                                {
                                    car.type_vehicule === "2" ?
                                        <TbMotorbike size={22} className="ml-[-10px]" /> :
                                        car.type_vehicule === "4" ?
                                            <PiCarProfileLight size={22} /> :
                                            car.type_vehicule === "8" ?
                                                <TbTruckDelivery size={25} />
                                                : null
                                }
                                {car.plaque}
                            </div>
                        </div>
                    ))}
                </div>
            </ModalUI>
        </>
    )
} */


export default function CarAttribution({ items, userId, selectedCarId, onAttributed }) {
    //const { adminData: carsData, isLoading } = useAdminLists({ type: "vehicules" });

    const [value, setValue] = useState()

    const options = items.map(item => {
        const carType = item.type_vehicule === "2" ? "2 roues" : item.type_vehicule === "4" ? "4 roues" : item.type_vehicule === "8" ? "camion" : ""
        return ({
            ...item,
            label: `${carType} ${carType ? " - " : ""} ${item.plaque}`,
            value: item.id
        })
    })

    useEffect(() => {
        if (selectedCarId) {
            setValue(selectedCarId);
        }
    }, [selectedCarId])

    const onVehiculeSelected = useCallback(async (vehicule) => {
        try {
            const { data } = await vehiculeService.attributeVehicule({
                user_id: userId,
                vehicule_id: vehicule.id
            });
            if (data.success) {
                toast.success("Le véhicule a été attribué avec succès");
                //onAttributed(vehicule)
            }
        } catch (error) {
            console.log(error)
        }
    }, [userId])

    return (
        <div className='flex items-center'>
            <OptionsSelector
                options={options}
                value={value}
                onChange={v => {
                    setValue(v.value)
                    onVehiculeSelected(v)
                }}
            />
        </div>
    )
}