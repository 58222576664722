import React, { useEffect, useState } from "react";
import sendFile from "../../../assets/img/icon/chat-icon-06.svg";
import userAvatar from "../../../assets/img/profile/avatar-03.jpg";
import chatFoot from "../../../assets/img/icon/chat-foot-icon-04.svg";
import chatFoot_2 from "../../../assets/img/icon/chat-foot-icon-03.svg";
import chatFoot_3 from "../../../assets/img/icon/chat-foot-icon-01.svg";
import chatFoot_4 from "../../../assets/img/icon/chat-foot-icon-02.svg";
import ChatService from "../../../services/chat/ChatService";
import useAuth from "../../../hooks/useAuth";
import { formatMessageDate, urlSplit } from "../../../utils/Functions/otherFunctions";
import moment from "moment";
import axios from "axios";
import { TbUser } from "react-icons/tb";

function ChatBoxComponent({ activeChat }) {
    const [messageList, setMessageList] = useState([])
    const [newMessage, setNewMessage] = useState('');
    const [fileMessage, setFileMessage] = useState(false)
    const { isAuthenticated, user } = useAuth();
    const { role } = urlSplit()

    const handleSendMessage = () => {
        if (newMessage !== '' || fileMessage.length > 0) {
            const formData = new FormData();
            formData.append('user_id', user.id);
            formData.append('message', newMessage);
            formData.append('chat_id', activeChat.id);

            if (fileMessage.length > 0) {
                // Ajoutez les fichiers sélectionnés à l'objet FormData
                for (let i = 0; i < fileMessage.length; i++) {
                    formData.append('documents', fileMessage[i]);
                }
            }
            ChatService.createMessage(formData).then(
                (res) => {
                    let msg = res.data.data;
                    let newMessageList = [...messageList]
                    newMessageList.push(msg)
                    setMessageList(newMessageList)
                    setNewMessage('')
                },
                (error) => {
                    console.log(error)
                }
            )
        }
    }

    // Fonction appelée lorsqu'un fichier est sélectionné
    const handleFileChange = (event) => {
        const files = event.target.files;
        setFileMessage(files);
    };


    useEffect(() => {
        if (activeChat) {
            let msg = activeChat?.chat_messages
            if (msg) {
                setMessageList([...msg])
            }
        }

    }, [activeChat])


    return (
        <>
            <div className="card chat-box">
                <div className="chat-search-group">
                    <div className="chat-user-group mb-0 d-flex align-items-center">
                        <div className="img-users call-user">
                            <a href="#">
                            <TbUser size={25} className="text-black" />
                            </a>
                            <span className="active-users bg-info" />
                        </div>
                        <div className="chat-users">
                            <div className="user-titles">
                                <h5>{activeChat?.shipping?.username}</h5>
                            </div>
                            <div className="user-text">
                                <p> {moment(new Date()).format("HH:MM")}{" "}</p>
                            </div>
                        </div>
                    </div>
                    <div className="chat-search-list">
                        <span className={'text-base'}>Reférence {activeChat?.shipping?.ref}</span>
                    </div>
                </div>
            </div>
            <div className="card chat-message-box mb-0">
                <div className="card-body p-0 flex flex-col h-[75vh]">

                    {/* message list */}
                    <div className="chat-body flex-1 overflow-y-scroll">
                        <ul className="list-unstyled chat-message">
                            {messageList.length > 0 ? <>
                                {messageList.map(
                                    (messages, index) => (
                                        <>
                                            {messages.user_id === user.id ?
                                                <>
                                                    {/* Expeditor part */}
                                                    <li className="media d-flex sent">
                                                        <div className="media-body flex-grow-1">
                                                            <div className="msg-box">
                                                                <div className="message-sub-box">
                                                                    <p>
                                                                        {messages?.message}
                                                                    </p>
                                                                    <span>{formatMessageDate(messages.created_at)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </> :
                                                <>
                                                    {/* receiver part */}
                                                    <li className="media d-flex received">
                                                        <div className="avatar flex-shrink-0">
                                                            <img
                                                                src={userAvatar}
                                                                alt="User Imagehb"
                                                                className="avatar-img rounded-circle"
                                                            />
                                                        </div>
                                                        <div className="media-body flex-grow-1">
                                                            <div className="msg-box">
                                                                <div className="message-sub-box">
                                                                    <p>
                                                                        {messages?.message}
                                                                    </p>
                                                                    <span>{formatMessageDate(messages.created_at)}</span>
                                                                    {/* <ul className="msg-sub-list">
                                                                        <li>
                                                                            <img
                                                                                src={sendFile}
                                                                                alt='fgg'
                                                                                className="me-1"
                                                                            />
                                                                            The liver.img
                                                                            <span className="ms-1">
                                                                                520KB
                                                                            </span>
                                                                        </li>
                                                                    </ul> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </>
                                            }

                                        </>
                                    )
                                )
                                }



                            </> : <></>}



                        </ul>
                    </div>

                    {/* New message input */}
                    <div className="chat-footer-box">
                        <div className="discussion-sent">
                            <div className="row gx-2">
                                <div className="col-lg-12">
                                    <div className="footer-discussion">
                                        <div className="inputgroups">
                                            <input
                                                className="text-base"
                                                type="text"
                                                placeholder="Type your Message here..."
                                                value={newMessage}
                                                onChange={(e) => setNewMessage(e.target.value)}
                                            />
                                            <div className="micro-text position-icon">
                                                <img
                                                    src={chatFoot}
                                                    alt=''
                                                />
                                            </div>
                                            <div className="send-chat position-icon comman-flex">
                                                <button onClick={handleSendMessage}>
                                                    <img
                                                        src={chatFoot_2}
                                                        alt='ddssdds'
                                                    />
                                                </button>
                                            </div>
                                            <div className="symple-text position-icon">
                                                <ul>
                                                    <li>
                                                        <label htmlFor="picture" className={'pointer'}>
                                                            <img
                                                                src={chatFoot_3}
                                                                className="me-2"
                                                                alt='sdddsd'
                                                            />

                                                        </label>
                                                        <input onChange={handleFileChange} type="file" className="d-none" id={'picture'} accept="image/*, .pdf, .doc, .docx" multiple />
                                                    </li>
                                                    {/* <li>
                                                        <button onClick={() => alert('cfvfd')}>
                                                            <img
                                                                src={chatFoot_4}
                                                                alt='submit'
                                                            />
                                                        </button>
                                                    </li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChatBoxComponent;
