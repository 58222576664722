import React from 'react';
import Head from '../../components/HeadComponent/Head';
import { Link, useNavigate } from 'react-router-dom';
import './style.css'
import {Player} from "@lottiefiles/react-lottie-player";

function Page501(props) {
    const navigate=useNavigate()
    return (
        <>
            <Head espace={'paris'}>
                <title>Exnet - Page not Found</title>
            </Head>
            <div className="main-wrapper error-wrapper">
                <div className="error-box">
                    <Player
                        autoplay
                        loop
                        src="https://assets4.lottiefiles.com/packages/lf20_llckxtas.json"
                        style={{ width: "90%" }}
                    ></Player>
                    <h3><i className="fas fa-exclamation-triangle" /> Oops! Error 501!</h3>
                    <p>You are not authorized to access this page.</p>

                    <span onClick={() => navigate('/')} className="btn btn-primary go-home">Go to Home</span>
                </div>
            </div>
        </>


    );
}

export default Page501;
