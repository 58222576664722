import React, { useState } from 'react'
import useDisclosure from '../../../hooks/useDisclosure';
import EmployeeFormModal from './Form';
import useAdminLists from '../../../hooks/useAdminLists';
import { formatDefaultDate } from '../../../utils/moment';
import { TableLoader } from '../../Utils/Loader';
import PaginationComponent from '../../paginationComponent/PaginationComponent';
import useTableFilter from '../../../hooks/useTableFilter';
import { TbArrowsUpDown } from 'react-icons/tb';
import { PlusIcon } from '@heroicons/react/24/outline';
import { TableComponent } from '../../TableauComponent';

export const EmployeesView = () => {
    return <MainView title="Liste des salariés" type="salaries" />
}

export const SoustraitantsView = () => {
    return <MainView title="Liste des fournisseurs" type="soustraitants" />
}

export const DriversView = () => {
    return <MainView title="Liste des chauffeurs" type="chauffeur" />
}

export default function MainView({ title, type = "salaries" }) {
    const { isOpen, onClose, onOpen } = useDisclosure();

    const {
        isOpen: isDeleteConfirmOpen,
        onClose: onDeleteConfirmClose,
        onOpen: onDeleteConfirmOpen
    } = useDisclosure();


    const { adminData, metadata, setPage, setPerPage, isLoading, onSubmit } = useAdminLists({ type });
    const [searchValue, setSearchValue] = useState("");
    const [selectedItem, setSelectedItem] = useState(null);

    const filteredLists = adminData.data.filter(item => {
        const matchingFirstName = item.prenom.includes(searchValue)
        const matchingLastName = item.nom.includes(searchValue)
        const matchingEmail = item.email.includes(searchValue)
        return matchingFirstName || matchingLastName || matchingEmail
    })

    const onItemSelected = (item, action) => {
        setSelectedItem(item)
        if (action === "edit") {
            onOpen()
        } else if (action === "delete") {
            //alert("Delete")
            onDeleteConfirmOpen()
        } else {

        }
    }

    return (
        <div className={"px-2 md:px-0"}>
            <div className="flex items-center justify-between gpa-3">
                <div>
                    <h4 className="uppercase page-title">{title}</h4>
                    <div className="w-md">
                        <input
                            className="w-full px-4 py-2 mb-3 bg-white border border-gray-500 rounded-lg outline-none form-control"
                            type="search"
                            placeholder="Recherche"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                    </div>
                </div>
                <div
                    className="float-right my-auto text-white bg-black btn btn-rounded"
                    onClick={onOpen}
                >
                    <PlusIcon className={"h-4 w-4 m-3 text-xl font-bold "} />
                </div>
            </div>

            <div className="py-3">
                {adminData.total ?
                    <PaginationComponent
                        page={metadata.page}
                        handlePageClick={setPage}
                        totalItems={adminData.total}
                        perPageItems={metadata.perPage}
                        setnumberPerPage={setPerPage}
                    /> : null}
            </div>

            <Lists data={filteredLists} onSelect={onItemSelected} isLoading={isLoading} />
            <div className="py-3">
                {adminData.total ?
                    <PaginationComponent
                        page={metadata.page}
                        element='pagination'
                        handlePageClick={setPage}
                        totalItems={adminData.total}
                        perPageItems={metadata.perPage}
                        setnumberPerPage={setPerPage}
                    /> : null}
            </div>
            <EmployeeFormModal
                isOpen={isOpen}
                onClose={() => {
                    setSelectedItem(null)
                    onClose()
                }}
                onSubmit={onSubmit}
                selectedData={selectedItem}
                type={type}
            />

        </div>
    )
}


const Lists = ({ data, onSelect, isLoading }) => {
    const { results, onTableHeaderClick } = useTableFilter({ items: data });

    const HEADERS = [
        { label: 'ID', filterKey: 'id' },
        { label: 'Nom', filterKey: 'nom' },
        { label: 'Prénoms', filterKey: 'prenom' },
        { label: 'Email', filterKey: 'email' },
        { label: 'Téléphone', filterKey: 'telephone' },
        { label: 'DATE DE CRÉATION', filterKey: 'created_at' },
        /* { label: 'Action', key: null }, */
    ];

    return (
        <TableComponent withAction={false} headerList={HEADERS} dataLength={data.length} isLoading={isLoading}>
            {results.map((item, i) => {
                return (
                    <tr key={i} className="py-3 divide-y divide-gray-300">
                        <td className={'cursor-pointer'}
                            onClick={() => {
                                onSelect(item, "edit")
                            }}
                        >{item.id}
                        </td>
                        <td className={'cursor-pointer'}
                            onClick={() => {
                                onSelect(item, "edit")
                            }}
                        >{item.nom}</td>
                        <td className={'cursor-pointer'}
                            onClick={() => {
                                onSelect(item, "edit")
                            }}
                        >{item.prenom}</td>
                        <td className={'cursor-pointer'}
                            onClick={() => {
                                onSelect(item, "edit")
                            }}
                        >{item.email}</td>
                        <td className={'cursor-pointer'}
                            onClick={() => {
                                onSelect(item, "edit")
                            }}
                        >{item.telephone}</td>
                        <td className={'cursor-pointer'}
                            onClick={() => {
                                onSelect(item, "edit")
                            }}
                        >{formatDefaultDate(item.created_at)}</td>


                    </tr>
                )
            })
            }
        </TableComponent>


    )
}