import React, { useCallback, useEffect, useState } from "react";
import useDisclosure from "../../hooks/useDisclosure";
import { useBasicFormValues } from "../../hooks/useFormValues";
import { userService } from "../../services/user/UserService";
import ExnetTypeSelector from "../Utils/Selectors/ExnetTypeSelector";
import ModalComponent from "../Utils/Modal/Index";
import RolesSelector from "../Utils/Selectors/Roles";
import MenuSelector, { SubMenuSelector } from "../Utils/Selectors/MenuSelector";
import ModalUI from "../Utils/Modal/Modal";
import { validateActorData } from "../../utils/validators";
import { useParams } from "react-router-dom";
import { extractRoles } from "../../utils";
import Loader from "../Utils/Loader";
import { toast } from "react-toastify";
import SalarieSalarieComponent from "../Utils/Selectors/SalarieSalarieComponent";
import PhoneInput from "react-phone-input-2";
import CountriesSelector from "../Utils/Selectors/CountriesSelector";
import { PlusIcon } from "@heroicons/react/24/outline";

const FormComponent = ({ selectedData, editable, newData, type, onSubmit, onClose }) => {
  const { milieu: _interface } = useParams();
  const {
    data: userData,
    setFormValue,
    setData,
  } = useBasicFormValues({
    roles: type,
    interface: _interface,
    type: _interface || "paris",
    user_type: type,
    onglet_expedition: "",
    menus: "",
    bill_center_id: null,
    country: "fr"
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [SalaryValue, setSalaryValue] = useState({})

  console.log({ userData })

  useEffect(() => {
    if (selectedData) {
      setData({ ...selectedData });
      setFormValue("user_type", type)
      //setFormValue("address1", selectedData.address1)

      if (selectedData?.roles) {
        const roles = extractRoles(selectedData.roles, 1)
        const interfaces = extractRoles(selectedData.roles, 2)

        if (roles) {
          setFormValue("roles", roles.map(r => r.toLowerCase()).join(","))
        }
        if (interfaces) {
          setFormValue("interface", interfaces.map(r => r.toLowerCase()).join(","))
        }

      }
    }
  }, [selectedData, type]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const submitData = {
        name: userData.name,
        email: userData.email,
        is_actif: 1,
        country: userData.country,
        postal_code: userData.postal_code,
        city: userData.city,
        address1: userData.address1,
        //telephone: userData.telephone,
        telephone1: userData.telephone1,
        salarie_id: userData.salarie_id,
        user_type: type,
        email1: userData.email,
        onglet_expedition: userData.onglet_expedition,
        menus: userData.menus,
        roles: userData.roles
      };
      const _type = userData.interface || "paris"

      if (userData.id) {
        submitData.id = userData.id;
      }

      submitData.type = _type;
      submitData.interface = _type

      console.log({ submitData })

      const isUpdated = Boolean(userData.id)

      setValidationError(null);
      const { error: _err } = validateActorData(submitData);
      if (_err) {
        setValidationError(_err);
        return;
      }
      setIsSubmitting(true);
      const request = isUpdated ? userService.update : userService.create
      const { data } = await request(submitData);

      if (data.success) {
        onSubmit?.(isUpdated ? {
          ...submitData,
          roles: selectedData.roles
        } : data.data)
      }
    } catch (error) {
      console.error(error);
      toast.error('Une erreur lors de la soumission de votre demande', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",

      })
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (SalaryValue && SalaryValue['value']) {
      let data = JSON.parse(JSON.stringify(SalaryValue['value']))
      data.salarie_id = data.id
      delete data.id
      setData({ ...data, user_type: type })

      if (data.roles) {
        const roles = extractRoles(data.roles, 1)
        const interfaces = extractRoles(data.roles, 2)

        if (roles) {
          setFormValue("roles", roles.map(r => r.toLowerCase()).join(","))
        }
        if (interfaces) {
          setFormValue("interface", interfaces.map(r => r.toLowerCase()).join(","))
        }

      }
    }



    //   for (const key in SalaryValue) {
    //     if (SalaryValue.hasOwnProperty(key)) {
    //         const value = JSON.parse(JSON.stringify(SalaryValue[key]));
    //         console.log(value);
    //     }
    // }

  }, [SalaryValue, type])

  const onEmployeeSelected = useCallback((v) => {
    const _value = v.value
    if (_value) {
      console.log({ _value, selectedData })
      setData(d => ({
        ...d,
        name: _value.nom + ' ' + _value.prenom,
        salarie_id: _value.id,
        email: _value.email,
        address1: _value.adress,
        telephone: _value.telephone,
        telephone1: _value.telephone,
        city: _value.city,
        country: _value.country,
        postal_code: _value.postal_code
      }))
    }
  }, [selectedData, setData])

  return (
    <div className="p-3">
      {validationError && (
        <div className="bg-red-100 mt-4 text-red-600 px-4 py-2 rounded-md text-[13px]">
          {validationError}
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="grid gap-6 mb-6 md:grid-cols-2">
          <SalarieSalarieComponent
            onChange={onEmployeeSelected}
            selectedValue={userData.salarie_id}
            isDisabled={Boolean(selectedData)}
          />

          <div className="text-left">
            <label
              htmlFor="address1"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Adresse*
            </label>
            <input
              type="text"
              id="address1"
              className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
              required
              value={userData.address1}
              readOnly
              onChange={(e) => setFormValue("address1", e.target.value)}
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="city"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Ville*
            </label>
            <input
              type="text"
              id="city"
              className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:border-blue-100"
              required
              readOnly
              value={userData.city}
              onChange={(e) => setFormValue("city", e.target.value)}
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="postal_code"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Code Postal*
            </label>
            <input
              type="text"
              id="postal_code"
              className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:border-blue-100"
              required
              readOnly
              value={userData.postal_code}
              onChange={(e) => setFormValue("postal_code", e.target.value)}
            />
          </div>

          <div className="text-left">
            <CountriesSelector
              selectedValue={userData.country}
              onSelect={(v) => {
                setFormValue("country", v.value);
              }}
              isDisabled={true}
            />
          </div>



          <div className="text-left">
            <label
              htmlFor="telephone1"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Téléphone*
            </label>
            <PhoneInput

              country={'fr'}
              defaultValue={selectedData ? userData.telephone1 : userData.telephone1}
              value={selectedData ? userData.telephone1 : userData.telephone1}
              onChange={(value, country, e, formattedValue) => {
                setFormValue(selectedData ? "telephone1" : "telephone", formattedValue)
              }}
              inputClass={'form-controle'}
              countryCodeEditable={true}
              disableDropdown={false}
              disabled={true}
            />

            {/* <input
              type="text"
              id="telephone1"
              className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
              required
              value={userData.telephone1}
              onChange={(e) => setFormValue("telephone1", e.target.value)}
            /> */}
          </div>


          <div className="text-left">
            <label
              htmlFor="email1"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Email*
            </label>
            <input
              type="email"
              id="email1"
              className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
              required
              value={userData.email}
              readOnly
              onChange={(e) => setFormValue("email", e.target.value)}
            />
          </div>

          <div className="text-left">
            <ExnetTypeSelector
              value={userData?.interface?.split(",")}
              onChange={(v) => {
                setFormValue("interface", v.join(","));
              }}
            />
          </div>

          {type !== "chauffeur" && <>
            <div className="text-left">
              <RolesSelector
                value={userData?.roles?.split(",")}
                onChange={(v) => {
                  console.log({ v, j: v.join(",") })
                  setFormValue("roles", v.join(","));
                }}
              //required={true}
              />
            </div>

            <div className="text-left">
              <MenuSelector
                value={userData?.menus?.split(",")}
                onChange={(v) => {
                  setFormValue("menus", v.join(","));
                }}
                type={type}
              //required={true}
              />
            </div>
            <div className="text-left">
              <SubMenuSelector
                value={userData?.onglet_expedition?.split(",")}
                onChange={(v) => {
                  setFormValue("onglet_expedition", v.join(","));
                }}
              />
            </div>
          </>}

        </div>
        <div className="flex justify-end mt-10 mb-5">
          <div
            type="button"
            onClick={() => onClose?.()}
            className="bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-5"
          >
            Annuler
          </div>
          {editable && (
            <button
              type="submit"
              className={`${isSubmitting ? "cursor-not-allowed" : "cursor-pointer"} text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center`}
              disabled={isSubmitting}
            >
              <span className="mr-1">Modifier</span>
              {isSubmitting && <Loader />}
            </button>
          )}

          {newData && (
            <button
              type="submit"
              className={`${isSubmitting ? "cursor-not-allowed" : "cursor-pointer"} text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center`}
              disabled={isSubmitting}
            >
              <span className="mr-1">Enregistrer</span>
              {isSubmitting && <Loader />}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default function UserSimpleForm({
  title,
  withoutModal,
  selectedData,
  editable,
}) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      {withoutModal ? (
        <FormComponent selectedData={selectedData} editable={editable} />
      ) : (
        <>
          <div
            className="float-right btn btn-primary btn-rounded"
            onClick={onOpen}
          >
            <PlusIcon  className={"h-4 w-4 m-3 text-xl font-bold "}/>
          </div>
          <ModalComponent isOpen={isOpen} onClose={onClose} title={title}>
            <FormComponent
              selectedData={null}
              newData={true}
              editable={false}
              onClose={onClose}
            />
          </ModalComponent>
        </>
      )}
    </>
  );
}

export const ActorModal = ({
  isOpen,
  selectedData,
  onClose,
  onSubmit,
  type,
}) => {
  return (
    <ModalUI
      isOpen={isOpen}
      onClose={onClose}
      title={selectedData ? `Modifier un ${type}` : `Créer un ${type}`}
    >
      <FormComponent
        selectedData={selectedData}
        newData={true}
        onSubmit={(result) => {
          onSubmit(result)
          onClose()
        }}
        type={type}
        onClose={onClose}
      />
    </ModalUI>
  );
};
