import React, { useEffect, useState } from 'react'
import exploitation from '../../services/exploitation';
import { useCallback } from 'react';
import { useLocation } from "react-router-dom";
import { Lists as ExpeditionLists } from '../../components/Exploitation/TableRow';

export default function ExploitationCourses() {
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [detailView, setDetailView] = useState("");

    const [isLoading, setIsLoading] = useState(true);
    const [metadata, setMetadata] = useState({
        page: 1,
        search: "",
    });

    const { page, search } = metadata;

    const { pathname } = useLocation();
    const splitPathname = pathname.split("/");

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true)
                const { data } = await exploitation.getShippings({
                    type: "all",
                    per_page: 50,
                    page,
                    category: "all",
                    ExpeditionType: "all",
                });
                console.log({ data })
                if (data.success) {
                    const _data = data.data
                    const _items = _data.data
                    setItems(i => page === 1 ? _items : [...i, ..._items]);

                    if (page < _data.last_page) {
                        setMetadata(m => ({ ...m, page: page + 1 }));
                    }
                }
            } catch (error) {
                console.error(error)
            } finally {
                setIsLoading(false)

            }
        })()
    }, [page])

    const onAttributionSelected = useCallback(() => { }, [])

    const onAddTrackingSelected = useCallback(() => { }, [])

    return (
        <div>
            <div className="flex items-center mb-10">
                <h4 className="uppercase page-title mb-0">Liste des courses</h4>
            </div>
            <div className="bg-white">
                <ExpeditionLists
                    data={items}
                    setItems={setItems}
                    isLoading={isLoading && items.length === 0}
                />
            </div>
        </div>
    )
}
