// create Fuel react service

import axios from "axios";
import TokenService from "../config/tokenService.js";

class FactureService {

    constructor() {
        TokenService.setHeader();
    }
    fetchFactures(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/ops/facture/liste`, data);
    }

    saveFacture(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/ops/facture/save`, data);
    }

    getLatestFactureRef() {
        return axios.post(`${process.env.REACT_APP_API_URL}/user/facture/ref`);
    }

    reeditFacture(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/user/facture/active-reediter`, data);
    }

    getFactureRefs(data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/user/facture/ref/liste-reediter`, data);
    }


    extractionList(options) {
        return axios.post(`${process.env.REACT_APP_API_URL}/ops/facture/extraction`, options);
    }

    globaleList(options) {
        return axios.post(`${process.env.REACT_APP_API_URL}/ops/facture/liste-globale`, options);
    }

    getAvoirShippings(options) {
        return axios.post(`${process.env.REACT_APP_API_URL}/ops/facture/detail`, options);
    }

    getShippingsWithAvoir(options) {
        return axios.post(`${process.env.REACT_APP_API_URL}/ops/facturation-ListByTypeAvoir/ByRef`, options);
    }



}

export default new FactureService();
