import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { exnetSpaceColor, urlSplit } from "../../utils/Functions/otherFunctions";
import SalarieSalarieComponent from "../Utils/Selectors/SalarieSalarieComponent";
import UsersSelector from "../Utils/Selectors/UsersSelector";

export default function Filter({
    filterData,
    setfilterData,
    revokeSearch,
    handleSearch
}) {

    const { exnetSpace } = urlSplit()

    const [closeSearch, setcloseSearch] = useState(false)
    const { pathname } = useLocation()

    const changeValue = (valueKey, value) => {
        let newValue = {};
        for (const property in filterData) {
            if (filterData[property] !== "") {
                newValue[property] = filterData[property];
            }
        }
        newValue[valueKey] = value;

        setfilterData(newValue);
    };

    const handlerEnterClick = (event) => {
        // Vérifier si la touche "Enter" a été pressée (code 13)
        if (event.key === 'Enter') {
            handleSearch()
        }
    };

    useEffect(() => {
        if (closeSearch) {
            revokeSearch()
            setcloseSearch(false)
        }
    }, [closeSearch])

    return (
        <div className="p-3 bg-gray-100 rounded-md">
            <div className="grid-cols-2 gap-4 space-y-2 md:space-y-0 lg:grid-cols-4 md:grid">
                <div className="w-full w-md ">
                    <label
                        className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                        htmlFor="ref"
                    >
                        Référence
                    </label>
                    <input
                        onKeyDown={handlerEnterClick}
                        className="block text-sm w-full h-[40px] px-4 py-2  leading-tight text-gray-700 bg-white border border-gray-500 rounded-lg appearance-none focus:outline-none focus:bg-white"
                        id="ref"
                        type="text"
                        placeholder=""
                        value={filterData?.ref ? filterData?.ref : ""}
                        onChange={(e) => {
                            if (parseInt(e.target.value) || e.target.value === "") {
                                if (e.target.value === "") {
                                    setcloseSearch(true)
                                    // revokeSearch()
                                    changeValue("ref", e.target.value);
                                } else {
                                    changeValue("ref", e.target.value);
                                }
                            }
                        }}
                    />
                </div>

                <div className="w-full w-md ">
                    <label
                        className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                        htmlFor="suivi"
                    >
                        Utilisateurs
                    </label>
                    <div className="mb-3">
                        <UsersSelector
                            profil="user"
                            showLabel={false}
                            // selectedValue={metadata.user_id}
                            selectedValue={filterData?.salarie_id}
                            onChange={(v) => {
                                if (v?.id) {
                                    console.log({ v: v.value })
                                    changeValue("user_id", v.value)
                                }
                            }}
                        />

                        {/* <SalarieSalarieComponent showLabel={false} selectedValue={filterData?.salarie_id} onChange={(v) => {
                            console.log({ v })
                            changeValue("user_id", v)
                        }} /> */}

                    </div>

                </div>

                <div className="w-full w-md">
                    <label
                        className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                        htmlFor="startDate"
                    >
                        Date de début
                    </label>
                    <input
                        onKeyDown={handlerEnterClick}
                        className="block text-sm w-full h-[40px] px-4 py-2 mb-3 leading-tight text-gray-700 bg-white border border-gray-500 rounded-lg appearance-none focus:outline-none focus:bg-white"
                        id="startDate"
                        type="date"
                        placeholder=""
                        value={filterData?.startDate ? filterData?.startDate : ""}
                        onChange={(e) => changeValue("startDate", e.target.value)}
                    />
                </div>

                <div className="w-full w-md">
                    <label
                        className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                        htmlFor="endDate"
                    >
                        Date de fin
                    </label>
                    <input
                        onKeyDown={handlerEnterClick}
                        className="block text-sm w-full h-[40px] px-4 py-2 mb-3 leading-tight text-gray-700 bg-white border border-gray-500 rounded-lg appearance-none focus:outline-none focus:bg-white"
                        id="endDate"
                        type="date"
                        placeholder=""
                        value={filterData?.endDate ? filterData?.endDate : ""}
                        onChange={(e) => changeValue("endDate", e.target.value)}
                    />
                </div>


                <div className="flex w-full w-md md:mb-0">
                    <button
                        onClick={handleSearch}
                        type="button"
                        className="mx-2 text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-3 py-2.5 text-center"
                        // disabled={isSubmitting}
                        style={{
                            color: "#fff",
                            backgroundColor: `${exnetSpaceColor(exnetSpace)}`,
                        }}
                    >
                        Rechercher
                    </button>

                    <button
                        onClick={revokeSearch}
                        type="button"
                        className="mx-2 text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-3 py-2.5 text-center"
                        // disabled={isSubmitting}
                        style={{
                            color: "#fff",
                            backgroundColor: `${exnetSpaceColor(exnetSpace)}`,
                        }}
                    >
                        Annuler
                    </button>
                </div>
            </div>
        </div>
    );
}
