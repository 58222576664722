import React, { useCallback, useEffect, useState } from "react";
import ModalUI from "../../Utils/Modal/Modal";
import { useBasicFormValues } from "../../../hooks/useFormValues";
import facturationCodeService from "../../../services/facturationCode/service";
import { validateFacturationCodeData } from "../../../utils/validators";
import Loader from "../../Utils/Loader";
import { SwitchComponent } from "../../Utils/Index";
import { pick } from "lodash";
import { toast } from "react-toastify";
import useAdminLists from "../../../hooks/useAdminLists";
import OptionsSelector from "../../Utils/Selectors/Index";

export default function FormModal({
    isOpen,
    onClose,
    onSubmit,
    selectedData,
}) {

    const initialData = {
        label: "",
        description: "",
        montant: 0,
        variation: 0,
        fuel: 1,
        for_variation: 0,
        Europe: 0,
        Express: 0,
        International: 0,
        Locale: 0,
        Spx_1h30: 0,
        SPX_3h: 0,
        National: 0,
        Premium: 0,
        Spx: 0
    }

    const {
        data: facturationCodData,
        setFormValue,
        setData,
    } = useBasicFormValues(initialData);

    useEffect(() => {
        if (selectedData) {
            //console.log({ selectedData })
            //setFormValue("chauffeur_id", selectedData.chauffeur_id);
            setData((d) => ({
                ...d,
                ...selectedData,
            }));
        } else {
            setData(initialData)
        }
    }, [selectedData, isOpen]);

    //console.log({ facturationCodData })

    const [validationError, setValidationError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const type = "fuels"

    const { adminData } = useAdminLists({ type });

    const handleSubmit = useCallback(
        async (e) => {
            try {
                e.preventDefault();
                setValidationError(null);
                const submitData = { ...facturationCodData } //pick(facturationCodData, ["id", "label", "description", "index_fuel"])
                if (submitData.fueldata) {
                    delete submitData.fueldata
                }
                const { error: _err } = validateFacturationCodeData(submitData);

                if (_err) {
                    setValidationError(_err);
                    return;
                }
                setIsSubmitting(true);
                const request = selectedData
                    ? facturationCodeService.edit
                    : facturationCodeService.add;
                const { data } = await request(submitData);
                if (data.success) {
                    onClose();
                    onSubmit?.(selectedData ? data.data : data.data);
                }
            } catch (error) {
                console.error(error);
                toast.error('Une erreur lors de la soumission de votre demande', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } finally {
                setIsSubmitting(false);
            }
        },
        [facturationCodData, selectedData]
    );

    const fuels = adminData.data.map(f => ({
        ...f,
        label: `${f.value}%`,
        v: f.value,
        value: f.index_fuel
    }))

    console.log({ fuels, facturationCodData })

    return (
        <ModalUI
            isOpen={isOpen}
            onClose={onClose}
            title={selectedData ? "Modifier le code de facturation" : "Créer un code de facturation"}
        >
            {validationError && (
                <div className="bg-red-100 mt-4 text-red-600 px-4 py-2 rounded-md text-[13px]">
                    {validationError}
                </div>
            )}
            <form onSubmit={handleSubmit} className="p-3">
                <div className="grid gap-6 pb-100 mb-3 md:grid-cols-2">
                    <div className="text-left">
                        <label
                            htmlFor="label"
                            className="block mb-2 text-sm font-medium text-gray-900"
                        >
                            Label
                        </label>
                        <input
                            type="text"
                            id="label"
                            placeholder="Label"
                            className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
                            required
                            value={facturationCodData.label}
                            onChange={(e) => setFormValue("label", e.target.value)}
                        />
                    </div>

                    <div className="text-left">
                        <label
                            htmlFor="description"
                            className="block mb-2 text-sm font-medium text-gray-900"
                        >
                            Description
                        </label>
                        <input
                            type="text"
                            id="description"
                            placeholder="Description"
                            className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
                            required
                            value={facturationCodData.description}
                            onChange={(e) => setFormValue("description", e.target.value)}
                        />
                    </div>
                    <div className="text-left">
                        <label
                            htmlFor="montant"
                            className="block mb-2 text-sm font-medium text-gray-900"
                        >
                            Montant
                        </label>
                        <input
                            type="number"
                            id="montant"
                            className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
                            required
                            value={facturationCodData.montant}
                            onChange={(e) => setFormValue("montant", e.target.value)}
                        />
                    </div>

                    <div className="text-left">
                        <label
                            htmlFor="variation"
                            className="block mb-2 text-sm font-medium text-gray-900"
                        >
                            Variation
                        </label>
                        <input
                            type="text"
                            id="variation"
                            placeholder="variation"
                            className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
                            required
                            value={facturationCodData.variation}
                            onChange={(e) => setFormValue("variation", e.target.value)}
                        />
                    </div>

                    <div className="text-left">
                        <OptionsSelector
                            label="Fuel"
                            options={fuels}
                            value={facturationCodData.fuel}
                            onChange={(e) => {
                                console.log({ e })
                                setFormValue("fuel", e.index_fuel)
                            }}
                        />
                    </div>

                    <div className="text-left">
                        <div className="flex items-center mt-9">
                            <div className="mr-2 text-sm font-medium text-gray-900">Avec TVA</div>
                            <SwitchComponent
                                label="tva"
                                checked={Boolean(facturationCodData.tva === 1)}
                                onChange={(value) => {
                                    setFormValue("tva", value ? 1 : 0);
                                }}
                            />
                        </div>
                    </div>
                </div>



                <div className="grid gap-6 mb-3 md:grid-cols-4 d-none">
                    {/* <div className="flex items-center mb-4 mr-4">
                        <div className="mr-2 text-sm font-medium text-gray-900">Avec TVA</div>
                        <SwitchComponent
                            label="tva"
                            checked={Boolean(facturationCodData.tva === 1)}
                            onChange={(value) => {
                                setFormValue("tva", value ? 1 : 0);
                            }}
                        />
                    </div> */}
                    <div className="flex items-center mb-4 mr-4">
                        <div className="mr-2 text-sm font-medium text-gray-900">Pour variation</div>
                        <SwitchComponent
                            label="for_variation"
                            checked={Boolean(facturationCodData.for_variation === 1)}
                            onChange={(value) => {
                                setFormValue("for_variation", value ? 1 : 0);
                            }}
                        />
                    </div>
                    <div className="flex items-center mb-4 mr-4">
                        <div className="mr-2 text-sm font-medium text-gray-900">Europe</div>
                        <SwitchComponent
                            label="Europe"
                            checked={Boolean(facturationCodData.Europe === 1)}
                            onChange={(value) => {
                                setFormValue("Europe", value ? 1 : 0);
                            }}
                        />
                    </div>

                    <div className="flex items-center mb-4 mr-4">
                        <div className="mr-2 text-sm font-medium text-gray-900">Express</div>
                        <SwitchComponent
                            label="Express"
                            checked={Boolean(facturationCodData.Express === 1)}
                            onChange={(value) => {
                                setFormValue("Express", value ? 1 : 0);
                            }}
                        />
                    </div>


                    <div className="flex items-center mb-4 mr-4">
                        <div className="mr-2 text-sm font-medium text-gray-900">International</div>
                        <SwitchComponent
                            label="International"
                            checked={Boolean(facturationCodData.International === 1)}
                            onChange={(value) => {
                                setFormValue("International", value ? 1 : 0);
                            }}
                        />
                    </div>

                    <div className="flex items-center mb-4 mr-4">
                        <div className="mr-2 text-sm font-medium text-gray-900">Locale</div>
                        <SwitchComponent
                            label="Locale"
                            checked={Boolean(facturationCodData.Locale === 1)}
                            onChange={(value) => {
                                setFormValue("Locale", value ? 1 : 0);
                            }}
                        />
                    </div>

                    <div className="flex items-center mb-4 mr-4">
                        <div className="mr-2 text-sm font-medium text-gray-900">Spx_1h30</div>
                        <SwitchComponent
                            label="Spx_1h30"
                            checked={Boolean(facturationCodData.Spx_1h30 === 1)}
                            onChange={(value) => {
                                setFormValue("Spx_1h30", value ? 1 : 0);
                            }}
                        />
                    </div>

                    <div className="flex items-center mb-4 mr-4">
                        <div className="mr-2 text-sm font-medium text-gray-900">SPX_3h</div>
                        <SwitchComponent
                            label="SPX_3h"
                            checked={Boolean(facturationCodData.SPX_3h === 1)}
                            onChange={(value) => {
                                setFormValue("SPX_3h", value ? 1 : 0);
                            }}
                        />
                    </div>

                    <div className="flex items-center mb-4 mr-4">
                        <div className="mr-2 text-sm font-medium text-gray-900">National</div>
                        <SwitchComponent
                            label="National"
                            checked={Boolean(facturationCodData.National === 1)}
                            onChange={(value) => {
                                setFormValue("National", value ? 1 : 0);
                            }}
                        />
                    </div>

                    <div className="flex items-center mb-4 mr-4">
                        <div className="mr-2 text-sm font-medium text-gray-900">Premium</div>
                        <SwitchComponent
                            label="Premium"
                            checked={Boolean(facturationCodData.Premium === 1)}
                            onChange={(value) => {
                                setFormValue("Premium", value ? 1 : 0);
                            }}
                        />
                    </div>

                    <div className="flex items-center mb-4 mr-4">
                        <div className="mr-2 text-sm font-medium text-gray-900">Spx</div>
                        <SwitchComponent
                            label="Spx"
                            checked={Boolean(facturationCodData.Spx === 1)}
                            onChange={(value) => {
                                setFormValue("Spx", value ? 1 : 0);
                            }}
                        />
                    </div>
                </div>
                <div className="flex justify-end mt-4">
                    <button
                        type="button"
                        onClick={() => onClose?.()}
                        className="bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-5"
                    >
                        Annuler
                    </button>
                    <button
                        type="submit"
                        className={`cursor-pointer text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm px-14 py-2.5 text-center`}
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                    //title={`${}`}
                    >
                        <span className="mr-3">
                            {!selectedData ? "Enregistrer" : "Modifier"}
                        </span>
                        {isSubmitting && <Loader />}
                    </button>
                </div>
            </form>
        </ModalUI>
    );
}
