import FacturationLineService from "../../../services/facturationLine/facturationLineService.js";

export const AdminFacturationLinesTableDataController = async (nbrPage, process, filter) => {

  switch (process){
      case "list":
          try {
              const data = await FacturationLineService.fetchFacturationLines({
                  type: (filter ? (filter.type ?? "all") : "all"),
                  per_page: process.env.REACT_APP_DATA_PER_PAGE,
                  page: nbrPage,
              });
              let response = data.data;
              return {
                  success: true,
                  data: response,
              };
          } catch (error) {
              return {
                  success: false,
                  data: error,
              };
          }
      case "update":
          break;
  }
};
